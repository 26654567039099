import { all, call, put } from 'redux-saga/effects';

import * as types from './calendar.types';
import * as actions from './calendar.actions';
import * as api from '../../api/request-config/calendar.api';

// import * as eventService from '../event/event.saga-service';

import { apiRequest } from '../../api/api.saga-services';
import { select, selectWithParams } from '../../../utils/selector.utils';
import { calendarSelectors } from './calendar.selectors';
import { IEvent } from '../event/event.types';

export function* storeCalendar(calendar: types.ICalendar) {
  yield put(actions.storeCalendar(calendar));
}

export function* clearCalendar(calendarId: types.ICalendar['id']) {
  yield put(actions.clearCalendar(calendarId));
}
export function* clearAllCalendars() {
  const calendarIds: types.ICalendar['id'][] = yield select(calendarSelectors.selectResourceIds);
  yield put(actions.clearCalendar(calendarIds));
}
export function* getMyCalendars() {
  const calendars: types.ICalendar[] = yield call(apiRequest, api.getMyCalendarsApi());
  if (!calendars) {
    return;
  }
  yield all(calendars.map((calendar) => call(storeCalendar, calendar)));
  return calendars.map((calendar) => calendar.id);
}

export function* populateEvents(calendarId: types.ICalendar['id']) {
  const calendar: types.ICalendar | null = yield selectWithParams(calendarSelectors.selectResourceById, calendarId);
  if (!calendar) {
    return;
  }
  // const eventIds: IEvent['id'][] = yield call(eventService.getEvents, { calendarId });
  // yield call(storeCalendar, { ...calendar, eventIds });
}

export function* addEvents(calendarId: types.ICalendar['id'], eventIds: IEvent['id'][]) {
  const calendar: types.ICalendar | null = yield selectWithParams(calendarSelectors.selectResourceById, calendarId);

  if (!calendar) {
    return;
  }

  const finalEventIds = calendar.eventIds
    ? [...calendar.eventIds, ...eventIds.filter((id) => calendar.eventIds?.includes(id))]
    : [...eventIds];
  yield call(storeCalendar, { ...calendar, eventIds: finalEventIds });
}
