import { createActionConst } from '../../../../utils/action.utils';

const TASKS_NAMESPACE = 'TASKS';

export const STORE_REDUCED_TASKS = createActionConst(TASKS_NAMESPACE, 'STORE_REDUCED_TASKS');
export const CLEAR_REDUCED_TASKS = createActionConst(TASKS_NAMESPACE, 'CLEAR_REDUCED_TASKS');

export const FETCH_TASKS = createActionConst(TASKS_NAMESPACE, 'FETCH_TASKS');
export const CREATE_TASK = createActionConst(TASKS_NAMESPACE, 'CREATE_TASK');
export const UPDATE_TASK = createActionConst(TASKS_NAMESPACE, 'UPDATE_TASK');
export const DELETE_TASK = createActionConst(TASKS_NAMESPACE, 'DELETE_TASK');

export const FILTER_TASK_FOR_USER_BY_MEETING = createActionConst(TASKS_NAMESPACE, 'FILTER_TASK_FOR_USER_BY_MEETING');

export const MOVE_TASK = createActionConst(TASKS_NAMESPACE, 'MOVE_TASK');
export const DND_TASK = createActionConst(TASKS_NAMESPACE, 'DND_TASK');
export const MOVE_BOARD_TASK = createActionConst(TASKS_NAMESPACE, 'MOVE_BOARD_TASK');

// export const TASK_SOCKET_NAME = 'my-channel';
// export const TASK_SOCKET_EVENT = 'my-event';

export const SAVE_TASK_SOCKET = 'saveTask';
export const DELETE_TASK_SOCKET = 'deleteTask';
