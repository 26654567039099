import { all, call, fork, takeLatest } from 'redux-saga/effects';

import * as constants from './labels.constants';
import * as types from './labels.types';
import * as services from './labels.saga-services';

function* invokeCreateLabel(action: types.ICreateLabel) {
  yield call(services.createLabel, action.payload);
}

function* invokeUpdateLabel(action: types.IUpdateLabel) {
  yield call(services.updateLabel, action.payload.id, action.payload.data);
}
function* invokeDeleteLabel(action: types.IDeleteLabel) {
  yield call(services.deleteLabel, action.payload);
}

/*
function* invokeSaveChartSocket(action: types.ISaveChartSocket) {
  yield call(services.handleChartFromNotification);
}
*/
export function* watchLabelsPurePageSaga() {
  yield takeLatest(constants.CREATE_LABEL, invokeCreateLabel);
  yield takeLatest(constants.UPDATE_LABEL, invokeUpdateLabel);
  yield takeLatest(constants.DELETE_LABEL, invokeDeleteLabel);

  //yield takeEvery(constants.SAVE_CHART_SOCKET, invokeSaveChartSocket);
}

export function* watchLabelsPageStorageSaga() {
  yield all([watchLabelsPurePageSaga].map(fork));
}
