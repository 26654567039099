import React, { FunctionComponent, useCallback, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { TaskWidget } from '../../widget-items/task-widget-items/TaskWidget';
import { ITaskWidgetListParams } from './types';

export const TaskWidgetList: FunctionComponent<ITaskWidgetListParams> = ({
  name,
  droppableId,
  actionElement,
  reducedTasks,
}) => {
  const [show, setShow] = useState(false);
  const handleShow = useCallback(() => setShow(!show), [show, setShow]);

  return (
    <div className="single-card-container">
      <div className="panel-body p-0">
        <div className="border-radius-1 lightGray-bg p-2 pb-1">
          {name && (
            <div className="panel-header p-0">
              <h2 className="bold">{name}</h2>
              <div className="ml-auto flex">{actionElement}</div>
            </div>
          )}
          <>
            <div className="todo-container tabs">
              <Droppable droppableId={`${droppableId}/active`}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {reducedTasks['active'].map((id, index) => (
                      <Draggable key={`${droppableId}/${id}`} draggableId={id} index={index}>
                        {(provided) => <TaskWidget taskId={id} provided={provided} />}
                      </Draggable>
                    ))}
                    {!reducedTasks['active'].length && <div className="card todo-card"></div>}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <div className="hidden_cards_container">
                <span className="card-separator hide-cards" onClick={handleShow}>
                  <span className="completed_text">
                    {show
                      ? `HIDE COMPLETED (${reducedTasks['done'].length})`
                      : `SHOW COMPLETED (${reducedTasks['done'].length})`}
                  </span>
                </span>
                {show && (
                  <Droppable droppableId={`${droppableId}/done`}>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {reducedTasks['done'].map((id, index) => (
                          <Draggable key={`${droppableId}/${id}`} draggableId={id} index={index}>
                            {(provided) => <TaskWidget taskId={id} provided={provided} />}
                          </Draggable>
                        ))}
                        {!reducedTasks['done'].length && <div className="card todo-card"></div>}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
