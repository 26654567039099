import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IFormPasswordInputProps } from './types';

import { get } from 'lodash';
import { useToggler } from '../../../../../utils/toggler.utils';

export const FormPasswordInput: FunctionComponent<IFormPasswordInputProps> = ({
  id,
  name,
  defaultValue,
  placeholder,
  validation,
  disabled,
  label,
}) => {
  const { control, errors } = useFormContext();
  const [isToggled, setToggled] = useToggler();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={validation}
      render={(controllerProps) => (
        <>
          {!!get(errors, name, false) && (
            <label htmlFor={name} style={{ color: 'red' }}>
              {`${label ? label + ' - ' : ''} ${get(errors, `${name}.message`)}`}
            </label>
          )}
          {!get(errors, name, false) && <label htmlFor={name}>{label}</label>}

          <span className="reveal_password" onClick={setToggled}>
            <i className={`icon-eye ${isToggled ? 'toggled' : ''}`}></i>
          </span>

          <input
            id={id}
            name={controllerProps.name}
            type={isToggled ? 'password' : 'text'}
            placeholder={placeholder}
            disabled={disabled}
            onChange={controllerProps.onChange}
            value={controllerProps.value}
          />
        </>
      )}
    />
  );
};
