/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { FunctionComponent } from 'react';
import { ActionsTaskForm } from '../../../../../../../../common/forms/ActionsTaskForm';
import { IMoveTaskProps } from './types';

export const MoveTask: FunctionComponent<IMoveTaskProps> = ({ task }) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  const close = useCallback(() => setActive(false), [setActive]);
  return (
    <div className={isActive ? 'dropdown opened' : 'dropdown'}>
      <a
        className="btn w100 gray-bg darkGray px-1 f-1 ml-0 mb-1 mt-1 dropdown-button"
        title="Move"
        onClick={toggleClass}
      >
        <i className="icon f-9 blueGray icon-upload mr-1" /> Move
      </a>
      <div className="dropdown-menu drop-right">
        <div className="mini-popup">
          <div className="mini-popup-header">
            Move card
            <span className="close_mini_popup" onClick={close}>
              ×
            </span>
          </div>
          <div className="mini-popup-body">
            <div className="field-container custom-select smaller-select">
              <label className="mb-1 mt-1 d-block">Select destination</label>
              <input type="hidden" />
              {isActive && <ActionsTaskForm task={task} move={true} onClick={toggleClass} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
