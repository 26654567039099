/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../app/dialog/dialog.actions';

import { categorySelectors } from '../../../../app/resource/category/category.selectors';

import { useSelectWithParams } from '../../../../utils/selector.utils';
import { IAgencyDescriptionDialogProps } from '../../../dialogs/AgencyDescriptionDialog/types';
import { Item } from './Item';
import { ICategoryProps } from './types';

export const Category: FunctionComponent<ICategoryProps> = ({ categoryId }) => {
  const category = useSelectWithParams(categorySelectors.selectResourceById, categoryId);
  const [showAll, setShowAll] = useState(true);
  const title = category?.title;
  const description = category?.description;
  const dispatch = useDispatch();
  const openAgencyDescriptionDialog = useCallback(() => {
    dispatch(openDialog('agencyDescriptionDialog', { title, description } as IAgencyDescriptionDialogProps));
  }, [description, dispatch, title]);

  return (
    <div className="panel agency-tools-item">
      <div className="agency-item-top-part">
        <span
          className="agency-tool-info link popup_button mr-3"
          data-popup="tooltip_popup"
          data-tooltip_text={description}
          data-tooltip_title={title}
        >
          <i className="icon-info blueGray" onClick={openAgencyDescriptionDialog} />
        </span>
        <span className="settings-button f-18">
          <i className="icon-settings blueGray" />
        </span>
        {category && <div className="agency-icon" dangerouslySetInnerHTML={{ __html: category?.avatar }}></div>}
        <h2 className="bold mt-2">{category?.title}</h2>
      </div>
      <div className="agency-item-bottom-part pt-0 pb-3 px-5">
        <div className={`list w100 ${showAll ? 'maxh70' : ''} overflow-hidden d-block showAllContainer`}>
          {category?.itemIds.map((itemId) => (
            <div className="list-item h35 mb-0 aic jcc border-bottom" key={itemId}>
              <Item itemId={itemId} color={category.color} />
            </div>
          ))}
        </div>
        {category && category.itemIds.length > 2 && (
          <div className="w100 h35 mb-0 flex aic jcc border-bottom">
            <span
              className="link  flex aic showAll"
              style={{ color: category?.color }}
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? 'Show All' : 'Close'} <i className={`icon-down-dir ${showAll ? '' : 'icon-rotated'}`} />
            </span>
          </div>
        )}
        {category?.buttonTitle !== '' && (
          <a
            className="btn popup_button mt-2"
            style={{ backgroundColor: category?.color }}
            data-popup="agency-tool_popup"
            title={category?.buttonTitle}
          >
            {category?.buttonTitle}
          </a>
        )}
      </div>
    </div>
  );
};
