import * as types from './user-form.types';
import * as constants from './user-form.constants';
import { IMeeting } from '../../../../resource/meeting/meeting.types';

export const filterSelectableTeams = (searchParams?: { title: string }): types.IFilterSelectableTeams => ({
  type: constants.FILTER_SELECTABLE_TEAMS,
  payload: searchParams,
});

export const filterSelectableAccounts = (searchParams?: { title: string }): types.IFilterSelectableAccounts => ({
  type: constants.FILTER_SELECTABLE_ACCOUNTS,
  payload: searchParams,
});

export const storeSelectableAccounts = (selectableAccounts: IMeeting['id'][]): types.IStoreSelectableAccounts => ({
  type: constants.STORE_SELECTABLE_ACCOUNTS,
  payload: selectableAccounts,
});

export const clearSelectableAccounts = (): types.IClearSelectableAccounts => ({
  type: constants.CLEAR_SELECTABLE_ACCOUNTS,
});

export const storeSelectableTeams = (selectableTeams: IMeeting['id'][]): types.IStoreSelectableTeams => ({
  type: constants.STORE_SELECTABLE_TEAMS,
  payload: selectableTeams,
});

export const clearSelectableTeams = (): types.IClearSelectableTeams => ({
  type: constants.CLEAR_SELECTABLE_TEAMS,
});

export const saveUserAvatar = (image: string): types.ISaveUserAvatar => ({
  type: constants.SAVE_USER_AVATAR,
  payload: image,
});

export const clearUserAvatar = (): types.IClearUserAvatar => ({
  type: constants.CLEAR_USER_AVATAR,
});
