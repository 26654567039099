import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IFormCheckboxInputProps } from './types';

export const FormCheckboxInput: FunctionComponent<IFormCheckboxInputProps> = ({
  id,
  name,
  defaultChecked,
  check,
  validation,
  disabled,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultChecked ? defaultChecked : false}
      render={(controllerProps) => (
        <input
          type="checkbox"
          id={id}
          name={name}
          onChange={() => controllerProps.onChange(check === undefined ? !controllerProps.value : !check)}
          checked={check === undefined ? controllerProps.value : check}
          disabled={disabled}
        />
      )}
    />
  );
};
