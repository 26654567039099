import { IApiRequestConfig } from '../api.types';
import { ITemplateSearchParams, ITemplateCreate } from '../../resource/template/template.types';

export const searchTemplateApi = (params?: ITemplateSearchParams): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'searchTemplates',
  uri: 'json',
  action: 'getTemplates',
  method: 'get',
  params: {
    ...params,
  },
});

export const createTemplateApi = (template: ITemplateCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createTemplate',
  uri: 'json',
  action: 'saveTemplate',
  method: 'post',
  data: template,
});
