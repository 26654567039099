/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { useApiRequestState } from '../../../utils/api.utils';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { MeetingForm } from '../../pages/MeetingPage/MeetingForm';

import { ConnectedDialog } from '../ConnectedDialog';
import { IMeetingFormDialogProps } from './types';

export const MeetingFormDialog: FunctionComponent = () => {
  const dispatch = useDispatch();
  const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'meetingFormDialog') as IMeetingFormDialogProps;
  const loaderState = props && props.data ? 'updateMeeting' : 'createMeeting';
  const [showLoader, error] = useApiRequestState(loaderState);
  // const [updateShowLoader, updateError] = useApiRequestStatus();
  const [errorCheck, updateErrorCheck] = useState(false);
  const newCB = useCallback((check) => {
    updateErrorCheck(check);
    //updateErrorCheck(false);
  }, []);
  const onClick = useCallback(
    (check) => {
      updateErrorCheck(check);
      setTimeout(function () {
        newCB(false);
      }, 1500);
    },
    [newCB],
  );

  const onClose = useCallback(() => {
    dispatch(closeDialog('meetingFormDialog'));
  }, [dispatch]);

  return (
    <ConnectedDialog dialogKey="meetingFormDialog">
      <div className="popup new_team_popup mid-popup isVisible">
        <div className="panel" style={{ minWidth: '710px' }}>
          <div className="panel-header small-header flex jcsb">
            <h3>
              {props?.data ? 'Edit' : 'New'} {props?.type === 'account' ? 'Account' : 'Team'}
            </h3>
            <span className="close-popup close">
              {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
              {error && !showLoader && <p style={{ color: 'red' }}> Something went wrong </p>}
            </span>
          </div>
          <div className="panel-body pt-0" style={{ maxHeight: '500px' }}>
            <MeetingForm {...props} errorCheck={errorCheck} />
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" title="" onClick={onClose}>
              Close
            </a>

            <button
              form="meeting-form"
              type="submit"
              className="btn btn-green"
              title=""
              style={showLoader ? { display: 'none' } : { display: 'block' }}
              onClick={() => onClick(true)}
            >
              Save
            </button>
            {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={30} width={30} />}
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
