/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { FormCSTextInput } from '../../../inputs/CustomSizeTextInput';
import { BudgetForm } from './BudgetForm';

import { FeesForm } from './FeesForm';

import { HourlyForm } from './HourlyForm';

import { IProjectTypeFormProps } from './types';

export const ProjectTypeForm: FunctionComponent<IProjectTypeFormProps> = ({
  focusHourlyTuple,
  focusBudgetTuple,
  focusChecked,
  projectData,
}) => {
  const [projectType, setProjectType] = useState(projectData?.type ? projectData.type : 'time');

  return (
    <>
      <div className="panel green-gradient py-1 px-2 flex aic mb-1">
        <h2 className="bold white flex aic">
          PROJECT TYPE <i className="f-1 icon-info white ml-05" data-tooltip="some tooltip text" />
        </h2>
      </div>
      <div className="panel-body p-0">
        <div className="border-radius-1 lightGray-bg p-2">
          <div className="panel white-bg px-2 py-1">
            <div className="panel-header no-border time-tabs orange-tabs px-0">
              <a
                className={`${projectType === 'time' ? 'selected' : ''}`}
                onClick={() => {
                  setProjectType('time');
                }}
                style={{ cursor: 'pointer' }}
                data-tab="orange-tabs"
              >
                Time
              </a>
              <a
                className={`${projectType === 'fixed_fee' ? 'selected' : ''}`}
                onClick={() => {
                  setProjectType('fixed_fee');
                }}
                style={{ cursor: 'pointer' }}
                data-tab="orange-tabs"
              >
                Fixed Fee
              </a>
              <a
                className={`${projectType === 'non_billable' ? 'selected' : ''}`}
                onClick={() => {
                  setProjectType('non_billable');
                }}
                style={{ cursor: 'pointer' }}
                data-tab="orange-tabs"
              >
                NonBillable
              </a>
            </div>
            {projectType === 'time' && (
              <div className="time-tab">
                <HourlyForm focusHourlyTuple={focusHourlyTuple} projectData={projectData} />
                <BudgetForm focusBudgetTuple={focusBudgetTuple} focusChecked={focusChecked} projectData={projectData} />
                <FormCSTextInput id="type" name="type" type="text" size="none" defaultValue={projectType} />
              </div>
            )}
            {projectType === 'fixed_fee' && (
              <div className="time-tab">
                <FeesForm projectData={projectData} />
                <BudgetForm focusBudgetTuple={focusBudgetTuple} focusChecked={focusChecked} projectData={projectData} />
                <FormCSTextInput id="type" name="type" type="text" size="none" defaultValue={projectType} />
              </div>
            )}
            {projectType === 'non_billable' && (
              <div className="time-tab">
                <BudgetForm focusBudgetTuple={focusBudgetTuple} focusChecked={focusChecked} projectData={projectData} />
                <FormCSTextInput id="type" name="type" type="text" size="none" defaultValue={projectType} />
              </div>
            )}

            <hr />
          </div>
        </div>
      </div>
    </>
  );
};
