import React, { FunctionComponent, useMemo } from 'react';
import { ITask } from '../../../../../../../../../app/resource/task/task.types';
import { useToggler } from '../../../../../../../../../utils/toggler.utils';
import { TaskCalendar } from '../../../../../../widget-items/task-widget-items/TaskCalendar';

import { ICalendarItemHourProps } from './types';

export const CalendarItemHour: FunctionComponent<ICalendarItemHourProps> = ({ tasks, userFilter }) => {
  const [isToggledPanel, handleTogglePanel] = useToggler();

  const filteredTasks = useMemo(
    () =>
      tasks.filter((task) =>
        task ? task.members.map((id) => userFilter.includes(id)).filter((contains) => !!contains).length > 0 : false,
      ) as ITask[],
    [tasks, userFilter],
  );

  const [displayedTasks, otherTasks] = useMemo<[ITask[], ITask[]]>(() => {
    return [filteredTasks.slice(0, 5), filteredTasks.slice(5)];
  }, [filteredTasks]);

  return displayedTasks ? (
    <>
      {displayedTasks.map((task) => (
        <TaskCalendar key={task.id} taskId={task.id} />
      ))}
      {!!otherTasks.length && (
        <>
          <div className={`more-calendar-items ${!isToggledPanel ? 'opened' : ''}`}>
            {otherTasks.map((task) => (
              <TaskCalendar key={task.id} taskId={task.id} />
            ))}
            <button className="btn btn-sm red-bg f-1 mx-0 my-1 jcc hide_more_events w100" onClick={handleTogglePanel}>
              CLOSE
            </button>
          </div>
          <button
            className="btn btn-sm f-1 mx-0 my-1 jcc show_more_events w100"
            onClick={handleTogglePanel}
          >{`+${otherTasks.length} MORE`}</button>
        </>
      )}
    </>
  ) : (
    <></>
  );
};
