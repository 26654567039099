import React from 'react';
import { SaveNewPasswordForm } from '../common/forms/SaveNewPasswordForm';
import { SubmitButton } from '../common/forms/SubmitButton';

export const ForgotPasswordPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '100vh',
        flexDirection: 'column',
      }}
    >
      <div style={{ backgroundColor: 'white', borderRadius: '10px', minWidth: '250px' }}>
        <h3 style={{ fontSize: '23px', paddingBottom: '20px', paddingTop: '10px' }}>Enter new password</h3>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <SaveNewPasswordForm />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }}>
          <SubmitButton formName="saveNewPassword-form" text="Send" />
        </div>
      </div>
    </div>
  );
};
