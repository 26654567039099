import React from 'react';
import { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createLabel, updateLabel } from '../../../../../app/page/page-storage/labels/labels.actions';
import { ILabel, ILabelCreateForm } from '../../../../../app/resource/label/label.types';

import { useRequiredValidation } from '../../../../../utils/form/validation.utils';

import { SimpleColorInput } from '../../inputs/SimpleColorInput';
import { FormSmallTextInput } from '../../inputs/SmallTextInput';

import { ILabelFormProps } from './types';

export const LabelForm: FunctionComponent<ILabelFormProps> = ({ labelData, boardId, onClick }) => {
  const dispatch = useDispatch();

  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: ILabelCreateForm, labelId?: ILabel['id']) => {
    onClick(true);

    if (labelId && labelData) {
      dispatch(updateLabel(labelId, { ...labelData, ...data }));
    } else {
      dispatch(createLabel({ ...data, boardId }));
    }
  };

  return (
    <FormProvider {...formProps}>
      <form
        id="label-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as ILabelCreateForm, labelData?.id))}
        method="post"
      >
        <div className="edit-label-input">
          <FormSmallTextInput
            id="name"
            name="name"
            type="text"
            placeholder="Enter Name"
            defaultValue={labelData ? labelData.name : ''}
            validation={requiredValidation}
          />
        </div>
        <button type="submit" className="btn btn-sm save_label">
          Save
        </button>
        <div className="color-palette flex aic">
          <SimpleColorInput id="color" name="color" defaultValue={labelData ? labelData.color : undefined} />
        </div>
      </form>
    </FormProvider>
  );
};
