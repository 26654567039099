/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { initialMeetingDashboardPage } from '../../../app/page/meeting-dashboard/meeting-dashboard.actions';
import { pageStateSelectors } from '../../../app/page/page-state/page-state.selectors';
import { IMeeting } from '../../../app/resource/meeting/meeting.types';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { PageLayout } from '../common/PageLayout';
import { WidgetWrapper } from '../common/widgets/WidgetWrapper';
import { MeetingDashboardNavbar } from '../common/navigation-bars/MeetigDashboardNavbar';
import { MeetingManagement } from './MeetingManagement';
import { MeetingParticipants } from './MeetingParticipants';

import { openDialog } from '../../../app/dialog/dialog.actions';
import { WidgetKpi } from '../common/widgets/widgets-kpi/WidgetKpi';
import { WidgetFullCalendar } from '../common/widgets/WidgetFullCalendar';
import { BoardsPage } from '../BoardsPage';
import { generateBoardId } from '../../../app/page/page-storage/tasks/tasks.saga-services';
import { WidgetTasksStandardView } from '../common/widgets/widget-generated-boards/WidgetTasksStandardView';
import { clearCurrentAgenda } from '../../../app/page/page-storage/agenda-items/agenda-items.actions';
import { TaskFormTrigger } from '../../dialogs/TaskFormDialog/TaskFormTrigger';
import { meetingSelectors } from '../../../app/resource/meeting/meeting.selectors';
import { agendaItemSelectors } from '../../../app/resource/agenda-item/agenda-item.selectors';
import { IAgendaItem } from '../../../app/resource/agenda-item/agenda-item.types';

export const MeetingDashboardPage = () => {
  const dispatch = useDispatch();
  const { meetingId } = useParams<{ meetingId: IMeeting['id'] }>();

  const showLoader = useSelectWithParams(pageStateSelectors.selectPageInProgress, 'meetingDashboard');

  const history = useHistory();
  const goToScoreSettings = useCallback(() => history.push(`/kpi/${meetingId}`), [history, meetingId]);

  const openScoreFormDialog = useCallback(() => {
    dispatch(
      openDialog('scoreFormDialog', {
        meetingId,
      }),
    );
  }, [dispatch, meetingId]);
  useEffect(() => {
    dispatch(clearCurrentAgenda());
  }, [dispatch]);

  useEffect(() => {
    dispatch(initialMeetingDashboardPage(meetingId));
    //dispatch(chooseClient(meetingId, true));
  }, [dispatch, meetingId]);

  const groupNonDefaultByUser = useMemo(() => generateBoardId(meetingId, 'groupNonDefaultByUser'), [meetingId]);

  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId) as IMeeting;
  const agendaItems = useSelectWithParams(
    agendaItemSelectors.selectResourcesById,
    meeting ? meeting.agendaItemIds : [],
  ) as IAgendaItem[];

  const [qi, setQi] = useState(false);
  const [kpi, setKpi] = useState(false);

  const [todo, setTodo] = useState(false);
  useEffect(() => {
    if (agendaItems) {
      const kpiItem = agendaItems.filter((ai) => ai && ai.agendaId === '2');
      const qiItem = agendaItems.filter((ai) => ai && ai.agendaId === '3');
      const todoItem = agendaItems.filter((ai) => ai && ai.agendaId === '5');
      if (kpiItem[0]) {
        if (kpiItem[0].checked === '1') {
          setKpi(true);
        } else {
          setKpi(false);
        }
      }
      if (qiItem[0]) {
        if (qiItem[0].checked === '1') {
          setQi(true);
        } else {
          setQi(false);
        }
      }
      if (todoItem[0]) {
        if (todoItem[0].checked === '1') {
          setTodo(true);
        } else {
          setTodo(false);
        }
      }
    }
  }, [agendaItems]);

  const pathname = window.location.href;
  if (pathname.endsWith('#kpi')) {
    const kpiElement = document.getElementById('kpi');
    if (kpiElement) {
      kpiElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <PageLayout
      showLoader={showLoader}
      smallSidebar
      externalLogicNavbar={<MeetingDashboardNavbar showLoader={showLoader} meetingId={meetingId} />}
    >
      {!showLoader ? (
        <>
          <div className="row mb-25">
            <div className="col-6">
              <MeetingManagement meetingId={meetingId} />
            </div>
            <div className="col-6">
              <MeetingParticipants meetingId={meetingId} />
            </div>
          </div>

          {/* <BoardWidget complexBoardId={generateBoardId(meetingId, 'groupNonDefaultByUser')} meetingId={meetingId} /> */}

          {todo && <WidgetTasksStandardView complexBoardId={groupNonDefaultByUser} />}

          <BoardsPage meetingId={meetingId} />

          {kpi && (
            <div id="kpi">
              <WidgetWrapper
                name="KPI's"
                actionElement={
                  <div className="ml-auto flex aic jcr">
                    <a
                      className="btn btn-md green-bg popup_button"
                      data-popup="new_score_popup"
                      title="Add Score"
                      onClick={openScoreFormDialog}
                    >
                      <i className="icon-plus mr-1"></i> Add Score
                    </a>
                    <a
                      className="btn btn-md btn-border white-bg"
                      title="Scorecard Settings"
                      onClick={goToScoreSettings}
                    >
                      <i className="icon-settings mr-1 f-0 blueGray"></i> Scorecard Settings
                    </a>
                    <a className="btn btn-md btn-border white-bg" title="Previous Quarter">
                      <i className="icon-left-arrow icon-rotated mr-1 f-0 blueGray"></i>
                      Previous Quarter
                    </a>
                    {/* <a
                    className="btn btn-md btn-border white-bg popup_button"
                    data-popup="reorder_users_popup"
                    title="Reorder Users"
                  >
                    <i className="icon-users mr-1 f-0 blueGray"></i>Reorder Users
                  </a> */}
                    <div className="toggle-buttons flex aic">
                      <a className="btn white-bg btn-md btn-border blueGray m-0 selected" title="">
                        Show
                      </a>
                      <a className="btn white-bg btn-md btn-border blueGray m-0" title="">
                        Hide
                      </a>
                    </div>
                  </div>
                }
              >
                <WidgetKpi />
              </WidgetWrapper>
            </div>
          )}
          {qi && (
            <WidgetTasksStandardView
              complexBoardId={generateBoardId(meetingId, 'groupMeetingRocksByUser')}
              actionElement={<TaskFormTrigger type={'1'} meetingId={meetingId} />}
            />
          )}
          <WidgetFullCalendar meetingId={meetingId} />
        </>
      ) : (
        <></>
      )}
    </PageLayout>
  );
};
