/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import { authSelectors } from '../../../app/auth/auth.selectors';

import { boardsPageSelectors } from '../../../app/page/page-storage/boards/boards.selectors';
import { meetingSelectors } from '../../../app/resource/meeting/meeting.selectors';

import { useSelect, useSelectWithParams } from '../../../utils/selector.utils';

import { Board } from './Board';
import { Boards } from './Boards';
import { IBoardsPageProps } from './types';

export const BoardsPage: FunctionComponent<IBoardsPageProps> = ({ meetingId }) => {
  const boardsIds = useSelect(boardsPageSelectors.selectBoards);
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);
  const userId = useSelect(authSelectors.selectLoggedUser);

  const admin = meeting?.administratorIds.includes(userId ? userId : '');

  const currentBoardId = useSelect(boardsPageSelectors.selectCurrentBoard);
  return (
    <div className="row mb-25 mt-3">
      <div className="col-12">
        <div className="panel vto-text">
          <div className="panel-header small-header no-border pb-0 mb-2">
            <h2 className="bold mr-3">Boards</h2>
          </div>
          {admin !== undefined && <Boards boardsIds={boardsIds} isAdmin={admin} />}
          {currentBoardId && <Board boardId={currentBoardId} boardsIds={boardsIds} meetingId={meetingId} />}
        </div>
      </div>
    </div>
  );
};
