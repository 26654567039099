import { put, select } from '@redux-saga/core/effects';
import { storeBoard } from '../../../../resource/boards/board/board.actions';
import { boardSelectors } from '../../../../resource/boards/board/board.selectors';
import { IBoard } from '../../../../resource/boards/board/board.types';
import { storeBoardList } from '../../../../resource/boards/boardList/boardList.actions';
import { IBoardList } from '../../../../resource/boards/boardList/boardList.types';
import { IMeeting } from '../../../../resource/meeting/meeting.types';
import { meetingsPageSelectors } from '../../meetings/meetings.selectors';
import {
  convertTempBoardListToBoarList,
  generateBoardId,
  generateBoardListId,
  TStatus,
} from '../../tasks/tasks.saga-services';

export function* groupNonDefaultByTypeFromRealBoardListAndStore(realBoardList: IBoardList) {
  const meetingId = (yield select(meetingsPageSelectors.selectCurrentMeeting)) as IMeeting['id'];

  const newBoardLists = groupNonDefaultByTypeFromRealBoardList(meetingId, realBoardList);
  yield put(storeBoardList(newBoardLists));

  const board = (yield select(
    boardSelectors.selectResourceById,
    generateBoardId(meetingId, 'groupByNonDefaultTypes'),
  )) as IBoard;
  yield put(
    storeBoard({ ...board, taskTypeIds: [...board.taskTypeIds, ...newBoardLists.map((baordList) => baordList.id)] }),
  );
}

export function groupNonDefaultByTypeFromRealBoardList(
  meetingId: IMeeting['id'],
  realBoardList: IBoardList,
  statuses: TStatus[] = ['active', 'done'],
): IBoardList[] {
  return statuses.map((status) =>
    convertTempBoardListToBoarList({
      id: generateBoardListId(meetingId, 'groupByNonDefaultTypes', { taskTypeId: realBoardList.id }, status),
      title: ``, // TODO: typeId to title
      taskIds: [],
      boardId: realBoardList.boardId,
    }),
  );
}
