import { createActionConst } from '../../../../utils/action.utils';

const SESSIONS_NAMESPACE = 'SESSIONS';

export const FETCH_SESSIONS = createActionConst(SESSIONS_NAMESPACE, 'FETCH_SESSIONS');
export const CREATE_SESSION = createActionConst(SESSIONS_NAMESPACE, 'CREATE_SESSION');
export const UPDATE_SESSION = createActionConst(SESSIONS_NAMESPACE, 'UPDATE_SESSION');
export const DELETE_SESSION = createActionConst(SESSIONS_NAMESPACE, 'DELETE_SESSION');

export const STORE_SESSION_IDS = createActionConst(SESSIONS_NAMESPACE, 'STORE_SESSION_IDS');
export const CLEAR_SESSION_IDS = createActionConst(SESSIONS_NAMESPACE, 'CLEAR_SESSION_IDS');

export const STORE_NEXT_SESSION = createActionConst(SESSIONS_NAMESPACE, 'STORE_NEXT_SESSION');
export const CLEAR_NEXT_SESSION = createActionConst(SESSIONS_NAMESPACE, 'CLEAR_NEXT_SESSION');
