/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { ActivityForm } from '../../../../../../../common/forms/ActivityForm';
import { Activity } from './Activity';
import { IActivitiesProps } from './types';

export const Activities: FunctionComponent<IActivitiesProps> = ({ activitiesIds, taskId }) => {
  return (
    <div className="row mt-2">
      <div className="col-12">
        <h4 className="bold">Activity</h4>
        <ActivityForm taskId={taskId} />
        <div className="activity-log">
          {activitiesIds.map((activityId) => (
            <Activity activityId={activityId} key={`activityId-${activityId}`} />
          ))}
        </div>
      </div>
    </div>
  );
};
