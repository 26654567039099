import { put, select, call, all } from 'redux-saga/effects';
import * as types from './column.types';
import * as actions from './column.actions';

//import * as api from '../../api/request-config/column.api';
import { columnSelectors } from './column.selectors';
import { IQuestionResponse } from '../question/question.types';
import { setupQuestions } from '../question/question.saga-service';
import { IColumnResponse } from './column.types';

export function* storeColumn(column: types.IColumn) {
  yield put(actions.storeColumn(column));

  return column;
}

export function* clearColumn(columnId: types.IColumn['id']) {
  yield put(actions.clearColumn(columnId));
}

export function* clearAllColumns() {
  const columnIds: types.IColumn['id'][] = yield select(columnSelectors.selectResourceIds);
  yield put(actions.clearColumn(columnIds));
}

export function* setupColumns(columns: types.IColumnResponse[]) {
  const questions = columns.reduce((prev, curr) => [...prev, ...curr.questions], [] as IQuestionResponse[]);
  const children = columns.reduce((prev, curr) => [...prev, ...curr.children], [] as IColumnResponse[]);
  yield call(setupQuestions, questions);
  yield call(setupChildren, children);

  yield all(
    columns.map((column) =>
      call(storeColumn, {
        id: column.id,
        title: column.title,
        image: column.image,
        default: column.default,
        subtitle: column.subtitle,
        colorClass: column.colorClass,
        columnLength: column.columnLength,
        columnType: column.columnType,
        showArraw: column.showArraw,
        showAnswer: column.showAnswer,
        answer: column.answer,
        description: column.description,
        atItemId: column.atItemId,
        parentId: column.parentId,
        childrenIds: column.children.map((child) => child.id),
        questionIds: column.questions.map((question) => question.id),
      }),
    ),
  );
}

export function* setupChildren(children: types.IColumnResponse[]) {
  const questions = children.reduce((prev, curr) => [...prev, ...curr.questions], [] as IQuestionResponse[]);
  //const children = children.reduce((prev, curr) => [...prev, ...curr.children], [] as IColumnResponse[]);
  yield call(setupQuestions, questions);

  yield all(
    children.map((column) =>
      call(storeColumn, {
        id: column.id,
        title: column.title,
        image: column.image,
        default: column.default,
        subtitle: column.subtitle,
        colorClass: column.colorClass,
        columnLength: column.columnLength,
        columnType: column.columnType,
        showArraw: column.showArraw,
        showAnswer: column.showAnswer,
        answer: column.answer,
        description: column.description,
        atItemId: column.atItemId,
        parentId: column.parentId,
        childrenIds: [],
        questionIds: column.questions.map((question) => question.id),
      }),
    ),
  );
}

// export function* searchColumns() {
//   const Columns: types.IColumn[] = yield call(apiRequest, api.searchColumnsApi());

//   yield all(Columns.map((Column) => call(storeColumn, Column)));
//   return Columns.map((Column) => Column.id);
// }

// export function* createColumn(data: types.IColumnCreate) {
//   const Column = (yield call(apiRequest, api.createColumnApi(data))) as types.IColumn;
//   if (!Column) {
//     return;
//   }
//   yield call(storeColumn, Column);
//   return Column;
// }

// export function* updateColumn(data: types.IColumnUpdate) {
//   const Column = (yield call(apiRequest, api.updateColumnApi(data))) as types.IColumn;
//   if (!Column) {
//     return;
//   }
//   Column.id = data.id;
//   yield call(storeColumn, Column);
//   return Column;
// }

// export function* deleteColumn(ColumnId: types.IColumn['id']) {
//   yield call(apiRequest, api.deleteColumnApi(ColumnId));
// }
