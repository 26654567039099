import * as types from './project-tasks.types';
import * as constants from './project-tasks.constants';
import { IProjectTask, IProjectTaskCreateForm } from '../../../resource/project-task/project-task.types';

export const createProjectTask = (projectTask: IProjectTaskCreateForm): types.ICreateProjectTask => ({
  type: constants.CREATE_PROJECT_TASK,
  payload: projectTask,
});

export const updateProjectTask = (
  projectTaskId: IProjectTask['id'],
  projectTask: IProjectTaskCreateForm,
): types.IUpdateProjectTask => ({
  type: constants.UPDATE_PROJECT_TASK,
  payload: {
    id: projectTaskId,
    data: projectTask,
  },
});

export const deleteProjectTask = (projectTaskId: IProjectTask['id']): types.IDeleteProjectTask => ({
  type: constants.DELETE_PROJECT_TASK,
  payload: projectTaskId,
});

export const storeProjectTasks = (projectTasks: IProjectTask['id'][]): types.IStoreProjectTask => ({
  type: constants.STORE_PROJECT_TASK,
  payload: projectTasks,
});

export const clearProjectTask = (): types.IClearProjectTask => ({
  type: constants.CLEAR_PROJECT_TASK,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
