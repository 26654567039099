import React, { FunctionComponent } from 'react';
import { AgendaItemsDialog } from './AgendaItemsDialog';

import { ConfirmationDialog } from './ConfirmationDialog';
import { DeleteDialog } from './DeleteDialog';
import { MeetingFormDialog } from './MeetingFormDialog';
import { ScoreFormDialog } from './ScoreFormDialog';
import { SectionFormDialog } from './SectionFormDialog';
import { TaskFormDialog } from './TaskFormDialog';
import { UserFormDialog } from './UserFormDialog';
import { NodeFormDialog } from './NodeFormDialog';
import { BoardFormDialog } from './BoardsDialogs/BoardFormDialog';
import { BoardListFormDialog } from './BoardsDialogs/BoardListFormDialog';
import { TrelloCardFormDialog } from './BoardsDialogs/TrelloCardFormDialog';
import { TrelloReplacementFormDialog } from './BoardsDialogs/TrelloReplacementFormDialog';
import { LabelFormDialog } from './LabelFormDialog';
import { SignUpFormDialog } from './SignUpFormDialog';
import { UserGroupFormDialog } from './UserGroupFormDialog';
import { PasswordGroupFormDialog } from './PasswordGroupFormDialog';
import { PasswordFormDialog } from './PasswordFormDialog';
import { ProjectFormDialog } from './ProjectFormDialog';
import { KpiNoteDialog } from './KpiNoteDialog';
import { AgendaFormDialog } from './AgendaFormDialog';
import { AgendaCustomFormDialog } from './AgendaCustomFormDialog';
import { WidgetsDialog } from './WidgetsDialog';
import { ForgotPasswordDialog } from './ForgotPasswordDialog';
import { QuartalFormDialog } from './QuartalFormDialog';
import { AgencyDescriptionDialog } from './AgencyDescriptionDialog';
import { ActionItemDialog } from './ActionItemDialog';
import { AIPreviewDialog } from './AIPreviewDialog';

export const Dialogs: FunctionComponent = () => (
  <>
    <ConfirmationDialog />
    <DeleteDialog />
    <MeetingFormDialog />
    <UserFormDialog />
    <TaskFormDialog />
    <AgendaItemsDialog />
    <ScoreFormDialog />
    <SectionFormDialog />
    <NodeFormDialog />
    <BoardFormDialog />
    <BoardListFormDialog />
    <TrelloCardFormDialog />
    <TrelloReplacementFormDialog />
    <LabelFormDialog />
    <SignUpFormDialog />
    <UserGroupFormDialog />
    <PasswordGroupFormDialog />
    <PasswordFormDialog />
    <ProjectFormDialog />
    <KpiNoteDialog />
    <AgendaFormDialog />
    <AgendaCustomFormDialog />
    <WidgetsDialog />
    <ForgotPasswordDialog />
    <QuartalFormDialog />
    <AgencyDescriptionDialog />
    <ActionItemDialog />
    <AIPreviewDialog />
  </>
);
