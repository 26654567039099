/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router';
import { meetingSelectors } from '../../../../../app/resource/meeting/meeting.selectors';
import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { IMeetingReviewProps } from './types';

export const MeetingReview: FunctionComponent<IMeetingReviewProps> = ({ meetingId }) => {
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);
  const history = useHistory();
  return (
    <li onClick={() => history.push(`/meeting-dashboard/${meeting?.id}#kpi`)} style={{ cursor: 'pointer' }}>
      <a className="drop-item">
        <Avatar
          className="img-fluid btn-badge f-1 mr-1 bold"
          title="Review KPIs"
          name={meeting?.title}
          alt="review KPIs"
          color={meeting?.color}
          size="26"
          round={true}
        />

        {meeting?.title}
      </a>
    </li>
  );
};
