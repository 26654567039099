import { put, select, call, all } from 'redux-saga/effects';
import * as types from './account-project.types';
import * as actions from './account-project.actions';

import * as api from '../../api/request-config/account-project.api';
import { accountProjectSelectors } from './account-project.selectors';
import { IProjectReport } from '../project-report/project-report.types';
import { storeProjectReport } from '../project-report/project-report.saga-service';
import { apiRequest } from '../../api/api.saga-services';

export function* storeAccountProject(accountProject: types.IAccountProject) {
  yield put(actions.storeAccountProject(accountProject));
  return accountProject;
}

export function* clearAccountProject(accountProjectId: types.IAccountProject['id']) {
  yield put(actions.clearAccountProject(accountProjectId));
}

export function* clearAllAccountProjects() {
  const accountProjectsIds: types.IAccountProject['id'][] = yield select(accountProjectSelectors.selectResourceIds);
  yield put(actions.clearAccountProject(accountProjectsIds));
}

export function* searchAccountProjects(accountId?: types.IAccountProject['id']) {
  const accountProjects: types.IAccountProjectResponse[] = yield call(
    apiRequest,
    api.searchAccountProjectsApi(accountId),
  );

  const projectReports = accountProjects.reduce((prev, curr) => [...prev, ...curr.projects], [] as IProjectReport[]);

  yield all(projectReports.map((projectReport) => call(storeProjectReport, projectReport)));
  yield all(
    accountProjects.map((accountProject) =>
      call(storeAccountProject, {
        id: accountProject.accountId,
        accountId: accountProject.accountId,
        projectReportIds: accountProject.projects.map((projectReport) => projectReport.id),
      }),
    ),
  );

  return accountProjects.map((accountProject) => accountProject.accountId);
}
/*
export function* createAccountProject(data: types.IAccountProjectCreate) {
  const accountProject = (yield call(apiRequest, api.createAccountProjectApi(data))) as types.IAccountProject;
  if (!accountProject) {
    return;
  }
  return accountProject;
}

export function* updateAccountProject(data: types.IAccountProjectUpdate) {
  const accountProject = (yield call(apiRequest, api.updateAccountProjectApi(data))) as types.IAccountProject;
  if (!accountProject) {
    return;
  }
  accountProject.id = data.id;
  yield call(storeAccountProject, accountProject);
  return accountProject;
}

export function* deleteAccountProject(AccountProjectId: types.IAccountProject['id']) {
  yield call(apiRequest, api.deleteAccountProjectApi(AccountProjectId));
}
*/
