/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';

import { Link, useLocation } from 'react-router-dom';

import Logo from '../../../resources/images/white-logo.svg';
import SmallLogo from '../../../resources/images/small-logo.svg';
import cc from 'classcat';
import { MeetingManuItems } from './MeetingManuItems';
import { useSelect } from '../../../../utils/selector.utils';
import { meetingSelectors } from '../../../../app/resource/meeting/meeting.selectors';

export const SideBar = () => {
  const { pathname } = useLocation();

  const activePath = useMemo(
    () => [
      pathname.match(/\/home/),
      pathname.match(/\/vto/),
      pathname.match(/\/accounts/),
      pathname.match(/\/teams/),
      pathname.match(/\/directory/),
      pathname.match(/\/time-tracking/),
      pathname.match(/\/settings/),
      // pathname.match(/\/passwords/),
      // pathname.match(/\/team-meeting-dashboard\/?$/),
      // pathname.match(/\/team-meeting-kpi\/?$/),
      // pathname.match(/\/live-meeting\/?$/),
    ],
    [pathname],
  );

  const meetings = useSelect(meetingSelectors.selectResourceList);

  const teams = useMemo(() => meetings.filter((meeting) => meeting.type !== 'account'), [meetings]);
  const accounts = useMemo(() => meetings.filter((meeting) => meeting.type === 'account'), [meetings]);

  return (
    <aside>
      <Link to="/home" className="logo">
        <img src={Logo} alt="" className="img-fluid" />
      </Link>
      <Link to="/home" className="logo-small">
        <img src={SmallLogo} alt="" className="img-fluid" />
      </Link>
      <menu>
        <div className="menu-container">
          <ul className="main-menu">
            <li className={cc({ active: activePath[0] })}>
              <Link to="/home">
                <i className="icon-home"></i> <span>DASHBOARD</span>
              </Link>
            </li>
            <li className={cc({ active: activePath[1] })}>
              <Link to="/agency-tools">
                <i className="icon-briefcase"></i> <span>AGENCY TOOLS</span>
              </Link>
            </li>
            <li className={cc({ active: activePath[2] })}>
              <Link to="/accounts">
                <i className="icon-info"></i> <span>ACCOUNTS</span>
              </Link>
            </li>
            <li className={cc({ active: activePath[3] })}>
              <Link to="/teams">
                <i className="icon-users"></i> <span>TEAMS</span>
              </Link>
            </li>
            <li className={cc({ active: activePath[4] })}>
              <Link to="/directory">
                <i className="icon-user-full"></i> <span>DIRECTORY</span>
              </Link>
            </li>
            {/*
            <li className={cc({ active: activePath[5] })}>
              <Link to="/time-tracking">
                <i className="icon-clock"></i> <span>TIME TRACKING</span>
              </Link>
            </li>
            <li className={cc({ active: activePath[6] })}>
              <a>
                <i className="icon-settings"></i> <span>SETTINGS</span>
              </a>
            </li>*/}
          </ul>
          <hr />
          <MeetingManuItems type="account" meetings={accounts} />
          <hr />
          <MeetingManuItems type="meeting" meetings={teams} />
        </div>
      </menu>
    </aside>
  );
};
