import * as types from './password-groups.types';
import * as constants from './password-groups.constants';
import {
  IPasswordGroup,
  IPasswordGroupSave,
  IPasswordGroupSearchParams,
} from '../../../resource/password-group/password-group.types';

export const getPasswordGroups = (searchParams?: IPasswordGroupSearchParams): types.IGetPasswordGroups => ({
  type: constants.GET_PASSWORD_GROUPS,
  payload: searchParams,
});

export const savePasswordGroup = (passwordGroup: IPasswordGroupSave): types.ISavePasswordGroup => ({
  type: constants.SAVE_PASSWORD_GROUP,
  payload: passwordGroup,
});

export const storePasswordGroups = (passwordIds: IPasswordGroup['id'][]): types.IStorePasswordGroups => ({
  type: constants.STORE_PASSWORD_GROUPS,
  payload: passwordIds,
});

export const clearPasswordGroups = (): types.IClearPasswordGroups => ({
  type: constants.CLEAR_PASSWORD_GROUPS,
});
