/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { WidgetsDialogList } from '../../pages/common/widgets/WidgetsDialogList';

import { ConnectedDialog } from '../ConnectedDialog';
import { IWidgetsDialogProps } from './types';

export const WidgetsDialog: FunctionComponent<IWidgetsDialogProps> = () => {
  const dispatch = useDispatch();
  // const [updateShowLoader, updateError] = useApiRequestStatus();

  const onClose = useCallback(() => {
    dispatch(closeDialog('widgetsDialog'));
  }, [dispatch]);

  return (
    <ConnectedDialog dialogKey="widgetsDialog">
      <div className="popup sort_widgets_popup">
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            <h3>Show/Hide Widgets</h3>
            <span className="close-popup close" onClick={onClose}>
              <i className="icon-close" />
            </span>
          </div>
          <div className="panel-body p-0">
            <WidgetsDialogList />
          </div>
          <div className="panel-footer flex jcr">
            <a onClick={onClose} className="link blueGray mr-2 close">
              Close
            </a>
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
