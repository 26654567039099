import moment from 'moment';
import React, { FunctionComponent, useMemo } from 'react';

import { generateBoardListId } from '../../../../../../../../app/page/page-storage/tasks/tasks.saga-services';
import { boardListSelectors } from '../../../../../../../../app/resource/boards/boardList/boardList.selectors';
import { taskSelectors } from '../../../../../../../../app/resource/task/task.selectors';
import { ITask } from '../../../../../../../../app/resource/task/task.types';
import { getHoursForDate } from '../../../../../../../../utils/date.utils';
import { useSelectWithParams } from '../../../../../../../../utils/selector.utils';
import { CalendarItemHour } from './CalendarItemHour';
import { ICalendarDayViewProps } from './types';

export const CalendarDayView: FunctionComponent<ICalendarDayViewProps> = ({ meetingId, date, userFilter }) => {
  const boardList = useSelectWithParams(
    boardListSelectors.selectResourceById,
    generateBoardListId(meetingId, 'groupNonDefaultByDate', { date }),
  );

  const tasks = useSelectWithParams(taskSelectors.selectResourcesById, boardList ? boardList.tasksIds : []);
  const hours = useMemo(() => getHoursForDate(date), [date]);

  const tasksPerHour = useMemo(
    () =>
      hours.map(
        (startTime, i) =>
          tasks.filter((task) =>
            hours[i + 1]
              ? moment(task?.dueDate).isSameOrAfter(startTime) && moment(task?.dueDate).isBefore(hours[i + 1])
              : moment(task?.dueDate).isSameOrAfter(startTime),
          ) as ITask[],
      ),
    [hours, tasks],
  );

  return (
    <>
      {hours.map((hour, i) => (
        <div key={`hour-${hour}`} className="calendar-cell" style={{ width: 'auto' }}>
          <CalendarItemHour tasks={tasksPerHour[i]} userFilter={userFilter} />
        </div>
      ))}
    </>
  );
};
