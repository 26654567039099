import { all, call, fork, takeLatest } from 'redux-saga/effects';

import * as constants from './meetings-info.constants';
import * as types from './meetings-info.types';
import * as services from './meetings-info.saga-services';

function* invokeSetupMeetingsInfo(action: types.IFetchMeetingsInfo) {
  yield call(services.setupMeetingsInfo);
}

export function* watchMeetingsInfoPurePageSaga() {
  yield takeLatest(constants.FETCH_MEETINGS_INFO, invokeSetupMeetingsInfo);
}

export function* watchMeetingsInfoPageSaga() {
  yield all([watchMeetingsInfoPurePageSaga].map(fork));
}
