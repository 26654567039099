import { put, select, call, all } from 'redux-saga/effects';
import * as types from './project.types';
import * as actions from './project.actions';
import * as api from '../../api/request-config/project.api';
import { projectSelectors } from './project.selectors';
import { apiRequest } from '../../api/api.saga-services';

export function* storeProject(project: types.IProject) {
  yield put(actions.storeProject(project));

  return project;
}

export function* clearProject(projectId: types.IProject['id']) {
  yield put(actions.clearProject(projectId));
}

export function* clearAllProjects() {
  const projectsIds: types.IProject['id'][] = yield select(projectSelectors.selectResourceIds);
  yield put(actions.clearProject(projectsIds));
}

export function* searchProjects(accountId: string, projectId?: string) {
  const projects: types.IProject[] = yield call(apiRequest, api.searchProjectsApi(accountId, projectId));

  yield all(projects.map((project) => call(storeProject, project)));
  return projects.map((project) => project.id);
}

export function* createProject(data: types.IProjectCreate) {
  const project = (yield call(apiRequest, api.createProjectApi(data))) as types.IProject;

  if (!project) {
    return;
  }
  yield call(storeProject, project);
  return project;
}

export function* updateProject(data: types.IProjectUpdate) {
  const project = (yield call(apiRequest, api.updateProjectApi(data))) as types.IProject;

  if (!project) {
    return;
  }
  project.id = data.id;
  yield call(storeProject, project);
  return project;
}

export function* deleteProject(projectId: types.IProject['id']) {
  yield call(apiRequest, api.deleteProjectApi(projectId));
}
