/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';

import { IMeetingInfoParams } from './types';

export const MeetingInfo: FunctionComponent<IMeetingInfoParams> = ({ selectedMeeting }) => {
  return (
    selectedMeeting && (
      <span className={'badge'} style={{ backgroundColor: selectedMeeting.color }}>
        {selectedMeeting.title}
      </span>
    )
  );
};
