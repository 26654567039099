import { put, select, call, all } from 'redux-saga/effects';
import * as types from './category.types';
import * as actions from './category.actions';

import * as api from '../../api/request-config/category.api';
import { categorySelectors } from './category.selectors';
import { apiRequest } from '../../api/api.saga-services';
import { IItemResponse } from '../item/item.types';
import { setupItems } from '../item/item.saga-service';

export function* storeCategory(category: types.ICategory) {
  yield put(actions.storeCategory(category));

  return category;
}

export function* clearCategory(categoryId: types.ICategory['id']) {
  yield put(actions.clearCategory(categoryId));
}

export function* clearAllCategories() {
  const categoriesIds: types.ICategory['id'][] = yield select(categorySelectors.selectResourceIds);
  yield put(actions.clearCategory(categoriesIds));
}

export function* searchCategories() {
  const categories: types.ICategoryResponse[] = yield call(apiRequest, api.searchCategoriesApi());

  const items = categories.reduce((prev, curr) => [...prev, ...curr.items], [] as IItemResponse[]);
  yield call(setupItems, items);

  yield all(
    categories.map((category) =>
      call(storeCategory, {
        id: category.id,
        avatar: category.avatar,
        title: category.title,
        description: category.description,
        color: category.color,
        buttonTitle: category.buttonTitle,
        buttonAction: category.buttonAction,
        createdBy: category.createdBy,
        createdAt: category.createdAt,
        itemIds: category.items.map((item) => item.id),
      }),
    ),
  );

  return categories.map((category) => category.id);
}

export function* createCategory(data: types.ICategoryCreate) {
  const category = (yield call(apiRequest, api.createCategoryApi(data))) as types.ICategory;
  if (!category) {
    return;
  }
  yield call(storeCategory, category);
  return category;
}

export function* updateCategory(data: types.ICategoryUpdate) {
  const category = (yield call(apiRequest, api.updateCategoryApi(data))) as types.ICategory;
  if (!category) {
    return;
  }
  category.id = data.id;
  yield call(storeCategory, category);
  return category;
}

export function* deleteCategory(categoryId: types.ICategory['id']) {
  yield call(apiRequest, api.deleteCategoryApi(categoryId));
}
