import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './calendars.constants';
import * as types from './calendars.types';
import * as services from './calendars.saga-services';

function* invokeSetupCalendar(action: types.ISetupCalendar) {
  yield call(services.setupCalendar, action.payload?.from, action.payload?.scope);
}
function* invokeToggleCalendar(action: types.IToggleCalendar) {
  yield call(services.toggleCalendar, action.payload);
}
function* invokeSearchByDescription(action: types.ISearchByDescription) {
  yield call(services.searchByDescription, action.payload);
}

function* refreshCalendar(action: types.IRefreshCalendarsSocket) {
  yield call(services.refreshCalendar, action.payload.userId);
}

export function* watchCalendarsPageSaga() {
  yield takeLatest(constants.SETUP_CALENDAR, invokeSetupCalendar);
  yield takeLatest(constants.TOGGLE_CALENDAR, invokeToggleCalendar);
  yield takeLatest(constants.SEARCH_BY_DESCRIPTION, invokeSearchByDescription);
  yield takeLatest(constants.REFRESH_CALENDAR, refreshCalendar);
}
