import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { IProjectTaskUpdate } from '../../../../../../../app/resource/project-task/project-task.types';
import { ProjectTask } from './ProjectTask';
import { IProjectTasksFormProps } from './types';

export const ProjectTasksForm: FunctionComponent<IProjectTasksFormProps> = ({ projectTasks }) => {
  const { control, setError, clearErrors, errors } = useFormContext();
  const { fields, append, remove } = useFieldArray<IProjectTaskUpdate>({ control, name: 'tasks' });
  const [title, setTitle] = useState('');
  const [removeRender, setRemoveRender] = useState(false);

  const test = useWatch({
    control,
    name: 'tasks[0].billable', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  });
  useEffect(() => {
    console.log('Log', test);
  }, [test]);

  const addTask = useCallback(() => {
    append({ title: title, id: undefined });
    setTitle('');
    clearErrors('tasks');
  }, [append, clearErrors, title]);

  const removeTask = useCallback(
    (index) => {
      setRemoveRender(true);
      if (fields.length > 0) {
        remove(index);
      } else {
        setError('tasks', { type: 'minLength', message: 'You need to add at least one entry' });
      }
    },
    [fields.length, remove, setError],
  );

  useEffect(() => {
    if (projectTasks) {
      if (projectTasks.length > 0 && fields.length < projectTasks.length && !removeRender) {
        append(projectTasks);
      }
    }
  }, [append, fields.length, projectTasks, removeRender]);

  return (
    <>
      {fields.map(
        (field, index) =>
          field && (
            <div className="task-row" key={index}>
              <ProjectTask
                removeTask={removeTask}
                index={index}
                projectTask={field}

                // projectType={projectType}
                // focusHourlyTuple={focusHourlyTuple}
                // focusBudgetTuple={focusBudgetTuple}
              />
            </div>
          ),
      )}
      <div className="row mt-3 mb-2">
        <div className="col-6">
          <input
            id="title"
            // name={`tasks.${fields.length}.title`}
            type="text"
            className="medium-input"
            placeholder="Add a task..."
            onChange={(e) => setTitle(e.currentTarget.value)}
            value={title}
          />
        </div>
        <div className="col-3 pl-0">
          <button
            disabled={!title}
            type="button"
            className="btn text-center darkGray-bg white max70"
            title="Add"
            onClick={addTask}
          >
            Add
          </button>
        </div>
      </div>
      {!!errors.tasks?.message && (
        <div>
          <p>{errors.tasks.message}</p>
        </div>
      )}
    </>
  );
};
