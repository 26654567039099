import { all, call, put } from 'redux-saga/effects';
import moment from 'moment';
import * as types from './calendar-item.types';
import * as actions from './calendar-item.actions';

import * as eventService from '../event/event.saga-service';

import { IEvent, IEventSearchParams } from '../event/event.types';
import { select, selectWithParams } from '../../../utils/selector.utils';
import { calendarItemSelectors } from './calendar-item.selectors';
import { formatSearch, generateDatesForScope } from '../../../utils/date.utils';

export function* storeCalendarItem(calendarItem: types.ICalendarItem) {
  yield put(actions.storeCalendarItem(calendarItem));
  return calendarItem.id;
}

export function* clearCalendarItem(calendarItremId: types.ICalendarItem['id']) {
  yield put(actions.clearCalendarItem(calendarItremId));
}
export function* clearAllCalendarItems() {
  const calendarItemIds: types.ICalendarItem['id'][] = yield select(calendarItemSelectors.selectResourceIds);
  yield put(actions.clearCalendarItem(calendarItemIds));
}

export function* storeEventToCalendarItem(event: IEvent) {
  const date = moment(event.start).format('YYYYMMDD');

  const calendarItem = yield selectWithParams(calendarItemSelectors.selectResourceById, date);

  if (calendarItem && calendarItem.eventIds.includes(event.id)) {
    return;
  }

  yield call(
    storeCalendarItem,
    calendarItem
      ? {
          ...calendarItem,
          eventIds: [...calendarItem.eventIds, event.id],
        }
      : { ...generatEmptyCalendarItem(date), eventIds: [event.id] },
  );
}

export const generatEmptyCalendarItem = (date: string): types.ICalendarItem => ({
  id: date,
  date,
  eventIds: [],
  type: 'googleCalendar',
});

export function* getCalendarItems(from: string, scope: types.IScope) {
  const dates = generateDatesForScope(from, scope);
  const calendarItems: (types.ICalendarItem | null)[] = yield selectWithParams(
    calendarItemSelectors.selectResourcesById,
    dates,
  );

  calendarItems.forEach((calendarItem, i) => {
    if (!calendarItem) {
      calendarItems[i] = generatEmptyCalendarItem(dates[i]);
    }
  });

  const calendarItemIds = yield all(
    (calendarItems as types.ICalendarItem[]).map((calendarItem) => call(storeCalendarItem, calendarItem)),
  );

  const params = {} as IEventSearchParams;
  params[scope] = formatSearch(from);
  yield call(eventService.getEvents, params);
  return calendarItemIds;
}
