import { createActionConst } from '../../../../utils/action.utils';

const CHART_NAMESPACE = 'CHARTS';

export const CREATE_CHART = createActionConst(CHART_NAMESPACE, 'CREATE_CHART');
export const UPDATE_CHART = createActionConst(CHART_NAMESPACE, 'UPDATE_CHART');
export const DELETE_CHART = createActionConst(CHART_NAMESPACE, 'DELETE_CHART');

export const STORE_IS_FETCHED = createActionConst(CHART_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(CHART_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_CHART = createActionConst(CHART_NAMESPACE, 'STORE_CHART');
export const CLEAR_CHART = createActionConst(CHART_NAMESPACE, 'CLEAR_CHART');

export const SAVE_CHART_SOCKET = 'saveChart';
export const DELETE_CHART_SOCKET = 'deleteChart';
