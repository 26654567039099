/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../../../../../../../../app/dialog/dialog.actions';
import { deleteTask } from '../../../../../../../../../../app/page/page-storage/boardLists/boardLists.actions';

import { IDeleteTaskProps } from './types';

export const DeleteTask: FunctionComponent<IDeleteTaskProps> = ({ task }) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  const close = useCallback(() => setActive(false), [setActive]);
  const dispatch = useDispatch();
  const onDeleteTask = useCallback(() => {
    dispatch(deleteTask(task.id));
    dispatch(closeDialog('trelloReplacementFormDialog'));
  }, [dispatch, task.id]);
  return (
    <div className={isActive ? 'dropdown opened' : 'dropdown'}>
      <a
        className="btn w100 gray-bg darkGray px-1 f-1 ml-0 mb-1 mt-1 dropdown-button"
        title="Delete"
        onClick={toggleClass}
      >
        <i className="icon f-9 blueGray icon-upload mr-1" /> Delete
      </a>
      <div className="dropdown-menu drop-right drop-up max-h320">
        <div className="mini-popup">
          <div className="mini-popup-header">
            Delete card
            <span className="close_mini_popup" onClick={close}>
              ×
            </span>
          </div>
          <div className="mini-popup-body">
            <p className="ln mb-2 darkGray">Deleting a card is permanent and there is no way to get it back.</p>
            <a className="btn btn-md red-bg white d-block w100 jcc" onClick={onDeleteTask}>
              Delete card
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
