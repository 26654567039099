import { all, call, put } from 'redux-saga/effects';

import * as types from './meeting.types';
import * as actions from './meeting.actions';

import { IMeeting, IMeetingResponse } from './meeting.types';
import { IKpi } from '../kpi/kpi.types';
import { ITask } from '../task/task.types';
import { ISession } from '../session/session.types';
import { IScore } from '../score/score.types';
import { IKpiRowScoreboard } from '../kpi-row-scoreboard/kpi-row-scoreboard.types';
import { IKpiRow } from '../kpi-row/kpi-row.types';

import { IAgendaItem } from '../agenda-item/agenda-item.types';

import { select, selectWithParams } from '../../../utils/selector.utils';
import { meetingSelectors } from './meeting.selectors';

import { apiRequest } from '../../api/api.saga-services';

import * as meetingApi from '../../api/request-config/meeting.api';
import * as scoreApi from '../../api/request-config/score.api';

import * as taskService from '../task/task.saga-service';
import * as agendaService from '../agenda-item/agenda-item.saga-service';
import * as scoreService from '../score/score.saga-service';
import * as sessionService from '../session/session.saga-service';
import * as kpiService from '../kpi/kpi.saga-service';
import * as kpiRowService from '../kpi-row/kpi-row.saga-service';
import * as kpiRowScoreboardService from '../kpi-row-scoreboard/kpi-row-scoreboard.saga-service';
import { IAgenda } from '../agenda/agenda.types';

export function* storeMeeting(meeting: types.IMeeting) {
  yield put(actions.storeMeeting(meeting));
  return meeting;
}

export function* clearMeeting(meetingId: types.IMeeting['id']) {
  yield put(actions.clearMeeting(meetingId));
}

export function* clearAllMeetings() {
  const meetingIds = yield select(meetingSelectors.selectResourceIds);
  yield put(actions.clearMeeting(meetingIds));
}

/* This method sends api request and handle response */
export function* searchMeetings(searchParams?: types.IMeetingSearchParams) {
  const meetingResponse = (yield call(
    apiRequest,
    meetingApi.searchMeetingApi(searchParams),
  )) as types.IMeetingResponse[];
  if (!meetingResponse) {
    return;
  }

  const sessions = meetingResponse.reduce((prev, curr) => [...prev, ...curr.nextSessions], [] as ISession[]);
  yield all(sessions.map((session) => call(sessionService.storeSession, session)));

  const agendaItems = meetingResponse.reduce((prev, curr) => [...prev, ...curr.agendaItems], [] as IAgenda[]);

  yield all(agendaItems.map((agendaItem) => call(agendaService.storeAgendaItem, agendaItem)));

  const meetings: IMeeting[] = meetingResponse.map((meeting) => meetingResponseToMeeting(meeting));
  yield all(meetings.map((meeting) => call(storeMeeting, meeting)));
  return meetings;
}

/* This method sends api request and handle response */
export function* createMeeting(meeting: types.IMeetingCreate) {
  const meetingResponse = (yield call(apiRequest, meetingApi.createMeetingApi(meeting))) as types.IMeetingResponse;
  if (!meetingResponse) {
    return;
  }

  yield all(meetingResponse.nextSessions.map((session) => call(sessionService.storeSession, session)));
  yield all(meetingResponse.agendaItems.map((agendaItem) => call(agendaService.storeAgendaItem, agendaItem)));

  const createdMeeting = meetingResponseToMeeting(meetingResponse);
  yield call(storeMeeting, createdMeeting);
  return createdMeeting;
}

/* This method sends api request and handle response */
export function* updateMeeting(meeting: types.IMeetingUpdate) {
  const meetingResponse = (yield call(apiRequest, meetingApi.updateMeetingApi(meeting))) as types.IMeetingResponse;
  if (!meetingResponse) {
    return;
  }

  yield all(meetingResponse.nextSessions.map((session) => call(sessionService.storeSession, session)));
  yield all(meetingResponse.agendaItems.map((agendaItem) => call(agendaService.storeAgendaItem, agendaItem)));

  const updatedMeeting = meetingResponseToMeeting(meetingResponse);
  yield call(storeMeeting, updatedMeeting);
  return updatedMeeting;
}

export function* deleteMeeting(meetingId: types.IMeeting['id']) {
  yield call(clearMeeting, meetingId);
  yield call(apiRequest, meetingApi.deleteMeetingApi(meetingId));
}

export function* finishMeetingSession(meetingId: types.IMeeting['id'], sessionId: ISession['id']) {
  yield call(apiRequest, meetingApi.finishMeetingSessionApi({ id: meetingId, sessionId }));
}

export function* populateTasks(meetingId: IMeeting['id']) {
  let meeting = (yield selectWithParams(meetingSelectors.selectResourceById, meetingId)) as IMeeting;

  const taskIds: ITask['id'][] = yield call(taskService.searchTasks, { id: meetingId }); // ALO

  if (!taskIds) {
    return;
  }
  meeting = yield call(storeMeeting, { ...meeting, taskIds });

  return meeting;
}

export function* setTasks(meetingId: IMeeting['id'], taskIds: ITask['id'][]) {
  const meeting = (yield selectWithParams(meetingSelectors.selectResourceById, meetingId)) as IMeeting;

  if (!meeting) {
    return;
  }
  yield call(storeMeeting, { ...meeting, taskIds });

  return meeting;
}

export function* populateKpi(meetingId: IMeeting['id']) {
  let meeting = (yield selectWithParams(meetingSelectors.selectResourceById, meetingId)) as IMeeting;

  if (!meeting.kpiId) {
    const { scores, sessions, scoreboards } = (yield call(apiRequest, scoreApi.getScoreBoardApi({ meetingId }))) as {
      scores: IScore[];
      sessions: ISession[];
      scoreboards: {
        [sessionId: string]: IKpiRowScoreboard[];
      };
    };

    meeting.sessionIds = sessions.map((session) => session.id);

    yield all(scores.map((score) => call(scoreService.storeScore, score)));
    yield all(sessions.map((session) => call(sessionService.storeSession, session)));

    const kpiRowScoreboards: IKpiRowScoreboard[] = [];
    const kpiRows: IKpiRow[] = [];

    const kpi = {
      id: meetingId,
      meetingId,
      sessionIds: sessions.map((session) => session.id),
      kpiRowIds: scores.map((score) => {
        const kpiRow = {
          id: score.id,
          scoreId: score.id,
          userId: score.userId,
          kpiRowScoreboardIds: sessions.map((session) => {
            let scoreboard = scoreboards[session.id].find(
              (scoreboard) => scoreboard.sessionId === session.id && scoreboard.scoresId === score.id,
            );
            if (!scoreboard) {
              scoreboard = {
                id: `${score.id}/${session.id}`,
                sessionId: session.id,
                scoresId: score.id,
                measured: '',
                note: '',
              };
            }
            kpiRowScoreboards.push(scoreboard);
            return scoreboard.id;
          }),
        };
        kpiRows.push(kpiRow);
        return kpiRow.id;
      }),
    };

    yield all(
      kpiRowScoreboards.map((kpiRowScoreboard) =>
        call(kpiRowScoreboardService.storeKpiRowScoreboard, kpiRowScoreboard),
      ),
    );
    yield all(kpiRows.map((kpiRow) => call(kpiRowService.storeKpiRow, kpiRow)));
    yield call(kpiService.storeKpi, kpi);
    meeting = yield call(storeMeeting, { ...meeting, kpiId: kpi.id });
  }

  return meeting;
}

export function* setKpi(meetingId: IMeeting['id'], kpiId: IKpi['id']) {
  const meeting = (yield selectWithParams(meetingSelectors.selectResourceById, meetingId)) as IMeeting;

  if (!meeting) {
    return;
  }
  yield call(storeMeeting, { ...meeting, kpiId }); // todo

  return meeting;
}

export function* populateAgendaItems(meetingId: IMeeting['id']) {
  let meeting = (yield selectWithParams(meetingSelectors.selectResourceById, meetingId)) as IMeeting;

  if (!meeting.agendaItemIds) {
    const agendaItems = (yield call(agendaService.searchAgendaItems, { id: meetingId })) as IAgendaItem[];
    meeting = yield call(storeMeeting, { ...meeting, agendaItemIds: agendaItems.map((agenda) => agenda.id) });
  }

  return meeting;
}

export function* setAgendaItems(meetingId: IMeeting['id'], agendaItemIds: IAgendaItem['id'][]) {
  const meeting = (yield selectWithParams(meetingSelectors.selectResourceById, meetingId)) as IMeeting;

  if (!meeting) {
    return;
  }
  yield call(storeMeeting, { ...meeting, agendaItemIds });

  return meeting;
}

export const meetingResponseToMeeting = (meetingResponse: IMeetingResponse): IMeeting => ({
  id: meetingResponse.id,
  title: meetingResponse.title,
  description: meetingResponse.description,
  startDate: meetingResponse.startDate,
  endDate: meetingResponse.endDate,
  startTime: meetingResponse.startTime,
  endTime: meetingResponse.endTime,
  duration: meetingResponse.duration,
  createdBy: meetingResponse.createdBy,
  googleDriveId: meetingResponse.googleDriveId,
  calendarSocialId: meetingResponse.calendarSocialId,
  calendarEventId: meetingResponse.calendarEventId,
  location: meetingResponse.location,
  sharepointUrl: meetingResponse.sharepointUrl,
  facebookGroupUrl: meetingResponse.facebookGroupUrl,
  fileDriveUrl: meetingResponse.fileDriveUrl,
  labels: meetingResponse.labels,
  shareWithComment: meetingResponse.shareWithComment,
  deleted: meetingResponse.deleted,
  color: meetingResponse.color,
  recurring: meetingResponse.recurring,
  repeatCheckbox: meetingResponse.repeatCheckbox,
  repeatEvery: meetingResponse.repeatEvery,
  repeatType: meetingResponse.repeatType,
  noOfCycles: meetingResponse.noOfCycles,
  lastStartDate: meetingResponse.lastStartDate,
  recurringDates: meetingResponse.recurringDates,
  confirmedBy: meetingResponse.confirmedBy,
  rejectedBy: meetingResponse.rejectedBy,
  image: meetingResponse.image,
  ytCode: meetingResponse.ytCode,
  type: meetingResponse.type,
  shareWithClientIds: meetingResponse.shareWithClients,
  administratorIds: meetingResponse.administrators,
  shareWithIds: meetingResponse.shareWith,
  nextSessionIds: meetingResponse.nextSessions.map((nextSession) => nextSession.id),
  clientId: meetingResponse.clientId,
  taskIds: meetingResponse.tasks ? meetingResponse.tasks.map((task) => task.id) : null,
  agendaItemIds: meetingResponse.agendaItems.map((agendaItem) => agendaItem.id),
  kpiId: null,
  sessionIds: null,
});
