import { put, select, call, all } from 'redux-saga/effects';
import * as types from './item.types';
import * as actions from './item.actions';

//import * as api from '../../api/request-config/item.api';
import { itemSelectors } from './item.selectors';
import { setupColumns } from '../column/column.saga-service';
import { IColumnResponse } from '../column/column.types';

export function* storeItem(item: types.IItem) {
  yield put(actions.storeItem(item));

  return item;
}

export function* clearItem(itemId: types.IItem['id']) {
  yield put(actions.clearItem(itemId));
}

export function* clearAllItems() {
  const itemIds: types.IItem['id'][] = yield select(itemSelectors.selectResourceIds);
  yield put(actions.clearItem(itemIds));
}

export function* setupItems(items: types.IItemResponse[]) {
  const columns = items.reduce((prev, curr) => [...prev, ...curr.columns], [] as IColumnResponse[]);
  yield call(setupColumns, columns);

  yield all(
    items.map((item) =>
      call(storeItem, {
        id: item.id,
        title: item.title,
        url: item.url,
        description: item.description,
        recap: item.recap,
        showSaveButton: item.showSaveButton,
        atCategoryId: item.atCategoryId,
        default: item.default,
        columnIds: item.columns.map((column) => column.id),
      }),
    ),
  );
}
// export function* searchItems() {
//   const items: types.IItem[] = yield call(apiRequest, api.searchItemsApi());

//   yield all(items.map((item) => call(storeItem, item)));
//   return items.map((item) => item.id);
// }

// export function* createItem(data: types.IItemCreate) {
//   const item = (yield call(apiRequest, api.createItemApi(data))) as types.IItem;
//   if (!item) {
//     return;
//   }
//   yield call(storeItem, item);
//   return item;
// }

// export function* updateItem(data: types.IItemUpdate) {
//   const item = (yield call(apiRequest, api.updateItemApi(data))) as types.IItem;
//   if (!item) {
//     return;
//   }
//   item.id = data.id;
//   yield call(storeItem, item);
//   return item;
// }

// export function* deleteItem(itemId: types.IItem['id']) {
//   yield call(apiRequest, api.deleteItemApi(itemId));
// }
