/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../../app/dialog/dialog.actions';
import { meetingsPageSelectors } from '../../../../../../app/page/page-storage/meetings/meetings.selectors';
import { taskSelectors } from '../../../../../../app/resource/task/task.selectors';
import { IDefaultTaskType } from '../../../../../../app/resource/task/task.types';
import { formatDate } from '../../../../../../utils/date.utils';
import { useSelect, useSelectWithParams } from '../../../../../../utils/selector.utils';
import { TaskMenu } from '../TaskMenu';
import { TaskAvatar } from './TaskAvatar';
import { ITaskWidgetParams } from './types';

export const TaskWidget: FunctionComponent<ITaskWidgetParams> = ({ taskId, provided }) => {
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId);
  const dispatch = useDispatch();

  const openUpdateTask = useCallback(() => {
    if (task) {
      dispatch(openDialog('taskFormDialog', { type: task.taskTypeId as IDefaultTaskType, task, userId: task.userId }));
    }
  }, [dispatch, task]);
  const filteredMeetingIds = useSelect(meetingsPageSelectors.selectFilteredMeetings);
  const [filteredTask, setFilteredTask] = useState(false);
  useEffect(() => {
    if (task) {
      setFilteredTask(filteredMeetingIds.some((id) => task.meetingIds.includes(id)));
    }
  }, [filteredMeetingIds, task]);

  return task ? (
    <a
      draggable={true}
      id={task.id}
      className="card todo-card panel"
      data-id="111"
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={filteredTask === true ? { display: 'none' } : {}}
    >
      <div className="card-header">
        {task.status === '0' && (
          <div className="badge lightBlue-bg blue mr-1">{task.taskTypeId === '1' ? 'On Track' : 'Open'}</div>
        )}
        {task.status === '1' && (
          <div className="badge lightGreen-bg green mr-1">
            <i className="icon-check"></i> Done
          </div>
        )}
        {task.status === '2' && (
          <div className="badge lightYellow-bg yellow mr-1">{task.taskTypeId === '1' ? 'Off Track' : 'On Hold'}</div>
        )}
        {task.status === '3' && <div className="badge lightRed-bg red mr-1">Urgent</div>}
        {task.status === '4' && <div className="badge lightRed-bg red mr-1">Overdue</div>}
        <div className="blueGray f-1 card-date">{formatDate(task.dueDate)}</div>

        <TaskMenu task={task} />
      </div>

      <div className="card-body" onClick={openUpdateTask}>
        <div className="card-desc">{task.title}</div>
      </div>

      <div className="card-footer">
        <TaskAvatar meetingIds={task.meetingIds} userIds={[task.userId]} taskType={task.type} />
        <div className="blueGray f-1">
          Due: <span className="card-due">{formatDate(task.dueDate)}</span>
        </div>
      </div>
    </a>
  ) : (
    <></>
  );
};
