import * as types from './projects.types';
import * as constants from './projects.constants';
import { IProject, IProjectCreateForm } from '../../../resource/project/project.types';
import { IAccountProject } from '../../../resource/account-project/account-project.types';

export const setupProject = (projectId: IProject['id'], accountId: string): types.ISetupProject => ({
  type: constants.SETUP_PROJECT,
  payload: {
    accountId: accountId,
    projectId: projectId,
  },
});

export const createProject = (project: IProjectCreateForm): types.ICreateProject => ({
  type: constants.CREATE_PROJECT,
  payload: project,
});

export const updateProject = (projectId: IProject['id'], project: IProjectCreateForm): types.IUpdateProject => ({
  type: constants.UPDATE_PROJECT,
  payload: {
    id: projectId,
    data: project,
  },
});

export const deleteProject = (projectId: IProject['id'], accountId: IAccountProject['id']): types.IDeleteProject => ({
  type: constants.DELETE_PROJECT,
  payload: { projectId, accountId },
});

export const storeProjects = (projects: IProject['id'][]): types.IStoreProject => ({
  type: constants.STORE_PROJECT,
  payload: projects,
});

export const clearProject = (): types.IClearProject => ({
  type: constants.CLEAR_PROJECT,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
