import { createActionConst } from '../../../../utils/action.utils';

const QUARTAL_NAMESPACE = 'QUARTALS';

export const CREATE_QUARTAL = createActionConst(QUARTAL_NAMESPACE, 'CREATE_QUARTAL');
export const UPDATE_QUARTAL = createActionConst(QUARTAL_NAMESPACE, 'UPDATE_QUARTAL');
export const DELETE_QUARTAL = createActionConst(QUARTAL_NAMESPACE, 'DELETE_QUARTAL');

export const STORE_IS_FETCHED = createActionConst(QUARTAL_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(QUARTAL_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_QUARTAL = createActionConst(QUARTAL_NAMESPACE, 'STORE_QUARTAL');
export const CLEAR_QUARTAL = createActionConst(QUARTAL_NAMESPACE, 'CLEAR_QUARTAL');

export const SAVE_QUARTAL_SOCKET = 'saveQuartal';
export const DELETE_QUARTAL_SOCKET = 'deleteQuartal';
