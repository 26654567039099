import React, { FunctionComponent } from 'react';
import { accountProjectSelectors } from '../../../../../../../app/resource/account-project/account-project.selectors';
import { meetingSelectors } from '../../../../../../../app/resource/meeting/meeting.selectors';
import { useSelectWithParams } from '../../../../../../../utils/selector.utils';
import { AccountProject } from './AccountProject';
import { IAccountProps } from './types';

export const Account: FunctionComponent<IAccountProps> = ({ accountId }) => {
  const account = useSelectWithParams(meetingSelectors.selectResourceById, accountId);
  const accountProject = useSelectWithParams(accountProjectSelectors.selectResourceById, accountId);

  return (
    <div className="single-tracinkg-account">
      <div className="border-radius-1 lightGray-bg px-2 py-1 w100 flex aic">
        <div className="small-tracking-icon white-bg semibold">
          {' '}
          <img src={account?.image} alt="youbar" className="img-fluid" />
        </div>
        <span className="bold">{account?.title}</span>
      </div>
      {accountProject?.projectReportIds.map(
        (projectReportId) =>
          projectReportId && (
            <AccountProject projectReportId={projectReportId} key={projectReportId} accountId={accountId} />
          ),
      )}
    </div>
  );
};
