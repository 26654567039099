/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { Column } from './Column';
import { IColumnsProps } from './types';

export const Columns: FunctionComponent<IColumnsProps> = ({ columnIds, showSaveButton }) => {
  return (
    <div className="border-radius-1 lightGray-bg p-2" style={{ display: 'flex', flexWrap: 'wrap' }}>
      {columnIds.map((columnId) => (
        <>
          <Column columnId={columnId} singlePage={false} />
        </>
      ))}

      {showSaveButton !== '0' && (
        <div className="row mb-1" style={{ width: '100%' }}>
          <div className="col-12 flex mt-2">
            <a className="btn green-bg ml-auto mr-0" title="Save Changes">
              Save Changes
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
