import { put } from 'redux-saga/effects';

import * as types from './kpi.types';
import * as actions from './kpi.actions';
import { select } from '../../../utils/selector.utils';
import { kpiPageSelectors } from '../../page/page-storage/kpi/kpi.selectors';

export function* storeKpi(kpi: types.IKpi) {
  yield put(actions.storeKpi(kpi));
  return kpi;
}

export function* clearKpi(kpiId: types.IKpi['id']) {
  yield put(actions.clearKpi(kpiId));
}

export function* clearAllKpis() {
  const kpiRowIds: types.IKpi['id'][] = yield select(kpiPageSelectors.selectKpiRowIds);
  yield put(actions.clearKpi(kpiRowIds));
}
