import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './user-form.constants';
import * as types from './user-form.types';
import * as services from './user-form.saga-services';

function* invokeFilterSelectableAccounts(action: types.IFilterSelectableAccounts) {
  yield call(services.filterSelectableAccounts, action.payload);
}

function* invokeFilterSelectableTeams(action: types.IFilterSelectableTeams) {
  yield call(services.filterSelectableTeams, action.payload);
}

export function* watchUsersFormSaga() {
  yield takeLatest(constants.FILTER_SELECTABLE_ACCOUNTS, invokeFilterSelectableAccounts);
  yield takeLatest(constants.FILTER_SELECTABLE_TEAMS, invokeFilterSelectableTeams);
}
