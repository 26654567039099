import { put, select, call } from 'redux-saga/effects';
import * as types from './board.types';
import * as actions from './board.actions';
import { apiRequest } from '../../../api/api.saga-services';
import * as api from '../../../api/request-config/board.api';
import { boardSelectors } from './board.selectors';

import { IMeeting } from '../../meeting/meeting.types';
import { IBoardList } from '../boardList/boardList.types';
import { storeBoardList } from '../boardList/boardList.actions';
import { ILabel } from '../../label/label.types';
import { storeLabel } from '../../label/label.actions';

export function* storeBoard(board: types.IBoard) {
  yield put(actions.storeBoard(board));

  return board;
}

export function* clearBoard(boardId: types.IBoard['id']) {
  yield put(actions.clearBoard(boardId));
}

export function* clearAllBoards() {
  const boardsIds: types.IBoard['id'][] = yield select(boardSelectors.selectResourceIds);
  yield put(actions.clearBoard(boardsIds));
}

export function* searchBoards(meetingId: IMeeting['id']) {
  const boardResponses: types.IBoardResponse[] = yield call(apiRequest, api.searchBoardsApi(meetingId));

  const boardLists = boardResponses.reduce((prev, curr) => [...prev, ...curr.taskTypes], [] as IBoardList[]);
  yield put(storeBoardList(boardLists));

  const labels = boardResponses.reduce((prev, curr) => [...prev, ...curr.labels], [] as ILabel[]);
  yield put(storeLabel(labels));

  const boards = boardResponses.map(mapBoardResponseToBoard);

  yield put(actions.storeBoard(boards));
  return boards;
}

export function* createBoard(data: types.IBoardCreate) {
  const board = (yield call(apiRequest, api.createBoardApi(data))) as types.IBoardResponse;
  if (!board) {
    return;
  }

  yield put(storeLabel(board.labels));

  const localBoard = mapBoardResponseToBoard(board);
  yield put(actions.storeBoard(localBoard));
  return localBoard;
}

export function* updateBoard(data: types.IBoardUpdate) {
  const board = (yield call(apiRequest, api.updateBoardApi(data))) as types.IBoard;
  if (!board) {
    return;
  }
  board.id = data.id;
  yield call(storeBoard, board);
  return board;
}

export function* deleteBoard(boardId: types.IBoard['id']) {
  yield call(apiRequest, api.deleteBoardApi(boardId));
}

export const mapBoardResponseToBoard = (boardResponse: types.IBoardResponse): types.IBoard => ({
  id: boardResponse.id,
  name: boardResponse.name,
  meetingIds: boardResponse.meetingIds,
  color: boardResponse.color,
  slug: boardResponse.slug,
  properties: boardResponse.properties,
  flag: boardResponse.flag,
  changedBy: boardResponse.changedBy,
  changedAt: boardResponse.changedAt,
  deleted: boardResponse.deleted,
  taskTypeIds: boardResponse.taskTypes ? boardResponse.taskTypes.map((boardList) => boardList.id) : [],
  labelIds: boardResponse.labels.map((label) => label.id),
});

export function* saveBoardOrder(boardIds: types.ISaveBoardOrder) {
  yield call(apiRequest, api.saveBoardOrderApi(boardIds));

  return boardIds;
}
