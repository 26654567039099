/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { useApiRequestState } from '../../../utils/api.utils';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { SubmitButton } from '../../pages/common/forms/SubmitButton';
import { UserGroupFrom } from '../../pages/UserPage/UserGroups/UserGroupForm';

import { ConnectedDialog } from '../ConnectedDialog';
import { IUserGroupFormDialogProps } from './types';

export const UserGroupFormDialog: FunctionComponent = () => {
  const dispatch = useDispatch();
  const props = useSelectWithParams(
    dialogSelectors.selectDialogProps,
    'userGroupFormDialog',
  ) as IUserGroupFormDialogProps;

  const [createShowLoader, createError] = useApiRequestState('createUserGroup');

  const onClose = useCallback(() => {
    dispatch(closeDialog('userGroupFormDialog'));
  }, [dispatch]);

  return (
    <ConnectedDialog dialogKey="userGroupFormDialog">
      <div className="popup new_team_popup mid-popup isVisible">
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            <h3>{props?.data ? 'Edit role' : 'Add role'} </h3>
            <span className="close-popup close">
              {createShowLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
              {createError && <p style={{ color: 'red' }}> Something went wrong </p>}
            </span>
          </div>
          <div className="panel-body pt-0" style={{ maxHeight: '500px' }}>
            <UserGroupFrom {...props} />
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" title="" onClick={onClose}>
              Close
            </a>

            {!props && <SubmitButton apiKey="createUserGroup" formName="user-form" text="Add" />}
            {props && <SubmitButton apiKey="createUserGroup" formName="user-form" text="Edit" />}
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
