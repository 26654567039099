import React, { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { forgotPasswordEmail } from '../../../../../app/auth/auth.actions';

import { useRequiredValidation } from '../../../../../utils/form/validation.utils';

import { FormSmallTextInput } from '../../inputs/SmallTextInput';

export const ForgotPasswordMailForm: FunctionComponent = () => {
  const dispatch = useDispatch();

  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: { email: string }) => {
    dispatch(forgotPasswordEmail(data.email));
  };

  return (
    <FormProvider {...formProps}>
      <form
        id="forgotPasswordMail-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as { email: string }))}
        method="post"
      >
        <div className="field-container">
          <FormSmallTextInput
            id="email"
            name="email"
            type="email"
            label="Email"
            placeholder="Enter Email"
            validation={requiredValidation}
          />
        </div>
      </form>
    </FormProvider>
  );
};
