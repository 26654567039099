import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IFormTextInputProps } from './types';

export const FormSmallTextInputCI: FunctionComponent<IFormTextInputProps> = ({
  id,
  name,
  defaultValue,
  type,
  placeholder,
  validation,
  disabled,
  rightIcon,
  leftIcon,
  multi,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={validation}
      render={(controllerProps) => (
        <>
          {leftIcon}
          {rightIcon}

          {multi ? (
            <textarea
              id={id}
              name={name}
              placeholder={placeholder}
              disabled={disabled}
              onChange={controllerProps.onChange}
              value={controllerProps.value}
            />
          ) : (
            <input
              id={id}
              name={controllerProps.name}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              onChange={controllerProps.onChange}
              value={controllerProps.value}
              className={'small-input checklist-item'}
            />
          )}
        </>
      )}
    />
  );
};
