/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { taskSelectors } from '../../../../../../../../../../app/resource/task/task.selectors';
import { useSelectWithParams } from '../../../../../../../../../../utils/selector.utils';
import { DueDateForm } from '../../../../../../../../common/forms/DueDateForm';

import { IDueDateMenuProps } from './types';

export const DueDateMenu: FunctionComponent<IDueDateMenuProps> = ({ taskId }) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  //const close = useCallback(() => setActive(false), [setActive]);
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId);
  return (
    <div className={isActive ? 'dropdown opened' : 'dropdown'}>
      <a
        className="btn w100 gray-bg darkGray px-1 f-1 ml-0 mb-1 mt-1 dropdown-button check_button"
        title="Due Date"
        onClick={toggleClass}
      >
        <i className="icon f-9 blueGray icon-calendar mr-1" /> Due Date
      </a>
      <div className="dropdown-menu drop-right" style={{ overflowY: 'visible' }}>
        <div className="mini-popup">
          <div className="mini-popup-header">
            Due Date
            <span className="close_mini_popup" onClick={toggleClass}>
              ×
            </span>
          </div>
          <div className="mini-popup-body">{task && <DueDateForm task={task} />}</div>
        </div>
      </div>
    </div>
  );
};
