import React, { FunctionComponent, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { changeOrderAgendaItem } from '../../../../../app/page/page-storage/agenda-items/agenda-items.actions';
import { agendaItemsPageSelectors } from '../../../../../app/page/page-storage/agenda-items/agenda-items.selectors';
import { useSelect } from '../../../../../utils/selector.utils';
import { AgendaDialogItem } from './AgendaDialogItem';

export const AgendaDialogList: FunctionComponent = () => {
  const dispatch = useDispatch();
  const agendaItemIds = useSelect(agendaItemsPageSelectors.selectAgendaItems);
  const handleDrop = useCallback(
    (result: DropResult) => {
      dispatch(changeOrderAgendaItem('', result));
    },
    [dispatch],
  );
  return (
    <DragDropContext onDragEnd={handleDrop}>
      <Droppable droppableId={`agendaItems`}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} id="widgetsList">
            {agendaItemIds.map((agendaItemId, index) => (
              <Draggable key={agendaItemId} draggableId={agendaItemId} index={index}>
                {(provided) => <AgendaDialogItem agendaItemId={agendaItemId} provided={provided} />}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
