import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './time-tracking.constants';
import * as types from './time-tracking.types';
import * as services from './time-tracking.saga-services';

function* invokeInitialTimeTrackingPage(action: types.IInitialTimeTrackingPage) {
  yield call(services.initialTimeTrackingPage);
}

export function* watchTimeTrackingPageSaga() {
  yield takeLatest(constants.INITIAL_TIME_TRACKING_PAGE, invokeInitialTimeTrackingPage);
}
