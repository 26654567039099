import { combineReducers, Reducer } from 'redux';

import * as types from './checkLists.types';
import * as constants from './checkLists.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.ICheckListPageState = {
  checkListsIds: [],
};

const checkListsReducer: Reducer<
  types.ICheckListPageState['checkListsIds'],
  types.IStoreCheckList | types.IClearCheckList
> = (state = initialState.checkListsIds, action) => {
  if (isActionOfType<types.IStoreCheckList>(action, constants.STORE_CHECKLIST)) {
    return action.payload;
  } else if (isActionOfType<types.IClearCheckList>(action, constants.CLEAR_CHECKLIST)) {
    return initialState.checkListsIds;
  }

  return state;
};

export const checkListsPageReducer: Reducer<types.ICheckListPageState, types.ICheckListPageAction> = combineReducers({
  checkListsIds: checkListsReducer,
});
