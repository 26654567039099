import * as types from './widgets.types';
import * as constants from './widgets.constants';
import { DropResult } from 'react-beautiful-dnd';

export const storeWidgets = (widgets: types.IWidget[]): types.IStoreWidgets => ({
  type: constants.STORE_WIDGETS,
  payload: widgets,
});

export const clearWidget = (): types.IClearWidgets => ({
  type: constants.CLEAR_WIDGETS,
});

export const dndWidget = (result: DropResult): types.IDndWidget => ({
  type: constants.DND_WIDGET,
  payload: result,
});
