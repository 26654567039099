import React from 'react';
import { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { boardSelectors } from '../../../../../app/resource/boards/board/board.selectors';
import { boardListSelectors } from '../../../../../app/resource/boards/boardList/boardList.selectors';

import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { useSelect, useSelectWithParams } from '../../../../../utils/selector.utils';

import { IAddAgendaCreateForm, IAddAgendaFormProps } from './types';
import { FormTextInput } from '../../../../pages/common/inputs/TextInput';
import { SelectInput } from '../../../../pages/common/inputs/SelectInput';
import { FormCSTextInput } from '../../../../pages/common/inputs/CustomSizeTextInput';

export const AddAgendaForm: FunctionComponent<IAddAgendaFormProps> = ({ meetingId, boardIds, onSubmit }) => {
  const formProps = useForm();

  const requiredValidation = useRequiredValidation();
  const boardOptions = useSelectWithParams(boardSelectors.selectResourcesById, boardIds).map((board) => ({
    value: board?.id,
    label: board?.name,
  }));

  const focusConnectTuple = formProps.watch('control');
  const focusBoardTuple = formProps.watch('board');
  const boardListOptions = useSelect(boardListSelectors.selectResourceList)
    .filter((boardList) => focusBoardTuple && boardList.boardId === focusBoardTuple.value)
    .map((boardList) => ({ value: boardList.id, label: boardList.name }));

  const connectOptions = [
    { value: '0', label: 'Accountability Board' },
    { value: '1', label: 'Accountability List' },
    { value: '2', label: 'External URL' },
  ];

  return (
    <FormProvider {...formProps}>
      <form
        id="addAgenda-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IAddAgendaCreateForm))}
        method="post"
      >
        <div className="field-container">
          <FormTextInput
            id="name"
            name="name"
            type="text"
            label="Agenda Name"
            placeholder="Enter Agenda Name"
            validation={requiredValidation}
          />
        </div>
        <div className="field-container">
          <SelectInput
            id="control"
            name="control"
            label="Connect to"
            validation={requiredValidation}
            options={connectOptions}
            isSingle
          />
        </div>
        {focusConnectTuple && (focusConnectTuple.value === '0' || focusConnectTuple.value === '1') && (
          <div className="field-container">
            <SelectInput
              id="board"
              name="board"
              label="Board"
              validation={requiredValidation}
              options={boardOptions}
              isSingle
            />
          </div>
        )}
        {focusConnectTuple && focusConnectTuple.value === '1' && (
          <div className="field-container">
            <SelectInput
              id="boardList"
              name="boardList"
              label="Board List"
              validation={requiredValidation}
              options={boardListOptions}
              isSingle
            />
          </div>
        )}
        {focusConnectTuple && focusConnectTuple.value === '2' && (
          <div className="field-container">
            <FormTextInput
              id="embedUrl"
              name="embedUrl"
              type="text"
              label="Embed URL"
              placeholder="Enter"
              validation={requiredValidation}
            />
          </div>
        )}
        <div className="field-container">
          <FormCSTextInput
            id="duration"
            name="duration"
            type="text"
            size="mw"
            label="Duration"
            placeholder="Minutes"
            validation={requiredValidation}
          />
        </div>
      </form>
    </FormProvider>
  );
};
