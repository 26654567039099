/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { ImgColumn } from './ImgColumn';

import { IImgColumnsProps } from './types';

export const ImgColumns: FunctionComponent<IImgColumnsProps> = ({ columnIds, showSaveButton }) => {
  return (
    <div className="border-radius-1 lightGray-bg p-2">
      <div className="row mb-1">
        <div className="col-12">
          <div className="panel">
            <div className="panel-body px-2 pt-2 pb-0">
              <div className="row">
                {columnIds.map((columnId) => (
                  <div className="col-4" key={columnId}>
                    <ImgColumn columnId={columnId} />
                  </div>
                ))}
                <div className="col-4">
                  <a className="personas-item">
                    <div className="persona-desc">
                      <span className="img-fluid persona-img">
                        <span>+</span>
                      </span>
                      <p className="bold mt-4 darkGray f-16">Develop New</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
