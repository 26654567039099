import * as types from './sessions.types';
import * as constants from './sessions.constants';
import { ISession, ISessionCreate, ISessionSearchParams } from '../../../resource/session/session.types';

export const fetchSessions = (searchParams?: ISessionSearchParams): types.IFetchSessions => ({
  type: constants.FETCH_SESSIONS,
  payload: searchParams,
});

export const createSession = (session: ISession): types.ICreateSession => ({
  type: constants.CREATE_SESSION,
  payload: session,
});

export const updateSession = (sessionId: ISession['id'], session: ISessionCreate): types.IUpdateSession => ({
  type: constants.UPDATE_SESSION,
  payload: {
    id: sessionId,
    data: session,
  },
});

export const deleteSession = (sessionId: ISession['id']): types.IDeleteSession => ({
  type: constants.DELETE_SESSION,
  payload: sessionId,
});

export const storeSessionIds = (sessionIds: ISession['id'][]): types.IStoreFetchedSessions => ({
  type: constants.STORE_SESSION_IDS,
  payload: sessionIds,
});

export const clearSessionIds = (): types.IClearFetchedSessions => ({
  type: constants.CLEAR_SESSION_IDS,
});

export const storeNextSession = (nextSessionId: ISession['id']): types.IStoreNextSession => ({
  type: constants.STORE_NEXT_SESSION,
  payload: nextSessionId,
});

export const clearNextSession = (): types.IClearNextSession => ({
  type: constants.CLEAR_NEXT_SESSION,
});
