import { all, call, fork, takeLatest } from 'redux-saga/effects';

import * as constants from './boards.constants';
import * as types from './boards.types';
import * as services from './boards.saga-services';

function* invokeCreateBoard(action: types.ICreateBoard) {
  yield call(services.createBoard, action.payload);
}

function* invokeUpdateBoard(action: types.IUpdateBoard) {
  yield call(services.updateBoard, action.payload.id, action.payload.data);
}
function* invokeDeleteBoard(action: types.IDeleteBoard) {
  yield call(services.deleteBoard, action.payload);
}

function* invokeCurrentBoard(action: types.IStoreCurrentBoard) {
  yield call(services.storeCurrentBoard, action.payload);
}

function* invokeSaveBoardSocket(action: types.ISaveBoardSocket) {
  yield call(services.handleBoardFromNotification, action.payload);
}
function* invokeDeleteBoardSocket(action: types.ISaveBoardSocket) {
  yield call(services.handleDeleteBoardFromNotification, action.payload);
}
function* invokeSaveBoardOrder(action: types.ISaveBoardOrder) {
  yield call(services.saveBoardOrder, action.payload.boardIds);
}

export function* watchBoardsPurePageSaga() {
  yield takeLatest(constants.CREATE_BOARD, invokeCreateBoard);
  yield takeLatest(constants.UPDATE_BOARD, invokeUpdateBoard);
  yield takeLatest(constants.DELETE_BOARD, invokeDeleteBoard);
  yield takeLatest(constants.STORE_CURRENT_BOARD, invokeCurrentBoard);
  yield takeLatest(constants.SAVE_BOARD_SOCKET, invokeSaveBoardSocket);
  yield takeLatest(constants.SAVE_BOARD_ORDER, invokeSaveBoardOrder);
  yield takeLatest(constants.DELETE_BOARD_SOCKET, invokeDeleteBoardSocket);
}

export function* watchBoardsPageStorageSaga() {
  yield all([watchBoardsPurePageSaga].map(fork));
}
