import { createActionConst } from '../../../../utils/action.utils';

const ACTIVITY_NAMESPACE = 'ACTIVITIES';

export const CREATE_ACTIVITY = createActionConst(ACTIVITY_NAMESPACE, 'CREATE_ACTIVITY');
export const UPDATE_ACTIVITY = createActionConst(ACTIVITY_NAMESPACE, 'UPDATE_ACTIVITY');
export const DELETE_ACTIVITY = createActionConst(ACTIVITY_NAMESPACE, 'DELETE_ACTIVITY');

export const SAVE_FILE = createActionConst(ACTIVITY_NAMESPACE, 'SAVE_FILE');

export const STORE_IS_FETCHED = createActionConst(ACTIVITY_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(ACTIVITY_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_ACTIVITY = createActionConst(ACTIVITY_NAMESPACE, 'STORE_ACTIVITY');
export const CLEAR_ACTIVITY = createActionConst(ACTIVITY_NAMESPACE, 'CLEAR_ACTIVITY');

export const SAVE_ACTIVITY_SOCKET = 'saveActivity';
export const DELETE_ACTIVITY_SOCKET = 'deleteActivity';
