import { all, call, fork, takeLatest } from 'redux-saga/effects';

import * as constants from './project-tasks.constants';
import * as types from './project-tasks.types';
import * as services from './project-tasks.saga-services';

function* invokeCreateProjectTask(action: types.ICreateProjectTask) {
  yield call(services.createProjectTask, action.payload);
}

function* invokeUpdateProjectTask(action: types.IUpdateProjectTask) {
  yield call(services.updateProjectTask, action.payload.id, action.payload.data);
}
function* invokeDeleteProjectTask(action: types.IDeleteProjectTask) {
  yield call(services.deleteProjectTask, action.payload);
}
/*
function* invokeSaveProjectTaskSocket(action: types.ISaveProjectTaskSocket) {
  yield call(services.handleProjectTaskFromNotification, action.payload);
}
*/
export function* watchProjectTasksPurePageSaga() {
  yield takeLatest(constants.CREATE_PROJECT_TASK, invokeCreateProjectTask);
  yield takeLatest(constants.UPDATE_PROJECT_TASK, invokeUpdateProjectTask);
  yield takeLatest(constants.DELETE_PROJECT_TASK, invokeDeleteProjectTask);

  //yield takeLatest(constants.SAVE_PROJECT_TASK_SOCKET, invokeSaveProjectTaskSocket);
}

export function* watchProjectTasksPageStorageSaga() {
  yield all([watchProjectTasksPurePageSaga].map(fork));
}
