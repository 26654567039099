/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useRequiredValidation } from '../../../../../utils/form/validation.utils';

import { IQuartalFormProps } from './types';

import { IQuartalCreateForm, IQuartal } from '../../../../../app/resource/quartal/quartal.types';

import { createQuartal, updateQuartal } from '../../../../../app/page/page-storage/quartals/quartals.actions';
import { DateTimeInput } from '../../inputs/DateTimeInput';
import moment from 'moment';

export const QuartalForm: FunctionComponent<IQuartalFormProps> = ({ quartalData }) => {
  const dispatch = useDispatch();

  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: IQuartalCreateForm, quartalId?: IQuartal['id']) => {
    const newData = {
      year: moment(data.year).format('YYYY'),
      startQuartal1: moment(data.startQuartal1).format('YYYY-MM-DD'),
      startQuartal2: moment(data.startQuartal2).format('YYYY-MM-DD'),
      startQuartal3: moment(data.startQuartal3).format('YYYY-MM-DD'),
      startQuartal4: moment(data.startQuartal4).format('YYYY-MM-DD'),
      endQuartal4: moment(data.endQuartal4).format('YYYY-MM-DD'),
    };
    if (quartalId && quartalData) {
      dispatch(updateQuartal(quartalId, { ...quartalData, ...newData }));
    } else {
      dispatch(createQuartal(newData));
    }
  };

  const year = useMemo(() => {
    try {
      if (quartalData) {
        const dateString = moment(quartalData.year).format('YYYY-MM-DD');

        return new Date(dateString);
      } else {
        return new Date();
      }
    } catch (error) {
      console.log(error);
    }
  }, [quartalData]);

  const startOne = useMemo(() => {
    try {
      if (quartalData) {
        const dateString = moment(quartalData.startQuartal1).format('YYYY-MM-DD');

        return new Date(dateString);
      } else {
        return new Date();
      }
    } catch (error) {
      console.log(error);
    }
  }, [quartalData]);

  const startTwo = useMemo(() => {
    try {
      if (quartalData) {
        const dateString = moment(quartalData.startQuartal2).format('YYYY-MM-DD');

        return new Date(dateString);
      } else {
        return new Date();
      }
    } catch (error) {
      console.log(error);
    }
  }, [quartalData]);

  const startThree = useMemo(() => {
    try {
      if (quartalData) {
        const dateString = moment(quartalData.startQuartal3).format('YYYY-MM-DD');

        return new Date(dateString);
      } else {
        return new Date();
      }
    } catch (error) {
      console.log(error);
    }
  }, [quartalData]);

  const startFour = useMemo(() => {
    try {
      if (quartalData) {
        const dateString = moment(quartalData.startQuartal4).format('YYYY-MM-DD');

        return new Date(dateString);
      } else {
        return new Date();
      }
    } catch (error) {
      console.log(error);
    }
  }, [quartalData]);

  const endFour = useMemo(() => {
    try {
      if (quartalData) {
        const dateString = moment(quartalData.endQuartal4).format('YYYY-MM-DD');

        return new Date(dateString);
      } else {
        return new Date();
      }
    } catch (error) {
      console.log(error);
    }
  }, [quartalData]);

  return (
    <FormProvider {...formProps}>
      <form
        id="quartal-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IQuartalCreateForm, quartalData?.id))}
        method="post"
      >
        {quartalData ? (
          <>
            <div className="row">
              <div className="col-12">
                <div className="field-container">
                  <DateTimeInput
                    id="year"
                    name="year"
                    label="Year"
                    defaultValue={year}
                    placeholder="Enter Year"
                    validation={requiredValidation}
                    yearPicker={true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="field-container">
                  <DateTimeInput
                    id="startQuartal1"
                    name="startQuartal1"
                    label="Start Quarter I"
                    defaultValue={startOne}
                    placeholder="Enter Start Date"
                    validation={requiredValidation}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="field-container">
                  <DateTimeInput
                    id="startQuartal2"
                    name="startQuartal2"
                    label="Start Quarter II"
                    defaultValue={startTwo}
                    placeholder="Enter Start Date"
                    validation={requiredValidation}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="field-container">
                  <DateTimeInput
                    id="startQuartal3"
                    name="startQuartal3"
                    label="Start Quarter III"
                    defaultValue={startThree}
                    placeholder="Enter Start Date"
                    validation={requiredValidation}
                  />
                </div>
              </div>
            </div>
            <div className="col-10">
              <div className="row">
                <div className="col-6">
                  <div className="field-container">
                    <DateTimeInput
                      id="startQuartal4"
                      name="startQuartal4"
                      label="Start Quarter IV"
                      defaultValue={startFour}
                      placeholder="Enter Start Date"
                      validation={requiredValidation}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="field-container">
                    <DateTimeInput
                      id="endQuartal4"
                      name="endQuartal4"
                      label="End Quarter IV"
                      defaultValue={endFour}
                      placeholder="Enter End Date"
                      validation={requiredValidation}
                    />
                  </div>
                </div>
              </div>
            </div>{' '}
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-12">
                <div className="field-container">
                  <DateTimeInput
                    id="year"
                    name="year"
                    label="Year"
                    placeholder="Enter Year"
                    validation={requiredValidation}
                    yearPicker={true}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="field-container">
                  <DateTimeInput
                    id="startQuartal1"
                    name="startQuartal1"
                    label="Start Quarter I"
                    placeholder="Enter Start Date"
                    validation={requiredValidation}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="field-container">
                  <DateTimeInput
                    id="startQuartal2"
                    name="startQuartal2"
                    label="Start Quarter II"
                    placeholder="Enter Start Date"
                    validation={requiredValidation}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="field-container">
                  <DateTimeInput
                    id="startQuartal3"
                    name="startQuartal3"
                    label="Start Quarter III"
                    placeholder="Enter Start Date"
                    validation={requiredValidation}
                  />
                </div>
              </div>
            </div>
            <div className="col-10">
              <div className="row">
                <div className="col-6">
                  <div className="field-container">
                    <DateTimeInput
                      id="startQuartal4"
                      name="startQuartal4"
                      label="Start Quarter IV"
                      placeholder="Enter Start Date"
                      validation={requiredValidation}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="field-container">
                    <DateTimeInput
                      id="endQuartal4"
                      name="endQuartal4"
                      label="End Quarter IV"
                      placeholder="Enter End Date"
                      validation={requiredValidation}
                    />
                  </div>
                </div>
              </div>
            </div>{' '}
          </>
        )}
      </form>
    </FormProvider>
  );
};
