/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import { userGroupSelectors } from '../../../../app/resource/user-group/user-group.selectors';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { IUserGroupsProps } from './types';

export const UserGroups: FunctionComponent<IUserGroupsProps> = ({ groupIds, onFocusUserGroup }) => {
  const groups = useSelectWithParams(userGroupSelectors.selectResourcesById, groupIds);

  return (
    <>
      {groups.map(
        (group) =>
          group && (
            <a
              key={`user-group-${group.id}`}
              style={{ cursor: 'pointer' }}
              className="directory-button panel flex aic px-1 mb-1"
              onClick={() => onFocusUserGroup(group.userIds)}
            >
              <i className="icon-user-full mr-1 blueGray"></i> {group.name}
              <span className="ml-auto blueGray">{group.userIds ? group.userIds.length : 0}</span>
            </a>
          ),
      )}
    </>
  );
};
