import { call, put, select } from 'redux-saga/effects';
import { apiRequest } from '../api/api.saga-services';
import { IUserCredentials, IUserWithTokens } from '../resource/user/user.types';
import * as pageService from '../page/page.initializer.saga-service';
import * as resourceService from '../resource/resource.destructor.saga-service';

import * as api from '../api/request-config/auth.api';
import * as actions from './auth.actions';
import { authSelectors } from './auth.selectors';
import { storeUser } from '../resource/user/user.actions';
import moment from 'moment';
import Cookies from 'js-cookie';
import { omit } from 'lodash';
import { INewPassword } from './auth.types';
import * as dialogService from '../dialog/dialog.saga-services';

import { push } from 'connected-react-router';

export function* logUserIn(user: IUserWithTokens) {
  // order matters!
  yield put(actions.storeLoggedUser(user.id));
  yield put(actions.storeAccessToken(user.accessToken));
  // yield put(actions.storeGooglePermisionGranted(user.googleApiToken));

  yield put(storeUser(omit(user, 'token')));
}

export function* cacheLoggedUser(user: IUserWithTokens, rememberMe: boolean, expiresAt: string) {
  yield call([Cookies, Cookies.set], 'logged_user', JSON.stringify({ ...user, rememberMe, expiresAt }), {
    expires: rememberMe ? 60 : undefined,
  });
}
export function getCookie(key: string) {
  return Cookies.get(key);
}
export function* loadCachedUser() {
  const user = (yield call(getCookie, 'logged_user')) as string | undefined;

  if (!user) {
    return;
  }

  try {
    const parsedUser = JSON.parse(user);
    yield put(actions.storeRememberUser(parsedUser.rememberMe));
    yield put(actions.storeUserExpiresAt(parsedUser.expiresAt));
    delete parsedUser.rememberMe;
    delete parsedUser.expiresAt;
    yield call(logUserIn, parsedUser);
  } catch (e) {
    console.error(e);
  }
}

export function* logIn(credentials: IUserCredentials) {
  const user = (yield call(apiRequest, api.loginApi(credentials))) as IUserWithTokens;
  if (!user) {
    return;
  }

  yield put(actions.storeRememberUser(!!credentials.rememberUser));
  const timestamp = credentials.rememberUser ? moment().add(60, 'days') : moment().add(1, 'hours');
  yield put(actions.storeUserExpiresAt(timestamp.toString()));
  yield call(logUserIn, user);
  yield call(cacheLoggedUser, user, !!credentials.rememberUser, timestamp.toString());
  // const clearLocalStorage = () => {
  //   Cookies.remove('logged_user');
  // };
  // window.removeEventListener('beforeunload', clearLocalStorage);

  // if (!credentials.rememberUser) {
  //   window.addEventListener('beforeunload', (event) => {
  //     Cookies.remove('logged_user');
  //   });
  // }
}

export function* logOutIfUserExpired() {
  const expiresAtString = (yield select(authSelectors.selectUserExpiresAt)) as string;

  if (moment(expiresAtString).isSameOrBefore(moment())) {
    yield call(logOut);
    return true;
  }
  return false;
}

export function* logOut() {
  yield put(actions.clearLoggedUser());
  yield put(actions.clearAccessToken());
  yield put(actions.clearGooglePermisionGranted());

  yield call(pageService.uninitPage);
  yield call(resourceService.uninitResources);

  yield call([Cookies, Cookies.remove], 'logged_user');
  // window.removeEventListener('unload', (event) => {
  //   Cookies.remove('logged_user');
  // });
}

export function* storeGooglePermisionGranted(permision: boolean) {
  const loggedUserString = (yield call(getCookie, 'logged_user')) as string | undefined;
  if (!loggedUserString) {
    return;
  }
  const loggedUser = JSON.parse(loggedUserString);
  yield call(
    cacheLoggedUser,
    { ...loggedUser, googleApiToken: permision ? 'true' : '' },
    loggedUser.rememberMe,
    loggedUser.expiresAt,
  );
}

export function* forgotPasswordEmail(email: string) {
  const success = (yield call(apiRequest, api.forgotPasswordApi(email))) as boolean;
  if (!success) {
    return;
  }
  // console.log(success);
  yield call(dialogService.closeDialog, 'forgotPasswordDialog');
}

export function* saveNewPassword(data: INewPassword) {
  const success = (yield call(apiRequest, api.saveNewPasswordApi(data))) as boolean;
  if (!success) {
    return;
  }
  // console.log(success);
  yield put(push('/login'));
}
