import { createActionConst } from '../../../../utils/action.utils';

const ACCOUNT_PROJECT_NAMESPACE = 'ACCOUNT_PROJECTS';

export const CREATE_ACCOUNT_PROJECT = createActionConst(ACCOUNT_PROJECT_NAMESPACE, 'CREATE_ACCOUNT_PROJECT');
export const UPDATE_ACCOUNT_PROJECT = createActionConst(ACCOUNT_PROJECT_NAMESPACE, 'UPDATE_ACCOUNT_PROJECT');
export const DELETE_ACCOUNT_PROJECT = createActionConst(ACCOUNT_PROJECT_NAMESPACE, 'DELETE_ACCOUNT_PROJECT');

export const STORE_IS_FETCHED = createActionConst(ACCOUNT_PROJECT_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(ACCOUNT_PROJECT_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_ACCOUNT_PROJECT = createActionConst(ACCOUNT_PROJECT_NAMESPACE, 'STORE_ACCOUNT_PROJECT');
export const CLEAR_ACCOUNT_PROJECT = createActionConst(ACCOUNT_PROJECT_NAMESPACE, 'CLEAR_ACCOUNT_PROJECT');

export const SAVE_ACCOUNT_PROJECT_SOCKET = 'saveAccountProjects';
export const DELETE_ACCOUNT_PROJECT_SOCKET = 'deleteAccountProjects';
