import { call, put, select } from 'redux-saga/effects';
import { IAgendaItem } from '../../../resource/agenda-item/agenda-item.types';
import { IMeeting } from '../../../resource/meeting/meeting.types';
import * as actions from './agenda-items.actions';

import * as meetingService from '../../../resource/meeting/meeting.saga-service';
import * as agendaService from '../../../resource/agenda-item/agenda-item.saga-service';

import { DropResult } from 'react-beautiful-dnd';
import { agendaItemsPageSelectors } from './agenda-items.selectors';
import { meetingSelectors } from '../../../resource/meeting/meeting.selectors';
import { ICurrentAgendaIndex } from './agenda-items.types';

export function* storeAgendaItems(agendaItems?: IAgendaItem['id'][]) {
  yield agendaItems ? put(actions.storeAgendaItems(agendaItems)) : put(actions.clearAgendaItems());
}

export function* setupAgendaItemsForMeetingForm(agendaItemIds: IAgendaItem['id'][]) {
  yield call(storeAgendaItems, agendaItemIds);
}

export function* setupAgendaItemsForMeeting(meetingId: IMeeting['id']) {
  const meeting = (yield select(meetingSelectors.selectResourceById, meetingId)) as IMeeting;

  // // const meeting = (yield call(meetingService.populateAgendaItems, meetingId)) as IMeeting;

  if (meeting && meeting.agendaItemIds) {
    yield call(storeAgendaItems, meeting.agendaItemIds);
  }
  yield call(meetingService.searchMeetings, { type: meeting.type });
}

export function* changeOrder(meetingId: IMeeting['id'], result: DropResult) {
  if (!result.destination) {
    return;
  }

  const sourceIndex = result.source.index;
  const destinationIndex = result.destination.index;
  const agendaItemId = result.draggableId;

  const agendaItemIds = (yield select(agendaItemsPageSelectors.selectAgendaItems)) as IAgendaItem['id'][];
  agendaItemIds.splice(sourceIndex, 1);
  agendaItemIds.splice(destinationIndex, 0, agendaItemId);

  yield call(storeAgendaItems, agendaItemIds);

  yield call(meetingService.setAgendaItems, meetingId, agendaItemIds);
}

export function* clearAgendaItemsPageStorage() {
  yield put(actions.clearAgendaItems());
  yield call(actions.clearCurrentAgenda);
}

export function* storeNextAgendaIndex(currentAgendaIndex: ICurrentAgendaIndex) {
  yield call(agendaService.moveToNextAgenda, currentAgendaIndex);
}

export function* moveToNextAgenda(currentAgendaIndex: ICurrentAgendaIndex) {
  yield call(agendaService.moveToNextAgenda, currentAgendaIndex);
}

export function* storeCurrentAgenda(currentAgendaIndex: ICurrentAgendaIndex) {
  yield put(currentAgendaIndex ? actions.storeCurrentAgenda(currentAgendaIndex) : actions.clearCurrentAgenda());
}
