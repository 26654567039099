import React, { FunctionComponent, useCallback, useState } from 'react';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import { updateTask } from '../../../../../../../../../../app/page/page-storage/boardLists/boardLists.actions';
import { IEditorProps } from './types';
import 'react-quill/dist/quill.snow.css';
import { useAtUserSearch } from '../../../../../../../../../../utils/atUserSearch.utils';
import { MembersManyOnAtInput } from '../../../../../../../../common/MembersManyOnAtInput';
import { userSelectors } from '../../../../../../../../../../app/resource/user/user.selectors';
import { useSelect } from '../../../../../../../../../../utils/selector.utils';
import { useApiRequestState } from '../../../../../../../../../../utils/api.utils';
import Loader from 'react-loader-spinner';

export const Editor: FunctionComponent<IEditorProps> = ({ task }) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState(task ? task.description : '');
  const { searchMember, onSelectUser, onTextChange, toggleClass, isActive } = useAtUserSearch({
    initText: task?.description,
    setText: setDescription,
  });

  const userIds = useSelect(userSelectors.selectResourceIds);

  const onBlur = useCallback(() => {
    const updatedTask = {
      id: task.id,
      title: task.title,
      description: description,
      dueDate: task.dueDate,

      userId: task.userId,
      meetingIds: task.meetingIds,

      status: task.status,

      label: task.label ?? [],
      taskTypeId: task.taskTypeId,
      members: task.members,
      showInTasks: task.showInTasks,
      boardId: task.boardId,
    };
    dispatch(updateTask(updatedTask));
  }, [description, dispatch, task]);

  const [showLoader] = useApiRequestState('updateTaskApi');

  return (
    <>
      <ReactQuill theme="snow" value={description} onChange={onTextChange} />
      {showLoader ? (
        <div
          style={{
            float: 'right',
            padding: '0px 10px',
            marginTop: '10px',
            height: '35px',
            width: '80px',
            justifyContent: 'center',
          }}
        >
          <Loader type="TailSpin" color="#1bc3a7" height="35px" width="35px" />
        </div>
      ) : (
        <button
          className="btn btn-sm green-bg"
          onClick={onBlur}
          style={{
            float: 'right',
            padding: '0px 10px',
            marginTop: '10px',
            height: '35px',
            width: '80px',
            justifyContent: 'center',
          }}
        >
          SEND
        </button>
      )}
      <div className={isActive ? 'dropdown opened' : 'dropdown'}>
        {isActive && (
          <MembersManyOnAtInput
            userIds={userIds}
            closeMenu={toggleClass}
            searchMember={searchMember}
            onSelectUser={onSelectUser}
            dropRight
          />
        )}
      </div>
    </>
  );
};
