import { call, takeEvery, takeLatest } from 'redux-saga/effects';
import * as constants from './agenda-items.constants';

import * as types from './agenda-items.types';
import * as services from './agenda-items.saga-services';

function* invokeChangeOrderAgendaItem(action: types.IChangeOrderAgendaItem) {
  yield call(services.changeOrder, action.payload.meetingId, action.payload.dropResult);
}

function* invokeMoveToNextAgenda(action: types.IMoveToNextAgenda) {
  yield call(services.moveToNextAgenda, action.payload);
}

function* invokeMoveToNextAgendaFromNotification(action: types.IMoveToNextAgendaNotification) {
  yield call(services.storeCurrentAgenda, action.payload);
}

export function* watchAgendaItemsPageSaga() {
  yield takeLatest(constants.CHANGE_ORDER_AGENDA_ITEM, invokeChangeOrderAgendaItem);
  yield takeLatest(constants.MOVE_TO_NEXT_AGENDA, invokeMoveToNextAgenda);

  yield takeEvery(constants.MOVE_TO_NEXT_AGENDA_SOCKET, invokeMoveToNextAgendaFromNotification);
}
