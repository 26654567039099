import React, { FunctionComponent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormCheckboxInput } from '../../../../../inputs/CheckboxInput';
import { FormCSTextInput } from '../../../../../inputs/CustomSizeTextInput';
import { IProjectTaskProps } from './types';

export const ProjectTask: FunctionComponent<IProjectTaskProps> = ({
  index,
  projectTask,
  removeTask,

  // focusHourlyTuple,
  // focusBudgetTuple,
}) => {
  const { control } = useFormContext();

  const focusHourlyTuple = useWatch<{ [key: string]: string }>({
    control,
    name: 'hourlyRate',
  });
  const focusBudgetTuple = useWatch<{ [key: string]: string }>({
    control,
    name: 'budgetType',
  });

  return (
    <div className="flex">
      <div className="flex aic">
        <span className="remove-task" onClick={() => removeTask(index)}>
          ×
        </span>
        {projectTask.id && (
          <div style={{ display: 'none' }}>
            <FormCSTextInput
              id={`tasks[${index}].id`}
              name={`tasks[${index}].id`}
              type="text"
              size="small-input"
              defaultValue={projectTask.id}
            />
          </div>
        )}
        {projectTask.title}
        <div style={{ display: 'none' }}>
          <FormCSTextInput
            id={`tasks[${index}].title`}
            name={`tasks[${index}].title`}
            type="text"
            size="small-input"
            defaultValue={projectTask.title}
          />
        </div>
      </div>
      <div className="flex aic jcr ml-auto">
        <div className="remember-checkbox">
          <FormCheckboxInput
            id={`tasks[${index}].billable`}
            name={`tasks[${index}].billable`}
            defaultChecked={projectTask.billable === '1' ? true : false}
          />

          <label htmlFor={`tasks[${index}].billable`} />
        </div>
      </div>
      {focusBudgetTuple && focusBudgetTuple.value === 'fees_per_task' && (
        <div className="flex aic jcr max100 ml-1 task_member_budget_hours" style={{ display: 'block !important' }}>
          <div className="field-container flex aic jcr mb-0">
            <FormCSTextInput
              id="budget"
              name={`tasks[${index}].budget`}
              type="text"
              size="small-input"
              defaultValue={projectTask.budget ? projectTask.budget : '10'}
            />
          </div>
        </div>
      )}
      {focusBudgetTuple && focusBudgetTuple.value === 'hours_per_task' && (
        <div className="flex aic jcr max100 ml-1 task_member_budget_amount" style={{ display: 'none !important' }}>
          <div className="field-container flex aic jcr mb-0">
            $
            <FormCSTextInput
              id="budgetHours"
              name={`tasks[${index}].budgetHours`}
              type="text"
              size="small-input"
              defaultValue={projectTask.budgetHours ? projectTask.budgetHours : '100'}
            />
          </div>
        </div>
      )}

      {focusHourlyTuple && focusHourlyTuple.value === 'task' && (
        <div className="flex aic jcr max100 ml-1 task_member_hourly_rate" style={{ display: 'none !important' }}>
          <div className="field-container flex aic jcr mb-0">
            $
            <FormCSTextInput
              id={`tasks[${index}].hourlyRate`}
              name={`tasks[${index}].hourlyRate`}
              type="text"
              size="small-input"
              defaultValue={projectTask.hourlyRate ? projectTask.hourlyRate : '100'}
            />
          </div>
        </div>
      )}
    </div>
  );
};
