import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './quartals.constants';
import * as types from './quartals.types';
import * as services from './quartals.saga-services';

function* invokeInitialQuartalsPage(action: types.IInitialQuartalsPage) {
  yield call(services.initialQuartalsPage);
}

export function* watchQuartalsPageSaga() {
  yield takeLatest(constants.INITIAL_QUARTALS_PAGE, invokeInitialQuartalsPage);
}
