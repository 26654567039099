import { IQuartal, IQuartalCreate, IQuartalUpdate } from '../../resource/quartal/quartal.types';
import { IApiRequestConfig } from '../api.types';

export const searchQuartalsApi = (): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'getQuartals',
  uri: 'json',
  action: 'getQuartals',
  method: 'get',
  params: {},
});

export const createQuartalApi = (quartal: IQuartalCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createQuartal',
  uri: 'json',
  action: 'saveQuartals',
  method: 'post',
  data: quartal,
});

export const updateQuartalApi = (quartal: IQuartalUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateQuartal',
  uri: 'json',
  action: 'saveQuartals',
  method: 'put',
  data: quartal,
});

export const deleteQuartalApi = (quartalId: IQuartal['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteQuartal',
  uri: 'json',
  action: 'deleteQuartals',
  method: 'delete',
  params: {
    id: quartalId,
  },
});
