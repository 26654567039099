import * as types from './passwords.types';
import * as constants from './passwords.constants';
import { IPassword, IPasswordSaveForm, IPasswordSearchParams } from '../../../resource/password/password.types';

export const searchPasswords = (searchParams?: IPasswordSearchParams): types.ISearchPasswords => ({
  type: constants.SEARCH_PASSWORDS,
  payload: searchParams,
});

export const savePassword = (passwordData: IPasswordSaveForm): types.ISavePassword => ({
  type: constants.SAVE_PASSWORD,
  payload: passwordData,
});

export const deletePassword = (passwordId: IPassword['id']): types.IDeletePassword => ({
  type: constants.DELETE_PASSWORD,
  payload: passwordId,
});

export const storePasswords = (passwordIds: IPassword['id'][]): types.IStorePasswords => ({
  type: constants.STORE_PASSWORDS,
  payload: passwordIds,
});

export const clearPasswrods = (): types.IClearPasswords => ({
  type: constants.CLEAR_PASSWORDS,
});

export const filterPasswords = (filterParams: types.IPasswordFilterParams): types.IFilterPasswords => ({
  type: constants.FILTER_PASSWORDS,
  payload: filterParams,
});

export const storeFilteredPasswords = (passwordIds: IPassword['id'][]): types.IStoreFilteredPasswords => ({
  type: constants.STORE_FILTERED_PASSWORDS,
  payload: passwordIds,
});

export const clearFilteredPasswrods = (): types.IClearFilteredPasswords => ({
  type: constants.CLEAR_FILTERED_PASSWORDS,
});

export const storeFilterParams = (filterParams: types.IPasswordFilterParams): types.IStoreFilterParams => ({
  type: constants.STORE_FILTER_PARAMS,
  payload: filterParams,
});

export const clearFilterParams = (): types.IClearFilterParams => ({
  type: constants.CLEAR_FILTER_PARAMS,
});
