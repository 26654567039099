import { call, put } from 'redux-saga/effects';

import * as types from './conclusion.types';
import * as actions from './conclusion.actions';
import * as api from '../../api/request-config/conclusion.api';

import { apiRequest } from '../../api/api.saga-services';
import { select } from '../../../utils/selector.utils';
import { conclusionSelectors } from './conclusion.selectors';

export function* storeConclusion(conclusion: types.IConclusion) {
  yield put(actions.storeConclusion(conclusion));
}

export function* clearConclusion(conclusionId: types.IConclusion['id']) {
  yield put(actions.clearConclusion(conclusionId));
}

export function* clearAllConclusions() {
  const conclusionIds: types.IConclusion['id'][] = yield select(conclusionSelectors.selectResourceIds);
  yield put(actions.clearConclusion(conclusionIds));
}

export function* createConclusion(data: types.IConclusionCreate) {
  const conclusion = (yield call(apiRequest, api.createConclusionApi(data))) as types.IConclusion;
  if (!conclusion) {
    return;
  }
  yield call(storeConclusion, conclusion);
  return conclusion.id;
}
