/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { FunctionComponent } from 'react';
import { taskSelectors } from '../../../../../../../../../../app/resource/task/task.selectors';
import { useSelectWithParams } from '../../../../../../../../../../utils/selector.utils';
import { MembersMenu } from '../../TaskMenus/MembersMenu';

import { ITaskMembersProps } from './types';
import { UserImg } from './UserImg';

export const TaskMembers: FunctionComponent<ITaskMembersProps> = ({ userIds, taskId }) => {
  const [isActive, setActive] = useState(false);
  const toggleClass = useCallback(() => {
    setActive(!isActive);
  }, [isActive]);
  //const close = useCallback(() => setActive(false), [setActive]);

  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId);

  return (
    task && (
      <div className="col">
        <h5 className="bold">MEMBERS</h5>
        <div className="users py-1 flex aic">
          {task.members.map((taskUserId) => (
            <UserImg key={`memberId-${taskUserId}`} userId={taskUserId} />
          ))}

          <div className={isActive ? 'dropdown opened' : 'dropdown'}>
            <a className="small-avatar gray-bg blueGray bold mr-05 flex aic jcc dropdown-button" onClick={toggleClass}>
              <i className="icon-plus" />
            </a>
            {isActive && <MembersMenu userIds={userIds} dropRight={false} closeMenu={toggleClass} taskId={task.id} />}
          </div>
        </div>
      </div>
    )
  );
};
