import { IMeeting, IMeetingNewCreateForm, IMeetingSearchParams } from '../../../resource/meeting/meeting.types';

import * as types from './meetings.types';
import * as constants from './meetings.constants';
import { ISession } from '../../../resource/session/session.types';

export const fetchMeetings = (searchParams?: IMeetingSearchParams): types.IFetchMeetings => ({
  type: constants.FETCH_MEETINGS,
  payload: searchParams,
});

// hot fix
// export const createMeeting = (meeting: IMeetingNewCreateForm): types.ICreateMeeting => ({
export const createMeeting = (meeting: IMeetingNewCreateForm): types.ICreateMeeting => ({
  type: constants.CREATE_MEETING,
  payload: meeting,
});

// hot fix
// export const updateMeeting = (meetingId: IMeeting['id'], meeting: IMeetingNewCreateForm): types.IUpdateMeeting => ({
export const updateMeeting = (meetingId: IMeeting['id'], meeting: IMeetingNewCreateForm): types.IUpdateMeeting => ({
  type: constants.UPDATE_MEETING,
  payload: {
    id: meetingId,
    data: meeting,
  },
});

export const deleteMeeting = (meetingId: IMeeting['id']): types.IDeleteMeeting => ({
  type: constants.DELETE_MEETING,
  payload: meetingId,
});

export const finishMeetingSession = (
  meetingId: IMeeting['id'],
  sessionId: ISession['id'],
): types.IFinishMeetingSession => ({
  type: constants.FINISH_MEETING_SESSION,
  payload: {
    meetingId,
    sessionId,
  },
});

export const storeReducedMeetings = (reducedMeetings: types.IReducedMeetings): types.IStoreReducedMeetings => ({
  type: constants.STORE_REDUCED_MEETINGS,
  payload: reducedMeetings,
});

export const clearReducedMeetings = (): types.IClearReducedMeetings => ({
  type: constants.CLEAR_REDUCED_MEETINGS,
});

export const storeFilteredMeetings = (filteredMeetings: IMeeting['id'][]): types.IStoreFilteredMeetings => ({
  type: constants.STORE_FILTERED_MEETINGS,
  payload: filteredMeetings,
});

export const clearFilteredMeetings = (): types.IClearFilteredMeetings => ({
  type: constants.CLEAR_FILTERED_MEETINGS,
});

export const storeCurrentMeeting = (meetingId: IMeeting['id']): types.IStoreCurrentMeeting => ({
  type: constants.STORE_CURRENT_MEETING,
  payload: meetingId,
});

export const clearCurrentMeeting = (): types.IClearCurrentMeeting => ({
  type: constants.CLEAR_CURRENT_MEETING,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
