import React, { FunctionComponent } from 'react';
import { INodeBodyProps } from './types';

export const NodeBody: FunctionComponent<INodeBodyProps> = ({ description }) => {
  return (
    <div>
      <p>{description}</p>
    </div>
  );
};
