/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
// import { useDispatch } from 'react-redux';

import { userSelectors } from '../../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../../utils/selector.utils';
import { TaskFormTrigger } from '../../../../../dialogs/TaskFormDialog/TaskFormTrigger';
import { TaskWidgetList } from '../../../widget-lists/TaskWidgetList';
import { WidgetWrapper } from '../../WidgetWrapper';
import { IWidgetTasksGroupedParams } from './types';

export const WidgetTasksGroupedByUser: FunctionComponent<IWidgetTasksGroupedParams> = ({
  type,
  name,
  actionElement,
  tasksPerUser,
  meetingId,
}) => {
  // const dispatch = useDispatch();

  const users = useSelectWithParams(userSelectors.selectResourcesById, Object.keys(tasksPerUser));

  return (
    <WidgetWrapper name={name} actionElement={actionElement}>
      <>
        {users.map(
          (user) =>
            user && (
              <TaskWidgetList
                reducedTasks={tasksPerUser[user.id]}
                actionElement={<TaskFormTrigger type={type} meetingId={meetingId} userId={user.id} />}
                key={`${type}: groupedTaskIdsByUserId[${user.id}]`}
                droppableId={`${type}/${user.id}`}
                name={`${user.firstName} ${user.lastName}`}
              />
            ),
        )}
        {tasksPerUser['uncategorised'] && (
          <TaskWidgetList
            reducedTasks={tasksPerUser['uncategorised']}
            droppableId={`${type}/uncategorised`}
            key={`${type}: groupedTaskIdsByUserId[uncategorised]`}
            name={`Uncategorised`}
          />
        )}
      </>
    </WidgetWrapper>
  );
};
