/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import { userSelectors } from '../../../../../../../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../../../../../../../utils/selector.utils';
import { IUserImg } from './types';

export const UserImg: FunctionComponent<IUserImg> = ({ userId }) => {
  const user = useSelectWithParams(userSelectors.selectResourceById, userId);
  return (
    user && (
      <a className="small-avatar mr-05">
        <img src={user.image} alt="" className="img-fluid" style={{ borderRadius: '50%' }} />
      </a>
    )
  );
};
