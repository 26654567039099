import React from 'react';
import { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { authSelectors } from '../../../../../app/auth/auth.selectors';
import { createTask } from '../../../../../app/page/page-storage/boardLists/boardLists.actions';
import { boardListSelectors } from '../../../../../app/resource/boards/boardList/boardList.selectors';

import { IBoardTaskForm, ITaskCreate } from '../../../../../app/resource/task/task.types';

import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { useSelect, useSelectWithParams } from '../../../../../utils/selector.utils';

import { FormSmallTextInput } from '../../inputs/SmallTextInput';

import { IBoardTaskFormProps } from './types';

export const BoardTaskForm: FunctionComponent<IBoardTaskFormProps> = ({ boardListId, meetingId }) => {
  const dispatch = useDispatch();
  const userId = useSelect(authSelectors.selectLoggedUser);
  const boardList = useSelectWithParams(boardListSelectors.selectResourceById, boardListId);
  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: IBoardTaskForm) => {
    const newData: ITaskCreate = {
      title: data.title,
      description: '',
      dueDate: '0000-00-00 00:00:00',
      userId: userId ? userId : '',
      meetingIds: [meetingId],
      status: '0',
      label: [],
      members: [],
      showInTasks: '1',
      taskTypeId: boardListId,
      boardId: boardList?.boardId ?? '',
    };

    dispatch(createTask(newData));
  };

  return (
    <FormProvider {...formProps}>
      <form
        id="board-task-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IBoardTaskForm))}
        method="post"
      >
        <div className="field-container">
          <FormSmallTextInput
            id="title"
            name="title"
            type="text"
            label="Card Title"
            placeholder="Enter Title"
            defaultValue=""
            validation={requiredValidation}
          />
        </div>
      </form>
    </FormProvider>
  );
};
