/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FunctionComponent } from 'react';

import { LabelMenu } from '../../TaskMenus/LabelMenu';
import { IMenuLabelsProps } from './types';

export const MenuLabels: FunctionComponent<IMenuLabelsProps> = ({ taskId }) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <div className={isActive ? 'dropdown opened' : 'dropdown'}>
      <a className="btn w100 gray-bg darkGray px-1 f-1 ml-0 mb-1 mt-1 dropdown-button " onClick={toggleClass}>
        <img src={require('../../../../../../../../../resources/images/labels-icon.png')} className="mr-1" alt="img" />
        Labels
      </a>
      {isActive && <LabelMenu dropRight={true} closeMenu={toggleClass} taskId={taskId} />}
    </div>
  );
};
