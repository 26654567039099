import moment from 'moment';

import { IEvent } from '../event/event.types';
import { IResource, IResourceAction, IResourceSelectors, IResourceState } from '../generators/resource.types';
import { IUser } from '../user/user.types';

export interface ICalendar extends IResource {
  title: string;
  timezone: string;
  colorId: string;
  backgroundColor: string;
  userId: IUser['id'];

  eventIds: IEvent['id'][] | null;
}

export interface ICalendarSearchParams {
  calendarId: ICalendar['id'];
}

export type ICalendarAction = IResourceAction<ICalendar>;
export type ICalendarState = IResourceState<ICalendar>;
export type ICalendarSelectors = IResourceSelectors<ICalendar>;

export const YEAR = moment().format('YYYY');
export const CURRENT_DATE = moment().format('YYYY-MM-DD');
