/* eslint-disable @typescript-eslint/no-unused-vars */
import { get } from 'lodash';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IAvatarFileInputProps } from './types';
// import { AccountCircle } from '@material-ui/icons';
// import { Avatar } from '../../../Avatar';

export const AvatarFileInput: FunctionComponent<IAvatarFileInputProps> = ({
  id,
  name,
  defaultValue,
  validation,
  disabled,
  label,
  maxImageSize,
  acceptableFormats,
}) => {
  const [src, setSrc] = useState(defaultValue); // za sta se src koristi?
  const { control, errors, setError, clearErrors } = useFormContext();
  console.log(defaultValue);
  const onImageInput = useCallback(
    (event, onChange) => {
      //console.log('USAO');
      if (event && event.currentTarget && event.currentTarget.files && event.currentTarget.files[0]) {
        const fileBlob = event.currentTarget.files[0];
        const filesize = (fileBlob.size / 1024 / 1024).toFixed(4);
        if (+filesize <= maxImageSize) {
          setSrc(URL.createObjectURL(fileBlob)); // cemu ovo?
          const reader = new FileReader();
          reader.readAsDataURL(fileBlob);
          reader.onloadend = () => {
            onChange(reader.result);
          };
          clearErrors(name);
        } else {
          setError(name, { type: 'maxLength', message: `Image should be lower size then ${maxImageSize}MB` });
        }
      }
    },
    [clearErrors, maxImageSize, name, setError],
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ''}
      rules={validation}
      render={(controllerProps) => (
        <>
          <input
            type="file"
            id="upload-button"
            name={name}
            style={{ display: 'none' }}
            onChange={(event) => onImageInput(event, controllerProps.onChange)}
            accept="image/*"
          />

          <div className="new-account-desc" style={{ paddingLeft: '25px' }}>
            {!!get(errors, name, false) && (
              <label htmlFor={name} style={{ color: 'red' }}>
                {get(errors, `${name}.message`)}
              </label>
            )}

            <p className="bold">{label}</p>
            <p className="small-text">
              {`Acceptable formats: ${acceptableFormats ? acceptableFormats : 'All'}. Max size: ${maxImageSize}mb.`}
            </p>
          </div>
        </>
      )}
    />
  );
};
