/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { authSelectors } from '../../../app/auth/auth.selectors';
import { openDialog } from '../../../app/dialog/dialog.actions';
import { pageStateSelectors } from '../../../app/page/page-state/page-state.selectors';
import { createUserGroup } from '../../../app/page/page-storage/user-groups/user-groups.actions';
import { userGroupsPageSelectors } from '../../../app/page/page-storage/user-groups/user-groups.selectors';
import { createUser } from '../../../app/page/page-storage/users/users.actions';
import { usersPageSelectors } from '../../../app/page/page-storage/users/users.selectors';
import { initialUserPage } from '../../../app/page/users/users.actions';
import { IUserGroupCreate } from '../../../app/resource/user-group/user-group.types';
import { IUser, IUserCreateForm } from '../../../app/resource/user/user.types';
import { useApiRequestState } from '../../../utils/api.utils';
import { useSelect, useSelectWithParams } from '../../../utils/selector.utils';
import { PageLayout } from '../common/PageLayout';
import { UserGroups } from './UserGroups';
import { UserInfo } from './UserInfo';
import { UserList } from './UserList';

export const UserPage: FunctionComponent = () => {
  const showLoader = useSelectWithParams(pageStateSelectors.selectPageInProgress, 'users');

  const loggedUserId = useSelect(authSelectors.selectLoggedUser);
  const [currentUserId, setCurrentUserId] = useState<IUser['id'] | null>(loggedUserId);
  const userGroupIds = useSelect(userGroupsPageSelectors.selectUserGroupIds);

  const userIds = useSelect(usersPageSelectors.selectUserIds);
  const [focusUserIds, setFocusUserIds] = useState(userIds);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialUserPage());
  }, [dispatch]);

  useEffect(() => {
    setFocusUserIds(userIds);
  }, [userIds]);

  const [deleteState] = useApiRequestState('deleteUser');

  useEffect(() => {
    if (deleteState === true) {
      setCurrentUserId(loggedUserId);
    }
  }, [deleteState, loggedUserId]);

  const openUserForm = useCallback(() => {
    dispatch(
      openDialog('userFormDialog', {
        onSubmit: (user: IUserCreateForm) => {
          dispatch(createUser(user));
        },
      }),
    );
  }, [dispatch]);

  const handleUserSelect = useCallback((userId: IUser['id']) => {
    setCurrentUserId(userId);
  }, []);

  const openUserGroupForm = useCallback(() => {
    dispatch(
      openDialog('userGroupFormDialog', {
        onSubmit: (user: IUserGroupCreate) => {
          dispatch(createUserGroup(user));
        },
      }),
    );
  }, [dispatch]);
  return (
    <PageLayout showLoader={showLoader}>
      <div className="row mb-25">
        <div className="col-12">
          <div className="panel">
            <div className="panel-header no-border">
              <h2 className="bold">Directory</h2>
              <div className="ml-auto flex aic jcr">
                <a className="btn btn-md green-bg" title="New contact" onClick={openUserForm}>
                  <i className="icon-plus mr-1"></i> New Contact
                </a>
              </div>
            </div>
            <div className="panel-body pt-0 ho-hover">
              <div className="border-radius-1 lightGray-bg p-2">
                <div className="row">
                  <div className="col-3">
                    <a
                      style={{ cursor: 'pointer' }}
                      className="directory-button panel flex aic px-1 mb-1"
                      onClick={() => setFocusUserIds(userIds)}
                    >
                      <i className="icon-user-full mr-1 blueGray f-2"></i> All users
                    </a>
                    <UserGroups groupIds={userGroupIds} onFocusUserGroup={setFocusUserIds} />
                    <a
                      style={{ cursor: 'pointer' }}
                      className="directory-button panel blueGray flex aic px-1"
                      onClick={openUserGroupForm}
                    >
                      <i className="icon-plus mr-1 blueGray f-2"></i> New Role
                    </a>
                  </div>
                  <div className="col-9">
                    <div className="panel panel-body w100 p-0 no-hober">
                      <div className="row">
                        <UserList userIds={focusUserIds} onUserSelect={handleUserSelect} />
                        {currentUserId && <UserInfo selectedUserId={currentUserId} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
