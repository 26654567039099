import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './meeting-dashboard.constants';
import * as types from './meeting-dashboard.types';
import * as services from './meeting-dashboard.saga-services';

function* invokeInitialMeetingDashboard(action: types.IInitialMeetingDashboardPage) {
  yield call(services.initialMeetingDashboardPage, action.payload);
}

export function* watchMeetingDashboardPageSaga() {
  yield takeLatest(constants.INITIAL_MEETING_DASHBOARD_PAGE, invokeInitialMeetingDashboard);
}
