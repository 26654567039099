/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initialChartPage } from '../../../app/page/chart/chart.actions';
import { PageLayout } from '../common/PageLayout';
import { chartPageSelectors } from '../../../app/page/page-storage/chart/chart.selectors';
import { useSelect, useSelectWithParams } from '../../../utils/selector.utils';
import { DefaultChart } from './DefaultChart';
import { openDialog } from '../../../app/dialog/dialog.actions';
import { pageStateSelectors } from '../../../app/page/page-state/page-state.selectors';
import Loader from 'react-loader-spinner';

export const ChartPage: FunctionComponent = () => {
  const dispatch = useDispatch();

  const nodeIds = useSelect(chartPageSelectors.selectNodes);
  const showSpinner = useSelectWithParams(pageStateSelectors.selectPageInProgress, 'chart');
  useEffect(() => {
    dispatch(initialChartPage());
  }, [dispatch]);

  const openNodeFormDialog = useCallback(() => {
    dispatch(openDialog('nodeFormDialog'));
  }, [dispatch]);

  return (
    <PageLayout>
      {nodeIds.length > 0 && !showSpinner && <DefaultChart nodeIds={nodeIds} />}
      {nodeIds.length === 0 && !showSpinner && (
        <a className="btn-badge green-bg popup_button" onClick={openNodeFormDialog}>
          <i className="icon-plus"></i>
        </a>
      )}
      {showSpinner && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <Loader type="TailSpin" color="#1bc3a7" height={40} width={40} />
        </div>
      )}
    </PageLayout>
  );
};
