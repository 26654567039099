import * as types from './meetings-info.types';
import * as constants from './meetings-info.constants';

export const fetchMeetingsInfo = (): types.IFetchMeetingsInfo => ({
  type: constants.FETCH_MEETINGS_INFO,
});

export const storeReducedMeetingsInfo = (
  reducedMeetingsInfo: types.IReducedMeetingsInfo,
): types.IStoreReducedMeetingsInfo => ({
  type: constants.STORE_REDUCED_MEETINGS_INFO,
  payload: reducedMeetingsInfo,
});

export const clearReducedMeetingsInfo = (): types.IClearReducedMeetingsInfo => ({
  type: constants.CLEAR_REDUCED_MEETINGS_INFO,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
