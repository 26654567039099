import { createActionConst } from '../../../../utils/action.utils';

const PROJECT_TASK_NAMESPACE = 'PROJECT_TASKS';

export const CREATE_PROJECT_TASK = createActionConst(PROJECT_TASK_NAMESPACE, 'CREATE_PROJECT_TASK');
export const UPDATE_PROJECT_TASK = createActionConst(PROJECT_TASK_NAMESPACE, 'UPDATE_PROJECT_TASK');
export const DELETE_PROJECT_TASK = createActionConst(PROJECT_TASK_NAMESPACE, 'DELETE_PROJECT_TASK');

export const STORE_IS_FETCHED = createActionConst(PROJECT_TASK_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(PROJECT_TASK_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_PROJECT_TASK = createActionConst(PROJECT_TASK_NAMESPACE, 'STORE_PROJECT_TASK');
export const CLEAR_PROJECT_TASK = createActionConst(PROJECT_TASK_NAMESPACE, 'CLEAR_PROJECT_TASK');

export const SAVE_PROJECT_TASK_SOCKET = 'saveProjectTasks';
export const DELETE_PROJECT_TASK_SOCKET = 'deleteProjectTasks';
