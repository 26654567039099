/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { userSelectors } from '../../../../../app/resource/user/user.selectors';
import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { useSelect } from '../../../../../utils/selector.utils';

import { SelectInput } from '../../inputs/SelectInput';
import { FormTextInput } from '../../inputs/TextInput';
import { INodeFormProps } from './types';

import { INodeCreateForm, IOrganizationChartNode } from '../../../../../app/resource/node/node.types';

import { createNode, updateNode } from '../../../../../app/page/page-storage/chart/chart.actions';

export const NodeForm: FunctionComponent<INodeFormProps> = ({ nodeData, parentId }) => {
  const dispatch = useDispatch();

  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: INodeCreateForm, nodeId?: IOrganizationChartNode['id']) => {
    if (nodeId && nodeData) {
      dispatch(updateNode(nodeId, { ...nodeData, ...data }));
    } else {
      dispatch(createNode({ ...data, parentId }));
    }
  };

  const users = useSelect(userSelectors.selectResourceList);

  const options = useMemo(
    () =>
      users.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })),
    [users],
  );
  const coworkersDefaultOptions = useMemo(
    () => (nodeData ? options.filter((option) => nodeData.coworkers.includes(option.value)) : undefined),
    [nodeData, options],
  );

  const defaultUser = useMemo(
    () => (nodeData ? options.find((option) => nodeData.userId === option.value) : undefined),
    [nodeData, options],
  );

  return (
    <FormProvider {...formProps}>
      <form
        id="node-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as INodeCreateForm, nodeData?.id))}
        method="post"
      >
        <div className="field-container">
          <SelectInput
            id="user"
            name="user"
            label="User"
            placeholder="Search..."
            options={options}
            defaultOptions={defaultUser}
            isSingle
            validation={requiredValidation}
          />
        </div>
        <div className="field-container">
          <FormTextInput
            id="description"
            name="description"
            type="text"
            label="Description"
            placeholder="Enter Description"
            defaultValue={nodeData ? nodeData.description : ''}
            validation={requiredValidation}
          />
        </div>

        <div className="field-container">
          <SelectInput
            id="coworkers"
            name="coworkers"
            label="Coworkers"
            placeholder="Search..."
            options={options}
            defaultOptions={coworkersDefaultOptions}
          />
        </div>
      </form>
    </FormProvider>
  );
};
