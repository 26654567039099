/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { setupProjectTimes } from '../../../../../app/page/page-storage/project-times/project-times.actions';
import { projectTimesPageSelectors } from '../../../../../app/page/page-storage/project-times/project-times.selectors';
import { projectTimeSelectors } from '../../../../../app/resource/project-time/project-time.selectors';
import { IProjectTasksTime } from '../../../../../app/resource/project-time/project-time.types';

import { useSelect, useSelectWithParams } from '../../../../../utils/selector.utils';
import { TaskTime } from './TaskTime';

export const TimeTab: FunctionComponent = () => {
  const currentWeek = moment().isoWeek();
  const [week, setWeek] = useState(currentWeek);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setupProjectTimes(week.toString()));
  }, [dispatch, week]);

  const [day, setDay] = useState(moment().format('dddd, D MMMM'));

  const setSelectedWeekDay = useCallback(
    (next: number) => {
      setDay(
        moment()
          .startOf('isoWeek')
          .week(next === 1 ? week + 2 : week)
          .day(1)
          .format('dddd, D MMMM'),
      );
    },
    [week],
  );

  const setSelectedDay = useCallback(
    (dayOfWeek: number) => {
      setDay(
        moment()
          .startOf('isoWeek')
          .week(week + 1)
          .day(dayOfWeek)
          .format('dddd, D MMMM'),
      );
    },
    [week],
  );
  const selectedDay = moment().day(day).format('dddd');

  const today = moment().format('dddd, D MMMM');

  const setNextWeek = useCallback(() => {
    setWeek(week + 1);
    setSelectedWeekDay(1);
  }, [setSelectedWeekDay, week]);

  const setPrevWeek = useCallback(() => {
    setWeek(week - 1);
    setSelectedWeekDay(0);
  }, [setSelectedWeekDay, week]);

  const timeIds = useSelect(projectTimesPageSelectors.selectProjectTimes);
  const times = useSelectWithParams(projectTimeSelectors.selectResourcesById, timeIds) as IProjectTasksTime[];

  const dayOfWeek =
    Number(moment().day(day).format('d')) - 1 < 0
      ? Number(moment().day(day).format('d')) + 6
      : Number(moment().day(day).format('d')) - 1;

  const selectedDate = useMemo(() => moment().isoWeek(week).isoWeekday(day).format('YYYY-MM-DD'), [day, week]);

  const tasksTimes = useMemo(
    () => times.filter((time) => time && time.changedAt === selectedDate) as IProjectTasksTime[],
    [selectedDate, times],
  );

  const dayArray = useMemo(() => {
    const days = [
      { day: 'Monday', time: '0:00' },
      { day: 'Tuesday', time: '0:00' },
      { day: 'Wednesday', time: '0:00' },
      { day: 'Thursday', time: '0:00' },
      { day: 'Friday', time: '0:00' },
      { day: 'Saturday', time: '0:00' },
      { day: 'Sunday', time: '0:00' },
      { weekTotal: '0:00' },
    ];
    let total = 0;
    let totalMonday = 0;
    let totalTuesday = 0;
    let totalWednesday = 0;
    let totalThursday = 0;
    let totalFriday = 0;
    let totalSaturday = 0;
    let totalSunday = 0;

    times.forEach((time) => {
      time ? (total = total + Number(time.minutes)) : (total = total + 0);
      const dayName = moment(time.changedAt).format('dddd');

      if (dayName === 'Monday') {
        totalMonday += Number(time.minutes);
      }
      if (dayName === 'Tuesday') {
        totalTuesday += Number(time.minutes);
      }
      if (dayName === 'Wednesday') {
        totalWednesday += Number(time.minutes);
      }
      if (dayName === 'Thursday') {
        totalThursday += Number(time.minutes);
      }
      if (dayName === 'Friday') {
        totalFriday += Number(time.minutes);
      }
      if (dayName === 'Saturday') {
        totalSaturday += Number(time.minutes);
      }
      if (dayName === 'Sunday') {
        totalSunday += Number(time.minutes);
      }
    });
    days[0].time = `${Math.floor(totalMonday / 60)}:${
      totalMonday.toString().length < 2 ? `0${totalMonday % 60}` : `${totalMonday % 60}`
    }`;

    days[1].time = `${Math.floor(totalTuesday / 60)}:${
      totalTuesday.toString().length < 2 ? `0${totalTuesday % 60}` : `${totalTuesday % 60}`
    }`;
    days[2].time = `${Math.floor(totalWednesday / 60)}:${
      totalWednesday.toString().length < 2 ? `0${totalWednesday % 60}` : `${totalWednesday % 60}`
    }`;
    days[3].time = `${Math.floor(totalThursday / 60)}:${
      totalThursday.toString().length < 2 ? `0${totalThursday % 60}` : `${totalThursday % 60}`
    }`;
    days[4].time = `${Math.floor(totalFriday / 60)}:${
      totalFriday.toString().length < 2 ? `0${totalFriday % 60}` : `${totalFriday % 60}`
    }`;
    days[5].time = `${Math.floor(totalSaturday / 60)}:${
      totalSaturday.toString().length < 2 ? `0${totalSaturday % 60}` : `${totalSaturday % 60}`
    }`;
    days[6].time = `${Math.floor(totalSunday / 60)}:${
      totalSunday.toString().length < 2 ? `0${totalSunday % 60}` : `${totalSunday % 60}`
    }`;
    days[7].weekTotal = `${Math.floor(total / 60)}:${total % 60}`;
    return days;
  }, [times]);

  return (
    <>
      <div className="panel white-bg flex px-2 py-1">
        <div className="week-timer-buttons flex mr-2">
          <span className="btn btn-sm white-bg btn-border" onClick={setPrevWeek}>
            <i className="icon-left-arrow blueGray transition2" />
          </span>
          <span className="btn btn-sm white-bg btn-border" onClick={setNextWeek}>
            <i className="icon-left-arrow blueGray transition2 icon-rotated" />
          </span>
        </div>
        <h2 className="flex aic f-2">
          <span className="bold">{`${day === today ? 'Today' : ''}`}</span>
          {`${day === today ? ':' : ''}`} {day}
        </h2>
        <div className="ml-auto flex aic jcr">
          {/* <a class="btn btn-md white-bg btn-border popup_button darkGray" data-popup="new_team_popup" title="Submit Week for Approval">Submit Week for Approval</a> */}
          <a className="btn btn-md white-bg btn-border px-1">
            <i className="icon-calendar blueGray" />
          </a>
          <a className="btn btn-md green-bg new_tracker" title="New Entry">
            <i className="icon-plus mr-1" /> New Entry
          </a>
        </div>
      </div>
      <div className="panel white-bg p-2 mt-2">
        <div className="border-radius-1 lightGray-bg p-05 w100">
          <div className="tracking-weeks">
            <span
              className={`tracking-week ${selectedDay === 'Monday' ? 'current' : ''}`}
              onClick={() => setSelectedDay(1)}
            >
              <b className="week-day">{dayArray[0].day}</b>
              <span className="week-time">{dayArray[0].time}</span>
            </span>
            <span
              className={`tracking-week ${selectedDay === 'Tuesday' ? 'current' : ''}`}
              onClick={() => setSelectedDay(2)}
            >
              <b className="week-day">{dayArray[1].day}</b>
              <span className="week-time">{dayArray[1].time}</span>
            </span>
            <span
              className={`tracking-week ${selectedDay === 'Wednesday' ? 'current' : ''}`}
              onClick={() => setSelectedDay(3)}
            >
              <b className="week-day">{dayArray[2].day}</b>
              <span className="week-time">{dayArray[2].time}</span>
            </span>
            <span
              className={`tracking-week ${selectedDay === 'Thursday' ? 'current' : ''}`}
              onClick={() => setSelectedDay(4)}
            >
              <b className="week-day">{dayArray[3].day}</b>
              <span className="week-time">{dayArray[3].time}</span>
            </span>
            <span
              className={`tracking-week ${selectedDay === 'Friday' ? 'current' : ''}`}
              onClick={() => setSelectedDay(5)}
            >
              <b className="week-day">{dayArray[4].day}</b>
              <span className="week-time">{dayArray[4].time}</span>
            </span>
            <span
              className={`tracking-week ${selectedDay === 'Saturday' ? 'current' : ''}`}
              onClick={() => setSelectedDay(6)}
            >
              <b className="week-day">{dayArray[5].day}</b>
              <span className="week-time">{dayArray[5].time}</span>
            </span>
            <span
              className={`tracking-week ${selectedDay === 'Sunday' ? 'current' : ''}`}
              onClick={() => setSelectedDay(7)}
            >
              <b className="week-day">{dayArray[6].day}</b>
              <span className="week-time">{dayArray[6].time}</span>
            </span>
          </div>
        </div>
        <div className="tracking-table">
          {tasksTimes.map((taskTime: IProjectTasksTime) => (
            <TaskTime taskTime={taskTime} key={taskTime.id} />
          ))}

          <div className="tracking-row">
            <div className="tracking-actions time-totals">
              <div className="tracking-total-time green bold">Day total: {dayArray[dayOfWeek].time}</div>
              <div className="tracking-week-total-time blueGray">Week total: {dayArray[7].weekTotal}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
