import { call, takeLatest } from 'redux-saga/effects';

import * as types from './auth.types';
import * as constants from './auth.constants';
import * as services from './auth.saga-services';

function* invokeLogin(action: types.ILogin) {
  yield call(services.logIn, action.payload);
}

function* invokeLogout(action: types.ILogout) {
  yield call(services.logOut);
}
function* invokeStoreGooglePermisionGranted(action: types.IStoreGoogleGrantedPermision) {
  yield call(services.storeGooglePermisionGranted, action.payload);
}

function* invokeForgotPasswordEmail(action: types.IForgotPasswordEmail) {
  yield call(services.forgotPasswordEmail, action.payload);
}

function* invokeSaveNewPassword(action: types.ISaveNewPassword) {
  yield call(services.saveNewPassword, action.payload);
}

export function* watchAuthSaga() {
  yield takeLatest(constants.LOGIN, invokeLogin);
  yield takeLatest(constants.LOGOUT, invokeLogout);
  yield takeLatest(constants.STORE_GOOGLE_GRANTED_PERMISION, invokeStoreGooglePermisionGranted);
  yield takeLatest(constants.FORGOT_PASSWORD_EMAIL, invokeForgotPasswordEmail);
  yield takeLatest(constants.SAVE_NEW_PASSWORD, invokeSaveNewPassword);
}
