import {
  IProjectTask,
  IProjectTaskCreate,
  IProjectTaskSearchParams,
  IProjectTaskUpdate,
} from '../../resource/project-task/project-task.types';
import { IApiRequestConfig } from '../api.types';

export const searchProjectTasksApi = (searchParams?: IProjectTaskSearchParams): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'getProjectTasks',
  uri: 'json',
  action: 'getProjectTasks',
  method: 'get',
  params: {
    ...searchParams,
  },
});

export const createProjectTaskApi = (projectTask: IProjectTaskCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createProjectTask',
  uri: 'json',
  action: 'saveProjectTask',
  method: 'post',
  data: projectTask,
});

export const updateProjectTaskApi = (projectTask: IProjectTaskUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateProjectTask',
  uri: 'json',
  action: 'saveProjectTask',
  method: 'put',
  data: projectTask,
});

export const deleteProjectTaskApi = (projectTaskId: IProjectTask['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteProjectTask',
  uri: 'json',
  action: 'deleteProjectTask',
  method: 'delete',
  params: {
    id: projectTaskId,
  },
});
