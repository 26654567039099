import React, { FunctionComponent, useMemo } from 'react';
import { getDaysOfWeek } from '../../../../../../../utils/date.utils';
import { CalendarDayView } from './CalendarDayView';
import { IWeekViewProps } from './types';

export const WeekView: FunctionComponent<IWeekViewProps> = ({ date, meetingId, userFilter }) => {
  const daysOfWeek = useMemo(() => {
    return getDaysOfWeek(date);
  }, [date]);

  return (
    <>
      <div className="calendar-rendered calendar-week-view">
        <div className="calendar-days">
          <div className="calendar-cell">
            <div className="calendar-cell-day">MON</div>
          </div>
          <div className="calendar-cell">
            <div className="calendar-cell-day">TUE</div>
          </div>
          <div className="calendar-cell">
            <div className="calendar-cell-day">WED</div>
          </div>
          <div className="calendar-cell">
            <div className="calendar-cell-day">THU</div>
          </div>
          <div className="calendar-cell">
            <div className="calendar-cell-day">FRI</div>
          </div>
          <div className="calendar-cell">
            <div className="calendar-cell-day">SAT</div>
          </div>
          <div className="calendar-cell">
            <div className="calendar-cell-day">SUN</div>
          </div>
        </div>
        <div className="calendar-week-main-view">
          <div className="week-view-hours">
            <div className="week-view-one-hour">8am</div>
            <div className="week-view-one-hour">9am</div>
            <div className="week-view-one-hour">10am</div>
            <div className="week-view-one-hour">11am</div>
            <div className="week-view-one-hour">12pm</div>
            <div className="week-view-one-hour">1pm</div>
            <div className="week-view-one-hour">2pm</div>
            <div className="week-view-one-hour">3pm</div>
            <div className="week-view-one-hour">4pm</div>
            <div className="week-view-one-hour">5pm</div>
            <div className="week-view-one-hour">6pm</div>
            <div className="week-view-one-hour">7pm</div>
            <div className="week-view-one-hour">8pm</div>
          </div>
          <div className="week-view-cells">
            {daysOfWeek.map((date) => (
              <div key={`date-${date}`} style={{ width: 'calc(100% / 7)' }}>
                <CalendarDayView meetingId={meetingId} date={date} userFilter={userFilter} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
