/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { useApiRequestState } from '../../../utils/api.utils';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { SubmitButton } from '../../pages/common/forms/SubmitButton';
import { TaskForm } from '../../pages/common/forms/TaskForm';
import { ConnectedDialog } from '../ConnectedDialog';
import { ITaskFormDialogProps } from './types';

export const TaskFormDialog: FunctionComponent = () => {
  const dispatch = useDispatch();

  const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'taskFormDialog') as ITaskFormDialogProps;
  const [showLoader, error] = useApiRequestState('createTaskApi');

  const onClose = useCallback(() => {
    dispatch(closeDialog('taskFormDialog'));
  }, [dispatch]);

  const title = useMemo(() => {
    let title = 'Add ';

    if (props) {
      if (props.task) {
        title = 'Edit ';
      }
      if (props.type === '4') {
        title += 'To Do';
      } else if (props.type === '2') {
        title += 'Issue';
      } else if (props.type === '1') {
        title += 'Quarterly Initiatives';
      } else if (props.type === '5') {
        title += 'Backburner';
      } else if (props.type === '3') {
        title += 'Headline';
      }
    }
    return title;
  }, [props]);
  return (
    <ConnectedDialog dialogKey="taskFormDialog">
      <div className="popup new_todo_popup" style={{ display: 'block' }}>
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            {title}

            <span className="close-popup close" onClick={onClose}>
              {!error && !showLoader && <i className="icon-close" />}
              {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
              {error && !showLoader && <p style={{ color: 'red' }}> Something went wrong </p>}
            </span>
          </div>
          <div className="panel-body" style={{ maxHeight: '619.1px' }}>
            <TaskForm {...props} />
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" onClick={onClose}>
              Close
            </a>

            {!props && <SubmitButton apiKey="createTaskApi" formName="task-form" text="Save" />}
            {props && <SubmitButton apiKey="updateTaskApi" formName="task-form" text="Save" />}
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
