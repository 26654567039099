import React, { FunctionComponent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { ISectionCreate } from '../../../../../app/resource/section/section.types';
import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { FormTextInput } from '../../../common/inputs/TextInput';
import { ISectionFormProps } from './types';
import 'react-quill/dist/quill.snow.css';

// import { DateTimeInput } from '../../common/inputs/DateTimeInput';

export const SectionForm: FunctionComponent<ISectionFormProps> = ({ templateId, data, onSubmit }) => {
  const formProps = useForm();
  const requiredValidation = useRequiredValidation();

  const [value, setValue] = useState(data ? data.description : '');
  return (
    <FormProvider {...formProps}>
      <form
        id="section-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) =>
          onSubmit({ ...formData, description: value, templateId } as ISectionCreate, data?.id),
        )}
        action=""
        method="post"
      >
        <div className="col-12" style={{ marginTop: '10px' }}>
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.title}
              id="title"
              name="title"
              type="text"
              label="Title"
              placeholder="Enter Title"
              validation={requiredValidation}
            />
          </div>
        </div>

        <div className="col-12" style={{ marginTop: '10px' }}>
          <div className="field-container">
            <label htmlFor="section_description">
              Description
              {/* <span className="error-msg">Something is wrong.</span> */}
            </label>
            <ReactQuill theme="snow" value={value} onChange={setValue} />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
