export const cutAndInstert = (
  sourceList: string[],
  sourceIndex: number,
  destinationIndex: number,
  destinationList?: string[],
) => {
  const elem = sourceList[sourceIndex];
  const copySourceList = [...sourceList];

  if (destinationList) {
    copySourceList.splice(sourceIndex, 1);
    const copyDestinationList = [...destinationList];
    copyDestinationList.splice(destinationIndex, 0, elem);
    return [copySourceList, copyDestinationList];
  }
  copySourceList.splice(sourceIndex, 1).splice(destinationIndex, 0, elem);
  copySourceList.splice(destinationIndex, 0, elem);

  return [copySourceList];
};

// test
// const sourceTest = ['1', '2', '3', '4', '5', '6'];
// const destinationTest = ['7', '8', '9', '10', '11', '12'];
//
// console.log(cutAndInstert(sourceTest, 1, 3, destinationTest));
// console.log(cutAndInstert(sourceTest, 3, 1));
