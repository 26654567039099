import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../../../../../app/dialog/dialog.actions';
import { saveScoreboard } from '../../../../../../../../../app/page/page-storage/kpi/kpi.actions';
import { kpiRowScoreboardSelectors } from '../../../../../../../../../app/resource/kpi-row-scoreboard/kpi-row-scoreboard.selectors';
import { scoreSelectors } from '../../../../../../../../../app/resource/score/score.selectors';
import { useDebounce } from '../../../../../../../../../utils/debounce.utils';
import { useSelectWithParams } from '../../../../../../../../../utils/selector.utils';
import { IKpiRowScoreboardProps } from './types';

export const KpiRowScoreboard: FunctionComponent<IKpiRowScoreboardProps> = ({ scoreId, kpiRowScoreboardId }) => {
  const dispatch = useDispatch();
  const scoreboard = useSelectWithParams(kpiRowScoreboardSelectors.selectResourceById, kpiRowScoreboardId);
  const score = useSelectWithParams(scoreSelectors.selectResourceById, scoreId);
  //console.log(score);
  const [measure, setMeasure] = useState('');
  useEffect(() => {
    if (scoreboard) {
      if (scoreboard.measured === '' || scoreboard.measured === null) {
        setMeasure('');
      } else {
        setMeasure(`${Math.ceil(Number(scoreboard.measured))}`);
      }
    }
    //setMeasure(scoreboard ? `${Math.ceil(Number(scoreboard.measured))}` : '');
  }, [scoreboard]);

  const inputClass = useMemo(() => {
    const green = 'lightGreen-bg green';
    const red = 'lightRed-bg red';
    const gray = '';
    // if (scoreboard) {
    //   return gray;
    // }
    if (!score) {
      return gray;
    }
    if (scoreboard && scoreboard.measured !== null && scoreboard.measured.toString() !== '') {
      if (score.goal === '>=') {
        return Number(measure) >= +score.score ? green : red;
      } else if (score.goal === '<=') {
        return Number(measure) <= +score.score ? green : red;
      } else if (score.goal === '==') {
        return Number(measure) === +score.score ? green : red;
      }
    } else {
      return gray;
    }

    return red;
  }, [measure, score, scoreboard]);

  const saveScoreboardDebounce = useDebounce(
    (measured: string) => {
      if (scoreboard && scoreboard.measured !== measured) {
        dispatch(saveScoreboard({ ...scoreboard, measured: measured }));
      }
    },
    500,
    [dispatch, scoreboard],
  );

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const measured = event.target.value;
      setMeasure(measured);
      //setMeasure(`${Math.ceil(measured)}`);
      saveScoreboardDebounce(measured);
    },
    [saveScoreboardDebounce],
  );

  const openKpiNoteFormDialog = useCallback(() => {
    dispatch(openDialog('kpiNoteFormDialog', { scoreboard }));
  }, [dispatch, scoreboard]);

  return (
    scoreboard && (
      <div className="kpi-date kpi-input">
        <input type="text" className={inputClass} value={measure} onChange={onChange} />
        {inputClass === 'lightRed-bg red' ? (
          <span
            className={`white-bg ${
              scoreboard.note ? 'lightGreen-border' : 'lightRed-border'
            } round-icon-smaller popup_button`}
            data-popup="new_note_popup"
            onClick={openKpiNoteFormDialog}
          >
            <i className="icon-pencil" />
          </span>
        ) : (
          <></>
        )}
      </div>
    )
  );
};
