import { all, call, fork, takeLatest } from 'redux-saga/effects';

import * as constants from './categories.constants';
import * as types from './categories.types';
import * as services from './categories.saga-services';

function* invokeSetupCategory(action: types.ISetupCategory) {
  yield call(services.setupCategories);
}

// function* invokeCreateCategory(action: types.ICreateCategory) {
//   yield call(services.createCategory, action.payload);
// }

// function* invokeUpdateCategory(action: types.IUpdateCategory) {
//   yield call(services.updateCategory, action.payload.id, action.payload.data);
// }
// function* invokeDeleteCategory(action: types.IDeleteCategory) {
//   yield call(services.deleteCategory, action.payload.CategoryId, action.payload.accountId);
// }
/*
function* invokeSaveCategorySocket(action: types.ISaveCategorySocket) {
  yield call(services.handleCategoryFromNotification, action.payload);
}
*/
export function* watchCategoriesPurePageSaga() {
  yield takeLatest(constants.SETUP_CATEGORY, invokeSetupCategory);
  // yield takeLatest(constants.CREATE_CATEGORY, invokeCreateCategory);
  // yield takeLatest(constants.UPDATE_CATEGORY, invokeUpdateCategory);
  // yield takeLatest(constants.DELETE_CATEGORY, invokeDeleteCategory);

  //yield takeLatest(constants.SAVE_Category__SOCKET, invokeSaveCategorySocket);
}

export function* watchCategoriesPageStorageSaga() {
  yield all([watchCategoriesPurePageSaga].map(fork));
}
