import { all, call, fork, takeEvery, takeLatest } from 'redux-saga/effects';

import * as constants from './users.constants';
import * as types from './users.types';
import * as services from './users.saga-services';
import { watchUsersFormSaga } from './user-form/user-form.saga-watchers';

function* invokeSetupUsers(action: types.IFetchUsers) {
  yield call(services.setupUsers, action.payload);
}

function* invokeSignUpUser(action: types.ISignUpUser) {
  yield call(services.signUpUser, action.payload);
}

function* invokeCreateUser(action: types.ICreateUser) {
  yield call(services.createUser, action.payload);
}

function* invokeUpdateUser(action: types.IUpdateUser) {
  yield call(services.updateUser, action.payload.id, action.payload.data);
}

function* invokeDeleteUser(action: types.IDeleteUser) {
  yield call(services.deleteUser, action.payload);
}

function* invokeSaveUserSocket(action: types.ISaveUserSocket) {
  yield call(services.handleUserFromNotification, action.payload);
}

export function* watchUsersPurePageSaga() {
  yield takeLatest(constants.FETCH_USERS, invokeSetupUsers);
  yield takeLatest(constants.CREATE_USER, invokeCreateUser);
  yield takeLatest(constants.UPDATE_USER, invokeUpdateUser);
  yield takeLatest(constants.DELETE_USER, invokeDeleteUser);
  yield takeLatest(constants.SIGNUP_USER, invokeSignUpUser);

  yield takeEvery(constants.SAVE_USER_SOCKET, invokeSaveUserSocket);
}

export function* watchUsersPageSaga() {
  yield all([watchUsersPurePageSaga, watchUsersFormSaga].map(fork));
}
