/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import Avatar from 'react-avatar';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import slugify from 'react-slugify';
import { openDialog } from '../../../../app/dialog/dialog.actions';
import { meetingSelectors } from '../../../../app/resource/meeting/meeting.selectors';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { ILiveMeetingNavbarProps } from './types';
//meetingId
export const LiveMeetingNavbar: FunctionComponent<ILiveMeetingNavbarProps> = ({
  agendaItem,
  showLoader,
  meetingId,
}) => {
  // const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);

  const [seconds, setSeconds] = useState<number>(0);

  const timer = useRef<NodeJS.Timeout | null>();
  const isTimeOut = useRef(false);

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    timer.current = null;
    isTimeOut.current = false;
    setSeconds(+agendaItem.duration * 60);

    timer.current = setInterval(
      () =>
        setSeconds((sec) => {
          if (sec === 0) {
            isTimeOut.current = true;
          }
          if (isTimeOut.current) {
            return sec + 1;
          } else {
            return sec - 1;
          }
        }),
      1000,
    );
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [agendaItem]);

  const history = useHistory();
  const title = useSelectWithParams(meetingSelectors.selectResourceById, meetingId)?.title;
  const redirectToMeeting = useCallback(() => {
    history.push(`/meeting-dashboard/${meetingId}/${slugify(title, { delimiter: '-' })}`);
  }, [history, meetingId, title]);

  const dispatch = useDispatch();
  const openAgendaFormDialog = useCallback(() => {
    dispatch(openDialog('aiPreviewDialog', { meetingId }));
  }, [dispatch, meetingId]);

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  const close = useCallback(() => setActive(false), [setActive]);

  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <div className="panel single-line flex aic jcsb p-0">
            <h2 className="bold flex aic">
              <a className="big-avatar yellow-bg flex aic jcc f-3 my-1 mx-2 white bold">
                {showLoader ? (
                  <Loader type="TailSpin" color="#1bc3a7" height={40} width={40} />
                ) : (
                  <Avatar
                    className="big-avatar yellow-bg flex aic jcc f-3 my-1 mx-2 white bold"
                    name={agendaItem.title}
                    round={true}
                    size="60"
                  />
                )}
              </a>
              {agendaItem.title}
              <span className="badge thin green-bg white f-0 ml-05">LIVE</span>
            </h2>
            <span
              className="meeting-live-time"
              style={{ marginLeft: '32%', backgroundColor: isTimeOut.current ? 'red' : undefined }}
            >
              <i className="icon-clock mr-05"></i>{' '}
              {isTimeOut.current
                ? `-${moment.utc(seconds * 1000).format('mm:ss')}`
                : `${moment.utc(seconds * 1000).format('mm:ss')}`}
            </span>
            <ul className="right-menu" style={{ height: '80px' }}>
              <li className="flex aic">
                <div className={isActive ? 'dropdown opened' : 'dropdown'} onMouseLeave={close}>
                  <div style={{ paddingLeft: '5px' }} onClick={toggleClass}>
                    <a className="btn btn-md btn-border white-bg ml-2" data-popup="delete_popup">
                      <i className="icon-close blueGray f-0 mr-05" />
                      Cancel Meeting
                    </a>
                  </div>
                  <div className="dropdown-menu drop-right drop-down max-h320">
                    <div className="mini-popup">
                      <div className="mini-popup-header">
                        Cancel Meeting
                        <span className="close_mini_popup" onClick={close}>
                          ×
                        </span>
                      </div>
                      <div className="mini-popup-body">
                        <p className="ln mb-2 darkGray">Are you sure you want to cancel the meeting?</p>
                        <a className="btn btn-md red-bg white d-block jcc" onClick={redirectToMeeting}>
                          Cancel Meeting
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="flex aic">
                <a className="btn btn-md darkGray-bg ml-2" onClick={openAgendaFormDialog}>
                  <i className="icon-briefcase f-0 mr-05"></i>Show Agenda
                </a>
              </li>
              {/* <li>
                <a className="panel-button no-border popup_button" data-popup="sort_widgets_popup">
                  <i className="icon-dots"></i>
                </a>
              </li> */}
              <li className="dropdown">
                <a className="panel-button dropdown-button" data-action="dropdown">
                  <i className="icon-bell"></i> <span className="badge"></span>
                </a>
                <ul className="dropdown-menu notification-menu drop-right"></ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
