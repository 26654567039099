import { all, call, put } from 'redux-saga/effects';

import * as types from './session.types';
import * as actions from './session.actions';
import * as api from '../../api/request-config/session.api';

import { apiRequest } from '../../api/api.saga-services';
import { select } from '../../../utils/selector.utils';
import { sessionSelectors } from './session.selectors';

export function* storeSession(session: types.ISession) {
  yield put(actions.storeSession(session));
}

export function* clearSession(sessionId: types.ISession['id']) {
  yield put(actions.clearSession(sessionId));
}

export function* clearAllSesions() {
  const sessionIds: types.ISession['id'][] = yield select(sessionSelectors.selectResourceIds);
  yield put(actions.clearSession(sessionIds));
}
/* This method sends api request and handle response */
export function* getSessions(searchParams: types.ISessionSearchParams) {
  const sessions: types.ISession[] = (yield call(apiRequest, api.getNextSessionApi(searchParams))) as types.ISession[];

  yield all(sessions.map((session) => call(storeSession, session)));

  return sessions.map((session) => session.id);
}

/* This method sends api request and handle response */
export function* updateSessions(sessionId: types.ISession['id'], sessionInfo: types.ISessionCreate) {
  const session = yield call(apiRequest, api.updateSessionApi({ id: sessionId, ...sessionInfo }));
  yield call(storeSession, session);
}
