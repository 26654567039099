import { call, put, select } from 'redux-saga/effects';

import * as actions from './chart.actions';
import * as nodeResourceService from './../../../resource/node/node.saga-service';

import { INodeCreate, INodeCreateForm, IOrganizationChartNode } from '../../../resource/node/node.types';
import * as dialogService from '../../../dialog/dialog.saga-services';
import { chartPageSelectors } from './chart.selectors';

export function* clearNodes() {
  yield put(actions.clearChart());
}

export function* setupChart() {
  const nodes = (yield call(nodeResourceService.searchNodes)) as IOrganizationChartNode['id'][];
  yield put(nodes ? actions.storeChart(nodes) : actions.clearChart());
}
export function* createNode(nodeInfo: INodeCreateForm) {
  const node = (yield call(
    nodeResourceService.createNode,
    mapNodeFormToNodeCreate(nodeInfo),
  )) as IOrganizationChartNode;

  if (!node) {
    return;
  }
  const nodesIds = yield select(chartPageSelectors.selectNodes);

  yield put(nodesIds ? actions.storeChart([...nodesIds, node.id]) : actions.clearChart());

  yield call(dialogService.closeDialog, 'nodeFormDialog');
}

export function* updateNode(nodeId: IOrganizationChartNode['id'], nodeInfo: INodeCreateForm) {
  const node = (yield call(nodeResourceService.updateNode, {
    id: nodeId,
    ...mapNodeFormToNodeCreate(nodeInfo),
  })) as IOrganizationChartNode;

  if (!node) {
    return;
  }
  const nodesIds = yield select(chartPageSelectors.selectNodes);

  yield put(nodesIds ? actions.storeChart([...nodesIds]) : actions.clearChart());
  yield call(dialogService.closeDialog, 'nodeFormDialog');
}

export function* deleteNode(nodeId: IOrganizationChartNode['id']) {
  yield call(nodeResourceService.deleteNode, nodeId);
  const nodesIds: IOrganizationChartNode['id'][] = yield select(chartPageSelectors.selectNodes);

  yield put(nodesIds ? actions.storeChart(nodesIds.filter((id) => nodeId !== id)) : actions.clearChart());
}

export const mapNodeFormToNodeCreate = (nodeInfo: INodeCreateForm): INodeCreate => ({
  parentId: nodeInfo.parentId,
  userId: nodeInfo.user.value,
  description: nodeInfo.description,
  coworkers: nodeInfo.coworkers.map((tupple) => tupple.value),
});
export function* clearChartPageStorage() {
  yield put(actions.clearChart());
}
