import moment from 'moment';
import React, { FunctionComponent, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { meetingInfoSelectors } from '../../../../app/resource/meeting-info/meeting-info.selectors';

import { meetingSelectors } from '../../../../app/resource/meeting/meeting.selectors';
import { IMeeting } from '../../../../app/resource/meeting/meeting.types';
import { userGroupSelectors } from '../../../../app/resource/user-group/user-group.selectors';
import { IUserCreateForm } from '../../../../app/resource/user/user.types';
import { useRequiredValidation } from '../../../../utils/form/validation.utils';
import { useSelect, useSelectWithParams } from '../../../../utils/selector.utils';
import { AvatarInput } from '../../common/inputs/AvatarInput';
import { DateTimeInput } from '../../common/inputs/DateTimeInput';

import { SelectInput } from '../../common/inputs/SelectInput';
import { FormTextInput } from '../../common/inputs/TextInput';

import { IUserFormProps } from './types';

export const UserForm: FunctionComponent<IUserFormProps> = ({ data, onSubmit }) => {
  const formProps = useForm();
  const requiredValidation = useRequiredValidation();

  const meetings = useSelect(meetingInfoSelectors.selectResourceList);
  const userGroups = useSelect(userGroupSelectors.selectResourceList);

  const { teamsOptions, accountsOptions } = useMemo(
    () => ({
      teamsOptions: meetings
        .filter((meeting) => meeting.type !== 'account')
        .map((team) => ({
          value: team.id,
          label: team.title,
        })),
      accountsOptions: meetings
        .filter((meeting) => meeting.type === 'account')
        .map((team) => ({
          value: team.id,
          label: team.title,
        })),
    }),
    [meetings],
  );

  const groupOptions = useMemo(
    () =>
      userGroups.map((group) => ({
        value: group.id,
        label: group.name,
      })),
    [userGroups],
  );

  const teamsDefaultValues = useSelectWithParams(meetingSelectors.selectResourcesById, data ? data.teams : []);
  const accountsDefaultValues = useSelectWithParams(meetingSelectors.selectResourcesById, data ? data.accounts : []);

  const groupsDefaultValues = useSelectWithParams(userGroupSelectors.selectResourcesById, data ? data.groupIds : []);
  console.log(groupsDefaultValues);
  const groupsDefaultOptions = useMemo(() => {
    const groups = groupsDefaultValues.map((group) => ({
      value: group ? group.id : '0',
      label: group ? group.name : '',
    }));
    return groups.length !== 0 ? groups : undefined;
  }, [groupsDefaultValues]);

  const teamsDefaultOptions = useMemo(() => {
    const meetings = (teamsDefaultValues.filter((team) => !!team) as IMeeting[]).map((team) => ({
      value: team.id,
      label: team.title,
    }));
    return meetings.length !== 0 ? meetings : undefined;
  }, [teamsDefaultValues]);

  const accountsDefaultOptions = useMemo(() => {
    const meetings = (accountsDefaultValues.filter((team) => !!team) as IMeeting[]).map((team) => ({
      value: team.id,
      label: team.title,
    }));
    return meetings.length !== 0 ? meetings : undefined;
  }, [accountsDefaultValues]);

  // const timezones = moment.tz.names();
  // const timezonesPST = [
  //   'Pacific/Honolulu',
  //   'America/Anchorage',
  //   'America/Los_Angeles',
  //   'America/Phoenix',
  //   'America/Mexico_City',
  //   'America/New_York',
  //   'America/Halifax',
  //   'America/Sao_Paulo',
  // ];
  // const timezoneOptions = useMemo(
  //   () =>
  //     timezonesPST.map((timezone) => ({
  //       value: timezone,
  //       label: moment.tz(timezone).format('Z z').concat(' ', timezone),
  //     })),
  //   [timezonesPST],
  // );
  // console.log('TIMEZONES: ', timezones);

  const timezones = [
    '(GMT-11:00) Midway Island, Samoa',
    '(GMT-11:00) Pago Pago',
    '(GMT-10:00) Hawaii',
    '(GMT-9:00) Alaska',
    '(GMT-9:00) Juneau',
    '(GMT-8:00) Vancouver',
    '(GMT-8:00) Pacific Time (US and Canada)',
    '(GMT-8:00) Tijuana',
    '(GMT-7:00) Edmonton',
    '(GMT-7:00) Mountain Time (US and Canada)',
    '(GMT-7:00) Arizona',
    '(GMT-7:00) Mazatlan',
    '(GMT-7:00) Chihuahua',
    '(GMT-7:00) Yukon',
    '(GMT-6:00) Winnipeg',
    '(GMT-6:00) Saskatchewan',
    '(GMT-6:00) Central Time (US and Canada)',
    '(GMT-6:00) Mexico City',
    '(GMT-6:00) Guatemala',
    '(GMT-6:00) El Salvador',
    '(GMT-6:00) Managua',
    '(GMT-6:00) Costa Rica',
    '(GMT-6:00) Tegucigalpa',
    '(GMT-6:00) Monterrey',
    '(GMT-5:00) Montreal',
    '(GMT-5:00) Eastern Time (US and Canada)',
    '(GMT-5:00) Indiana (East)',
    '(GMT-5:00) Panama',
    '(GMT-5:00) Bogota',
    '(GMT-5:00) Lima',
    '(GMT-5:00) Acre',
    '(GMT-4:00) Halifax',
    '(GMT-4:00) Puerto Rico',
    '(GMT-4:00) Caracas',
    '(GMT-4:00) Atlantic Time (Canada)',
    '(GMT-4:00) La Paz',
    '(GMT-4:00) Guyana',
    '(GMT-3:30) Newfoundland and Labrador',
    '(GMT-3:00) Santiago',
    '(GMT-3:00) Montevideo',
    '(GMT-3:00) Recife',
    '(GMT-3:00) Buenos Aires, Georgetown',
    '(GMT-3:00) Greenland',
    '(GMT-3:00) Sao Paulo',
    '(GMT-2:00) Fernando de Noronha',
    '(GMT-1:00) Azores',
    '(GMT-1:00) Cape Verde Islands',
    '(GMT+0:00) Universal Time UTC',
    '(GMT+0:00) Greenwich Mean Time',
    '(GMT+0:00) Reykjavik',
    '(GMT+0:00) Dublin',
    '(GMT+0:00) London',
    '(GMT+0:00) Lisbon',
    '(GMT+0:00) Nouakchott',
    '(GMT+1:00) Belgrade, Bratislava, Ljubljana',
    '(GMT+1:00) Sarajevo, Skopje, Zagreb',
    '(GMT+1:00) Casablanca',
    '(GMT+1:00) Oslo',
    '(GMT+1:00) Copenhagen',
    '(GMT+1:00) Brussels',
    '(GMT+1:00) Amsterdam, Berlin, Rome, Stockholm, Vienna',
    '(GMT+1:00) Amsterdam',
    '(GMT+1:00) Rome',
    '(GMT+1:00) Stockholm',
    '(GMT+1:00) Vienna',
    '(GMT+1:00) Luxembourg',
    '(GMT+1:00) Paris',
    '(GMT+1:00) Zurich',
    '(GMT+1:00) Madrid',
    '(GMT+1:00) West Central Africa',
    '(GMT+1:00) Algiers',
    '(GMT+1:00) Tunis',
    '(GMT+1:00) Warsaw',
    '(GMT+1:00) Prague Bratislava',
    '(GMT+1:00) Budapest',
    '(GMT+2:00) Helsinki',
    '(GMT+2:00) Harare, Pretoria',
    '(GMT+2:00) Sofia',
    '(GMT+2:00) Athens',
    '(GMT+2:00) Bucharest',
    '(GMT+2:00) Nicosia',
    '(GMT+2:00) Beirut',
    '(GMT+2:00) Damascus',
    '(GMT+2:00) Jerusalem',
    '(GMT+2:00) Amman',
    '(GMT+2:00) Tripoli',
    '(GMT+2:00) Cairo',
    '(GMT+2:00) Johannesburg',
    '(GMT+2:00) Khartoum',
    '(GMT+2:00) Kiev',
    '(GMT+2:00) Chisinau',
    '(GMT+3:00) Nairobi',
    '(GMT+3:00) Istanbul',
    '(GMT+3:00) Moscow',
    '(GMT+3:00) Baghdad',
    '(GMT+3:00) Kuwait',
    '(GMT+3:00) Riyadh',
    '(GMT+3:00) Bahrain',
    '(GMT+3:00) Qatar',
    '(GMT+3:00) Aden',
    '(GMT+3:00) Djibouti',
    '(GMT+3:00) Mogadishu',
    '(GMT+3:00) Minsk',
    '(GMT+3:30) Tehran',
    '(GMT+4:00) Dubai',
    '(GMT+4:00) Muscat',
    '(GMT+4:00) Baku, Tbilisi, Yerevan',
    '(GMT+4:30) Kabul',
    '(GMT+5:00) Yekaterinburg',
    '(GMT+5:00) Islamabad, Karachi, Tashkent',
    '(GMT+5:30) India',
    '(GMT+5:30) Mumbai, Kolkata, New Delhi',
    '(GMT+5:30) Colombo',
    '(GMT+5:45) Kathmandu',
    '(GMT+6:00) Almaty',
    '(GMT+6:00) Dacca',
    '(GMT+6:00) Astana, Dhaka',
    '(GMT+6:30) Rangoon',
    '(GMT+7:00) Novosibirsk',
    '(GMT+7:00) Krasnoyarsk',
    '(GMT+7:00) Bangkok',
    '(GMT+7:00) Vietnam',
    '(GMT+7:00) Jakarta',
    '(GMT+8:00) Irkutsk, Ulaanbaatar',
    '(GMT+8:00) Beijing, Shanghai',
    '(GMT+8:00) Hong Kong SAR',
    '(GMT+8:00) Taipei',
    '(GMT+8:00) Kuala Lumpur',
    '(GMT+8:00) Singapore',
    '(GMT+8:00) Perth',
    '(GMT+9:00) Yakutsk',
    '(GMT+9:00) Seoul',
    '(GMT+9:00) Osaka, Sapporo, Tokyo',
    '(GMT+9:30) Darwin',
    '(GMT+10:00) Vladivostok',
    '(GMT+10:00) Guam, Port Moresby',
    '(GMT+10:00) Brisbane',
    '(GMT+10:30) Adelaide',
    '(GMT+11:00) Canberra, Melbourne, Sydney',
    '(GMT+11:00) Hobart',
    '(GMT+11:00) Magadan',
    '(GMT+11:00) Solomon Islands',
    '(GMT+11:00) New Caledonia',
    '(GMT+11:00) Lord Howe IsIand',
    '(GMT+12:00) Kamchatka',
    '(GMT+12:00) Fiji Islands, Marshall Islands',
    '(GMT+13:00) Auckland, Wellington',
    '(GMT+13:00) Independent State of Samoa',
  ];

  const timezoneOptions = useMemo(
    () =>
      timezones.map((timezone) => ({
        value: timezone,
        label: timezone,
      })),
    [timezones],
  );

  const timezoneDefaultOptions = useMemo(
    () => timezoneOptions.filter((timezone) => data && timezone.value === data.timezone),
    [data, timezoneOptions],
  );
  const selectedTimezone = timezoneDefaultOptions[0];

  // const timezonesPST = [moment.tz('Pacific/Honolulu').format('Z z'), moment.tz('America/New_York').format('Z z')];

  const defaultValue = data?.dob;

  const date = useMemo(() => {
    try {
      if (defaultValue) {
        const dateString = defaultValue === '0000-00-00' ? '' : new Date(moment(defaultValue).format('YYYY-MM-DD'));
        return dateString;
      } else {
        return '';
      }
    } catch (error) {
      console.log(error);
    }
  }, [defaultValue]);

  return (
    <FormProvider {...formProps}>
      <form
        id="user-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IUserCreateForm, data?.id))}
        action=""
        method="post"
      >
        <div className="popup-upload-panel">
          {/* <ImageInput
            defaultValue={data?.image}
            id="image"
            name="image"
            label={`Upload Contact Photo`}
            maxImageSize={2}
            acceptableFormats={'jpg, png, gif'}
            // validation={requiredValidation}
          /> */}
          <AvatarInput
            defaultValue={data?.image}
            id="image"
            name="image"
            label={`Upload Contact Photo`}
            maxImageSize={2}
            acceptableFormats={'jpg, png, gif, webp'}
          />
        </div>

        <div className="col-12" style={{ marginTop: '10px' }}>
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.firstName}
              id="firstName"
              name="firstName"
              type="text"
              label="First Name"
              placeholder="Enter First Name"
              validation={requiredValidation}
            />
          </div>
        </div>

        <div className="col-12">
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.lastName}
              id="lastName"
              name="lastName"
              type="text"
              label="Last Name"
              placeholder="Enter Last Name"
              validation={requiredValidation}
            />
          </div>
        </div>

        <div className="col-12">
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.email}
              id="email"
              name="email"
              type="email"
              label="Email"
              placeholder="Enter Email"
              validation={requiredValidation}
            />
          </div>
        </div>

        <div className="col-12">
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.jobTitle}
              id="jobTitle"
              name="jobTitle"
              type="text"
              label="Job Title"
              placeholder="Enter Job Title"
              // validation={requiredValidation}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="field-container">
            <SelectInput
              defaultOptions={selectedTimezone}
              id="timezone"
              name="timezone"
              label="Timezone"
              placeholder="Enter Timezone"
              options={timezoneOptions}
              // validation={requiredValidation}
              isSingle
            />
          </div>
        </div>
        <div className="col-12">
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.address}
              id="address"
              name="address"
              type="text"
              label="Address"
              placeholder="Enter Address"
              // validation={requiredValidation}
            />
          </div>
        </div>

        {/* <div className="col-12">
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.alternativeAddress}
              id="alternativeAddress"
              name="alternativeAddress"
              type="text"
              label="Alternative Address"
              placeholder="Enter Alternative Address"
            />
          </div>
        </div> */}

        <div className="col-12">
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.phone}
              id="phone"
              name="phone"
              type="text"
              label="Phone"
              placeholder="Enter Phone"
              // validation={requiredValidation}
            />
          </div>
        </div>

        <div className="col-12">
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.alternativePhone}
              id="alternativePhone"
              name="alternativePhone"
              type="text"
              label="Alternative Phone"
              placeholder="Enter Alternative Phone"
            />
          </div>
        </div>

        <div className="col-12">
          <div className="field-container">
            <DateTimeInput
              defaultValue={date}
              id="dob"
              name="dob"
              label="Date of Birth"
              placeholder="Enter Date of Birth"
              // validation={requiredValidation}
            />
          </div>
        </div>

        <div className="col-12">
          <div className="field-container">
            <SelectInput
              defaultOptions={accountsDefaultOptions}
              id="accounts"
              name="accounts"
              label="Accounts"
              placeholder="Search Accounts"
              options={accountsOptions}
            />
          </div>
        </div>

        <div className="col-12">
          <div className="field-container">
            <SelectInput
              defaultOptions={teamsDefaultOptions}
              id="teams"
              name="teams"
              label="Teams"
              placeholder="Search Teams"
              options={teamsOptions}
            />
          </div>
        </div>

        <div className="col-12">
          <div className="field-container">
            <SelectInput
              // defaultOptions={teamsDefaultOptions}
              id="groups"
              name="groups"
              label="Roles"
              placeholder="Select roles"
              options={groupOptions}
              // validation={requiredValidation}
              defaultOptions={groupsDefaultOptions}
            />
          </div>
        </div>

        {/* <div className="col-12">
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.skype}
              id="skype"
              name="skype"
              type="text"
              label="Skype"
              placeholder="Enter SkypeID"
            />
          </div>
        </div> */}
      </form>
    </FormProvider>
  );
};
