import { createActionConst } from '../../../../utils/action.utils';

const TEMPLATE_NAMESPACE = 'TEMPLATE';

export const FETCH_TEMPLATES = createActionConst(TEMPLATE_NAMESPACE, 'FETCH_TEMPLATES');
export const CREATE_TEMPLATE = createActionConst(TEMPLATE_NAMESPACE, 'CREATE_TEMPLATE');
export const DELETE_TEMPLATE = createActionConst(TEMPLATE_NAMESPACE, 'DELETE_TEMPLATE');

export const CREATE_SECTION = createActionConst(TEMPLATE_NAMESPACE, 'CREATE_SECTION');
export const UPDATE_SECTION = createActionConst(TEMPLATE_NAMESPACE, 'UPDATE_SECTION');
export const DELETE_SECTION = createActionConst(TEMPLATE_NAMESPACE, 'DELETE_SECTION');

export const STORE_TEMPLATES = createActionConst(TEMPLATE_NAMESPACE, 'STORE_TEMPLATES');
export const CLEAR_TEMPLATES = createActionConst(TEMPLATE_NAMESPACE, 'CLEAR_TEMPLATES');
