import * as types from './categories.types';
import * as constants from './categories.constants';
import { ICategory } from '../../../resource/category/category.types';

// export const createCategory = (category: ICategoryCreateForm): types.ICreateCategory => ({
//   type: constants.CREATE_Category,
//   payload: category,
// });

// export const updateCategory = (CategoryId: ICategory['id'], Category: ICategoryCreateForm): types.IUpdateCategory => ({
//   type: constants.UPDATE_Category,
//   payload: {
//     id: CategoryId,
//     data: Category,
//   },
// });

// export const deleteCategory = (CategoryId: ICategory['id'], accountId: IAccountCategory['id']): types.IDeleteCategory => ({
//   type: constants.DELETE_Category,
//   payload: { CategoryId, accountId },
// });

export const storeCategories = (Categories: ICategory['id'][]): types.IStoreCategory => ({
  type: constants.STORE_CATEGORY,
  payload: Categories,
});

export const clearCategory = (): types.IClearCategory => ({
  type: constants.CLEAR_CATEGORY,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
