import { put, select, call, all } from 'redux-saga/effects';
import * as types from './question.types';
import * as actions from './question.actions';

//import * as api from '../../api/request-config/Question.api';
import { questionSelectors } from './question.selectors';
import { IAnswer } from '../answer/answer.types';
import { storeAnswer } from '../answer/answer.saga-service';

export function* storeQuestion(question: types.IQuestion) {
  yield put(actions.storeQuestion(question));

  return question;
}

export function* clearQuestion(questionId: types.IQuestion['id']) {
  yield put(actions.clearQuestion(questionId));
}

export function* clearAllQuestions() {
  const questionIds: types.IQuestion['id'][] = yield select(questionSelectors.selectResourceIds);
  yield put(actions.clearQuestion(questionIds));
}

export function* setupQuestions(questions: types.IQuestionResponse[]) {
  const answers = questions.reduce((prev, curr) => [...prev, ...curr.answers], [] as IAnswer[]);
  yield all(answers.map((answer) => call(storeAnswer, answer)));

  yield all(
    questions.map((question) =>
      call(storeQuestion, {
        id: question.id,
        numOfAnswers: question.numOfAnswers,
        addAnswer: question.addAnswer,
        question: question.question,
        cssClass: question.cssClass,
        questionLength: question.questionLength,
        atColumnId: question.atColumnId,
        answerIds: question.answers.map((answer) => answer.id),
      }),
    ),
  );
}

// export function* searchQuestions() {
//   const Questions: types.IQuestion[] = yield call(apiRequest, api.searchQuestionsApi());

//   yield all(Questions.map((Question) => call(storeQuestion, Question)));
//   return Questions.map((Question) => Question.id);
// }

// export function* createQuestion(data: types.IQuestionCreate) {
//   const Question = (yield call(apiRequest, api.createQuestionApi(data))) as types.IQuestion;
//   if (!Question) {
//     return;
//   }
//   yield call(storeQuestion, Question);
//   return Question;
// }

// export function* updateQuestion(data: types.IQuestionUpdate) {
//   const Question = (yield call(apiRequest, api.updateQuestionApi(data))) as types.IQuestion;
//   if (!Question) {
//     return;
//   }
//   Question.id = data.id;
//   yield call(storeQuestion, Question);
//   return Question;
// }

// export function* deleteQuestion(QuestionId: types.IQuestion['id']) {
//   yield call(apiRequest, api.deleteQuestionApi(QuestionId));
// }
