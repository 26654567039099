/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { pageStateSelectors } from '../../../app/page/page-state/page-state.selectors';
import { IMeeting } from '../../../app/resource/meeting/meeting.types';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { PageLayout } from '../common/PageLayout';
import { WidgetWrapper } from '../common/widgets/WidgetWrapper';
import { initialKpiPage } from '../../../app/page/kpi/kpi.actions';
import { MeetingDashboardNavbar } from '../common/navigation-bars/MeetigDashboardNavbar';
import { WidgetKpiSettings } from '../common/widgets/widgets-kpi/WidgetKpiSettings';
import { ScoreFormTrigger } from '../../dialogs/ScoreFormDialog/ScoreFormTrigger';

import ExportIcon from '../../resources/images/new-icons/export-icon.svg';
import SearchIcon from '../../resources/images/search-icon.svg';

export const KpiPage = () => {
  const dispatch = useDispatch();
  const showLoader = useSelectWithParams(pageStateSelectors.selectPageInProgress, 'kpi');
  const { meetingId } = useParams<{ meetingId: IMeeting['id'] }>();

  useEffect(() => {
    dispatch(initialKpiPage(meetingId));
  }, [dispatch, meetingId]);

  return (
    <PageLayout
      showLoader={showLoader}
      smallSidebar
      externalLogicNavbar={<MeetingDashboardNavbar showLoader={showLoader} meetingId={meetingId} />}
    >
      {!showLoader ? (
        <>
          <WidgetWrapper
            name="Scorecard Settings"
            actionElement={
              <div className="ml-auto flex aic jcr">
                <div className="field-container custom-select smaller-select with-checkbox mb-0 w280">
                  <input type="hidden" defaultValue="All Users" />
                  <span className="select-field">
                    <span className="select-value">All Users</span> <i className="icon-down-dir" />
                  </span>
                  <div className="select-dropdown" style={{ boxSizing: 'border-box', display: 'none' }}>
                    <div className="select-item selected" data-val="All Users">
                      All Users
                    </div>
                    <div className="select-item" data-val="David Ephraim">
                      David Ephraim
                    </div>
                    <div className="select-item" data-val="Anca Stefanescu">
                      Anca Stefanescu
                    </div>
                    <div className="select-item" data-val="Sarah Shimoda">
                      Sarah Shimoda
                    </div>
                    <div className="select-item" data-val="Milan Vranes">
                      Milan Vranes
                    </div>
                    <div className="select-item" data-val="Chris Diaz">
                      Chris Diaz
                    </div>
                    <div className="select-item" data-val="Vladimir Cvejic">
                      Vladimir Cvejic
                    </div>
                    <div className="select-item" data-val="Austin LaRoche">
                      Austin LaRoche
                    </div>
                  </div>
                </div>
                <a className="btn btn-md btn-border white-bg px-1">
                  <img src={ExportIcon} alt="export" className="img-fluid mr-1" style={{ width: 8 }} />
                  Export
                </a>
                <a className="btn btn-md btn-border white-bg px-1">
                  <img src={SearchIcon} alt="search" className="img-fluid" />
                </a>
                <ScoreFormTrigger meetingId={meetingId} />
              </div>
            }
          >
            <WidgetKpiSettings />
          </WidgetWrapper>
        </>
      ) : (
        <></>
      )}
    </PageLayout>
  );
};

/* <div className="row mb-25 mt-3">
<WidgetWrapper
  name="KPI's"
  actionElement={
    <div className="ml-auto flex aic jcr">
      <a className="btn btn-md green-bg popup_button" data-popup="new_score_popup" title="Add Score">
        <i className="icon-plus mr-1"></i> Add Score
      </a>
      <a className="btn btn-md btn-border white-bg" title="Scorecard Settings">
        <i className="icon-settings mr-1 f-0 blueGray"></i> Scorecard Settings
      </a>
      <a className="btn btn-md btn-border white-bg" title="Previous Quarter">
        <i className="icon-left-arrow icon-rotated mr-1 f-0 blueGray"></i>
        Previous Quarter
      </a>
      <a
        className="btn btn-md btn-border white-bg popup_button"
        data-popup="reorder_users_popup"
        title="Reorder Users"
      >
        <i className="icon-users mr-1 f-0 blueGray"></i>Reorder Users
      </a>
      <div className="toggle-buttons flex aic">
        <a className="btn white-bg btn-md btn-border blueGray m-0 selected" title="">
          Show
        </a>
        <a className="btn white-bg btn-md btn-border blueGray m-0" title="">
          Hide
        </a>
      </div>
    </div>
  }
>
  <WidgetKpi />
</WidgetWrapper>
</div> */
