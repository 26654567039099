import * as types from './agenda-items.types';
import * as constants from './agenda-items.constants';
import { IAgendaItem } from '../../../resource/agenda-item/agenda-item.types';
import { IMeeting } from '../../../resource/meeting/meeting.types';
import { DropResult } from 'react-beautiful-dnd';

export const storeAgendaItems = (agendaItemIds: IAgendaItem['id'][]): types.IStoreAgendaItems => ({
  type: constants.STORE_AGENDA_ITEMS,
  payload: agendaItemIds,
});

export const clearAgendaItems = (): types.IClearAgendaItems => ({
  type: constants.CLEAR_AGENDA_ITEMS,
});

export const changeOrderAgendaItem = (
  meetingId: IMeeting['id'],
  dropResult: DropResult,
): types.IChangeOrderAgendaItem => ({
  type: constants.CHANGE_ORDER_AGENDA_ITEM,
  payload: {
    meetingId,
    dropResult,
  },
});

export const storeCurrentAgenda = (currentAgendaIndex: types.ICurrentAgendaIndex): types.IStoreCurrentAgenda => ({
  type: constants.STORE_CURRENT_AGENDA_INDEX,
  payload: currentAgendaIndex,
});

export const clearCurrentAgenda = (): types.IClearCurrentAgenda => ({
  type: constants.CLEAR_CURRENT_AGENDA_INDEX,
});

export const moveToNextAgenda = (currentAgendaIndex: types.ICurrentAgendaIndex): types.IMoveToNextAgenda => ({
  type: constants.MOVE_TO_NEXT_AGENDA,
  payload: currentAgendaIndex,
});
