import { IApiRequestConfig } from '../api.types';
import { IUserCredentials } from '../../resource/user/user.types';
import { INewPassword } from '../../auth/auth.types';

export const loginApi = (params: IUserCredentials): IApiRequestConfig<IUserCredentials, unknown> => ({
  apiRouteKey: 'login',
  uri: 'json',
  action: 'signin',
  method: 'get',
  params: {
    ...params,
  },
});

export const forgotPasswordApi = (email: string): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'forgotPassword',
  uri: 'json',
  action: 'forgotPassword',
  method: 'post',
  params: {
    email,
  },
});

export const saveNewPasswordApi = (params: INewPassword): IApiRequestConfig<INewPassword, unknown> => ({
  apiRouteKey: 'saveNewPassword',
  uri: 'json',
  action: 'saveNewPassword',
  method: 'post',
  params: {
    ...params,
  },
});
