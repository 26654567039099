import { ISessionSearchParams, ISessionUpdate } from '../../resource/session/session.types';
import { IApiRequestConfig } from '../api.types';

export const getNextSessionApi = (searchParams: ISessionSearchParams): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'nextSession',
  uri: 'json',
  action: 'getNextSessions',
  method: 'get',
  params: searchParams,
});

export const updateSessionApi = (data: ISessionUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateSession',
  uri: 'json',
  action: 'saveSession',
  method: 'post',
  data,
});
