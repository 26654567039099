import { IApiRequestConfig } from '../api.types';
import { ITimerSave } from '../../page/timer/timer.types';

export const saveTimerApi = (timerResult?: ITimerSave): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'saveTimer',
  uri: 'json',
  action: 'saveTimer',
  method: 'post',
  data: timerResult,
});
