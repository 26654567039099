import { combineReducers, Reducer } from 'redux';

import * as types from './activities.types';
import * as constants from './activities.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IActivityPageState = {
  activitiesIds: [],
};

const activitiesReducer: Reducer<
  types.IActivityPageState['activitiesIds'],
  types.IStoreActivity | types.IClearActivity
> = (state = initialState.activitiesIds, action) => {
  if (isActionOfType<types.IStoreActivity>(action, constants.STORE_ACTIVITY)) {
    return action.payload;
  } else if (isActionOfType<types.IClearActivity>(action, constants.CLEAR_ACTIVITY)) {
    return initialState.activitiesIds;
  }

  return state;
};

export const activitiesPageReducer: Reducer<types.IActivityPageState, types.IActivityPageAction> = combineReducers({
  activitiesIds: activitiesReducer,
});
