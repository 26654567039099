import * as types from './user-groups.types';
import * as constants from './user-groups.constants';
import { IUserGroup, IUserGroupCreate, IUserGroupSearchParamas } from '../../../resource/user-group/user-group.types';

export const getUserGroups = (searchParams?: IUserGroupSearchParamas): types.IGetUserGroups => ({
  type: constants.GET_USER_GROUPS,
  payload: searchParams,
});

export const createUserGroup = (userGroup: IUserGroupCreate): types.ICreateUserGroup => ({
  type: constants.CREATE_USER_GROUP,
  payload: userGroup,
});

export const storeUserGroups = (userIds: IUserGroup['id'][]): types.IStoreUserGroups => ({
  type: constants.STORE_USER_GROUPS,
  payload: userIds,
});

export const clearUserGroups = (): types.IClearUserGroups => ({
  type: constants.CLEAR_USER_GROUPS,
});
