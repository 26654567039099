import { createActionConst } from '../../utils/action.utils';

const AUTH_NAMESPACE = 'AUTH';

export const LOGIN = createActionConst(AUTH_NAMESPACE, 'LOGIN');
export const LOGOUT = createActionConst(AUTH_NAMESPACE, 'LOGOUT');

export const STORE_LOGGED_USER = createActionConst(AUTH_NAMESPACE, 'STORE_LOGGED_USER');
export const CLEAR_LOGGED_USER = createActionConst(AUTH_NAMESPACE, 'CLEAR_LOGGED_USER');

export const STORE_ACCESS_TOKEN = createActionConst(AUTH_NAMESPACE, 'STORE_ACCESS_TOKEN');
export const CLEAR_ACCESS_TOKEN = createActionConst(AUTH_NAMESPACE, 'CLEAR_ACCESS_TOKEN');

export const STORE_GOOGLE_GRANTED_PERMISION = createActionConst(AUTH_NAMESPACE, 'STORE_GOOGLE_GRANTED_PERMISION');
export const CLEAR_GOOGLE_GRANTED_PERMISION = createActionConst(AUTH_NAMESPACE, 'CLEAR_GOOGLE_GRANTED_PERMISION');

export const STORE_REMEMBER_USER = createActionConst(AUTH_NAMESPACE, 'STORE_REMEMBER_USER');
export const CLEAR_REMEMBER_USER = createActionConst(AUTH_NAMESPACE, 'CLEAR_REMEMBER_USER');

export const STORE_USER_EXPIRES_AT = createActionConst(AUTH_NAMESPACE, 'STORE_USER_EXPIRES_AT');
export const CLEAR_USER_EXPIRES_AT = createActionConst(AUTH_NAMESPACE, 'CLEAR_USER_EXPIRES_AT');
export const FORGOT_PASSWORD_EMAIL = createActionConst(AUTH_NAMESPACE, 'FORGOT_PASSWORD_EMAIL');
export const SAVE_NEW_PASSWORD = createActionConst(AUTH_NAMESPACE, 'SAVE_NEW_PASSWORD');
