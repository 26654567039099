import { IUser, IUserCredentials } from '../resource/user/user.types';

import * as types from './auth.types';
import * as constants from './auth.constants';

export const logIn = (credentials: IUserCredentials): types.ILogin => ({
  type: constants.LOGIN,
  payload: credentials,
});

export const logOut = (): types.ILogout => ({
  type: constants.LOGOUT,
});

export const storeLoggedUser = (loggedUser: IUser['id']): types.IStoreLoggedUser => ({
  type: constants.STORE_LOGGED_USER,
  payload: loggedUser,
});
export const clearLoggedUser = (): types.IClearLoggedUser => ({
  type: constants.CLEAR_LOGGED_USER,
});

export const storeAccessToken = (accessToken: string): types.IStoreAccessToken => ({
  type: constants.STORE_ACCESS_TOKEN,
  payload: accessToken,
});
export const clearAccessToken = (): types.IClearAccessToken => ({
  type: constants.CLEAR_ACCESS_TOKEN,
});

export const storeGooglePermisionGranted = (granted: string): types.IStoreGoogleGrantedPermision => ({
  type: constants.STORE_GOOGLE_GRANTED_PERMISION,
  payload: !!granted,
});
export const clearGooglePermisionGranted = (): types.IClearGoogleGrantedPermision => ({
  type: constants.CLEAR_GOOGLE_GRANTED_PERMISION,
});

export const storeRememberUser = (rememberUser: boolean): types.IStoreRememberUser => ({
  type: constants.STORE_REMEMBER_USER,
  payload: rememberUser,
});
export const clearRememberUser = (): types.IClearRememberUser => ({
  type: constants.CLEAR_REMEMBER_USER,
});

export const storeUserExpiresAt = (userExpiresAt: string): types.IStoreUserExpiresAt => ({
  type: constants.STORE_USER_EXPIRES_AT,
  payload: userExpiresAt,
});
export const clearUserExpiresAt = (): types.IClearUserExpiresAt => ({
  type: constants.CLEAR_USER_EXPIRES_AT,
});

export const forgotPasswordEmail = (email: string): types.IForgotPasswordEmail => ({
  type: constants.FORGOT_PASSWORD_EMAIL,
  payload: email,
});

export const saveNewPassword = (data: types.INewPassword): types.ISaveNewPassword => ({
  type: constants.SAVE_NEW_PASSWORD,
  payload: data,
});
