import { call, put } from 'redux-saga/effects';

import * as actions from './project-times.actions';
import * as projectTimeResourceService from './../../../resource/project-time/project-time.saga-service';

import { IProjectTasksTime } from '../../../resource/project-time/project-time.types';

export function* clearProjectTimes() {
  yield put(actions.clearProjectTime());
}

export function* setupProjectTimes(week: string) {
  const projectTimes = (yield call(projectTimeResourceService.searchProjectTimes, week)) as IProjectTasksTime['id'][];

  yield put(projectTimes ? actions.storeProjectTimes(projectTimes) : actions.clearProjectTime());
}

export function* clearProjectTimesPageStorage() {
  yield put(actions.clearProjectTime());
}
