import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { initialAgencyToolsPage } from '../../../app/page/agency-tools/agency-tools.actions';
import { pageStateSelectors } from '../../../app/page/page-state/page-state.selectors';
import { IColumn } from '../../../app/resource/column/column.types';

import { useSelectWithParams } from '../../../utils/selector.utils';
import { PageLayout } from '../common/PageLayout';
import { SingleColumn } from './SingleColumn';

export const AgencySinglePage = () => {
  const dispatch = useDispatch();
  const { columnId } = useParams<{ columnId: IColumn['id'] }>();
  const showLoader = useSelectWithParams(pageStateSelectors.selectPageInProgress, 'agencyTools');

  useEffect(() => {
    dispatch(initialAgencyToolsPage());
  }, [dispatch]);
  return (
    <PageLayout showLoader={showLoader}>
      <SingleColumn columnId={columnId} />
    </PageLayout>
  );
};
