import { call, select } from 'redux-saga/effects';

import * as pageStateService from '../page-state/page-state.saga-services';
import * as meetingService from '../page-storage/meetings/meetings.saga-services';
import * as pageInitializerService from '../page.initializer.saga-service';
import * as sessionService from '../page-storage/sessions/sessions.saga-services';
import * as calendarService from '../page-storage/calendars/calendars.saga-services';
import * as taskService from '../page-storage/tasks/tasks.saga-services';
import * as widgetService from '../page-storage/widgets/widgets.saga-services';

import { authSelectors } from '../../auth/auth.selectors';
import { CURRENT_DATE } from '../../resource/calendar/calendar.types';
import { IUser } from '../../resource/user/user.types';

export function* initialHomePage() {
  yield call(pageStateService.initPage, 'home');

  if (!((yield call(pageInitializerService.initPage)) as boolean)) {
    yield call(widgetService.storeWidgets, [
      { key: 'homeCalendar', title: 'Calendar' },
      { key: 'homeAccounts', title: 'Accounts' },
      { key: 'homeTodos', title: `To Do's` },
      { key: 'homeQis', title: 'Quarterly Initiatives' },
    ]);

    const currentId = (yield select(authSelectors.selectLoggedUser)) as IUser['id'];
    yield call(meetingService.putFilteredMeetingsByUserId, currentId);

    yield call(taskService.setupTasksForUser, currentId);
    yield call(sessionService.setupSessions, { userId: currentId });

    yield call(calendarService.setupCalendar, CURRENT_DATE, 'day');
  }

  yield call(pageStateService.completedInit, 'home');
}
