import { IConclusionCreate } from '../../resource/conclusion/conclusion.types';
import { IApiRequestConfig } from '../api.types';

export const createConclusionApi = (conclusion: IConclusionCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createConclusion',
  uri: 'json',
  action: 'saveConclusion',
  method: 'post',
  data: conclusion,
});
