/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { initialMeetingForm } from '../../../../app/page/meetings/meeting-form/meeting-form.actions';
import { userSelectors } from '../../../../app/resource/user/user.selectors';
import { useRequiredValidation } from '../../../../utils/form/validation.utils';
import { useSelect } from '../../../../utils/selector.utils';
import { AvatarInput } from '../../common/inputs/AvatarInput';
import { ColorInput } from '../../common/inputs/ColorInput';
import { DateTimeInput } from '../../common/inputs/DateTimeInput';
import { SelectInput } from '../../common/inputs/SelectInput';
import { FormTextInput } from '../../common/inputs/TextInput';
import { IMeetingFormProps } from './types';

// import { useDebounce } from '../../../../utils/debounce.utils';

export const MeetingForm: FunctionComponent<IMeetingFormProps> = ({ type, onSubmit, data, errorCheck }) => {
  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const error = formProps.errors;

  if (errorCheck === true) {
    const errors = [
      error.image?.ref.name ? 'Account Photo' : undefined,
      error.color?.ref.name ? 'Account Color' : undefined,
      error.title?.ref.name ? 'Account Title' : undefined,
      error.administrators?.ref.name ? 'Account Leaders' : undefined,
      error.shareWith?.ref.name ? 'Account Members' : undefined,
      error.sharepointUrl?.ref.name ? 'Meeting URL' : undefined,
      error.startTime?.ref.name ? 'Start Time' : undefined,
      error.endTime?.ref.name ? 'End Time' : undefined,
    ];

    for (let i = 0; i < 8; i++) {
      if (errors[i] !== undefined) {
        alert(`${errors[i]} field is required.`);
        break;
      }
    }
  }

  const dispatch = useDispatch();

  const users = useSelect(userSelectors.selectResourceList);
  const options = useMemo(
    () =>
      users.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })),
    [users],
  );

  const administratorDefaultOptions = useMemo(
    () => (data ? options.filter((option) => data.administratorIds.includes(option.value)) : undefined),
    [data, options],
  );

  const shareWithDefaultOptions = useMemo(
    () => (data ? options.filter((option) => data.shareWithIds.includes(option.value)) : undefined),
    [data, options],
  );

  // const timeRange = useMemo(() => {
  //   const startTime = formProps.getValues()['startTime'];
  //   const endTime = formProps.getValues()['endTime'];

  //   return {
  //     startMinTime: endTime ? new Date(new Date().setTime(0)) : undefined,
  //     startMaxTime: endTime ? new Date() : undefined, // endTime.getTime() - 60000
  //     endMinTime: startTime ? new Date() : undefined, // startTime.getTime() + 60000
  //     endMaxTime: startTime ? new Date(new Date().setTime(3600000 * 22.99)) : undefined,
  //   };
  // }, [formProps]);

  useEffect(() => {
    dispatch(initialMeetingForm(data?.id));
  }, [data, dispatch]);

  // const filterUsers = useDebounce(
  //   (value) => {
  //     dispatch(searchSelectableUsers({ firstName: value }));
  //   },
  //   300,
  //   [dispatch],
  // );

  const repeatOptions = [
    { value: 'days', label: 'days' },
    { value: 'weeks', label: 'weeks' },
    { value: 'months', label: 'months' },
    { value: 'years', label: 'years' },
  ];

  const timeOptions = useMemo(() => {
    const timeTable = [];

    for (let i = 0; i < 10; i++) {
      const t1: { value: string; label: string } = { value: '', label: '' };
      const t2: { value: string; label: string } = { value: '', label: '' };
      const t3: { value: string; label: string } = { value: '', label: '' };
      const t4: { value: string; label: string } = { value: '', label: '' };
      t1.value = '0' + i + ':00';
      t1.label = `${i === 0 ? `12:00 AM` : `${t1.value} AM`}`;

      t2.value = '0' + i + ':15';
      t2.label = `${i === 0 ? `12:15 AM` : `${t2.value} AM`}`;
      t3.value = '0' + i + ':30';
      t3.label = `${i === 0 ? `12:30 AM` : `${t3.value} AM`}`;
      t4.value = '0' + i + ':45';
      t4.label = `${i === 0 ? `12:45 AM` : `${t4.value} AM`}`;
      timeTable.push(t1, t2, t3, t4);
    }
    for (let i = 0; i < 10; i++) {
      const t1: { value: string; label: string } = { value: '', label: '' };
      const t2: { value: string; label: string } = { value: '', label: '' };
      const t3: { value: string; label: string } = { value: '', label: '' };
      const t4: { value: string; label: string } = { value: '', label: '' };
      t1.value = '1' + i + ':00';
      t1.label = `${i < 2 ? `${t1.value} AM` : `${i === 2 ? '12:00 PM' : `0${Number('1' + i) - 12}:00 PM`}`}`;

      t2.value = '1' + i + ':15';
      t2.label = `${i < 2 ? `${t2.value} AM` : `${i === 2 ? '12:15 PM' : `0${Number('1' + i) - 12}:15 PM`}`}`;
      t3.value = '1' + i + ':30';
      t3.label = `${i < 2 ? `${t3.value} AM` : `${i === 2 ? '12:30 PM' : `0${Number('1' + i) - 12}:30 PM`}`}`;
      t4.value = '1' + i + ':45';
      t4.label = `${i < 2 ? `${t4.value} AM` : `${i === 2 ? '12:45 PM' : `0${Number('1' + i) - 12}:45 PM`}`}`;
      timeTable.push(t1, t2, t3, t4);
    }
    for (let i = 0; i < 4; i++) {
      const t1: { value: string; label: string } = { value: '', label: '' };
      const t2: { value: string; label: string } = { value: '', label: '' };
      const t3: { value: string; label: string } = { value: '', label: '' };
      const t4: { value: string; label: string } = { value: '', label: '' };
      t1.value = '2' + i + ':00';
      t1.label = `${i < 2 ? '0' : ''}${Number('2' + i) - 12}:00 PM`;
      t2.value = '2' + i + ':15';
      t2.label = `${i < 2 ? '0' : ''}${Number('2' + i) - 12}:15 PM`;
      t3.value = '2' + i + ':30';
      t3.label = `${i < 2 ? '0' : ''}${Number('2' + i) - 12}:30 PM`;
      t4.value = '2' + i + ':45';
      t4.label = `${i < 2 ? '0' : ''}${Number('2' + i) - 12}:45 PM`;
      timeTable.push(t1, t2, t3, t4);
    }
    return timeTable;
  }, []);
  const startTimeWatch: { value: string; label: string } = formProps.watch('startTime');
  const endTimeWatch: { value: string; label: string } = formProps.watch('endTime');
  const endTimeOptions = useMemo(() => {
    return startTimeWatch ? timeOptions.filter((time) => time.value > startTimeWatch.value) : timeOptions;
  }, [startTimeWatch, timeOptions]);
  const startTimeOptions = useMemo(() => {
    return endTimeWatch ? timeOptions.filter((time) => time.value < endTimeWatch.value) : timeOptions;
  }, [endTimeWatch, timeOptions]);

  const defaultStartDateValue = data?.startDate;
  const startTime = data?.startTime?.slice(0, 5);
  const endTime = data?.endTime?.slice(0, 5);
  const startDate = useMemo(() => {
    try {
      if (defaultStartDateValue) {
        const dateString = moment(defaultStartDateValue).format('YYYY-MM-DD');

        return new Date(dateString);
      } else {
        return new Date();
      }
    } catch (error) {
      console.log(error);
    }
  }, [defaultStartDateValue]);

  const defaultStartTimeArray = useMemo(() => timeOptions.filter((option) => startTime === option.value), [
    startTime,
    timeOptions,
  ]);

  const defaultEndTimeArray = useMemo(() => timeOptions.filter((option) => endTime === option.value), [
    endTime,
    timeOptions,
  ]);
  const defaultStartTime = defaultStartTimeArray[0];
  const defaultEndTime = defaultEndTimeArray[0];

  const [isRepeat, setRepeat] = useState(false);

  const toggleClass = () => {
    setRepeat(!isRepeat);
  };
  useEffect(() => {
    if (data) {
      if (+data?.repeatEvery > 0) {
        setRepeat(true);
      }
    }
  }, [data]);

  const defaultURL = data?.sharepointUrl
    ? data?.sharepointUrl &&
      (data?.sharepointUrl.slice(0, 8) === 'https://' || data?.sharepointUrl.slice(0, 7) === 'http://')
      ? data.sharepointUrl
      : `https://${data?.sharepointUrl}`
    : '';

  return (
    <FormProvider {...formProps}>
      <form
        id="meeting-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit(onSubmit)}
        action=""
        method="post"
      >
        <div className="popup-upload-panel">
          {/* <ImageInput
            id="image"
            name="image"
            label={`Upload ${type === 'account' ? 'Account' : 'Team'}  Photo`}
            maxImageSize={2}
            acceptableFormats={'jpg, png, gif'}
            validation={requiredValidation}
            defaultValue={data?.image}
          /> */}
          <AvatarInput
            id="image"
            name="image"
            label={`Upload ${type === 'account' ? 'Account' : 'Team'}  Photo`}
            maxImageSize={2}
            acceptableFormats={'jpg, png, gif'}
            validation={requiredValidation}
            defaultValue={data?.image}
          />
        </div>
        <div className="popup-upload-panel">
          <ColorInput
            id="color"
            name="color"
            placeholder={`Pick ${type === 'account' ? 'Account' : 'Team'} Color`}
            description="Team initials will be automatically generated from account name."
            validation={requiredValidation}
            defaultValue={data?.color}
          />
        </div>
        <div className="row">
          <div className="col-12" style={{ marginTop: '20px' }}>
            <div className="field-container">
              <FormTextInput
                id="title"
                name="title"
                type="text"
                label={type === 'account' ? 'Account Title' : 'Team Name'}
                placeholder="Enter Title"
                validation={requiredValidation}
                defaultValue={data?.title}
              />
            </div>
          </div>

          <div className="col-12">
            <div className="field-container">
              <SelectInput
                id="administrators"
                name="administrators"
                label={type === 'account' ? 'Account Leaders' : 'Team Leaders'}
                placeholder="Search..."
                validation={requiredValidation}
                // onInputChange={filterUsers}
                options={options}
                defaultOptions={administratorDefaultOptions}
              />
            </div>
          </div>

          <div className="col-12">
            <div className="field-container">
              <SelectInput
                id="shareWith"
                name="shareWith"
                label={type === 'account' ? 'Account Members' : 'Team Members'}
                placeholder="Search..."
                validation={requiredValidation}
                // onInputChange={filterUsers}
                options={options}
                defaultOptions={shareWithDefaultOptions}
              />
            </div>
          </div>
          {/* <div className="col-12">
            <div className="field-container">
              <FormTextInput
                id="facebookGroupUrl"
                name="facebookGroupUrl"
                type="text"
                label={type === 'account' ? 'Facebook Account URL' : 'Facebook Team URL'}
                placeholder="Enter URL"
                defaultValue={data?.facebookGroupUrl}
              />
            </div>
          </div> */}
        </div>
        <h4>Meeting</h4>
        <div className="row">
          <div className="col-12">
            <div className="field-container">
              <FormTextInput
                id="sharepointUrl"
                name="sharepointUrl"
                type="text"
                label="Meeting URL"
                placeholder="Enter Meeting URL"
                defaultValue={defaultURL}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="field-container">
              <DateTimeInput
                id="startDate"
                name="startDate"
                label="Start Date"
                defaultValue={startDate}
                placeholder="Enter Start Date"
                validation={requiredValidation}
              />
            </div>
          </div>
        </div>
        <div className="col-10">
          <div className="row">
            <div className="col-6">
              <div className="field-container">
                <SelectInput
                  id="startTime"
                  name="startTime"
                  label="Start Time"
                  placeholder="Enter Start Time"
                  defaultOptions={defaultStartTime}
                  options={startTimeOptions}
                  validation={requiredValidation}
                  isSingle
                />
                {/*<DateTimeInput
                  id="startTime"
                  name="startTime"
                  label="Start Time"
                  placeholder="Enter Start Time"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  // timeCaption="Time"
                  // timeFormat="HH:mm"
                  // dateFormat="HH:mm"
                  popperPlacement="right"
                  //defaultValue={startTime}
                  // minTime={timeRange.startMinTime}
                  // maxTime={timeRange.startMaxTime}
                  validation={requiredValidation}
                />*/}
              </div>
            </div>
            <div className="col-6">
              <div className="field-container">
                <SelectInput
                  id="endTime"
                  name="endTime"
                  label="End Time"
                  placeholder="Enter End Time"
                  defaultOptions={defaultEndTime}
                  options={endTimeOptions}
                  validation={requiredValidation}
                  isSingle
                />
                {/*<DateTimeInput
                  id="endTime"
                  name="endTime"
                  label="End Time"
                  placeholder="Enter End Time"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  // timeCaption="Time"
                  // timeFormat="HH:mm"
                  // dateFormat="HH:mm"
                  popperPlacement="left"
                  //defaultValue={endTime}
                  // minTime={timeRange.endMinTime}
                  // maxTime={timeRange.endMaxTime}
                  validation={requiredValidation}
                />*/}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12" style={{ margin: '10px' }}>
          <div className="remember-checkbox flex aic jcr">
            <input type="checkbox" id="repeatCheckbox" checked={isRepeat} onChange={toggleClass} />
            <label htmlFor="repeatCheckbox">Repeat</label>
          </div>
        </div>
        <div className="row" id="repeat-segment" style={isRepeat ? { display: 'flex' } : { display: 'none' }}>
          <div className="col-6">
            <div className="field-container">
              <FormTextInput
                id="repeatEvery"
                name="repeatEvery"
                type="text"
                label="Repeat Every"
                placeholder="Enter"
                defaultValue={data?.repeatEvery}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="field-container" style={{ marginTop: '18px' }}>
              <SelectInput
                id="repeatType"
                name="repeatType"
                validation={requiredValidation}
                options={repeatOptions}
                defaultOptions={
                  data?.repeatType ? { value: data.repeatType, label: data.repeatType } : repeatOptions[0]
                }
                isSingle
              />
            </div>
          </div>
        </div>
        <div className="row">{/* <div className="col-12">TODO:</div> */}</div>
      </form>
    </FormProvider>
  );
};
