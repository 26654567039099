import { ILabel, ILabelCreate, ILabelUpdate } from '../../resource/label/label.types';
import { IApiRequestConfig } from '../api.types';

export const searchLabelsApi = (): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'getLabels',
  uri: 'json',
  action: 'getLabels',
  method: 'get',
  params: {},
});

export const createLabelApi = (label: ILabelCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createLabel',
  uri: 'json',
  action: 'saveLabel',
  method: 'post',
  data: label,
});

export const updateLabelApi = (label: ILabelUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateLabel',
  uri: 'json',
  action: 'saveLabel',
  method: 'put',
  data: label,
});

export const deleteLabelApi = (labelId: ILabel['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteLabel',
  uri: 'json',
  action: 'deleteLabel',
  method: 'delete',
  params: {
    id: labelId,
  },
});
