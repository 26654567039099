/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { FunctionComponent } from 'react';
import { meetingSelectors } from '../../../../../../../../../app/resource/meeting/meeting.selectors';

import { useSelectWithParams } from '../../../../../../../../../utils/selector.utils';
import { MenuMembers } from './MenuMembers';
import { TaskMembers } from './TaskMembers';

import { IMembersProps } from './types';

export const Members: FunctionComponent<IMembersProps> = ({ menu, taskId, meetingId }) => {
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);
  //const userIds = useMemo(() => meeting?.shareWithIds ?? [], [meeting]);
  const users = useMemo(() => meeting && ([...meeting.shareWithIds, ...meeting.administratorIds] ?? []), [meeting]);

  const uniqueUserIds = Array.from(new Set(users));

  return meeting && menu ? (
    <MenuMembers userIds={uniqueUserIds} taskId={taskId} />
  ) : (
    <TaskMembers userIds={uniqueUserIds} taskId={taskId} />
  );
};
