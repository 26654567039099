/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { updateAnswer } from '../../../../../../../../app/page/page-storage/answers/answers.actions';

import { answerSelectors } from '../../../../../../../../app/resource/answer/answer.selectors';
import { useSelectWithParams } from '../../../../../../../../utils/selector.utils';
import { IAnswerProps } from './types';

export const Answer: FunctionComponent<IAnswerProps> = ({ answerId }) => {
  const dispatch = useDispatch();
  const answer = useSelectWithParams(answerSelectors.selectResourceById, answerId);
  const [text, setText] = useState(answer?.answer ? answer.answer : '');
  const saveAnswer = useCallback(() => {
    const id = answerId;
    const atQuestionId = answer ? answer.atQuestionId : '';
    const answerClass = answer ? answer.class : '';
    const newData = { ...answer, answer: text, id: id, atQuestionId: atQuestionId, class: answerClass };
    dispatch(updateAnswer(newData));
  }, [answer, answerId, dispatch, text]);
  return (
    <div className="px-0 mb-2">
      {answer && (
        <textarea
          placeholder="Enter"
          value={text}
          style={{ height: `${answer.class}px` }}
          onChange={(event) => setText(event.target.value)}
          onBlur={saveAnswer}
        />
      )}
    </div>
  );
};
