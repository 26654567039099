import { call, put } from '@redux-saga/core/effects';
import { DropResult } from 'react-beautiful-dnd';
import { cutAndInstert } from '../../../../../utils/dnd.utils';
import { selectWithParams } from '../../../../../utils/selector.utils';
import { storeBoardList } from '../../../../resource/boards/boardList/boardList.actions';
import { saveTaskOrder } from '../../../../resource/boards/boardList/boardList.saga-service';
import { boardListSelectors } from '../../../../resource/boards/boardList/boardList.selectors';
import { IBoardList } from '../../../../resource/boards/boardList/boardList.types';

export function* taskDnd(result: DropResult) {
  if (!result.destination) {
    return;
  }
  const taskId = result.draggableId;
  const destinationId = result.destination.droppableId;
  const destinationIndex = result.destination.index;
  const sourceId = result.source.droppableId;
  const sourceIndex = result.source.index;

  if (sourceId === destinationId && destinationIndex === sourceIndex) {
    return;
  }

  const source = (yield selectWithParams(boardListSelectors.selectResourceById, sourceId)) as IBoardList;

  // if (!source || source.tasksIds[sourceIndex] !== taskId) {
  //   console.log('OVDEEE????');
  //   return;
  // }

  const destination = (yield selectWithParams(boardListSelectors.selectResourceById, destinationId)) as IBoardList;

  const [sourceTaskIds, destinationTaskIds] = cutAndInstert(
    source.tasksIds,
    sourceIndex,
    destinationIndex,
    destination?.tasksIds,
  );
  // console.log(sourceTaskIds, destinationTaskIds);
  const sourceObj = JSON.parse(sourceId);
  const destinationObj = JSON.parse(destinationId);

  if (sourceId === destinationId) {
    let updatedList = [];
    const sourceList = (yield selectWithParams(
      boardListSelectors.selectResourceById,
      sourceObj.taskTypeId,
    )) as IBoardList;

    updatedList = [...sourceTaskIds.slice(0, destinationIndex), taskId, ...sourceTaskIds.slice(destinationIndex)];
    //updatedList = destination.tasksIds.splice(sourceIndex, 1);
    console.log(updatedList);
    const updatedSourceList = { ...sourceList, tasksIds: updatedList };
    const updatedGeneratedList = { ...source, tasksIds: updatedList };

    yield put(storeBoardList(updatedSourceList));
    yield put(storeBoardList(updatedGeneratedList));
    if (destinationObj.taskTypeId) {
      yield call(saveTaskOrder, { taskTypeId: destinationObj.taskTypeId, taskIds: updatedList });
    }
  }

  if (sourceId !== destinationId) {
    const updatedSourceList = { ...source, tasksIds: sourceTaskIds };
    yield put(storeBoardList(updatedSourceList));
    if (sourceObj.groupBy === 'groupNonDefaultByUser' && destinationObj.groupBy === 'groupNonDefaultByUser') {
      const sepIndex = taskId.indexOf('-');
      const testId = `${taskId.slice(0, sepIndex)}-${destinationObj.userId}`;

      const updatedIds = destinationTaskIds.filter((id) => id !== testId);
      const findIndex = updatedIds.indexOf(taskId);
      updatedIds[findIndex] = testId;
      console.log(updatedIds);

      const updatedDestinationList = { ...destination, tasksIds: updatedIds };
      console.log(updatedSourceList, updatedDestinationList);
      yield put(storeBoardList(updatedDestinationList));
    } else {
      const updatedDestinationList = { ...destination, tasksIds: destinationTaskIds };
      yield put(storeBoardList(updatedDestinationList));
    }

    const mainSource = (yield selectWithParams(
      boardListSelectors.selectResourceById,
      sourceObj.taskTypeId,
    )) as IBoardList;
    const mainDestination = (yield selectWithParams(
      boardListSelectors.selectResourceById,
      destinationObj.taskTypeId,
    )) as IBoardList;

    const updatedMainSourceList = { ...mainSource, tasksIds: sourceTaskIds };
    const updatedMainDestinationList = { ...mainDestination, tasksIds: destinationTaskIds };

    yield put(storeBoardList(updatedMainSourceList));
    yield put(storeBoardList(updatedMainDestinationList));

    if (destinationObj.taskTypeId) {
      yield call(saveTaskOrder, { taskTypeId: destinationObj.taskTypeId, taskIds: destinationTaskIds });
    }
  }
}
