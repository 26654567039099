import React, { FunctionComponent } from 'react';
import { authSelectors } from '../../../../../app/auth/auth.selectors';
import { boardListsPageSelectors } from '../../../../../app/page/page-storage/boardLists/boardLists.selectors';
import { boardsPageSelectors } from '../../../../../app/page/page-storage/boards/boards.selectors';
import { IBoardList } from '../../../../../app/resource/boards/boardList/boardList.types';
import { meetingSelectors } from '../../../../../app/resource/meeting/meeting.selectors';
import { useSelect, useSelectWithParams } from '../../../../../utils/selector.utils';
import { Board } from '../../../BoardsPage/Board';
import { BoardBody } from '../../../BoardsPage/Board/BoardBody';
import { Boards } from '../../../BoardsPage/Boards';
import { WidgetWrapper } from '../WidgetWrapper';
import { IWidgetUnimplemented } from './types';

export const WidgetUnimplemented: FunctionComponent<IWidgetUnimplemented> = ({ agendaItem, meetingId }) => {
  const isCustom = agendaItem.isCustom === '1' ? true : false;
  const isBoard = agendaItem.boardId !== '0' && agendaItem.boardListId === '0';
  const isBoardList = agendaItem.boardId !== '0' && agendaItem.boardListId !== '0';
  const isEmbedURL = agendaItem.embedUrl !== '' ? true : false;
  const boardIds = useSelect(boardsPageSelectors.selectBoards);
  const boardListsIds: IBoardList['id'][] = useSelect(boardListsPageSelectors.selectBoardLists).filter(
    (boardListId) => boardListId === agendaItem.boardListId,
  );
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);
  const userId = useSelect(authSelectors.selectLoggedUser);

  const admin = meeting?.administratorIds.includes(userId ? userId : '');

  return (
    <WidgetWrapper name={agendaItem.title}>
      <>
        {!isCustom && (
          <div>
            <p>{agendaItem.description}</p>
          </div>
        )}
        {isBoard && agendaItem.boardId && admin !== undefined && (
          <div className="row mb-25 mt-3" style={{ width: '100%' }}>
            <div className="col-12">
              <div className="panel vto-text">
                <div className="panel-header small-header no-border pb-0 mb-2">
                  <h2 className="bold mr-3">Boards</h2>
                </div>
                <Boards boardsIds={boardIds} isAdmin={admin} />
                <Board boardId={agendaItem.boardId} meetingId={meetingId} unextendableBoardList={true} />
              </div>
            </div>
          </div>
        )}
        {isBoardList && agendaItem.boardId && admin !== undefined && (
          <BoardBody
            boardListsIds={boardListsIds}
            boardId={agendaItem.boardId}
            meetingId={meetingId}
            virtualFlag={true}
            unextendableBoard={true}
            isAdmin={admin}
          />
        )}
        {isEmbedURL && <iframe src={agendaItem.embedUrl} title={agendaItem.title} width="100%" height="800"></iframe>}
      </>
    </WidgetWrapper>
  );
};
