import React, { FunctionComponent } from 'react';
import { userSelectors } from '../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { Participant } from './Participant';
import { IParticipantListProps } from './types';

export const ParticipantList: FunctionComponent<IParticipantListProps> = ({ userIds }) => {
  const users = useSelectWithParams(userSelectors.selectResourcesById, userIds);

  return (
    <div className="members_list flex aic jcl px-2 flex-wrap">
      {users.map((user) => user && <Participant key={user.id} user={user} />)}
    </div>
  );
};
