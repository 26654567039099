import React, { useMemo } from 'react';
import { FunctionComponent } from 'react';

import { FormCSTextInput } from '../../../../inputs/CustomSizeTextInput';
import { SelectInput } from '../../../../inputs/SelectInput';
import { IHourlyFormProps } from './types';

export const HourlyForm: FunctionComponent<IHourlyFormProps> = ({ focusHourlyTuple, projectData }) => {
  const hourlyTypeOptions = useMemo(
    () => [
      { value: 'no_rate', label: 'No Hourly Rate' },
      { value: 'project', label: 'Project Hours Rate' },
      { value: 'person', label: 'Person Hourly Rate' },
      { value: 'task', label: 'Task Hourly Rate' },
    ],
    [],
  );
  const defaultTypeOptions = hourlyTypeOptions.filter((type) => type.value === projectData?.hourlyRate);
  return (
    <>
      <div className="row hourly_rates_main" style={{ display: 'flex !important' }}>
        <div className="col-12">
          <label className="w100 flex mb-1">Hourly Rates</label>
          <label className="blueGray">
            <small>We need hourly rates to track your projectʼs billable amount.</small>
          </label>
        </div>
        <div className="col-6 pr-1">
          <div className="field-container mb-3">
            <SelectInput
              id="hourlyRate"
              name="hourlyRate"
              options={hourlyTypeOptions}
              defaultOptions={defaultTypeOptions[0] ? defaultTypeOptions[0] : hourlyTypeOptions[0]} //data.nesto
              isSingle
            />
          </div>
        </div>

        <div className="col-6 pl-1">
          {focusHourlyTuple && focusHourlyTuple.value === 'project' && (
            <div
              className="field-container mb-3 max100 flex-vertical mx-0 project_hourly_amount"
              style={{ paddingTop: '12px' }}
            >
              <div className="flex aic jcc">
                ${' '}
                <FormCSTextInput
                  id="projectHoursRate"
                  name="projectHoursRate"
                  type="text"
                  size="big-input"
                  defaultValue={projectData?.projectHoursRate}
                />
              </div>
            </div>
          )}
          {focusHourlyTuple && focusHourlyTuple.value === 'person' && (
            <div className="field-container mb-3 flex-vertical mx-0 person_hourly_info" style={{ paddingTop: '20px' }}>
              <div className="lightBlue-bg p-05 radius-5 flex aic f-1">
                <i className="icon-info mr-05 f-2 blue" /> You can set your rates in the Team section below
              </div>
            </div>
          )}
          {focusHourlyTuple && focusHourlyTuple.value === 'task' && (
            <div className="field-container mb-3 flex-vertical mx-0 task_hourly_info" style={{ paddingTop: '20px' }}>
              <div className="lightBlue-bg p-05 radius-5 flex aic f-1">
                <i className="icon-info mr-05 f-2 blue" /> You can set your rates in the Task section below
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
