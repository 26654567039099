import * as types from './boardLists.types';
import * as constants from './boardLists.constants';
import { IBoardListCreateForm, IBoardList } from '../../../resource/boards/boardList/boardList.types';
import { ICopyTask, IMoveTask, ITask, ITaskCreate, ITaskUpdate } from '../../../resource/task/task.types';
import { DropResult } from 'react-beautiful-dnd';

export const createBoardList = (boardList: IBoardListCreateForm): types.ICreateBoardList => ({
  type: constants.CREATE_BOARD_LIST,
  payload: boardList,
});

export const updateBoardList = (
  boardListId: IBoardList['id'],
  boardList: IBoardListCreateForm,
): types.IUpdateBoardList => ({
  type: constants.UPDATE_BOARD_LIST,
  payload: {
    id: boardListId,
    data: boardList,
  },
});

export const deleteBoardList = (boardListId: IBoardList['id']): types.IDeleteBoardList => ({
  type: constants.DELETE_BOARD_LIST,
  payload: boardListId,
});

export const moveBoardList = (result: DropResult): types.IMoveBoardList => ({
  type: constants.MOVE_BOARD_LIST,
  payload: result,
});

export const storeBoardLists = (boardLists: IBoardList['id'][]): types.IStoreBoardList => ({
  type: constants.STORE_BOARD_LIST,
  payload: boardLists,
});

export const clearBoardList = (): types.IClearBoardList => ({
  type: constants.CLEAR_BOARD_LIST,
});

export const initializeTaskProps = (taskId: ITask['id']): types.IInitializeTaskProps => ({
  type: constants.INITIALIZE_TASK_PROPS,
  payload: taskId,
});
export const clearTaskProps = (): types.IClearTaskProps => ({
  type: constants.CLEAR_TASK_PROPS,
});
export const createTask = (task: ITaskCreate): types.ICreateTask => ({
  type: constants.CREATE_TASK,
  payload: task,
});

export const updateTask = (task: ITaskUpdate): types.IUpdateTask => ({
  type: constants.UPDATE_TASK,
  payload: {
    data: task,
  },
});

export const deleteTask = (taskId: ITask['id']): types.IDeleteTask => ({
  type: constants.DELETE_TASK,
  payload: taskId,
});

export const moveTask = (data: IMoveTask): types.ITaskMove => ({
  type: constants.MOVE_TASK,
  payload: {
    data: data,
  },
});

export const copyTask = (data: ICopyTask): types.ITaskCopy => ({
  type: constants.COPY_TASK,
  payload: {
    data: data,
  },
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
