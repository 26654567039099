import React, { ChangeEvent, FunctionComponent, useCallback, useState } from 'react';
import { userSelectors } from '../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { IUserListParams, IReducedUsers } from './types';
import { UserWidget } from './UserWidget';
import searchIcon from '../../../resources/images/search-icon.svg';

export const UserList: FunctionComponent<IUserListParams> = ({ onUserSelect, userIds }) => {
  const users = useSelectWithParams(userSelectors.selectResourcesById, userIds);

  const [searchParam, setSearchParam] = useState<string>('');

  const reduceByFirstName = useCallback(
    () =>
      users.reduce((prev, curr) => {
        if (!curr || !curr.firstName.toLowerCase().includes(searchParam.toLowerCase())) {
          return { ...prev };
        }
        if (!prev[curr.firstName[0]]) {
          prev[curr.firstName[0]] = [];
        }
        prev[curr.firstName[0]] = [...prev[curr.firstName[0]], curr];
        return { ...prev };
      }, {} as IReducedUsers),
    [searchParam, users],
  );

  const onFilter = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchParam(event.target.value);
  }, []);

  return (
    <div className="col-3 border-right h100 pr-0">
      <div className="search-form px-2 pt-2">
        <form action="" className="form-search-contacts">
          <input type="text" className="small-input" placeholder="Search Contact..." onChange={onFilter} />
          <button type="button" className="small-submit">
            <img src={searchIcon} alt="search-icon" className="img-fluid" />
          </button>
        </form>
      </div>
      {users && (
        <div className="contact-list">
          {Object.keys(reduceByFirstName()).map((letter) => (
            <div key={letter}>
              <div className="letter-title border-bottom">{letter}</div>
              {reduceByFirstName()[letter].map(
                (user) => user && <UserWidget user={user} key={user.id} onUserSelect={onUserSelect} />,
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
