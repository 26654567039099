import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './passwords.constants';
import * as types from './passwords.types';
import * as services from './passwords.saga-services';

function* invokeInitialPasswordsPage(action: types.IInitialPasswordsPage) {
  yield call(services.initialPasswordsPage, action.payload);
}

export function* watchPasswordsPageSaga() {
  yield takeLatest(constants.INITIAL_PASSWORDS_PAGE, invokeInitialPasswordsPage);
}
