import { put, select, call, all } from 'redux-saga/effects';
import * as types from './project-time.types';
import * as actions from './project-time.actions';
import * as api from './../../api/request-config/project-time.api';
import { projectTimeSelectors } from './project-time.selectors';
import { apiRequest } from '../../api/api.saga-services';

export function* storeProjectTime(projectTask: types.IProjectTasksTime) {
  yield put(actions.storeProjectTime(projectTask));

  return projectTask;
}

export function* clearProjectTime(projectTimeId: types.IProjectTasksTime['id']) {
  yield put(actions.clearProjectTime(projectTimeId));
}

export function* clearAllProjectTimes() {
  const projectTimesIds: types.IProjectTasksTime['id'][] = yield select(projectTimeSelectors.selectResourceIds);
  yield put(actions.clearProjectTime(projectTimesIds));
}

export function* searchProjectTimes(week: string) {
  const projectTimes: types.IProjectTasksTime[] = yield call(apiRequest, api.searchProjectTimesApi(week));

  //projectTimes.forEach((time) => (time.id = time.changedAt));
  yield all(projectTimes.map((projectTime) => call(storeProjectTime, projectTime)));
  return projectTimes.map((projectTime) => projectTime.id);
}
