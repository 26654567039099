import React, { FunctionComponent } from 'react';
import { taskSelectors } from '../../../../../../app/resource/task/task.selectors';
import { useSelectWithParams } from '../../../../../../utils/selector.utils';
import { ITaskCalendarProps } from './types';

export const TaskCalendar: FunctionComponent<ITaskCalendarProps> = ({ taskId }) => {
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId);

  return (
    task && (
      <div className="calendar-item">
        <div className="calendar-event">
          <div className="calendar-card lightGreen-bg green">{task.title}</div>
        </div>
      </div>
    )
  );
};
