import { ICategory, ICategoryCreate, ICategoryUpdate } from '../../resource/category/category.types';
import { IApiRequestConfig } from '../api.types';

export const searchCategoriesApi = (): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'getCategories',
  uri: 'json',
  action: 'getAtCategories',
  method: 'get',
});

export const createCategoryApi = (category: ICategoryCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createCategory',
  uri: 'json',
  action: 'saveCategory',
  method: 'post',
  data: category,
});

export const updateCategoryApi = (category: ICategoryUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateCategory',
  uri: 'json',
  action: 'saveCategory',
  method: 'put',
  data: category,
});

export const deleteCategoryApi = (categoryId: ICategory['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteCategory',
  uri: 'json',
  action: 'deleteCategory',
  method: 'delete',
  params: {
    id: categoryId,
  },
});
