import { FunctionComponent, useMemo } from 'react';
import React from 'react';
import { TaskFormTrigger } from '../../../../dialogs/TaskFormDialog/TaskFormTrigger';
import { IWidgetConclusionProps } from './types';
import { WidgetTasksGroupedByUser } from '../widget-grouped/WidgetTasksGroupedByUser';
import { UserRecaps } from './UserRecaps';
import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { meetingSelectors } from '../../../../../app/resource/meeting/meeting.selectors';
import { OtherComments } from './OtherComments';

export const WidgetConclusion: FunctionComponent<IWidgetConclusionProps> = ({ meetingId, reducedTasks, sessionId }) => {
  const todos = useMemo(() => reducedTasks['4'], [reducedTasks]);

  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);

  const userIds = useMemo(
    () =>
      meeting
        ? [...meeting.shareWithIds, ...meeting.administratorIds].filter((value, index, self) => {
            return self.indexOf(value) === index;
          })
        : [],
    [meeting],
  );
  const nextSessionId = useMemo(() => (meeting ? (meeting.sessionIds ? meeting.sessionIds[0] : '') : ''), [meeting]);

  return (
    <>
      {todos && (
        <WidgetTasksGroupedByUser
          meetingId={meetingId}
          actionElement={<TaskFormTrigger type="4" meetingId={meetingId} />}
          name="To Do List Recap"
          type="4"
          tasksPerUser={todos}
        />
      )}
      <UserRecaps userIds={userIds} nextSessionId={nextSessionId} />
      <OtherComments sessionId={sessionId} />
    </>
  );
};
