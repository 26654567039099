/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../app/dialog/dialog.actions';

export const SignUp: FunctionComponent = () => {
  const dispatch = useDispatch();

  const onClickDialog = useCallback(() => {
    dispatch(openDialog('signUpFormDialog'));
  }, [dispatch]);

  return (
    <label style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', lineHeight: '1.3' }}>
      {`Don't have an account?`}
      <label style={{ color: '#1bc3a7', paddingBottom: '10px' }} onClick={onClickDialog}>
        Sign up
      </label>
    </label>
  );
};
