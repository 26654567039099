import * as types from './timer.types';
import * as constants from './timer.constants';
import { IProjectTask } from '../../resource/project-task/project-task.types';
import { IMeeting } from '../../resource/meeting/meeting.types';
import { IProjectTasksTime } from '../../resource/project-time/project-time.types';

export const initialTimer = (): types.IInitialTimer => ({
  type: constants.INITIAL_TIMER,
});

export const storeIsOpen = (): types.IStoreIsOpen => ({
  type: constants.STORE_IS_OPEN,
  payload: true,
});
export const clearIsOpen = (): types.IClearIsOpen => ({
  type: constants.CLEAR_IS_OPEN,
});

export const storeTimerStatus = (timerStatus: types.ITimerStatus): types.IStoreTimerStatus => ({
  type: constants.STORE_TIMER_STATUS,
  payload: timerStatus,
});
export const clearTimerStatus = (): types.IClearTimerStatus => ({
  type: constants.CLEAR_TIMER_STATUS,
});

export const changeTime = (time: number): types.IChangeTime => ({
  type: constants.STORE_TIME,
  payload: time,
});
export const storeTime = (time: number): types.IStoreTime => ({
  type: constants.STORE_TIME,
  payload: time,
});

export const clearTime = (): types.IClearTime => ({
  type: constants.CLEAR_TIME,
});

export const storeProjectTasks = (projectTaskIds: IProjectTask['id'][]): types.IStoreProjectTasks => ({
  type: constants.STORE_PROJECT_TASKS,
  payload: projectTaskIds,
});

export const clearProjectTasks = (): types.IClearProjectTasks => ({
  type: constants.CLEAR_PROJECT_TASKS,
});

export const storeProjectTask = (projectTaskId: IProjectTask['id']): types.IStoreProjectTask => ({
  type: constants.STORE_PROJECT_TASK,
  payload: projectTaskId,
});

export const clearProjectTask = (): types.IClearProjectTask => ({
  type: constants.CLEAR_PROJECT_TASK,
});

export const storeClient = (clientId: IMeeting['id']): types.IStoreClient => ({
  type: constants.STORE_CLIENT,
  payload: clientId,
});

export const clearClient = (): types.IClearClient => ({
  type: constants.CLEAR_CLIENT,
});

export const storeNote = (note: string): types.IStoreNote => ({
  type: constants.STORE_NOTE,
  payload: note,
});

export const clearNote = (): types.IClearNote => ({
  type: constants.CLEAR_NOTE,
});

export const saveTimerResult = (result: types.ITimerSave): types.ISaveTimerResult => ({
  type: constants.SAVE_TIMER_RESULT,
  payload: result,
});

export const toggleTimer = (isOpen: boolean): types.IToggleTimer => ({
  type: constants.TOGGLE_TIMER,
  payload: isOpen,
});

export const changeTimerStatus = (
  status: types.ITimerStatus,
  timerSaveForm?: types.ITimerSaveForm,
): types.IChangeTimerStatus => ({
  type: constants.CHANGE_TIMER_STATUS,
  payload: { status, timerSaveForm },
});

export const chooseProjectTask = (projectTaskId: IProjectTask['id']): types.IChooseProjectTask => ({
  type: constants.CHOOSE_PROJECT_TASK,
  payload: projectTaskId,
});

export const chooseClient = (clientId: IMeeting['id'], external?: true): types.IChooseClient => ({
  type: constants.CHOOSE_CLIENT,
  payload: { clientId, external },
});

export const chooseTimerResult = (timerResultId: IProjectTasksTime['id']): types.IChooseTimerResult => ({
  type: constants.CHOOSE_TIMER_RESULT,
  payload: timerResultId,
});
export const storeTimerResult = (timerResultId: IProjectTasksTime['id']): types.IStoreTimerResult => ({
  type: constants.STORE_TIMER_RESULT,
  payload: timerResultId,
});
export const clearTimerResult = (): types.IClearTimerResult => ({
  type: constants.CLEAR_TIMER_RESULT,
});

export const cancelEdit = (): types.ICancelEdit => ({
  type: constants.CANCEL_EDIT,
});
