import { put, select } from 'redux-saga/effects';

import * as actions from './user-form.actions';

import { meetingSelectors } from '../../../../resource/meeting/meeting.selectors';
import { IMeeting } from '../../../../resource/meeting/meeting.types';
import { IFilterParams } from './user-form.types';

export function* filterSelectableTeams(searchParams?: IFilterParams) {
  const selectableMeetings = (yield select(meetingSelectors.selectResourceList)) as IMeeting[];
  yield put(
    selectableMeetings
      ? actions.storeSelectableTeams(
          selectableMeetings
            .filter(
              (meeting) => meeting.type === 'meeting' && meeting.title.includes(searchParams ? searchParams.title : ''),
            )
            .map((meeting) => meeting.id),
        )
      : actions.clearSelectableTeams(),
  );
}

export function* filterSelectableAccounts(searchParams?: IFilterParams) {
  const selectableMeetings = (yield select(meetingSelectors.selectResourceList)) as IMeeting[];
  yield put(
    selectableMeetings
      ? actions.storeSelectableAccounts(
          selectableMeetings
            .filter(
              (meeting) => meeting.type === 'account' && meeting.title.includes(searchParams ? searchParams.title : ''),
            )
            .map((meeting) => meeting.id),
        )
      : actions.clearSelectableAccounts(),
  );
}
