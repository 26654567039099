/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { ISectionProps } from './types';

import { sectionSelectors } from '../../../../app/resource/section/section.selectors';
import { useDispatch } from 'react-redux';
import { deleteSection, updateSection } from '../../../../app/page/page-storage/templates/templates.actions';
import { ISection, ISectionCreate } from '../../../../app/resource/section/section.types';
import { openDialog } from '../../../../app/dialog/dialog.actions';

export const Section: FunctionComponent<ISectionProps> = ({ sectionId }) => {
  const dispatch = useDispatch();
  const section = useSelectWithParams(sectionSelectors.selectResourceById, sectionId);

  const openScoreFormDialog = useCallback(() => {
    if (section) {
      dispatch(
        openDialog('sectionFormDialog', {
          templateId: section.templateId,
          onSubmit: (newSection: ISectionCreate, id: ISection['id'] | undefined) => {
            if (id) {
              dispatch(updateSection({ ...newSection, id }));
            }
          },
          onDelete: () => {
            dispatch(deleteSection(sectionId));
          },
          data: section,
        }),
      );
    }
  }, [dispatch, section, sectionId]);
  return (
    section && (
      <div className="panel mb-1">
        <div className="panel-body">
          <div className="panel green-gradient py-1 px-2 flex aic">
            <h2 className="bold white">{section.title}</h2>
            <a className="link white ml-auto popup_button" onClick={openScoreFormDialog}>
              Edit
            </a>
          </div>
          <div className="p-2" dangerouslySetInnerHTML={{ __html: section.description }}></div>
        </div>
      </div>
    )
  );
};
