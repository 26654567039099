import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { eventSelectors } from '../../../../../../app/resource/event/event.selectors';
import { IEvent } from '../../../../../../app/resource/event/event.types';
import { formatHour } from '../../../../../../utils/date.utils';
import { useSelectWithParams } from '../../../../../../utils/selector.utils';
import { IEventWidgetProps } from './types';

const getRandomInt = (max: number) => Math.floor(Math.random() * max);

export const EventWidgets: FunctionComponent<IEventWidgetProps> = ({ eventIds }) => {
  const events = useSelectWithParams(eventSelectors.selectResourcesById, eventIds);

  const reducedEvents = useMemo(() => {
    return events.reduce((prev, curr) => {
      if (!curr) {
        return { ...prev };
      }
      const holder = { ...prev };
      const eventStart = formatHour(curr.start);

      if (!holder[eventStart]) {
        holder[eventStart] = [];
      }

      holder[eventStart] = [...holder[eventStart], curr];
      return holder;
    }, {} as { [key: string]: IEvent[] });
  }, [events]);

  const randColor = useCallback(() => {
    const colors = ['lightBlue-bg blue', 'lightRed-bg red', 'lightYellow-bg yellow', 'lightPurple-bg purple'];
    return colors[getRandomInt(colors.length)];
  }, []);

  return (
    <>
      {events &&
        Object.keys(reducedEvents).map((eventStart) => (
          <div key={`reducedEvents[${eventStart}]`} className="calendar-item">
            <div className="calendar-time blueGray">{eventStart}</div>
            <div className="calendar-event">
              {reducedEvents[eventStart].map((event, index) => (
                <div
                  key={`reducedEvents[${eventStart}][${index}]`}
                  className={`calendar-card ${event.color ? 'todo' : randColor()}`}
                >
                  {`${formatHour(event.start)} - ${formatHour(event.end)}: ${event.summary}`}
                </div>
              ))}
            </div>
          </div>
        ))}
    </>
  );
};
