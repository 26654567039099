/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo } from 'react';
import { FunctionComponent } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { useApiRequestState } from '../../../utils/api.utils';
import { useSelectWithParams } from '../../../utils/selector.utils';

import { KpiNoteForm } from '../../pages/common/forms/KpiNoteForm';
import { SubmitButton } from '../../pages/common/forms/SubmitButton';
import { ConnectedDialog } from '../ConnectedDialog';
import { IKpiNoteFormDialogProps } from './types';

export const KpiNoteDialog: FunctionComponent = () => {
  const dispatch = useDispatch();

  const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'kpiNoteFormDialog') as IKpiNoteFormDialogProps;
  const [showLoader, error] = useApiRequestState('updateScoreboard');

  const onClose = useCallback(() => {
    dispatch(closeDialog('kpiNoteFormDialog'));
  }, [dispatch]);

  const title = useMemo(() => (props && props.scoreboard?.note === '' ? 'Add Note' : 'Edit Note'), [props]);
  return (
    <ConnectedDialog dialogKey="kpiNoteFormDialog">
      <div className="popup new_todo_popup" style={{ display: 'block' }}>
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            {title}

            <span className="close-popup close" onClick={onClose}>
              {!error && !showLoader && <i className="icon-close" />}
              {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
              {error && !showLoader && <p style={{ color: 'red' }}> Something went wrong </p>}
            </span>
          </div>
          <div className="panel-body">
            <KpiNoteForm {...props} />
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" onClick={onClose}>
              Close
            </a>
            <SubmitButton formName="kpinote-form" text="Save" />
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
