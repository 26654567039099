import React, { FunctionComponent, useCallback } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { dndWidget } from '../../../../../app/page/page-storage/widgets/widgets.actions';

export const WidgetsDragDropContext: FunctionComponent = ({ children }) => {
  const dispatch = useDispatch();
  const handleDrop = useCallback(
    (result: DropResult) => {
      dispatch(dndWidget(result));
    },
    [dispatch],
  );

  return <DragDropContext onDragEnd={handleDrop}>{children}</DragDropContext>;
};
