import React, { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { saveFile } from '../../../../../app/page/page-storage/activities/activities.actions';

import { ISaveFile } from '../../../../../app/resource/activity/activity.types';

import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { FileInput } from '../../inputs/FileInput';
import { FormSmallTextInput } from '../../inputs/SmallTextInput';

import { IAttachmentFormProps } from './types';

export const AttachmentForm: FunctionComponent<IAttachmentFormProps> = ({ taskId }) => {
  const dispatch = useDispatch();

  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: ISaveFile) => {
    data.taskId = taskId;
    dispatch(saveFile({ ...data }));
  };

  return (
    <FormProvider {...formProps}>
      <form
        id="saveFile-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as ISaveFile))}
        method="post"
      >
        <div className="field-container">
          <FileInput id="fileString" name="fileString" validation={requiredValidation} maxFileSize={5} />
        </div>
        <div className="field-container">
          <FormSmallTextInput
            id="fileString"
            name="fileString"
            type="text"
            label="Attach a link"
            placeholder="Paste any link here..."
            validation={requiredValidation}
          />
        </div>
        <button type="submit" className="btn btn-md green-bg white d-block w100 jcc">
          Attach
        </button>
      </form>
    </FormProvider>
  );
};
