import { IPasswordGroupSave, IPasswordGroupSearchParams } from '../../resource/password-group/password-group.types';
import { IApiRequestConfig } from '../api.types';

export const searchPasswordGroupsApi = (params?: IPasswordGroupSearchParams): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'searchPasswordGroups',
  uri: 'json',
  action: 'getPasswordGroups',
  method: 'get',
  params: {
    ...params,
  },
});

export const savePasswordGroupApi = (passwordGroup: IPasswordGroupSave): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'savePasswordGroup',
  uri: 'json',
  action: 'savePasswordGroup',
  method: 'post',
  data: passwordGroup,
});
