import { call, put, select } from 'redux-saga/effects';

import * as actions from './password-groups.actions';

import * as passwordGroupService from '../../../resource/password-group/password-group.saga-service';
import * as dialogService from '../../../dialog/dialog.saga-services';

import {
  IPasswordGroup,
  IPasswordGroupSave,
  IPasswordGroupSearchParams,
} from '../../../resource/password-group/password-group.types';
import { passwordGroupsPageSelectors } from './password-groups.selectors';

export function* getPasswordGroups(searchParams?: IPasswordGroupSearchParams) {
  const passwordGroups = (yield call(passwordGroupService.getPasswordGroups, searchParams)) as IPasswordGroup[];
  yield put(
    passwordGroups
      ? actions.storePasswordGroups(passwordGroups.map((passwordGroup) => passwordGroup.id))
      : actions.clearPasswordGroups(),
  );
}

export function* savePasswordGroup(passwordGroup: IPasswordGroupSave) {
  const passwordGroupId = (yield call(passwordGroupService.savePasswordGroup, passwordGroup)) as IPasswordGroup['id'];

  if (!passwordGroupId) {
    return;
  }

  const passwordGroupIds = (yield select(passwordGroupsPageSelectors.selectPasswordGroupIds)) as IPasswordGroup['id'][];
  yield put(actions.storePasswordGroups([...passwordGroupIds, passwordGroupId]));
  yield call(dialogService.closeDialog, 'passwordGroupFormDialog');
}

export function* clearPasswordGroupsPageStorage() {
  yield put(actions.clearPasswordGroups());
}
