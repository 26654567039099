/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { useFieldArray, useFormContext } from 'react-hook-form';

import { IAgendaUpdate } from '../../../../../app/resource/agenda/agenda.types';

import { AgendaItem } from './AgendaItem';

import { IAgendaItemsFormProps } from './types';

export const AgendaItemsForm: FunctionComponent<IAgendaItemsFormProps> = ({
  agendaItems,
  getFormData,
  setAddItem,
  addItem,
  addAgendaState,
  setAddAgendaState,
  onAdd,
  setOnAdd,
}) => {
  const { control, setError, errors } = useFormContext();
  // eslint-disable-next-line prefer-const
  const { fields, append, remove, move } = useFieldArray<IAgendaUpdate>({ control, name: 'agendaItems' });

  const [removeRender, setRemoveRender] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const handleChange = useCallback(() => {
    //setagendaItems(fields.map((field) => field.a))
    setDisabled(!disabled);
  }, [disabled]);
  useEffect(() => {
    if (addAgendaState.title !== '') {
      append({
        title: addAgendaState.title,
        id: undefined,
        checked: addAgendaState.checked,
        duration: addAgendaState.duration,
        agendaId: addAgendaState.agendaId,
        description: addAgendaState.description,
        sort: addAgendaState.sort,
        isCustom: '1',
        boardId: addAgendaState.boardId,
        boardListId: addAgendaState.boardListId,
        embedUrl: addAgendaState.embedUrl,
      });
      setAddAgendaState({
        title: '',
        boardId: '',
        boardListId: '',
        embedUrl: '',
        checked: '1',
        duration: '5',
        agendaId: '',
        description: '',
        sort: '',
      });
    }
    if (onAdd && fields.length > 1 && fields[fields.length - 2].keyName === 'conclusion') {
      move(fields.length - 2, fields.length - 1);
      setOnAdd(false);
    }
  }, [addAgendaState, append, setAddAgendaState, fields, move, onAdd, setOnAdd]);

  const removeAgendaItem = useCallback(
    (index) => {
      setRemoveRender(true);
      if (fields.length > 0) {
        remove(index);
      } else {
        setError('agendaItems', { type: 'minLength', message: 'You need to add at least one entry' });
      }
    },
    [fields.length, remove, setError],
  );

  useEffect(() => {
    if (agendaItems) {
      if (agendaItems.length > 0 && fields.length < agendaItems.length && !removeRender) {
        append(agendaItems);
      }
    }
  }, [agendaItems, append, fields.length, removeRender]);

  const { watch } = useFormContext();

  const times = useMemo(
    () =>
      fields.map((field, index) =>
        disabled
          ? +watch(`agendaItems[${index}].checked`) === 1
            ? +watch(`agendaItems[${index}].duration`)
            : 0
          : +watch(`agendaItems[${index}].checked`) === 1 && field.agendaId !== ''
          ? +watch(`agendaItems[${index}].duration`)
          : 0,
      ),
    [disabled, fields, watch],
  );

  const showTime = useMemo(() => times.reduce((prev, curr) => (curr ? curr : 0) + prev, 0), [times]);

  const handleDrop = useCallback(
    (result: DropResult) => {
      setOnAdd(false);
      const sourceIndex = result.source.index;
      if (result.destination) {
        const destinationIndex = result.destination?.index;
        move(sourceIndex, destinationIndex);
      }
    },
    [move, setOnAdd],
  );

  return (
    <>
      <div className="row">
        <div className="col-12 mb-2" style={{ display: 'none' }}>
          <div className="remember-checkbox flex aic jcl">
            <input
              type="checkbox"
              id="defaultCheckbox"
              name="defaultCheckbox"
              onChange={handleChange}
              checked={false}
            />
            <label htmlFor="defaultCheckbox">Default 90 Minute Meeting</label>
          </div>
        </div>

        <div className="col-12">
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId={`agendaItems`}>
              {(provided) => (
                <div
                  className="row meeting90-container"
                  style={{ flexDirection: 'column' }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {fields.map(
                    (field, index) =>
                      field && (
                        <Draggable
                          key={`${field.agendaId}`}
                          draggableId={field.agendaId ? field.agendaId : 'testId'}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="col-6 meeting-list-item"
                              key={index}
                              style={{ maxWidth: 'none', width: '100%' }}
                            >
                              <AgendaItem
                                removeAgendaItem={removeAgendaItem}
                                index={index}
                                agendaItem={field}
                                defaultCheckbox={disabled}
                                provided={provided}
                              />
                            </div>
                          )}
                        </Draggable>
                      ),
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className="row flex aic">
            <div className="col-6 flex aic">
              <div className="flex aic py-2">
                {/* <a className="btn btn-sm white-bg btn-border mx-0" onClick={addAgendaItem}> */}
                <a className="btn btn-sm white-bg btn-border mx-0" onClick={() => setAddItem(!addItem)}>
                  <i className="icon-plus" /> Add Agenda Item
                </a>
              </div>
            </div>
            <div className="col-6 flex aic jcr">
              <p>
                Total Meeting Duration: <span className="total_minutes">{showTime}</span> minute
              </p>
            </div>
          </div>
        </div>
      </div>
      {!!errors.agendaItems?.message && (
        <div>
          <p>{errors.agendaItems.message}</p>
        </div>
      )}
    </>
  );
};
