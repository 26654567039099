import { all, call, put } from 'redux-saga/effects';

import * as types from './template.types';
import * as actions from './template.actions';
import * as api from '../../api/request-config/template.api';
import * as sectionService from '../section/section.saga-service';
import { ISection } from '../section/section.types';
import { apiRequest } from '../../api/api.saga-services';
import { templateSelectors } from './template.selectors';
import { select } from '../../../utils/selector.utils';

export function* storeTemplate(template: types.ITemplate) {
  yield put(actions.storeTemplate(template));
  return template.id;
}

export function* clearTemplate(templateId: types.ITemplate['id']) {
  yield put(actions.clearTemplate(templateId));
}

export function* clearAllTemplates() {
  const templateIds: types.ITemplate['id'][] = yield select(templateSelectors.selectResourceIds);
  yield put(actions.clearTemplate(templateIds));
}

export function* searchTemplates(searchParams?: types.ITemplateSearchParams) {
  const templateResponses: types.ITemplateResponse[] = yield call(apiRequest, api.searchTemplateApi(searchParams));

  const sections = templateResponses.reduce((prev, curr) => [...prev, ...curr.sections], [] as ISection[]);
  yield all(sections.map((section) => call(sectionService.storeSection, section)));

  const templateIds = yield all(
    templateResponses.map((response) => call(storeTemplate, convertTemplateResponseToTemplate(response))),
  );

  return templateIds;
}

export function* createTemplate(templateInfo: types.ITemplateCreate) {
  const templateResponse: types.ITemplateResponse = yield call(apiRequest, api.createTemplateApi(templateInfo));

  yield all(templateResponse.sections.map((section) => call(sectionService.storeSection, section)));

  const templateId = yield call(storeTemplate, convertTemplateResponseToTemplate(templateResponse));

  return templateId;
}

export const convertTemplateResponseToTemplate = (templateResponse: types.ITemplateResponse) => ({
  id: templateResponse.id,
  title: templateResponse.title,
  sectionIds: templateResponse.sections.map((section) => section.id),
});
