import { call } from 'redux-saga/effects';

import * as pageInitializerService from '../page.initializer.saga-service';
import * as pageStateService from '../page-state/page-state.saga-services';
import * as sessionService from '../page-storage/sessions/sessions.saga-services';
import { select } from '../../../utils/selector.utils';
import { authSelectors } from '../../auth/auth.selectors';

export function* initialMeetingPage() {
  yield call(pageStateService.initPage, 'home');
  //yield call(agendaService.setupDefaultAgendaItems);
  if (!((yield call(pageInitializerService.initPage)) as boolean)) {
    const userId = yield select(authSelectors.selectLoggedUser);
    yield call(sessionService.setupSessions, { userId: userId });
  }

  yield call(pageStateService.completedInit, 'home');
}
