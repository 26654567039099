import React from 'react';
import { FunctionComponent } from 'react';

import { FormCSTextInput } from '../../../../../inputs/CustomSizeTextInput';

import { IProjectMemberProps } from './types';

import { useFormContext, useWatch } from 'react-hook-form';

import { FormCheckboxInput } from '../../../../../inputs/CheckboxInput';
import { Member } from './Member';

export const ProjectMember: FunctionComponent<IProjectMemberProps> = ({
  index,
  projectMember,
  removeMember,
  userId,
  // focusHourlyTuple,
  // focusBudgetTuple,
}) => {
  const { control } = useFormContext();
  const focusHourlyTuple = useWatch<{ [key: string]: string }>({
    control,
    name: 'hourlyRate',
  });
  const focusBudgetTuple = useWatch<{ [key: string]: string }>({
    control,
    name: 'budgetType',
  });

  return (
    <div className="flex">
      <div className="flex aic">
        <span className="remove-task" onClick={() => removeMember(index)}>
          ×
        </span>
        {projectMember.id && (
          <div style={{ display: 'none' }}>
            <FormCSTextInput
              id={`teams[${index}].id`}
              name={`teams[${index}].id`}
              type="text"
              size="small-input"
              defaultValue={projectMember.id}
            />
          </div>
        )}
        <Member userId={userId} index={index} />
      </div>
      <div className="flex aic jcr ml-auto">
        <div className="remember-checkbox">
          <FormCheckboxInput
            id={`teams[${index}].manage`}
            name={`teams[${index}].manage`}
            defaultChecked={projectMember.manage === '1' ? true : false}
          />
          <label htmlFor={`teams[${index}].manage`} />
        </div>
      </div>
      {focusBudgetTuple && focusBudgetTuple.value === 'hours_per_person' && (
        <div className="flex aic jcr max100 ml-1 team_member_budget_hours" style={{ display: 'none !important' }}>
          <div className="field-container flex aic jcr mb-0">
            <FormCSTextInput
              id={`teams[${index}].budgetHours`}
              name={`teams[${index}].budgetHours`}
              type="text"
              size="small-input"
              defaultValue={projectMember.budgetHours ? projectMember.budgetHours : '10'}
            />
          </div>
        </div>
      )}
      {focusHourlyTuple && focusHourlyTuple.value === 'person' && (
        <div className="flex aic jcr max100 ml-1 team_member_hourly_rate" style={{ display: 'block !important' }}>
          <div className="field-container flex aic jcr mb-0">
            ${' '}
            <FormCSTextInput
              id={`teams[${index}].hourlyRate`}
              name={`teams[${index}].hourlyRate`}
              type="text"
              size="small-input"
              defaultValue={projectMember.hourlyRate ? projectMember.hourlyRate : '100'}
            />
          </div>
        </div>
      )}
    </div>
  );
};
