/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { FunctionComponent } from 'react';
import { kpiPageSelectors } from '../../../../../../app/page/page-storage/kpi/kpi.selectors';
import { useSelect } from '../../../../../../utils/selector.utils';
import { ScoreRow } from './ScoreRow';

export const WidgetKpiSettings: FunctionComponent = () => {
  const scoreIds = useSelect(kpiPageSelectors.selectScoreIds);
  return (
    <div className="panel">
      <div className="panel-body pt-0 ho-hover">
        <div className="border-radius-1 lightGray-bg p-2" style={{ position: 'sticky', top: '20px' }}>
          <div className="row">
            <div className="col">
              <div className="table-container kpi-settings-table" role="table" aria-label="KPIs settings">
                <div className="flex-table header panel mb-1 aic py-0" role="rowgroup">
                  <div className="flex-row" role="columnheader">
                    USER
                  </div>
                  <div className="flex-row" role="columnheader">
                    MEASURABLE
                  </div>
                  <div className="flex-row" role="columnheader">
                    GOAL
                  </div>
                  <div className="flex-row last" role="columnheader" />
                </div>

                {scoreIds.map((scoreId) => (
                  <ScoreRow key={scoreId} scoreId={scoreId} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
