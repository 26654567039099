import { call, put, select } from 'redux-saga/effects';

import * as actions from './user-groups.actions';

import * as userGroupService from '../../../resource/user-group/user-group.saga-service';
import * as dialogService from '../../../dialog/dialog.saga-services';

import { userGroupsPageSelectors } from './user-groups.selectors';
import { IUserGroup, IUserGroupCreate, IUserGroupSearchParamas } from '../../../resource/user-group/user-group.types';

export function* getUserGroups(searchParams?: IUserGroupSearchParamas) {
  const userGroups = (yield call(userGroupService.getUserGroups, searchParams)) as IUserGroup[];

  yield put(userGroups ? actions.storeUserGroups(userGroups.map((user) => user.id)) : actions.clearUserGroups());
}

export function* createUserGroup(userGroup: IUserGroupCreate) {
  const userGroupId = (yield call(userGroupService.createUserGroup, userGroup)) as IUserGroup['id'];

  if (!userGroupId) {
    return;
  }

  const userGroupIds = (yield select(userGroupsPageSelectors.selectUserGroupIds)) as IUserGroup['id'][];
  yield put(actions.storeUserGroups([...userGroupIds, userGroupId]));
  yield call(dialogService.closeDialog, 'userGroupFormDialog');
}

export function* clearUserGroupsPageStorage() {
  yield put(actions.clearUserGroups());
}
