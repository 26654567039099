/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React from 'react';
import { FunctionComponent, useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../../app/dialog/dialog.actions';
import { createMeeting } from '../../../../../../app/page/page-storage/meetings/meetings.actions';
import { meetingSelectors } from '../../../../../../app/resource/meeting/meeting.selectors';
import { IMeetingCreateForm, IMeetingNewCreateForm } from '../../../../../../app/resource/meeting/meeting.types';
import { useRequiredValidation } from '../../../../../../utils/form/validation.utils';
import { useSelect } from '../../../../../../utils/selector.utils';
import { FormCSTextInput } from '../../../inputs/CustomSizeTextInput';
import { DateTimeInput } from '../../../inputs/DateTimeInput';
import { SelectInput } from '../../../inputs/SelectInput';
import { IProjectDetailsFormProps } from './types';

export const ProjectDetailsForm: FunctionComponent<IProjectDetailsFormProps> = ({ projectData }) => {
  const dispatch = useDispatch();

  const requiredValidation = useRequiredValidation();
  const type = 'account';
  const openMeetingForm = useCallback(() => {
    dispatch(
      openDialog('meetingFormDialog', {
        type,
        onSubmit: (meeting: IMeetingCreateForm) => {
          // hot fix
          dispatch(createMeeting({ ...meeting, type } as IMeetingNewCreateForm));
          //dispatch(createMeeting({ ...meeting, type }));
        },
      }),
    );
  }, [dispatch]);

  const meetings = useSelect(meetingSelectors.selectResourceList);
  const accounts = useMemo(() => meetings.filter((meeting) => meeting.type === type), [meetings]);
  const accountOptions = useMemo(
    () =>
      accounts.map((account) => ({
        value: account.id,
        label: account.title,
      })),
    [accounts],
  );
  const defaultAccount = accountOptions.find((option) => option.value === projectData?.accountId);

  const defaultStartDateValue = projectData?.startDate;
  const startDate = useMemo(() => {
    try {
      if (defaultStartDateValue) {
        const dateString = moment(defaultStartDateValue).format('YYYY-MM-DD');

        return new Date(dateString);
      } else {
        return new Date();
      }
    } catch (error) {
      console.log(error);
    }
  }, [defaultStartDateValue]);

  const defaultEndDateValue = projectData?.endDate;
  const endDate = useMemo(() => {
    try {
      if (defaultEndDateValue) {
        const dateString = moment(defaultEndDateValue).format('YYYY-MM-DD');

        return new Date(dateString);
      } else {
        return new Date();
      }
    } catch (error) {
      console.log(error);
    }
  }, [defaultEndDateValue]);

  return (
    <>
      <div className="panel white-bg p-2">
        <div className="panel green-gradient py-1 px-2 flex aic mb-3">
          <h2 className="bold white">Project details</h2>
        </div>
        <div className="field-container">
          <div className="row">
            <div className="col-6 pr-1">
              <div className="field-container mb-0">
                <SelectInput
                  id="account"
                  name="account"
                  label="Account"
                  placeholder="Select"
                  defaultOptions={defaultAccount}
                  validation={requiredValidation}
                  options={accountOptions}
                  isSingle
                />
              </div>
            </div>
            <div className="col-6 pl-1" style={{ paddingTop: '30px' }}>
              <div className="field-container mb-0">
                <a
                  className="btn darkGray-bg white px-2 d-inline-block popup_button"
                  data-popup="new_account_popup"
                  title="New Account"
                  onClick={openMeetingForm}
                >
                  <i className="icon-plus" /> New Account
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="field-container">
          <div className="row"></div>
          <hr />
          <div className="row">
            <div className="col-6 pr-1">
              <div className="field-container mb-0">
                <FormCSTextInput
                  id="name"
                  name="name"
                  type="text"
                  label="Project Name"
                  placeholder="Email Marketing..."
                  defaultValue={projectData ? projectData.name : ''}
                  validation={requiredValidation}
                  size="medium-input"
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-6 pr-1">
              <div className="field-container">
                <DateTimeInput
                  id="startDate"
                  name="startDate"
                  label="Start Date"
                  defaultValue={startDate}
                  placeholder="Enter Start Date"
                  validation={requiredValidation}
                />
              </div>
            </div>
            <div className="col-6 pr-1">
              <div className="field-container mb-0">
                <DateTimeInput
                  id="endDate"
                  name="endDate"
                  label="End Date"
                  defaultValue={endDate}
                  placeholder="Enter End Date"
                  validation={requiredValidation}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-12 pr-1">
              <div className="field-container mb-0">
                <label htmlFor="note" className="f-14">
                  Notes
                </label>
                <FormCSTextInput
                  id="note"
                  name="note"
                  type="text"
                  placeholder="Optional, but great for notes like invoice schedules. You can se notes when creating an invoice for Fixed Fee projects."
                  defaultValue={projectData ? projectData.note : ''}
                  size="medium-input"
                  multi
                />
              </div>
              <p className="red mt-2">
                <small>Note can only be seen by administrators.</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
    </>
  );
};
