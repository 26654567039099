import { createActionConst } from '../../../../utils/action.utils';

const CALENDARS_NAMESPACE = 'CALENDARS';

export const SETUP_CALENDAR = createActionConst(CALENDARS_NAMESPACE, 'SETUP_CALENDAR');

export const STORE_CALENDAR_ITEMS = createActionConst(CALENDARS_NAMESPACE, 'STORE_CALENDAR_ITEMS'); // This is also a date
export const CLEAR_CALENDAR_ITEMS = createActionConst(CALENDARS_NAMESPACE, 'CLEAR_CALENDAR_ITEMS');

export const TOGGLE_CALENDAR = createActionConst(CALENDARS_NAMESPACE, 'TOGGLE_CALENDAR');
export const STORE_TOGGLED_CALENDARS = createActionConst(CALENDARS_NAMESPACE, 'STORE_TOGGLED_CALENDARS');
export const CLEAR_TOGGLED_CALENDARS = createActionConst(CALENDARS_NAMESPACE, 'CLEAR_TOGGLED_CALENDARS');

export const SEARCH_BY_DESCRIPTION = createActionConst(CALENDARS_NAMESPACE, 'SEARCH_BY_DESCRIPTION');
export const STORE_DESCRIPTION_PARAM = createActionConst(CALENDARS_NAMESPACE, 'STORE_DESCRIPTION_PARAM');
export const CLEAR_DESCRIPTION_PARAM = createActionConst(CALENDARS_NAMESPACE, 'CLEAR_DESCRIPTION_PARAM');

export const REFRESH_CALENDAR = 'calendarRefresh';
