import React, { useMemo } from 'react';
import { FunctionComponent } from 'react';

import { FormCheckboxInput } from '../../../../inputs/CheckboxInput';
import { FormCSTextInput } from '../../../../inputs/CustomSizeTextInput';
import { SelectInput } from '../../../../inputs/SelectInput';
import { IBudgetFormProps } from './types';

export const BudgetForm: FunctionComponent<IBudgetFormProps> = ({ focusBudgetTuple, focusChecked, projectData }) => {
  const typeOptions = useMemo(
    () => [
      { value: 'no_budget', label: 'No Budget' },
      { value: 'total_project_fees', label: 'Total Project Fees' },
      { value: 'fees_per_task', label: 'Fees per Task' },
      { value: 'total_project_hours', label: 'Total Project Hours' },
      { value: 'hours_per_task', label: 'Hours per Task' },
      { value: 'hours_per_person', label: 'Hours per Person' },
    ],
    [],
  );
  const defaultTypeOptions = typeOptions.filter((type) => type.value === projectData?.budgetType);

  return (
    <>
      <div className="row budget_main" style={{ display: 'flex !important' }}>
        <div className="col-12">
          <label className="w100 flex mb-1">Budget</label>
          <label className="blueGray">
            <small>Set a budget to track project progress.</small>
          </label>
        </div>
        <div className="col-6 pr-1">
          <label className="w100 flex mb-1"></label>
          <SelectInput
            id="budgetType"
            name="budgetType"
            options={typeOptions}
            defaultOptions={defaultTypeOptions[0] ? defaultTypeOptions[0] : typeOptions[0]} //data.nesto
            isSingle
          />
        </div>
        <div className="col-6 pl-1">
          {focusBudgetTuple && focusBudgetTuple.value === 'total_project_fees' && (
            <div
              className="field-container mb-0 mx-0 flex-vertical max100 budget_amount"
              style={{ paddingTop: '12px' }}
            >
              <div className="flex aic jcc">
                ${' '}
                <FormCSTextInput
                  id="totalProjectFee"
                  name="totalProjectFee"
                  type="text"
                  size="big-input"
                  defaultValue={projectData?.totalProjectFee}
                />
              </div>
            </div>
          )}
          {focusBudgetTuple && focusBudgetTuple.value === 'total_project_hours' && (
            <div className="field-container mb-0 max100 budget_hours" style={{ paddingTop: '12px' }}>
              <div className="flex aic jcc">
                <FormCSTextInput
                  id="totalProjectHours"
                  name="totalProjectHours"
                  type="text"
                  size="big-input"
                  defaultValue={projectData?.totalProjectHours}
                />{' '}
                hours
              </div>
            </div>
          )}
          {focusBudgetTuple &&
            (focusBudgetTuple.value === 'fees_per_task' ||
              focusBudgetTuple.value === 'hours_per_task' ||
              focusBudgetTuple.value === 'hours_per_person') && (
              <div className="field-container mb-0 flex-vertical mx-0 budget_task_info" style={{ paddingTop: '20px' }}>
                <div className="lightBlue-bg p-05 radius-5 flex aic f-1">
                  <i className="icon-info mr-05 f-2 blue" /> You can set your rates in the Task section below
                </div>
              </div>
            )}
        </div>
        {focusBudgetTuple &&
          (focusBudgetTuple.value === 'total_project_fees' ||
            focusBudgetTuple.value === 'fees_per_task' ||
            focusBudgetTuple.value === 'total_project_hours' ||
            focusBudgetTuple.value === 'hours_per_task' ||
            focusBudgetTuple.value === 'hours_per_person') && (
            <div className="col-12 mb-2 mt-2 budget_reset" style={{ display: 'block !important' }}>
              <div className="remember-checkbox flex aic jcl">
                <FormCheckboxInput id="budgetReset" name="budgetReset" />
                <label htmlFor="budgetReset">Budget resets every month</label>
              </div>
            </div>
          )}
        {focusBudgetTuple && focusBudgetTuple.value === 'total_project_fees' && (
          <div className="col-12 mb-2 budget_project_expenses" style={{ display: 'none !important' }}>
            <div className="remember-checkbox flex aic jcl">
              <FormCheckboxInput id="budgetProjectExpenses" name="budgetProjectExpenses" />
              <label htmlFor="budgetProjectExpenses">Budget includes billable and non-billable project expenses</label>
            </div>
          </div>
        )}
        {focusBudgetTuple &&
          (focusBudgetTuple.value === 'total_project_fees' ||
            focusBudgetTuple.value === 'fees_per_task' ||
            focusBudgetTuple.value === 'total_project_hours' ||
            focusBudgetTuple.value === 'hours_per_task' ||
            focusBudgetTuple.value === 'hours_per_person') && (
            <div className="col-12 budget_email_exceeds" style={{ display: 'block !important' }}>
              <div className="remember-checkbox flex aic jcl">
                <FormCheckboxInput id="budgetEmailExceeds" name="budgetEmailExceeds" />
                <label htmlFor="budgetEmailExceeds" className="flex aic">
                  Send email alerts if project exceeds{' '}
                  <div className="max70 mx-1">
                    <FormCSTextInput
                      id="percentOfBudgetExceed"
                      name="percentOfBudgetExceed"
                      type="text"
                      size="smallest-input text-center percent_of_budget_exceed"
                      disabled={!focusChecked ? true : false}
                    />
                  </div>
                  % of budget{' '}
                </label>
              </div>
            </div>
          )}
      </div>
    </>
  );
};
