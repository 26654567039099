import { combineReducers, Reducer } from 'redux';

import * as types from './project-times.types';
import * as constants from './project-times.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IProjectTimesPageState = {
  projectTimesIds: [],
};

const projectTimesReducer: Reducer<
  types.IProjectTimesPageState['projectTimesIds'],
  types.IStoreProjectTime | types.IClearProjectTime
> = (state = initialState.projectTimesIds, action) => {
  if (isActionOfType<types.IStoreProjectTime>(action, constants.STORE_PROJECT_TIME)) {
    return action.payload;
  } else if (isActionOfType<types.IClearProjectTime>(action, constants.CLEAR_PROJECT_TIME)) {
    return initialState.projectTimesIds;
  }

  return state;
};

export const projectTimesPageReducer: Reducer<
  types.IProjectTimesPageState,
  types.IProjectTimesPageAction
> = combineReducers({
  projectTimesIds: projectTimesReducer,
});
