import React, { useMemo } from 'react';
import { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { copyTask, moveTask } from '../../../../../app/page/page-storage/boardLists/boardLists.actions';
import { boardSelectors } from '../../../../../app/resource/boards/board/board.selectors';
import { IBoard } from '../../../../../app/resource/boards/board/board.types';
import { boardListSelectors } from '../../../../../app/resource/boards/boardList/boardList.selectors';

import { IActionsForm } from '../../../../../app/resource/task/task.types';

import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { useSelect } from '../../../../../utils/selector.utils';

import { SelectInput } from '../../inputs/SelectInput';
//import { SmallSelectInput } from '../../inputs/SmallSelectInput';

import { IActionsTaskFormProps } from './types';

export const ActionsTaskForm: FunctionComponent<IActionsTaskFormProps> = ({ task, move, onClick }) => {
  const dispatch = useDispatch();

  const formProps = useForm();
  const focusBoardTupple = useMemo(() => formProps.watch('board'), [formProps]) as { value: string; label: string };

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: IActionsForm) => {
    onClick(true);
    const newData = { id: task.id, taskTypeId: data.taskType.value };

    move ? dispatch(moveTask(newData)) : dispatch(copyTask(newData));
  };
  const boards = useSelect(boardSelectors.selectResourceList);
  const boardLists = useSelect(boardListSelectors.selectResourceList);
  const boardListsMap = useMemo(() => new Map(boardLists.map((boardList) => [boardList.id, boardList])), [boardLists]);

  const boardsOptions = useMemo(
    () =>
      boards.map((board) => ({
        value: board.id,
        label: board.name,
      })),
    [boards],
  );
  const newBoardsOptions = useMemo(
    () => boardsOptions.filter((board) => board.value.length < 10),

    [boardsOptions],
  );

  const focusBoard: IBoard | null | undefined = focusBoardTupple
    ? boards.find((board) => focusBoardTupple.value === board.id)
    : null;

  const boardListOptions = useMemo(
    () =>
      focusBoard
        ? focusBoard.taskTypeIds.map((boardListId) => ({
            value: boardListId,
            label: boardListsMap.get(boardListId)?.name,
          }))
        : [],
    [boardListsMap, focusBoard],
  );

  return (
    <FormProvider {...formProps}>
      <form
        id="actions-task-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IActionsForm))}
        method="post"
      >
        <div className="field-container">
          <SelectInput id="board" name="board" validation={requiredValidation} options={newBoardsOptions} isSingle />
        </div>
        <div className="field-container">
          <SelectInput
            id="taskType"
            name="taskType"
            validation={requiredValidation}
            options={boardListOptions}
            isSingle
          />
        </div>

        <button type="submit" className="btn btn-md green-bg white d-block w100 jcc">
          {move ? 'Move' : 'Copy'}
        </button>
      </form>
    </FormProvider>
  );
};
