import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import * as constants from './boardLists.constants';
import * as types from './boardLists.types';
import * as services from './boardLists.saga-services';
import { handleMoveBoardTaskFromNotification } from '../tasks/tasks.saga-services';
import { IMoveBoardTaskSocket } from '../tasks/tasks.types';
import { DropResult } from 'react-beautiful-dnd';
import { clearApiInProgress, storeApiInProgress } from '../../../api/api.actions';

function* invokeCreateBoardList(action: types.ICreateBoardList) {
  yield call(services.createBoardList, action.payload);
}

function* invokeUpdateBoardList(action: types.IUpdateBoardList) {
  yield call(services.updateBoardList, action.payload.id, action.payload.data);
}
function* invokeDeleteBoardList(action: types.IDeleteBoardList) {
  yield call(services.deleteBoardList, action.payload);
}
function* invokeMoveBoardList(action: types.IMoveBoardList) {
  yield call(services.moveBoardList, action.payload);
}
function* invokeInitializeTaskProps(action: types.IInitializeTaskProps) {
  // zameniti ovo sa spinerom specijalno za dialog content - ovako mi je najbrze da odradim fix
  yield put(storeApiInProgress('updateTaskApi'));
  yield call(services.initializeTaskProps, action.payload);
  yield put(clearApiInProgress('updateTaskApi'));
}
function* invokeClearTaskProps(action: types.IClearTaskProps) {
  yield call(services.clearTaskProps);
}
function* invokeCreateTask(action: types.ICreateTask) {
  yield call(services.createBoardTask, action.payload);
}

function* invokeUpdateTask(action: types.IUpdateTask) {
  yield call(services.updateBoardTask, action.payload.data);
}
function* invokeMoveTask(action: types.ITaskMove) {
  yield call(services.moveTask, action.payload.data);
}
function* invokeCopyTask(action: types.ITaskCopy) {
  yield call(services.copyTask, action.payload.data);
}
function* invokeDeleteTask(action: types.IDeleteTask) {
  yield call(services.deleteBoardTask, action.payload);
}
function* invokeSaveBoardListSocket(action: types.ISaveBoardListSocket) {
  yield call(services.handleBoardListFromNotification, action.payload);
}

function* invokeDeleteBoardListSocket(action: types.IDeleteBoardListSocket) {
  yield call(services.handleBoardListDeleteFromNotification, action.payload);
}

// function* invokeSaveTaskSocket(action: types.ISaveTaskSocket) {
//   yield call(services.handlSaveTaskFromNotification, action.payload);
// }
function* invokeCopyTaskSocket(action: types.ICopyTaskSocket) {
  yield call(services.handleCopyTaskFromNotification, action.payload);
}
function* invokeMoveTaskSocket(action: types.IMoveTaskSocket | IMoveBoardTaskSocket) {
  if (!action.payload.dragAndDrop) {
    yield call(services.handleMoveTaskFromNotification, action.payload);
  } else {
    yield call(handleMoveBoardTaskFromNotification, action.payload.dragAndDrop as DropResult);
  }
}

export function* watchBoardListsPurePageSaga() {
  yield takeLatest(constants.CREATE_BOARD_LIST, invokeCreateBoardList);
  yield takeLatest(constants.UPDATE_BOARD_LIST, invokeUpdateBoardList);
  yield takeLatest(constants.DELETE_BOARD_LIST, invokeDeleteBoardList);
  yield takeLatest(constants.MOVE_BOARD_LIST, invokeMoveBoardList);
  yield takeLatest(constants.INITIALIZE_TASK_PROPS, invokeInitializeTaskProps);
  yield takeLatest(constants.CLEAR_TASK_PROPS, invokeClearTaskProps);
  yield takeLatest(constants.CREATE_TASK, invokeCreateTask);
  yield takeLatest(constants.UPDATE_TASK, invokeUpdateTask);
  yield takeLatest(constants.DELETE_TASK, invokeDeleteTask);
  yield takeLatest(constants.MOVE_TASK, invokeMoveTask);
  yield takeLatest(constants.COPY_TASK, invokeCopyTask);

  yield takeLatest(constants.SAVE_BOARD_LIST_SOCKET, invokeSaveBoardListSocket);
  yield takeLatest(constants.DELETE_BOARD_LIST_SOCKET, invokeDeleteBoardListSocket);
  // yield takeLatest(constants.SAVE_TASK_SOCKET, invokeSaveTaskSocket);
  yield takeLatest(constants.MOVE_TASK_SOCKET, invokeMoveTaskSocket);
  yield takeLatest(constants.COPY_TASK_SOCKET, invokeCopyTaskSocket);
}

export function* watchBoardListsPageStorageSaga() {
  yield all([watchBoardListsPurePageSaga].map(fork));
}
