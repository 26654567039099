/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { labelsPageSelectors } from '../../../../app/page/page-storage/labels/labels.selectors';

import { useSelect, useSelectWithParams } from '../../../../utils/selector.utils';
import { ConnectedDialog } from '../../ConnectedDialog';
import { Labels } from '../../../pages/BoardsPage/Board/BoardBody/BoardBodyTasks/Task/TaskWidgets/Labels';
import { CheckLists } from '../../../pages/BoardsPage/Board/BoardBody/BoardBodyTasks/Task/TaskWidgets/CheckLists';
import { checkListsPageSelectors } from '../../../../app/page/page-storage/checklists/checkLists.selectors';
import { CheckListMenu } from '../../../pages/BoardsPage/Board/BoardBody/BoardBodyTasks/Task/TaskWidgets/TaskMenus/CheckListMenu';
import { Activities } from '../../../pages/BoardsPage/Board/BoardBody/BoardBodyTasks/Task/TaskWidgets/Activities';
import { activitiesPageSelectors } from '../../../../app/page/page-storage/activities/activities.selectors';
import { AttachmentMenu } from '../../../pages/BoardsPage/Board/BoardBody/BoardBodyTasks/Task/TaskWidgets/TaskMenus/AttachmentMenu';
import { ITrelloReplacementFormDialogProps } from './types';
import { dialogSelectors } from '../../../../app/dialog/dialog.selectors';
import { clearTaskProps, initializeTaskProps } from '../../../../app/page/page-storage/boardLists/boardLists.actions';
import { TaskActions } from '../../../pages/BoardsPage/Board/BoardBody/BoardBodyTasks/Task/TaskWidgets/TaskActions';
import { Members } from '../../../pages/BoardsPage/Board/BoardBody/BoardBodyTasks/Task/TaskWidgets/Members';
import { Header } from '../../../pages/BoardsPage/Board/BoardBody/BoardBodyTasks/Task/TaskWidgets/Header';
import { DueDateMenu } from '../../../pages/BoardsPage/Board/BoardBody/BoardBodyTasks/Task/TaskWidgets/TaskMenus/DueDateMenu';
import { DueDate } from '../../../pages/BoardsPage/Board/BoardBody/BoardBodyTasks/Task/TaskWidgets/DueDate';
import { Description } from '../../../pages/BoardsPage/Board/BoardBody/BoardBodyTasks/Task/TaskWidgets/Description';
import { ListName } from '../../../pages/BoardsPage/Board/BoardBody/BoardBodyTasks/Task/TaskWidgets/ListName';
import { taskSelectors } from '../../../../app/resource/task/task.selectors';

export const TrelloReplacementFormDialog: FunctionComponent = () => {
  const props = useSelectWithParams(
    dialogSelectors.selectDialogProps,
    'trelloReplacementFormDialog',
  ) as ITrelloReplacementFormDialogProps;
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(clearTaskProps());
  }, [dispatch]);

  useEffect(() => {
    if (props) {
      dispatch(initializeTaskProps(props.taskId));
    }
  }, [dispatch, props]);

  const labelsIds = useSelect(labelsPageSelectors.selectLabels);
  const checkListsIds = useSelect(checkListsPageSelectors.selectCheckLists);
  const activitiesIds = useSelect(activitiesPageSelectors.selectActivities);

  const taskId = props ? props.taskId : '';
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId);
  const meetingId = task ? task.meetingIds[0] : '';

  return (
    <ConnectedDialog dialogKey="trelloReplacementFormDialog">
      <div className="popup big-popup trello_replacement" style={{ display: 'block' }}>
        <div className="panel">
          {props && <Header taskId={props.taskId} onClose={onClose} />}
          <div className="panel-body" style={{ maxHeight: '684.3px' }}>
            {props && <ListName taskId={props.taskId} />}
            <div className="row ais">
              <div className="col-9">
                <div className="row">
                  {props && <Members menu={false} taskId={props.taskId} meetingId={meetingId} />}
                  {props && <Labels labelsIds={labelsIds} menu={false} taskId={props.taskId} />}
                  {props && <DueDate taskId={props.taskId} />}
                </div>
                {props && <Description taskId={props.taskId} />}
                <CheckLists checkListsIds={checkListsIds} />
                {props && <Activities activitiesIds={activitiesIds} taskId={props.taskId} />}
              </div>
              <div className="col-3 sticky-side">
                <h5 className="bold">ADD TO CARD</h5>
                <div className="buttons">
                  {props && <Members menu={true} taskId={props.taskId} meetingId={meetingId} />}
                  {props && <Labels labelsIds={labelsIds} menu={true} taskId={props.taskId} />}
                  {props && <CheckListMenu taskId={props.taskId} />}
                  {props && <DueDateMenu taskId={props.taskId} />}
                  {props && <AttachmentMenu taskId={props.taskId} />}
                </div>
                {props && <TaskActions taskId={props.taskId} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
