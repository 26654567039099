/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback, useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { updateTask } from '../../../../../../../../app/page/page-storage/boardLists/boardLists.actions';
import { taskSelectors } from '../../../../../../../../app/resource/task/task.selectors';
import { ITask } from '../../../../../../../../app/resource/task/task.types';
import { useApiRequestState } from '../../../../../../../../utils/api.utils';
import { useSelectWithParams } from '../../../../../../../../utils/selector.utils';

import { IStatusProps } from './types';

export const Status: FunctionComponent<IStatusProps> = ({ taskId }) => {
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId) as ITask;
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(task.status === '1' ? false : true);
  useEffect(() => {
    task.status === '1' ? setDisabled(false) : setDisabled(true);
  }, [task.status]);
  const handleChange = useCallback(() => {
    //setDisabled(!disabled);

    const status = disabled ? '1' : '0';
    const updatedTask = {
      id: task.id,
      title: task.title,
      description: task.description,
      dueDate: task.dueDate,
      userId: task.userId,
      meetingIds: task.meetingIds,
      status: status,
      label: task.label ?? [],
      taskTypeId: task.taskTypeId,
      members: task.members,
      showInTasks: task.showInTasks,
      boardId: task.boardId,
    };

    dispatch(updateTask(updatedTask));
  }, [disabled, dispatch, task]);

  const [showLoader] = useApiRequestState('updateTaskApi');
  return (
    task && (
      <div
        className={`badge ${disabled ? 'white-bg' : 'lightGreen-bg'}`}
        style={{ minHeight: '25px' }}
        //onClick={(e) => e.stopPropagation()}
      >
        <div className="remember-checkbox flex aic jcl">
          <input type="checkbox" id="doneCheckboxx" name="doneCheckboxx" onChange={handleChange} checked={!disabled} />
          {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
          {!showLoader && (
            <label htmlFor="doneCheckboxx" style={{ color: `${disabled ? 'darkGray' : 'green'}` }}>
              Done
            </label>
          )}
        </div>
      </div>
    )
  );
};
