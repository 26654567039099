import { IBoard, IBoardCreate, IBoardUpdate, ISaveBoardOrder } from '../../resource/boards/board/board.types';
import { IApiRequestConfig } from '../api.types';

export const searchBoardsApi = (meetingId: string): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'getBoards',
  uri: 'json',
  action: 'getBoards',
  method: 'get',
  params: {
    meetingId,
  },
});

export const createBoardApi = (board: IBoardCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createBoard',
  uri: 'json',
  action: 'saveBoards',
  method: 'post',
  data: board,
});

export const updateBoardApi = (board: IBoardUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateBoard',
  uri: 'json',
  action: 'saveBoards',
  method: 'put',
  data: board,
});

export const deleteBoardApi = (boardId: IBoard['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteBoard',
  uri: 'json',
  action: 'deleteBoards',
  method: 'delete',
  params: {
    id: boardId,
  },
});

export const saveBoardOrderApi = (boardIds: ISaveBoardOrder): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'saveBoardOrderApi',
  uri: 'json',
  action: 'saveBoardOrder',
  method: 'post',
  data: boardIds,
});
