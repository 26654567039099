import React, { FunctionComponent, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IFileInputProps } from './types';

// import { AccountCircle } from '@material-ui/icons';
// import { Avatar } from '../../../Avatar';

export const FileInput: FunctionComponent<IFileInputProps> = ({
  id,
  name,
  defaultValue,
  validation,
  disabled,
  label,
  maxFileSize,
  acceptableFormats,
}) => {
  const [fileName, setFileName] = useState(defaultValue);

  const { control, setError, clearErrors } = useFormContext();

  const onImageInput = useCallback(
    (event, onChange) => {
      if (event && event.currentTarget && event.currentTarget.files && event.currentTarget.files[0]) {
        const fileBlob = event.currentTarget.files[0];
        const filesize = (fileBlob.size / 1024 / 1024).toFixed(4);
        if (+filesize <= maxFileSize) {
          setFileName(fileBlob.name);
          const reader = new FileReader();
          reader.readAsDataURL(fileBlob);
          reader.onloadend = () => {
            onChange(reader.result);
          };
          clearErrors(name);
        } else {
          setError(name, { type: 'maxLength', message: `File should be lower size then ${maxFileSize}MB` });
        }
      }
    },
    [clearErrors, maxFileSize, name, setError],
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={validation}
      render={(controllerProps) => (
        <>
          <input
            type="file"
            id="upload-button"
            name={name}
            style={{ display: 'none' }}
            onChange={(event) => onImageInput(event, controllerProps.onChange)}
          />
          <label htmlFor="upload-button">
            <div className="one-attach">Computer</div>
          </label>

          <div>{fileName}</div>
        </>
      )}
    />
  );
};
