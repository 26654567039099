/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../app/dialog/dialog.actions';
import { ITaskFormDialogProps } from '../types';

export const TaskFormTrigger: FunctionComponent<ITaskFormDialogProps> = ({ type, meetingId, userId }) => {
  const dispatch = useDispatch();

  const openTaskFormDialog = useCallback(() => {
    dispatch(
      openDialog('taskFormDialog', {
        type,
        meetingId,
        userId,
      }),
    );
  }, [type, dispatch, meetingId, userId]);

  return (
    <a className="btn-badge green-bg popup_button" onClick={openTaskFormDialog}>
      <i className="icon-plus"></i>
    </a>
  );

  // <a className="btn btn-md green-bg popup_button">
  //   <i className="icon-plus mr-1"></i> Add Task
  // </a>;
};
