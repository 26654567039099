/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { taskSelectors } from '../../../../../../../../../app/resource/task/task.selectors';
import { useSelectWithParams } from '../../../../../../../../../utils/selector.utils';
import { CopyTask } from './CopyTask';
import { DeleteTask } from './DeleteTask';
import { MoveTask } from './MoveTask';
import { ITaskActionsProps } from './types';

export const TaskActions: FunctionComponent<ITaskActionsProps> = ({ taskId }) => {
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId);

  return (
    <>
      <h5 className="bold mt-4">ACTIONS</h5>
      <div className="buttons">
        {task && <MoveTask task={task} />}
        {task && <CopyTask task={task} />}
        {/* <a className="btn w100 gray-bg darkGray px-1 f-1 ml-0 mb-1 mt-1 check_button">
          <i className="icon f-9 blueGray icon-eye mr-1" /> Watch
        </a>
        <a className="btn w100 gray-bg darkGray px-1 f-1 ml-0 mb-1 mt-1 check_button">
          <img src={require('../../../../../../../../resources/images/labels-icon.png')} className="mr-1" alt="img" />{' '}
          Archive
        </a> */}
        {task && <DeleteTask task={task} />}
      </div>
    </>
  );
};
