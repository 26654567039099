import { IResource, IResourceAction, IResourceSelectors, IResourceState } from '../generators/resource.types';
import { IMeeting } from '../meeting/meeting.types';

export const BACKBURNUER = '-1';
export const KPI = '2';
export const MEETING_ROCK = '3';
export const HEADLINE = '4';
export const TODO = '5';
export const ISSUE = '6';
export const CONCLUSION = '7';

export const AGENDA_ITEM_TYPES = [BACKBURNUER, MEETING_ROCK, HEADLINE, TODO, ISSUE];

export interface IAgendaItem extends IResource {
  title: string;
  description: string;
  keyName: string;
  functionName: string;
  color: string;
  isCustom: string;
  sort: string;
  deleted: string;
  duration: string;
  checked: string;

  agendaId: string; // IAgendaType['id'];

  boardId?: string;
  boardListId?: string;
  embedUrl?: string;
}

export interface IAgenda extends IResource {
  title: string;
  description: string;
  keyName: string;
  functionName: string;
  color: string;
  isCustom: string;
  sort: string;

  duration: string;
  checked: string;

  agendaId: string;
}

export interface IAgendaCreate {
  title: string;
  description: string;
  keyName: string;
  functionName: string;
  color: string;
  isCustom: string;
  sort: string;
  deleted: string;
  duration: string;
  checked: string;

  agendaId: string;
}
export interface IAgendaUpdate {
  id?: IAgenda['id'];
  title: string;
  description: string;
  keyName: string;
  functionName: string;
  color: string;
  isCustom: string;
  sort: string;
  deleted: string;
  duration: string;
  checked: string;

  agendaId: string;
}

export interface IAgendaItemSearchParams {
  agendaId?: IAgendaItem['id'];
  id?: IMeeting['id'];
}

export type IAgendaItemAction = IResourceAction<IAgendaItem>;
export type IAgendaItemState = IResourceState<IAgendaItem>;
export type IAgendaItemSelectors = IResourceSelectors<IAgendaItem>;
