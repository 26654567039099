/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { moveToNextAgenda } from '../../../app/page/page-storage/agenda-items/agenda-items.actions';
import { agendaItemSelectors } from '../../../app/resource/agenda-item/agenda-item.selectors';
import { IAgenda } from '../../../app/resource/agenda/agenda.types';
import { meetingSelectors } from '../../../app/resource/meeting/meeting.selectors';
import { IMeeting } from '../../../app/resource/meeting/meeting.types';
import { ISession } from '../../../app/resource/session/session.types';

import { useSelectWithParams } from '../../../utils/selector.utils';

import { ConnectedDialog } from '../ConnectedDialog';

import { IAIPreviewDialogProps } from './types';

export const AIPreviewDialog: FunctionComponent = () => {
  const dispatch = useDispatch();
  const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'aiPreviewDialog') as IAIPreviewDialogProps;

  const onClose = useCallback(() => {
    dispatch(closeDialog('aiPreviewDialog'));
  }, [dispatch]);

  const data = useSelectWithParams(meetingSelectors.selectResourceById, props?.meetingId) as IMeeting;

  const agendaItemsSelected = useSelectWithParams(
    agendaItemSelectors.selectResourcesById,
    data ? data.agendaItemIds : [],
  ) as IAgenda[];

  const agendaItems = agendaItemsSelected.filter((item) => item.checked === '1');

  const history = useHistory();
  const goToAgendaItem = useCallback(
    (index: number) => {
      const [, path, mId, sesId] = history.location.pathname.split('/');
      const meetingId = mId as IMeeting['id'];
      const sessionId = sesId as ISession['id'];
      dispatch(moveToNextAgenda({ meetingId, sessionId, index: `${index}` }));
      history.push(`/${path}/${mId}/${sesId}/${index}`);
    },
    [dispatch, history],
  );

  return (
    <ConnectedDialog dialogKey="aiPreviewDialog">
      <div className="popup agenda_popup" style={{ display: 'block' }}>
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            <h3>Agenda</h3>
            <span className="close-popup close" onClick={onClose}>
              <i className="icon-close" />
            </span>
          </div>
          <div className="panel-body p-2">
            {agendaItems.map(
              (ai, index) =>
                ai && (
                  <a
                    className="panel flex aic px-1 mb-1 close"
                    style={{ margin: 'auto', userSelect: 'none', cursor: 'pointer' }}
                    title=""
                    onClick={() => {
                      goToAgendaItem(index);
                      dispatch(closeDialog('aiPreviewDialog'));
                    }}
                  >
                    {ai.title} <span className="ml-auto blueGray">{ai.duration} min</span>
                  </a>
                ),
            )}
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
