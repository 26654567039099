import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './passwords.constants';
import * as types from './passwords.types';
import * as services from './passwords.saga-services';

function* invokeSetupPasswords(action: types.ISearchPasswords) {
  yield call(services.setupPasswords, action.payload);
}

function* invokeSavePassword(action: types.ISavePassword) {
  yield call(services.savePassword, action.payload);
}

function* invokeDeletePassword(action: types.IDeletePassword) {
  yield call(services.deletePassword, action.payload);
}

function* invokeFilterPassword(action: types.IFilterPasswords) {
  yield call(services.storeFilterPasswords, action.payload);
}

export function* watchPasswordsPageSaga() {
  yield takeLatest(constants.SEARCH_PASSWORDS, invokeSetupPasswords);
  yield takeLatest(constants.SAVE_PASSWORD, invokeSavePassword);
  yield takeLatest(constants.FILTER_PASSWORDS, invokeFilterPassword);
  yield takeLatest(constants.DELETE_PASSWORD, invokeDeletePassword);
}
