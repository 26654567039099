import React, { FunctionComponent } from 'react';

import { CalendarFilter } from './CalendarFilter';
import SearchIcon from '../../../../../resources/images/search-icon.svg';
import Arrow from '../../../../../resources/images/calendar-arrow.svg';
import { ICalendarOptionsProps } from './types';

export const CalendarOptions: FunctionComponent<ICalendarOptionsProps> = ({
  viewInterval,
  decMonth,
  incMonth,
  filterByDescription,
  description,
  userIds,
  setUserFilter,
  setViewType,
}) => {
  return (
    <div className="calendar-options">
      <div className="title-arrows">
        <h3 className="side-title">
          <span className="calendar-month">{viewInterval}</span>
        </h3>
        <div className="calendar-arrows">
          <span className="left-arrow" onClick={decMonth}>
            <img src={Arrow} alt="arrow-left" className="img-fluid" />
          </span>
          <span className="right-arrow" onClick={incMonth}>
            <img src={Arrow} alt="arrow-right" className="img-fluid" />
          </span>
        </div>
      </div>
      <form className="form-search-calendar">
        <input
          type="text"
          className="small-input"
          placeholder="Search for event…"
          onChange={filterByDescription}
          value={description}
        />
        <button type="submit" className="small-submit">
          <img src={SearchIcon} alt="search" className="img-fluid" />
        </button>
      </form>
      <h3 className="side-title mt-3">Default View</h3>
      <div className="calendar-views">
        <div className="border-checkbox ml-1">
          <input type="radio" name="cal_view" id="day" onClick={() => setViewType('day')} />
          <label htmlFor="day">Day</label>
        </div>
        <div className="border-checkbox ml-1">
          <input type="radio" name="cal_view" id="week" onClick={() => setViewType('week')} />
          <label htmlFor="week">Week</label>
        </div>
        <div className="border-checkbox ml-1">
          <input type="radio" name="cal_view" id="month" onClick={() => setViewType('month')} defaultChecked />
          <label htmlFor="month">Month</label>
        </div>
      </div>
      <h3 className="side-title mt-3">Members</h3>
      <div className="calendar-views">
        <CalendarFilter userIds={userIds} setUserFilter={setUserFilter} />
      </div>
    </div>
  );
};
