import React, { FunctionComponent, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { get } from 'lodash';
import { ISelectProps } from './types';

import Select, { OptionsType, OptionTypeBase, StylesConfig } from 'react-select';

export const SelectInput: FunctionComponent<ISelectProps> = ({
  id,
  name,
  defaultOptions,
  validation,
  disabled,
  placeholder,
  rightIcon,
  leftIcon,
  label,
  options,
  onInputChange,
  isSingle,
  className,
}) => {
  const { control, errors, setError } = useFormContext();

  const customStyles: StylesConfig<OptionsType<OptionTypeBase>, true> = {
    control: (customStyles) => ({
      ...customStyles,
      boxShadow: '0 !important',
      borderColor: '#edf2f5',
      '&:hover': {
        borderColor: '#edf2f5',
        backgroundColor: '#edf2f5',
      },
    }),
    placeholder: (customStyles) => ({
      ...customStyles,
      color: '#D8D8D8',
      fontWeight: 'bold',
      letterSpacing: '0.4px',
    }),
    indicatorSeparator: (customStyles) => ({
      ...customStyles,
      borderColor: '#edf2f5',
    }),
  };

  useEffect(() => {
    if (validation && !errors[name] && control.getValues()[name] && control.getValues()[name].length < 1) {
      console.log(name);
      if (name === 'status') {
        setError(name, { type: 'minLength', message: 'You need to select a status to save' });
      } else {
        setError(name, { type: 'minLength', message: 'You need to add at least one entity' });
      }
    }
  }, [control, errors, name, setError, validation]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultOptions || ''}
      rules={validation}
      disabled={disabled}
      render={(controllerProps) => (
        <>
          {!!get(errors, name, false) && (
            <label htmlFor={name} style={{ color: 'red' }}>
              {`${label ? label + ' - ' : ''} ${get(errors, `${name}.message`)}`}
            </label>
          )}
          {!get(errors, name, false) && <label htmlFor={name}>{label}</label>}
          {leftIcon}
          {rightIcon}
          <Select
            defaultValue={defaultOptions || ''}
            id={id}
            className={`custom-select-new ${className}`}
            name={controllerProps.name}
            disabled={disabled}
            onInputChange={onInputChange}
            onChange={controllerProps.onChange}
            options={options}
            value={controllerProps.value}
            placeholder={placeholder}
            isMulti={isSingle ? undefined : true}
            closeMenuOnSelect={isSingle}
            styles={customStyles}
          />
        </>
      )}
    />
  );
};

// eslint-disable-next-line no-lone-blocks
{
  /*
<input
  id={id}
  name={controllerProps.name}
  placeholder={placeholder}
  disabled={disabled}
  onChange={controllerProps.onChange}
  value={controllerProps.value}
/>
*/
}
