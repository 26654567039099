import { combineReducers, Reducer } from 'redux';

import * as types from './password-groups.types';
import * as constants from './password-groups.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IPasswordGroupsPageState = {
  passwordGroupIds: [],
};

const passwordGroupIdsReducer: Reducer<
  types.IPasswordGroupsPageState['passwordGroupIds'],
  types.IStorePasswordGroups | types.IClearPasswordGroups
> = (state = initialState.passwordGroupIds, action) => {
  if (isActionOfType<types.IStorePasswordGroups>(action, constants.STORE_PASSWORD_GROUPS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearPasswordGroups>(action, constants.CLEAR_PASSWORD_GROUPS)) {
    return initialState.passwordGroupIds;
  }
  return state;
};

export const passwordGroupsPageReducer: Reducer<
  types.IPasswordGroupsPageState,
  types.IPasswordGroupsPageAction
> = combineReducers({
  passwordGroupIds: passwordGroupIdsReducer,
});
