import { IApiRequestConfig } from '../api.types';
import { IUser, IUserCreate, IUserSearchParams, IUserSignUpForm, IUserUpdate } from '../../resource/user/user.types';

export const searchUserApi = (params?: IUserSearchParams): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'searchUsers',
  uri: 'json',
  action: 'getUsers',
  method: 'get',
  params: {
    ...params,
  },
});

export const createUserApi = (user: IUserCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createUser',
  uri: 'json',
  action: 'saveUser',
  method: 'post',
  data: user,
});

export const updateUserApi = (user: IUserUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateUser',
  uri: 'json',
  action: 'saveUser',
  method: 'post',
  data: user,
});

export const deleteUserApi = (userId: IUser['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteUser',
  uri: 'json',
  action: 'deleteUser',
  method: 'delete',
  params: {
    id: userId,
  },
});

export function signUpUserApi(data: IUserSignUpForm): IApiRequestConfig<unknown, unknown> {
  throw new Error('Function not implemented.');
}
