/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import Loader from 'react-loader-spinner';
// import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import slugify from 'react-slugify';
// import { openDialog } from '../../../../../app/dialog/dialog.actions';
import { meetingSelectors } from '../../../../../app/resource/meeting/meeting.selectors';
import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { Notifications } from '../NavigationBar/Notifications';
import { Settings } from '../NavigationBar/Settings';
import { IMeetingDashboardNavbarProps } from './types';

export const MeetingDashboardNavbar: FunctionComponent<IMeetingDashboardNavbarProps> = ({ meetingId, showLoader }) => {
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);
  const initials = useMemo(() => meeting?.title[0], [meeting]);
  const teamColor = useMemo(() => meeting?.color, [meeting]);
  const history = useHistory();
  // const dispatch = useDispatch();

  // const openAgendaItemsDialog = useCallback(() => {
  //   dispatch(openDialog('agendaItemsDialog'));
  // }, [dispatch]);

  const redirectToPasswords = useCallback(() => {
    history.push(`/passwords/${meetingId}`);
  }, [history, meetingId]);

  const title = meeting ? meeting.title : '';
  const redirectToMeeting = useCallback(() => {
    history.push(`/meeting-dashboard/${meetingId}/${slugify(title, { delimiter: '-' })}`);
  }, [history, meetingId, title]);

  return (
    meeting && (
      <div className="row mb-2">
        <div className="col-12">
          <div className="panel single-line flex aic jcc p-0">
            {showLoader ? (
              <Loader type="TailSpin" color="#1bc3a7" height={40} width={100} />
            ) : (
              <a
                onClick={redirectToMeeting}
                className="big-avatar flex aic jcc f-3 my-1 mx-2 white bold"
                style={{ backgroundColor: teamColor, cursor: 'pointer' }}
              >
                {initials}
              </a>
            )}

            <h2 className="bold" style={{ marginLeft: '5px' }}>
              {meeting.title}
            </h2>
            <ul className="right-menu" style={{ height: 80 }}>
              {/* <li className="flex aic">
                <a href="" className="btn btn-md darkGray-bg ml-1">
                  Reports
                </a>
              </li>
              <li className="flex aic">
                <a href="" className="btn btn-md darkGray-bg ml-1">
                  Company Vision
                </a>
              </li> */}
              <li className="flex aic">
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={redirectToPasswords}
                  className="btn big-avatar-50 flex aic jcc popup_button ml-2"
                  data-popup="new_password_popup"
                >
                  <i className="icon2-lock" style={{ fontSize: '20px !important' }} />
                </a>
              </li>
              {/* <li>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={openAgendaItemsDialog}
                  className="panel-button no-border popup_button"
                >
                  <i className="icon-dots" />
                </a>
              </li> */}
              <Notifications />
              <Settings />
            </ul>
          </div>
        </div>
      </div>
    )
  );
};
