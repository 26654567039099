import { all, call, put } from 'redux-saga/effects';

import * as types from './meeting-info.types';
import * as actions from './meeting-info.actions';

import { select } from '../../../utils/selector.utils';
import { meetingInfoSelectors } from './meeting-info.selectors';

import { apiRequest } from '../../api/api.saga-services';

import * as meetingInfoApi from '../../api/request-config/meeting-info.api';

export function* storeMeetingInfo(meetingInfo: types.IMeetingInfo) {
  yield put(actions.storeMeetingInfo(meetingInfo));
  return meetingInfo;
}

export function* clearAllMeetingsInfo() {
  const meetingInfoIds = yield select(meetingInfoSelectors.selectResourceIds);
  yield put(actions.clearMeetingInfo(meetingInfoIds));
}

/* This method sends api request and handle response */
export function* searchMeetingsInfo() {
  const meetingsInfo = (yield call(apiRequest, meetingInfoApi.searchMeetingInfoApi())) as types.IMeetingInfo[];
  if (!meetingsInfo) {
    return;
  }

  yield all(meetingsInfo.map((meetingInfo) => call(storeMeetingInfo, meetingInfo)));
  return meetingsInfo;
}
