/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';

import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';

import { useSelectWithParams } from '../../../utils/selector.utils';
import { ActionItemForm } from '../../pages/common/forms/ActionItemForm';
import { SubmitButton } from '../../pages/common/forms/SubmitButton';

import { ConnectedDialog } from '../ConnectedDialog';

import { IActionItemDialogProps } from './types';

export const ActionItemDialog: FunctionComponent = () => {
  const dispatch = useDispatch();
  const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'actionItemDialog') as IActionItemDialogProps;

  const onClose = useCallback(() => {
    dispatch(closeDialog('actionItemDialog'));
  }, [dispatch]);

  return (
    <ConnectedDialog dialogKey="actionItemDialog">
      <div className="popup new_trello_card_popup" style={{ display: 'block' }}>
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            <h3>Add Action Item</h3>
            <span className="close-popup close" onClick={onClose}>
              <i className="icon-close" />
            </span>
          </div>
          <div className="panel-body">{props && <ActionItemForm meetingId={props.meetingId} />}</div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" onClick={onClose}>
              Close
            </a>
            <SubmitButton apiKey={'createTaskApi'} formName={'action-item-form'} text="Save" />
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
