/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import { notificationsPageSelectors } from '../../../../../../app/page/page-storage/notifications/notifications.selectors';
import { useSelect } from '../../../../../../utils/selector.utils';
import { useToggler } from '../../../../../../utils/toggler.utils';
import { Notification } from './Notification';

export const Notifications: FunctionComponent = () => {
  const [isToggledPanel, handleTogglePanel] = useToggler();
  const notificationIds = useSelect(notificationsPageSelectors.selectFetchedNotifications);

  const unseenCount = useSelect(notificationsPageSelectors.selectUnseenCount);

  return (
    <li className={`dropdown ${!isToggledPanel ? 'opened' : ''}`}>
      <a style={{ cursor: 'pointer' }} className="panel-button dropdown-button" onClick={handleTogglePanel}>
        <i className="icon-bell"></i> {unseenCount && <span className="badge">{unseenCount}</span>}
      </a>
      <ul className="dropdown-menu notification-menu drop-right" onMouseLeave={handleTogglePanel}>
        {notificationIds.map((notificationId) => (
          <li key={notificationId}>
            <Notification notificationId={notificationId} />
          </li>
        ))}

        <li className="view-all">
          <a>View All Notifications</a>
        </li>
      </ul>
    </li>
  );
};
