/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { deleteUser } from '../../../app/page/page-storage/users/users.actions';
import { useApiRequestState } from '../../../utils/api.utils';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { SubmitButton } from '../../pages/common/forms/SubmitButton';
import { UserForm } from '../../pages/UserPage/UserForm';

import { ConnectedDialog } from '../ConnectedDialog';
import { IUserFormDialogProps } from './types';

export const UserFormDialog: FunctionComponent = () => {
  const dispatch = useDispatch();
  const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'userFormDialog') as IUserFormDialogProps;

  const [createShowLoader, createError] = useApiRequestState('createUser');
  const [updateShowLoader, updateError] = useApiRequestState('updateUser');

  const showLoader = useMemo(() => createShowLoader || updateShowLoader, [createShowLoader, updateShowLoader]);
  const error = useMemo(() => (createError || updateError) && !(createShowLoader || updateShowLoader), [
    createError,
    createShowLoader,
    updateError,
    updateShowLoader,
  ]);

  const onClose = useCallback(() => {
    dispatch(closeDialog('userFormDialog'));
  }, [dispatch]);

  const userId = props ? props.data?.id : undefined;

  const onDeleteUser = useCallback(() => {
    if (userId) {
      dispatch(deleteUser(userId));
    }
  }, [dispatch, userId]);
  return (
    <ConnectedDialog dialogKey="userFormDialog">
      <div className="popup new_team_popup mid-popup isVisible">
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            <h3>{props?.data ? 'Edit contact' : 'Add contact'} </h3>
            <span className="close-popup close">
              {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
              {error && !showLoader && <p style={{ color: 'red' }}> Something went wrong </p>}
            </span>
          </div>
          <div className="panel-body pt-0" style={{ maxHeight: '500px' }}>
            <UserForm {...props} />
          </div>

          <div className="panel-footer no-border flex jcr">
            {userId && (
              <button className="btn btn-sm red-bg" style={{ marginRight: 'auto' }} onClick={onDeleteUser}>
                Delete
              </button>
            )}
            <a className="link blueGray mr-2 close" title="" onClick={onClose}>
              Close
            </a>

            <SubmitButton formName="user-form" text="Save" />
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
