import { all, call } from 'redux-saga/effects';
import { clearAllMeetings } from './meeting/meeting.saga-service';
import { clearAllUsers } from './user/user.saga-service';
import { clearAllAgendaItems } from './agenda-item/agenda-item.saga-service';
import { clearAllTasks } from './task/task.saga-service';
import { clearAllSesions } from './session/session.saga-service';
import { clearAllKpis } from './kpi/kpi.saga-service';
import { clearAllKpiRows } from './kpi-row/kpi-row.saga-service';
import { clearAllKpiRowScoreboards } from './kpi-row-scoreboard/kpi-row-scoreboard.saga-service';
import { clearAllNotifications } from './notification/notification.saga-service';
import { clearAllScores } from './score/score.saga-service';
import { clearAllCalendars } from './calendar/calendar.saga-service';
import { clearAllCalendarItems } from './calendar-item/calendar-item.saga-service';
import { clearAllEvents } from './event/event.saga-service';
import { clearAllSections } from './section/section.saga-service';
import { clearAllTemplates } from './template/template.saga-service';
import { clearAllConclusions } from './conclusion/conclusion.saga-service';
import { clearAllNodes } from './node/node.saga-service';
import { clearAllBoards } from './boards/board/board.saga-service';
import { clearAllBoardLists } from './boards/boardList/boardList.saga-service';
import { clearAllCheckLists } from './checklist/checkList.saga-service';
import { clearAllChecklistItems } from './checklistItem/checklistItem.saga-service';
import { clearAllActivities } from './activity/activity.saga-service';
import { clearAllLabels } from './label/label.saga-service';
import { clearAllUserGroups } from './user-group/user-group.saga-service';
import { clearAllPasswords } from './password/password.saga-service';
import { clearAllPasswordGroups } from './password-group/password-group.saga-service';
import { clearAllProjectTasks } from './project-task/project-task.saga-service';
import { clearAllProjectMembers } from './project-member/project-member.saga-service';
import { clearAllProjects } from './project/project.saga-service';
import { clearAllProjectReports } from './project-report/project-report.saga-service';
import { clearAllAccountProjects } from './account-project/account-project.saga-service';
import { clearAllProjectTimes } from './project-time/project-time.saga-service';
import { clearAllCategories } from './category/category.saga-service';
import { clearAllItems } from './item/item.saga-service';
import { clearAllColumns } from './column/column.saga-service';
import { clearAllMeetingsInfo } from './meeting-info/meeting-info.saga-service';

export function* uninitResources() {
  const clearEntities = [
    clearAllMeetings,
    clearAllUsers,
    clearAllTasks,
    clearAllSesions,
    clearAllKpis,
    clearAllKpiRows,
    clearAllKpiRowScoreboards,
    clearAllAgendaItems,
    clearAllConclusions,
    clearAllNotifications,
    clearAllTemplates,
    clearAllCalendars,
    clearAllCalendarItems,
    clearAllEvents,
    clearAllScores,
    clearAllSections,
    clearAllNodes,
    clearAllBoards,
    clearAllBoardLists,
    clearAllCheckLists,
    clearAllChecklistItems,
    clearAllActivities,
    clearAllLabels,
    clearAllUserGroups,
    clearAllPasswords,
    clearAllPasswordGroups,
    clearAllProjectTasks,
    clearAllProjectMembers,
    clearAllProjects,
    clearAllProjectReports,
    clearAllAccountProjects,
    clearAllProjectTimes,
    clearAllCategories,
    clearAllItems,
    clearAllColumns,
    clearAllMeetingsInfo,
  ];
  yield all(clearEntities.map((clear) => call(clear)));
}
