import { call } from 'redux-saga/effects';

import * as pageStateService from '../page-state/page-state.saga-services';

import * as pageInitializerService from '../page.initializer.saga-service';
import * as quartalsService from '../page-storage/quartals/quartals.saga-services';

export function* initialQuartalsPage() {
  yield call(pageStateService.initPage, 'quartals');
  if (!((yield call(pageInitializerService.initPage)) as boolean)) {
    yield call(quartalsService.setupQuartals);
  }

  yield call(pageStateService.completedInit, 'quartals');
}
