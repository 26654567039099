import * as types from './checkLists.types';
import * as constants from './checkLists.constants';
import { ICheckList, ICheckListCreate, ICheckListUpdateForm } from '../../../resource/checklist/checkList.types';
import { IChecklistItem, IChecklistItemCreateForm } from '../../../resource/checklistItem/checklistItem.types';

export const createCheckList = (checkList: ICheckListCreate): types.ICreateCheckList => ({
  type: constants.CREATE_CHECKLIST,
  payload: checkList,
});

export const updateCheckList = (
  checkListId: ICheckList['id'],
  checkList: ICheckListUpdateForm,
): types.IUpdateCheckList => ({
  type: constants.UPDATE_CHECKLIST,
  payload: {
    id: checkListId,
    data: checkList,
  },
});

export const deleteCheckList = (checkListId: ICheckList['id']): types.IDeleteCheckList => ({
  type: constants.DELETE_CHECKLIST,
  payload: checkListId,
});

export const storeCheckLists = (checkLists: ICheckList['id'][]): types.IStoreCheckList => ({
  type: constants.STORE_CHECKLIST,
  payload: checkLists,
});

export const createChecklistItem = (checklistItem: IChecklistItemCreateForm): types.ICreateChecklistItem => ({
  type: constants.CREATE_CHECKLIST_ITEM,
  payload: checklistItem,
});

export const updateChecklistItem = (
  checklistItemId: IChecklistItem['id'],
  checklistItem: IChecklistItemCreateForm,
): types.IUpdateChecklistItem => ({
  type: constants.UPDATE_CHECKLIST_ITEM,
  payload: {
    id: checklistItemId,
    data: checklistItem,
  },
});

export const deleteChecklistItem = (checklistItemId: IChecklistItem['id']): types.IDeleteChecklistItem => ({
  type: constants.DELETE_CHECKLIST_ITEM,
  payload: checklistItemId,
});

export const storeChecklistItem = (checklistItems: IChecklistItem['id'][]): types.IStoreCheckList => ({
  type: constants.STORE_CHECKLIST_ITEM,
  payload: checklistItems,
});
export const clearChecklistItem = (): types.IClearChecklistItem => ({
  type: constants.CLEAR_CHECKLIST_ITEM,
});
export const clearCheckList = (): types.IClearCheckList => ({
  type: constants.CLEAR_CHECKLIST,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
