import * as types from './answers.types';
import * as constants from './answers.constants';
import { IAnswerUpdate } from '../../../resource/answer/answer.types';

// export const setupAnswer = (AnswerId: IAnswer['id'], accountId: string): types.ISetupAnswer => ({
//   type: constants.SETUP_Answer,
//   payload: {
//     accountId: accountId,
//     AnswerId: AnswerId,
//   },
// });

// export const createAnswer = (Answer: IAnswerCreateForm): types.ICreateAnswer => ({
//   type: constants.CREATE_Answer,
//   payload: Answer,
// });

export const updateAnswer = (answer: IAnswerUpdate): types.IUpdateAnswer => ({
  type: constants.UPDATE_ANSWER,
  payload: {
    data: answer,
  },
});

// export const deleteAnswer = (AnswerId: IAnswer['id'], accountId: IAccountAnswer['id']): types.IDeleteAnswer => ({
//   type: constants.DELETE_Answer,
//   payload: { AnswerId, accountId },
// });

// export const storeAnswers = (Answers: IAnswer['id'][]): types.IStoreAnswer => ({
//   type: constants.STORE_Answer,
//   payload: Answers,
// });

// export const clearAnswer = (): types.IClearAnswer => ({
//   type: constants.CLEAR_Answer,
// });

// export const storeIsFetched = (): types.IStoreIsFetched => ({
//   type: constants.STORE_IS_FETCHED,
// });

// export const clearIsFetched = (): types.IClearIsFetched => ({
//   type: constants.CLEAR_IS_FETCHED,
// });
