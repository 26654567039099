import { call, put, select } from 'redux-saga/effects';

import * as actions from './activities.actions';
import * as activityResourceService from './../../../resource/activity/activity.saga-service';

import { activitiesPageSelectors } from './activities.selectors';

import {
  IActivity,
  IActivityCreate,
  IActivityCreateForm,
  IActivityFromNotification,
  ISaveFile,
} from '../../../resource/activity/activity.types';
import { ITask } from '../../../resource/task/task.types';

export function* clearActivities() {
  yield put(actions.clearActivity());
}

export function* setupActivities(taskId: ITask['id']) {
  const activities = (yield call(activityResourceService.searchActivities, taskId)) as IActivity['id'][];

  yield put(activities ? actions.storeActivities(activities) : actions.clearActivity());
}
export function* createActivity(activityInfo: IActivityCreateForm) {
  const activity = (yield call(
    activityResourceService.createActivity,
    mapActivityFormToActivityCreate(activityInfo),
  )) as IActivity;

  if (!activity) {
    return;
  }
  const activitiesIds = yield select(activitiesPageSelectors.selectActivities);

  yield put(activitiesIds ? actions.storeActivities([...activitiesIds, activity.id]) : actions.clearActivity());
}

export function* updateActivity(activityId: IActivity['id'], activityInfo: IActivityCreateForm) {
  const activity = (yield call(activityResourceService.updateActivity, {
    id: activityId,
    ...mapActivityFormToActivityCreate(activityInfo),
  })) as IActivity;

  if (!activity) {
    return;
  }
  const activitiesIds = yield select(activitiesPageSelectors.selectActivities);

  yield put(activitiesIds ? actions.storeActivities([...activitiesIds]) : actions.clearActivity());
}

export function* deleteActivity(activityId: IActivity['id']) {
  yield call(activityResourceService.deleteActivity, activityId);

  const activitiesIds: IActivity['id'][] = yield select(activitiesPageSelectors.selectActivities);

  yield put(
    activitiesIds ? actions.storeActivities(activitiesIds.filter((id) => activityId !== id)) : actions.clearActivity(),
  );
}

export const mapActivityFormToActivityCreate = (activityInfo: IActivityCreateForm): IActivityCreate => ({
  description: activityInfo.description,
  taskId: activityInfo.taskId,
});
export function* clearActivitiesPageStorage() {
  yield put(actions.clearActivity());
}

export function* saveFile(activityInfo: ISaveFile) {
  const activity = (yield call(activityResourceService.saveFile, activityInfo)) as IActivity;

  if (!activity) {
    return;
  }
  const activitiesIds = yield select(activitiesPageSelectors.selectActivities);

  yield put(activitiesIds ? actions.storeActivities([...activitiesIds, activity.id]) : actions.clearActivity());
}

export function* handleActivityFromNotification(activityResponse: IActivityFromNotification) {
  const activitiesIds = yield select(activitiesPageSelectors.selectActivities);
  yield call(activityResourceService.storeActivity, { ...activityResponse });
  yield put(actions.storeActivities([...activitiesIds, activityResponse.id]));
}
