import React from 'react';
import { FunctionComponent } from 'react';
import { IColorProps } from './types';

export const Color: FunctionComponent<IColorProps> = ({ color, controllerProps, onClick }) => {
  return (
    <span
      key={`colorId-${color.value}`}
      className={color.state ? 'small-label selected' : 'small-label'}
      style={{ backgroundColor: color.value }}
      onClick={() => {
        onClick(color.value, controllerProps);
      }}
    ></span>
  );
};
