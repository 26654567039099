/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../app/dialog/dialog.actions';
import { updateMeeting } from '../../../../app/page/page-storage/meetings/meetings.actions';
import { agendaItemSelectors } from '../../../../app/resource/agenda-item/agenda-item.selectors';
import { meetingSelectors } from '../../../../app/resource/meeting/meeting.selectors';
import { IMeetingCreateForm, IMeetingNewCreateForm } from '../../../../app/resource/meeting/meeting.types';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { ParticipantList } from './ParticipantList';
import { IMeetingParticipantsProps } from './types';

export const MeetingParticipants: FunctionComponent<IMeetingParticipantsProps> = ({ meetingId }) => {
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);

  const dispatch = useDispatch();
  const agendaItems = useSelectWithParams(
    agendaItemSelectors.selectResourcesById,
    meeting ? meeting.agendaItemIds : [],
  );
  const openMeetingForm = useCallback(() => {
    if (meeting) {
      dispatch(
        openDialog('meetingFormDialog', {
          type: meeting.type,
          data: meeting,
          onSubmit: (meetingInfo: IMeetingCreateForm) => {
            // hot fix
            dispatch(
              updateMeeting(meeting.id, {
                ...meetingInfo,
                type: meeting.type,
                agendaItems: agendaItems,
              } as IMeetingNewCreateForm),
            );
            //dispatch(updateMeeting(meeting.id, { ...meetingInfo, type: meeting.type }));
          },
        }),
      );
    }
  }, [agendaItems, dispatch, meeting]);

  return (
    meeting && (
      <div className="panel vto-text h100 flex flex-column">
        <div className="panel-header no-border">
          <h2 className="bold">Team Members</h2>
          <div className="ml-auto flex aic">
            <a className="btn btn-md green-bg popup_button" title="Export" onClick={openMeetingForm}>
              <i className="icon-plus" /> Add User
            </a>
          </div>
        </div>
        <div className="row h100">
          <div className="col-12">
            <div className="panel-body pt-0 h100">
              <div className="border-radius-1 lightGray-bg p-2 h100">
                <div className="panel py-1 px-0 mb-1 no-border">
                  <div className="panel-body pt-05 h100">
                    <h5 className="bold">{meeting.type === 'account' ? 'ATAK Interactive' : 'ADMINS'}</h5>
                  </div>
                  <ParticipantList userIds={meeting.administratorIds} />
                </div>

                <div className="panel py-1 px-0 mb-0 no-border" style={{ marginBottom: '-10px !important' }}>
                  <div className="panel-body pt-05 h100">
                    <h5 className="bold">{meeting.type === 'account' ? meeting.title : 'MEMBERS'}</h5>
                  </div>
                  <ParticipantList userIds={meeting.shareWithIds} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
