import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './password-groups.constants';
import * as types from './password-groups.types';
import * as services from './password-groups.saga-services';

function* invokeGetPasswordGroups(action: types.IGetPasswordGroups) {
  yield call(services.getPasswordGroups, action.payload);
}

function* invokeSavePasswordGroup(action: types.ISavePasswordGroup) {
  yield call(services.savePasswordGroup, action.payload);
}

export function* watchPasswordsGroupPageSaga() {
  yield takeLatest(constants.GET_PASSWORD_GROUPS, invokeGetPasswordGroups);
  yield takeLatest(constants.SAVE_PASSWORD_GROUP, invokeSavePasswordGroup);
}
