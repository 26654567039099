import React, { FunctionComponent, useCallback, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { updateSession } from '../../../../../../app/page/page-storage/sessions/sessions.actions';
import { useApiRequestState } from '../../../../../../utils/api.utils';
import { IOtherCommentsProps } from './types';

export const OtherComments: FunctionComponent<IOtherCommentsProps> = ({ sessionId }) => {
  const dispatch = useDispatch();

  const [text, setText] = useState('');

  const saveSession = useCallback(() => {
    dispatch(updateSession(sessionId, { note: text }));
  }, [dispatch, sessionId, text]);
  const [showLoader] = useApiRequestState('updateSession');
  return (
    <div className="row mb-25 mt-3">
      <div className="col-12">
        <div className="panel vto-text">
          <div className="panel-header no-border">
            <h2 className="bold">Other Comments</h2>
          </div>
          <div className="row">
            <div className="col-12">
              <form className="panel-body pt-0">
                <div className="border-radius-1 lightGray-bg px-2 py-1">
                  <textarea
                    className="recap-textarea"
                    placeholder="Based on this meeting, who needs to know what?"
                    value={text}
                    onChange={(event) => setText(event.target.value)}
                  />
                </div>
                <div className="recap-submit">
                  {!showLoader && (
                    <button type="button" className="btn green-bg" onClick={saveSession}>
                      Save Your Notes
                    </button>
                  )}
                  {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={30} width={30} />}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
