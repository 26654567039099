/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { itemSelectors } from '../../../../../app/resource/item/item.selectors';

import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { IItemProps } from './types';

export const Item: FunctionComponent<IItemProps> = ({ itemId, color }) => {
  const item = useSelectWithParams(itemSelectors.selectResourceById, itemId);
  const history = useHistory();
  return (
    <>
      {item && item.url === '' ? (
        <a
          className="link flex aic"
          style={{ color: color }}
          onClick={() => history.push(`/agency-dashboard/${itemId}`)}
        >
          {item && item.title}
        </a>
      ) : (
        <a className="link flex aic" style={{ color: color }} onClick={() => history.push(`${item?.url}`)}>
          {item && item.title}
        </a>
      )}
    </>
  );
};
