import { all, call, put } from 'redux-saga/effects';

import * as types from './task.types';
import * as actions from './task.actions';
import * as api from '../../api/request-config/task.api';

import * as meetingResourceService from '../meeting/meeting.saga-service';
import * as userResourceService from '../user/user.saga-service';

import { meetingSelectors } from '../../resource/meeting/meeting.selectors';
import { userSelectors } from '../../resource/user/user.selectors';

import { apiRequest } from '../../api/api.saga-services';
import { select, selectWithParams } from '../../../utils/selector.utils';
import { IUser } from '../user/user.types';
import { IMeeting } from '../meeting/meeting.types';
import { taskSelectors } from './task.selectors';
import { IBoardList } from '../boards/boardList/boardList.types';
import { boardListSelectors } from '../boards/boardList/boardList.selectors';
import { storeBoardList } from '../boards/boardList/boardList.actions';
import { generateActiveDoneComplexIds, generateBoardListId } from '../../page/page-storage/tasks/tasks.saga-services';
import { meetingsPageSelectors } from '../../page/page-storage/meetings/meetings.selectors';
import { IBoard } from '../boards/board/board.types';
import { boardSelectors } from '../boards/board/board.selectors';

export function* storeTask(task: types.ITask) {
  yield put(actions.storeTask(task));
  //yield call(addTaskToMeetings, task.meetingIds, task.id);
  //yield call(addTaskToUsers, task.userId ? [task.userId] : [], task.id);
  return task;
}

export function* clearTask(taskId: types.ITask['id']) {
  yield call(removeTaskFromMeetings, taskId);
  yield call(removeTaskFromUsers, taskId);
  yield put(actions.clearTask(taskId));
}

export function* clearAllTasks() {
  const taskIds: types.ITask['id'][] = yield select(taskSelectors.selectResourceIds);
  yield put(actions.clearTask(taskIds));
}

/* This method sends api request and handle response */
export function* searchTasks(searchParams: types.ITaskSearchParams) {
  const tasks: types.ITask[] = yield call(apiRequest, api.searchTasksApi(searchParams));
  yield all(tasks.map((task) => call(storeTask, task)));
  return tasks.map((task) => task.id);
}

export function* createTask(data: types.ITaskCreate) {
  const task = (yield call(apiRequest, api.createTaskApi(data))) as types.ITask;
  if (!task) {
    return;
  }
  yield call(storeTask, task);
  return task;
}

export function* updateTask(data: types.ITaskUpdate) {
  const task = (yield call(apiRequest, api.updateTaskApi(data))) as types.ITask;
  if (!task) {
    return;
  }

  return task;
}
export function* createBoardTask(data: types.ITaskCreate) {
  const board = (yield selectWithParams(boardSelectors.selectResourceById, data.boardId)) as IBoard;
  const newData = { ...data, meetingIds: board.meetingIds };
  const task = (yield call(apiRequest, api.createTaskApi(newData))) as types.ITask;
  if (!task) {
    return;
  }

  return task;
}

export function* updateBoardTask(data: types.ITaskUpdate) {
  const task = (yield call(apiRequest, api.updateTaskApi(data))) as types.ITask;
  if (!task) {
    return;
  }
  yield call(storeTask, task);
  const boardList: IBoardList = yield selectWithParams(boardListSelectors.selectResourceById, task.taskTypeId);

  yield call(storeBoardList, boardList);
  return task;
}

export function* deleteTask(taskId: types.ITask['id']) {
  yield call(clearTask, taskId);
  yield call(apiRequest, api.deleteTaskApi(taskId));
}

export function* addTaskToUsers(userIds: IUser['id'][], taskId: types.ITask['id']) {
  const users: IUser[] = ((yield selectWithParams(userSelectors.selectResourcesById, userIds)) as IUser[]).filter(
    (user: IUser | null) => !!user,
  );

  yield all(
    users.map((user) => {
      const updatedUser = { ...user };

      if (!updatedUser.taskIds) {
        return call(userResourceService.storeUser, updatedUser);
      }

      updatedUser.taskIds = updatedUser.taskIds.includes(taskId)
        ? [...updatedUser.taskIds]
        : [...updatedUser.taskIds, taskId];
      return call(userResourceService.storeUser, updatedUser);
    }),
  );
}

export function* addTaskToMeetings(meetingIds: IMeeting['id'][], taskId: types.ITask['id']) {
  const meetings: IMeeting[] = ((yield selectWithParams(
    meetingSelectors.selectResourcesById,
    meetingIds,
  )) as IMeeting[]).filter((meeting: IMeeting | null) => !!meeting);

  yield all(
    meetings.map((meeting) => {
      const updatedMeeting = { ...meeting };

      if (!updatedMeeting.taskIds) {
        return call(meetingResourceService.storeMeeting, updatedMeeting);
      }

      updatedMeeting.taskIds = updatedMeeting.taskIds.includes(taskId)
        ? [...updatedMeeting.taskIds]
        : [...updatedMeeting.taskIds, taskId];
      return call(meetingResourceService.storeMeeting, updatedMeeting);
    }),
  );
}

export function* removeTaskFromUsers(taskId: types.ITask['id']) {
  const task = (yield selectWithParams(taskSelectors.selectResourceById, taskId)) as types.ITask;

  if (!task) {
    return;
  }
  const userIds = task.userId ? [task.userId] : [];

  const users = ((yield selectWithParams(userSelectors.selectResourcesById, userIds)) as IUser[]).filter(
    (user) => !!user,
  );

  yield all(
    users.map((user) => {
      const updatedUser = { ...user };
      updatedUser.taskIds = updatedUser.taskIds ? updatedUser.taskIds.filter((id) => id !== taskId) : null;
      return call(userResourceService.storeUser, updatedUser);
    }),
  );
}

export function* removeTaskFromMeetings(taskId: types.ITask['id']) {
  const task = (yield selectWithParams(taskSelectors.selectResourceById, taskId)) as types.ITask;
  if (!task) {
    return;
  }
  const meetings = ((yield selectWithParams(
    meetingSelectors.selectResourcesById,
    task.meetingIds,
  )) as IMeeting[]).filter((meeting) => !!meeting);

  yield all(
    meetings.map((meeting) => {
      const updatedMeeting = { ...meeting };
      updatedMeeting.taskIds = updatedMeeting.taskIds ? updatedMeeting.taskIds.filter((id) => id !== taskId) : null;
      return call(meetingResourceService.storeMeeting, updatedMeeting);
    }),
  );
}

export function* moveTask(data: types.IMoveTask) {
  yield call(apiRequest, api.moveTaskApi(data));
}

export function* moveTaskNotification(params: types.IMoveTask) {
  const task = (yield selectWithParams(taskSelectors.selectResourceById, params.id)) as types.ITask;
  if (!task || params.taskTypeId === task.taskTypeId) {
    return;
  }

  const oldBoardList = (yield selectWithParams(boardListSelectors.selectResourceById, task.taskTypeId)) as IBoardList;
  if (oldBoardList) {
    yield call(storeBoardList, {
      ...oldBoardList,
      tasksIds: oldBoardList.tasksIds.filter((taskId) => taskId !== task.id),
    });
  }

  const newTask = (yield call(storeTask, { ...task, taskTypeId: params.taskTypeId })) as types.ITask;
  const boardListExists = (yield selectWithParams(
    boardListSelectors.selectResourceById,
    params.taskTypeId,
  )) as IBoardList;
  if (boardListExists) {
    yield call(storeBoardList, { ...boardListExists, tasksIds: [...boardListExists.tasksIds, task.id] });
  }
  return newTask;
}

export function* copyTask(data: types.ICopyTask) {
  const task = (yield call(apiRequest, api.copyTaskApi(data))) as types.ITask;

  const newTask = (yield call(copyTaskNotification, {
    sourceId: data.id,
    id: task.id,
    taskTypeId: data.taskTypeId,
  })) as types.ITask;
  return newTask;
}

export function* copyTaskNotification(params: types.ICopyTaskFromNotification) {
  const task = (yield selectWithParams(taskSelectors.selectResourceById, params.sourceId)) as types.ITask;
  if (!task) {
    return;
  }
  const newTask = (yield call(storeTask, { ...task, id: params.id, taskTypeId: params.taskTypeId })) as types.ITask;
  const boardListExists: IBoardList | null = yield selectWithParams(
    boardListSelectors.selectResourceById,
    params.taskTypeId,
  );
  if (boardListExists && !boardListExists.tasksIds.includes(params.id)) {
    yield call(storeBoardList, { ...boardListExists, tasksIds: [...boardListExists.tasksIds, newTask.id] });
  }
  return task;
}

export function* saveTaskNotification(task: types.ITask) {
  const meetingId = (yield select(meetingsPageSelectors.selectCurrentMeeting)) as IMeeting['id'];
  const boardLists = (yield selectWithParams(boardListSelectors.selectResourcesById, [
    task.taskTypeId,
    ...generateActiveDoneComplexIds(
      generateBoardListId(meetingId, 'groupByNonDefaultTypes', { taskTypeId: task.taskTypeId }),
    ),
  ])) as IBoardList[];

  const updatedBoardList = boardLists
    .map((boardList) =>
      boardList && !boardList.tasksIds.includes(task.id)
        ? { ...boardList, tasksIds: [...boardList.tasksIds, task.id] }
        : false,
    )
    .filter((clearFalse) => !!clearFalse) as IBoardList[];

  console.log(
    'BOARD LIST',
    boardLists,
    generateActiveDoneComplexIds(
      generateBoardListId(meetingId, 'groupByNonDefaultTypes', { taskTypeId: task.taskTypeId }),
    ),
  );

  yield put(actions.storeTask(task));
  yield put(storeBoardList(updatedBoardList));
  return task;
}
