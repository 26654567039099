import { call, takeLatest } from 'redux-saga/effects';
import * as constants from './kpi.constants';

import * as types from './kpi.types';
import * as services from './kpi.saga-services';

function* invokeSaveScoreboard(action: types.ISaveScoreboard) {
  yield call(services.saveScoreboard, action.payload);
}

function* invokeCreateScore(action: types.ICreateScore) {
  yield call(services.createScore, action.payload);
}

function* invokeUpdateScore(action: types.IUpdateScore) {
  yield call(services.updateScore, action.payload.scoreId, action.payload.data);
}

function* invokeSaveScoreOrder(action: types.ISaveScoreOrder) {
  yield call(services.saveScoreOrder, action.payload.scoreIds);
}

function* invokeUpdateScoreboardSocket(action: types.IUpdateScoreboardSocket) {
  yield call(services.handleScoreboardFromNotification, action.payload);
}

export function* watchKpiPageSaga() {
  yield takeLatest(constants.SAVE_SCOREBOARD, invokeSaveScoreboard);
  yield takeLatest(constants.CREATE_SCORE, invokeCreateScore);
  yield takeLatest(constants.UPDATE_SCORE, invokeUpdateScore);
  yield takeLatest(constants.SAVE_SCORE_ORDER, invokeSaveScoreOrder);

  yield takeLatest(constants.SAVE_SCOREBOARD_SOCKET, invokeUpdateScoreboardSocket);
}
