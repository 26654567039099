import * as types from './timer.types';
import * as constants from './timer.constants';

import { combineReducers, Reducer } from 'redux';
import { isActionOfType } from '../../../utils/action.utils';

const initialState: types.ITimerState = {
  isOpen: false,
  timerStatus: 'STOPPED_TIMER',
  time: 0,
  clientId: null,
  projectTaskIds: [],
  projectTaskId: null,
  note: '',
  timerResultId: null,
};

const isOpenReducer: Reducer<types.ITimerState['isOpen'], types.IStoreIsOpen | types.IClearIsOpen> = (
  state = initialState.isOpen,
  action,
) => {
  if (isActionOfType<types.IStoreIsOpen>(action, constants.STORE_IS_OPEN)) {
    return action.payload;
  } else if (isActionOfType<types.IClearIsOpen>(action, constants.CLEAR_IS_OPEN)) {
    return initialState.isOpen;
  }
  return state;
};

const timerStatusReducer: Reducer<
  types.ITimerState['timerStatus'],
  types.IStoreTimerStatus | types.IClearTimerStatus
> = (state = initialState.timerStatus, action) => {
  if (isActionOfType<types.IStoreTimerStatus>(action, constants.STORE_TIMER_STATUS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearTimerStatus>(action, constants.CLEAR_TIMER_STATUS)) {
    return initialState.timerStatus;
  }
  return state;
};

const timeReducer: Reducer<types.ITimerState['time'], types.IStoreTime | types.IClearTime> = (
  state = initialState.time,
  action,
) => {
  if (isActionOfType<types.IStoreTime>(action, constants.STORE_TIME)) {
    return action.payload;
  } else if (isActionOfType<types.IClearTime>(action, constants.CLEAR_TIME)) {
    state = initialState.time;
  }
  return state;
};

const clientIdReducer: Reducer<types.ITimerState['clientId'], types.IStoreClient | types.IClearClient> = (
  state = initialState.clientId,
  action,
) => {
  if (isActionOfType<types.IStoreClient>(action, constants.STORE_CLIENT)) {
    return action.payload;
  } else if (isActionOfType<types.IClearClient>(action, constants.CLEAR_CLIENT)) {
    state = initialState.clientId;
  }
  return state;
};

const projectTaskIdsReducer: Reducer<
  types.ITimerState['projectTaskIds'],
  types.IStoreProjectTasks | types.IClearProjectTasks
> = (state = initialState.projectTaskIds, action) => {
  if (isActionOfType<types.IStoreProjectTasks>(action, constants.STORE_PROJECT_TASKS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearProjectTasks>(action, constants.CLEAR_PROJECT_TASKS)) {
    state = initialState.projectTaskIds;
  }
  return state;
};

const projectTaskIdReducer: Reducer<
  types.ITimerState['projectTaskId'],
  types.IStoreProjectTask | types.IClearProjectTask
> = (state = initialState.projectTaskId, action) => {
  if (isActionOfType<types.IStoreProjectTask>(action, constants.STORE_PROJECT_TASK)) {
    return action.payload;
  } else if (isActionOfType<types.IClearProjectTask>(action, constants.CLEAR_PROJECT_TASK)) {
    state = initialState.projectTaskId;
  }
  return state;
};

const noteReducer: Reducer<types.ITimerState['note'], types.IStoreNote | types.IClearNote> = (
  state = initialState.note,
  action,
) => {
  if (isActionOfType<types.IStoreNote>(action, constants.STORE_NOTE)) {
    return action.payload;
  } else if (isActionOfType<types.IClearNote>(action, constants.CLEAR_NOTE)) {
    state = initialState.note;
  }
  return state;
};

const timerResutIdReducer: Reducer<
  types.ITimerState['timerResultId'],
  types.IStoreTimerResult | types.IClearTimerResult
> = (state = initialState.timerResultId, action) => {
  if (isActionOfType<types.IStoreTimerResult>(action, constants.STORE_TIMER_RESULT)) {
    return action.payload;
  } else if (isActionOfType<types.IClearTimerResult>(action, constants.CLEAR_TIMER_RESULT)) {
    state = initialState.timerResultId;
  }
  return state;
};

export const timerTrackerReducer: Reducer<types.ITimerState, types.ITimerAction> = combineReducers({
  isOpen: isOpenReducer,
  timerStatus: timerStatusReducer,
  time: timeReducer,
  clientId: clientIdReducer,
  projectTaskIds: projectTaskIdsReducer,
  projectTaskId: projectTaskIdReducer,
  note: noteReducer,
  timerResultId: timerResutIdReducer,
});
