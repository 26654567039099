import { get } from 'lodash';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { CirclePicker } from 'react-color';
import { Controller, useFormContext } from 'react-hook-form';
import { IColorInputProps } from './types';

const colors = ['#C2CDD8', '#66717C', '#3D434A', '#747FED', '#21AFE4', '#1BC3A7', '#13AC92', '#FF5C6C', '#F4A743'];
export const ColorInput: FunctionComponent<IColorInputProps> = ({
  id,
  name,
  defaultValue,
  placeholder,
  validation,
  label,
  description,
  disabled,
}) => {
  const [color, setColor] = useState(defaultValue ? defaultValue : '#edf2f5');
  const [visible, setVisible] = useState(false);
  const { control, errors } = useFormContext();
  const visibleSetter = useCallback(() => {
    if (!disabled) {
      setVisible(!visible);
    }
  }, [disabled, visible]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ''}
      rules={validation}
      render={(controllerProps) => (
        <>
          <div className="new-account-image">
            <span id={id} className="round-icon" style={{ backgroundColor: color }}>
              <i className="icon2-color"></i>
            </span>
          </div>
          <div onClick={visibleSetter} className="round-icon-small">
            <i className="icon-pencil"></i>
          </div>
          <div className="new-account-desc">
            {!!get(errors, name, false) && (
              <label htmlFor={name} style={{ color: 'red' }}>
                {`${label ? label + ' - ' : ''} ${get(errors, `${name}.message`)}`}
              </label>
            )}
            <p className="bold"> {placeholder}</p>
            <p className="small-text">{description}</p>
          </div>
          {visible && (
            <CirclePicker
              onChange={(color) => {
                setColor(color.hex);
                controllerProps.onChange(color.hex);
                visibleSetter();
              }}
              colors={colors}
            />
          )}
        </>
      )}
    />
  );
};
