import { call } from 'redux-saga/effects';

import * as pageStateService from '../page-state/page-state.saga-services';

import * as pageInitializerService from '../page.initializer.saga-service';
import * as chartService from '../page-storage/chart/chart.saga-services';

export function* initialChartPage() {
  yield call(pageStateService.initPage, 'chart');
  if (!((yield call(pageInitializerService.initPage)) as boolean)) {
    yield call(chartService.setupChart);
  }
  yield call(pageStateService.completedInit, 'chart');
}
