import React, { FunctionComponent } from 'react';
import { TaskDragDropContext } from '../../drag-drop-contexts/TaskDragDropContext';
import { IWidgetWrapperParams } from './types';

export const WidgetWrapper: FunctionComponent<IWidgetWrapperParams> = ({ name, actionElement, children }) => {
  return (
    <div className="row mb-25 mt-3">
      <div className="col-12">
        <div className="panel vto-text">
          <div className="panel-header small-header no-border pb-0">
            <h2 className="bold">{name}</h2>
            <div className="ml-auto flex aic">{actionElement}</div>
          </div>
          <div className="cards-container-overflow">
            <TaskDragDropContext>
              <div className="cards-container">{children}</div>
            </TaskDragDropContext>
          </div>
        </div>
      </div>
    </div>
  );
};
