/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ProjectTeamForm } from './ProjectTeamForm';
import { IProjectTeamProps } from './types';

export const ProjectTeam: FunctionComponent<IProjectTeamProps> = ({ projectData }) => {
  const { control } = useFormContext();

  const members = projectData?.teams;
  const focusHourlyTuple = useWatch<{ [key: string]: string }>({
    control,
    name: 'hourlyRate',
  });
  const focusBudgetTuple = useWatch<{ [key: string]: string }>({
    control,
    name: 'budgetType',
  });

  return (
    <>
      <div className="panel green-gradient py-1 px-2 flex aic mb-1">
        <h2 className="bold white flex aic">TEAM</h2>
      </div>
      <div className="popup-tasks">
        <div className="tasks-header">
          <span className="tasks-header-item">MEMBER NAME</span>
          <span className="tasks-header-item text-right ml-auto">MANAGE PROJECT</span>
          {focusBudgetTuple && focusBudgetTuple.value === 'hours_per_person' && (
            <span
              className="tasks-header-item text-right max100 ml-1 team_member_budget_hours"
              style={{ display: 'none !important' }}
            >
              BUDGET (hours)
            </span>
          )}
          {focusHourlyTuple && focusHourlyTuple.value === 'person' && (
            <span
              className="tasks-header-item text-right max100 ml-1 team_member_hourly_rate"
              style={{ display: 'block !important' }}
            >
              HOURLY RATE
            </span>
          )}
        </div>
        <ProjectTeamForm projectMembers={members} />
      </div>
    </>
  );
};
