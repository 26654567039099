/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FunctionComponent } from 'react';
import ReactDatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';

import { updateTask } from '../../../../../../../../../app/page/page-storage/boardLists/boardLists.actions';

import { taskSelectors } from '../../../../../../../../../app/resource/task/task.selectors';
import { ITask } from '../../../../../../../../../app/resource/task/task.types';
import { useSelectWithParams } from '../../../../../../../../../utils/selector.utils';

import { IDueDateProps } from './types';

export const DueDate: FunctionComponent<IDueDateProps> = ({ taskId }) => {
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId) as ITask;
  const dispatch = useDispatch();

  const defaultStartDateValue = task?.dueDate;
  const defaultDueDate = useMemo(() => {
    try {
      if (defaultStartDateValue) {
        const dateString = moment(defaultStartDateValue).format('YYYY-MM-DD');
        if (dateString === 'Invalid date') {
          return '0000-00-00 00:00:00';
        }
        return defaultStartDateValue;
      }
    } catch (error) {
      console.log(error);
      return '0000-00-00 00:00:00';
    }
  }, [defaultStartDateValue]);
  const [startDate, setStartDate] = useState(new Date(moment(defaultDueDate).format('YYYY-MM-DD')));

  useEffect(() => {
    setStartDate(new Date(moment(defaultDueDate).format('YYYY-MM-DD')));
  }, [defaultDueDate, defaultStartDateValue, task.dueDate]);
  const handleDateSelect = useCallback(
    (date: Date) => {
      dispatch(updateTask({ label: [], ...task, dueDate: moment(date).format('YYYY-MM-DD') }));
    },
    [dispatch, task],
  );
  return (
    <div className="col-4">
      <div className="field-container datepicker">
        <h5 className="bold mb-1">DUE DATE</h5>
        <div className="tags-container">
          {String(startDate) === 'Invalid Date' ? (
            <ReactDatePicker
              className="small-input"
              //selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              onSelect={(date: Date) => handleDateSelect(date)}
              onClickOutside={() => handleDateSelect(startDate)}
              dateFormat={'MM/dd/yyyy'}
              customInput={<InputMask mask="99/99/9999" placeholder="MM/dd/yyyy" />}
            />
          ) : (
            <ReactDatePicker
              className="small-input"
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              onSelect={(date: Date) => handleDateSelect(date)}
              onClickOutside={() => handleDateSelect(startDate)}
              dateFormat={'MM/dd/yyyy'}
              customInput={<InputMask mask="99/99/9999" placeholder="MM/dd/yyyy" />}
            />
          )}
        </div>
      </div>
    </div>
  );
};
