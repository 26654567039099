import { all, call } from 'redux-saga/effects';
import { logOutIfUserExpired } from '../auth/auth.saga-services';

import { setupMeetings } from './page-storage/meetings/meetings.saga-services';
import { setupNotifications } from './page-storage/notifications/notifications.saga-services';
import { setupUsers } from './page-storage/users/users.saga-services';

import { clearMeetingsPageStorage } from './page-storage/meetings/meetings.saga-services';
import { clearUsersPageStorage } from './page-storage/users/users.saga-services';
import { clearTasksPageStorage } from './page-storage/tasks/tasks.saga-services';
import { clearSessionsPageStorage } from './page-storage/sessions/sessions.saga-services';
import { clearKpisPageStorage } from './page-storage/kpi/kpi.saga-services';
import { clearAgendaItemsPageStorage } from './page-storage/agenda-items/agenda-items.saga-services';
import { clearConclusionsPageStorage } from './page-storage/conclusions/conclusions.saga-services';
import { clearNotificationsPageStorage } from './page-storage/notifications/notifications.saga-services';
import { clearTemplatesPageStorage } from './page-storage/templates/templates.saga-services';
import { clearCalendarsPageStorage } from './page-storage/calendars/calendars.saga-services';
import { setupDefaultAgendaItems } from '../resource/agenda-item/agenda-item.saga-service';
import { clearBoardsPageStorage } from './page-storage/boards/boards.saga-services';
import { clearboardListsPageStorage } from './page-storage/boardLists/boardLists.saga-services';
import { clearActivitiesPageStorage } from './page-storage/activities/activities.saga-services';
import { clearCheckListsPageStorage } from './page-storage/checklists/checkLists.saga-services';
import { clearLabelsPageStorage } from './page-storage/labels/labels.saga-services';
import { clearUserGroupsPageStorage } from './page-storage/user-groups/user-groups.saga-services';
import { clearPasswordsPageStorage } from './page-storage/passwords/passwords.saga-services';
import { clearPasswordGroupsPageStorage } from './page-storage/password-groups/password-groups.saga-services';
import { clearProjectTasksPageStorage } from './page-storage/project-tasks/project-tasks.saga-services';
import { clearProjectMembersPageStorage } from './page-storage/project-members/project-members.saga-services';
import { clearProjectsPageStorage } from './page-storage/projects/projects.saga-services';
import { clearProjectTimesPageStorage } from './page-storage/project-times/project-times.saga-services';
import { clearQuartalsPageStorage } from './page-storage/quartals/quartals.saga-services';
import { clearCategoriesPageStorage } from './page-storage/categories/categories.saga-services';
import { clearMeetingsInfoPageStorage } from './page-storage/meetings-info/meetings-info.saga-services';

export function* initPage() {
  const expiredToken = (yield call(logOutIfUserExpired)) as boolean;
  if (!expiredToken) {
    const setups = [setupNotifications, setupUsers, setupMeetings, setupDefaultAgendaItems];
    yield all(setups.map((setup) => call(setup)));
  }
  return expiredToken;
}

export function* uninitPage() {
  const uninits = [
    clearMeetingsPageStorage,
    clearUsersPageStorage,
    clearTasksPageStorage,
    clearSessionsPageStorage,
    clearKpisPageStorage,
    clearAgendaItemsPageStorage,
    clearConclusionsPageStorage,
    clearNotificationsPageStorage,
    clearTemplatesPageStorage,
    clearCalendarsPageStorage,
    clearBoardsPageStorage,
    clearboardListsPageStorage,
    clearActivitiesPageStorage,
    clearCheckListsPageStorage,
    clearLabelsPageStorage,
    clearUserGroupsPageStorage,
    clearPasswordsPageStorage,
    clearPasswordGroupsPageStorage,
    clearProjectTasksPageStorage,
    clearProjectMembersPageStorage,
    clearProjectsPageStorage,
    clearProjectTimesPageStorage,
    clearQuartalsPageStorage,
    clearCategoriesPageStorage,
    clearMeetingsInfoPageStorage,
  ];
  yield all(uninits.map((uninit) => call(uninit)));
}
