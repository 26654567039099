import React, { FunctionComponent } from 'react';
import { formatDay } from '../../../../../../../utils/date.utils';
import { CalendarDayView } from '../WeekView/CalendarDayView';
import { IDayViewPorps } from './types';

export const DayView: FunctionComponent<IDayViewPorps> = ({ meetingId, userFilter, date }) => {
  return (
    <div className="calendar-rendered calendar-day-view">
      <div className="calendar-days">
        <div className="calendar-cell all-day-cell">
          <div className="calendar-cell-day">{formatDay(date)}</div>
        </div>
      </div>
      <div className="calendar-week-main-view">
        <div className="week-view-hours">
          <div className="week-view-one-hour">8am</div>
          <div className="week-view-one-hour">9am</div>
          <div className="week-view-one-hour">10am</div>
          <div className="week-view-one-hour">11am</div>
          <div className="week-view-one-hour">12pm</div>
          <div className="week-view-one-hour">1pm</div>
          <div className="week-view-one-hour">2pm</div>
          <div className="week-view-one-hour">3pm</div>
          <div className="week-view-one-hour">4pm</div>
          <div className="week-view-one-hour">5pm</div>
          <div className="week-view-one-hour">6pm</div>
          <div className="week-view-one-hour">7pm</div>
          <div className="week-view-one-hour">8pm</div>
        </div>
        <div className="week-view-cells">
          <div key={`date-${date}`} style={{ width: '100%' }}>
            <CalendarDayView meetingId={meetingId} date={date} userFilter={userFilter} />
          </div>
        </div>
      </div>
    </div>
  );
};
