/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { openDialog } from '../../../../app/dialog/dialog.actions';
import { savePasswordGroup } from '../../../../app/page/page-storage/password-groups/password-groups.actions';
import { passwordGroupsPageSelectors } from '../../../../app/page/page-storage/password-groups/password-groups.selectors';
import { filterPasswords } from '../../../../app/page/page-storage/passwords/passwords.actions';
import { passwordsPageSelectors } from '../../../../app/page/page-storage/passwords/passwords.selectors';
import { IMeeting } from '../../../../app/resource/meeting/meeting.types';
import { IPasswordGroupSave } from '../../../../app/resource/password-group/password-group.types';
import { useSelect } from '../../../../utils/selector.utils';
import { PasswordFolder } from './PasswordFolder';

export const PasswordFolders: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { meetingId } = useParams<{ meetingId: IMeeting['id'] }>();

  const passwordGroupIds = useSelect(passwordGroupsPageSelectors.selectPasswordGroupIds);
  const passwordIds = useSelect(passwordsPageSelectors.selectPasswordIds);

  const resetFilter = useCallback(() => {
    dispatch(filterPasswords({ groupId: null }));
  }, [dispatch]);

  const onOpenDialog = useCallback(() => {
    dispatch(
      openDialog('passwordGroupFormDialog', {
        meetingId,
        onSubmit: (data: IPasswordGroupSave) => {
          dispatch(savePasswordGroup(data));
        },
      }),
    );
  }, [dispatch, meetingId]);

  return (
    <div className="col max-330">
      <h5 className="bold mb-1">PASSWORD FOLDERS</h5>
      <a
        className="directory-button panel small-panel flex aic px-1 mb-1"
        style={{ cursor: 'pointer' }}
        onClick={resetFilter}
      >
        <i className="icon2-folder mr-1 blueGray" /> All Passwords
        <span className="ml-auto blueGray">{`${passwordIds.length} items`}</span>
      </a>

      {passwordGroupIds.map((passwordGroupId) => (
        <PasswordFolder key={`password-group-${passwordGroupId}`} passwordGroupId={passwordGroupId} />
      ))}

      <a
        className="directory-button panel small-panel blueGray flex aic px-1"
        style={{ cursor: 'pointer' }}
        onClick={onOpenDialog}
      >
        <i className="icon-plus mr-1 blueGray f-2" /> New Folder
      </a>
    </div>
  );
};
