import { all, call, put } from 'redux-saga/effects';
import * as types from './node.types';
import * as actions from './node.actions';
import { select } from '../../../utils/selector.utils';
import { nodeSelectors } from './node.selectors';
import { apiRequest } from '../../api/api.saga-services';
import * as api from '../../api/request-config/chart.api';

export function* storeNode(node: types.IOrganizationChartNode) {
  yield put(actions.storeNode(node));

  return node;
}

export function* clearNode(nodeId: types.IOrganizationChartNode['id']) {
  yield put(actions.clearNode(nodeId));
}

export function* clearAllNodes() {
  const nodeIds: types.IOrganizationChartNode['id'][] = yield select(nodeSelectors.selectResourceIds);
  yield put(actions.clearNode(nodeIds));
}

/* This method sends api request and handle response */
export function* searchNodes() {
  const nodes: types.IOrganizationChartNode[] = yield call(apiRequest, api.searchNodesApi());
  if (!nodes) {
    return;
  }
  const newNodes = nodes.map((node) =>
    node.parentId ? { ...node, id: `n-${node.id}`, parentId: `n-${node.parentId}` } : { ...node, id: `n-${node.id}` },
  );

  yield all(newNodes.map((node) => call(storeNode, node)));
  return newNodes.map((node) => node.id);
}

export function* createNode(data: types.INodeCreate) {
  const nodeApi = data.parentId ? { ...data, parentId: data.parentId?.substring(2) } : { ...data };

  const node = (yield call(apiRequest, api.createNodeApi(nodeApi))) as types.IOrganizationChartNode;
  if (!node) {
    return;
  }
  const newNode = node.parentId
    ? { ...node, id: `n-${node.id}`, parentId: `n-${node.parentId}` }
    : { ...node, id: `n-${node.id}` };

  yield call(storeNode, newNode);
  return newNode;
}

export function* updateNode(data: types.INodeUpdate) {
  const nodeApi = data.parentId
    ? { ...data, parentId: data.parentId?.substring(2), id: data.id.substring(2) }
    : { ...data, id: data.id.substring(2) };

  const node = (yield call(apiRequest, api.updateNodeApi(nodeApi))) as types.IOrganizationChartNode;
  if (!node) {
    return;
  }

  const newNode = node.parentId
    ? { ...node, id: `${data.id}`, parentId: `n-${node.parentId}` }
    : { ...node, id: `${data.id}` };

  yield call(storeNode, newNode);
  return newNode;
}

export function* deleteNode(nodeId: types.IOrganizationChartNode['id']) {
  const newId = nodeId.substring(2);
  yield call(apiRequest, api.deleteNodeApi(newId));
}
