import { combineReducers, Reducer } from 'redux';

import * as types from './user-groups.types';
import * as constants from './user-groups.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IUserGroupsPageState = {
  userGroupIds: [],
};

const userGroupIdsReducer: Reducer<
  types.IUserGroupsPageState['userGroupIds'],
  types.IStoreUserGroups | types.IClearUserGroups
> = (state = initialState.userGroupIds, action) => {
  if (isActionOfType<types.IStoreUserGroups>(action, constants.STORE_USER_GROUPS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearUserGroups>(action, constants.CLEAR_USER_GROUPS)) {
    return initialState.userGroupIds;
  }
  return state;
};

export const userGroupsPageReducer: Reducer<types.IUserGroupsPageState, types.IUserGroupsPageAction> = combineReducers({
  userGroupIds: userGroupIdsReducer,
});
