import { combineReducers, Reducer } from 'redux';

import * as types from './chart.types';
import * as constants from './chart.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IChartPageState = {
  nodeIds: [],
};

const chartNodesReducer: Reducer<types.IChartPageState['nodeIds'], types.IStoreChart | types.IClearChart> = (
  state = initialState.nodeIds,
  action,
) => {
  if (isActionOfType<types.IStoreChart>(action, constants.STORE_CHART)) {
    return action.payload;
  } else if (isActionOfType<types.IClearChart>(action, constants.CLEAR_CHART)) {
    return initialState.nodeIds;
  }

  return state;
};

export const chartPageReducer: Reducer<types.IChartPageState, types.IChartPageAction> = combineReducers({
  nodeIds: chartNodesReducer,
});
