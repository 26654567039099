import { call } from 'redux-saga/effects';

import * as pageInitializerService from '../page.initializer.saga-service';
import * as pageStateService from '../page-state/page-state.saga-services';
import * as templateService from '../page-storage/templates/templates.saga-services';

export function* initialVtoPage() {
  yield call(pageStateService.initPage, 'vto');
  if (!((yield call(pageInitializerService.initPage)) as boolean)) {
    yield call(templateService.fetchTemplates);
  }

  yield call(pageStateService.completedInit, 'vto');
}
