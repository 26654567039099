import { put, select, call, all } from 'redux-saga/effects';
import * as types from './label.types';
import * as actions from './label.actions';
import { apiRequest } from '../../api/api.saga-services';
import * as api from '../../api/request-config/label.api';
import { labelSelectors } from './label.selectors';

export function* storeLabel(label: types.ILabel) {
  yield put(actions.storeLabel(label));

  return label;
}

export function* clearLabel(labelId: types.ILabel['id']) {
  yield put(actions.clearLabel(labelId));
}

export function* clearAllLabels() {
  const labelsIds: types.ILabel['id'][] = yield select(labelSelectors.selectResourceIds);
  yield put(actions.clearLabel(labelsIds));
}

export function* searchLabels() {
  const labels: types.ILabel[] = yield call(apiRequest, api.searchLabelsApi());
  if (labels) {
    yield all(labels.map((label) => call(storeLabel, label)));
    return labels.map((label) => label.id);
  }
}

export function* createLabel(data: types.ILabelCreate) {
  const label = (yield call(apiRequest, api.createLabelApi(data))) as types.ILabel;
  if (!label) {
    return;
  }
  yield call(storeLabel, label);
  return label;
}

export function* updateLabel(data: types.ILabelUpdate) {
  const label = (yield call(apiRequest, api.updateLabelApi(data))) as types.ILabel;
  if (!label) {
    return;
  }
  label.id = data.id;
  yield call(storeLabel, label);
  return label;
}

export function* deleteLabel(labelId: types.ILabel['id']) {
  yield call(apiRequest, api.deleteLabelApi(labelId));
}
