import { put, select, call, all } from 'redux-saga/effects';
import * as types from './boardList.types';
import * as actions from './boardList.actions';
import { apiRequest } from '../../../api/api.saga-services';
import * as api from '../../../api/request-config/boardList.api';
import { boardListSelectors } from './boardList.selectors';
import { IBoard } from '../board/board.types';
import { ITask } from '../../task/task.types';
import { storeTask } from '../../task/task.saga-service';

export function* storeBoardList(boardList: types.IBoardList) {
  yield put(actions.storeBoardList(boardList));
  return boardList;
}

export function* clearBoardList(boardListId: types.IBoardList['id']) {
  yield put(actions.clearBoardList(boardListId));
}

export function* clearAllBoardLists() {
  const boardListsIds: types.IBoardList['id'][] = yield select(boardListSelectors.selectResourceIds);
  yield put(actions.clearBoardList(boardListsIds));
}

export function* searchBoardLists(boardId: IBoard['id']) {
  const boardLists: types.IBoardListResponse[] = yield call(apiRequest, api.searchBoardListsApi(boardId));
  const tasks = boardLists.reduce((prev, curr) => [...prev, ...curr.tasks], [] as ITask[]);

  yield all(tasks.map((task) => call(storeTask, task)));
  yield all(
    boardLists.map((boardList) =>
      call(storeBoardList, {
        id: boardList.id,
        name: boardList.name,
        boardId: boardList.boardId,
        slug: boardList.slug,
        properties: boardList.properties,
        flag: boardList.flag,
        changedBy: boardList.changedBy,
        changedAt: boardList.changedAt,
        deleted: boardList.deleted,
        tasksIds: boardList.tasks.map((task) => task.id),
      }),
    ),
  );

  return boardLists.map((boardList) => boardList.id);
}

export function* createBoardList(data: types.IBoardListCreate) {
  const boardList = (yield call(apiRequest, api.createBoardListApi(data))) as types.IBoardList;
  if (!boardList) {
    return;
  }
  return boardList;
}

export function* updateBoardList(data: types.IBoardListUpdate) {
  const boardList = (yield call(apiRequest, api.updateBoardListApi(data))) as types.IBoardList;
  if (!boardList) {
    return;
  }
  boardList.id = data.id;
  yield call(storeBoardList, boardList);
  return boardList;
}

export function* deleteBoardList(boardListId: types.IBoardList['id']) {
  yield call(apiRequest, api.deleteBoardListApi(boardListId));
}

export function* saveTaskOrder(data: types.ISaveTaskOrder) {
  yield call(apiRequest, api.saveTaskOrderApi(data));
}

export function* saveBoardListOrder(data: types.ISaveBoardListOrder) {
  yield call(apiRequest, api.saveBoardListOrder(data));
}
