import { createActionConst } from '../../../utils/action.utils';

const TIMER_NAMESPACE = 'TIMER_NAMESPACE';

export const INITIAL_TIMER = createActionConst(TIMER_NAMESPACE, 'INITIAL_TIMER');

export const TOGGLE_TIMER = createActionConst(TIMER_NAMESPACE, 'TOGGLE_TIMER');
export const STORE_IS_OPEN = createActionConst(TIMER_NAMESPACE, 'STORE_IS_OPEN');
export const CLEAR_IS_OPEN = createActionConst(TIMER_NAMESPACE, 'CLEAR_IS_OPEN');

export const CHANGE_TIMER_STATUS = createActionConst(TIMER_NAMESPACE, 'CHANGE_TIMER_STATUS');
export const STORE_TIMER_STATUS = createActionConst(TIMER_NAMESPACE, 'STORE_TIMER_STATUS');
export const CLEAR_TIMER_STATUS = createActionConst(TIMER_NAMESPACE, 'CLEAR_TIMER_STATUS');

export const CHANGE_TIME = createActionConst(TIMER_NAMESPACE, 'CHANGE_TIME');
export const STORE_TIME = createActionConst(TIMER_NAMESPACE, 'STORE_CLIENT');
export const CLEAR_TIME = createActionConst(TIMER_NAMESPACE, 'CLEAR_TIME');

export const CHOOSE_CLIENT = createActionConst(TIMER_NAMESPACE, 'CHOOSE_CLIENT');
export const STORE_CLIENT = createActionConst(TIMER_NAMESPACE, 'STORE_CLIENT');
export const CLEAR_CLIENT = createActionConst(TIMER_NAMESPACE, 'CLEAR_CLIENT');

export const STORE_PROJECT_TASKS = createActionConst(TIMER_NAMESPACE, 'STORE_PROJECT_TASKS');
export const CLEAR_PROJECT_TASKS = createActionConst(TIMER_NAMESPACE, 'CLEAR_PROJECT_TASKS');

export const CHOOSE_PROJECT_TASK = createActionConst(TIMER_NAMESPACE, 'CHOOSE_PROJECT_TASK');
export const STORE_PROJECT_TASK = createActionConst(TIMER_NAMESPACE, 'STORE_PROJECT_TASK');
export const CLEAR_PROJECT_TASK = createActionConst(TIMER_NAMESPACE, 'CLEAR_PROJECT_TASK');

export const STORE_NOTE = createActionConst(TIMER_NAMESPACE, 'STORE_NOTE');
export const CLEAR_NOTE = createActionConst(TIMER_NAMESPACE, 'CLEAR_NOTE');

export const SAVE_TIMER_RESULT = createActionConst(TIMER_NAMESPACE, 'SAVE_TIMER_RESULT');

export const CHOOSE_TIMER_RESULT = createActionConst(TIMER_NAMESPACE, 'CHOOSE_TIMER_RESULT');

export const STORE_TIMER_RESULT = createActionConst(TIMER_NAMESPACE, 'STORE_TIMER_RESULT');
export const CLEAR_TIMER_RESULT = createActionConst(TIMER_NAMESPACE, 'CLEAR_TIMER_RESULT');

export const CANCEL_EDIT = createActionConst(TIMER_NAMESPACE, 'CANCEL_EDIT');
