/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import Loader from 'react-loader-spinner';
import { boardSelectors } from '../../../../../../../../../../app/resource/boards/board/board.selectors';
import { taskSelectors } from '../../../../../../../../../../app/resource/task/task.selectors';
import { useApiRequestState } from '../../../../../../../../../../utils/api.utils';
import { useSelectWithParams } from '../../../../../../../../../../utils/selector.utils';
import { LabelForm } from '../../../../../../../../common/forms/LabelForm';

import { Label } from './Label';
import { ILabelMenuProps } from './types';

export const LabelMenu: FunctionComponent<ILabelMenuProps> = ({ dropRight, closeMenu, taskId }) => {
  const [createForm, setCreateForm] = useState(false);
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId);
  const board = useSelectWithParams(boardSelectors.selectResourceById, task?.boardId ?? '');

  const toggleCreate = () => {
    setCreateForm(!createForm);
  };
  const [showLoader] = useApiRequestState('updateTaskApi');
  return (
    task && (
      <div className={dropRight ? 'dropdown-menu drop-right' : 'dropdown-menu'}>
        <div className="mini-popup">
          <div className="mini-popup-header">
            Labels
            <span className="close_mini_popup" onClick={closeMenu}>
              ×
            </span>
          </div>
          <div className="mini-popup-body">
            <input type="text" className="small-input" placeholder="Search labels" />
            <label className="mb-1 mt-1 d-block">LABELS</label>
            {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
            {!showLoader && (
              <div className="list-labels">
                {board &&
                  board.labelIds.map((labelId) => (
                    <Label labelId={labelId} key={`labelId-${labelId}`} taskId={taskId} />
                  ))}
                <div className={createForm ? 'one-label edited' : 'one-label'}>
                  <a className="badge gray-bg darkGray jcl edit-label w100 mx-0 mt-1" onClick={toggleCreate}>
                    Create a new label
                  </a>
                  {toggleCreate && (
                    <div className="edit-label-container">
                      {createForm && <LabelForm onClick={toggleCreate} boardId={task.boardId} />}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};
