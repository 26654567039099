/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useState } from 'react';
import Avatar from 'react-avatar';
import { IMeetingRowProps } from './types';
import { useHistory } from 'react-router-dom';
import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { meetingSelectors } from '../../../../../app/resource/meeting/meeting.selectors';
import { UserAvatars } from '../UserAvatars';
import { useDispatch } from 'react-redux';
import { IMeetingCreateForm, IMeetingNewCreateForm } from '../../../../../app/resource/meeting/meeting.types';
import { openDialog } from '../../../../../app/dialog/dialog.actions';
import { deleteMeeting, updateMeeting } from '../../../../../app/page/page-storage/meetings/meetings.actions';
import { sessionSelectors } from '../../../../../app/resource/session/session.selectors';
import moment from 'moment';
import { agendaItemSelectors } from '../../../../../app/resource/agenda-item/agenda-item.selectors';
import slugify from 'react-slugify';

export const MeetingRow: FunctionComponent<IMeetingRowProps> = ({ meetingId }) => {
  const history = useHistory();

  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);
  const dispatch = useDispatch();
  const agendaItems = useSelectWithParams(
    agendaItemSelectors.selectResourcesById,
    meeting ? meeting.agendaItemIds : [],
  );
  const openMeetingForm = useCallback(() => {
    if (meeting) {
      dispatch(
        openDialog('meetingFormDialog', {
          type: meeting.type,
          data: meeting,
          onSubmit: (meetingInfo: IMeetingCreateForm) => {
            console.log(meeting, agendaItems);
            dispatch(
              updateMeeting(meeting.id, {
                ...meetingInfo,
                type: meeting.type,
                agendaItems: agendaItems,
              } as IMeetingNewCreateForm),
            );
            //dispatch(updateMeeting(meeting.id, { ...meetingInfo, type: meeting.type }));
          },
        }),
      );
    }
  }, [agendaItems, dispatch, meeting]);
  const sessionsIds = meeting ? meeting.nextSessionIds : [];
  const nextSessions = useSelectWithParams(sessionSelectors.selectResourcesById, sessionsIds);

  const onDeleteMeeting = useCallback(() => {
    if (meeting) {
      dispatch(deleteMeeting(meeting.id));
    }
  }, [dispatch, meeting]);

  const openAgendaFormDialog = useCallback(() => {
    dispatch(openDialog('agendaFormDialog', { meetingId }));
  }, [dispatch, meetingId]);

  const goToLiveMeeting = useCallback(() => {
    if (meeting && meeting.nextSessionIds && meeting.nextSessionIds[0]) {
      history.push(`/live-meeting/${meeting.id}/${meeting.nextSessionIds[0]}/0`);
    } else {
      alert('Next session doesn`t exist...');
    }
  }, [meeting, history]);

  const goToKpi = useCallback(() => {
    if (meeting) {
      history.push(`/kpi/${meeting.id}`);
    }
  }, [meeting, history]);

  const meetingShare = meeting?.shareWithIds ? meeting.shareWithIds : [];
  const meetingAdmins = meeting?.administratorIds ? meeting.administratorIds : [];

  const meetingRowMembers = Array.from(new Set([...meetingShare, ...meetingAdmins]));

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  const close = useCallback(() => setActive(false), [setActive]);

  return (
    meeting && (
      <div
        className="flex-table"
        role="rowgroup"
        onClick={() => history.push(`/meeting-dashboard/${meeting.id}/${slugify(meeting.title, { delimiter: '-' })}`)}
        style={{ cursor: 'pointer' }}
      >
        <div className="flex-row first" role="cell">
          <div className="account-item">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {meeting.image.length > 1 ? (
                <div className="big-avatar mr-05">
                  <img src={meeting.image} alt="Test" className="img-fluid" style={{ borderRadius: '50%' }} />
                </div>
              ) : (
                <Avatar
                  className="account-item-image f-2 bold"
                  title="Review KPIs"
                  name={meeting.title}
                  alt="review KPIs"
                  color={meeting.color}
                  size="50"
                  round={true}
                  style={{ cursor: 'pointer' }}
                />
              )}
              <div className="account-item-desc">
                <h3 className="account-item-title">{meeting.title}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row" role="cell">
          <UserAvatars userIds={meetingRowMembers} />
        </div>
        <div className="flex-row" role="cell">
          <div className="flex aic h100">
            {/*{meeting.startDate} {meeting.startTime}*/}
            {nextSessions.length === 0 ? '/' : moment(nextSessions[0]?.sessionDate).format('MM/DD/YYYY dddd, hh:mm A')}
          </div>
        </div>
        <div className="flex-row" role="cell">
          <div className="flex aic jcr h100">
            <span onClick={goToLiveMeeting} className="btn btn-md darkGray-bg mr-1">
              Start Meeting
            </span>
            <div
              className="round-icon-small mr-1"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                openMeetingForm();
                e.stopPropagation();
              }}
            >
              <i className="icon-pencil"></i>
            </div>
            <div
              className="round-icon-small mr-1"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                openAgendaFormDialog();
                e.stopPropagation();
              }}
            >
              AG
            </div>
            <div
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                goToKpi();
                e.stopPropagation();
              }}
              className="round-icon-small mr-0"
            >
              KPI
            </div>

            <div className={isActive ? 'dropdown opened' : 'dropdown'}>
              <div
                className="round-icon-small ml-1"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  toggleClass();
                  e.stopPropagation();
                }}
              >
                X
              </div>
              <div
                className="dropdown-menu drop-right drop-up max-h320"
                style={{ bottom: '-40%' }}
                onMouseLeave={(e: React.MouseEvent<HTMLElement>) => {
                  close();
                  e.stopPropagation();
                }}
              >
                <div className="mini-popup">
                  {/* <div className="mini-popup-header">
                    Delete meeting
                    <span
                      className="close_mini_popup"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        close();
                        e.stopPropagation();
                      }}
                    >
                      ×
                    </span>
                  </div> */}
                  <div className="mini-popup-body">
                    <p className="ln mb-2 darkGray">
                      Deleting a meeting is permanent and there is no way to get it back.
                    </p>
                    <a
                      className="btn btn-md red-bg white d-block jcc"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        onDeleteMeeting();
                        e.stopPropagation();
                      }}
                    >
                      Delete meeting
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
