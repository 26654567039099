import {
  IProjectMember,
  IProjectMemberCreate,
  IProjectMemberUpdate,
} from '../../resource/project-member/project-member.types';
import { IApiRequestConfig } from '../api.types';

export const searchProjectMembersApi = (week: string, accountId?: string): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'getProjectTeams',
  uri: 'json',
  action: 'getProjectTeams',
  method: 'get',
  params: { accountId, week },
});

export const createProjectMemberApi = (projectMember: IProjectMemberCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createProjectTeam',
  uri: 'json',
  action: 'saveProjectTeams',
  method: 'post',
  data: projectMember,
});

export const updateProjectMemberApi = (projectMember: IProjectMemberUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateProjectTeam',
  uri: 'json',
  action: 'saveProjectTeams',
  method: 'put',
  data: projectMember,
});

export const deleteProjectMemberApi = (projectMemberId: IProjectMember['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteProjectTeam',
  uri: 'json',
  action: 'deleteProjectTeams',
  method: 'delete',
  params: {
    id: projectMemberId,
  },
});
