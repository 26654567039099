/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { AttachmentForm } from '../../../../../../../../common/forms/AttachmentForm';

import { IAttachmentMenuProps } from './types';
import '../../../../';

export const AttachmentMenu: FunctionComponent<IAttachmentMenuProps> = ({ taskId }) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  //const close = useCallback(() => setActive(false), [setActive]);
  return (
    <div className={isActive ? 'dropdown opened' : 'dropdown'}>
      <a
        className="btn w100 gray-bg darkGray px-1 f-1 ml-0 mb-1 mt-1 dropdown-button"
        title="Attachment"
        onClick={toggleClass}
      >
        <img
          src={require('../../../../../../../../../../components/resources/images/attach.png')}
          className="mr-1"
          alt="img"
        />
        Attachment
      </a>
      <div className="dropdown-menu drop-right">
        <div className="mini-popup">
          <div className="mini-popup-header">
            Attach from…
            <span className="close_mini_popup" onClick={toggleClass}>
              ×
            </span>
          </div>
          <div className="mini-popup-body">
            <div className="list-attach">
              {/*<div className="one-attach">Computer</div>
              <div className="one-attach">Trello</div>
              <div className="one-attach">Google Drive</div>
              <div className="one-attach">Dropbox</div>
              <div className="one-attach">Box</div>
              <div className="one-attach">OneDrive</div>*/}
            </div>
            <hr className="my-1" />
            <AttachmentForm taskId={taskId} />
          </div>
        </div>
      </div>
    </div>
  );
};
