/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';

import { checklistItemSelectors } from '../../../../../../../../../../../app/resource/checklistItem/checklistItem.selectors';
import { useSelectWithParams } from '../../../../../../../../../../../utils/selector.utils';
import { Item } from './Item';

import { IChecklistItemsProps } from './types';

export const Items: FunctionComponent<IChecklistItemsProps> = ({ checkList }) => {
  const checklistItems = useSelectWithParams(checklistItemSelectors.selectResourcesById, checkList.checklistItemsIds);
  return (
    <div>
      <div className="checklist calendar-views">
        {checklistItems.map(
          (checklistItem) =>
            checklistItem && <Item checklistItem={checklistItem} key={`checklistItemId-${checklistItem.id}`} />,
        )}
      </div>
    </div>
  );
};
