import { combineReducers, Reducer } from 'redux';

import * as types from './notifications.types';
import * as constants from './notifications.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.INotificationPageState = {
  fetchedNotifications: [],
  isFetched: false,
  unseenCount: 0,
};

const fetchedNotificationsReducer: Reducer<
  types.INotificationPageState['fetchedNotifications'],
  types.IStoreFetchedNotifications | types.IClearFetchedNotifications
> = (state = initialState.fetchedNotifications, action) => {
  if (isActionOfType<types.IStoreFetchedNotifications>(action, constants.STORE_FETCHED_NOTIFICATIONS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearFetchedNotifications>(action, constants.CLEAR_FETCHED_NOTIFICATIONS)) {
    return initialState.fetchedNotifications;
  }
  return state;
};

const isFetchedReducer: Reducer<
  types.INotificationPageState['isFetched'],
  types.IStoreIsFetched | types.IClearIsFetched
> = (state = initialState.isFetched, action) => {
  if (isActionOfType<types.IStoreIsFetched>(action, constants.STORE_IS_FETCHED)) {
    return true;
  } else if (isActionOfType<types.IClearIsFetched>(action, constants.CLEAR_IS_FETCHED)) {
    return initialState.isFetched;
  }
  return state;
};

const unseenCountReducer: Reducer<
  types.INotificationPageState['unseenCount'],
  types.IStoreUnseenCount | types.IClearUnseenCount
> = (state = initialState.unseenCount, action) => {
  if (isActionOfType<types.IStoreUnseenCount>(action, constants.STORE_UNSEEN_COUNT)) {
    return action.payload;
  } else if (isActionOfType<types.IClearUnseenCount>(action, constants.CLEAR_UNSEEN_COUNT)) {
    return initialState.unseenCount;
  }
  return state;
};

export const notificationsPageReducer: Reducer<
  types.INotificationPageState,
  types.INotificationsPageAction
> = combineReducers({
  fetchedNotifications: fetchedNotificationsReducer,
  isFetched: isFetchedReducer,
  unseenCount: unseenCountReducer,
});
