/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { columnSelectors } from '../../../../app/resource/column/column.selectors';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { ISingleColumnProps } from './types';
import Photo from '../../../resources/images/photo-icon.svg';
import { Column } from '../../AgencyDashboardPage/Item/Columns/Column';
import { QuestionRow } from './QuestionRow';

export const SingleColumn: FunctionComponent<ISingleColumnProps> = ({ columnId }) => {
  const column = useSelectWithParams(columnSelectors.selectResourceById, columnId);

  return (
    <div className="row mb-25">
      <div className="col-12">
        <div className="panel vto-text">
          <div className="panel-header no-border">
            <h2 className="flex aic bold">
              {column?.title}
              <span
                className="flex link aic ml-05 popup_button"
                data-popup="tooltip_popup"
                data-tooltip_text="A business framework is a process and fundamental base of what operating strategies guide an bussines or organization. The choice of a business framework depends on the business, the organizational structure, the strategic planning and systems."
                data-tooltip_title="Buyer Personas"
              >
                <i className="icon-info blueGray f-10" />
              </span>
            </h2>
            <div className="ml-auto flex aic">
              <a className="btn btn-md btn-border white-bg" title="Print">
                <i className="icon2-print mr-1 f-0 blueGray" />
                Print
              </a>
              <a className="btn btn-md darkGray-bg" title="Export">
                Export
              </a>
            </div>
          </div>
          <div className="panel-body pt-0">
            <div className="border-radius-1 lightGray-bg p-2">
              {/* <div className="panel white-bg flex px-2 py-1 mb-1">
                <div className="week-timer-buttons flex mr-2">
                  <a href="agency_tools.php" className="btn btn-md white-bg btn-border px-1">
                    <i className="icon-left-arrow blueGray f-10" /> Back
                  </a>
                  <div className="field-container custom-select small-select header-select mb-0 ml-1">
                    <input type="hidden" name defaultValue="Folder 2" />
                    <span className="select-field h32 border-radius-04">
                      <span className="select-value">
                        Tool: Buyer Personas <i className="icon-down-dir" />
                      </span>
                    </span>
                    <div className="select-dropdown" style={{ boxSizing: 'border-box', display: 'none' }}>
                      <div className="select-item" data-val="Company Vision">
                        Company Vision
                      </div>
                      <div className="select-item selected" data-val="Company Vision">
                        Company Vision
                      </div>
                      <div className="select-item" data-val="Company Vision">
                        Company Vision
                      </div>
                      <div className="select-item" data-val="Company Vision">
                        Company Vision
                      </div>
                      <div className="select-item" data-val="Company Vision">
                        Company Vision
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-auto flex aic jcr">
                  <a className="btn btn-md white-bg btn-border px-1" title>
                    Reset Template to Default
                  </a>
                </div>
              </div> */}
              <div className="row mb-1">
                <div className="col-12">
                  <div className="panel">
                    <div className="panel-body p-4">
                      <div className="row">
                        <div className="col-3">
                          <div className="profile-image">
                            <img src={column?.image} alt="" className="single-persona-img" />
                            <a className="photo-change-icon blueGray-bg">
                              <img src={Photo} alt="" className="img-fluid" />
                            </a>
                          </div>
                        </div>
                        <div className="col-9 pl-4">
                          {column?.questionIds.map((questionId) => (
                            <>
                              <QuestionRow questionId={questionId} />
                            </>
                          ))}

                          <div
                            className="flex aic mb-1"
                            style={{
                              padding: `0 15px`,
                            }}
                          >
                            <div className="profile-input">
                              <button className="btn btn-border white-bg darkGray min-h50 text-left border-2x px-1 no-shadow px-2 w100 mx-0 f-14">
                                + Add Demo Property
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {column?.childrenIds.map((columnId) => (
                  <>
                    <Column columnId={columnId} singlePage={true} />
                  </>
                ))}
              </div>
              <div className="row mb-1">
                <div className="col-12">
                  <div className="panel">
                    <div className="panel-body p-2">
                      <div className="row mb-0">
                        <div className="col-6">
                          <div className="panel green-gradient-right py-1 px-2 flex aic jcsb mb-2 no-shadow">
                            <h2 className="bold white lh-13 flex aic jcsb">NEW CATEGORY</h2>
                          </div>
                          <div className="flex aic mb-1">
                            <div className="profile-input">
                              <button className="btn btn-border white-bg darkGray min-h50 text-left border-2x px-1 no-shadow px-2 w100 mx-0 f-14">
                                + Add New Property
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="profile-input">
                            <button className="btn btn-border white-bg h120 text-left border-2x px-1 no-shadow px-2 w100 mx-0 f-4 text-center aic jcc blueGray">
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
