/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ICustomNodeProps } from './types';
import { NodeHeader } from './NodeHeader';
import { NodeBody } from './NodeBody';
import { NodeFooter } from './NodeFooter';
import '../../../../resources/styles/theme.scss';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../app/dialog/dialog.actions';
import { deleteNode, updateNode } from '../../../../../app/page/page-storage/chart/chart.actions';
import { IOrganizationChartNode } from '../../../../../app/resource/node/node.types';
import { IUser } from '../../../../../app/resource/user/user.types';

const getRandomInt = (max: number) => Math.floor(Math.random() * max);

export const CustomNode: FunctionComponent<ICustomNodeProps> = ({ nodeData }) => {
  const dispatch = useDispatch();

  const [droppedNode, setDroppedNode] = useState(false);

  const oldChildren = useRef<IOrganizationChartNode['id'][]>([]);

  const dropNode = () => {
    setDroppedNode(true);
    oldChildren.current = nodeData.children ? nodeData.children.map((child) => child.id) : [];
  };

  useEffect(() => {
    if (droppedNode) {
      setDroppedNode(false);
      const droppedNode = nodeData.children
        ? nodeData.children.find((node) => !oldChildren.current.includes(node.id))
        : undefined;

      if (droppedNode) {
        dispatch(
          updateNode(droppedNode.id, {
            user: { value: droppedNode.userId, label: '' },
            parentId: nodeData.id,
            description: droppedNode.description,
            coworkers: droppedNode.coworkers.map((coworker) => ({ value: coworker, label: '' })),
          }),
        );
      }
    }
  }, [nodeData, droppedNode, dispatch]);

  const randColor = useMemo(() => {
    const colors = ['yellow-bg', 'blueGray-bg', 'green-bg', 'blue-bg'];
    return colors[getRandomInt(colors.length)];
  }, []);

  const openAddNodeFormDialog = useCallback(() => {
    dispatch(openDialog('nodeFormDialog', { parentId: nodeData.id }));
  }, [dispatch, nodeData.id]);

  const openEditNodeFormDialog = useCallback(() => {
    dispatch(openDialog('nodeFormDialog', { nodeData }));
  }, [dispatch, nodeData]);

  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  const close = useCallback(() => setActive(false), [setActive]);
  const onDeleteNode = useCallback(() => {
    if (!nodeData.children) {
      dispatch(deleteNode(nodeData.id));
    } else {
      alert('Only leaves can be deleted!');
    }
  }, [dispatch, nodeData]);

  const onDeleteUserFromNode = (userId: IUser['id']) => {
    dispatch(
      updateNode(nodeData.id, {
        user: { value: nodeData.userId, label: '' },
        parentId: nodeData.parentId,
        description: nodeData.description,
        coworkers: nodeData.coworkers
          .filter((coworker) => userId !== coworker)
          .map((coworker) => ({ value: coworker, label: '' })),
      }),
    );
  };

  return (
    <div className={`orgchart-box ${randColor}`} onDrop={dropNode} style={{ height: '290px' }}>
      <div className="orgchart-box-header">
        <NodeHeader userId={nodeData.userId} />
        <div
          className={isActive ? 'orgchart-box-options dropdown opened' : 'orgchart-box-options dropdown'}
          onClick={toggleClass}
          onMouseLeave={close}
        >
          <div className={`round-icon-smaller dropdown-button ${randColor}`}>
            <i className="icon-pencil white f-0" />
          </div>
          <div className="dropdown-menu">
            <div className="drop-flex">
              <a className="drop-item" onClick={openAddNodeFormDialog}>
                Add Seat
              </a>

              <a className="drop-item" onClick={openEditNodeFormDialog}>
                Edit Seat
              </a>

              <a className="drop-item" onClick={onDeleteNode}>
                Delete Seat
              </a>

              <a className="drop-item" onClick={openEditNodeFormDialog}>
                Add User
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="orgchart-box-body">
        <div className="orgchart-body-list">
          <NodeBody description={nodeData.description} />
        </div>
        <div className="orgchart-body-users">
          <div className="orgchart-body-separator" />
          <NodeFooter userIds={nodeData.coworkers} nodeId={nodeData.id} onUserDelete={onDeleteUserFromNode} />
        </div>
      </div>
    </div>
  );
};
