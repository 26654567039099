import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { IProjectMemberUpdate } from '../../../../../../../app/resource/project-member/project-member.types';

import { userSelectors } from '../../../../../../../app/resource/user/user.selectors';
import { useSelect } from '../../../../../../../utils/selector.utils';
import { ProjectMember } from './ProjectMember';

import { IProjectTeamFormProps } from './types';

export const ProjectTeamForm: FunctionComponent<IProjectTeamFormProps> = ({ projectMembers }) => {
  const { control, setError, clearErrors, errors } = useFormContext();
  const { fields, append, remove } = useFieldArray<IProjectMemberUpdate>({ control, name: 'teams' });
  const [user, setUser] = useState('');
  const [userName, setUserName] = useState('Assign a Member…');
  const [active, setActive] = useState(false);
  const [removeRender, setRemoveRender] = useState(false);

  const addMember = useCallback(() => {
    append({ userId: user, id: undefined });

    setUser('');
    setUserName('Assign a Member…');
    clearErrors('teams');
  }, [append, clearErrors, user]);

  const removeMember = useCallback(
    (index) => {
      setRemoveRender(true);
      if (fields.length > 0) {
        remove(index);
      } else {
        setError('teams', { type: 'minLength', message: 'You need to add at least one entry' });
      }
    },
    [fields.length, remove, setError],
  );

  useEffect(() => {
    if (projectMembers) {
      if (projectMembers.length > 0 && fields.length < projectMembers.length && !removeRender) {
        append(projectMembers);
      }
    }
  }, [append, fields.length, projectMembers, removeRender]);
  const users = useSelect(userSelectors.selectResourceList);

  const memberOptions = useMemo(
    () =>
      users
        .map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }))
        .filter((user) => !fields.map((field) => field.userId).includes(user.value)),
    [fields, users],
  );

  return (
    <>
      {fields.map(
        (field, index) =>
          field && (
            <div className="task-row" key={index}>
              {field.userId && (
                <ProjectMember removeMember={removeMember} index={index} projectMember={field} userId={field.userId} />
              )}
            </div>
          ),
      )}
      <div className="row mt-3 mb-2">
        <div className="col-6">
          <div className="custom-select small-select" onClick={() => setActive(!active)}>
            <input type="hidden" id="member" />
            <span className={`select-field ${active ? 'active' : ''}`}>
              <span className="select-value">{userName}</span> <i className="icon-down-dir" />
            </span>
            <div
              className="select-dropdown"
              style={
                active ? { display: 'block', boxSizing: 'border-box' } : { display: 'none', boxSizing: 'border-box' }
              }
            >
              {memberOptions.map((option) => (
                <option
                  className="select-item"
                  key={option.value}
                  value={option.value}
                  onClick={() => {
                    setUser(option.value);
                    setUserName(option.label);
                  }}
                >
                  {option.label}
                </option>
              ))}
            </div>
          </div>
        </div>
        <div className="col-3 pl-0">
          <button
            disabled={!user}
            type="button"
            className="btn text-center darkGray-bg white max70"
            title="Add"
            onClick={addMember}
          >
            Add
          </button>
        </div>
      </div>

      {!!errors.members?.message && (
        <div>
          <p>{errors.members.message}</p>
        </div>
      )}
    </>
  );
};
