import { call } from 'redux-saga/effects';

import * as pageStateService from '../page-state/page-state.saga-services';
import { setupAccountProjects } from '../page-storage/account-projects/account-projects.saga-services';

import * as pageInitializerService from '../page.initializer.saga-service';

export function* initialTimeTrackingPage() {
  yield call(pageStateService.initPage, 'timeTracking');
  if (!((yield call(pageInitializerService.initPage)) as boolean)) {
    yield call(setupAccountProjects);
  }

  yield call(pageStateService.completedInit, 'timeTracking');
}
