import { call, takeLatest } from 'redux-saga/effects';
import * as constants from './boards.constants';
import * as types from './boards.types';
import * as services from './boards.saga-services';

function* invokeInitialBoardsPage(action: types.IInitialBoardsPage) {
  yield call(services.initialBoardsPage, action.payload);
}

export function* watchBoardsPageSaga() {
  yield takeLatest(constants.INITIAL_BOARDS_PAGE, invokeInitialBoardsPage);
}
