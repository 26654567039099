/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../app/dialog/dialog.actions';
import { ISectionFormProps } from '../../../pages/VtoPage/Section/SectionForm/types';

export const SectionFormTrigger: FunctionComponent<ISectionFormProps> = ({ templateId, data, onSubmit }) => {
  const dispatch = useDispatch();

  const openScoreFormDialog = useCallback(() => {
    dispatch(
      openDialog('sectionFormDialog', {
        templateId,
        onSubmit,
        data,
      }),
    );
  }, [data, dispatch, onSubmit, templateId]);

  return (
    <a className="btn btn-md green-bg popup_button" title="Add Section" onClick={openScoreFormDialog}>
      <i className="icon-plus mr-1"></i> Add Section
    </a>
  );
};
