import { IEventSearchParams } from '../../resource/event/event.types';
import { IApiRequestConfig } from '../api.types';

export const getEventsApi = (params: IEventSearchParams): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'getEvents',
  uri: 'json',
  action: 'getCalendarEvents',
  method: 'get',
  params,
});
