/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { itemSelectors } from '../../../../app/resource/item/item.selectors';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { Columns } from './Columns';
import { ImgColumns } from './ImgColumns';
import { ItemHeader } from './ItemHeader';
import { IItemProps } from './types';

export const Item: FunctionComponent<IItemProps> = ({ itemId }) => {
  const item = useSelectWithParams(itemSelectors.selectResourceById, itemId);
  console.log(item);
  return (
    <div className="row mb-25">
      <div className="col-12">
        <div className="panel vto-text">
          {item && <ItemHeader title={item.title} description={item.description} />}
          <div className="panel-body pt-0">
            {item && item.default === '0' ? (
              <Columns columnIds={item.columnIds} showSaveButton={item.showSaveButton} />
            ) : (
              item && <ImgColumns columnIds={item.columnIds} showSaveButton={item.showSaveButton} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
