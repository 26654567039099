import { all, call, fork, takeLatest } from 'redux-saga/effects';

import * as constants from './projects.constants';
import * as types from './projects.types';
import * as services from './projects.saga-services';

function* invokeSetupProject(action: types.ISetupProject) {
  yield call(services.setupProject, action.payload.accountId, action.payload.projectId);
}

function* invokeCreateProject(action: types.ICreateProject) {
  yield call(services.createProject, action.payload);
}

function* invokeUpdateProject(action: types.IUpdateProject) {
  yield call(services.updateProject, action.payload.id, action.payload.data);
}
function* invokeDeleteProject(action: types.IDeleteProject) {
  yield call(services.deleteProject, action.payload.projectId, action.payload.accountId);
}
/*
function* invokeSaveProjectSocket(action: types.ISaveProjectSocket) {
  yield call(services.handleProjectFromNotification, action.payload);
}
*/
export function* watchProjectsPurePageSaga() {
  yield takeLatest(constants.SETUP_PROJECT, invokeSetupProject);
  yield takeLatest(constants.CREATE_PROJECT, invokeCreateProject);
  yield takeLatest(constants.UPDATE_PROJECT, invokeUpdateProject);
  yield takeLatest(constants.DELETE_PROJECT, invokeDeleteProject);

  //yield takeLatest(constants.SAVE_PROJECT__SOCKET, invokeSaveProjectSocket);
}

export function* watchProjectsPageStorageSaga() {
  yield all([watchProjectsPurePageSaga].map(fork));
}
