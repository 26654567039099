/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { IProjectFormProps } from './types';
import { ProjectDetailsForm } from './ProjectDetailsForm';
import { ProjectTypeForm } from './ProjectTypeForm';
import { PermissionsForm } from './PermissionsForm';

import { FormProvider, useForm } from 'react-hook-form';
import { ProjectTasks } from './ProjectTasks';
import { ProjectTeam } from './ProjectTeam';

import { useDispatch } from 'react-redux';
import {
  createProject,
  deleteProject,
  updateProject,
} from '../../../../../app/page/page-storage/projects/projects.actions';
import { IProject, IProjectCreateForm } from '../../../../../app/resource/project/project.types';
import { IAccountProject } from '../../../../../app/resource/account-project/account-project.types';
import { closeDialog } from '../../../../../app/dialog/dialog.actions';
import { SubmitButton } from '../SubmitButton';

export const ProjectForm: FunctionComponent<IProjectFormProps> = ({ showProjectForm, projectData }) => {
  const formProps = useForm();
  const focusHourlyTuple = formProps.watch('hourlyRate');
  const focusBudgetTuple = formProps.watch('budgetType');
  const focusChecked = formProps.watch('budgetEmailExceeds');

  const dispatch = useDispatch();

  const onSubmit = (data: IProjectCreateForm, projectId: IProject['id'] | undefined) => {
    if (!projectId) {
      dispatch(createProject({ ...data }));
    } else {
      dispatch(updateProject(projectId, { ...data }));

      dispatch(closeDialog('projectFormDialog'));
    }
    showProjectForm && showProjectForm(false);
  };

  const onDelete = useCallback(
    (projectId: IProject['id'], accountId: IAccountProject['id']) => {
      dispatch(deleteProject(projectId, accountId));
    },
    [dispatch],
  );

  return (
    <>
      <FormProvider {...formProps}>
        <form
          id="project-form"
          className="topic-form"
          onSubmit={formProps.handleSubmit((formData: IProjectCreateForm) => onSubmit(formData, projectData?.id))}
          method="post"
        >
          <ProjectDetailsForm projectData={projectData} />
          <ProjectTypeForm
            focusHourlyTuple={focusHourlyTuple}
            focusBudgetTuple={focusBudgetTuple}
            focusChecked={focusChecked}
            projectData={projectData}
          />
          <PermissionsForm projectData={projectData} />
          <ProjectTasks projectData={projectData} />
          <ProjectTeam projectData={projectData} />
        </form>
      </FormProvider>

      <div className="bottom-buttons flex aic jcsb">
        <SubmitButton apiKey="createProject" formName="project-form" text="Save" />

        {projectData && (
          <a
            className="link link-red red f-1"
            title="Delete project"
            onClick={() => onDelete(projectData.id, projectData.accountId)}
          >
            Delete project
          </a>
        )}
      </div>
    </>
  );
};
