import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './widgets.constants';
import * as types from './widgets.types';
import * as services from './widgets.saga-services';

function* invokeDndWidte(action: types.IDndWidget) {
  yield call(services.dndWidget, action.payload);
}

export function* watchWidgetsPageSaga() {
  yield takeLatest(constants.DND_WIDGET, invokeDndWidte);
}
