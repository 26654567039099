import React, { FunctionComponent } from 'react';
import { IFooterProps } from '../../types';

export const RememberMe: FunctionComponent<IFooterProps> = ({ setRememberUser }) => {
  return (
    <>
      <input type="checkbox" name="" id="remember-me" onChange={(e) => setRememberUser(e.target.checked)} />
      <label htmlFor="remember-me">Remember me</label>
    </>
  );
};
