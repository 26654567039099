import { call, put, select } from 'redux-saga/effects';

import * as actions from './meetings-info.actions';

import * as meetingInfoResourceService from '../../../resource/meeting-info/meeting-info.saga-service';

import { meetingsInfoPageSelectors } from './meetings-info.selectors';
import { IReducedMeetingsInfo } from './meetings-info.types';
import { IMeetingInfo } from '../../../resource/meeting-info/meeting-info.types';
import { meetingInfoSelectors } from '../../../resource/meeting-info/meeting-info.selectors';

// import * as apiService from '../../api/api.saga-services';

export function* reduceAndStoreMeetingsInfo(meetingsInfo: IMeetingInfo[]) {
  if (!meetingsInfo) {
    return;
  }
  yield put(
    actions.storeReducedMeetingsInfo(
      meetingsInfo.reduce((prev, curr) => {
        if (!prev[curr.type]) {
          prev[curr.type] = [];
        }
        prev[curr.type] = [...prev[curr.type], curr.id];
        return { ...prev };
      }, {} as IReducedMeetingsInfo),
    ),
  );
}

export function* clearReducedMeetingsInfo() {
  yield put(actions.clearReducedMeetingsInfo());
}

export function* setupMeetingsInfo() {
  const isFetched = (yield select(meetingsInfoPageSelectors.selectIsFetched)) as boolean;

  let meetingsInfo: IMeetingInfo[] = [];

  if (!isFetched) {
    meetingsInfo = (yield call(meetingInfoResourceService.searchMeetingsInfo)) as IMeetingInfo[];
    yield put(meetingsInfo ? actions.storeIsFetched() : actions.clearIsFetched());
  } else {
    meetingsInfo = yield select(meetingInfoSelectors.selectResourceList);
  }
  yield call(reduceAndStoreMeetingsInfo, meetingsInfo);
}

export function* putMeetingsInfo() {
  const meetingsInfo = (yield select(meetingInfoSelectors.selectResourceList)) as IMeetingInfo[];
  yield call(reduceAndStoreMeetingsInfo, meetingsInfo);
}

export function* clearMeetingsInfoPageStorage() {
  yield put(actions.clearReducedMeetingsInfo());
  yield put(actions.clearIsFetched());
}
