import { IApiRequestConfig } from '../api.types';
import { ISection, ISectionCreate, ISectionUpdate } from '../../resource/section/section.types';

export const createSectionApi = (section: ISectionCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createSection',
  uri: 'json',
  action: 'saveSection',
  method: 'post',
  data: section,
});

export const updateSectionApi = (section: ISectionUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateSection',
  uri: 'json',
  action: 'saveSection',
  method: 'put',
  data: section,
});

export const deleteSectionApi = (sectionId: ISection['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteSection',
  uri: 'json',
  action: 'deleteSection',
  method: 'delete',
  params: { id: sectionId },
});
