import { all, call, put, select } from 'redux-saga/effects';

import * as types from './user.types';
import * as actions from './user.actions';
import { apiRequest } from '../../api/api.saga-services';

import * as api from '../../api/request-config/user.api';
import * as signupApi from '../../api/request-config/signup.api';
import * as taskService from '../task/task.saga-service';
import * as sessionService from '../session/session.saga-service';

import { selectWithParams } from '../../../utils/selector.utils';
import { userSelectors } from './user.selectors';
import { ITask } from '../task/task.types';
import { ISession } from '../session/session.types';
import * as auth from '../../auth/auth.saga-services';
import moment from 'moment';
import { serializeDate } from '../../../utils/date.utils';

export function* storeUser(user: types.IUser) {
  yield put(actions.storeUser(user));
  return user;
}

export function* clearUser(userId: types.IUser['id']) {
  yield put(actions.clearUser(userId));
}
export function* clearAllUsers() {
  const userIds: types.IUser['id'][] = yield select(userSelectors.selectResourceIds);
  yield put(actions.clearUser(userIds));
}
/* This method sends api request and handle response */
export function* searchUsers(searchParams?: types.IUserSearchParams) {
  const users = (yield call(apiRequest, api.searchUserApi(searchParams))) as types.IUser[];
  users.sort((a, b) => (a.firstName < b.firstName ? -1 : 1));

  if (!users) {
    return;
  }

  yield all(users.map((user) => call(storeUser, { ...user, taskIds: null, sessionIds: null })));
  return users;
}

export function* populateTasks(userId: types.IUser['id']) {
  let user = (yield selectWithParams(userSelectors.selectResourceById, userId)) as types.IUser;

  const taskIds = (yield call(taskService.searchTasks, { userId })) as ITask['id'][];

  user = yield call(storeUser, { ...user, taskIds });

  return user;
}

export function* setTasks(userId: types.IUser['id'], taskIds: ITask['id'][]) {
  const user = (yield selectWithParams(userSelectors.selectResourceById, userId)) as types.IUser;

  if (!user) {
    return;
  }
  yield call(storeUser, { ...user, taskIds });

  return user;
}

export function* populateSessions(userId: types.IUser['id']) {
  let user = (yield select(userSelectors.selectResourceById, userId)) as types.IUser;
  if (!user) {
    return;
  }
  if (!user.sessionIds) {
    const sessionIds = (yield call(sessionService.getSessions, { userId })) as ISession['id'][];
    user = yield call(storeUser, { ...user, sessionIds });
  }
  return user;
}

export function* setSessions(userId: types.IUser['id'], sessionIds: ISession['id'][]) {
  const user = (yield select(userSelectors.selectResourceById, userId)) as types.IUser;
  if (!user) {
    return;
  }
  yield call(storeUser, { ...user, sessionIds });
}

export function* createUser(data: types.IUserCreate) {
  const user = (yield call(apiRequest, api.createUserApi(data))) as types.IUser;
  if (!user) {
    return;
  }
  yield call(storeUser, user);
  return user.id;
}

export function* updateUser(data: types.IUserUpdate) {
  const user = (yield call(apiRequest, api.updateUserApi(data))) as types.IUser;
  if (!user) {
    return;
  }
  yield call(storeUser, user);
  return user.id;
}

export function* deleteUser(userId: types.IUser['id']) {
  yield call(apiRequest, api.deleteUserApi(userId));
}

// export function* deleteAccount(clientId: IClient['id'], accountId: types.IAccount['id']) {
//   const account = (yield call(apiRequest, api.deleteAccountApi(clientId, accountId))) as types.IAccount;

//   if (!account) {
//     return;
//   }

//   yield call(clearAccount, accountId);
//   return accountId;
// }
export function* signUpUser(data: types.IUserSignUp) {
  const result = (yield call(apiRequest, signupApi.signUpUserApi(data))) as { result: types.IUserWithTokens };
  const user = result.result;
  if (!result) {
    return;
  }

  yield call(auth.logUserIn, user);
  yield call(auth.cacheLoggedUser, user, true, serializeDate(moment().add(60, 'days')));
  yield call(storeUser, user);
  return user.id;
}
