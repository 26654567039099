/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useState } from 'react';
import { AccountsTab } from './AccountsTab';
import { TeamTab } from './TeamTab';
import { TimeTab } from './TimeTab';

export const Tabs: FunctionComponent = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="row mb-25">
      <div className="col-12">
        <div className="panel">
          <div className="panel-header small-header no-border">
            <div className="time-tabs">
              <h2
                className={`${activeTab === 0 ? 'current' : ''}`}
                onClick={() => setActiveTab(0)}
                style={{ cursor: 'pointer' }}
              >
                <a>Time</a>
              </h2>
              <h2
                className={`${activeTab === 1 ? 'current' : ''}`}
                onClick={() => setActiveTab(1)}
                style={{ cursor: 'pointer' }}
              >
                <a>Accounts</a>
              </h2>
              <h2
                className={`${activeTab === 2 ? 'current' : ''}`}
                onClick={() => setActiveTab(2)}
                style={{ cursor: 'pointer' }}
              >
                <a>Team</a>
              </h2>
              <h2
                className={`${activeTab === 3 ? 'current' : ''}`}
                onClick={() => setActiveTab(3)}
                style={{ cursor: 'pointer' }}
              >
                <a>Reports</a>
              </h2>
            </div>
          </div>
          <div className="panel-body pt-0">
            <div className="border-radius-1 lightGray-bg p-2">{activeTab === 0 && <TimeTab />}</div>
            <div className="border-radius-1 lightGray-bg p-2">{activeTab === 1 && <AccountsTab />}</div>
            <div className="border-radius-1 lightGray-bg p-2">{activeTab === 2 && <TeamTab />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
