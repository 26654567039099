import moment from 'moment';
import { IScope } from '../app/resource/calendar-item/calendar-item.types';

export const FORMAT_DATE = 'MMM Do, YYYY';
export const SERIALIZE_DATE = 'YYYYMMDD';
export const MONTH_FORMAT = 'MMMM, YYYY';
export const DAY_MONTH_FORMAT = 'MMM Do.';

export const FULL_FORMAT = 'MM/DD/YYYY dddd, hh:mma';
export const HOUR_FORMAT = 'hh:mma';

export const SEARCH_FORMAT = 'YYYY-MM-DD';
export const DASH_MONTH_FORMAT = 'YYYY-MM';
export const FORMAT_FOR_TIMER = 'MM/DD @ HH:mma';

export const US_FORMAT = 'MMMM DD, YYYY';

export const formatDate = (date: moment.MomentInput): string => moment(date).format(FORMAT_DATE);
export const formatDateUS = (date: moment.MomentInput): string => moment(date).format(US_FORMAT);
export const serializeDate = (date: moment.MomentInput): string => moment(date).format(SERIALIZE_DATE);
export const formatMonth = (date: moment.MomentInput): string => moment(date).format(MONTH_FORMAT);
export const formatDayMonth = (date: moment.MomentInput): string => moment(date).format(DAY_MONTH_FORMAT);
export const formatHour = (date: moment.MomentInput): string => moment(date).format(HOUR_FORMAT);
export const formatSearch = (date: moment.MomentInput): string => moment(date).format(SEARCH_FORMAT);
export const dashMonth = (date: moment.MomentInput): string => moment(date).format(DASH_MONTH_FORMAT);
export const formatForTimer = (date: moment.MomentInput): string => moment(date).format(FORMAT_FOR_TIMER);

export const formatFull = (date: moment.MomentInput): string => moment(date).format(FULL_FORMAT);

export const formatDay = (date: moment.MomentInput): string =>
  ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'][moment(date).day()];

export const difference = (begin: moment.MomentInput, end: moment.MomentInput): string => {
  const duration = moment.duration(moment(end).diff(moment(begin)));
  if (duration.asMinutes() < 60) {
    return `${Math.ceil(duration.asMinutes())} ${Math.ceil(duration.asHours()) === 1 ? 'minute' : 'minutes'}`;
  }
  if (duration.asHours() < 24) {
    return `${Math.ceil(duration.asHours())} ${Math.ceil(duration.asHours()) === 1 ? 'hour' : 'hours'}`;
  }
  return `${Math.ceil(duration.asDays())} ${Math.ceil(duration.asDays()) === 1 ? 'day' : 'days'}`;
};

export const hourDuration = (start: moment.MomentInput, end: moment.MomentInput) => {
  const duration = moment.duration(moment(start).diff(end));
  return Math.ceil(duration.asHours());
};

export const generateDatesForScope = (from: moment.MomentInput, scope: IScope) => {
  if (scope === 'month') {
    return Array.from({ length: moment(from).daysInMonth() }, (x, i) =>
      moment(from).startOf('month').add(i, 'days').format('YYYYMMDD'),
    );
  } else if (scope === 'week') {
    return Array.from({ length: 7 }, (x, i) => moment(from).startOf('isoWeek').add(i, 'days').format('YYYYMMDD'));
  }
  return [serializeDate(from)];
};

export const nextDate = (date: moment.MomentInput) => moment(date).add(1, 'day');
export const prevDate = (date: moment.MomentInput) => moment(date).add(-1, 'day');
export const nextWeek = (date: moment.MomentInput) => moment(date).add(1, 'week');
export const prevWeek = (date: moment.MomentInput) => moment(date).add(-1, 'week');
export const nextMonth = (date: moment.MomentInput) => moment(date).add(1, 'month');
export const prevMonth = (date: moment.MomentInput) => moment(date).add(-1, 'month');

export const addHours = (date: moment.MomentInput, hours: number) => moment(date).add(hours, 'hour');

export const getDaysOfMonth = function (yearMonth: string) {
  const daysInMonth = moment(yearMonth, DASH_MONTH_FORMAT).daysInMonth();
  const arrayDays = [];
  for (let i = 1; i <= daysInMonth; i++) {
    if (i < 10) {
      arrayDays.push(`${yearMonth}-0${i}`);
    } else {
      arrayDays.push(`${yearMonth}-${i}`);
    }
  }
  return arrayDays;
};

export const getDaysOfWeek = (current: string) => generateDatesForScope(current, 'week');

export const calendarWeekFormat = (date: string) => {
  const daysOfWeek = getDaysOfWeek(date);
  return `${formatDate(daysOfWeek[0])} - ${formatDate(daysOfWeek[6])}`;
};

export const getHoursForDate = (date: string) =>
  [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((hour) => addHours(date, hour));
