import { put, call, all } from 'redux-saga/effects';
import { IMeeting } from '../../resource/meeting/meeting.types';

import * as actions from './timer.actions';
import { ITimerSave, ITimerSaveForm, ITimerStatus } from './timer.types';

import * as projectTaskService from '../../resource/project-task/project-task.saga-service';
import { IProjectTask } from '../../resource/project-task/project-task.types';

import * as api from './../../api/request-config/timer.api';
import { apiRequest } from '../../api/api.saga-services';
import { select, selectWithParams } from '../../../utils/selector.utils';
import { meetingSelectors } from '../../resource/meeting/meeting.selectors';
import { IProjectTasksTime } from '../../resource/project-time/project-time.types';
import { projectTimeSelectors } from '../../resource/project-time/project-time.selectors';
import { timerSelectors } from './timer.selectors';
import { storeProjectTime } from '../../resource/project-time/project-time.actions';

export function* toggleTimer(isOpen: boolean) {
  if (isOpen) {
    yield put(actions.storeIsOpen());
  } else {
    yield put(actions.clearIsOpen());
  }
}

export function* changeTime(time: number) {
  yield put(actions.storeTime(time));
}

export function* changeTimerStatus(status: ITimerStatus, timerSaveForm?: ITimerSaveForm) {
  yield put(actions.storeTimerStatus(status));
  if (timerSaveForm) {
    yield put(actions.storeProjectTask(timerSaveForm.projectTask.value));
    yield put(actions.storeClient(timerSaveForm.account.value));
    yield put(actions.storeNote(timerSaveForm.note ?? ''));
  }

  if (status === 'STARTED_TIMER') {
    yield all([put(actions.clearProjectTask()), put(actions.clearClient()), put(actions.clearNote())]);
  }
}

export function* chooseClient(meetingId: IMeeting['id'], external?: true) {
  if (external) {
    const meeting = (yield selectWithParams(meetingSelectors.selectResourceById, meetingId)) as IMeeting;
    if (!meeting || meeting.type !== 'account') {
      return;
    }
    yield put(actions.storeClient(meeting.id));
    yield put(actions.storeIsOpen());
  } else {
    const projectTaskIds = (yield call(projectTaskService.searchProjectTasks, {
      accountId: meetingId,
    })) as IProjectTask['id'][];

    yield put(projectTaskIds ? actions.storeProjectTasks(projectTaskIds) : actions.clearProjectTasks());
  }
}

export function* saveTimerResult(timerResult: ITimerSave) {
  const timerResultId = (yield select(timerSelectors.selectTimerResult)) as IProjectTasksTime['id'] | null;
  const newResult = (yield call(
    apiRequest,
    timerResultId ? api.saveTimerApi({ id: timerResultId, ...timerResult }) : api.saveTimerApi(timerResult),
  )) as IProjectTasksTime;
  yield all([
    put(actions.clearProjectTask()),
    put(actions.clearClient()),
    put(actions.clearNote()),
    put(actions.clearTimerResult()),
  ]);

  if (timerResultId) {
    yield put(actions.clearIsOpen());
  }

  if (!newResult) {
    return newResult;
  }
  yield put(storeProjectTime(newResult));
}

export function* cancelEdit() {
  yield all([
    put(actions.clearProjectTask()),
    put(actions.clearClient()),
    put(actions.clearNote()),
    put(actions.clearTimerResult()),
    put(actions.clearIsOpen()),
  ]);
}

export function* chooseTimerResult(timerResultId: IProjectTasksTime['id']) {
  yield put(actions.storeIsOpen());
  yield put(actions.storeTimerResult(timerResultId));

  const timerResult = (yield selectWithParams(
    projectTimeSelectors.selectResourceById,
    timerResultId,
  )) as IProjectTasksTime;

  yield put(actions.storeClient(timerResult.meetingId));
  yield put(actions.storeProjectTask(timerResult.projectId));
  // yield put(actions.storeNote(timerResult.note ?? ''));
}
