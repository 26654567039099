/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';
import Loader from 'react-loader-spinner';

import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';

import { useApiRequestState } from '../../../utils/api.utils';

import { SignUpForm } from '../../pages/common/forms/SignUpForm';
import { SubmitButton } from '../../pages/common/forms/SubmitButton';
import { ConnectedDialog } from '../ConnectedDialog';

export const SignUpFormDialog: FunctionComponent = () => {
  const dispatch = useDispatch();

  const [showLoader, error] = useApiRequestState('signUpUser');
  const onClose = useCallback(() => {
    dispatch(closeDialog('signUpFormDialog'));
  }, [dispatch]);
  return (
    <ConnectedDialog dialogKey="signUpFormDialog">
      <div className="popup new_board_popup" style={{ display: 'block' }}>
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            <h3>Sign up</h3>
            <span className="close-popup close" onClick={onClose}>
              {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
              {error && !showLoader && <p style={{ color: 'red' }}> Something went wrong </p>}
            </span>
          </div>
          <div className="panel-body">
            <SignUpForm />
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" onClick={onClose}>
              Close
            </a>

            <SubmitButton apiKey="signUpUser" formName="signUp-form" text="Save" />
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
