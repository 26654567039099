/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useMemo, useState } from 'react';
import { tasksPageSelectors } from '../../../../../../app/page/page-storage/tasks/tasks.selectors';
import { useSelect } from '../../../../../../utils/selector.utils';
import { TaskFormTrigger } from '../../../../../dialogs/TaskFormDialog/TaskFormTrigger';
import { TaskWidgetLine } from '../../../widget-items/task-widget-items/TaskWIdgetLine';
import { IWidgetTasksLineViewProps } from './types';

export const WidgetTasksLineView: FunctionComponent<IWidgetTasksLineViewProps> = ({ type, meetingId, title }) => {
  const reducedTasks = useSelect(tasksPageSelectors.selectReducedTasks);
  const userIds = useMemo(() => (reducedTasks[type] ? Object.keys(reducedTasks[type]) : []), [reducedTasks, type]);

  const [fullWidth, setFullWidth] = useState(true);

  return (
    <div className="panel vto-text">
      <div className="panel-header small-header no-border pb-0">
        <h2 className="bold">{title}</h2>
        <div className="ml-auto flex aic">
          <TaskFormTrigger type={type} meetingId={meetingId} />
          <div className="toggle-buttons flex aic ml-1">
            <a className="btn white-bg btn-md btn-border px-1 blueGray m-0">Show</a>
            <a className="btn white-bg btn-md btn-border px-1 blueGray m-0 selected">Hide</a>
          </div>
          <div className="toggle-buttons flex aic ml-1">
            <a
              className={`btn white-bg btn-md btn-border px-1 blueGray m-0 ${!fullWidth ? 'selected' : ''}`}
              onClick={() => setFullWidth(true)}
            >
              <i className="icon2-column-1" />
            </a>
            <a
              className={`btn white-bg btn-md btn-border px-1 blueGray m-0 ${fullWidth ? 'selected' : ''}`}
              onClick={() => setFullWidth(false)}
            >
              <i className="icon2-column-2" />
            </a>
          </div>
        </div>
      </div>
      <div className="panel-body">
        <div className="border-radius-1 lightGray-bg p-2 py-1">
          <div className="row headlines-container">
            {userIds.map(
              (userId, index) =>
                reducedTasks[type][userId] &&
                reducedTasks[type][userId].active.map((taskId) => (
                  <TaskWidgetLine
                    key={`${type}/${userId}/active/${taskId}`}
                    userId={userId}
                    taskId={taskId}
                    width={fullWidth}
                    index={index}
                    type={type}
                  />
                )),
            )}
            {userIds.map(
              (userId, index) =>
                reducedTasks[type][userId] &&
                reducedTasks[type][userId].done.map((taskId) => (
                  <TaskWidgetLine
                    key={`${type}/${userId}/done/${taskId}`}
                    userId={userId}
                    taskId={taskId}
                    width={fullWidth}
                    index={index}
                    type={type}
                  />
                )),
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
