import { all, call, fork, takeLatest } from 'redux-saga/effects';

import * as constants from './answers.constants';
import * as types from './answers.types';
import * as services from './answers.saga-services';

// function* invokeSetupAnswer(action: types.ISetupAnswer) {
//   yield call(services.setupAnswer, action.payload.accountId, action.payload.AnswerId);
// }

// function* invokeCreateAnswer(action: types.ICreateAnswer) {
//   yield call(services.createAnswer, action.payload);
// }

function* invokeUpdateAnswer(action: types.IUpdateAnswer) {
  yield call(services.updateAnswer, action.payload.data);
}
// function* invokeDeleteAnswer(action: types.IDeleteAnswer) {
//   yield call(services.deleteAnswer, action.payload.AnswerId, action.payload.accountId);
// }
/*
function* invokeSaveAnswerSocket(action: types.ISaveAnswerSocket) {
  yield call(services.handleAnswerFromNotification, action.payload);
}
*/
export function* watchAnswersPurePageSaga() {
  // yield takeLatest(constants.SETUP_Answer, invokeSetupAnswer);
  // yield takeLatest(constants.CREATE_Answer, invokeCreateAnswer);
  yield takeLatest(constants.UPDATE_ANSWER, invokeUpdateAnswer);
  // yield takeLatest(constants.DELETE_Answer, invokeDeleteAnswer);

  //yield takeLatest(constants.SAVE_Answer__SOCKET, invokeSaveAnswerSocket);
}

export function* watchAnswersPageStorageSaga() {
  yield all([watchAnswersPurePageSaga].map(fork));
}
