import { all, call, fork, takeLatest } from 'redux-saga/effects';

import * as constants from './activities.constants';
import * as types from './activities.types';
import * as services from './activities.saga-services';

function* invokeCreateActivity(action: types.ICreateActivity) {
  yield call(services.createActivity, action.payload);
}

function* invokeUpdateActivity(action: types.IUpdateActivity) {
  yield call(services.updateActivity, action.payload.id, action.payload.data);
}
function* invokeDeleteActivity(action: types.IDeleteActivity) {
  yield call(services.deleteActivity, action.payload);
}

function* invokeSaveFile(action: types.ISaveFilePage) {
  yield call(services.saveFile, action.payload);
}

function* invokeSaveActivitySocket(action: types.ISaveActivitySocket) {
  yield call(services.handleActivityFromNotification, action.payload);
}

export function* watchActivitiesPurePageSaga() {
  yield takeLatest(constants.CREATE_ACTIVITY, invokeCreateActivity);
  yield takeLatest(constants.UPDATE_ACTIVITY, invokeUpdateActivity);
  yield takeLatest(constants.DELETE_ACTIVITY, invokeDeleteActivity);
  yield takeLatest(constants.SAVE_FILE, invokeSaveFile);

  yield takeLatest(constants.SAVE_ACTIVITY_SOCKET, invokeSaveActivitySocket);
}

export function* watchActivitiesPageStorageSaga() {
  yield all([watchActivitiesPurePageSaga].map(fork));
}
