import React, { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IUserGroupCreate } from '../../../../../app/resource/user-group/user-group.types';
import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { FormTextInput } from '../../../common/inputs/TextInput';
import { IUserGroupFromProps } from './types';

export const UserGroupFrom: FunctionComponent<IUserGroupFromProps> = ({ data, onSubmit }) => {
  const formProps = useForm();
  const requiredValidation = useRequiredValidation();

  return (
    <FormProvider {...formProps}>
      <form
        id="user-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IUserGroupCreate, data?.id))}
        action=""
        method="post"
      >
        <div className="col-12" style={{ marginTop: '10px' }}>
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.name}
              id="name"
              name="name"
              type="text"
              label="Name"
              placeholder="Enter Name"
              validation={requiredValidation}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
