import React, { FunctionComponent, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createCheckList } from '../../../../../app/page/page-storage/checklists/checkLists.actions';
import { checkListSelectors } from '../../../../../app/resource/checklist/checkList.selectors';
import { ICheckListCreate, ICheckListCreateForm } from '../../../../../app/resource/checklist/checkList.types';
import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { useSelect } from '../../../../../utils/selector.utils';
import { SelectInput } from '../../inputs/SelectInput';
import { FormSmallTextInput } from '../../inputs/SmallTextInput';

import { ICheckListFormProps } from './types';

export const CheckListForm: FunctionComponent<ICheckListFormProps> = ({ checkListData, taskId, onClick }) => {
  const dispatch = useDispatch();

  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const checkLists = useSelect(checkListSelectors.selectResourceList);

  const options = useMemo(
    () =>
      checkLists.map((copyFromId) => ({
        value: copyFromId.id,
        label: copyFromId.title,
      })),
    [checkLists],
  );

  const onSubmit = (data: ICheckListCreateForm) => {
    onClick(true);

    const newData: ICheckListCreate = { title: data.title, taskId: taskId, copyFromId: data.copyFromId.value };
    dispatch(createCheckList({ ...newData }));
  };

  return (
    <FormProvider {...formProps}>
      <form
        id="checkList-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as ICheckListCreateForm))}
        method="post"
      >
        <div className="field-container">
          <FormSmallTextInput
            id="title"
            name="title"
            type="text"
            label="Title"
            placeholder="Enter Title"
            defaultValue={checkListData ? checkListData.title : ''}
            validation={requiredValidation}
          />
        </div>
        <div className="field-container custom-select smaller-select">
          <SelectInput
            id="copyFromId"
            name="copyFromId"
            label="Copy items from…"
            placeholder="Copy items from…"
            options={options}
            isSingle
          />
        </div>
        <button type="submit" className="btn btn-md green-bg white d-block w100 jcc">
          Save
        </button>
      </form>
    </FormProvider>
  );
};
