import { combineReducers, Reducer } from 'redux';

import * as types from './project-members.types';
import * as constants from './project-members.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IProjectTeamsPageState = {
  projectTeamsIds: [],
};

const projectTeamsReducer: Reducer<
  types.IProjectTeamsPageState['projectTeamsIds'],
  types.IStoreProjectTeam | types.IClearProjectTeam
> = (state = initialState.projectTeamsIds, action) => {
  if (isActionOfType<types.IStoreProjectTeam>(action, constants.STORE_PROJECT_TEAM)) {
    return action.payload;
  } else if (isActionOfType<types.IClearProjectTeam>(action, constants.CLEAR_PROJECT_TEAM)) {
    return initialState.projectTeamsIds;
  }

  return state;
};

export const projectTeamsPageReducer: Reducer<
  types.IProjectTeamsPageState,
  types.IProjectTeamsPageAction
> = combineReducers({
  projectTeamsIds: projectTeamsReducer,
});
