import { createActionConst } from '../../../../../utils/action.utils';

const USER_FORM_NAMESPACE = 'USER_FORM';

export const FILTER_SELECTABLE_TEAMS = createActionConst(USER_FORM_NAMESPACE, 'FILTER_SELECTABLE_TEAMS');
export const STORE_SELECTABLE_TEAMS = createActionConst(USER_FORM_NAMESPACE, 'STORE_SELECTABLE_TEAMS');
export const CLEAR_SELECTABLE_TEAMS = createActionConst(USER_FORM_NAMESPACE, 'CLEAR_SELECTABLE_TEAMS');

export const FILTER_SELECTABLE_ACCOUNTS = createActionConst(USER_FORM_NAMESPACE, 'FILTER_SELECTABLE_ACCOUNTS');
export const STORE_SELECTABLE_ACCOUNTS = createActionConst(USER_FORM_NAMESPACE, 'STORE_SELECTABLE_ACCOUNTS');
export const CLEAR_SELECTABLE_ACCOUNTS = createActionConst(USER_FORM_NAMESPACE, 'CLEAR_SELECTABLE_ACCOUNTS');

export const SAVE_USER_AVATAR = createActionConst(USER_FORM_NAMESPACE, 'SAVE_USER_AVATAR');
export const CLEAR_USER_AVATAR = createActionConst(USER_FORM_NAMESPACE, 'CLEAR_USER_AVATAR');
