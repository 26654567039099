import * as types from './boards.types';
import * as constants from './boards.constants';
import { IBoardCreateForm, IBoard } from '../../../resource/boards/board/board.types';

export const createBoard = (board: IBoardCreateForm): types.ICreateBoard => ({
  type: constants.CREATE_BOARD,
  payload: board,
});

export const updateBoard = (boardId: IBoard['id'], board: IBoardCreateForm): types.IUpdateBoard => ({
  type: constants.UPDATE_BOARD,
  payload: {
    id: boardId,
    data: board,
  },
});

export const deleteBoard = (boardId: IBoard['id']): types.IDeleteBoard => ({
  type: constants.DELETE_BOARD,
  payload: boardId,
});

export const storeCurrentBoard = (boardId: IBoard['id']): types.IStoreCurrentBoard => ({
  type: constants.STORE_CURRENT_BOARD,
  payload: boardId,
});

export const clearCurrentBoard = (): types.IClearCurrentBoard => ({
  type: constants.CLEAR_CURRENT_BOARD,
});

export const storeBoards = (boards: IBoard['id'][]): types.IStoreBoard => ({
  type: constants.STORE_BOARD,
  payload: boards,
});

export const clearBoard = (): types.IClearBoard => ({
  type: constants.CLEAR_BOARD,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});

export const saveBoardOrder = (boardIds: IBoard['id'][]): types.ISaveBoardOrder => ({
  type: constants.SAVE_BOARD_ORDER,
  payload: {
    boardIds,
  },
});
