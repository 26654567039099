import { Reducer } from 'redux';

import * as types from './widgets.types';
import * as constants from './widgets.constants';

const initialWidgetState: types.IWidgetState = {
  visible: true,
};

const initialHomePageState = {
  homeCalendar: initialWidgetState,
  homeAccounts: initialWidgetState,
  homeTodos: initialWidgetState,
  homeQis: initialWidgetState,
};

const initialState: types.IWidgetsState = {
  ...initialHomePageState,
};

// exception to the rule, because of dynamic keys on state, it was easier to bundle everything into one reducer
// in general don't do this, create reducers per property
export const widgetsReducer: Reducer<types.IWidgetsState, types.IWidgetAction> = (state = initialState, action) => {
  if (action.type === constants.STORE_WIDGET_VISIBLE) {
    const key = (action as types.IStoreWidgetVisibility).payload;

    return {
      ...state,
      [key]: {
        ...state[key],
        visible: true,
      },
    };
  } else if (action.type === constants.CLEAR_WIDGET_VISIBLE) {
    const key = (action as types.IClearWidgetVisibility).payload;

    return {
      ...state,
      [key]: {
        ...state[key],
        visible: false,
      },
    };
  }
  return state;
};
