import { Reducer } from 'redux';

import * as types from './api.types';
import * as constants from './api.constants';

const initialRequestState: types.IApiRequestState = {
  inProgress: false,
  error: null,
};

const initialState: types.IApiState = {
  login: initialRequestState,
  searchMeetings: initialRequestState,
  createMeeting: initialRequestState,
  updateMeeting: initialRequestState,
  deleteMeeting: initialRequestState,
  finishMeetingSession: initialRequestState,
  updateSession: initialRequestState,
  signUpUser: initialRequestState,
  searchUsers: initialRequestState,
  createUser: initialRequestState,
  updateUser: initialRequestState,
  deleteUser: initialRequestState,
  searchTasks: initialRequestState,
  searchAgendaItemApi: initialRequestState,
  nextSession: initialRequestState,
  createScoreApi: initialRequestState,
  scoreBoard: initialRequestState,
  createScoreboard: initialRequestState,
  updateScoreboard: initialRequestState,
  updateScoreApi: initialRequestState,
  saveScoreOrderApi: initialRequestState,
  createTaskApi: initialRequestState,
  updateTaskApi: initialRequestState,
  deleteTaskApi: initialRequestState,
  createConclusion: initialRequestState,
  searchNotifications: initialRequestState,
  updateNotification: initialRequestState,
  updateNotifications: initialRequestState,
  searchTemplates: initialRequestState,
  createTemplate: initialRequestState,
  createSection: initialRequestState,
  updateSection: initialRequestState,
  deleteSection: initialRequestState,
  getMyCalendars: initialRequestState,
  getEvents: initialRequestState,
  getNodes: initialRequestState,
  createNode: initialRequestState,
  updateNode: initialRequestState,
  deleteNode: initialRequestState,
  getBoards: initialRequestState,
  createBoard: initialRequestState,
  updateBoard: initialRequestState,
  deleteBoard: initialRequestState,
  saveBoardOrderApi: initialRequestState,
  getBoardLists: initialRequestState,
  createBoardList: initialRequestState,
  updateBoardList: initialRequestState,
  deleteBoardList: initialRequestState,
  saveTaskOrder: initialRequestState,
  saveBoardListOrder: initialRequestState,
  getLabels: initialRequestState,
  createLabel: initialRequestState,
  updateLabel: initialRequestState,
  deleteLabel: initialRequestState,
  getCheckLists: initialRequestState,
  createCheckList: initialRequestState,
  updateCheckList: initialRequestState,
  deleteCheckList: initialRequestState,
  createChecklistItem: initialRequestState,
  updateChecklistItem: initialRequestState,
  deleteChecklistItem: initialRequestState,
  getActivities: initialRequestState,
  createActivity: initialRequestState,
  updateActivity: initialRequestState,
  deleteActivity: initialRequestState,
  saveFile: initialRequestState,
  moveTask: initialRequestState,
  copyTask: initialRequestState,
  moveToNextAgendaApi: initialRequestState,
  searchUserGroups: initialRequestState,
  createUserGroup: initialRequestState,
  searchPasswordGroups: initialRequestState,
  savePasswordGroup: initialRequestState,
  searchPasswords: initialRequestState,
  savePassword: initialRequestState,
  deletePassword: initialRequestState,
  getProjectTasks: initialRequestState,
  createProjectTask: initialRequestState,
  updateProjectTask: initialRequestState,
  deleteProjectTask: initialRequestState,
  getProjectTeams: initialRequestState,
  createProjectTeam: initialRequestState,
  updateProjectTeam: initialRequestState,
  deleteProjectTeam: initialRequestState,
  getProjects: initialRequestState,
  createProject: initialRequestState,
  updateProject: initialRequestState,
  deleteProject: initialRequestState,
  getProjectTimes: initialRequestState,
  getAccountProjects: initialRequestState,
  saveTimer: initialRequestState,
  getQuartals: initialRequestState,
  createQuartal: initialRequestState,
  updateQuartal: initialRequestState,
  deleteQuartal: initialRequestState,
  forgotPassword: initialRequestState,
  saveNewPassword: initialRequestState,
  getCategories: initialRequestState,
  createCategory: initialRequestState,
  updateCategory: initialRequestState,
  deleteCategory: initialRequestState,
  updateAnswer: initialRequestState,
  searchMeetingsInfo: initialRequestState,
};

// exception to the rule, because of dynamic keys on state, it was easier to bundle everything into one reducer
// in general don't do this, create reducers per property
export const apiReducer: Reducer<types.IApiState, types.IApiAction> = (state = initialState, action) => {
  if (action.type === constants.STORE_API_IN_PROGRESS) {
    const key = (action as types.IStoreApiInProgress).payload;

    return {
      ...state,
      [key]: {
        ...state[key],
        inProgress: true,
      },
    };
  } else if (action.type === constants.CLEAR_API_IN_PROGRESS) {
    const key = (action as types.IClearApiInProgress).payload;

    return {
      ...state,
      [key]: {
        ...state[key],
        inProgress: false,
      },
    };
  } else if (action.type === constants.STORE_API_ERROR) {
    const { key, error } = (action as types.IStoreApiError).payload;

    return {
      ...state,
      [key]: {
        ...state[key],
        error,
      },
    };
  } else if (action.type === constants.CLEAR_API_ERROR) {
    const key = (action as types.IClearApiError).payload;

    return {
      ...state,
      [key]: {
        ...state[key],
        error: null,
      },
    };
  }

  return state;
};
