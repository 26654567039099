import React, { FunctionComponent, useCallback, useState } from 'react';
import Loader from 'react-loader-spinner';

import { useDispatch } from 'react-redux';
import { updateTask } from '../../../../../../../../app/page/page-storage/boardLists/boardLists.actions';

import { taskSelectors } from '../../../../../../../../app/resource/task/task.selectors';
import { ITask } from '../../../../../../../../app/resource/task/task.types';
import { useApiRequestState } from '../../../../../../../../utils/api.utils';

import { useSelectWithParams } from '../../../../../../../../utils/selector.utils';

import { ILabelsProps } from './types';

export const Labels: FunctionComponent<ILabelsProps> = ({ labels, taskId }) => {
  let counter = 0;
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId) as ITask;
  const dispatch = useDispatch();
  const [loaderTaskId, setLoaderTaskId] = useState('');
  const handleStatus = useCallback(
    (e, id) => {
      e.stopPropagation();
      setLoaderTaskId(id);
      const updatedTask = {
        id: task.id,
        title: task.title,
        description: task.description,
        dueDate: task.dueDate,
        userId: task.userId,
        meetingIds: task.meetingIds,
        status: task.status === '1' ? '0' : '1',
        label: task.label ?? [],
        taskTypeId: task.taskTypeId,
        members: task.members,
        showInTasks: task.showInTasks,
        boardId: task.boardId,
      };

      dispatch(updateTask(updatedTask));
    },
    [
      dispatch,
      task.boardId,
      task.description,
      task.dueDate,
      task.id,
      task.label,
      task.meetingIds,
      task.members,
      task.showInTasks,
      task.status,
      task.taskTypeId,
      task.title,
      task.userId,
    ],
  );
  const [showLoader] = useApiRequestState('updateTaskApi');
  return (
    <div className="card-header" style={{ justifyContent: 'space-between' }}>
      <div className="blueGray f-1 card-date flex aic" style={{ flexWrap: 'wrap' }}>
        {labels.map((label) => (
          <span
            key={`${taskId}-label[${label}${counter++}]`}
            className="team-badge"
            style={{ backgroundColor: label, margin: '0 5px 5px 0' }}
          />
        ))}
      </div>
      {showLoader && loaderTaskId === task.id && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
      {loaderTaskId !== task.id && (
        <div className="checklist-checkbox flex aic m-0" onClick={(e) => handleStatus(e, task.id)}>
          <input type="checkbox" id={`checkBox-${task.id}`} checked={task.status === '1'} readOnly />

          <label htmlFor={`checkBox-${task.id}`}></label>
        </div>
      )}
    </div>
  );
};
