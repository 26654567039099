/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { userSelectors } from '../../../../../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../../../../../utils/selector.utils';
import { FormCSTextInput } from '../../../../../../inputs/CustomSizeTextInput';
import { IMemberFormProps } from './types';

export const Member: FunctionComponent<IMemberFormProps> = ({ userId, index }) => {
  const user = useSelectWithParams(userSelectors.selectResourceById, userId);

  return (
    <div className="account-item">
      <a className="small-avatar mr-05">
        <img src={user?.image} alt="" className="img-fluid" />
        <span className="status-badge green-bg">
          <i className="icon-check green" />
        </span>
      </a>
      <div className="account-item-desc mr-1">
        <h3 className="account-item-title">
          {user && <a className="hover-green">{`${user.firstName} ${user.lastName}`}</a>}
        </h3>
        <FormCSTextInput
          id={`teams[${index}].userId`}
          name={`teams[${index}].userId`}
          type="text"
          size="none"
          defaultValue={userId}
        />
      </div>
      <img src="images/verified-badge.svg" alt="" className="img-fluid verified-badge" />
    </div>
  );
};
