import * as types from './chart.types';
import * as constants from './chart.constants';
import { INodeCreateForm, IOrganizationChartNode } from '../../../resource/node/node.types';

export const createNode = (node: INodeCreateForm): types.ICreateChart => ({
  type: constants.CREATE_CHART,
  payload: node,
});

export const updateNode = (nodeId: IOrganizationChartNode['id'], node: INodeCreateForm): types.IUpdateChart => ({
  type: constants.UPDATE_CHART,
  payload: {
    id: nodeId,
    data: node,
  },
});

export const deleteNode = (nodeId: IOrganizationChartNode['id']): types.IDeleteChart => ({
  type: constants.DELETE_CHART,
  payload: nodeId,
});

export const storeChart = (chartNodes: IOrganizationChartNode['id'][]): types.IStoreChart => ({
  type: constants.STORE_CHART,
  payload: chartNodes,
});

export const clearChart = (): types.IClearChart => ({
  type: constants.CLEAR_CHART,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
