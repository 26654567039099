import { createActionConst } from '../../../../utils/action.utils';

const LABEL_NAMESPACE = 'LABELS';

export const CREATE_LABEL = createActionConst(LABEL_NAMESPACE, 'CREATE_LABEL');
export const UPDATE_LABEL = createActionConst(LABEL_NAMESPACE, 'UPDATE_LABEL');
export const DELETE_LABEL = createActionConst(LABEL_NAMESPACE, 'DELETE_LABEL');

export const STORE_IS_FETCHED = createActionConst(LABEL_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(LABEL_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_LABEL = createActionConst(LABEL_NAMESPACE, 'STORE_LABEL');
export const CLEAR_LABEL = createActionConst(LABEL_NAMESPACE, 'CLEAR_LABEL');

export const SAVE_LABEL_SOCKET = 'saveLabel';
export const DELETE_LABEL_SOCKET = 'deleteLabel';
