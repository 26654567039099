import { call, takeLatest } from 'redux-saga/effects';
import * as constants from './conclusions.constants';

import * as types from './conclusions.types';
import * as services from './conclusions.saga-services';

function* invokeCreateConclusion(action: types.ICreateConclusion) {
  yield call(services.createConclusion, action.payload);
}

function* invokeUpdateConclusionSocket(action: types.IUpdateConclusionSocket) {
  yield call(services.handleConclusionFromNotification, action.payload);
}

export function* watchConclusionPageSaga() {
  yield takeLatest(constants.SAVE_CONCLUSION_SOCKET, invokeUpdateConclusionSocket);
  yield takeLatest(constants.CREATE_CONCLUSION, invokeCreateConclusion);
}
