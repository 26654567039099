/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FormEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { CalendarOptions } from './CalendarOptions';
import { CalendarView } from './CalendarView/indext';
import { IWidgetFullCalendarProps } from './types';

import {
  prevMonth,
  nextMonth,
  serializeDate,
  dashMonth,
  nextWeek,
  prevWeek,
  nextDate,
  prevDate,
  formatMonth,
  formatDayMonth,
  calendarWeekFormat,
} from '../../../../../utils/date.utils';
import { CURRENT_DATE } from '../../../../../app/resource/calendar/calendar.types';
import { searchByDescription } from '../../../../../app/page/page-storage/calendars/calendars.actions';
import { useSelect, useSelectWithParams } from '../../../../../utils/selector.utils';
import { calendarsPageSelectors } from '../../../../../app/page/page-storage/calendars/calendars.selectors';
import { useDispatch } from 'react-redux';
import { meetingSelectors } from '../../../../../app/resource/meeting/meeting.selectors';
import { IUser } from '../../../../../app/resource/user/user.types';

export const WidgetFullCalendar: FunctionComponent<IWidgetFullCalendarProps> = ({ meetingId }) => {
  const dispatch = useDispatch();
  const [interval, setInterval] = useState('');
  const [viewType, setViewType] = useState('');

  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);
  const userIds = useMemo(
    () => (meeting ? Array.from(new Set([...meeting.administratorIds, ...meeting.shareWithIds])) : []),
    [meeting],
  );
  const [userFilter, setUserFilter] = useState<IUser['id'][]>([]);

  const controls = useMemo(() => {
    const controls = {
      interval: interval ? dashMonth(interval) : '',
      viewInterval: formatMonth(interval),
      inc: () => {
        setInterval(serializeDate(nextMonth(interval)));
      },
      dec: () => {
        setInterval(serializeDate(prevMonth(interval)));
      },
    };
    if (viewType === 'week') {
      controls.interval = interval;
      controls.viewInterval = calendarWeekFormat(interval);
      controls.inc = () => {
        setInterval(serializeDate(nextWeek(interval)));
      };
      controls.dec = () => {
        setInterval(serializeDate(prevWeek(interval)));
      };
    } else if (viewType === 'day') {
      controls.interval = interval;
      controls.viewInterval = formatDayMonth(interval);
      controls.inc = () => {
        setInterval(serializeDate(nextDate(interval)));
      };
      controls.dec = () => {
        setInterval(serializeDate(prevDate(interval)));
      };
    }
    return controls;
  }, [interval, viewType]);

  useEffect(() => {
    if (meeting) {
      setUserFilter([...meeting.administratorIds, ...meeting.shareWithIds]);
    }
  }, [meeting]);

  useEffect(() => {
    setInterval(CURRENT_DATE);
    setViewType('month');
  }, []);

  const description = useSelect(calendarsPageSelectors.selectDescriptionParam);
  const filterByDescription = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      dispatch(searchByDescription(event.currentTarget.value));
    },
    [dispatch],
  );

  return (
    <div className="row mb-25 mt-3">
      <div className="col-12">
        <div className="panel vto-text">
          <div className="panel-header small-header no-border pb-0">
            <h2 className="bold">Calendar</h2>
            {/* <div className="ml-auto flex aic">
              <a className="btn green-bg btn-md popup_button" data-popup title="Add Event">
                <i className="icon-plus white f-1" /> Add Event
              </a>
              <a className="btn btn-md btn-border white-bg" title="Add Event">
                Calendar Settings
              </a>
            </div> */}
          </div>
          <div className="cards-container-overflow">
            <div className="cards-container">
              <div className="panel-body px-0 w100">
                <div className="border-radius-1 lightGray-bg p-2">
                  <div className="calendar-container">
                    <CalendarOptions
                      viewInterval={controls.viewInterval}
                      decMonth={controls.dec}
                      incMonth={controls.inc}
                      setViewType={setViewType}
                      filterByDescription={filterByDescription}
                      description={description}
                      userIds={userIds}
                      setUserFilter={setUserFilter}
                    />
                    {controls.interval && (
                      <CalendarView
                        meetingId={meetingId}
                        date={controls.interval}
                        userFilter={userFilter}
                        viewType={viewType}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
