import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './sessions.constants';
import * as types from './sessions.types';
import * as services from './sessions.saga-services';

function* invokeUpdateSession(action: types.IUpdateSession) {
  yield call(services.updateSessions, action.payload.id, action.payload.data);
}

export function* watchSessionsPageSaga() {
  yield takeLatest(constants.UPDATE_SESSION, invokeUpdateSession);
}
