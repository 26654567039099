import { all, call, fork, takeLatest } from 'redux-saga/effects';

import * as constants from './checkLists.constants';
import * as types from './checkLists.types';
import * as services from './checkLists.saga-services';

function* invokeCreateCheckList(action: types.ICreateCheckList) {
  yield call(services.createCheckList, action.payload);
}

function* invokeUpdateCheckList(action: types.IUpdateCheckList) {
  yield call(services.updateCheckList, action.payload.id, action.payload.data);
}
function* invokeDeleteCheckList(action: types.IDeleteCheckList) {
  yield call(services.deleteCheckList, action.payload);
}

function* invokeCreateChecklistItem(action: types.ICreateChecklistItem) {
  yield call(services.createChecklistItem, action.payload);
}

function* invokeUpdateChecklistItem(action: types.IUpdateChecklistItem) {
  yield call(services.updateChecklistItem, action.payload.id, action.payload.data);
}
function* invokeDeleteChecklistItem(action: types.IDeleteChecklistItem) {
  yield call(services.deleteChecklistItem, action.payload);
}

/*
function* invokeSaveChartSocket(action: types.ISaveChartSocket) {
  yield call(services.handleChartFromNotification);
}
*/
export function* watchCheckListsPurePageSaga() {
  yield takeLatest(constants.CREATE_CHECKLIST, invokeCreateCheckList);
  yield takeLatest(constants.UPDATE_CHECKLIST, invokeUpdateCheckList);
  yield takeLatest(constants.DELETE_CHECKLIST, invokeDeleteCheckList);
  yield takeLatest(constants.CREATE_CHECKLIST_ITEM, invokeCreateChecklistItem);
  yield takeLatest(constants.UPDATE_CHECKLIST_ITEM, invokeUpdateChecklistItem);
  yield takeLatest(constants.DELETE_CHECKLIST_ITEM, invokeDeleteChecklistItem);
  //yield takeEvery(constants.SAVE_CHART_SOCKET, invokeSaveChartSocket);
}

export function* watchCheckListsPageStorageSaga() {
  yield all([watchCheckListsPurePageSaga].map(fork));
}
