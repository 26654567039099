import { call, put, select } from 'redux-saga/effects';

import * as actions from './project-tasks.actions';
import * as projectTaskResourceService from './../../../resource/project-task/project-task.saga-service';

import { projectTasksPageSelectors } from './project-tasks.selectors';
import {
  IProjectTask,
  IProjectTaskCreate,
  IProjectTaskCreateForm,
} from '../../../resource/project-task/project-task.types';

export function* clearProjectTasks() {
  yield put(actions.clearProjectTask());
}

export function* setupProjectTasks(projectId: string) {
  const projectTasks = (yield call(projectTaskResourceService.searchProjectTasks, {
    projectId,
  })) as IProjectTask['id'][];

  yield put(projectTasks ? actions.storeProjectTasks(projectTasks) : actions.clearProjectTask());
}

export function* createProjectTask(projectTaskInfo: IProjectTaskCreateForm) {
  const projectTask = (yield call(
    projectTaskResourceService.createProjectTask,
    mapProjectTaskFormToProjectTaskCreate(projectTaskInfo),
  )) as IProjectTask;

  if (!projectTask) {
    return;
  }
}

export function* updateProjectTask(projectTaskId: IProjectTask['id'], projectTaskInfo: IProjectTaskCreateForm) {
  const projectTask = (yield call(projectTaskResourceService.updateProjectTask, {
    id: projectTaskId,
    ...mapProjectTaskFormToProjectTaskCreate(projectTaskInfo),
  })) as IProjectTask;

  if (!projectTask) {
    return;
  }
}

export function* deleteProjectTask(projectTaskId: IProjectTask['id']) {
  yield call(projectTaskResourceService.deleteProjectTask, projectTaskId);

  const projectTasksIds: IProjectTask['id'][] = yield select(projectTasksPageSelectors.selectProjectTasks);

  yield put(
    projectTasksIds
      ? actions.storeProjectTasks(projectTasksIds.filter((id) => projectTaskId !== id))
      : actions.clearProjectTask(),
  );
}

export const mapProjectTaskFormToProjectTaskCreate = (ProjectTaskInfo: IProjectTaskCreateForm): IProjectTaskCreate => ({
  title: ProjectTaskInfo.title,
  billable: ProjectTaskInfo.billable,
  budget: ProjectTaskInfo.budget,
  budgetHours: ProjectTaskInfo.budgetHours,
  hourlyRate: ProjectTaskInfo.hourlyRate,
  changedBy: ProjectTaskInfo.changedBy,
  changedAt: ProjectTaskInfo.changedAt,
  deleted: ProjectTaskInfo.deleted,
  projectId: ProjectTaskInfo.projectId,
});

export function* clearProjectTasksPageStorage() {
  yield put(actions.clearProjectTask());
}
/*
export function* handleProjectTaskFromNotification(projectTaskResponse: IProjectTaskFromNotification) {
  const projectTaskExists = (yield selectWithParams(
    projectTaskSelectors.selectResourceById,
    projectTaskResponse.id,
  )) as IProjectTask | null;

  if (projectTaskExists) {
    yield call(projectTaskResourceService.storeProjectTask, { ...projectTaskResponse });
  } else {
    const projectTasksIds = yield select(projectTasksPageSelectors.selectProjectTasks);
    yield call(projectTaskResourceService.storeProjectTask, { ...projectTaskResponse });

    yield put(actions.storeProjectTasks([...projectTasksIds, projectTaskResponse.id]));
  }
}
*/
