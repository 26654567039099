import { IApiRequestConfig } from '../api.types';
import { IMeeting, IMeetingCreate, IMeetingSearchParams, IMeetingUpdate } from '../../resource/meeting/meeting.types';
import { ISession } from '../../resource/session/session.types';

export const searchMeetingApi = (params?: IMeetingSearchParams): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'searchMeetings',
  uri: 'json',
  action: 'getMeetings',
  method: 'get',
  params: {
    ...params,
  },
});

export const createMeetingApi = (meeting: IMeetingCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createMeeting',
  uri: 'json',
  action: 'saveMeeting',
  method: 'post',
  data: meeting,
});

export const updateMeetingApi = (meeting: IMeetingUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateMeeting',
  uri: 'json',
  action: 'saveMeeting',
  method: 'post',
  data: meeting,
});

export const deleteMeetingApi = (meetingId: IMeeting['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteMeeting',
  uri: 'json',
  action: 'deleteMeeting',
  method: 'delete',
  params: {
    id: meetingId,
  },
});

export const finishMeetingSessionApi = (endSession: {
  id: IMeeting['id'];
  sessionId: ISession['id'];
}): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'finishMeetingSession',
  uri: 'json',
  action: 'finishMeetingSession',
  method: 'post',
  data: endSession,
});
