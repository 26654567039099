import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './chart.constants';
import * as types from './chart.types';
import * as services from './chart.saga-services';

function* invokeInitialChartPage(action: types.IInitialChartPage) {
  yield call(services.initialChartPage);
}

export function* watchChartPageSaga() {
  yield takeLatest(constants.INITIAL_CHART_PAGE, invokeInitialChartPage);
}
