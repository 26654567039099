import { IActivity, IActivityCreate, IActivityUpdate, ISaveFile } from '../../resource/activity/activity.types';
import { IApiRequestConfig } from '../api.types';

export const searchActivitiesApi = (taskId: string): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'getActivities',
  uri: 'json',
  action: 'getActivities',
  method: 'get',
  params: {
    taskId,
  },
});

export const createActivityApi = (activity: IActivityCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createActivity',
  uri: 'json',
  action: 'saveActivity',
  method: 'post',
  data: activity,
});

export const updateActivityApi = (activity: IActivityUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateActivity',
  uri: 'json',
  action: 'saveActivity',
  method: 'put',
  data: activity,
});

export const deleteActivityApi = (activityId: IActivity['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteActivity',
  uri: 'json',
  action: 'deleteActivity',
  method: 'delete',
  params: {
    id: activityId,
  },
});

export const saveFileApi = (activity: ISaveFile): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'saveFile',
  uri: 'json',
  action: 'saveFile',
  method: 'post',
  data: activity,
});
