import {
  IKpiRowScoreboardCreate,
  IKpiRowScoreboardUpdate,
} from '../../resource/kpi-row-scoreboard/kpi-row-scoreboard.types';
import { IApiRequestConfig } from '../api.types';

export const createScoreboardApi = (scoreboard: IKpiRowScoreboardCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createScoreboard',
  uri: 'json',
  action: 'saveScoreBoard',
  method: 'post',
  data: scoreboard,
});

export const updateScoreboardApi = (scoreboard: IKpiRowScoreboardUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateScoreboard',
  uri: 'json',
  action: 'saveScoreBoard',
  method: 'put',
  data: scoreboard,
});
