import React, { FunctionComponent } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { widgetsPageSelectors } from '../../../../../app/page/page-storage/widgets/widgets.selectors';
import { useSelect } from '../../../../../utils/selector.utils';
import { WidgetsDragDropContext } from '../../drag-drop-contexts/WidgetsDragDropContext';
import { WidgetDialogItem } from './WidgetDialogItem';

export const WidgetsDialogList: FunctionComponent = () => {
  const widgetsForPage = useSelect(widgetsPageSelectors.selectPageWidgets);
  return (
    <WidgetsDragDropContext>
      <Droppable droppableId={`widgets`}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} id="widgetsList">
            {widgetsForPage.map((widget, index) => (
              <Draggable key={widget.key} draggableId={widget.key} index={index}>
                {(provided) => <WidgetDialogItem widget={widget} provided={provided} />}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </WidgetsDragDropContext>
  );
};
