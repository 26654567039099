import * as types from './tasks.types';
import * as constants from './tasks.constants';
import { ITask, ITaskCreateForm, ITaskSearchParams } from '../../../resource/task/task.types';
import { DropResult } from 'react-beautiful-dnd';
import { IMeeting } from '../../../resource/meeting/meeting.types';
import { IUser } from '../../../resource/user/user.types';

export const storeReducedTasks = (reducedTasks: types.IReducedTasks): types.IStoreReducedTasks => ({
  type: constants.STORE_REDUCED_TASKS,
  payload: reducedTasks,
});

export const clearReducedTasks = (): types.IClearReducedTasks => ({
  type: constants.CLEAR_REDUCED_TASKS,
});

export const fetchTasks = (searchParams: ITaskSearchParams): types.IFetchTasks => ({
  type: constants.FETCH_TASKS,
  payload: searchParams,
});

export const createTask = (task: ITaskCreateForm): types.ICreateTask => ({
  type: constants.CREATE_TASK,
  payload: task,
});

export const updateTask = (taskId: ITask['id'], task: ITaskCreateForm): types.IUpdateTask => ({
  type: constants.UPDATE_TASK,
  payload: {
    id: taskId,
    data: task,
  },
});

export const deleteTask = (taskId: ITask['id']): types.IDeleteTask => ({
  type: constants.DELETE_TASK,
  payload: taskId,
});

export const moveTask = (params: DropResult): types.IMoveTask => ({
  type: constants.MOVE_TASK,
  payload: params,
});

export const moveBoardTask = (params: DropResult): types.IMoveTask => ({
  type: constants.MOVE_BOARD_TASK,
  payload: params,
});

export const dndTask = (params: DropResult): types.IDndTask => ({
  type: constants.DND_TASK,
  payload: params,
});

export const filterTaskForUserByMeetingIds = (
  userId: IUser['id'],
  meetingIds: IMeeting['id'][],
): types.IFilterTaskForUserByMeetingIds => ({
  type: constants.FILTER_TASK_FOR_USER_BY_MEETING,
  payload: {
    userId,
    meetingIds,
  },
});
