import { call, put, select } from 'redux-saga/effects';

import * as actions from './checkLists.actions';

import { ICheckListCreate, ICheckList, ICheckListUpdateForm } from '../../../resource/checklist/checkList.types';
import * as dialogService from '../../../dialog/dialog.saga-services';
import { checkListsPageSelectors } from './checkLists.selectors';
import * as checkListResourceService from '../../../resource/checklist/checkList.saga-service';
import * as checklistItemResourceService from '../../../resource/checklistItem/checklistItem.saga-service';

import * as activitiesPageStorageService from '../activities/activities.saga-services';

import {
  IChecklistItem,
  IChecklistItemCreate,
  IChecklistItemCreateForm,
} from '../../../resource/checklistItem/checklistItem.types';
import { ITask } from '../../../resource/task/task.types';

export function* clearCheckLists() {
  yield put(actions.clearCheckList());
}

export function* setupCheckLists(taskId: ITask['id']) {
  const checkLists = (yield call(checkListResourceService.searchCheckLists, taskId)) as ICheckList['id'][];

  yield put(checkLists ? actions.storeCheckLists(checkLists) : actions.clearCheckList());
}
export function* createCheckList(checkListInfo: ICheckListCreate) {
  const checkList = (yield call(
    checkListResourceService.createCheckList,
    mapCheckListFormToCheckListCreate(checkListInfo),
  )) as ICheckList;

  if (!checkList) {
    return;
  }
  const checkListsIds = yield select(checkListsPageSelectors.selectCheckLists);

  yield put(checkListsIds ? actions.storeCheckLists([...checkListsIds, checkList.id]) : actions.clearCheckList());

  yield call(activitiesPageStorageService.setupActivities, checkListInfo.taskId);
  yield call(dialogService.closeDialog, 'checkListFormDialog');
}

export function* updateCheckList(checkListId: ICheckList['id'], checkListInfo: ICheckListUpdateForm) {
  const checkList = (yield call(checkListResourceService.updateCheckList, {
    id: checkListId,
    ...mapCheckListFormToCheckListUpdate(checkListInfo),
  })) as ICheckList;

  if (!checkList) {
    return;
  }
  const checkListsIds = yield select(checkListsPageSelectors.selectCheckLists);

  yield put(checkListsIds ? actions.storeCheckLists([...checkListsIds]) : actions.clearCheckList());
  yield call(dialogService.closeDialog, 'checkListFormDialog');
}

export function* deleteCheckList(checkListId: ICheckList['id']) {
  yield call(checkListResourceService.deleteCheckList, checkListId);

  const checkListsIds: ICheckList['id'][] = yield select(checkListsPageSelectors.selectCheckLists);

  yield put(
    checkListsIds
      ? actions.storeCheckLists(checkListsIds.filter((id) => checkListId !== id))
      : actions.clearCheckList(),
  );
}

export const mapCheckListFormToCheckListCreate = (checkListInfo: ICheckListCreate): ICheckListCreate => ({
  title: checkListInfo.title,
  taskId: checkListInfo.taskId,
  copyFromId: checkListInfo.copyFromId,
});
export const mapCheckListFormToCheckListUpdate = (checkListInfo: ICheckListUpdateForm): ICheckListUpdateForm => ({
  title: checkListInfo.title,
  taskId: checkListInfo.taskId,
});
export function* clearCheckListsPageStorage() {
  yield put(actions.clearCheckList());
}

export function* createChecklistItem(checklistItemInfo: IChecklistItemCreateForm) {
  yield call(
    checklistItemResourceService.createChecklistItem,
    mapChecklistItemFormToChecklistItemCreate(checklistItemInfo),
  );
}

export function* updateChecklistItem(
  checklistItemId: IChecklistItem['id'],
  checklistItemInfo: IChecklistItemCreateForm,
) {
  yield call(checklistItemResourceService.updateChecklistItem, {
    id: checklistItemId,
    ...mapChecklistItemFormToChecklistItemCreate(checklistItemInfo),
  });
}

export function* deleteChecklistItem(checklistItemId: IChecklistItem['id']) {
  yield call(checklistItemResourceService.deleteChecklistItem, checklistItemId);

  yield call(checklistItemResourceService.clearChecklistItem, checklistItemId);
}

export const mapChecklistItemFormToChecklistItemCreate = (
  checklistItemInfo: IChecklistItemCreateForm,
): IChecklistItemCreate => ({
  title: checklistItemInfo.title,
  checklistId: checklistItemInfo.checklistId,
  status: checklistItemInfo.status,
});
