/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../app/dialog/dialog.actions';

export const ForgotPassword = () => {
  const dispatch = useDispatch();

  const onClickDialog = useCallback(() => {
    dispatch(openDialog('forgotPasswordDialog'));
  }, [dispatch]);
  return (
    <a className="link blueGray ml-2" title="Forgot password" onClick={onClickDialog}>
      Forgot password?
    </a>
  );
};
