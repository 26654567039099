import { createActionConst } from '../../../../utils/action.utils';

const MEETINGS_NAMESPACE = 'MEETINGS';

export const FETCH_MEETINGS = createActionConst(MEETINGS_NAMESPACE, 'FETCH_MEETINGS');
export const CREATE_MEETING = createActionConst(MEETINGS_NAMESPACE, 'CREATE_MEETING');
export const UPDATE_MEETING = createActionConst(MEETINGS_NAMESPACE, 'UPDATE_MEETING');
export const DELETE_MEETING = createActionConst(MEETINGS_NAMESPACE, 'DELETE_MEETING');

export const FINISH_MEETING_SESSION = createActionConst(MEETINGS_NAMESPACE, 'FINISH_MEETING_SESSION');

export const STORE_REDUCED_MEETINGS = createActionConst(MEETINGS_NAMESPACE, 'STORE_REDUCED_MEETINGS');
export const CLEAR_REDUCED_MEETINGS = createActionConst(MEETINGS_NAMESPACE, 'CLEAR_REDUCED_MEETINGS');

export const STORE_FILTERED_MEETINGS = createActionConst(MEETINGS_NAMESPACE, 'STORE_FILTERED_MEETINGS');
export const CLEAR_FILTERED_MEETINGS = createActionConst(MEETINGS_NAMESPACE, 'CLEAR_FILTERED_MEETINGS');

export const STORE_IS_FETCHED = createActionConst(MEETINGS_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(MEETINGS_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_CURRENT_MEETING = createActionConst(MEETINGS_NAMESPACE, 'STORE_CURRENT_MEETING');
export const CLEAR_CURRENT_MEETING = createActionConst(MEETINGS_NAMESPACE, 'CLEAR_CURRENT_MEETING');

export const SAVE_MEETING_SOCKET = 'saveMeeting';
export const DELETE_MEETING_SOCKET = 'deleteMeeting';
