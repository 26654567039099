import { combineReducers, Reducer } from 'redux';

import * as types from './quartals.types';
import * as constants from './quartals.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IQuartalPageState = {
  quartalsIds: [],
};

const quartalsReducer: Reducer<types.IQuartalPageState['quartalsIds'], types.IStoreQuartal | types.IClearQuartal> = (
  state = initialState.quartalsIds,
  action,
) => {
  if (isActionOfType<types.IStoreQuartal>(action, constants.STORE_QUARTAL)) {
    return action.payload;
  } else if (isActionOfType<types.IClearQuartal>(action, constants.CLEAR_QUARTAL)) {
    return initialState.quartalsIds;
  }

  return state;
};

export const quartalsPageReducer: Reducer<types.IQuartalPageState, types.IQuartalPageAction> = combineReducers({
  quartalsIds: quartalsReducer,
});
