import * as types from './widgets.types';
import * as constants from './widgets.constants';

export const storeWidgetVisibility = (key: types.IWidgetKey): types.IStoreWidgetVisibility => ({
  type: constants.STORE_WIDGET_VISIBLE,
  payload: key,
});
export const clearWidgetVisibility = (key: types.IWidgetKey): types.IClearWidgetVisibility => ({
  type: constants.CLEAR_WIDGET_VISIBLE,
  payload: key,
});
