import React, { FunctionComponent, useEffect } from 'react';
import { kpiRowSelectors } from '../../../../../../../../app/resource/kpi-row/kpi-row.selectors';
import { useSelectWithParams } from '../../../../../../../../utils/selector.utils';
import { KpiRowScoreboard } from './KpiRowScoreboard';
import { KpiRowScoreDetails } from './KpiRowScoreDetails';
import { KpiRowUserDetails } from './KpiRowUserDetails';
import { IWidgetKpiRowProps } from './types';

export const WidgetKpiRow: FunctionComponent<IWidgetKpiRowProps> = ({ kpiRowId, provided }) => {
  const kpiRow = useSelectWithParams(kpiRowSelectors.selectResourceById, kpiRowId);

  const kpiRowIds = kpiRow ? kpiRow.kpiRowScoreboardIds : [];

  useEffect(() => {
    console.log('rerender');
  }, [kpiRowIds]);
  return (
    kpiRow && (
      <div
        className="flex-table"
        role="rowgroup"
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <div className="flex-row first first-fixed" role="cell">
          <KpiRowUserDetails userId={kpiRow.userId} />
          <KpiRowScoreDetails scoreId={kpiRow.scoreId} />
        </div>
        <div className="flex-row f-14" role="cell">
          <div className="panel single-line w-auto">
            {kpiRow.kpiRowScoreboardIds.map((scoreId) => (
              <KpiRowScoreboard key={scoreId} scoreId={kpiRow.scoreId} kpiRowScoreboardId={scoreId} />
            ))}
          </div>
        </div>
      </div>
    )
  );
};
