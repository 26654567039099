/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';

import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';

import { useSelect, useSelectWithParams } from '../../../utils/selector.utils';

import { SubmitButton } from '../../pages/common/forms/SubmitButton';

import { ConnectedDialog } from '../ConnectedDialog';

import { IAgendaCustomFormDialogProps } from './types';
import { AgendaCustomItemForm } from '../../pages/common/forms/AgendaCustomItemForm';

import { boardsPageSelectors } from '../../../app/page/page-storage/boards/boards.selectors';

export const AgendaCustomFormDialog: FunctionComponent = () => {
  const dispatch = useDispatch();

  const props = useSelectWithParams(
    dialogSelectors.selectDialogProps,
    'agendaCustomFormDialog',
  ) as IAgendaCustomFormDialogProps;

  const onClose = useCallback(() => {
    dispatch(closeDialog('agendaCustomFormDialog'));
  }, [dispatch]);

  const boardIds = useSelect(boardsPageSelectors.selectBoards);

  return (
    <ConnectedDialog dialogKey="agendaCustomFormDialog">
      <div className="popup new_board_popup" style={{ display: 'block' }}>
        <div className="panel" style={{ minWidth: '700px' }}>
          <div className="panel-header small-header flex jcsb">
            <h3>Agenda</h3>
            <span className="close-popup close" onClick={onClose}>
              <i className="icon-close" />
            </span>
          </div>
          <div className="panel-body">
            {props && props.meetingId && <AgendaCustomItemForm meetingId={props.meetingId} boardIds={boardIds} />}
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" onClick={onClose}>
              Close
            </a>

            <SubmitButton formName="agendaCustomItem-form" text="Save" />
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
