import { combineReducers, Reducer } from 'redux';

import * as types from './page.types';

import { meetingsPageReducer } from './page-storage/meetings/meetings.reducer';
import { usersPageReducer } from './page-storage/users/users.reducer';
import { tasksPageReducer } from './page-storage/tasks/tasks.reducer';
import { agendaItemsPageReducer } from './page-storage/agenda-items/agenda-items.reducer';

import { pageStateReducer } from './page-state/page-state.reducer';
import { kpiPageReducer } from './page-storage/kpi/kpi.reducer';
import { conclusionsPageReducer } from './page-storage/conclusions/conclusions.reducer';
import { sessionsPageReducer } from './page-storage/sessions/sessions.reducer';
import { notificationsPageReducer } from './page-storage/notifications/notifications.reducer';
import { templatesPageReducer } from './page-storage/templates/templates.reducer';
import { calendarsPageReducer } from './page-storage/calendars/calendars.reducer';
import { chartPageReducer } from './page-storage/chart/chart.reducer';
import { boardsPageReducer } from './page-storage/boards/boards.reducer';
import { boardListsPageReducer } from './page-storage/boardLists/boardLists.reducer';
import { labelsPageReducer } from './page-storage/labels/labels.reducer';
import { checkListsPageReducer } from './page-storage/checklists/checkLists.reducer';
import { activitiesPageReducer } from './page-storage/activities/activities.reducer';
import { userGroupsPageReducer } from './page-storage/user-groups/user-groups.reducer';
import { passwordsPageReducer } from './page-storage/passwords/passwords.reducer';
import { passwordGroupsPageReducer } from './page-storage/password-groups/password-groups.reducer';
import { projectTasksPageReducer } from './page-storage/project-tasks/project-tasks.reducer';
import { projectTeamsPageReducer } from './page-storage/project-members/project-members.reducer';
import { projectsPageReducer } from './page-storage/projects/projects.reducer';
import { timerTrackerReducer } from './timer/timer.reducer';
import { accountProjectsPageReducer } from './page-storage/account-projects/account-projects.reducer';
import { projectTimesPageReducer } from './page-storage/project-times/project-times.reducer';
import { quartalsPageReducer } from './page-storage/quartals/quartals.reducer';
import { widgetsPageReducer } from './page-storage/widgets/widgets.reducer';
import { categoriesPageReducer } from './page-storage/categories/categories.reducer';
import { meetingsInfoPageReducer } from './page-storage/meetings-info/meetings-info.reducer';

export const pageReducer: Reducer<types.IPageState, types.IPageAction> = combineReducers({
  meetings: meetingsPageReducer,
  users: usersPageReducer,
  tasks: tasksPageReducer,
  sessions: sessionsPageReducer,
  kpi: kpiPageReducer,
  agendaItems: agendaItemsPageReducer,
  conclusions: conclusionsPageReducer,
  notifications: notificationsPageReducer,
  templates: templatesPageReducer,
  calendars: calendarsPageReducer,
  chart: chartPageReducer,
  boards: boardsPageReducer,
  boardLists: boardListsPageReducer,
  labels: labelsPageReducer,
  checkLists: checkListsPageReducer,
  activities: activitiesPageReducer,
  userGroups: userGroupsPageReducer,
  passwords: passwordsPageReducer,
  passwordGroups: passwordGroupsPageReducer,
  projectTasks: projectTasksPageReducer,
  projectTeams: projectTeamsPageReducer,
  projects: projectsPageReducer,
  projectTimes: projectTimesPageReducer,
  accountProjects: accountProjectsPageReducer,
  pageState: pageStateReducer,
  timer: timerTrackerReducer,
  quartals: quartalsPageReducer,
  widgetsPage: widgetsPageReducer,
  categories: categoriesPageReducer,
  meetingsInfo: meetingsInfoPageReducer,
});
