import { put, select, call, all } from 'redux-saga/effects';
import * as types from './quartal.types';
import * as actions from './quartal.actions';
import { apiRequest } from '../../api/api.saga-services';
import * as api from '../../api/request-config/quartal.api';
import { quartalSelectors } from './quartal.selectors';

export function* storeQuartal(quartal: types.IQuartal) {
  yield put(actions.storeQuartal(quartal));

  return quartal;
}

export function* clearQuartal(quartalId: types.IQuartal['id']) {
  yield put(actions.clearQuartal(quartalId));
}

export function* clearAllQuartals() {
  const quartalsIds: types.IQuartal['id'][] = yield select(quartalSelectors.selectResourceIds);
  yield put(actions.clearQuartal(quartalsIds));
}

export function* searchQuartals() {
  const quartals: types.IQuartal[] = yield call(apiRequest, api.searchQuartalsApi());
  if (quartals) {
    yield all(quartals.map((quartal) => call(storeQuartal, quartal)));
    return quartals.map((quartal) => quartal.id);
  }
}

export function* createQuartal(data: types.IQuartalCreate) {
  const quartal = (yield call(apiRequest, api.createQuartalApi(data))) as types.IQuartal;
  if (!quartal) {
    return;
  }
  yield call(storeQuartal, quartal);
  return quartal;
}

export function* updateQuartal(data: types.IQuartalUpdate) {
  const quartal = (yield call(apiRequest, api.updateQuartalApi(data))) as types.IQuartal;
  if (!quartal) {
    return;
  }
  quartal.id = data.id;
  yield call(storeQuartal, quartal);
  return quartal;
}

export function* deleteQuartal(quartalId: types.IQuartal['id']) {
  yield call(apiRequest, api.deleteQuartalApi(quartalId));
}
