/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { taskSelectors } from '../../../../../../../../../../app/resource/task/task.selectors';
import { useSelectWithParams } from '../../../../../../../../../../utils/selector.utils';
import { LabelMenu } from '../../TaskMenus/LabelMenu';
import { ITaskLabelsProps } from './types';

export const TaskLabels: FunctionComponent<ITaskLabelsProps> = ({ taskId }) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  //const close = useCallback(() => setActive(false), [setActive]);
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId);
  let counter = 0;
  return (
    task && (
      <div className="col">
        <h5 className="bold">LABELS</h5>
        <div className="users py-1 flex aic">
          {task.label &&
            task.label.map(
              (label) =>
                label && (
                  <a
                    className={`small-label mr-05`}
                    style={{ backgroundColor: `${label}` }}
                    key={`labelId-${label}${counter++}`}
                  ></a>
                ),
            )}

          <div className={isActive ? 'dropdown opened' : 'dropdown'}>
            <a className="small-label gray-bg blueGray bold mr-05 flex aic jcc dropdown-button" onClick={toggleClass}>
              <i className="icon-plus" />
            </a>
            {isActive && <LabelMenu dropRight={false} closeMenu={toggleClass} taskId={taskId} />}
          </div>
        </div>
      </div>
    )
  );
};
