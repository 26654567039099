/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { IUserWidgetProps } from './types';
export const UserWidget: FunctionComponent<IUserWidgetProps> = ({ user, onUserSelect }) => {
  const handleUserSelect = useCallback(() => onUserSelect(user.id), [user, onUserSelect]);

  return (
    <div className="contact-item" onClick={handleUserSelect}>
      <div className="contact-img">
        <img src={user.image} alt={`${user.firstName} ${user.lastName}`} className="img-fluid small-avatar" />
      </div>
      <div className="contact-name">
        {`${user.firstName} ${user.lastName}`}
        <div className="contact-links">
          <a className="blueGray" title="">
            <i className="icon2-at"></i>
          </a>
          {/* <a className="blueGray" title="">
            <i className="icon2-slack"></i>
          </a> */}
          {/* <a className="blueGray" title="">
            <i className="icon2-hangouts"></i>
          </a> */}
        </div>
      </div>
    </div>
  );
};
