import { combineReducers, Reducer } from 'redux';

import * as types from './meetings-info.types';
import * as constants from './meetings-info.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IMeetingsInfoPageState = {
  reducedMeetingsInfo: {},
  isFetched: false,
};

const reducedMeetingsInfoReducer: Reducer<
  types.IMeetingsInfoPageState['reducedMeetingsInfo'],
  types.IStoreReducedMeetingsInfo | types.IClearReducedMeetingsInfo
> = (state = initialState.reducedMeetingsInfo, action) => {
  if (isActionOfType<types.IStoreReducedMeetingsInfo>(action, constants.STORE_REDUCED_MEETINGS_INFO)) {
    return action.payload;
  } else if (isActionOfType<types.IClearReducedMeetingsInfo>(action, constants.CLEAR_REDUCED_MEETINGS_INFO)) {
    return initialState.reducedMeetingsInfo;
  }
  return state;
};

const isFetchedReducer: Reducer<
  types.IMeetingsInfoPageState['isFetched'],
  types.IStoreIsFetched | types.IClearIsFetched
> = (state = initialState.isFetched, action) => {
  if (isActionOfType<types.IStoreIsFetched>(action, constants.STORE_IS_FETCHED)) {
    return true;
  } else if (isActionOfType<types.IClearIsFetched>(action, constants.CLEAR_IS_FETCHED)) {
    return initialState.isFetched;
  }
  return state;
};

export const meetingsInfoPageReducer: Reducer<
  types.IMeetingsInfoPageState,
  types.IMeetingsInfoPageAction
> = combineReducers({
  reducedMeetingsInfo: reducedMeetingsInfoReducer,
  isFetched: isFetchedReducer,
});
