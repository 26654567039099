import { combineReducers, Reducer } from 'redux';

import * as types from './users.types';
import * as constants from './users.constants';
import { isActionOfType } from '../../../../utils/action.utils';

import { userFormReducer } from './user-form/user-form.reducer';

const initialState: types.IUsersPagePureState = {
  userIds: [],
  isFetched: false,
};

const userIdsReducer: Reducer<
  types.IUsersPagePureState['userIds'],
  types.IStoreFetchedUsers | types.IClearFetchedUsers
> = (state = initialState.userIds, action) => {
  if (isActionOfType<types.IStoreFetchedUsers>(action, constants.STORE_FETCHED_USERS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearFetchedUsers>(action, constants.CLEAR_FETCHED_USERS)) {
    return initialState.userIds;
  }
  return state;
};

const isFetchedReducer: Reducer<
  types.IUsersPagePureState['isFetched'],
  types.IStoreIsFetched | types.IClearIsFetched
> = (state = initialState.isFetched, action) => {
  if (isActionOfType<types.IStoreIsFetched>(action, constants.STORE_IS_FETCHED)) {
    return true;
  } else if (isActionOfType<types.IClearIsFetched>(action, constants.CLEAR_IS_FETCHED)) {
    return initialState.isFetched;
  }
  return state;
};

export const usersPageReducer: Reducer<types.IUsersPageState, types.IUsersPageAction> = combineReducers({
  userIds: userIdsReducer,
  isFetched: isFetchedReducer,
  userForm: userFormReducer,
});
