// import { TASK_SOCKET_EVENT, TASK_SOCKET_NAME } from '../page/page-storage/tasks/tasks.constants';

// export const TASK_SOCKET_CONFIG = {
//   channel: TASK_SOCKET_NAME,
//   actions: [TASK_SOCKET_EVENT],
// };

export const SOCKET_CONFIG = {
  channel: 'my-channel',
  actions: ['my-event'],
};
