import { createActionConst } from '../../../../utils/action.utils';

const MEETINGS_INFO_NAMESPACE = 'MEETINGS_INFO';

export const FETCH_MEETINGS_INFO = createActionConst(MEETINGS_INFO_NAMESPACE, 'FETCH_MEETINGS_INFO');

export const STORE_REDUCED_MEETINGS_INFO = createActionConst(MEETINGS_INFO_NAMESPACE, 'STORE_REDUCED_MEETINGS_INFO');
export const CLEAR_REDUCED_MEETINGS_INFO = createActionConst(MEETINGS_INFO_NAMESPACE, 'CLEAR_REDUCED_MEETINGS_INFO');

export const STORE_IS_FETCHED = createActionConst(MEETINGS_INFO_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(MEETINGS_INFO_NAMESPACE, 'CLEAR_IS_FETCHED');
