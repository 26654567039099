import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { saveScoreOrder } from '../../../../../../../app/page/page-storage/kpi/kpi.actions';

import { kpiPageSelectors } from '../../../../../../../app/page/page-storage/kpi/kpi.selectors';
import { useSelect } from '../../../../../../../utils/selector.utils';
import { WidgetKpiRow } from './WidgetKpiRow';

export const WidgetKpiBody: FunctionComponent = () => {
  const kpiRowIds = useSelect(kpiPageSelectors.selectKpiRowIds);
  const dispatch = useDispatch();
  const [rows, setRows] = useState(kpiRowIds);
  useEffect(() => {
    setRows(kpiRowIds);
  }, [kpiRowIds]);

  const handleDrop = useCallback(
    (result: DropResult) => {
      console.log(result);
      if (result.destination) {
        const rowId = result.draggableId;
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;

        if (sourceIndex !== destinationIndex) {
          if (destinationIndex > sourceIndex) {
            rows.splice(sourceIndex, 1);
            const newRows = [...rows.slice(0, destinationIndex - 1), rowId, ...rows.slice(destinationIndex - 1)];
            setRows(newRows);
            dispatch(saveScoreOrder(newRows));
          } else {
            rows.splice(sourceIndex, 1);
            const newRows = [...rows.slice(0, destinationIndex), rowId, ...rows.slice(destinationIndex)];
            setRows(newRows);
            dispatch(saveScoreOrder(newRows));
          }
        }
      }
    },
    [rows, dispatch],
  );

  return (
    <>
      <DragDropContext onDragEnd={handleDrop}>
        {rows.map((kpiRowId, index) => (
          <Droppable droppableId={kpiRowId} key={index} direction="vertical" type="column">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Draggable key={`draggable-row-${kpiRowId}`} draggableId={kpiRowId} index={index}>
                  {(provided) => <WidgetKpiRow key={kpiRowId} kpiRowId={kpiRowId} provided={provided} />}
                </Draggable>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
    </>
  );
};
