/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PageLayout } from '../common/PageLayout';

import { initialVtoPage } from '../../../app/page/vto/vto.actions';
import { useSelect, useSelectWithParams } from '../../../utils/selector.utils';
import { Section } from './Section';
import { ISectionCreate } from '../../../app/resource/section/section.types';
import { SectionFormTrigger } from '../../dialogs/SectionFormDialog/SectionFormTrigger';
import { createSection } from '../../../app/page/page-storage/templates/templates.actions';
import { pageStateSelectors } from '../../../app/page/page-state/page-state.selectors';
import { TemplateDropDown } from './TemplateDropDown';
import { ITemplate } from '../../../app/resource/template/template.types';
import { templatesPageSelectors } from '../../../app/page/page-storage/templates/templates.selectors';

export const VtoPage: FunctionComponent = () => {
  const dispatch = useDispatch();

  const templateIds = useSelect(templatesPageSelectors.selectTemplateIds);
  const [currentTemplate, setCurrentTemplate] = useState<null | ITemplate>(null);

  const [isFullWidth, setFullWidth] = useState(false);

  const showLoader = useSelectWithParams(pageStateSelectors.selectPageInProgress, 'vto');

  const handleCreateSection = useCallback(
    (section: ISectionCreate) => {
      dispatch(createSection(section));
    },
    [dispatch],
  );

  const [sectionLeft, sectionRight] = useMemo(
    () =>
      currentTemplate
        ? [
            currentTemplate.sectionIds.filter((sectionId, index) => !(index % 2)),
            currentTemplate.sectionIds.filter((sectionId, index) => index % 2),
          ]
        : [[], []],
    [currentTemplate],
  );

  useEffect(() => {
    dispatch(initialVtoPage());
  }, [dispatch]);
  return (
    <PageLayout showLoader={showLoader}>
      {!showLoader ? (
        <div className="row mb-25">
          <div className="col-12">
            <div className="panel vto-text">
              <div className="panel-header no-border">
                <h2 className="bold">Vision Board</h2>
                <div className="ml-auto flex aic">
                  <a className="btn btn-md btn-border white-bg" title="Export">
                    <i className="icon-upload mr-1 f-0 blueGray" /> Export
                  </a>
                  <a className="btn btn-md btn-border white-bg" title="Print">
                    <i className="icon2-print mr-1 f-0 blueGray" />
                    Print
                  </a>
                  <div className="toggle-buttons flex aic">
                    <a
                      className={`btn white-bg btn-md btn-border blueGray m-0 ${isFullWidth ? 'selected' : ''}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => setFullWidth(true)}
                    >
                      <i className="icon2-column-1" />
                    </a>
                    <a
                      className={`btn white-bg btn-md btn-border blueGray m-0 ${isFullWidth ? '' : 'selected'}`}
                      style={{ cursor: 'pointer' }}
                      onClick={() => setFullWidth(false)}
                    >
                      <i className="icon2-column-2" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="panel-body pt-0">
                <div className="border-radius-1 lightGray-bg p-2">
                  <div className="panel py-1 px-0 mb-1">
                    <div className="panel-header no-border flex aic ">
                      <div className="big-avatar-50 mr-2">
                        <img src={require('../../resources/images/atak-icon.png')} className="img-fluid" />
                      </div>
                      <h2 className="mr-3 f-2">ATAK Interactive</h2>

                      <div className="ml-auto flex aic">
                        <TemplateDropDown templateIds={templateIds} setCurrentTemplate={setCurrentTemplate} />
                        <a className="btn white-bg btn-border popup_button" data-popup="save_template_popup">
                          <i className="icon2-save f-1 blueGray mr-1" /> Save template
                        </a>
                        {currentTemplate && (
                          <SectionFormTrigger templateId={currentTemplate.id} onSubmit={handleCreateSection} />
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {isFullWidth ? (
                      <div className="col-12">
                        {currentTemplate &&
                          currentTemplate.sectionIds.map((sectionId) => (
                            <Section key={sectionId} sectionId={sectionId} />
                          ))}
                      </div>
                    ) : (
                      <>
                        <div className="col-6">
                          {currentTemplate &&
                            sectionLeft.map((sectionId) => (
                              <Section key={`currentTemplate.sections[${sectionId}]`} sectionId={sectionId} />
                            ))}
                        </div>
                        <div className="col-6">
                          {currentTemplate &&
                            sectionRight.map((sectionId) => (
                              <Section key={`currentTemplate.sections[${sectionId}]`} sectionId={sectionId} />
                            ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </PageLayout>
  );
};
