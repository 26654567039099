import { IProject, IProjectCreate, IProjectUpdate } from '../../resource/project/project.types';
import { IApiRequestConfig } from '../api.types';

export const searchProjectsApi = (accountId: string, projectId?: string): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'getProjects',
  uri: 'json',
  action: 'getProjects',
  method: 'get',
  params: {
    accountId,
    id: projectId,
  },
});

export const createProjectApi = (project: IProjectCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createProject',
  uri: 'json',
  action: 'saveProject',
  method: 'post',
  data: project,
});

export const updateProjectApi = (project: IProjectUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateProject',
  uri: 'json',
  action: 'saveProject',
  method: 'put',
  data: project,
});

export const deleteProjectApi = (projectId: IProject['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteProject',
  uri: 'json',
  action: 'deleteProject',
  method: 'delete',
  params: {
    id: projectId,
  },
});
