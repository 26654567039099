import { createActionConst } from '../../../../utils/action.utils';

const PROJECT_MEMBER_NAMESPACE = 'PROJECT_MEMBERS';

export const SETUP_PROJECT_TEAM = createActionConst(PROJECT_MEMBER_NAMESPACE, 'SETUP_PROJECT_TEAM');

export const CREATE_PROJECT_MEMBER = createActionConst(PROJECT_MEMBER_NAMESPACE, 'CREATE_PROJECT_MEMBER');
export const UPDATE_PROJECT_MEMBER = createActionConst(PROJECT_MEMBER_NAMESPACE, 'UPDATE_PROJECT_MEMBER');
export const DELETE_PROJECT_MEMBER = createActionConst(PROJECT_MEMBER_NAMESPACE, 'DELETE_PROJECT_MEMBER');

export const STORE_IS_FETCHED = createActionConst(PROJECT_MEMBER_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(PROJECT_MEMBER_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_PROJECT_TEAM = createActionConst(PROJECT_MEMBER_NAMESPACE, 'STORE_PROJECT_TEAM');
export const CLEAR_PROJECT_TEAM = createActionConst(PROJECT_MEMBER_NAMESPACE, 'CLEAR_PROJECT_TEAM');

export const SAVE_PROJECT_MEMBER_SOCKET = 'saveProjectMEMBERs';
export const DELETE_PROJECT_MEMBER_SOCKET = 'deleteProjectMEMBERs';
