import React, { FunctionComponent } from 'react';
import Avatar from 'react-avatar';
import { meetingSelectors } from '../../../../../app/resource/meeting/meeting.selectors';
import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { IMeetingBadgeProps } from './types';

export const MeetingBadge: FunctionComponent<IMeetingBadgeProps> = ({ meetingId, toggled, setToggled }) => {
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);

  return (
    meeting && (
      <Avatar
        style={{ cursor: 'pointer', marginLeft: '5px' }}
        className={toggled ? 'toggled' : ''}
        title={meeting.id}
        name={meeting.title}
        alt={meeting.id}
        color={meeting.color}
        size="25"
        round={true}
        onClick={setToggled}
      />
    )
  );
};
