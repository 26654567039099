import { combineReducers, Reducer } from 'redux';

import * as types from './boards.types';
import * as constants from './boards.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IBoardPageState = {
  boardsIds: [],
  currentBoard: null,
};

const boardsReducer: Reducer<types.IBoardPageState['boardsIds'], types.IStoreBoard | types.IClearBoard> = (
  state = initialState.boardsIds,
  action,
) => {
  if (isActionOfType<types.IStoreBoard>(action, constants.STORE_BOARD)) {
    return action.payload;
  } else if (isActionOfType<types.IClearBoard>(action, constants.CLEAR_BOARD)) {
    return initialState.boardsIds;
  }

  return state;
};

const currentBoardReducer: Reducer<
  types.IBoardPageState['currentBoard'],
  types.IStoreCurrentBoard | types.IClearCurrentBoard
> = (state = initialState.currentBoard, action) => {
  if (isActionOfType<types.IStoreCurrentBoard>(action, constants.STORE_CURRENT_BOARD)) {
    return action.payload;
  } else if (isActionOfType<types.IClearCurrentBoard>(action, constants.CLEAR_CURRENT_BOARD)) {
    return initialState.currentBoard;
  }
  return state;
};

export const boardsPageReducer: Reducer<types.IBoardPageState, types.IBoardPageAction> = combineReducers({
  boardsIds: boardsReducer,
  currentBoard: currentBoardReducer,
});
