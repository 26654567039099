/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { templateSelectors } from '../../../../app/resource/template/template.selectors';
import { ITemplate } from '../../../../app/resource/template/template.types';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { useToggler } from '../../../../utils/toggler.utils';
import { ITemplateDropDownProps } from './types';

export const TemplateDropDown: FunctionComponent<ITemplateDropDownProps> = ({ templateIds, setCurrentTemplate }) => {
  const selectedTemplates = useSelectWithParams(templateSelectors.selectResourcesById, templateIds);

  const templates = useMemo(() => selectedTemplates.filter((template) => !!template) as ITemplate[], [
    selectedTemplates,
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentTemplate = useMemo(() => templates[currentIndex], [currentIndex, templates]);

  useEffect(() => {
    setCurrentTemplate(currentTemplate);
  }, [currentTemplate, setCurrentTemplate]);

  const [isToggledPanel, handleTogglePanel] = useToggler();
  return (
    <div className="field-container custom-select small-select mb-0" onClick={handleTogglePanel}>
      <input type="hidden" />
      <span className={`select-field ${!isToggledPanel ? 'active' : ''}`}>
        <span className="select-value">{currentTemplate ? currentTemplate.title : ''}</span>
        <i className="icon-down-dir" />
      </span>
      <div
        className="select-dropdown"
        style={{ boxSizing: 'border-box', display: isToggledPanel ? 'none' : 'block' }}
        onMouseLeave={handleTogglePanel}
      >
        {templates.map((template, index) => (
          <div
            onClick={() => setCurrentIndex(index)}
            key={template.id}
            className={`select-item ${index === currentIndex ? 'selected' : ''}`}
          >
            {template.title}
          </div>
        ))}
      </div>
    </div>
  );
};
