import React from 'react';
import { FunctionComponent } from 'react';

import { FormCSTextInput } from '../../../../inputs/CustomSizeTextInput';
import { IFeesFormProps } from './types';

export const FeesForm: FunctionComponent<IFeesFormProps> = ({ projectData }) => {
  return (
    <>
      <div className="row project_fees_main" style={{ display: 'none !important' }}>
        <div className="col-12">
          <label className="w100 flex mb-1">Project Fees</label>
          <label className="blueGray">
            <small>Enter the amount you plan to invoice.</small>
          </label>
        </div>
        <div className="col-6 pr-1">
          <div className="field-container mb-3 max120">
            <div className="flex aic jcc">
              ${' '}
              <FormCSTextInput
                id="fixedFee"
                name="fixedFee"
                type="text"
                size="big-input"
                defaultValue={projectData?.fixedFee}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
