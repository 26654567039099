import { put, select, call, all } from 'redux-saga/effects';
import * as types from './project-report.types';
import * as actions from './project-report.actions';
//import * as api from './../../api/request-config/project-report.api';
import { projectReportSelectors } from './project-report.selectors';

export function* storeProjectReport(projectReport: types.IProjectReport) {
  yield put(actions.storeProjectReport(projectReport));

  return projectReport;
}

export function* clearProjectReport(projectReportId: types.IProjectReport['id']) {
  yield put(actions.clearProjectReport(projectReportId));
}

export function* clearAllProjectReports() {
  const projectReportsIds: types.IProjectReport['id'][] = yield select(projectReportSelectors.selectResourceIds);
  yield put(actions.clearProjectReport(projectReportsIds));
}

export function* searchProjectReports(projects: types.IProjectReport[]) {
  const projectReports: types.IProjectReport[] = projects;

  yield all(projectReports.map((projectReport) => call(storeProjectReport, projectReport)));
  return projectReports.map((projectReport) => projectReport.id);
}

export function* createProjectReport(data: types.IProjectReportCreate) {
  //const projectReport = (yield call(apiRequest, api.createProjectReportApi(data))) as types.IProjectReport;
  const projectReport = data as types.IProjectReport;
  if (!projectReport) {
    return;
  }
  yield call(storeProjectReport, projectReport);
  return projectReport;
}

export function* updateProjectReport(data: types.IProjectReportUpdate) {
  //const projectReport = (yield call(apiRequest, api.updateProjectReportApi(data))) as types.IProjectReport;
  const projectReport = data as types.IProjectReport;
  if (!projectReport) {
    return;
  }
  projectReport.id = data.id;
  yield call(storeProjectReport, projectReport);
  return projectReport;
}

export function* deleteProjectReport(projectReportId: types.IProjectReport['id']) {
  //yield call(apiRequest, api.deleteProjectReportApi(projectReportId));
}
