import { combineReducers, Reducer } from 'redux';

import * as types from './labels.types';
import * as constants from './labels.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.ILabelPageState = {
  labelsIds: [],
};

const labelsReducer: Reducer<types.ILabelPageState['labelsIds'], types.IStoreLabel | types.IClearLabel> = (
  state = initialState.labelsIds,
  action,
) => {
  if (isActionOfType<types.IStoreLabel>(action, constants.STORE_LABEL)) {
    return action.payload;
  } else if (isActionOfType<types.IClearLabel>(action, constants.CLEAR_LABEL)) {
    return initialState.labelsIds;
  }

  return state;
};

export const labelsPageReducer: Reducer<types.ILabelPageState, types.ILabelPageAction> = combineReducers({
  labelsIds: labelsReducer,
});
