import * as types from './calendars.types';

export const calendarsPageSelectors: types.ICalendarPageSelectors = {
  selectCalendarItems: (state) => state.page.calendars.calendarItemIds,
  selectFilteredCalendarItems: (state) =>
    state.page.calendars.calendarItemIds.map((calendarItemId) => ({
      ...state.resource.calendarItem.byId[calendarItemId],
      eventIds: state.resource.calendarItem.byId[calendarItemId].eventIds.filter((eventId) =>
        state.resource.event.byId[eventId]
          ? (state.page.calendars.toggledCalendarIds.length
              ? state.page.calendars.toggledCalendarIds.includes(state.resource.event.byId[eventId].calendarId)
              : true) &&
            state.resource.event.byId[eventId].summary
              .toLowerCase()
              .includes(state.page.calendars.descriptionParam.toLowerCase())
          : false,
      ),
    })),
  selectToggledCalendars: (state) => state.page.calendars.toggledCalendarIds,
  selectDescriptionParam: (state) => state.page.calendars.descriptionParam,
};
