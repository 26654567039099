import { IAnswerUpdate } from '../../resource/answer/answer.types';
import { IApiRequestConfig } from '../api.types';

export const updateAnswerApi = (answer: IAnswerUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateAnswer',
  uri: 'json',
  action: 'saveAnswer',
  method: 'put',
  data: answer,
});
