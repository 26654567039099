import { call, put } from 'redux-saga/effects';

import * as types from './section.types';
import * as actions from './section.actions';
import * as api from '../../api/request-config/section.api';
import { apiRequest } from '../../api/api.saga-services';

import * as templateService from '../../resource/template/template.saga-service';
import { select, selectWithParams } from '../../../utils/selector.utils';
import { templateSelectors } from '../template/template.selectors';
import { sectionSelectors } from './section.selectors';
import { ITemplate } from '../template/template.types';

export function* storeSection(section: types.ISection) {
  yield put(actions.storeSesction(section));
  return section;
}

export function* clearSection(sectionId: types.ISection['id']) {
  yield put(actions.clearSection(sectionId));
}

export function* clearAllSections() {
  const sectionIds: types.ISection['id'][] = yield select(sectionSelectors.selectResourceIds);
  yield put(actions.clearSection(sectionIds));
}

export function* createSection(sectionInfo: types.ISectionCreate) {
  const section = (yield call(apiRequest, api.createSectionApi(sectionInfo))) as types.ISection;
  if (!section) {
    return;
  }
  yield call(storeSection, section);

  const template = yield selectWithParams(templateSelectors.selectResourceById, section.templateId);
  yield call(templateService.storeTemplate, { ...template, sectionIds: [...template.sectionIds, section.id] });

  return section;
}

export function* updateSection(newSection: types.ISectionUpdate) {
  const section = (yield call(apiRequest, api.updateSectionApi(newSection))) as types.ISection;
  if (!section) {
    return;
  }
  yield call(storeSection, section);
  return section;
}

export function* deleteSection(sectionId: types.ISection['id']) {
  yield call(apiRequest, api.deleteSectionApi(sectionId));
  const section: types.ISection = yield selectWithParams(sectionSelectors.selectResourceById, sectionId);

  if (!section) {
    return;
  }

  yield call(clearSection, sectionId);

  const template: ITemplate = yield selectWithParams(templateSelectors.selectResourceById, section.templateId);
  if (!template) {
    return;
  }
  yield call(templateService.storeTemplate, {
    ...template,
    sectionIds: template.sectionIds.filter((id) => sectionId !== id),
  });
}
