import { combineReducers, Reducer } from 'redux';

import * as types from './widgets.types';
import * as constants from './widgets.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IWidgetsPageState = {
  pageWidgets: [],
};

const widgetsReducer: Reducer<types.IWidgetsPageState['pageWidgets'], types.IStoreWidgets | types.IClearWidgets> = (
  state = initialState.pageWidgets,
  action,
) => {
  if (isActionOfType<types.IStoreWidgets>(action, constants.STORE_WIDGETS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearWidgets>(action, constants.CLEAR_WIDGETS)) {
    return initialState.pageWidgets;
  }
  return state;
};

export const widgetsPageReducer: Reducer<types.IWidgetsPageState, types.IWidgetsPageAction> = combineReducers({
  pageWidgets: widgetsReducer,
});
