import { combineReducers, Reducer } from 'redux';
import { isActionOfType } from '../../../../utils/action.utils';

import * as types from './conclusions.types';
import * as constants from './conclusions.constants';

const initialState: types.IConclusionsPageState = {
  conclusions: [],
};

const conclusionsReducer: Reducer<
  types.IConclusionsPageState['conclusions'],
  types.IStoreConclusions | types.IClearConclusions
> = (state = initialState.conclusions, action) => {
  if (isActionOfType<types.IStoreConclusions>(action, constants.STORE_CONCLUSIONS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearConclusions>(action, constants.CLEAR_CONCLUSIONS)) {
    return initialState.conclusions;
  }
  return state;
};

export const conclusionsPageReducer: Reducer<
  types.IConclusionsPageState,
  types.IConclusionsPageAction
> = combineReducers({
  conclusions: conclusionsReducer,
});
