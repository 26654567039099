import * as types from './templates.types';
import * as constants from './templates.constants';
import { ITemplate, ITemplateCreate, ITemplateSearchParams } from '../../../resource/template/template.types';
import { ISection, ISectionCreate, ISectionUpdate } from '../../../resource/section/section.types';

export const fetchTemplates = (searchParams?: ITemplateSearchParams): types.IFetchTemplates => ({
  type: constants.FETCH_TEMPLATES,
  payload: searchParams,
});

export const createTemplate = (template: ITemplateCreate): types.ICreateTemplate => ({
  type: constants.CREATE_TEMPLATE,
  payload: template,
});

export const deleteTemplate = (templateId: ITemplate['id']): types.IDeleteTemplate => ({
  type: constants.DELETE_TEMPLATE,
  payload: templateId,
});

export const createSection = (sectionCreate: ISectionCreate): types.ICreateSection => ({
  type: constants.CREATE_SECTION,
  payload: sectionCreate,
});
export const updateSection = (sectionUpdate: ISectionUpdate): types.IUpdateSection => ({
  type: constants.UPDATE_SECTION,
  payload: sectionUpdate,
});

export const deleteSection = (sectionId: ISection['id']): types.IDeleteSection => ({
  type: constants.DELETE_SECTION,
  payload: sectionId,
});

export const storeTemplateIds = (templateIds: ITemplate['id'][]): types.IStoreTemplateIds => ({
  type: constants.STORE_TEMPLATES,
  payload: templateIds,
});

export const clearTemplateIds = (): types.IClearTemplateIds => ({
  type: constants.CLEAR_TEMPLATES,
});
