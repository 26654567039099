/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../../../../app/dialog/dialog.actions';
import { setupProject } from '../../../../../../../../app/page/page-storage/projects/projects.actions';

import { projectReportSelectors } from '../../../../../../../../app/resource/project-report/project-report.selectors';
import { useSelectWithParams } from '../../../../../../../../utils/selector.utils';

import { IAccountProjectProps } from './types';

export const AccountProject: FunctionComponent<IAccountProjectProps> = ({ projectReportId, accountId }) => {
  const projectReport = useSelectWithParams(projectReportSelectors.selectResourceById, projectReportId);
  const dispatch = useDispatch();
  const openProjectFormDialog = useCallback(() => {
    dispatch(openDialog('projectFormDialog', { projectReportId }));
  }, [dispatch, projectReportId]);
  const getProject = useCallback(() => {
    dispatch(setupProject(projectReportId, accountId));
  }, [accountId, dispatch, projectReportId]);
  return (
    <div className="tracking-accounts-table">
      <div className="tracking-small-row">
        <div className="tracking-small-client px-2">
          <a className="tracking-client-name">{projectReport?.name}</a>
        </div>
        <div className="tracking-client px-2">
          <div className="tracking-client-budget">
            <span>Budget</span> {projectReport?.budget}
          </div>
        </div>
        <div className="tracking-actions">
          <div className="tracking-client-spent mr-2">
            <span>Spent</span> {projectReport?.spent}
          </div>
          <div className="tracking-client-progress">
            <div className={`progressbar-container ${Number(projectReport?.remainingPercent) < 0 ? 'red-bg' : ''}`}>
              <div className="progressbar-progress" style={{ width: `${projectReport?.remainingPercent}%` }} />
            </div>
          </div>
          <div className="tracking-client budget-remaining">
            <div className="tracking-client-budget">
              <span>Remaining</span>{' '}
              <div className="remaining-amount">
                {projectReport?.remaining}{' '}
                <b className="f-14 light blueGray">{`(${projectReport?.remainingPercent}%)`}</b>
              </div>
            </div>
          </div>
          <div className="tracking-client budget-cost">
            <div className="tracking-client-budget">
              <span>Cost</span> <span className="f-14 blue">{`$${projectReport?.cost}`}</span>
            </div>
          </div>
          <div
            className="round-icon-small mr-1 popup_button"
            data-popup="new_team_popup"
            onClick={() => {
              openProjectFormDialog();
              getProject();
            }}
          >
            <i className="icon-pencil" />
          </div>
        </div>
      </div>
    </div>
  );
};
