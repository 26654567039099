import { combineReducers, Reducer } from 'redux';

import { isActionOfType } from '../../../../utils/action.utils';

import * as types from './calendars.types';
import * as constants from './calendars.constants';

const initialState: types.ICalendarPageState = {
  calendarItemIds: [],
  toggledCalendarIds: [],
  descriptionParam: '',
};

const calendarItemIdsReducer: Reducer<
  types.ICalendarPageState['calendarItemIds'],
  types.IStoreCalendarItems | types.IClearCalendarItems
> = (state = initialState.calendarItemIds, action) => {
  if (isActionOfType<types.IStoreCalendarItems>(action, constants.STORE_CALENDAR_ITEMS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearCalendarItems>(action, constants.CLEAR_CALENDAR_ITEMS)) {
    return initialState.calendarItemIds;
  }
  return state;
};

const toggledCalendarIdsReducer: Reducer<
  types.ICalendarPageState['toggledCalendarIds'],
  types.IStoreToggledCalendars | types.IClearToggledCalendars
> = (state = initialState.toggledCalendarIds, action) => {
  if (isActionOfType<types.IStoreToggledCalendars>(action, constants.STORE_TOGGLED_CALENDARS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearToggledCalendars>(action, constants.CLEAR_TOGGLED_CALENDARS)) {
    return initialState.toggledCalendarIds;
  }
  return state;
};

const descriptionParamReducer: Reducer<
  types.ICalendarPageState['descriptionParam'],
  types.IStoreDescriptionParam | types.IClearDescriptionParam
> = (state = initialState.descriptionParam, action) => {
  if (isActionOfType<types.IStoreDescriptionParam>(action, constants.STORE_DESCRIPTION_PARAM)) {
    return action.payload;
  } else if (isActionOfType<types.IClearDescriptionParam>(action, constants.CLEAR_DESCRIPTION_PARAM)) {
    return initialState.descriptionParam;
  }
  return state;
};

export const calendarsPageReducer: Reducer<types.ICalendarPageState, types.ICalendarPageAction> = combineReducers({
  calendarItemIds: calendarItemIdsReducer,
  toggledCalendarIds: toggledCalendarIdsReducer,
  descriptionParam: descriptionParamReducer,
});
