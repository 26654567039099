/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';

import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { LabelForm } from '../../pages/common/forms/LabelForm';
import { SubmitButton } from '../../pages/common/forms/SubmitButton';

import { ConnectedDialog } from '../ConnectedDialog';
import { ILabelFormDialogProps } from './types';

export const LabelFormDialog: FunctionComponent = () => {
  const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'labelFormDialog') as ILabelFormDialogProps;

  return (
    <ConnectedDialog dialogKey="labelFormDialog">
      <div className="edit-label-container" style={{ display: 'block' }}>
        <LabelForm {...props} />

        {!props && <SubmitButton apiKey="createLabel" formName="label-form" text="Save" />}
        {props && <SubmitButton apiKey="updateLabel" formName="label-form" text="Save" />}
      </div>
    </ConnectedDialog>
  );
};
