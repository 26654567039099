/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';

import Avatar from 'react-avatar';
import { IMeetingManuItemsProps } from './types';
import { useHistory } from 'react-router-dom';
import slugify from 'react-slugify';
import { IMeeting } from '../../../../../app/resource/meeting/meeting.types';

export const MeetingManuItems: FunctionComponent<IMeetingManuItemsProps> = ({ type, meetings }) => {
  const history = useHistory();
  const meetingsSorted = (meetings.filter((meeting) => !!meeting) as IMeeting[]).sort((a, b) =>
    a.title.localeCompare(b.title),
  ); // ako bas nisi filtrirao pre nego sto si poslao komponenti, filtriraj u komponenti

  // TODO remove added styles
  return (
    <>
      <div className="menu-title">{type === 'account' ? 'My Accounts' : 'My Teams'}</div>
      <ul className="accounts-menu">
        {meetings && meetings.length ? (
          meetingsSorted.map(
            (meeting) =>
              meeting && (
                <li
                  key={meeting.id}
                  onClick={() =>
                    history.push(`/meeting-dashboard/${meeting.id}/${slugify(meeting.title, { delimiter: '-' })}`)
                  }
                  style={{ zIndex: 0, cursor: 'pointer' }}
                >
                  <a>
                    {meeting.image.length > 1 ? (
                      <div className="small-avatar mr-05 white-bg">
                        <img src={meeting.image} alt="Test" className="img-fluid" style={{ borderRadius: '50%' }} />
                      </div>
                    ) : (
                      <div className="menu-img">
                        <Avatar
                          title="Review KPIs"
                          name={meeting.title}
                          alt="review KPIs"
                          color={meeting.color}
                          size="25"
                        />
                      </div>
                    )}

                    <span>{meeting.title}</span>
                  </a>
                </li>
              ),
          )
        ) : (
          <li>
            <a>
              <span>{type === 'account' ? '0 Accounts' : '0 Teams'}</span>
            </a>
          </li>
        )}
      </ul>
    </>
  );
};
