import { useCallback, useState } from 'react';

export const useAtUserSearch = ({
  setText,
  initText,
}: {
  setText: (text: ((oldText: string) => string) | string) => void;
  initText?: string;
}) => {
  const [searchMember, setSearchMember] = useState('');
  const [isActive, setActive] = useState(false);
  const toggleClass = useCallback(() => {
    setActive(!isActive);
  }, [isActive]);
  const [atCount, setAtCount] = useState(initText ? (initText.match(new RegExp('@', 'g')) || []).length : 0);

  const onTextChange = useCallback(
    (txt: string) => {
      setText(txt);
      const newAtCount = (txt.match(new RegExp('@', 'g')) || []).length;
      const splitByAt = txt.split('@');
      const searchString = splitByAt[splitByAt.length - 1].split('<')[0];

      setSearchMember(searchString);

      if (newAtCount > atCount) {
        setActive(true);
      } else if (newAtCount < atCount) {
        setActive(false);
      }
      setAtCount(newAtCount);
    },
    [atCount, setText],
  );
  const onSelectUser = useCallback(
    (userTag: string) => {
      setActive(false);
      setText((txt) => {
        const lastIndexOfAt = txt.lastIndexOf('@') + 1;
        const splitByAt = txt.split('@');
        const searchString = splitByAt[splitByAt.length - 1].split('<')[0];
        return txt.slice(0, lastIndexOfAt) + userTag + txt.slice(lastIndexOfAt + searchString.length);
      });
    },
    [setText],
  );

  return {
    searchMember,
    onSelectUser,
    onTextChange,
    toggleClass,
    setActive,
    isActive,
  };
};
