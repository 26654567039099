/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { checklistItemSelectors } from '../../../../../../../../../../../app/resource/checklistItem/checklistItem.selectors';
import { IChecklistItem } from '../../../../../../../../../../../app/resource/checklistItem/checklistItem.types';
import { useSelectWithParams } from '../../../../../../../../../../../utils/selector.utils';

import { IProgressBarProps } from './types';

export const ProgressBar: FunctionComponent<IProgressBarProps> = ({ checkList }) => {
  const checklistItems = useSelectWithParams(checklistItemSelectors.selectResourcesById, checkList.checklistItemsIds);
  const listLength = checklistItems.length;

  const listWithActiveStatus =
    listLength > 0
      ? checklistItems.filter((checklistItem) => checklistItem && checklistItem.status !== '0')
      : ([] as IChecklistItem[]);

  const progressBar = (listWithActiveStatus.length / listLength) * 100;
  return (
    <div className="progressbar-container my-2">
      <div className="progressbar-progress" style={{ width: `${progressBar}%` }} />
    </div>
  );
};
