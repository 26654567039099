import React, { FunctionComponent, useCallback, useState } from 'react';
import { IAvatarInputProps } from './types';
import Avatar from 'react-avatar-edit';
import { AvatarFileInput } from './AvatarFileInput';
import { useDispatch } from 'react-redux';
import { saveUserAvatar } from '../../../../../app/page/page-storage/users/user-form/user-form.actions';

export const AvatarInput: FunctionComponent<IAvatarInputProps> = ({
  id,
  name,
  defaultValue,
  validation,
  label,
  maxImageSize,
  acceptableFormats,
}) => {
  const src = defaultValue ? defaultValue : '';
  const [rerender, setRerender] = useState(true);

  const [avatar, setAvatar] = useState({ preview: undefined, src });

  const onClose = useCallback(() => {
    setAvatar({ ...avatar, preview: undefined });
  }, [avatar]);

  const dispatch = useDispatch();
  const onCrop = useCallback(
    (preview) => {
      setAvatar({ ...avatar, preview: preview });
      dispatch(saveUserAvatar(preview));
      setRerender(false);
      setRerender(true);
    },
    [avatar, dispatch],
  );

  return (
    <>
      <div className="new-account-image">
        {avatar.preview ? (
          <img className="round-icon" alt="" src={avatar.preview ? avatar.preview : src} />
        ) : (
          <span className="round-icon">
            <i className="icon-image"></i>
          </span>
        )}
      </div>

      <Avatar
        width={120}
        height={80}
        onCrop={onCrop}
        onClose={onClose}
        labelStyle={{ fontSize: '12px' }}
        onFileLoad={onCrop}
        src={src}
        mimeTypes={'.jpg, .gif, .png, .webp'}
      />
      {/* {!avatar.preview && (
        <div className="new-account-desc" style={{ paddingLeft: '25px' }}>
          <p className="bold">{label}</p>
          <p className="small-text">
            {`Acceptable formats: ${acceptableFormats ? acceptableFormats : 'All'}. Max size: ${maxImageSize}mb.`}
          </p>
        </div>
      )} */}
      {rerender && (
        <AvatarFileInput
          id={id}
          name={name}
          label={label}
          maxImageSize={maxImageSize}
          acceptableFormats={acceptableFormats}
          validation={validation}
          defaultValue={avatar.preview ? avatar.preview : defaultValue}
        />
      )}
    </>
  );
};
