import React, { FunctionComponent } from 'react';
import { userSelectors } from '../../../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../../../utils/selector.utils';
import { IUserDetailsProps } from './types';

export const UserDetails: FunctionComponent<IUserDetailsProps> = ({ userId }) => {
  const user = useSelectWithParams(userSelectors.selectResourceById, userId);

  return (
    user && (
      <div className="drop-item flex aic">
        <div className="big-avatar mr-1">
          <img src={user.image} alt={`${user.firstName} ${user.lastName}`} className="img-fluid" />
        </div>
        <h3 className="mr-3">
          {`${user.firstName} ${user.lastName}`} <span className="d-block w100">{user.jobTitle}</span>
        </h3>
      </div>
    )
  );
};
