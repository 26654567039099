import React, { FunctionComponent, useMemo } from 'react';

import { generateBoardListId } from '../../../../../../../../app/page/page-storage/tasks/tasks.saga-services';
import { boardListSelectors } from '../../../../../../../../app/resource/boards/boardList/boardList.selectors';
import { taskSelectors } from '../../../../../../../../app/resource/task/task.selectors';
import { ITask } from '../../../../../../../../app/resource/task/task.types';
import { useSelectWithParams } from '../../../../../../../../utils/selector.utils';
import { useToggler } from '../../../../../../../../utils/toggler.utils';
import { TaskCalendar } from '../../../../../widget-items/task-widget-items/TaskCalendar';
import { ICalendarItemDayProps } from './types';

export const CalendarItemDay: FunctionComponent<ICalendarItemDayProps> = ({ meetingId, date, userFilter }) => {
  const boardList = useSelectWithParams(
    boardListSelectors.selectResourceById,
    generateBoardListId(meetingId, 'groupNonDefaultByDate', { date }),
  );

  const boardTasks = useSelectWithParams(taskSelectors.selectResourcesById, boardList ? boardList.tasksIds : []);

  const qiList = useSelectWithParams(
    boardListSelectors.selectResourceById,
    generateBoardListId(meetingId, 'groupQIsByDate', {
      taskTypeId: '1',
      date: date,
    }),
  );

  const qiTasks = useSelectWithParams(taskSelectors.selectResourcesById, qiList ? qiList.tasksIds : []);

  const [isToggledPanel, handleTogglePanel] = useToggler();

  const filteredTasks = useMemo(
    () =>
      boardTasks.filter((task) =>
        task ? task.members.map((id) => userFilter.includes(id)).filter((contains) => !!contains).length > 0 : false,
      ) as ITask[],
    [boardTasks, userFilter],
  );
  const tasks = [...filteredTasks, ...qiTasks] as ITask[];

  const [firstTask, otherTasks] = useMemo<[undefined | ITask, ITask[]]>(() => {
    if (tasks[0]) {
      return [tasks[0], tasks.slice(1, tasks.length)];
    }
    return [undefined, []];
  }, [tasks]);

  return firstTask ? (
    <>
      <TaskCalendar taskId={firstTask.id} />

      {!!otherTasks.length && (
        <>
          <div className={`more-calendar-items ${!isToggledPanel ? 'opened' : ''}`}>
            {otherTasks.map((task) => (
              <TaskCalendar key={task.id} taskId={task.id} />
            ))}
            <button className="btn btn-sm red-bg f-1 mx-0 my-1 jcc hide_more_events w100" onClick={handleTogglePanel}>
              CLOSE
            </button>
          </div>
          <button
            className="btn btn-sm f-1 mx-0 my-1 jcc show_more_events w100"
            onClick={handleTogglePanel}
          >{`+${otherTasks.length} MORE`}</button>
        </>
      )}
    </>
  ) : (
    <></>
  );
};
