import * as types from './auth.types';
import * as constants from './auth.constants';
import { combineReducers, Reducer } from 'redux';
import { isActionOfType } from '../../utils/action.utils';

const initialState: types.IAuthState = {
  loggedUser: null,
  accessToken: null,
  googleGrantedPermision: false,
  rememberUser: false,
  userExpiresAt: null,
};

const loggedUserReducer: Reducer<types.IAuthState['loggedUser'], types.IStoreLoggedUser | types.IClearLoggedUser> = (
  state = initialState.loggedUser,
  action,
) => {
  if (isActionOfType<types.IStoreLoggedUser>(action, constants.STORE_LOGGED_USER)) {
    return action.payload;
  } else if (isActionOfType<types.IClearLoggedUser>(action, constants.CLEAR_LOGGED_USER)) {
    return null;
  }

  return state;
};

const accessTokenReducer: Reducer<types.IAuthState['accessToken'], types.IStoreLoggedUser | types.IClearLoggedUser> = (
  state = initialState.accessToken,
  action,
) => {
  if (isActionOfType<types.IStoreAccessToken>(action, constants.STORE_ACCESS_TOKEN)) {
    return action.payload;
  } else if (isActionOfType<types.IClearAccessToken>(action, constants.CLEAR_ACCESS_TOKEN)) {
    return null;
  }

  return state;
};

const googleGrantedPermisionReducer: Reducer<
  types.IAuthState['googleGrantedPermision'],
  types.IStoreGoogleGrantedPermision | types.IClearLoggedUser
> = (state = initialState.googleGrantedPermision, action) => {
  if (isActionOfType<types.IStoreGoogleGrantedPermision>(action, constants.STORE_GOOGLE_GRANTED_PERMISION)) {
    return action.payload;
  } else if (isActionOfType<types.IClearLoggedUser>(action, constants.CLEAR_GOOGLE_GRANTED_PERMISION)) {
    return state;
  }

  return state;
};

const rememberUserReducer: Reducer<
  types.IAuthState['rememberUser'],
  types.IStoreRememberUser | types.IClearRememberUser
> = (state = initialState.rememberUser, action) => {
  if (isActionOfType<types.IStoreRememberUser>(action, constants.STORE_REMEMBER_USER)) {
    return action.payload;
  } else if (isActionOfType<types.IClearRememberUser>(action, constants.CLEAR_REMEMBER_USER)) {
    return false;
  }

  return state;
};

const userExpiresAtReducer: Reducer<
  types.IAuthState['userExpiresAt'],
  types.IStoreUserExpiresAt | types.IClearUserExpiresAt
> = (state = initialState.userExpiresAt, action) => {
  if (isActionOfType<types.IStoreUserExpiresAt>(action, constants.STORE_USER_EXPIRES_AT)) {
    return action.payload;
  } else if (isActionOfType<types.IClearUserExpiresAt>(action, constants.CLEAR_USER_EXPIRES_AT)) {
    return null;
  }

  return state;
};

export const authReducer: Reducer<types.IAuthState, types.IAuthAction> = combineReducers({
  loggedUser: loggedUserReducer,
  accessToken: accessTokenReducer,
  googleGrantedPermision: googleGrantedPermisionReducer,
  rememberUser: rememberUserReducer,
  userExpiresAt: userExpiresAtReducer,
});
