import React, { FunctionComponent } from 'react';
import Loader from 'react-loader-spinner';
import { useApiRequestState } from '../../../../utils/api.utils';
import { IFooterProps } from '../types';
import { ForgotPassword } from './ForgotPassword';
import { RememberMe } from './RememberMe';

export const Footer: FunctionComponent<IFooterProps> = ({ setRememberUser }) => {
  const [showLoader] = useApiRequestState('login');
  return (
    <div className="flex jcsb my-3">
      <div className="remember-checkbox flex aic">
        <RememberMe setRememberUser={setRememberUser} />
        <ForgotPassword />
      </div>
      {!showLoader && (
        <button form="login-form" type="submit" className="btn btn-lg green-bg wide-btn">
          Sign In
        </button>
      )}
      {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={30} width={30} />}
    </div>
  );
};
