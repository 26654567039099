import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './templates.constants';
import * as types from './templates.types';
import * as services from './templates.saga-services';

function* invokeFetchTemplates(action: types.IFetchTemplates) {
  yield call(services.fetchTemplates, action.payload);
}

function* invokeCreateTemplate(action: types.ICreateTemplate) {
  yield call(services.createTemplate, action.payload);
}

function* invokeAddSection(action: types.ICreateSection) {
  yield call(services.addSection, action.payload);
}

function* invokeUpdateSection(action: types.IUpdateSection) {
  yield call(services.updateSection, action.payload);
}

function* invokeDeleteSection(action: types.IDeleteSection) {
  yield call(services.deleteSection, action.payload);
}

export function* watchTemplatesPageSaga() {
  yield takeLatest(constants.FETCH_TEMPLATES, invokeFetchTemplates);
  yield takeLatest(constants.CREATE_TEMPLATE, invokeCreateTemplate);
  yield takeLatest(constants.CREATE_SECTION, invokeAddSection);
  yield takeLatest(constants.UPDATE_SECTION, invokeUpdateSection);
  yield takeLatest(constants.DELETE_SECTION, invokeDeleteSection);
}
