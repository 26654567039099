import { combineReducers, Reducer } from 'redux';
import { isActionOfType } from '../../../../utils/action.utils';

import * as types from './tasks.types';
import * as constants from './tasks.constants';

const initialState: types.ITasksPageState = {
  reducedTasks: {},
};

const reducedTasksReducer: Reducer<
  types.ITasksPageState['reducedTasks'],
  types.IStoreReducedTasks | types.IClearReducedTasks
> = (state = initialState.reducedTasks, action) => {
  if (isActionOfType<types.IStoreReducedTasks>(action, constants.STORE_REDUCED_TASKS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearReducedTasks>(action, constants.CLEAR_REDUCED_TASKS)) {
    return initialState.reducedTasks;
  }
  return state;
};

export const tasksPageReducer: Reducer<types.ITasksPageState, types.ITasksPageAction> = combineReducers({
  reducedTasks: reducedTasksReducer,
});
