import { IMeeting } from '../../../resource/meeting/meeting.types';

import * as meetingResourceService from '../../../resource/meeting/meeting.saga-service';
import * as userResourceService from '../../../resource/user/user.saga-service';
import * as taskResourceService from '../../../resource/task/task.saga-service';
import * as dialogService from '../../../dialog/dialog.saga-services';

import * as actions from './tasks.actions';

import { select, selectWithParams } from '../../../../utils/selector.utils';
import { all, call, put, PutEffect, SelectEffect } from 'redux-saga/effects';
import { taskSelectors } from '../../../resource/task/task.selectors';
import {
  IDefaultTaskType,
  IGroupBy,
  IMoveTask,
  ITask,
  ITaskCreate,
  ITaskCreateForm,
  ITaskFromNotification,
  ITaskUpdate,
} from '../../../resource/task/task.types';
import { IReducedTasks } from './tasks.types';
import { IUser } from '../../../resource/user/user.types';
import { DropResult } from 'react-beautiful-dnd';
import { tasksPageSelectors } from './tasks.selectors';

import * as meetingService from '../../../page/page-storage/meetings/meetings.saga-services';
import * as boardService from '../boards/boards.saga-services';

import { serializeDate } from '../../../../utils/date.utils';
import { IBoardList } from '../../../resource/boards/boardList/boardList.types';
import { boardListSelectors } from '../../../resource/boards/boardList/boardList.selectors';
import { clearBoardList, storeBoardList } from '../../../resource/boards/boardList/boardList.actions';
import { userSelectors } from '../../../resource/user/user.selectors';
import { IBoard } from '../../../resource/boards/board/board.types';
import { storeBoard } from '../../../resource/boards/board/board.actions';
import { IStoreResourceAction } from '../../../resource/generators/resource.types';
import { storeTask } from '../../../resource/task/task.actions';
import { boardSelectors } from '../../../resource/boards/board/board.selectors';
import { meetingSelectors } from '../../../resource/meeting/meeting.selectors';
import { meetingsPageSelectors } from '../meetings/meetings.selectors';
import { saveNonDefaultByType } from './tasks-handlers/save-non-default-by-type.saga-service';
import { saveDefaultByType } from './tasks-handlers/save-default-by-type.saga-service';

export function* handleTaskFromNotificationTest(taskResponse: ITaskFromNotification) {
  if (!taskResponse.dragAndDrop) {
    const boardLists = ((yield all(findBoardListsToUpdate(taskResponse))) as IBoardList[]).filter(
      (boardList) => !!boardList,
    );

    yield put(storeTask(taskResponse));
    yield put(
      storeBoardList(
        boardLists.map((boardList) =>
          boardList.tasksIds.includes(taskResponse.id)
            ? { ...boardList, tasksIds: [...boardList.tasksIds] }
            : { ...boardList, tasksIds: [...boardList.tasksIds, taskResponse.id] },
        ),
      ),
    );
  }
}

export function* combinedHandleTaskFromNotification(taskResponse: ITaskFromNotification) {
  if (!taskResponse.dragAndDrop) {
    const meeting = (yield selectWithParams(
      meetingSelectors.selectResourceById,
      taskResponse.meetingIds[0],
    )) as IMeeting;
    if (meeting) {
      const meetingId = (yield select(meetingsPageSelectors.selectCurrentMeeting)) as IMeeting['id'];

      const tasks = (yield select(taskSelectors.selectResourceList)) as ITask[];
      const newTaskAdded = tasks
        .filter((task) => task.meetingIds.includes(meetingId))
        .map((task) => (task.id !== taskResponse.id ? task : taskResponse));

      const users = (yield selectWithParams(userSelectors.selectResourcesById, [
        ...meeting.shareWithIds,
        ...meeting.administratorIds,
      ])) as IUser[];

      const boards = (yield selectWithParams(boardSelectors.selectResourcesById, [
        generateBoardId(meeting.id, 'groupNonDefaultByDate'),
        generateBoardId(meeting.id, 'groupNonDefaultByUser'),
      ])) as IBoard[];

      // yield put(clearBoard(boards.map((b) => b.id)));

      const boardListIds: IBoardList['id'][] = [];

      boards.forEach((b) => {
        if (b) {
          if (b.taskTypeIds) {
            b.taskTypeIds.forEach((blId) => boardListIds.push(blId));
          }
        }
      });
      yield put(clearBoardList(boardListIds));

      const tempBoards = groupTasks(
        newTaskAdded,
        meeting.id,
        [
          'groupNonDefaultByDate',
          'groupNonDefaultByUser',
          'groupMeetingRocksByUser',
          // 'groupIssuesByUser',
          // 'groupHeadlinesByUser',
          'groupTodosByUser',
          // 'groupBackburnersByUser',
          'groupByDefaultTypes',
          'groupByNonDefaultTypes',
          'groupQIsByDate',
        ],
        users,
        [],
      );
      yield all(
        tempBoards.reduce(
          (prev, curr) => [
            ...prev,
            ...Object.values(curr.mapIdBoardList).map((tempBoardList) =>
              put(storeBoardList(convertTempBoardListToBoarList(tempBoardList))),
            ),
          ],
          [] as PutEffect<IStoreResourceAction<IBoardList>>[],
        ),
      );
      yield put(storeBoard(tempBoards.map((tempBoard) => convertTempBoardToBoard(tempBoard))));
      yield all([call(saveDefaultByType, taskResponse), call(saveNonDefaultByType, taskResponse)]);
      // const boardLists = ((yield all(findBoardListsToUpdate(taskResponse))) as IBoardList[]).filter(
      //   (boardList) => !!boardList,
      // );
      // const dateTaskListId = generateBoardListId(taskResponse.meetingIds[0], 'groupNonDefaultByDate', {
      //   date: taskResponse.dueDate,
      // });
      // const boardListForDate = boardLists.find((boardList) => boardList.id === dateTaskListId);
      // if (!boardListForDate) {
      //   const boardId = generateBoardId(taskResponse.meetingIds[0], 'groupNonDefaultByDate');
      //   const board = (yield selectWithParams(boardSelectors.selectResourceById, boardId)) as IBoard;
      //   const newBoardList = {
      //     id: dateTaskListId,
      //     name: '',
      //     boardId: board.id,
      //     slug: '',
      //     properties: null,
      //     flag: 'T',
      //     changedBy: '',
      //     changedAt: '',
      //     deleted: '',
      //     tasksIds: [taskResponse.id],
      //   };
      //   boardLists.push(newBoardList);
      //   yield put(storeBoard({ ...board, taskTypeIds: [...(board.taskTypeIds ?? []), dateTaskListId] }));
      // }
      // //yield put(storeTask(taskResponse));
      // yield put(
      //   storeBoardList(
      //     boardLists.map((boardList) =>
      //       boardList.tasksIds.includes(taskResponse.id)
      //         ? { ...boardList, tasksIds: [...boardList.tasksIds] }
      //         : { ...boardList, tasksIds: [...boardList.tasksIds, taskResponse.id] },
      //     ),
      //   ),
      // );
    }
  }

  // HOT FIX ==============================
  const defaultTaskTypeIds = ['1', '2', '3', '4', '5'];
  if (!defaultTaskTypeIds.includes(taskResponse.taskTypeId)) {
    yield put(storeTask(taskResponse));
    return;
  }

  // ======================================

  if (taskResponse.dragAndDrop) {
    const updatedTask: ITask = yield call(handleMoveTask, taskResponse.dragAndDrop as DropResult);

    if (updatedTask) {
      yield call(taskResourceService.storeTask, updatedTask);
    }
    return;
  }

  const reducedTasks = { ...(yield select(tasksPageSelectors.selectReducedTasks)) } as IReducedTasks;

  const oldTask: ITask | null = yield selectWithParams(taskSelectors.selectResourceById, taskResponse.id);

  if (oldTask && reducedTasks[oldTask.taskTypeId][oldTask.userId]) {
    if (oldTask.status !== '1') {
      reducedTasks[oldTask.taskTypeId][oldTask.userId].active.splice(
        reducedTasks[oldTask.taskTypeId][oldTask.userId].active.indexOf(oldTask.id),
        1,
      );
    } else {
      reducedTasks[oldTask.taskTypeId][oldTask.userId].done.splice(
        reducedTasks[oldTask.taskTypeId][oldTask.userId].done.indexOf(oldTask.id),
        1,
      );
    }
  }

  const task = (yield call(taskResourceService.storeTask, taskResponse)) as ITask;

  if (!reducedTasks) {
    return;
  }

  if (!reducedTasks[task.taskTypeId]) {
    reducedTasks[task.taskTypeId] = {};
  }

  if (!reducedTasks[task.taskTypeId][task.userId]) {
    reducedTasks[task.taskTypeId][task.userId] = { active: [], done: [] };
  }

  if (reducedTasks[task.taskTypeId][task.userId]) {
    if (task.status !== '1') {
      reducedTasks[task.taskTypeId][task.userId].active = reducedTasks[task.taskTypeId][task.userId].active.includes(
        task.id,
      )
        ? [...reducedTasks[task.taskTypeId][task.userId].active]
        : [...reducedTasks[task.taskTypeId][task.userId].active, task.id];
    } else {
      reducedTasks[task.taskTypeId][task.userId].done = reducedTasks[task.taskTypeId][task.userId].done.includes(
        task.id,
      )
        ? [...reducedTasks[task.taskTypeId][task.userId].done]
        : [...reducedTasks[task.taskTypeId][task.userId].done, task.id];
    }
  }
  yield put(actions.storeReducedTasks(reducedTasks));
}

export function* handleTaskFromNotification(taskResponse: ITaskFromNotification) {
  if (taskResponse) {
    const boardList: IBoardList = yield selectWithParams(
      boardListSelectors.selectResourceById,
      taskResponse.taskTypeId,
    );
    if (boardList && boardList.flag === 'B') {
      return;
    }

    if (taskResponse.dragAndDrop) {
      const updatedTask: ITask = yield call(handleMoveTask, taskResponse.dragAndDrop as DropResult);

      if (updatedTask) {
        yield call(taskResourceService.storeTask, updatedTask);
      }
      return;
    }

    const reducedTasks = { ...(yield select(tasksPageSelectors.selectReducedTasks)) } as IReducedTasks;

    const oldTask: ITask | null = yield selectWithParams(taskSelectors.selectResourceById, taskResponse.id);

    if (oldTask && reducedTasks[oldTask.taskTypeId][oldTask.userId]) {
      if (oldTask.status !== '1') {
        reducedTasks[oldTask.taskTypeId][oldTask.userId].active.splice(
          reducedTasks[oldTask.taskTypeId][oldTask.userId].active.indexOf(oldTask.id),
          1,
        );
      } else {
        reducedTasks[oldTask.taskTypeId][oldTask.userId].done.splice(
          reducedTasks[oldTask.taskTypeId][oldTask.userId].done.indexOf(oldTask.id),
          1,
        );
      }
    }

    const task = (yield call(taskResourceService.storeTask, taskResponse)) as ITask;

    if (!reducedTasks) {
      return;
    }

    if (!reducedTasks[task.taskTypeId]) {
      reducedTasks[task.taskTypeId] = {};
    }

    if (!reducedTasks[task.taskTypeId][task.userId]) {
      reducedTasks[task.taskTypeId][task.userId] = { active: [], done: [] };
    }

    if (reducedTasks[task.taskTypeId][task.userId]) {
      if (task.status !== '1') {
        reducedTasks[task.taskTypeId][task.userId].active = reducedTasks[task.taskTypeId][task.userId].active.includes(
          task.id,
        )
          ? [...reducedTasks[task.taskTypeId][task.userId].active]
          : [...reducedTasks[task.taskTypeId][task.userId].active, task.id];
      } else {
        reducedTasks[task.taskTypeId][task.userId].done = reducedTasks[task.taskTypeId][task.userId].done.includes(
          task.id,
        )
          ? [...reducedTasks[task.taskTypeId][task.userId].done]
          : [...reducedTasks[task.taskTypeId][task.userId].done, task.id];
      }
    }
    yield put(actions.storeReducedTasks(reducedTasks));
  }
}

export function* handleMoveTask(result: DropResult, newLogic = false) {
  if (!result || !result.destination) {
    return;
  }
  if (newLogic) {
    const destinationId = result.destination.droppableId;
    const sourceId = result.source.droppableId;
    const sourceObj = JSON.parse(sourceId);
    const destinationObj = JSON.parse(destinationId);

    const { status, taskTypeId } = JSON.parse(destinationId);
    let taskId = result.draggableId;
    if (sourceObj.groupBy === 'groupNonDefaultByUser' && destinationObj.groupBy === 'groupNonDefaultByUser') {
      const sepIndex = taskId.indexOf('-');
      taskId = taskId.slice(0, sepIndex);
    }

    const task = (yield selectWithParams(taskSelectors.selectResourceById, taskId)) as ITask;
    if (sourceObj.groupBy === 'groupNonDefaultByUser' && destinationObj.groupBy === 'groupNonDefaultByUser') {
      const sourceMember = sourceObj.userId;
      const destinationMember = destinationObj.userId;
      const newMembers = task.members.filter((user) => user !== sourceMember);
      const uniqueMembers = Array.from(new Set([...newMembers, destinationMember]));
      const updateTask = {
        ...task,
        status: status === 'done' ? '1' : '0',
        members: uniqueMembers,
      };
      yield put(storeTask(updateTask));
      return updateTask;
    } else {
      const updateTask = {
        ...task,
        status: status === 'done' ? '1' : '0',
        taskTypeId: taskTypeId,
      };
      yield put(storeTask(updateTask));
      return updateTask;
    }
  } else {
    const taskId = result.draggableId;
    const task = (yield selectWithParams(taskSelectors.selectResourceById, taskId)) as ITask;

    const [type, userId, taskStatus] = result.destination.droppableId.split('/');

    if (!type || !taskStatus) {
      return;
    }

    const destinationUserId = userId ? userId : task.userId;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const reducedTasks = { ...((yield select(tasksPageSelectors.selectReducedTasks)) as IReducedTasks) };

    if (!reducedTasks[type]) {
      reducedTasks[type] = {};
    }

    if (!reducedTasks[type][destinationUserId]) {
      reducedTasks[type][destinationUserId] = { active: [], done: [] };
    }

    if (
      (taskStatus === 'active' && reducedTasks[type][destinationUserId].active.indexOf(taskId) === destinationIndex) ||
      (taskStatus !== 'active' && reducedTasks[type][destinationUserId].done.indexOf(taskId) === destinationIndex)
    ) {
      return;
    }

    if (reducedTasks[task.taskTypeId][task.userId] && reducedTasks[type][destinationUserId]) {
      if (task.status !== '1') {
        reducedTasks[task.taskTypeId][task.userId].active.splice(sourceIndex, 1);
      } else {
        reducedTasks[task.taskTypeId][task.userId].done.splice(sourceIndex, 1);
      }

      if (taskStatus === 'active') {
        reducedTasks[type][destinationUserId].active.splice(destinationIndex, 0, taskId);
      } else {
        reducedTasks[type][destinationUserId].done.splice(destinationIndex, 0, taskId);
      }
    }

    const updateTask = {
      ...task,
      taskTypeId: type,
      userId: destinationUserId,
      status: taskStatus === 'active' ? task.status : '1',
      sort: destinationIndex.toString(),
    };

    yield call(taskResourceService.storeTask, updateTask);
    yield put(actions.storeReducedTasks(reducedTasks));
    return updateTask;
  }
}

export function* reduceAndStoreTasks(tasks: ITask[]) {
  yield put(
    actions.storeReducedTasks(
      tasks.reduce((prev, curr) => {
        if (!prev[curr.taskTypeId]) {
          prev[curr.taskTypeId] = {};
        }
        const userId = curr.userId ? curr.userId : 'uncategorised';

        if (!prev[curr.taskTypeId][userId]) {
          prev[curr.taskTypeId][userId] = {
            active: [],
            done: [],
          };
        }

        if (curr.status !== '1') {
          prev[curr.taskTypeId][userId].active = [...prev[curr.taskTypeId][userId].active, curr.id];
        } else {
          prev[curr.taskTypeId][userId].done = [...prev[curr.taskTypeId][userId].done, curr.id];
        }
        return { ...prev };
      }, {} as { [taskTypeKey: string]: { [userKey: string]: { active: ITask['id'][]; done: ITask['id'][] } } }),
    ),
  );
}

export function* clearReducedTasks() {
  yield put(actions.clearReducedTasks());
}

export function* setupTasksForMeeting(meetingId: IMeeting['id']) {
  const meetingAndBoards = (yield all([
    call(meetingResourceService.populateTasks, meetingId),
    call(boardService.setupBoards, meetingId),
  ])) as (IMeeting | IBoard[])[];

  const meeting = meetingAndBoards[0] as IMeeting;
  const boards = meetingAndBoards[1] as IBoard[];

  if (!meeting || !meeting.taskIds) {
    return;
  }

  const tasks = (yield selectWithParams(taskSelectors.selectResourcesById, meeting.taskIds)) as ITask[];
  const users = (yield selectWithParams(userSelectors.selectResourcesById, [
    ...meeting.shareWithIds,
    ...meeting.administratorIds,
  ])) as IUser[];

  // const boards = (yield selectWithParams(boardSelectors.selectResourcesById, [])) as IUser[];

  const tempBoards = groupTasks(
    tasks,
    meeting.id,
    [
      'groupNonDefaultByDate',
      'groupNonDefaultByUser',
      'groupMeetingRocksByUser',
      // 'groupIssuesByUser',
      // 'groupHeadlinesByUser',
      'groupTodosByUser',
      // 'groupBackburnersByUser',
      'groupByDefaultTypes',
      'groupByNonDefaultTypes',
      'groupQIsByDate',
    ],
    users,
    boards,
  );
  yield all(tempBoards.map((tempBoard) => put(storeBoard(convertTempBoardToBoard(tempBoard)))));
  yield all(
    tempBoards.reduce(
      (prev, curr) => [
        ...prev,
        ...Object.values(curr.mapIdBoardList).map((tempBoardList) =>
          put(storeBoardList(convertTempBoardListToBoarList(tempBoardList))),
        ),
      ],
      [] as PutEffect<IStoreResourceAction<IBoardList>>[],
    ),
  );
  yield call(reduceAndStoreTasks, tasks);
  yield call(meetingService.storeCurrentMeeting, meetingId);
}

export function* setupTasksForUser(userId: IUser['id']) {
  const user = (yield call(userResourceService.populateTasks, userId)) as IUser;
  const tasks = (yield selectWithParams(taskSelectors.selectResourcesById, user.taskIds as ITask['id'][])) as ITask[];
  const tempBoards = groupTasks(tasks, '', ['groupNonDefaultByMember', 'groupMeetingRocksByUser'], [user], []);
  yield all(tempBoards.map((tempBoard) => put(storeBoard(convertTempBoardToBoard(tempBoard)))));
  yield all(
    tempBoards.reduce(
      (prev, curr) => [
        ...prev,
        ...Object.values(curr.mapIdBoardList).map((tempBoardList) =>
          put(storeBoardList(convertTempBoardListToBoarList(tempBoardList))),
        ),
      ],
      [] as PutEffect<IStoreResourceAction<IBoardList>>[],
    ),
  );
  yield all(tempBoards.map((tempBoard) => put(storeBoard(convertTempBoardToBoard(tempBoard)))));

  yield call(reduceAndStoreTasks, tasks);
}

export function* moveTask(result: DropResult, newLogic = false) {
  if (newLogic) {
    const updateTask = (yield call(handleMoveTask, result, newLogic)) as ITask;
    if (updateTask) {
      yield call(taskResourceService.updateTask, {
        id: updateTask.id,
        title: updateTask.title,
        description: updateTask.description,
        dueDate: serializeDate(updateTask.dueDate),
        reminder: '', // updateTask.reminder
        userId: updateTask.userId,
        meetingIds: updateTask.meetingIds,
        type: updateTask.type,
        status: updateTask.status,
        dragAndDrop: result,
        label: updateTask.label,
        taskTypeId: updateTask.taskTypeId,
        members: updateTask.members,
        showInTasks: updateTask.showInTasks,
        boardId: updateTask.boardId,
      } as ITaskUpdate);
    }
  } else {
    if (!result || !result.destination) {
      return;
    }

    const taskId = result.draggableId;
    const task = (yield selectWithParams(taskSelectors.selectResourceById, taskId)) as ITask;

    const [type, , taskStatus] = result.destination.droppableId.split('/');

    if (task.status === '1' && taskStatus === 'active') {
      alert(`You can't put done task to active.`);
      return;
    }
    if (!type || !taskStatus) {
      return;
    }

    const updateTask = (yield call(handleMoveTask, result)) as ITask;

    if (updateTask) {
      yield call(taskResourceService.updateTask, {
        id: updateTask.id,
        title: updateTask.title,
        description: updateTask.description,
        dueDate: serializeDate(updateTask.dueDate),
        reminder: '', // updateTask.reminder
        userId: updateTask.userId,
        meetingIds: updateTask.meetingIds,
        type: updateTask.type,
        status: updateTask.status,
        dragAndDrop: result,
        label: updateTask.label,
        taskTypeId: updateTask.taskTypeId,
        members: updateTask.members,
        showInTasks: updateTask.showInTasks,
        boardId: updateTask.boardId,
      } as ITaskUpdate);
    }
  }
}

export function* handleMoveBoardTaskFromNotification(result: DropResult) {
  if (!result.destination) {
    return;
  }
  const taskId = result.draggableId;
  const destinationId = result.destination.droppableId;
  const destinationIndex = result.destination.index;
  const sourceId = result.source.droppableId;
  const sourceIndex = result.source.index;

  if (sourceId === destinationId && destinationIndex === sourceIndex) {
    return;
  }

  const destination = (yield selectWithParams(boardListSelectors.selectResourceById, destinationId)) as IBoardList;
  if (!destination || destination.tasksIds[destinationIndex] === taskId) {
    return;
  }

  let source: IBoardList | null = null;
  if (sourceId !== destinationId) {
    source = (yield selectWithParams(boardListSelectors.selectResourceById, sourceId)) as IBoardList;
  }

  if (source) {
    const sourceTaskIds = [...source.tasksIds];
    sourceTaskIds.splice(sourceIndex, 1);
    yield put(storeBoardList({ ...source, tasksIds: sourceTaskIds }));
  }

  const destinationTaskIds = [...destination.tasksIds];
  if (!source) {
    destinationTaskIds.splice(sourceIndex, 1);
  }
  destinationTaskIds.splice(destinationIndex, 0, taskId);
  yield put(storeBoardList({ ...destination, tasksIds: destinationTaskIds }));
}

export function* moveBoardTask(result: DropResult) {
  yield call(handleMoveBoardTaskFromNotification, result);
  if (!result.destination) {
    return;
  }
  yield call(taskResourceService.moveTask, {
    id: result.draggableId,
    taskTypeId: result.destination.droppableId,
    dragAndDrop: result,
  } as IMoveTask);
}

export function* createTask(taskInfo: ITaskCreateForm) {
  const task = (yield call(taskResourceService.createTask, mapTaskFormToTaskCreate(taskInfo))) as ITask;
  if (!task) {
    return;
  }
  yield call(dialogService.closeDialog, 'taskFormDialog');
}

export function* updateTask(taskId: ITask['id'], taskInfo: ITaskCreateForm) {
  const task = (yield call(taskResourceService.updateTask, {
    ...mapTaskFormToTaskCreate(taskInfo),
    id: taskId,
  })) as ITask;

  if (!task) {
    return;
  }
  yield call(dialogService.closeDialog, 'taskFormDialog');
}

export function* deleteTask(taskId: ITask['id']) {
  yield call(taskResourceService.deleteTask, taskId);
}

export function* deleteTaskSocket(taskId: ITask['id']) {
  const task = (yield selectWithParams(taskSelectors.selectResourceById, taskId)) as ITask;
  yield call(taskResourceService.clearTask, taskId);
  if (task) {
    const reducedTasks = { ...((yield select(tasksPageSelectors.selectReducedTasks)) as IReducedTasks) };

    if (!reducedTasks[task.type] || !Object.keys(reducedTasks[task.type])) {
      return;
    }

    if (reducedTasks[task.type][task.userId].active.includes(taskId)) {
      reducedTasks[task.type][task.userId].active = reducedTasks[task.type][task.userId].active.filter(
        (id) => id !== taskId,
      );
    }

    if (reducedTasks[task.type][task.userId].done.includes(taskId)) {
      reducedTasks[task.type][task.userId].done = reducedTasks[task.type][task.userId].done.filter(
        (id) => id !== taskId,
      );
    }

    yield put(actions.storeReducedTasks(reducedTasks));
  }
}

export function* filterTaskForUserByMeetingIds(userId: IUser['id'], meetingIds: IMeeting['id'][]) {
  const user = (yield call(userResourceService.populateTasks, userId)) as IUser;

  if (!user) {
    return;
  }

  const tasks = (yield selectWithParams(taskSelectors.selectResourcesById, user.taskIds as ITask['id'][])) as ITask[];
  const check = [];
  for (const task of tasks) {
    if (task && !task.meetingIds[0]) {
      check.push(task);
    } else {
      for (const meetingId of meetingIds) {
        if (task && task.meetingIds.includes(meetingId)) {
          check.push(task);
          break;
        }
      }
    }
  }

  yield call(reduceAndStoreTasks, check);
}

export const mapTaskFormToTaskCreate = (taskInfo: ITaskCreateForm): ITaskCreate => ({
  title: taskInfo.title,
  description: taskInfo.description,
  dueDate: serializeDate(taskInfo.dueDate),
  reminder: taskInfo.reminder ? taskInfo.reminder.value : '',
  userId: taskInfo.user.value,
  meetingIds: taskInfo.meeting.map((m) => m.value),
  type: taskInfo.type,
  status: taskInfo.status ? taskInfo.status.value : '0',
  label: [],
  taskTypeId: taskInfo.taskTypeId,
  members: [],
  showInTasks: taskInfo.showInTasks ? taskInfo.showInTasks : '1',
  boardId: taskInfo.boardId,
});

export function* clearTasksPageStorage() {
  yield put(actions.clearReducedTasks());
}

export type TStatus = 'active' | 'done' | undefined;
export const backendStatusToTStatus = (status: string): TStatus => (status !== '1' ? 'active' : 'done');

export const generateActiveComplexId = (complexId: string) =>
  JSON.stringify({ ...JSON.parse(complexId), status: 'active' });

export const generateDoneComplexId = (complexId: string) =>
  JSON.stringify({ ...JSON.parse(complexId), status: 'done' });

export const generateActiveDoneComplexIds = (complexId: string) => [
  generateActiveComplexId(complexId),
  generateDoneComplexId(complexId),
];

export const extractBoardListIds = (board: IBoard | undefined | null) =>
  board && board.taskTypeIds
    ? board.taskTypeIds.filter((boardListId) => JSON.parse(boardListId).status === 'active')
    : [];

// export const useExtractBoardListIds = (complexBoardId: IBoard['id']) => {
//   const board = useSelectWithParams(boardSelectors.selectResourceById, complexBoardId);
//   const boardListIds = useMemo(() => extractBoardListIds(board), [board]);
//   return boardListIds;
// };

export const decideBoardListTypeBasedOnComplexId = (complexId: string) => {
  const idObject = JSON.parse(complexId);
  if (+idObject.taskTypeId < 6) {
    return 'default';
  }
  return 'nonDoardList';
};

export interface ITempBoardList {
  id: string;
  boardId: IBoard['id'];
  taskIds: ITask['id'][];
  title?: string;
  taskTypeId?: IDefaultTaskType | string;
  userId?: IUser['id'];
  date?: string;
}

export interface IMapIdToBoardLists {
  [id: string]: ITempBoardList;
}

export interface ITempBoard {
  id: string;
  groupBy: IGroupBy;
  meetingId: IMeeting['id'];
  mapIdBoardList: { [id: string]: ITempBoardList };
  title: string;
}

export function generateBoardId(meetingId: IMeeting['id'], groupBy: IGroupBy) {
  return JSON.stringify({ idType: 'board', meetingId, groupBy });
}

export function generateBoardListId(
  meetingId: IMeeting['id'],
  groupBy: string,
  aditionalMark?: {
    taskTypeId?: IDefaultTaskType | string;
    userId?: IUser['id'];
    date?: string;
  },
  status?: TStatus,
) {
  return JSON.stringify({
    idType: 'boardList',
    meetingId,
    groupBy,
    taskTypeId: aditionalMark?.taskTypeId,
    userId: aditionalMark?.userId,
    date: aditionalMark?.date ? serializeDate(aditionalMark?.date) : undefined,
    status,
  });
}

const titles = {
  groupTodosByDate: 'Todos grouped by dates',
  groupNonDefaultByUser: 'Action items',
  groupNonDefaultByMember: 'Tasks per members',
  groupNonDefaultByDate: 'Tasks grouped by dates',
  groupMeetingRocksByMember: 'Quarterly Initiatives',
  groupIssuesByMember: 'Issues',
  groupHeadlinesByMember: 'Headlines',
  groupTodosByMember: 'To Do List',
  groupQIsByDate: 'QI List',
  groupBackburnersByMember: 'Backbursers',
  groupMeetingRocksByUser: 'Quarterly Initiatives',
  groupIssuesByUser: 'Issues',
  groupHeadlinesByUser: 'Headlines',
  groupTodosByUser: 'To Do List',
  groupBackburnersByUser: 'Backbursers',
  groupByDefaultTypes: 'Grouped by default types',
  groupByNonDefaultTypes: 'Grouped by non default types',
};
export function generateBoards(meetingId: IMeeting['id'], groupBy: IGroupBy[]) {
  return groupBy.map((by) => ({
    id: generateBoardId(meetingId, by),
    groupBy: by,
    meetingId,
    mapIdBoardList: {},
    title: titles[by] ? titles[by] : '',
  }));
}

export function generateBoardListsForBoard(board: ITempBoard, users: IUser[], tasks: ITask[], realBoards: IBoard[]) {
  if (board.groupBy === 'groupNonDefaultByUser') {
    return groupNonDefaultByUser(board, users);
  } else if (board.groupBy === 'groupNonDefaultByMember') {
    return groupNonDefaultByMember(board, users);
  } else if (board.groupBy === 'groupNonDefaultByDate') {
    return groupNonDefaultByDate(board, tasks);
  } else if (board.groupBy === 'groupTodosByDate') {
    return groupDefaultTypeByDate(board, tasks, '4');
  } else if (board.groupBy === 'groupQIsByDate') {
    return groupDefaultTypeByDate(board, tasks, '1');
  } else if (board.groupBy === 'groupMeetingRocksByMember') {
    return groupDefaultByMember(board, users, '1');
  } else if (board.groupBy === 'groupIssuesByMember') {
    return groupDefaultByMember(board, users, '2');
  } else if (board.groupBy === 'groupHeadlinesByMember') {
    return groupDefaultByMember(board, users, '3');
  } else if (board.groupBy === 'groupTodosByMember') {
    return groupDefaultByMember(board, users, '4');
  } else if (board.groupBy === 'groupBackburnersByMember') {
    return groupDefaultByMember(board, users, '5');
  } else if (board.groupBy === 'groupMeetingRocksByUser') {
    return groupDefaultByUser(board, users, '1');
  } else if (board.groupBy === 'groupIssuesByUser') {
    return groupDefaultByUser(board, users, '2');
  } else if (board.groupBy === 'groupHeadlinesByUser') {
    return groupDefaultByUser(board, users, '3');
  } else if (board.groupBy === 'groupTodosByUser') {
    return groupDefaultByUser(board, users, '4');
  } else if (board.groupBy === 'groupBackburnersByUser') {
    return groupDefaultByUser(board, users, '5');
  } else if (board.groupBy === 'groupByDefaultTypes') {
    return groupDefaultByType(board);
  } else if (board.groupBy === 'groupByNonDefaultTypes') {
    return groupNonDefaultByType(board, realBoards);
  }
  return {};
}

export function groupDefaultByMembersGenerateIds(
  meetingId: IMeeting['id'],
  userIds: IUser['id'][],
  defaultType: IDefaultTaskType,
  statuses: TStatus[] = ['active', 'done'],
) {
  return statuses
    .map((status) => [
      ...userIds.map((userId) =>
        generateBoardListId(meetingId, 'groupNonDefaultByMember', { userId: userId, taskTypeId: defaultType }, status),
      ),
      // generateBoardListId(meetingId, 'groupDefaultByUser'),
    ])
    .reduce((prev, curr) => [...prev, ...curr], [] as IBoardList['id'][]);
}

export function groupDefaultByMember(
  board: ITempBoard,
  users: IUser[],
  defaultType: IDefaultTaskType,
  statuses: TStatus[] = ['active', 'done'],
) {
  return statuses
    .map((status) =>
      ([
        ...users.map((user) => ({
          id: generateBoardListId(board.meetingId, board.groupBy, { userId: user.id, taskTypeId: defaultType }, status),
          title: `${user.firstName} ${user.lastName}`,
          taskIds: [],
          userId: user.id,
          boardId: board.id,
        })),
        /*{
      id: generateBoardListId(board.meetingId, board.groupBy),
      title: 'Unassigned tasks',
      taskIds: [],
      boardId: board.id,
    },*/
      ] as ITempBoardList[]).reduce((prev, curr) => {
        prev[curr.id] = curr;
        return prev;
      }, {} as IMapIdToBoardLists),
    )
    .reduce((prev, curr) => ({ ...prev, ...curr }), {} as IMapIdToBoardLists);
}

export function groupDefaultByUserGenerateIds(
  meetingId: IMeeting['id'],
  userIds: IUser['id'][],
  defaultType: IDefaultTaskType,
  statuses: TStatus[] = ['active', 'done'],
) {
  return statuses
    .map((status) => [
      ...userIds.map((userId) =>
        generateBoardListId(meetingId, 'groupDefaultByUser', { userId: userId, taskTypeId: defaultType }, status),
      ),
      // generateBoardListId(meetingId, 'groupDefaultByUser'),
    ])
    .reduce((prev, curr) => [...prev, ...curr], [] as IBoardList['id'][]);
}

export function groupDefaultByUser(
  board: ITempBoard,
  users: IUser[],
  defaultType: IDefaultTaskType,
  statuses: TStatus[] = ['active', 'done'],
) {
  return statuses
    .map((status) =>
      ([
        ...users.map((user) => ({
          id: generateBoardListId(board.meetingId, board.groupBy, { userId: user.id, taskTypeId: defaultType }, status),
          title: `${user.firstName} ${user.lastName}`,
          taskIds: [],
          userId: user.id,
          boardId: board.id,
        })),
        /*{
      id: generateBoardListId(board.meetingId, board.groupBy),
      title: 'Unassigned tasks',
      taskIds: [],
      boardId: board.id,
    },*/
      ] as ITempBoardList[]).reduce((prev, curr) => {
        prev[curr.id] = curr;
        return prev;
      }, {} as IMapIdToBoardLists),
    )
    .reduce((prev, curr) => ({ ...prev, ...curr }), {} as IMapIdToBoardLists);
}

export function groupDefaultByType(board: ITempBoard, statuses: TStatus[] = ['active', 'done']) {
  const defaultTypes = ['1', '2', '3', '4', '5'];
  return statuses
    .map((status) =>
      (defaultTypes.map((defaultType) => ({
        id: generateBoardListId(board.meetingId, board.groupBy, { taskTypeId: defaultType }, status),
        title: ``, // TODO: typeId to title
        taskIds: [],
        boardId: board.id,
      })) as ITempBoardList[]).reduce((prev, curr) => {
        prev[curr.id] = curr;
        return prev;
      }, {} as IMapIdToBoardLists),
    )
    .reduce((prev, curr) => ({ ...prev, ...curr }), {} as IMapIdToBoardLists);
}

export function groupNonDefaultByType(
  board: ITempBoard,
  realBoards: IBoard[],
  statuses: TStatus[] = ['active', 'done'],
) {
  return statuses
    .map((status) =>
      realBoards
        .map((realBoard) =>
          realBoard.taskTypeIds
            ? realBoard.taskTypeIds.map((nonDefaultType) => ({
                id: generateBoardListId(board.meetingId, board.groupBy, { taskTypeId: nonDefaultType }, status),
                title: ``, // TODO: typeId to title
                taskIds: [],
                boardId: board.id,
              }))
            : [],
        )
        .reduce((prev, curr) => [...prev, ...curr], []),
    )
    .reduce((prev, curr) => [...prev, ...curr], [])
    .reduce((prev, curr) => {
      prev[curr.id] = curr;
      return prev;
    }, {} as IMapIdToBoardLists);
}

export function groupNonDefaultByUser(board: ITempBoard, users: IUser[], statuses: TStatus[] = ['active', 'done']) {
  return statuses
    .map((status) =>
      ([
        ...users.map((user) => ({
          id: generateBoardListId(board.meetingId, board.groupBy, { userId: user.id }, status),
          title: `${user.firstName} ${user.lastName}`,
          taskIds: [],
          userId: user.id,
          boardId: board.id,
        })),
        /*{
      id: generateBoardListId(board.meetingId, board.groupBy),
      title: 'Unassigned tasks',
      taskIds: [],
      boardId: board.id,
    },*/
      ] as ITempBoardList[]).reduce((prev, curr) => {
        prev[curr.id] = curr;
        return prev;
      }, {} as IMapIdToBoardLists),
    )
    .reduce((prev, curr) => ({ ...prev, ...curr }), {} as IMapIdToBoardLists);
}

export function groupNonDefaultByMember(board: ITempBoard, users: IUser[], statuses: TStatus[] = ['active', 'done']) {
  return statuses
    .map((status) =>
      ([
        ...users.map((user) => ({
          id: generateBoardListId(board.meetingId, board.groupBy, { userId: user.id }, status),
          title: `${user.firstName} ${user.lastName}`,
          taskIds: [],
          userId: user.id,
          boardId: board.id,
        })),
        /*{
      id: generateBoardListId(board.meetingId, board.groupBy),
      title: 'Unassigned tasks',
      taskIds: [],
      boardId: board.id,
    },*/
      ] as ITempBoardList[]).reduce((prev, curr) => {
        prev[curr.id] = curr;
        return prev;
      }, {} as IMapIdToBoardLists),
    )
    .reduce((prev, curr) => ({ ...prev, ...curr }), {} as IMapIdToBoardLists);
}

export function groupDefaultTypeByDateGenerateId(
  meetingId: IMeeting['id'],
  defaultType: IDefaultTaskType,
  dueDate: string,
) {
  return generateBoardListId(meetingId, 'groupDefaultTypeByDate', {
    taskTypeId: defaultType,
    date: serializeDate(dueDate),
  });
}

export function groupDefaultTypeByDate(
  board: { id: string; groupBy: IGroupBy; meetingId: IMeeting['id'] },
  tasks: ITask[],
  defaultType: IDefaultTaskType,
) {
  return tasks.reduce((prev, curr) => {
    if (curr.taskTypeId === defaultType) {
      const id = generateBoardListId(board.meetingId, board.groupBy, {
        taskTypeId: defaultType,
        date: serializeDate(curr.dueDate),
      });
      prev[id] = {
        id,
        date: serializeDate(curr.dueDate),
        taskIds: [],
        taskTypeId: defaultType,
        boardId: board.id,
      };
    }
    return prev;
  }, {} as IMapIdToBoardLists);
}

export function groupNonDefaultByDateGenerateId(meetingId: IMeeting['id'], dueDate: string) {
  return generateBoardListId(meetingId, 'groupNonDefaultByDate', {
    date: serializeDate(dueDate),
  });
}

export function groupNonDefaultByDate(
  board: { id: string; groupBy: IGroupBy; meetingId: IMeeting['id'] },
  tasks: ITask[],
) {
  return tasks.reduce((prev, curr) => {
    if (+curr.taskTypeId > 6) {
      const id = generateBoardListId(board.meetingId, board.groupBy, {
        date: serializeDate(curr.dueDate),
      });
      prev[id] = {
        id,
        date: curr.dueDate,
        taskIds: [],
        boardId: board.id,
      };
    }
    return prev;
  }, {} as IMapIdToBoardLists);
}

export function groupTasks(
  tasks: ITask[],
  meetingId: IMeeting['id'],
  groupBy: IGroupBy[],
  users: IUser[],
  realBoards: IBoard[],
) {
  const boardsRaw = generateBoards(meetingId, groupBy);
  const boards = boardsRaw.map((board) => ({
    ...board,
    mapIdBoardList: generateBoardListsForBoard(board, users, tasks, realBoards),
  }));
  tasks.forEach((task) => pushTaskIdToBoardList(task, boards));
  return boards;
}

export function pushTaskIdToBoardList(task: ITask, tempBaords: ITempBoard[]) {
  tempBaords.forEach((board) => {
    if (board.groupBy === 'groupNonDefaultByUser') {
      return pushTaskIdToNonDefaultByUser(task, board);
    } else if (board.groupBy === 'groupNonDefaultByMember') {
      return pushTaskIdToNonDefaultByMember(task, board);
    } else if (board.groupBy === 'groupNonDefaultByDate') {
      return pushTaskIdToNonDefaultByDates(task, board);
    } else if (board.groupBy === 'groupTodosByDate') {
      return pushTaskIdToDefaultByDates(task, board, '4');
    } else if (board.groupBy === 'groupQIsByDate') {
      return pushTaskIdToDefaultByDates(task, board, '1');
    } else if (board.groupBy === 'groupMeetingRocksByMember') {
      return pushTaskIdToDefaultByMember(task, board, '1');
    } else if (board.groupBy === 'groupIssuesByMember') {
      return pushTaskIdToDefaultByMember(task, board, '2');
    } else if (board.groupBy === 'groupHeadlinesByMember') {
      return pushTaskIdToDefaultByMember(task, board, '3');
    } else if (board.groupBy === 'groupTodosByMember') {
      return pushTaskIdToDefaultByMember(task, board, '4');
    } else if (board.groupBy === 'groupBackburnersByMember') {
      return pushTaskIdToDefaultByMember(task, board, '5');
    } else if (board.groupBy === 'groupMeetingRocksByUser') {
      return pushTaskIdToDefaultByUser(task, board, '1');
    } else if (board.groupBy === 'groupIssuesByUser') {
      return pushTaskIdToDefaultByUser(task, board, '2');
    } else if (board.groupBy === 'groupHeadlinesByUser') {
      return pushTaskIdToDefaultByUser(task, board, '3');
    } else if (board.groupBy === 'groupTodosByUser') {
      return pushTaskIdToDefaultByUser(task, board, '4');
    } else if (board.groupBy === 'groupBackburnersByUser') {
      return pushTaskIdToDefaultByUser(task, board, '5');
    } else if (board.groupBy === 'groupByDefaultTypes') {
      return pushTaskIdToDefaultByType(task, board);
    } else if (board.groupBy === 'groupByNonDefaultTypes') {
      return pushTaskIdToNonDefaultByType(task, board);
    }
  });
}

export function pushTaskIdToNonDefaultByUser(task: ITask, tempBoard: ITempBoard) {
  const defaultTaskTypeIds = ['1', '2', '3', '4', '5'];
  if (task.showInTasks === '1') {
    if (!defaultTaskTypeIds.includes(task.taskTypeId)) {
      (!task.userId
        ? [generateBoardListId(tempBoard.meetingId, tempBoard.groupBy, {}, backendStatusToTStatus(task.status))]
        : task.members.map((member) =>
            generateBoardListId(
              tempBoard.meetingId,
              tempBoard.groupBy,
              { userId: member },
              backendStatusToTStatus(task.status),
            ),
          )
      ).forEach((boardListId) => {
        if (tempBoard.mapIdBoardList[boardListId]) {
          const userId = JSON.parse(boardListId).userId;
          tempBoard.mapIdBoardList[boardListId].taskIds.push(`${task.id}-${userId}`);
        }
      });
    }
  }
}

export function pushTaskIdToNonDefaultByMember(task: ITask, tempBoard: ITempBoard) {
  const defaultTaskTypeIds = ['1', '2', '3', '4', '5'];
  if (task.showInTasks === '1') {
    if (!defaultTaskTypeIds.includes(task.taskTypeId)) {
      (!task.userId
        ? [generateBoardListId(tempBoard.meetingId, tempBoard.groupBy, {}, backendStatusToTStatus(task.status))]
        : task.members.map((member) =>
            generateBoardListId(
              tempBoard.meetingId,
              tempBoard.groupBy,
              { userId: member },
              backendStatusToTStatus(task.status),
            ),
          )
      ).forEach((boardListId) => {
        if (tempBoard.mapIdBoardList[boardListId]) {
          tempBoard.mapIdBoardList[boardListId].taskIds.push(task.id);
        }
      });
    }
  }
}

export function pushTaskIdToDefaultByMember(task: ITask, tempBoard: ITempBoard, taskTypeId: IDefaultTaskType) {
  if (taskTypeId === task.taskTypeId) {
    (!task.userId
      ? [
          generateBoardListId(
            tempBoard.meetingId,
            tempBoard.groupBy,
            { taskTypeId: task.taskTypeId },
            backendStatusToTStatus(task.status),
          ),
        ]
      : task.members.map((memberId) =>
          generateBoardListId(
            tempBoard.meetingId,
            tempBoard.groupBy,
            {
              userId: memberId,
              taskTypeId: task.taskTypeId,
            },
            backendStatusToTStatus(task.status),
          ),
        )
    ).forEach((boardListId) => {
      if (tempBoard.mapIdBoardList[boardListId]) {
        tempBoard.mapIdBoardList[boardListId].taskIds.push(task.id);
      }
    });
  }
}

export function pushTaskIdToDefaultByUser(task: ITask, tempBoard: ITempBoard, taskTypeId: IDefaultTaskType) {
  if (taskTypeId === task.taskTypeId) {
    const boardListId = !task.userId
      ? generateBoardListId(
          tempBoard.meetingId,
          tempBoard.groupBy,
          { taskTypeId: task.taskTypeId },
          backendStatusToTStatus(task.status),
        )
      : generateBoardListId(
          tempBoard.meetingId,
          tempBoard.groupBy,
          {
            userId: task.userId,
            taskTypeId: task.taskTypeId,
          },
          backendStatusToTStatus(task.status),
        );
    if (tempBoard.mapIdBoardList[boardListId]) {
      tempBoard.mapIdBoardList[boardListId].taskIds.push(task.id);
    }
  }
}

export function pushTaskIdToDefaultByType(task: ITask, tempBoard: ITempBoard) {
  const defaultTaskTypeIds = ['1', '2', '3', '4', '5'];
  if (defaultTaskTypeIds.includes(task.taskTypeId)) {
    const boardListId = generateBoardListId(
      tempBoard.meetingId,
      tempBoard.groupBy,
      {
        taskTypeId: task.taskTypeId,
      },
      backendStatusToTStatus(task.status),
    );
    if (tempBoard.mapIdBoardList[boardListId]) {
      tempBoard.mapIdBoardList[boardListId].taskIds.push(task.id);
    }
  }
}

export function pushTaskIdToNonDefaultByType(task: ITask, tempBoard: ITempBoard) {
  const defaultTaskTypeIds = ['1', '2', '3', '4', '5'];
  if (task.showInTasks === '1') {
    if (!defaultTaskTypeIds.includes(task.taskTypeId)) {
      const boardListId = generateBoardListId(
        tempBoard.meetingId,
        tempBoard.groupBy,
        {
          taskTypeId: task.taskTypeId,
        },
        backendStatusToTStatus(task.status),
      );
      if (tempBoard.mapIdBoardList[boardListId]) {
        tempBoard.mapIdBoardList[boardListId].taskIds.push(task.id);
      }
    }
  }
}

export function pushTaskIdToDefaultByDates(task: ITask, tempBoard: ITempBoard, taskTypeId: IDefaultTaskType) {
  if (task.taskTypeId === taskTypeId && backendStatusToTStatus(task.status) === 'active') {
    // CHECK IF THIS HAS STATUS
    const boardListId = generateBoardListId(tempBoard.meetingId, tempBoard.groupBy, {
      taskTypeId: taskTypeId,
      date: task.dueDate,
    });
    if (tempBoard.mapIdBoardList[boardListId]) {
      tempBoard.mapIdBoardList[boardListId].taskIds.push(task.id);
    }
  }
}

export function pushTaskIdToNonDefaultByDates(task: ITask, tempBoard: ITempBoard) {
  const defaultTaskTypeIds = ['1', '2', '3', '4', '5'];
  if (task.showInTasks === '1') {
    if (!defaultTaskTypeIds.includes(task.taskTypeId) && backendStatusToTStatus(task.status) === 'active') {
      // CHECK IF THIS HAS STATUS
      const boardListId = generateBoardListId(tempBoard.meetingId, tempBoard.groupBy, {
        date: serializeDate(task.dueDate),
      });
      if (tempBoard.mapIdBoardList[boardListId]) {
        tempBoard.mapIdBoardList[boardListId].taskIds.push(task.id);
      }
    }
  }
}

export function convertTempBoardToBoard(board: ITempBoard): IBoard {
  return {
    id: board.id,
    name: board.title,
    meetingIds: [board.meetingId],
    color: 'white',
    slug: '',
    properties: null,
    flag: 'T', //temp
    changedBy: '',
    changedAt: '',
    deleted: '',
    taskTypeIds: Object.keys(board.mapIdBoardList),
    labelIds: [],
  };
}

export function convertTempBoardListToBoarList(boardList: ITempBoardList): IBoardList {
  return {
    id: boardList.id,
    name: boardList.title ? boardList.title : '',
    boardId: boardList.boardId,
    slug: '',
    properties: null,
    flag: 'T',
    changedBy: '',
    changedAt: '',
    deleted: '',
    tasksIds: boardList.taskIds,
  };
}

export function findBoardListsToUpdate(task: ITask) {
  return [
    findNaturalBoardListToUpdate(task),
    ...findNonDefaultByDateBoardListToUpdate(task),
    ...findNonDefaultByMemberBoardListToUpdate(task),
    ...findNonDefaultByUserBoardListToUpdate(task),
  ];
}

export function findNaturalBoardListToUpdate(task: ITask) {
  return selectWithParams(boardListSelectors.selectResourceById, task.taskTypeId);
}
export function findNonDefaultByDateBoardListToUpdate(task: ITask) {
  return task.meetingIds.map((meetingId) =>
    selectWithParams(
      boardListSelectors.selectResourceById,
      generateBoardListId(meetingId, 'groupNonDefaultByDate', { date: task.dueDate }),
    ),
  );
}
export function findNonDefaultByMemberBoardListToUpdate(task: ITask) {
  const selectors: SelectEffect[] = [];
  task.meetingIds.forEach((meetingId) =>
    task.members.forEach((member) =>
      selectors.push(
        selectWithParams(
          boardListSelectors.selectResourceById,
          generateBoardListId(meetingId, 'groupNonDefaultByMember', { userId: member }),
        ),
      ),
    ),
  );
  return selectors;
}

export function findNonDefaultByUserBoardListToUpdate(task: ITask) {
  const selectors: SelectEffect[] = [];
  if (task.members.length !== 0) {
    task.meetingIds.forEach((meetingId) =>
      task.members.forEach((member) =>
        selectors.push(
          selectWithParams(
            boardListSelectors.selectResourceById,
            generateBoardListId(meetingId, 'groupNonDefaultByUser', { userId: member }),
          ),
        ),
      ),
    );
  }
  return selectors;
}
