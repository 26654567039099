import { IUserSignUp } from '../../resource/user/user.types';
import { IApiRequestConfig } from '../api.types';

export const signUpUserApi = (data: IUserSignUp): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'signUpUser',
  uri: 'json',
  action: 'signup',
  method: 'post',
  data: data,
});
