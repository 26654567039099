/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { updateTask } from '../../../../../../../../../../../app/page/page-storage/boardLists/boardLists.actions';
import { deleteLabel } from '../../../../../../../../../../../app/page/page-storage/labels/labels.actions';
import { labelSelectors } from '../../../../../../../../../../../app/resource/label/label.selectors';
import { taskSelectors } from '../../../../../../../../../../../app/resource/task/task.selectors';
import { useSelectWithParams } from '../../../../../../../../../../../utils/selector.utils';

import { LabelForm } from '../../../../../../../../../common/forms/LabelForm';

import { ILabelProps } from './types';

export const Label: FunctionComponent<ILabelProps> = ({ labelId, taskId }) => {
  const label = useSelectWithParams(labelSelectors.selectResourceById, labelId);
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId);

  const taskLabels = useMemo(() => {
    if (task && task.label) {
      return task.label;
    }
    return [];
  }, [task]);

  const [isActive, setActive] = useState(false);
  const labelFiltered = useMemo(() => {
    if (label) {
      return taskLabels.filter((taskLabel) => taskLabel === label.color);
    }
    return taskLabels;
  }, [label, taskLabels]);
  const [isSelected, setSelected] = useState(labelFiltered.length > 0 ? true : false);

  const dispatch = useDispatch();
  const toggleClass = (status: boolean) => {
    setActive(!status);
  };
  const onDeleteLabel = useCallback(() => {
    dispatch(deleteLabel(labelId));
  }, [dispatch, labelId]);

  const addTaskLabel = useCallback(() => {
    if (label && task) {
      dispatch(updateTask({ ...task, label: [...taskLabels, label.color] }));
    }
  }, [dispatch, label, task, taskLabels]);

  const deleteTaskLabel = useCallback(() => {
    if (label && task) {
      dispatch(updateTask({ ...task, label: taskLabels.filter((taskLabel) => taskLabel !== label.color) }));
    }
  }, [dispatch, label, task, taskLabels]);

  const toggleSelect = () => {
    isSelected ? deleteTaskLabel() : addTaskLabel();
    setSelected(!isSelected);
  };

  return (
    label && (
      <div
        className={
          isActive
            ? isSelected
              ? 'one-label selected edited'
              : 'one-label edited'
            : isSelected
            ? 'one-label selected'
            : 'one-label'
        }
        key={`labelId-${label.id}`}
      >
        <a className={`badge jcl`} style={{ backgroundColor: `${label.color}` }} onClick={toggleSelect}>
          {label.name}
        </a>
        <span className="edit-label" key={`labelId-${label.id}`} onClick={() => toggleClass(isActive)}>
          <i className="icon icon-pencil" />
        </span>
        {isActive && (
          <div className="edit-label-container">
            {task && <LabelForm labelData={label} onClick={toggleClass} boardId={task.boardId} />}

            <button className="btn btn-sm red-bg delete-label" onClick={onDeleteLabel}>
              Delete label
            </button>
          </div>
        )}
      </div>
    )
  );
};
