/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { FunctionComponent, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../app/dialog/dialog.actions';

import { saveBoardOrder, storeCurrentBoard } from '../../../../app/page/page-storage/boards/boards.actions';

import { boardSelectors } from '../../../../app/resource/boards/board/board.selectors';

import { useSelectWithParams } from '../../../../utils/selector.utils';
import { IBoardsProps } from './types';

export const Boards: FunctionComponent<IBoardsProps> = ({ boardsIds, isAdmin }) => {
  const dispatch = useDispatch();
  const [boardIds, setBoardIds] = useState(boardsIds);

  const appendedBoardId = boardsIds.filter((boardId) => !boardIds.includes(boardId));
  const removedBoardId = boardIds.filter((boardId) => !boardsIds.includes(boardId));
  useEffect(() => {
    if (appendedBoardId[0]) {
      setBoardIds([...boardIds, appendedBoardId[0]]);
    }
    if (removedBoardId[0]) {
      const filteredBoards = boardIds.filter((boardId) => boardId !== removedBoardId[0]);
      setBoardIds(filteredBoards);
    }
  }, [appendedBoardId, boardIds, boardsIds, removedBoardId]);

  const openBoardFormDialog = useCallback(() => {
    dispatch(openDialog('boardFormDialog'));
  }, [dispatch]);

  const setCurrentBoardId = useCallback(
    (boardId) => {
      dispatch(storeCurrentBoard(boardId));
    },
    [dispatch],
  );

  const boards = useSelectWithParams(boardSelectors.selectResourcesById, boardIds);

  useEffect(() => {
    if (boardIds[0]) {
      dispatch(storeCurrentBoard(boardIds[0]));
    }
  }, [boardIds, dispatch]);

  const handleDrop = useCallback(
    (result: DropResult) => {
      console.log(result);
      if (result.destination) {
        const boardId = result.draggableId;
        const sourceIndex = result.source.index;
        const destinationIndex =
          result.destination.index > boardIds.length ? result.destination.index - 1 : result.destination.index;

        if (sourceIndex !== destinationIndex) {
          if (destinationIndex > sourceIndex) {
            boardIds.splice(sourceIndex, 1);
            const newBoardIds = [
              ...boardIds.slice(0, destinationIndex - 1),
              boardId,
              ...boardIds.slice(destinationIndex - 1),
            ];
            setBoardIds(newBoardIds);
            dispatch(saveBoardOrder(newBoardIds));
          } else {
            boardIds.splice(sourceIndex, 1);
            const newBoardIds = [...boardIds.slice(0, destinationIndex), boardId, ...boardIds.slice(destinationIndex)];
            setBoardIds(newBoardIds);
            dispatch(saveBoardOrder(newBoardIds));
          }
        }
      }
    },
    [boardIds, dispatch],
  );

  return (
    <div className="col-12">
      <div className="border-radius-1 lightGray-bg p-2 flex aic jcl">
        {isAdmin === true ? (
          <DragDropContext onDragEnd={handleDrop}>
            {boards.map(
              (board, index) =>
                board && (
                  <Droppable droppableId={board.id} key={board.id} direction="horizontal" type="column">
                    {(provided) => (
                      <div style={{ display: 'flex' }} {...provided.droppableProps} ref={provided.innerRef}>
                        <Draggable key={`draggable-row-${board.id}`} draggableId={board.id} index={index}>
                          {(provided) => (
                            <a
                              className={`btn btn-lg f-14 px-2 bold`}
                              style={{ backgroundColor: `${board.color}` }}
                              title={board.name}
                              key={board.id}
                              role="rowgroup"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              onClick={() => setCurrentBoardId(board.id)}
                            >
                              {board.name}
                            </a>
                          )}
                        </Draggable>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ),
            )}
          </DragDropContext>
        ) : (
          <>
            {boards.map(
              (board) =>
                board && (
                  <a
                    className={`btn btn-lg f-14 px-2 bold`}
                    style={{ backgroundColor: `${board.color}` }}
                    title={board.name}
                    key={`boardId-${board.id}`}
                    onClick={() => setCurrentBoardId(board.id)}
                  >
                    {board.name}
                  </a>
                ),
            )}
          </>
        )}
        <a
          className="btn btn-lg f-14 white-bg px-2 bold popup_button"
          title="Create new board"
          onClick={openBoardFormDialog}
        >
          <i className="icon icon-plus" /> Create new board
        </a>
      </div>
    </div>
  );
};
