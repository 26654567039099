import { combineReducers, Reducer } from 'redux';
import { isActionOfType } from '../../../../utils/action.utils';

import * as types from './kpi.types';
import * as constants from './kpi.constants';

const initialState: types.IKpiPageState = {
  kpiRowIds: [],
  sessionIds: [],
  scoreIds: [],
};

const kpiRowIdsReducer: Reducer<types.IKpiPageState['kpiRowIds'], types.IStoreKpiRowIds | types.IClearKpiRowIds> = (
  state = initialState.kpiRowIds,
  action,
) => {
  if (isActionOfType<types.IStoreKpiRowIds>(action, constants.STORE_KPI_ROW_IDS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearKpiRowIds>(action, constants.CLEAR_KPI_ROW_IDS)) {
    return initialState.kpiRowIds;
  }
  return state;
};

const sessionIdsReducer: Reducer<types.IKpiPageState['sessionIds'], types.IStoreSessionIds | types.IClearSessionIds> = (
  state = initialState.sessionIds,
  action,
) => {
  if (isActionOfType<types.IStoreSessionIds>(action, constants.STORE_SESSION_IDS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearSessionIds>(action, constants.CLEAR_SESSION_IDS)) {
    return initialState.sessionIds;
  }
  return state;
};

const scoreIdsReducer: Reducer<types.IKpiPageState['scoreIds'], types.IStoreScoreIds | types.IClearScoreIds> = (
  state = initialState.scoreIds,
  action,
) => {
  if (isActionOfType<types.IStoreScoreIds>(action, constants.STORE_SCORE_IDS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearScoreIds>(action, constants.CLEAR_SCORE_IDS)) {
    return initialState.scoreIds;
  }
  return state;
};

export const kpiPageReducer: Reducer<types.IKpiPageState, types.IKpiPageAction> = combineReducers({
  kpiRowIds: kpiRowIdsReducer,
  sessionIds: sessionIdsReducer,
  scoreIds: scoreIdsReducer,
});
