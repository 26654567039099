import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './home.constants';
import * as types from './home.types';
import * as services from './home.saga-services';

function* invokeInitialHomePage(action: types.IInitialHomePage) {
  yield call(services.initialHomePage);
}

export function* watchHomePageSaga() {
  yield takeLatest(constants.INITIAL_HOME_PAGE, invokeInitialHomePage);
}
