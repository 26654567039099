import * as types from './project-members.types';
import * as constants from './project-members.constants';
import {
  IProjectMember,
  IProjectMemberCreateForm,
  IProjectTeam,
} from '../../../resource/project-member/project-member.types';

export const createProjectMember = (projectMember: IProjectMemberCreateForm): types.ICreateProjectMember => ({
  type: constants.CREATE_PROJECT_MEMBER,
  payload: projectMember,
});

export const updateProjectMember = (
  projectMemberId: IProjectMember['id'],
  ProjectMember: IProjectMemberCreateForm,
): types.IUpdateProjectMember => ({
  type: constants.UPDATE_PROJECT_MEMBER,
  payload: {
    id: projectMemberId,
    data: ProjectMember,
  },
});

export const setupProjectTeams = (week: string, accountId?: string): types.ISetupProjectTeams => ({
  type: constants.SETUP_PROJECT_TEAM,
  payload: { week, accountId },
});

export const deleteProjectMember = (projectMemberId: IProjectMember['id']): types.IDeleteProjectMember => ({
  type: constants.DELETE_PROJECT_MEMBER,
  payload: projectMemberId,
});

export const storeProjectMembers = (projectTeams: IProjectTeam['userId'][]): types.IStoreProjectTeam => ({
  type: constants.STORE_PROJECT_TEAM,
  payload: projectTeams,
});

export const clearProjectMember = (): types.IClearProjectTeam => ({
  type: constants.CLEAR_PROJECT_TEAM,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
