/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useHistory } from 'react-router-dom';
import slugify from 'react-slugify';
import { meetingsPageSelectors } from '../../../../../app/page/page-storage/meetings/meetings.selectors';
import { meetingSelectors } from '../../../../../app/resource/meeting/meeting.selectors';
import { useSelect, useSelectWithParams } from '../../../../../utils/selector.utils';
import { IMeetingWidgetProps } from './types';

export const MeetingWidget: FunctionComponent<IMeetingWidgetProps> = ({ meetingId }) => {
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);
  const history = useHistory();
  const filteredMeetingIds = useSelect(meetingsPageSelectors.selectFilteredMeetings);
  const [filteredMeeting, setFilteredMeeting] = useState(false);
  useEffect(() => {
    setFilteredMeeting(filteredMeetingIds.includes(meetingId));
  }, [filteredMeetingIds, meetingId]);
  return (
    meeting && (
      <a
        className="card todo-card panel"
        onClick={() => history.push(`/meeting-dashboard/${meeting.id}/${slugify(meeting.title, { delimiter: '-' })}`)}
        style={filteredMeeting === true ? { display: 'none' } : { zIndex: 0, cursor: 'pointer' }}
      >
        <div className="panel-body">
          <div className="account-item">
            <div className="account-item-image">
              <Avatar
                className="img-fluid"
                title="Review KPIs"
                name={meeting.title}
                alt="review KPIs"
                color={meeting.color}
                size="50"
                round={true}
              />
            </div>
            <div className="account-item-desc">
              <h3 className="account-item-title">{meeting.title}</h3>
              <div className="account-item-task">
                Open To Do’s: <span>{meeting?.taskIds?.length}</span>
              </div>
            </div>
          </div>
        </div>
      </a>
    )
  );
};
