/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { updateTask } from '../../../../../../../../../../../app/page/page-storage/boardLists/boardLists.actions';
import { taskSelectors } from '../../../../../../../../../../../app/resource/task/task.selectors';
import { userSelectors } from '../../../../../../../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../../../../../../../utils/selector.utils';
import { IMemberProps } from './types';

export const Member: FunctionComponent<IMemberProps> = ({ userId, taskId }) => {
  const user = useSelectWithParams(userSelectors.selectResourceById, userId);
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId);

  const taskMembers = useMemo(() => {
    return task?.members ?? [];
  }, [task]);

  const membersFiltered = useMemo(() => {
    return user ? taskMembers.filter((taskMember) => taskMember === user.id) : [];
  }, [taskMembers, user]);

  const [isSelected, setSelected] = useState(!!membersFiltered.length);

  const dispatch = useDispatch();

  const addTaskMember = useCallback(() => {
    if (task && user) {
      dispatch(updateTask({ label: [], ...task, members: [...task.members, user.id] }));
    }
  }, [dispatch, task, user]);

  const deleteTaskMember = useCallback(() => {
    if (task && user) {
      dispatch(
        updateTask({ label: [], ...task, members: task.members.filter((taskMember) => taskMember !== user.id) }),
      );
    }
  }, [dispatch, task, user]);

  const toggleSelect = useCallback(() => {
    if (isSelected) {
      deleteTaskMember();
    } else {
      addTaskMember();
    }
    setSelected(!isSelected);
  }, [addTaskMember, deleteTaskMember, isSelected]);

  return (
    user && (
      <div className={isSelected ? 'one-member selected' : 'one-member'} onClick={toggleSelect}>
        <img src={user.image} alt="" className="small-avatar img-fluid" />
        {`${user.firstName} ${user.lastName}`}
      </div>
    )
  );
};
