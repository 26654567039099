/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { FunctionComponent } from 'react';

import { IDeleteAIProps } from './types';

export const DeleteAI: FunctionComponent<IDeleteAIProps> = ({ agendaItem, removeAgendaItem }) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  const close = useCallback(() => setActive(false), [setActive]);

  return (
    <div className={isActive ? 'dropdown opened' : 'dropdown'}>
      <div style={{ paddingLeft: '5px' }} onClick={toggleClass}>
        <a className="round-icon-small mr-0 popup_button" data-popup="delete_popup">
          <i className="icon2-trash" />
        </a>
      </div>
      <div className="dropdown-menu drop-right drop-down max-h320">
        <div className="mini-popup">
          <div className="mini-popup-header">
            Delete item
            <span className="close_mini_popup" onClick={close}>
              ×
            </span>
          </div>
          <div className="mini-popup-body">
            <p className="ln mb-2 darkGray">Deleting an item is permanent and there is no way to get it back.</p>
            <a className="btn btn-md red-bg white d-block jcc" onClick={() => removeAgendaItem(agendaItem)}>
              Delete item
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
