import React, { FunctionComponent, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { Color } from './Color';
import { IColorInputProps } from './types';

const colors = [
  { value: '#C2CDD8', state: false },
  { value: '#66717C', state: false },
  { value: '#3D434A', state: false },
  { value: '#747FED', state: false },
  { value: '#21AFE4', state: false },
  { value: '#1BC3A7', state: false },
  { value: '#13AC92', state: false },
  { value: '#FF5C6C', state: false },
  { value: '#F4A743', state: false },
];
export const SimpleColorInput: FunctionComponent<IColorInputProps> = ({ name, defaultValue, validation }) => {
  const { control } = useFormContext();
  const defaultState = colors.map((tupple) =>
    tupple.value === defaultValue ? { ...tupple, state: true } : { ...tupple, state: false },
  );
  const [colorsState, setColorsState] = useState(defaultState);

  const onClickColor = (
    color: string,
    controllerProps: {
      onChange: (...event: unknown[]) => void;
      onBlur: () => void;
      value: unknown;
      name: string;
    },
  ) => {
    const updateColors = colorsState.map((tupple) =>
      tupple.value === color ? { ...tupple, state: true } : { ...tupple, state: false },
    );

    setColorsState(updateColors);

    updateColors.map((tupple) => (tupple.state === true ? controllerProps.onChange(tupple.value) : tupple));
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : '#C2CDD8'}
      rules={validation}
      render={(controllerProps) => (
        <>
          {colorsState.map(
            (color) =>
              color && (
                <Color
                  color={color}
                  key={`colorId-${color.value}`}
                  controllerProps={controllerProps}
                  onClick={onClickColor}
                />
              ),
          )}
        </>
      )}
    />
  );
};
