import { put, select, call, all } from 'redux-saga/effects';
import * as types from './activity.types';
import * as actions from './activity.actions';

import * as api from '../../api/request-config/activity.api';
import { activitySelectors } from './activity.selectors';
import { apiRequest } from '../../api/api.saga-services';
import { ITask } from '../task/task.types';

export function* storeActivity(activity: types.IActivity) {
  yield put(actions.storeActivity(activity));

  return activity;
}

export function* clearActivity(activityId: types.IActivity['id']) {
  yield put(actions.clearActivity(activityId));
}

export function* clearAllActivities() {
  const activitiesIds: types.IActivity['id'][] = yield select(activitySelectors.selectResourceIds);
  yield put(actions.clearActivity(activitiesIds));
}

export function* searchActivities(taskId: ITask['id']) {
  const activities: types.IActivity[] = yield call(apiRequest, api.searchActivitiesApi(taskId));

  yield all(activities.map((activity) => call(storeActivity, activity)));
  return activities.map((activity) => activity.id);
}

export function* createActivity(data: types.IActivityCreate) {
  const activity = (yield call(apiRequest, api.createActivityApi(data))) as types.IActivity;
  if (!activity) {
    return;
  }
  yield call(storeActivity, activity);
  return activity;
}

export function* updateActivity(data: types.IActivityUpdate) {
  const activity = (yield call(apiRequest, api.updateActivityApi(data))) as types.IActivity;
  if (!activity) {
    return;
  }
  activity.id = data.id;
  yield call(storeActivity, activity);
  return activity;
}

export function* deleteActivity(activityId: types.IActivity['id']) {
  yield call(apiRequest, api.deleteActivityApi(activityId));
}

export function* saveFile(data: types.ISaveFile) {
  const activity = (yield call(apiRequest, api.saveFileApi(data))) as types.IActivity;
  if (!activity) {
    return;
  }
  yield call(storeActivity, activity);
  return activity;
}
