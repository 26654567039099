import React, { FunctionComponent } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route, Redirect } from 'react-router-dom';

import { getHistory } from '../../app/store';

import { PublicOnlyRoute } from './PublicOnlyRoute';
import { UserOnlyRoute } from './UserOnlyRoute';

import { LoginPage } from '../pages/LoginPage';
import { HomePage } from '../pages/HomePage';
import { MeetingPage } from '../pages/MeetingPage';
import { LiveMeetingPage } from '../pages/LiveMeetingPage';
import { UserPage } from '../pages/UserPage';
import { MeetingDashboardPage } from '../pages/MeetingDashboardPage';
import { KpiPage } from '../pages/KpiPage';
import { VtoPage } from '../pages/VtoPage';
import { ChartPage } from '../pages/ChartPage';
import { BoardsPage } from '../pages/BoardsPage';
import { PasswordsPage } from '../pages/PasswordsPage';
import { TimeTrackingPage } from '../pages/TimeTrackingPage';
import { QuartalsPage } from '../pages/QuartalsPage';
import { ForgotPasswordPage } from '../pages/ForgotPasswordPage';
import { AgencyToolsPage } from '../pages/AgencyToolsPage';
import { AgencyDashboardPage } from '../pages/AgencyDashboardPage';
import { AgencySinglePage } from '../pages/AgencySinglePage';

export const Router: FunctionComponent = () => (
  <ConnectedRouter history={getHistory()}>
    <Switch>
      <PublicOnlyRoute path="/login" component={LoginPage} />
      <PublicOnlyRoute path="/forgotpassword" component={ForgotPasswordPage} />
      <UserOnlyRoute path="/home" component={HomePage} />
      <UserOnlyRoute path="/directory" component={UserPage} />
      <UserOnlyRoute path="/passwords/:meetingId" component={PasswordsPage} />
      <UserOnlyRoute path="/accounts" component={() => <MeetingPage type="account" />} />
      <UserOnlyRoute path="/teams" component={() => <MeetingPage type="meeting" />} /> {/*TODO change to team*/}
      <UserOnlyRoute path="/meeting-dashboard/:meetingId" component={MeetingDashboardPage} />
      <UserOnlyRoute path="/kpi/:meetingId" component={KpiPage} />
      <UserOnlyRoute path="/live-meeting/:meetingId/:sessionId/:current" component={LiveMeetingPage} />
      <UserOnlyRoute path="/agency-tools" component={AgencyToolsPage} />
      <UserOnlyRoute path="/agency-dashboard/:itemId" component={AgencyDashboardPage} />
      <UserOnlyRoute path="/agency-single/:columnId" component={AgencySinglePage} />
      <UserOnlyRoute path="/vto" component={VtoPage} />
      <UserOnlyRoute path="/chart" component={ChartPage} />
      <UserOnlyRoute path="/boards/:meetingId" component={BoardsPage} />
      <UserOnlyRoute path="/time-tracking" component={TimeTrackingPage} />
      <UserOnlyRoute path="/quartals" component={QuartalsPage} />
      <Route path="">
        <Redirect to="/home" />
      </Route>
    </Switch>
  </ConnectedRouter>
);
