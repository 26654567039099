import React, { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { saveNewPassword } from '../../../../../app/auth/auth.actions';

import { usePasswordValidation, useRequiredValidation } from '../../../../../utils/form/validation.utils';

import { FormSmallTextInput } from '../../inputs/SmallTextInput';
import { ISaveNewPasswordFormData } from './types';

export const SaveNewPasswordForm: FunctionComponent = () => {
  const dispatch = useDispatch();

  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: ISaveNewPasswordFormData) => {
    const submitData = { password: data.password, passwordResetHash: data.passwordResetHash }; //data.timezone.value
    dispatch(saveNewPassword(submitData));
  };

  const password = formProps.watch('password');
  const repeatPassword = formProps.watch('repeatPassword');

  const passwordValidation = usePasswordValidation(password, repeatPassword, 6);
  const history = useHistory();

  const [, hash] = history.location.search.split('=');
  console.log(hash);
  return (
    <FormProvider {...formProps}>
      <form
        id="saveNewPassword-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as ISaveNewPasswordFormData))}
        method="post"
      >
        <div className="field-container">
          <FormSmallTextInput
            id="password"
            name="password"
            type="password"
            label="Password"
            placeholder="Enter Password"
            validation={passwordValidation}
          />
        </div>
        <div className="field-container">
          <FormSmallTextInput
            id="repeatPassword"
            name="repeatPassword"
            type="password"
            label="Repeat Password"
            placeholder="Enter Password"
            validation={passwordValidation}
          />
        </div>

        <div className="field-container" style={{ display: 'none' }}>
          <FormSmallTextInput
            id="passwordResetHash"
            name="passwordResetHash"
            type="text"
            validation={requiredValidation}
            defaultValue={hash}
          />
        </div>
      </form>
    </FormProvider>
  );
};
