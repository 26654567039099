/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';

import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';

import { SubmitButton } from '../../pages/common/forms/SubmitButton';

import { ConnectedDialog } from '../ConnectedDialog';

import { ForgotPasswordMailForm } from '../../pages/common/forms/ForgotPasswordMailForm';

export const ForgotPasswordDialog: FunctionComponent = () => {
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(closeDialog('forgotPasswordDialog'));
  }, [dispatch]);

  return (
    <ConnectedDialog dialogKey="forgotPasswordDialog">
      <div className="popup new_board_popup" style={{ display: 'block' }}>
        <div className="panel" style={{ minWidth: '700px' }}>
          <div className="panel-header small-header flex jcsb">
            <h3>Forgot password</h3>
            <span className="close-popup close" onClick={onClose}>
              <i className="icon-close" />
            </span>
          </div>
          <div className="panel-body">
            <ForgotPasswordMailForm />
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" onClick={onClose}>
              Close
            </a>

            <SubmitButton formName="forgotPasswordMail-form" text="Send" />
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
