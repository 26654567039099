import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './vto.constants';
import * as types from './vto.types';
import * as services from './vto.saga-services';

function* invokeInitialVtoPage(action: types.IInitialVtoPage) {
  yield call(services.initialVtoPage);
}

export function* watchVtoPageSaga() {
  yield takeLatest(constants.VTO_MEETING_PAGE, invokeInitialVtoPage);
}
