import React, { FunctionComponent, useMemo } from 'react';
import { userSelectors } from '../../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../../utils/selector.utils';
import { INodeHeaderProps } from './types';

export const NodeHeader: FunctionComponent<INodeHeaderProps> = ({ userId }) => {
  const user = useSelectWithParams(userSelectors.selectResourceById, userId);
  const showName = useMemo(() => `${user?.firstName} ${user?.lastName}`, [user]);
  const showTitle = useMemo(() => user?.jobTitle, [user]);
  return (
    user && (
      <>
        <div className="orgchart-box-img small-avatar">
          <img src={user.image} className="img-fluid small-avatar" alt={`${user.firstName} ${user.lastName}`} />
        </div>
        <div className="orgchart-box-title">
          <h5>{showTitle}</h5>
          <h6>{showName}</h6>
        </div>
      </>
    )
  );
};
