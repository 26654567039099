import React, { ChangeEvent, FunctionComponent, useCallback, useMemo, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { moveTask } from '../../../../../../app/page/page-storage/tasks/tasks.actions';
import { tasksPageSelectors } from '../../../../../../app/page/page-storage/tasks/tasks.selectors';
import { taskSelectors } from '../../../../../../app/resource/task/task.selectors';
import { userSelectors } from '../../../../../../app/resource/user/user.selectors';
import { formatDate } from '../../../../../../utils/date.utils';
import { useSelect, useSelectWithParams } from '../../../../../../utils/selector.utils';
import { TaskMenu } from '../TaskMenu';
import { ITaskWidgetLineProps } from './types';

export const TaskWidgetLine: FunctionComponent<ITaskWidgetLineProps> = ({ userId, taskId, width, index, type }) => {
  const dispatch = useDispatch();
  const user = useSelectWithParams(userSelectors.selectResourceById, userId);
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId);

  const [isChecked, setChecked] = useState(task ? task.status !== '0' : false);

  const reducedTasks = useSelect(tasksPageSelectors.selectReducedTasks);

  const updateReducedTasks = useMemo(() => reducedTasks[type][userId].active.filter((id) => task && id !== task?.id), [
    reducedTasks,
    task,
    type,
    userId,
  ]);

  const onChangeStatus = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!isChecked && task && index !== undefined) {
        dispatch(
          moveTask({
            destination: {
              droppableId: `${type}/${task.userId}/done`,
              index: 0,
            },
            source: {
              index,
            },
            draggableId: task.id,
          } as DropResult),
        );

        setChecked(true);
        reducedTasks[type][userId].active = updateReducedTasks;
      }
      if (isChecked && task && index !== undefined) {
        alert(`You can't put done task to active.`);
      }
    },
    [dispatch, index, isChecked, reducedTasks, task, type, updateReducedTasks, userId],
  );

  return task && user ? (
    <div className={`single-headline p-1 pb-0 ${width ? 'col-12' : 'col-6'}`}>
      <div className="panel flex aic jcsb px-2 h100px">
        <div className="headline-left flex aic">
          <span className="small-avatar mr-1">
            <img src={user.image} alt={`${user.firstName} ${user.lastName}`} className="img-fluid" />
          </span>
          {task.title}
        </div>
        <div className="headline-right flex aic jcsb">
          {/* <span className="blueGray">{task.dueDate ? formatDate(task.dueDate) : formatDate(new Date())}</span> */}
          <div className="border-checkbox ml-1">
            <input id={task.id} type="checkbox" checked={isChecked} onChange={onChangeStatus} />
            <label htmlFor={task.id} />
          </div>
          <TaskMenu task={task} />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
