import { IConclusion, IConclusionCreate } from '../../../resource/conclusion/conclusion.types';
import * as conclusionResourceService from '../../../resource/conclusion/conclusion.saga-service';
import * as actions from './conclusions.actions';
import { call, put } from 'redux-saga/effects';

export function* createConclusion(conclusionInfo: IConclusionCreate) {
  const conclusion = (yield call(conclusionResourceService.createConclusion, {
    ...conclusionInfo,
  })) as IConclusion['id'];

  if (!conclusion) {
    return;
  }

  // todo store fetchedConclusions
}

export function* handleConclusionFromNotification(conclusion: IConclusion) {
  yield call(conclusionResourceService.storeConclusion, conclusion);
}

export function* clearConclusionsPageStorage() {
  yield put(actions.clearConclusions());
}
