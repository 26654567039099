import * as types from './notifications.types';
import * as constants from './notifications.constants';
import { INotification } from '../../../resource/notification/notification.types';

export const fetchNotifications = (): types.IFetchNotifications => ({
  type: constants.FETCH_NOTIFICATIONS,
});

export const updateNotification = (notificationId: INotification['id']): types.IUpdateNotification => ({
  type: constants.UPDATE_NOTIFICATION,
  payload: {
    id: notificationId,
  },
});

export const updateNotifications = (notificationIds: INotification['id'][]): types.IUpdateNotifications => ({
  type: constants.UPDATE_NOTIFICATION,
  payload: {
    notificationIds,
  },
});

export const deleteNotification = (notificationId: INotification['id']): types.IDeleteNotification => ({
  type: constants.DELETE_NOTIFICATION,
  payload: notificationId,
});

export const storeFetchedNotifications = (
  notificationIds: INotification['id'][],
): types.IStoreFetchedNotifications => ({
  type: constants.STORE_FETCHED_NOTIFICATIONS,
  payload: notificationIds,
});

export const clearFetchedNotifications = (): types.IClearFetchedNotifications => ({
  type: constants.CLEAR_FETCHED_NOTIFICATIONS,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});

export const storeUnseenCount = (count: number): types.IStoreUnseenCount => ({
  type: constants.STORE_UNSEEN_COUNT,
  payload: count,
});

export const clearUnseenCount = (): types.IClearUnseenCount => ({
  type: constants.CLEAR_UNSEEN_COUNT,
});
