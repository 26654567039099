import React, { FunctionComponent } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ProjectTasksForm } from './ProjectTasksForm';
import { IProjectTasksProps } from './types';

export const ProjectTasks: FunctionComponent<IProjectTasksProps> = ({ projectTaskIds, projectData }) => {
  const { control } = useFormContext();
  //const projectTasks = useSelectWithParams(projectTaskSelectors.selectResourcesById, projectTaskIds ?? []);
  //const tasks = useMemo(() => projectTasks as IProjectTask[], [projectTasks]);
  const tasks = projectData?.tasks;
  const focusHourlyTuple = useWatch<{ [key: string]: string }>({
    control,
    name: 'hourlyRate',
  });
  const focusBudgetTuple = useWatch<{ [key: string]: string }>({
    control,
    name: 'budgetType',
  });
  return (
    <>
      <div className="panel green-gradient py-1 px-2 flex aic mb-1">
        <h2 className="bold white flex aic">TASKS</h2>
      </div>
      <div className="popup-tasks">
        <div className="tasks-header">
          <span className="tasks-header-item">TASK NAME</span>
          <span className="tasks-header-item text-right ml-auto">BILLABLE</span>
          {focusBudgetTuple && focusBudgetTuple.value === 'fees_per_task' && (
            <span
              className="tasks-header-item text-right max100 ml-1 task_member_budget_amount"
              style={{ display: 'none !important' }}
            >
              BUDGET
            </span>
          )}
          {focusBudgetTuple && focusBudgetTuple.value === 'hours_per_task' && (
            <span
              className="tasks-header-item text-right max100 ml-1 task_member_budget_hours"
              style={{ display: 'block !important' }}
            >
              BUDGET (hours)
            </span>
          )}

          {focusHourlyTuple && focusHourlyTuple.value === 'task' && (
            <span
              className="tasks-header-item text-right max100 ml-1 task_member_hourly_rate"
              style={{ display: 'none !important' }}
            >
              HOURLY RATE
            </span>
          )}
        </div>
        <ProjectTasksForm projectTasks={tasks} />
      </div>
    </>
  );
};
