import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './user-groups.constants';
import * as types from './user-groups.types';
import * as services from './user-groups.saga-services';

function* invokeGetUserGroups(action: types.IGetUserGroups) {
  yield call(services.getUserGroups, action.payload);
}

function* invokeCreateUserGroup(action: types.ICreateUserGroup) {
  yield call(services.createUserGroup, action.payload);
}

export function* watchUsersGroupPageSaga() {
  yield takeLatest(constants.GET_USER_GROUPS, invokeGetUserGroups);
  yield takeLatest(constants.CREATE_USER_GROUP, invokeCreateUserGroup);
}
