/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import {
  deleteChecklistItem,
  updateChecklistItem,
} from '../../../../../../../../../../../../app/page/page-storage/checklists/checkLists.actions';

import { IChecklistItemProps } from './types';

export const Item: FunctionComponent<IChecklistItemProps> = ({ checklistItem }) => {
  const dispatch = useDispatch();
  const onDeleteChecklistItem = useCallback(() => {
    dispatch(deleteChecklistItem(checklistItem.id));
  }, [checklistItem.id, dispatch]);

  const [checked, setChecked] = useState(checklistItem.status === '1');
  const onCheck = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if ((checklistItem.status === '1') !== checked) {
      dispatch(updateChecklistItem(checklistItem.id, { ...checklistItem, status: checked ? '1' : '0' }));
    }
  }, [checklistItem, checked, dispatch]);

  return (
    <div className="checklist-checkbox flex aic py-1 m-0">
      <input type="checkbox" id={`checkBox-${checklistItem.id}`} checked={checked} onChange={onCheck} />

      <label htmlFor={`checkBox-${checklistItem.id}`}>{checklistItem.title}</label>
      <span
        className="remove-checklist-item link red f-2 ml-auto remove_checklist_item"
        onClick={onDeleteChecklistItem}
      >
        ×
      </span>
    </div>
  );
};
