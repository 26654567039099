/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useCallback, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { updateTask } from '../../../../../../../../../app/page/page-storage/boardLists/boardLists.actions';
import { taskSelectors } from '../../../../../../../../../app/resource/task/task.selectors';
import { ITask } from '../../../../../../../../../app/resource/task/task.types';
import { useSelectWithParams } from '../../../../../../../../../utils/selector.utils';
import { IHeaderProps } from './types';

export const Header: FunctionComponent<IHeaderProps> = ({ taskId, onClose }) => {
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId) as ITask;
  const dispatch = useDispatch();
  const [title, setTitle] = useState(task.title);

  const onBlur = useCallback(() => {
    const updatedTask = {
      id: task.id,
      title: title,
      description: task.description,
      dueDate: task.dueDate,
      userId: task.userId,
      meetingIds: task.meetingIds,
      status: task.status,
      label: task.label ?? [],
      taskTypeId: task.taskTypeId,
      members: task.members,
      showInTasks: task.showInTasks,
      boardId: task.boardId,
    };
    dispatch(updateTask(updatedTask));
  }, [dispatch, task, title]);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const updatedTitle = event.target.value;
    setTitle(updatedTitle);
  }, []);

  return (
    <div className="panel-header small-header flex jcsb">
      {task && <input className="editable" value={title} onChange={onChange} onBlur={(e) => onBlur()} />}
      <span className="close-popup close" onClick={onClose}>
        <i className="icon-close" />
      </span>
    </div>
  );
};
