import { all, call, fork, takeLatest } from 'redux-saga/effects';

import * as constants from './project-times.constants';
import * as types from './project-times.types';
import * as services from './project-times.saga-services';

function* invokeSetupProjectTimes(action: types.ISetupProjectTimes) {
  yield call(services.setupProjectTimes, action.payload);
}

export function* watchProjectTimesPurePageSaga() {
  yield takeLatest(constants.SETUP_PROJECT_TIME, invokeSetupProjectTimes);
}

export function* watchProjectTimesPageStorageSaga() {
  yield all([watchProjectTimesPurePageSaga].map(fork));
}
