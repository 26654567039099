import React, { useMemo } from 'react';
import OrganizationChart, { IDefaultChartProps } from '@dabeng/react-orgchart';
import { IOrganizationChartNode, IOrganizationChartSource } from '../../../../app/resource/node/node.types';
import { FunctionComponent } from 'react';
import { CustomNode } from './CustomNode';

import './style.css';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { nodeSelectors } from '../../../../app/resource/node/node.selectors';

const listToTree = (list: IOrganizationChartNode[]): IOrganizationChartNode | undefined => {
  const idMapping: { [key: string]: number } = list.reduce((prev, curr, i) => {
    prev[curr.id] = i;
    return { ...prev };
  }, {} as { [key: string]: number });
  const convert = (lista: IOrganizationChartNode[]): IOrganizationChartSource | undefined => {
    let root: IOrganizationChartSource | undefined = undefined;
    lista.forEach((el) => {
      if (!el.parentId) {
        root = el;
        return;
      }
      const parentEl = lista[idMapping[el.parentId]];
      if (parentEl) {
        parentEl.children = [...(parentEl.children || []), el];
      }
    });
    return root;
  };
  return convert(list.map((element) => ({ ...element, children: undefined })));
};

export const DefaultChart: FunctionComponent<IDefaultChartProps> = ({ nodeIds }) => {
  const nodes = useSelectWithParams(nodeSelectors.selectResourcesById, nodeIds);
  const datasource = useMemo(
    () => (nodes.length > 0 ? listToTree(nodes.filter((node) => !!node) as IOrganizationChartNode[]) : []),
    [nodes],
  );

  return (
    <div>
      {datasource && (
        <OrganizationChart chartClass="chart" datasource={datasource} draggable NodeTemplate={CustomNode} />
      )}
    </div>
  );
};
