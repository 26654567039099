import React, { useMemo } from 'react';
import { FunctionComponent } from 'react';
import { ITaskAvatarParams } from './types';
import { useSelectWithParams } from '../../../../../../../utils/selector.utils';
import { meetingSelectors } from '../../../../../../../app/resource/meeting/meeting.selectors';
import { userSelectors } from '../../../../../../../app/resource/user/user.selectors';

export const TaskAvatar: FunctionComponent<ITaskAvatarParams> = ({ meetingIds, userIds, taskType }) => {
  const meetings = useSelectWithParams(meetingSelectors.selectResourcesById, meetingIds);
  const users = useSelectWithParams(userSelectors.selectResourcesById, userIds);

  const image = useMemo(
    () => (taskType === 'rock' ? meetings.map((meetings) => meetings?.image) : users.map((users) => users?.image)),
    [taskType, meetings, users],
  );

  return (
    <div className="card-footer">
      <div className="group-stack mr-1">
        <span className="btn-badge white-bg f-1 bold">
          <img src={image[0]} alt="" className="img-fluid" />
        </span>
      </div>
      <div className="blueGray f-1"></div>
    </div>
  );
};
