/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import { userSelectors } from '../../../../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../../../../utils/selector.utils';
import { IUserCellProps } from './types';

export const UserCell: FunctionComponent<IUserCellProps> = ({ userId }) => {
  const user = useSelectWithParams(userSelectors.selectResourceById, userId);

  return (
    user && (
      <div className="account-item">
        <a className="small-avatar mr-05">
          <img src={user.image} alt={`${user.firstName} ${user.lastName}`} className="img-fluid" />
        </a>
        <div className="account-item-desc">
          <h3 className="account-item-title">{`${user.firstName} ${user.lastName}`}</h3>
        </div>
      </div>
    )
  );
};
