import React, { FunctionComponent, useCallback, useState } from 'react';
import { Dialogs } from '../../../dialogs';
import NavigationBar from '../navigation-bars/NavigationBar';
import { SideBar } from '../SideBar';
import { PageLayoutProps } from './types';

export const PageLayout: FunctionComponent<PageLayoutProps> = ({
  aditionalNavbar,
  children,
  smallSidebar,
  externalLogicNavbar,
  showLoader,
  showHide,
}) => {
  const [sideBar, setSideBar] = useState(!!smallSidebar);
  const toggleSideBar = useCallback(() => {
    setSideBar((sBar) => !sBar);
  }, [setSideBar]);
  return (
    <>
      <main id="app-root" className={sideBar ? 'small-menu' : ''}>
        <SideBar />
        <section>
          {externalLogicNavbar ? (
            externalLogicNavbar
          ) : (
            <NavigationBar
              showLoader={showLoader}
              leftArrow={sideBar}
              toggleSideBar={toggleSideBar}
              showHide={showHide}
            />
          )}
          {showLoader === true ? null : aditionalNavbar}

          {children}
        </section>
      </main>
      <Dialogs />
    </>
  );
};
