import React, { FunctionComponent, useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import {
  decideBoardListTypeBasedOnComplexId,
  generateActiveDoneComplexIds,
} from '../../../../../../../app/page/page-storage/tasks/tasks.saga-services';
import { boardListSelectors } from '../../../../../../../app/resource/boards/boardList/boardList.selectors';
import { meetingSelectors } from '../../../../../../../app/resource/meeting/meeting.selectors';
import { useSelectWithParams } from '../../../../../../../utils/selector.utils';
import { useToggler } from '../../../../../../../utils/toggler.utils';
import { Task } from '../../../../../BoardsPage/Board/BoardBody/BoardBodyTasks/Task';
import { TaskWidget } from '../../../../widget-items/task-widget-items/TaskWidget';
import { ITasksStandardListProps } from './types';

export const TasksStandardList: FunctionComponent<ITasksStandardListProps> = ({
  complexBoardListId,
  actionElement,
  name,
  isSingle,
}) => {
  const type = useMemo(() => decideBoardListTypeBasedOnComplexId(complexBoardListId), [complexBoardListId]);
  const [activeBoardList, doneBoardList] = useSelectWithParams(
    boardListSelectors.selectResourcesById,
    generateActiveDoneComplexIds(complexBoardListId),
  );
  const [show, handleShow] = useToggler(false);

  const myObject = activeBoardList && JSON.parse(activeBoardList.id);
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, myObject && myObject.meetingId);
  const shareWith = meeting && meeting.shareWithIds.filter((id) => id === myObject.userId);

  return (
    <div className="single-card-container">
      <div className="panel-body p-0">
        <div
          className="border-radius-1 p-2 pb-1"
          style={
            meeting && meeting.type === 'account' && shareWith && shareWith.length > 0
              ? { backgroundColor: '#eff1f3' }
              : { backgroundColor: '#f6f9fb' }
          }
        >
          {!isSingle && (
            <div className="panel-header p-0">
              <h2 className="bold">{name ? name : activeBoardList?.name}</h2>
              <div className="ml-auto flex">{actionElement}</div>
            </div>
          )}
          {activeBoardList && (
            <div className="todo-container tabs">
              <Droppable droppableId={activeBoardList.id}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {activeBoardList.tasksIds.map((id, index) => (
                      <Draggable key={`${activeBoardList.id}-${id}`} draggableId={id} index={index}>
                        {(provided) => {
                          if (type === 'default') {
                            return <TaskWidget taskId={id} provided={provided} />;
                          }
                          return <Task taskId={id} provided={provided} meetingId="" />;
                        }}
                      </Draggable>
                    ))}
                    {!activeBoardList.tasksIds.length && <div style={{ height: '20px' }}></div>}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {doneBoardList && (
                <div className="hidden_cards_container">
                  <span className="card-separator hide-cards" onClick={handleShow}>
                    <span className="completed_text">
                      {show
                        ? `HIDE COMPLETED (${doneBoardList.tasksIds.length})`
                        : `SHOW COMPLETED (${doneBoardList.tasksIds.length})`}
                    </span>
                  </span>
                  {show && (
                    <Droppable droppableId={doneBoardList.id}>
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {doneBoardList.tasksIds.map((id, index) => (
                            <Draggable key={`${doneBoardList.id}-${id}`} draggableId={id} index={index}>
                              {(provided) => {
                                if (type === 'default') {
                                  return <TaskWidget taskId={id} provided={provided} />;
                                }
                                return <Task taskId={id} provided={provided} meetingId="" />;
                              }}
                            </Draggable>
                          ))}
                          {!doneBoardList.tasksIds.length && <div style={{ height: '20px' }}></div>}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
