/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../app/dialog/dialog.actions';
import { deletePassword, savePassword } from '../../../../../app/page/page-storage/passwords/passwords.actions';
import { passwordSelectors } from '../../../../../app/resource/password/password.selectors';
import { IPasswordSaveForm } from '../../../../../app/resource/password/password.types';
import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { useToggler } from '../../../../../utils/toggler.utils';
import { IPasswordRowProps } from './types';
import PassLock from '../../../../resources/images/new-icons/pass-lock.svg';

export const PasswordRow: FunctionComponent<IPasswordRowProps> = ({ passwordId }) => {
  const dispatch = useDispatch();

  const password = useSelectWithParams(passwordSelectors.selectResourceById, passwordId);
  const [isToggled, handleToggle] = useToggler();

  const copyPassword = useCallback(() => {
    if (password) {
      navigator.clipboard.writeText(password.password);
    }
  }, [password]);

  const openInNewTab = useCallback(() => {
    if (password && password.siteUrl) {
      const link = password.siteUrl;
      if (link.startsWith('http://') || link.startsWith('https://')) {
        const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.opener = null;
        }
      } else {
        const newWindow = window.open('https://'.concat(link), '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.opener = null;
        }
      }
    }
  }, [password]);

  const onOpenDialog = useCallback(() => {
    if (password) {
      dispatch(
        openDialog('passwordFormDialog', {
          meetingId: password.meetingId,
          onSubmit: (data: IPasswordSaveForm) => {
            dispatch(savePassword(data));
          },
          data: password,
        }),
      );
    }
  }, [dispatch, password]);

  const onDelete = useCallback(() => {
    if (password) {
      dispatch(
        openDialog('deleteDialog', {
          onConfirm: () => {
            dispatch(deletePassword(password.id));
          },
        }),
      );
    }
  }, [dispatch, password]);
  return (
    password && (
      <div className="flex-table" role="rowgroup">
        <div className="flex-row first" role="cell">
          <div className="account-item">
            <div className="gray-box-pass blueGray-bg mr-1">
              <img src={PassLock} alt="" className="img-fluid" style={{ width: '10px' }} />
            </div>
            <div className="account-item-desc">
              <h3 className="account-item-title">{password.account}</h3>
            </div>
          </div>
        </div>
        <div className="flex-row f-14" role="cell">
          {password.username}
        </div>
        <div className="flex-row" role="cell">
          <div className="field-container password-field mb-0" onClick={copyPassword}>
            <span className={`copy_pass ${isToggled ? '' : 'toggled'}`}>
              <i className="icon2-copy" />
            </span>
            <span className="reveal_password" onClick={handleToggle}>
              <i className={`icon-eye ${isToggled ? 'toggled' : ''}`} />
            </span>
            <input
              type={isToggled ? 'password' : 'text'}
              readOnly
              name="password"
              className="small-input f-14 no-border"
              defaultValue={password.password}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
        <div className="flex-row aic jcr" role="cell">
          <a onClick={openInNewTab} className="btn btn-md darkGray-bg mr-1" style={{ cursor: 'pointer' }}>
            Login URL
          </a>
          {/* <a className="round-icon-small mr-1">
            <i className="icon2-folder" />
          </a> */}
          <a
            className="round-icon-small mr-1 popup_button"
            data-popup="new_password_popup"
            style={{ cursor: 'pointer' }}
            onClick={onOpenDialog}
          >
            <i className="icon-pencil" />
          </a>
          <a
            className="round-icon-small mr-0 popup_button"
            data-popup="delete_popup"
            style={{ cursor: 'pointer' }}
            onClick={onDelete}
          >
            <i className="icon2-trash" />
          </a>
        </div>
      </div>
    )
  );
};
