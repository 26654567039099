/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import { userSelectors } from '../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { IUserAvatarsProps } from './types';

export const UserAvatars: FunctionComponent<IUserAvatarsProps> = ({ userIds }) => {
  const users = useSelectWithParams(userSelectors.selectResourcesById, userIds);

  return (
    <div className="flex aic h100">
      {users &&
        users.map(
          (user) =>
            user && (
              <a key={user.id} className="small-avatar mr-05">
                <img src={user.image} alt={user.firstName} className="img-fluid" style={{ borderRadius: '50%' }} />
              </a>
            ),
        )}
    </div>
  );
};
