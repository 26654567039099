/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { useApiRequestState } from '../../../utils/api.utils';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { SubmitButton } from '../../pages/common/forms/SubmitButton';
import { SectionForm } from '../../pages/VtoPage/Section/SectionForm';
import { ISectionFormProps } from '../../pages/VtoPage/Section/SectionForm/types';
import { ConnectedDialog } from '../ConnectedDialog';

export const SectionFormDialog: FunctionComponent = () => {
  const dispatch = useDispatch();

  const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'sectionFormDialog') as ISectionFormProps;
  const [createShowLoader, createError] = useApiRequestState('createSection');
  const [deleteShowLoader, deleteError] = useApiRequestState('deleteSection');
  const [updateShowLoader, updateError] = useApiRequestState('updateSection');

  const error = useMemo(() => {
    if (deleteError) {
      return deleteError;
    } else if (createError) {
      return createError;
    } else if (updateError) {
      return null;
    }
  }, [createError, deleteError, updateError]);

  const showLoader = useMemo(() => {
    return createShowLoader || deleteShowLoader || updateShowLoader;
  }, [createShowLoader, deleteShowLoader, updateShowLoader]);

  const onClose = useCallback(() => {
    dispatch(closeDialog('sectionFormDialog'));
  }, [dispatch]);

  const [showWarning, setShowWarning] = useState(false);

  const handleDelete = useCallback(() => {
    if (props && props.onDelete) {
      props.onDelete();
      setShowWarning(false);
    }
  }, [props]);

  return (
    props && (
      <ConnectedDialog dialogKey="sectionFormDialog">
        <div className="popup new_todo_popup" style={{ display: 'block' }}>
          <div className="panel">
            <div className="panel-header small-header flex jcsb">
              {`${props.data ? 'Update' : 'Add'} Section`}
              <span className="close-popup close" onClick={onClose}>
                {!error && !showLoader && <i className="icon-close" />}
                {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
                {error && !showLoader && <p style={{ color: 'red' }}> Something went wrong </p>}
              </span>
            </div>
            <div className="panel-body" style={{ maxHeight: '619.1px' }}>
              <SectionForm {...props} />
            </div>
            <div className="panel-footer no-border flex jcr">
              <div
                className="delete-section-container bottom-notification red-bg white"
                style={{ display: `${showWarning ? 'flex' : 'none'}`, boxSizing: 'border-box' }}
              >
                <span className="">Are you sure you want to delete this section? </span>
                <div className="notif-buttons">
                  <a
                    className="btn no-bg white btn-border no_delete_section"
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowWarning(false)}
                  >
                    No
                  </a>
                  <a className="btn white-bg red btn-border mr-0" style={{ cursor: 'pointer' }} onClick={handleDelete}>
                    Yes
                  </a>
                </div>
              </div>

              {props.onDelete && (
                <a
                  className="link red mr-2 mr-auto delete_section"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowWarning(true)}
                >
                  Delete
                </a>
              )}
              <a className="link blueGray mr-2 close" onClick={onClose}>
                Close
              </a>
              {!props && <SubmitButton apiKey="createSection" formName="section-form" text="Save" />}
              {props && <SubmitButton apiKey="updateSection" formName="section-form" text="Save" />}
            </div>
          </div>
        </div>
      </ConnectedDialog>
    )
  );
};
