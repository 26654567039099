import React, { FunctionComponent } from 'react';
import { userSelectors } from '../../../../../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../../../../../utils/selector.utils';
import { IKpiRowUserDetailsProps } from './types';

export const KpiRowUserDetails: FunctionComponent<IKpiRowUserDetailsProps> = ({ userId }) => {
  const user = useSelectWithParams(userSelectors.selectResourceById, userId);
  return (
    user && (
      <span className="kpi-header-user">
        <span className="small-avatar" style={{ width: '34px', height: '34px' }}>
          <img
            style={{ borderRadius: '50%', width: '34px', height: '34px' }}
            src={user.image}
            alt={`${user.firstName} ${user.lastName}`}
            className="img-fluid popup_button"
            title={`${user.firstName} ${user.lastName}`}
          />
        </span>
      </span>
    )
  );
};
