import { all, call, put, select } from 'redux-saga/effects';

import * as types from './password.types';
import * as actions from './password.actions';

import { passwordSelectors } from './password.selectors';
import { apiRequest } from '../../api/api.saga-services';
import * as api from '../../api/request-config/password.api';
import { selectWithParams } from '../../../utils/selector.utils';
import { passwordGroupSelectors } from '../password-group/password-group.selectors';
import { IPasswordGroup } from '../password-group/password-group.types';
import { storePasswordGroup } from '../password-group/password-group.actions';
import * as CryptoJS from 'crypto-js';

const TEST_PASSWORD_KEY = 'test-password-key';

const decriptPassword = (password: string) => {
  const bytes = CryptoJS.AES.decrypt(password, TEST_PASSWORD_KEY);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const cryptPassword = (password: string): string => {
  return CryptoJS.AES.encrypt(password, TEST_PASSWORD_KEY).toString();
};

export function* storePassword(password: types.IPassword) {
  yield put(actions.storePassword({ ...password, password: decriptPassword(password.password) }));
  return password;
}
export function* clearPassword(passwordId: types.IPassword['id']) {
  yield put(actions.clearPassword(passwordId));
}
export function* clearAllPasswords() {
  const passwordIds: types.IPassword['id'][] = yield select(passwordSelectors.selectResourceIds);
  yield put(actions.clearPassword(passwordIds));
}

export function* searchPasswords(searchParams?: types.IPasswordSearchParams) {
  const passwords = (yield call(apiRequest, api.searchPasswordsApi(searchParams))) as types.IPassword[];
  if (!passwords) {
    return;
  }

  yield all(passwords.map((password) => call(storePassword, password)));
  return passwords;
}

export function* savePassword(data: types.IPasswordSave) {
  const password = (yield call(
    apiRequest,
    api.savePasswordApi({ ...data, password: cryptPassword(data.password) }),
  )) as types.IPassword;

  const passwordGroups = (yield selectWithParams(
    passwordGroupSelectors.selectResourcesById,
    password.groupIds,
  )) as IPasswordGroup[];

  yield all(
    passwordGroups.map((group) =>
      group.passwordIds.includes(password.id)
        ? () => undefined
        : put(storePasswordGroup({ ...group, passwordIds: [...group.passwordIds, password.id] })),
    ),
  );

  yield call(storePassword, password);
  return password;
}

export function* deletePassword(passwordId: types.IPassword['id']) {
  yield call(apiRequest, api.deletePasswordApi(passwordId));
  const password = (yield selectWithParams(passwordSelectors.selectResourceById, passwordId)) as types.IPassword;
  if (!password) {
    return;
  }
  const passwordGroups = (yield selectWithParams(
    passwordGroupSelectors.selectResourcesById,
    password.groupIds,
  )) as IPasswordGroup[];

  yield all(
    passwordGroups.map((group) =>
      group.passwordIds.includes(password.id)
        ? put(storePasswordGroup({ ...group, passwordIds: group.passwordIds.filter((id) => passwordId !== id) }))
        : () => undefined,
    ),
  );
}
