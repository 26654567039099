import React, { FunctionComponent } from 'react';
import { sessionSelectors } from '../../../../../../app/resource/session/session.selectors';
import { useSelectWithParams } from '../../../../../../utils/selector.utils';
import { MeetingName } from './MeetingName';
import { INextMeetingParams } from './types';

export const NextMeeting: FunctionComponent<INextMeetingParams> = ({ sessionId }) => {
  const nextSession = useSelectWithParams(sessionSelectors.selectResourceById, sessionId);

  return (
    <div className="panel-text ml-2">
      <span className="darkGray">
        <i className="icon-users mr-05"></i> Next Meeting:
        {nextSession ? (
          <>
            <MeetingName meetingId={nextSession.meetingId} date={nextSession.sessionDate} />
          </>
        ) : (
          '/'
        )}
      </span>
    </div>
  );
};
