import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { initialAgencyToolsPage } from '../../../app/page/agency-tools/agency-tools.actions';
import { pageStateSelectors } from '../../../app/page/page-state/page-state.selectors';
import { IItem } from '../../../app/resource/item/item.types';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { PageLayout } from '../common/PageLayout';
import { Item } from './Item';

export const AgencyDashboardPage = () => {
  const dispatch = useDispatch();
  const { itemId } = useParams<{ itemId: IItem['id'] }>();
  const showLoader = useSelectWithParams(pageStateSelectors.selectPageInProgress, 'agencyTools');

  useEffect(() => {
    dispatch(initialAgencyToolsPage());
  }, [dispatch]);
  return (
    <PageLayout showLoader={showLoader}>
      <Item itemId={itemId} />
    </PageLayout>
  );
};
