import React from 'react';
import { FunctionComponent } from 'react';

import { projectTeamsPageSelectors } from '../../../../../../app/page/page-storage/project-members/project-members.selectors';
import { useSelect } from '../../../../../../utils/selector.utils';
import { Team } from './Team';

export const Teams: FunctionComponent = () => {
  const teamsIds = useSelect(projectTeamsPageSelectors.selectProjectTeams);

  return (
    <div className="panel white-bg p-2 mt-2">
      <div className="single-tracinkg-account">
        <div className="border-radius-1 lightGray-bg pl-2 py-1 w100 flex aic">
          <div className="tracking-small-team-item">
            <span className="bold f-1">{`EMPLOYEES (${teamsIds.length})`}</span>
          </div>
          <div className="tracking-actions">
            <div className="tracking-small-team-hours">
              <span className="bold f-1">TOTAL HOURS</span>
            </div>
            <div className="team-team-capacity">
              <span className="bold f-1">CAPACITY</span>
            </div>
            <div className="team-billable-hours">
              <span className="bold f-1">BILLABLE HOURS</span>
            </div>
          </div>
        </div>
        <div className="tracking-team-table">
          {teamsIds.map((teamId) => (
            <Team teamId={teamId} key={teamId} />
          ))}
        </div>
      </div>
    </div>
  );
};
