import React, { FunctionComponent } from 'react';
// import { DragDropContext } from 'react-beautiful-dnd';
import { IWidgetWrapperSingleColumnParams } from './types';

export const WidgetWrapperSingleColumn: FunctionComponent<IWidgetWrapperSingleColumnParams> = ({
  name,
  actionElement,
  filterElement,
  children,
}) => {
  return (
    <div className="col-3">
      <div className="panel">
        <div className="panel-header">
          <h2 className="bold">{name}</h2>
          <div className="ml-auto flex">{actionElement}</div>
        </div>
        <div className="panel-body">
          <div className="border-radius-1 lightGray-bg">{children}</div>
        </div>
      </div>
    </div>
  );
};
