import { all, fork } from 'redux-saga/effects';
import { watchLiveMeetingPageSaga } from './live-meeting/live-meeting.saga-watchers';
import { watchMeetingsPageSaga } from './page-storage/meetings/meetings.saga-watchers';
import { watchUsersPageSaga } from './page-storage/users/users.saga-watchers';
import { watchHomePageSaga } from './home/home.saga-watchers';
import { watchMeetingDashboardPageSaga } from './meeting-dashboard/meeting-dashboard.saga-watchers';
import { watchMeetingPageSaga } from './meetings/meetings.saga-watchers';
import { watchMeetingsFormSaga } from './meetings/meeting-form/meeting-form.saga-watchers';
import { watchAgendaItemsPageSaga } from './page-storage/agenda-items/agenda-items.saga.watchers';
import { watchTaskPageSaga } from './page-storage/tasks/tasks.saga.watchers';
import { watchKpiPageSaga } from './page-storage/kpi/kpi.saga.watchers';
import { watchConclusionPageSaga } from './page-storage/conclusions/conclusions.saga.watchers';
import { watchSessionsPageSaga } from './page-storage/sessions/sessions.saga-watchers';
import { watchSingleKpiPageSaga } from './kpi/kpi.saga-watchers';
import { watchUserPageInitialSaga } from './users/users.saga-watchers';
import { watchNotificationsPageSaga } from './page-storage/notifications/notifications.saga-watchers';
import { watchTemplatesPageSaga } from './page-storage/templates/templates.saga-watchers';

import { watchVtoPageSaga } from './vto/vto.saga-watchers';
import { watchCalendarsPageSaga } from './page-storage/calendars/calendars.saga-watchers';
import { watchChartPageSaga } from './chart/chart.saga-watchers';
import { watchChartPageSaga as watchNodesPageSaga } from './page-storage/chart/chart.saga-watchers';
import { watchBoardsPageStorageSaga } from './page-storage/boards/boards.saga-watchers';
import { watchBoardsPageSaga } from './boards/boards.saga-watchers';
import { watchBoardListsPageStorageSaga } from './page-storage/boardLists/boardLists.saga-watchers';
import { watchLabelsPageStorageSaga } from './page-storage/labels/labels.saga-watchers';
import { watchCheckListsPageStorageSaga } from './page-storage/checklists/checkLists.saga-watchers';

import { watchActivitiesPageStorageSaga } from './page-storage/activities/activities.saga-watchers';
import { watchUsersGroupPageSaga } from './page-storage/user-groups/user-groups.saga-watchers';
import { watchPasswordsPageSaga } from './page-storage/passwords/passwords.saga-watchers';
import { watchPasswordsPageSaga as watchInitPasswordsPageSaga } from './passwords/passwords.saga-watchers';

import { watchPasswordsGroupPageSaga } from './page-storage/password-groups/password-groups.saga-watchers';
import { watchTimeTrackingPageSaga } from './time-tracking/time-tracking.saga-watchers';
import { watchProjectTasksPageStorageSaga } from './page-storage/project-tasks/project-tasks.saga-watchers';
import { watchProjectMembersPageStorageSaga } from './page-storage/project-members/project-members.saga-watchers';
import { watchProjectsPageStorageSaga } from './page-storage/projects/projects.saga-watchers';
import { watchTimerSaga } from './timer/timer.saga-watchers';
import { watchProjectTimesPageStorageSaga } from './page-storage/project-times/project-times.saga-watchers';
import { watchQuartalsPageSaga } from './quartals/quartals.saga-watchers';
import { watchQuartalsPageStorageSaga } from './page-storage/quartals/quartals.saga-watchers';
import { watchWidgetsPageSaga } from './page-storage/widgets/widgets.saga-watcher';
import { watchCategoriesPageStorageSaga } from './page-storage/categories/categories.saga-watchers';
import { watchAgencyToolsPageSaga } from './agency-tools/agency-tools.saga-watchers';
import { watchAnswersPageStorageSaga } from './page-storage/answers/answers.saga-watchers';
import { watchMeetingsInfoPageSaga } from './page-storage/meetings-info/meetings-info.saga-watchers';

export function* watchPageSaga() {
  yield all(
    [
      watchHomePageSaga,
      watchLiveMeetingPageSaga,
      watchMeetingDashboardPageSaga,
      watchMeetingsPageSaga,
      watchUserPageInitialSaga,
      watchAgendaItemsPageSaga,
      watchMeetingPageSaga,
      watchMeetingsFormSaga,
      watchUsersPageSaga,
      watchTaskPageSaga,
      watchSessionsPageSaga,
      watchKpiPageSaga,
      watchSingleKpiPageSaga,
      watchConclusionPageSaga,
      watchNotificationsPageSaga,
      watchVtoPageSaga,
      watchTemplatesPageSaga,
      watchCalendarsPageSaga,
      watchChartPageSaga,
      watchNodesPageSaga,
      watchBoardsPageSaga,
      watchBoardsPageStorageSaga,
      watchBoardListsPageStorageSaga,
      watchLabelsPageStorageSaga,
      watchCheckListsPageStorageSaga,
      watchActivitiesPageStorageSaga,
      watchUsersGroupPageSaga,
      watchPasswordsPageSaga,
      watchPasswordsGroupPageSaga,
      watchInitPasswordsPageSaga,
      watchTimeTrackingPageSaga,
      watchProjectTasksPageStorageSaga,
      watchProjectMembersPageStorageSaga,
      watchProjectsPageStorageSaga,
      watchProjectTimesPageStorageSaga,
      watchTimerSaga,
      watchQuartalsPageSaga,
      watchQuartalsPageStorageSaga,
      watchWidgetsPageSaga,
      watchCategoriesPageStorageSaga,
      watchAgencyToolsPageSaga,
      watchAnswersPageStorageSaga,
      watchMeetingsInfoPageSaga,
    ].map(fork),
  );
}
