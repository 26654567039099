/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { columnSelectors } from '../../../../../../app/resource/column/column.selectors';
import { useSelectWithParams } from '../../../../../../utils/selector.utils';
import { Question } from './Question';
import { IColumnProps } from './types';
import Arrow from '../../../../../resources/images/arrow-down.svg';
import { QuestionRow } from '../../../../AgencySinglePage/SingleColumn/QuestionRow';

export const Column: FunctionComponent<IColumnProps> = ({ columnId, singlePage }) => {
  const column = useSelectWithParams(columnSelectors.selectResourceById, columnId);
  return (
    <>
      <div className="row mb-1" style={{ width: `${column?.columnLength}%`, flexGrow: 1 }}>
        <div className="col-12">
          <div className="panel" style={{ height: '100%' }}>
            <div className="panel-body">
              <div className="row mb-0">
                <div className="col-12">
                  <div className={`panel ${column?.colorClass} min-h60 py-1 px-2 flex aic mb-2 no-shadow`}>
                    <h2 className="bold white lh-13 flex aic jcsb">{column?.title}</h2>
                    {column?.columnType !== 'noneditable' && (
                      <a className="link white ml-auto popup_button" data-popup="edit_vto_section_with_reset_popup">
                        Edit
                      </a>
                    )}
                  </div>
                </div>
                {column?.questionIds.map((questionId) => (
                  <>{singlePage ? <QuestionRow questionId={questionId} /> : <Question questionId={questionId} />}</>
                ))}
                {singlePage && (
                  <div
                    className="col-4"
                    style={{
                      width: `100%`,
                      maxWidth: `100%`,
                      flex: `0 0 100%`,
                    }}
                  >
                    <div className="flex aic mb-1">
                      <div className="profile-input">
                        <button className="btn btn-border white-bg darkGray min-h50 text-left border-2x px-1 no-shadow px-2 w100 mx-0 f-14">
                          + Add Property
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {column?.showArraw === '1' && (
        <div className="row" style={{ width: '100%' }}>
          <div className="text-center my-3 w100">
            <img src={Arrow} alt="" className="img-fluid" />
          </div>
        </div>
      )}
    </>
  );
};
