/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { useApiRequestState } from '../../../utils/api.utils';
import { useSelectWithParams } from '../../../utils/selector.utils';

import { ConnectedDialog } from '../ConnectedDialog';
import { IProjectFormDialogProps } from './types';
import { ProjectFormData } from './ProjectFormData';

export const ProjectFormDialog: FunctionComponent = () => {
  const dispatch = useDispatch();

  const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'projectFormDialog') as IProjectFormDialogProps;

  const [showLoader, error] = useApiRequestState('getProjects');

  const onClose = useCallback(() => {
    dispatch(closeDialog('projectFormDialog'));
  }, [dispatch]);

  return (
    <ConnectedDialog dialogKey="projectFormDialog">
      <div className="popup new_todo_popup" style={{ display: 'block' }}>
        <div className="panel" style={{ minWidth: '700px' }}>
          <div className="panel-header small-header flex jcsb">
            {'Edit Project'}

            <span className="close-popup close" onClick={onClose}>
              {!error && !showLoader && <i className="icon-close" />}
              {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
              {error && !showLoader && <p style={{ color: 'red' }}> Something went wrong </p>}
            </span>
          </div>
          <div className="panel-body" style={{ maxHeight: '619.1px' }}>
            {props && <ProjectFormData projectId={props.projectReportId} />}
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" onClick={onClose}>
              Close
            </a>
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
