import * as types from './timer.types';

export const timerSelectors: types.ITimerSelectors = {
  selectIsOpen: (state) => state.page.timer.isOpen,
  selectTimerStatus: (state) => state.page.timer.timerStatus,
  selectTime: (state) => state.page.timer.time,
  selectClient: (state) => state.page.timer.clientId,
  selectProjectTasks: (state) => state.page.timer.projectTaskIds,
  selectProjectTask: (state) => state.page.timer.projectTaskId,
  selectNote: (state) => state.page.timer.note,
  selectTimerResult: (state) => state.page.timer.timerResultId,
};
