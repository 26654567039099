/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';

import { MenuLabels } from './MenuLabels';
import { TaskLabels } from './TaskLabels';

import { ILabelsProps } from './types';

export const Labels: FunctionComponent<ILabelsProps> = ({ labelsIds, menu, taskId }) => {
  return menu ? <MenuLabels taskId={taskId} /> : <TaskLabels taskId={taskId} />;
};
