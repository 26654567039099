import React, { FunctionComponent, useCallback } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { dndTask } from '../../../../../app/page/page-storage/tasks/tasks.actions';

export const TaskDragDropContext: FunctionComponent = ({ children }) => {
  const dispatch = useDispatch();
  const handleDrop = useCallback(
    (result: DropResult) => {
      console.log(result);
      dispatch(dndTask(result));
      // dispatch(updateTask, {
      //   propertyTargets: {
      //     propertyTarget: newValue,
      //     propertyTarget: newValue...
      //   }
      //   taskId: ...
      // });
    },
    [dispatch],
  );

  return <DragDropContext onDragEnd={handleDrop}>{children}</DragDropContext>;
};
