import { FunctionComponent, useMemo } from 'react';
import React from 'react';
import { TaskFormTrigger } from '../../../../dialogs/TaskFormDialog/TaskFormTrigger';
import { IWidgetIdsProps } from './types';
import { WidgetTasksSimpleLineView } from '../widget-grouped/WidgetTasksSimpleLineView';
import { WidgetTasksGroupedByUser } from '../widget-grouped/WidgetTasksGroupedByUser';

export const WidgetIds: FunctionComponent<IWidgetIdsProps> = ({ meetingId, reducedTasks }) => {
  const todos = useMemo(() => reducedTasks['4'], [reducedTasks]);
  return (
    <>
      <div className="row mb-2">
        <div className="col-6">
          <WidgetTasksSimpleLineView type={'2'} meetingId={meetingId} title="Review Issues" />
        </div>
        <div className="col-6">
          <WidgetTasksSimpleLineView type={'5'} meetingId={meetingId} title="Backburner" />
        </div>
      </div>
      {/* <WidgetTasksStandardView
        complexBoardId={generateBoardId(meetingId, 'groupTodosByUser')}
        actionElement={<TaskFormTrigger type="4" meetingId={meetingId} />}
      /> */}
      <WidgetTasksGroupedByUser
        meetingId={meetingId}
        actionElement={<TaskFormTrigger type="4" meetingId={meetingId} />}
        name="To Do List"
        type="4"
        tasksPerUser={todos}
      />
    </>
  );
};
