import { createActionConst } from '../../../../utils/action.utils';

const CONCLUSIONS_NAMESPACE = 'CONCLUSIONS';

export const STORE_CONCLUSIONS = createActionConst(CONCLUSIONS_NAMESPACE, 'STORE_CONCLUSIONS');
export const CLEAR_CONCLUSIONS = createActionConst(CONCLUSIONS_NAMESPACE, 'CLEAR_CONCLUSIONS');

export const FETCH_CONCLUSIONS = createActionConst(CONCLUSIONS_NAMESPACE, 'FETCH_CONCLUSIONS');
export const CREATE_CONCLUSION = createActionConst(CONCLUSIONS_NAMESPACE, 'CREATE_CONCLUSION');
export const UPDATE_CONCLUSION = createActionConst(CONCLUSIONS_NAMESPACE, 'UPDATE_CONCLUSION');
export const DELETE_CONCLUSION = createActionConst(CONCLUSIONS_NAMESPACE, 'DELETE_CONCLUSION');

export const SAVE_CONCLUSION_SOCKET = 'saveConclusion';
