import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { pageStateSelectors } from '../../../app/page/page-state/page-state.selectors';
import { initialTimeTrackingPage } from '../../../app/page/time-tracking/time-tracking.actions';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { PageLayout } from '../common/PageLayout';
import { Tabs } from './Tabs';

export const TimeTrackingPage: FunctionComponent = () => {
  const showLoader = useSelectWithParams(pageStateSelectors.selectPageInProgress, 'timeTracking');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialTimeTrackingPage());
  }, [dispatch]);

  return (
    <PageLayout showLoader={showLoader}>
      <Tabs />
    </PageLayout>
  );
};
