/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';

import { checkListSelectors } from '../../../../../../../../../app/resource/checklist/checkList.selectors';

import { useSelectWithParams } from '../../../../../../../../../utils/selector.utils';
import { CheckList } from './CheckList';
import { ICheckListsProps } from './types';

export const CheckLists: FunctionComponent<ICheckListsProps> = ({ checkListsIds }) => {
  const checkLists = useSelectWithParams(checkListSelectors.selectResourcesById, checkListsIds);

  return (
    <div className="row">
      {checkLists.map(
        (checkList) => checkList && <CheckList checkList={checkList} key={`checkListId-${checkList.id}`} />,
      )}
    </div>
  );
};
