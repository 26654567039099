import { call, put, select } from 'redux-saga/effects';

import * as actions from './projects.actions';
import * as projectResourceService from '../../../resource/project/project.saga-service';
import { projectsPageSelectors } from './projects.selectors';
import * as dialogService from '../../../dialog/dialog.saga-services';
import { IProject, IProjectCreate, IProjectCreateForm } from '../../../resource/project/project.types';
import { IAccountProject } from '../../../resource/account-project/account-project.types';
import { accountProjectSelectors } from '../../..//resource/account-project/account-project.selectors';
import * as accountProjectService from '../../..//resource/account-project/account-project.saga-service';

export function* clearProjects() {
  yield put(actions.clearProject());
}

export function* setupProjects(accountId: string, projectId?: string) {
  const projects = (yield call(projectResourceService.searchProjects, accountId, projectId)) as IProject['id'][];

  yield put(projects ? actions.storeProjects(projects) : actions.clearProject());
}

export function* setupProject(accountId: string, projectId?: string) {
  const projects = (yield call(projectResourceService.searchProjects, accountId, projectId)) as IProject['id'][];

  yield put(projects ? actions.storeProjects(projects) : actions.clearProject());
}

export function* createProject(projectInfo: IProjectCreateForm) {
  const project = (yield call(
    projectResourceService.createProject,
    mapProjectFormToProjectCreate(projectInfo),
  )) as IProject;

  if (!project) {
    return;
  }
}

export function* updateProject(projectId: IProject['id'], projectInfo: IProjectCreateForm) {
  const project = (yield call(projectResourceService.updateProject, {
    id: projectId,
    ...mapProjectFormToProjectCreate(projectInfo),
  })) as IProject;

  if (!project) {
    return;
  }
}

export function* deleteProject(projectId: IProject['id'], accountId: IAccountProject['id']) {
  yield call(projectResourceService.deleteProject, projectId);

  const projectsIds: IProject['id'][] = yield select(projectsPageSelectors.selectProjects);

  yield put(projectsIds ? actions.storeProjects(projectsIds.filter((id) => projectId !== id)) : actions.clearProject());

  const accountProject: IAccountProject = yield select(accountProjectSelectors.selectResourceById, accountId);
  const updatedIds = accountProject.projectReportIds.filter((id) => projectId !== id);
  const updateProject: IAccountProject = { ...accountProject, projectReportIds: updatedIds };
  yield call(accountProjectService.storeAccountProject, updateProject);

  yield call(dialogService.closeDialog, 'projectFormDialog');
}

export const mapProjectFormToProjectCreate = (projectInfo: IProjectCreateForm): IProjectCreate => ({
  name: projectInfo.name,
  startDate: projectInfo.startDate,
  endDate: projectInfo.endDate,
  note: projectInfo.note,
  type: projectInfo.type,
  hourlyRate: projectInfo.hourlyRate ? projectInfo.hourlyRate.value : 'no_rate',
  budgetType: projectInfo.budgetType.value,
  totalProjectFee: projectInfo.totalProjectFee,
  totalProjectHours: projectInfo.totalProjectHours,
  projectHoursRate: projectInfo.projectHoursRate,
  fixedFee: projectInfo.fixedFee,
  showToAll: projectInfo.showToAll,
  showToAdmins: projectInfo.showToAdmins,
  accountId: projectInfo.account.value,
  teams: projectInfo.teams ? projectInfo.teams : [],
  tasks: projectInfo.tasks ? projectInfo.tasks : [],
});

export function* clearProjectsPageStorage() {
  yield put(actions.clearProject());
}
/*
export function* handleProjectFromNotification(projectResponse: IProjectFromNotification) {
  const projectExists = (yield selectWithParams(
    projectSelectors.selectResourceById,
    projectResponse.id,
  )) as IProject | null;

  if (projectExists) {
    yield call(projectResourceService.storeProject, { ...projectResponse });
  } else {
    const projectsIds = yield select(projectsPageSelectors.selectProjects);
    yield call(projectResourceService.storeProject, { ...projectResponse });

    yield put(actions.storeProjects([...projectsIds, projectResponse.id]));
  }
}
*/
