import React, { FunctionComponent } from 'react';
import { WidgetKpiBody } from './WidgetKpiBody';
import { WidgetKpiHeader } from './WidgetKpiHeader';

export const WidgetKpi: FunctionComponent = () => {
  return (
    <div className="panel">
      <div className="panel-body pt-0 ho-hover">
        <div className="border-radius-1 lightGray-bg p-2" style={{ position: 'sticky', top: '20px' }}>
          <div className="row">
            <div className="col">
              <div className="table-container kpi-table" role="table" aria-label="Destinations">
                <WidgetKpiHeader />
                <WidgetKpiBody />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
