import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { setupProjectTeams } from '../../../../../app/page/page-storage/project-members/project-members.actions';
import { Teams } from './Teams';
import { Total } from './Total';

export const TeamTab: FunctionComponent = () => {
  const currentWeek = moment().isoWeek();
  const [week, setWeek] = useState(currentWeek);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setupProjectTeams(week.toString()));
  }, [dispatch, week]);

  const chosenWeekStart = moment()
    .startOf('isoWeek')
    .week(week + 1);

  const chosenWeekEnd = moment()
    .endOf('isoWeek')
    .week(week + 2);

  const startDay = chosenWeekStart.format('D');
  const endDay = chosenWeekEnd.format('D MMM YYYY');

  const setNextWeek = useCallback(() => {
    setWeek(week + 1);
  }, [week]);

  const setPrevWeek = useCallback(() => {
    setWeek(week - 1);
  }, [week]);
  return (
    <>
      <div className="panel white-bg flex px-2 py-1">
        <div className="week-timer-buttons flex mr-2">
          <span className="btn btn-sm white-bg btn-border" onClick={setPrevWeek}>
            <i className="icon-left-arrow blueGray transition2" />
          </span>
          <span className="btn btn-sm white-bg btn-border" onClick={setNextWeek}>
            <i className="icon-left-arrow blueGray transition2 icon-rotated" />
          </span>
        </div>
        <h2 className="flex aic f-2">
          <span className="bold">{week === currentWeek ? `This Week` : ''}</span>
          {week === currentWeek ? `:` : ''} {`${startDay} – ${endDay}`}
        </h2>
        <div className="ml-auto flex aic jcr">
          <div className="field-container custom-select small-select header-select mb-0 mr-1">
            <input type="hidden" defaultValue="Folder 2" />
            <span className="select-field">
              <span className="select-value">
                Everyone <i className="icon-down-dir" />
              </span>
            </span>
            <div className="select-dropdown" style={{ display: 'none', boxSizing: 'border-box' }}>
              <div className="select-item" data-val="Everyone 1">
                Everyone 1
              </div>
              <div className="select-item selected" data-val="Everyone 2">
                Everyone 2
              </div>
              <div className="select-item" data-val="Everyone 3">
                Everyone 3
              </div>
              <div className="select-item" data-val="Everyone 4">
                Everyone 4
              </div>
              <div className="select-item" data-val="Everyone 5">
                Everyone 5
              </div>
            </div>
          </div>
        </div>
      </div>
      <Total />
      <Teams />
    </>
  );
};
