/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { userSelectors } from '../../../../../../../../app/resource/user/user.selectors';
import { IUser } from '../../../../../../../../app/resource/user/user.types';
import { useSelectWithParams } from '../../../../../../../../utils/selector.utils';

import { IMembersProps } from './types';

export const Members: FunctionComponent<IMembersProps> = ({ membersIds }) => {
  const members = useSelectWithParams(userSelectors.selectResourcesById, membersIds) as IUser[];
  return (
    <div className="group-stack text-right">
      {members.map(
        (member) =>
          member && (
            <span className="btn-badge white-bg f-1 bold" key={`memberId-${member.id}`}>
              <img src={member.image} alt="" className="img-fluid" style={{ borderRadius: '50%' }} />
            </span>
          ),
      )}
    </div>
  );
};
