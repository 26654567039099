import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateTask } from '../../../../../app/page/page-storage/boardLists/boardLists.actions';
import { IDueDateForm } from '../../../../../app/resource/task/task.types';
import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { DateTimeInput } from '../../inputs/DateTimeInput';
import { SelectInput } from '../../inputs/SelectInput';
import { SubmitButton } from '../SubmitButton';
import { IDueDateFormProps } from './types';

export const DueDateForm: FunctionComponent<IDueDateFormProps> = ({ task }) => {
  const dispatch = useDispatch();

  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: IDueDateForm) => {
    dispatch(updateTask({ label: [], ...task, dueDate: data.dueDate, reminder: data.reminder.value }));
  };
  const reminderOptions = [
    { value: '0', label: 'None' },
    { value: '1', label: 'At a time of due date' },
    { value: '2', label: '1 day before' },
    { value: '3', label: '2 days before' },
  ];

  const defaultStartDateValue = task.dueDate;
  const defaultDueDate = useMemo(() => {
    try {
      if (defaultStartDateValue) {
        const dateString = moment(defaultStartDateValue).format('YYYY-MM-DD');
        if (dateString === 'Invalid date') {
          return '0000-00-00 00:00:00';
        }
        return defaultStartDateValue;
      }
    } catch (error) {
      console.log(error);
      return '0000-00-00 00:00:00';
    }
  }, [defaultStartDateValue]);
  useEffect(() => {
    formProps.setValue(
      'dueDate',
      task && (defaultDueDate !== '0000-00-00 00:00:00' || '')
        ? new Date(moment(defaultDueDate).format('YYYY-MM-DD'))
        : new Date(),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task]);
  /*
  const date = useMemo(() => {
    try {
      if (defaultValue) {
        const dateString = moment(defaultValue).format('YYYY-MM-DD');

        return new Date(dateString);
      } else {
        return new Date();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);*/
  return (
    <FormProvider {...formProps}>
      <form
        id="duedate-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IDueDateForm))}
        method="post"
      >
        <div className="field-container">
          <DateTimeInput
            id="dueDate"
            name="dueDate"
            label="Due Date"
            validation={requiredValidation}
            //defaultValue={task.dueDate}
          />
        </div>
        <div className="field-container">
          <SelectInput id="reminder" name="reminder" label="Set due date reminder" options={reminderOptions} isSingle />
        </div>
        <SubmitButton formName="duedate-form" text="SAVE" />
      </form>
    </FormProvider>
  );
};
