import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './live-meeting.constants';
import * as types from './live-meeting.types';
import * as services from './live-meeting.saga-services';

function* invokeInitialLiveMeetingPage(action: types.IInitalLiveMeetingPage) {
  yield call(services.initalLiveMeetingPage, action.payload);
}

export function* watchLiveMeetingPageSaga() {
  yield takeLatest(constants.INITIAL_LIVE_MEETING_PAGE, invokeInitialLiveMeetingPage);
}
