import { all, call, put, select } from 'redux-saga/effects';

import * as actions from './users.actions';

import * as userResourceService from '../../../resource/user/user.saga-service';
import * as dialogService from '../../../dialog/dialog.saga-services';

import { IUserSearchParams, IUserCreateForm, IUser, IUserSignUp } from '../../../resource/user/user.types';
import { usersPageSelectors } from './users.selectors';
import { selectWithParams } from '../../../../utils/selector.utils';
import { userSelectors } from '../../../resource/user/user.selectors';
import { userGroupSelectors } from '../../../resource/user-group/user-group.selectors';
import { IUserGroup } from '../../../resource/user-group/user-group.types';
import { storeUserGroup } from '../../../resource/user-group/user-group.actions';
import moment from 'moment';
import { userFormSelectors } from './user-form/user-form.selectors';

// import * as apiService from '../../api/api.saga-services';

export function* setupUsers(searchParams?: IUserSearchParams) {
  const isFetched = (yield select(usersPageSelectors.selectIsFetched)) as boolean;

  if (!isFetched) {
    const users = (yield call(userResourceService.searchUsers, searchParams)) as IUser[];
    yield put(users ? actions.storeFetchedUsers(users.map((user) => user.id)) : actions.clearFetchedUsers());
    yield put(users ? actions.storeIsFetched() : actions.clearIsFetched());
  }
}
export function* signUpUser(user: IUserSignUp) {
  const userId = (yield call(userResourceService.signUpUser, user)) as IUser['id'];

  if (!userId) {
    return;
  }
  yield call(dialogService.closeDialog, 'signUpFormDialog');
}

export function* createUser(user: IUserCreateForm) {
  const groupIds = user.groups.length > 0 ? user.groups.map((tuple) => tuple.value) : [];

  const userId = (yield call(userResourceService.createUser, {
    ...user,
    teams: user.teams.length > 0 ? user.teams.map((tuple) => tuple.value) : [],
    accounts: user.accounts.length > 0 ? user.accounts.map((tuple) => tuple.value) : [],
    groupIds,
    timezone: user.timezone.value !== '' ? user.timezone.value : moment.tz.guess(),
  })) as IUser['id'];

  if (!userId) {
    return;
  }

  const userGroups = (yield select(userGroupSelectors.selectResourceList)) as IUserGroup[];
  yield all(
    userGroups
      .filter((userGroup) => groupIds.includes(userGroup.id))
      .map((userGroup) => put(storeUserGroup({ ...userGroup, userIds: [...userGroup.userIds, userId] }))),
  );

  yield call(dialogService.closeDialog, 'userFormDialog');

  // const users = (yield select(usersPageSelectors.selectUserIds)) as IUser['id'][];
  // yield put(actions.storeFetchedUsers([...users, userId]));
}

export function* updateUser(id: IUser['id'], user: IUserCreateForm) {
  const image = (yield select(userFormSelectors.selectUserAvatar)) as string;
  const groupIds = user.groups.length > 0 ? user.groups.map((tuple) => tuple.value) : [];
  const userId = (yield call(userResourceService.updateUser, {
    id,
    ...user,
    image: image,
    teams: user.teams.length > 0 ? user.teams.map((tuple) => tuple.value) : [],
    accounts: user.accounts.length > 0 ? user.accounts.map((tuple) => tuple.value) : [],
    groupIds,
    timezone: user.timezone.value,
  })) as IUser['id'];

  if (!userId) {
    return;
  }

  const userGroups = (yield select(userGroupSelectors.selectResourceList)) as IUserGroup[];
  yield all(
    userGroups
      .filter((userGroup) => groupIds.includes(userGroup.id))
      .map((userGroup) => put(storeUserGroup({ ...userGroup, userIds: [...userGroup.userIds, userId] }))),
  );

  yield call(dialogService.closeDialog, 'userFormDialog');
  // const users = (yield select(usersPageSelectors.selectUserIds)) as IUser['id'][];
  // yield put(actions.storeFetchedUsers([...users]));
}

export function* deleteUser(userId: IUser['id']) {
  yield call(userResourceService.deleteUser, userId);

  const usersIds: IUser['id'][] = yield select(usersPageSelectors.selectUserIds);

  yield put(usersIds ? actions.storeFetchedUsers(usersIds.filter((id) => userId !== id)) : actions.clearUser());
  yield call(dialogService.closeDialog, 'userFormDialog');
}

export function* handleUserFromNotification(user: IUser) {
  const userExists = (yield selectWithParams(userSelectors.selectResourceById, user.id)) as IUser | null;

  yield call(userResourceService.storeUser, {
    ...user,
    taskIds: userExists ? userExists.taskIds : null,
    sessionIds: userExists ? userExists.sessionIds : null,
  });

  const userIds = (yield select(usersPageSelectors.selectUserIds)) as IUser['id'][];
  yield put(actions.storeFetchedUsers(userIds.includes(user.id) ? [...userIds] : [...userIds, user.id]));
}

export function* clearUsersPageStorage() {
  yield put(actions.clearFetchedUsers());
  yield put(actions.clearIsFetched());
}
