/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { INextSessionProps } from './types';
import { sessionSelectors } from '../../../../../app/resource/session/session.selectors';
import { useHistory } from 'react-router-dom';
import { meetingSelectors } from '../../../../../app/resource/meeting/meeting.selectors';
import moment from 'moment';
import { agendaItemSelectors } from '../../../../../app/resource/agenda-item/agenda-item.selectors';

export const NextSession: FunctionComponent<INextSessionProps> = ({ sessionId, meetingId }) => {
  const session = useSelectWithParams(sessionSelectors.selectResourceById, sessionId ? sessionId : '');
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId ? meetingId : '');
  const teamColor = useMemo(() => meeting?.color, [meeting]);
  const history = useHistory();

  const goToLiveMeeting = useCallback(() => {
    history.push(`/live-meeting/${meetingId}/${sessionId}/0`);
  }, [history, meetingId, sessionId]);

  const agendaItems = useSelectWithParams(
    agendaItemSelectors.selectResourcesById,
    meeting ? meeting.agendaItemIds : [],
  );

  const filteredAgendaItems = agendaItems.filter((ai) => ai?.checked === '1');

  return (
    <div className="col-6">
      <div className="panel-body pt-0 pr-0">
        <div className="border-radius-1 lightGray-bg p-2" style={{ minHeight: '330px' }}>
          <div className="panel py-1 px-0 mb-1 no-border flex flex-column aic jcc" style={{ height: '234px' }}>
            <div className="big-avatar-80 flex aic jcc f-3 my-1 mx-2 white bold" style={{ backgroundColor: teamColor }}>
              60&apos;
            </div>
            <div className="aic jcc flex flex-column">
              <h2 className="bold mt-2">Next Meeting</h2>
              <p> {session ? moment(session.sessionDate).format('MM/DD/YYYY dddd, hh:mm A') : '/'}</p>
            </div>
          </div>

          {sessionId && meeting && filteredAgendaItems.length > 0 && (
            <a className="btn btn-lg mt-2 flex aic jcc" style={{ cursor: 'pointer' }} onClick={goToLiveMeeting}>
              <i className="icon2-play f-1 mr-1"></i> Start Meeting
            </a>
          )}
          {sessionId && meeting && filteredAgendaItems.length === 0 && (
            <a
              className="btn btn-lg mt-2 flex aic jcc"
              style={{ backgroundColor: 'gray', pointerEvents: 'none' }}
              onClick={goToLiveMeeting}
            >
              <i className="icon2-play f-1 mr-1"></i> Start Meeting
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
