import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initialMeetingPage } from '../../../app/page/meetings/meetings.actions';
import { pageStateSelectors } from '../../../app/page/page-state/page-state.selectors';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { PageLayout } from '../common/PageLayout';
import { ControlWrapper } from './ControlWrapper';
import { MeetingTable } from './MeetingTable';
import { IMeetingPageProps } from './types';

export const MeetingPage: FunctionComponent<IMeetingPageProps> = ({ type }) => {
  const showLoader = useSelectWithParams(pageStateSelectors.selectPageInProgress, 'meetings');

  const [sortType, setsortType] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialMeetingPage());
  }, [dispatch]);

  return (
    <PageLayout showLoader={showLoader}>
      <div className="row mb-25">
        <ControlWrapper type={type} sortType={sortType} setSortType={setsortType}>
          <MeetingTable type={type} sortType={sortType} />
        </ControlWrapper>
      </div>
    </PageLayout>
  );
};
