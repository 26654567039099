/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { initalLiveMeetingPage } from '../../../app/page/live-meeting/live-meeting.actions';
import { pageStateSelectors } from '../../../app/page/page-state/page-state.selectors';
import { agendaItemsPageSelectors } from '../../../app/page/page-storage/agenda-items/agenda-items.selectors';
import { tasksPageSelectors } from '../../../app/page/page-storage/tasks/tasks.selectors';
import { IReducedTasks } from '../../../app/page/page-storage/tasks/tasks.types';
import { agendaItemSelectors } from '../../../app/resource/agenda-item/agenda-item.selectors';
import {
  CONCLUSION,
  HEADLINE,
  ISSUE,
  KPI,
  MEETING_ROCK,
  TODO,
} from '../../../app/resource/agenda-item/agenda-item.types';
import { IMeeting } from '../../../app/resource/meeting/meeting.types';
import { useSelect, useSelectWithParams } from '../../../utils/selector.utils';
import { TaskFormTrigger } from '../../dialogs/TaskFormDialog/TaskFormTrigger';
import { PageLayout } from '../common/PageLayout';
import { WidgetTasksLineView } from '../common/widgets/widget-grouped/WidgetTasksLineView';
import { WidgetIds } from '../common/widgets/WidgetIds';
import { WidgetKpi } from '../common/widgets/widgets-kpi/WidgetKpi';
import { WidgetUnimplemented } from '../common/widgets/WidgetUnimplemented';
import { WidgetWrapper } from '../common/widgets/WidgetWrapper';
import { LiveMeetingNavbar } from './LiveMeetingNavbar';
import { WidgetConclusion } from '../common/widgets/WidgetConclusion';
import { ISession } from '../../../app/resource/session/session.types';
import { finishMeetingSession } from '../../../app/page/page-storage/meetings/meetings.actions';
import { ScoreFormTrigger } from '../../dialogs/ScoreFormDialog/ScoreFormTrigger';
import { moveToNextAgenda } from '../../../app/page/page-storage/agenda-items/agenda-items.actions';
import { BoardsPage } from '../BoardsPage';
import { generateBoardId } from '../../../app/page/page-storage/tasks/tasks.saga-services';
import { WidgetTasksStandardView } from '../common/widgets/widget-generated-boards/WidgetTasksStandardView';
import { meetingSelectors } from '../../../app/resource/meeting/meeting.selectors';
import slugify from 'react-slugify';
import { openDialog } from '../../../app/dialog/dialog.actions';

export const LiveMeetingPage = () => {
  const showLoader = useSelectWithParams(pageStateSelectors.selectPageInProgress, 'liveMeeting');
  const history = useHistory();

  const dispatch = useDispatch();
  const { meetingId, sessionId, current } = useParams<{
    meetingId: IMeeting['id'];
    sessionId: ISession['id'];
    current: string;
  }>();

  const agendaIndexFromPusher = useSelect(agendaItemsPageSelectors.selectCurrentAgenda);
  const agendaItemIds = useSelect(agendaItemsPageSelectors.selectAgendaItems);

  const agendaItemsSelected = useSelectWithParams(agendaItemSelectors.selectResourcesById, agendaItemIds);
  const agendaItems = useMemo(() => agendaItemsSelected.filter((agendaItem) => agendaItem?.checked === '1'), [
    agendaItemsSelected,
  ]);

  // const displayAiButton = useMemo(() => {
  //   let temp = false;
  //   agendaItems.map((ai) => {
  //     if (ai?.functionName === 'to_do_list') {
  //       temp = true;
  //     }
  //   });
  //   return temp;
  // }, [agendaItems]);

  const displayAiButton = useMemo(() => agendaItems.filter((agendaItem) => agendaItem?.functionName === 'to_do_list'), [
    agendaItems,
  ]);

  const currentAgendaItem = useMemo(() => agendaItems[+current], [current, agendaItems]);

  const [iHaveSeen, setIHaveSeen] = useState<string[]>([]);

  useEffect(() => {
    setIHaveSeen((old) => [...old, current]);
  }, [current]);
  useEffect(() => {
    if (
      !agendaIndexFromPusher ||
      agendaIndexFromPusher.meetingId !== meetingId ||
      agendaIndexFromPusher.sessionId !== sessionId ||
      iHaveSeen.includes(agendaIndexFromPusher.index)
    ) {
      return;
    }
    const [, path, mId, sesId] = history.location.pathname.split('/');
    history.push(`/${path}/${mId}/${sesId}/${agendaIndexFromPusher.index}`);
  }, [agendaIndexFromPusher, current, history, iHaveSeen, meetingId, sessionId]);

  const currentTaskTypeId = useMemo(() => {
    if (agendaItems[+current]?.agendaId === '5') {
      return '4';
    }
    if (agendaItems[+current]?.agendaId === '2') {
      return '1';
    }
    return '1';
  }, [agendaItems, current]);
  const nextAgendaItem = useMemo(() => agendaItems[+current + 1], [current, agendaItems]);
  const reducedTasks = useSelect(tasksPageSelectors.selectReducedTasks) as IReducedTasks;

  // const tasksPerUser = useMemo(
  //   () =>
  //     currentAgendaItem && currentAgendaItem.agendaId && reducedTasks
  //       ? reducedTasks[currentTaskTypeId]
  //         ? reducedTasks[currentTaskTypeId]
  //         : {}
  //       : {},
  //   [currentAgendaItem, currentTaskTypeId, reducedTasks],
  // );

  useEffect(() => {
    dispatch(initalLiveMeetingPage({ meetingId, sessionId, index: 'remove' }));
  }, [dispatch, meetingId, sessionId]);

  const title = useSelectWithParams(meetingSelectors.selectResourceById, meetingId)?.title;
  const saveAndEndMeeting = useCallback(() => {
    dispatch(finishMeetingSession(meetingId, sessionId));
    history.push(`/meeting-dashboard/${meetingId}/${slugify(title, { delimiter: '-' })}`);
  }, [dispatch, history, meetingId, sessionId, title]);

  const setNextAgendaItem = useCallback(() => {
    dispatch(moveToNextAgenda({ meetingId, sessionId, index: `${+current + 1}` }));
    const [, path, mId, sesId] = history.location.pathname.split('/');
    history.push(`/${path}/${mId}/${sesId}/${+current + 1}`);
  }, [current, dispatch, history, meetingId, sessionId]);

  const currentComponent = useMemo(() => {
    if (!currentAgendaItem) {
      return undefined;
    }

    if (MEETING_ROCK === currentAgendaItem.agendaId) {
      return 'tasksPerUsers';
    } else if (TODO === currentAgendaItem.agendaId) {
      return 'boards';
    } else if (KPI === currentAgendaItem.agendaId) {
      return 'kpi';
    } else if (HEADLINE === currentAgendaItem.agendaId) {
      return 'headlines';
    } else if (ISSUE === currentAgendaItem.agendaId) {
      return 'ids';
    } else if (CONCLUSION === currentAgendaItem.agendaId) {
      return 'conclusion';
    } else {
      return 'unimplemented';
    }
  }, [currentAgendaItem]);

  const openActionItemDialog = useCallback(() => {
    dispatch(openDialog('actionItemDialog', { meetingId }));
  }, [dispatch, meetingId]);

  return (
    <PageLayout
      showLoader={showLoader}
      smallSidebar
      externalLogicNavbar={
        currentAgendaItem ? (
          <LiveMeetingNavbar showLoader={showLoader} agendaItem={currentAgendaItem} meetingId={meetingId} />
        ) : undefined
      }
    >
      {!showLoader && (
        <>
          {displayAiButton?.length !== 0 && (
            <span className="plus_button popupp" onClick={openActionItemDialog}>
              <i className="icon-plus"></i>
            </span>
          )}
          {currentAgendaItem && currentComponent === 'tasksPerUsers' && (
            <WidgetTasksStandardView
              complexBoardId={generateBoardId(meetingId, 'groupMeetingRocksByUser')}
              actionElement={<TaskFormTrigger type={currentTaskTypeId} meetingId={meetingId} />}
            />
          )}
          {currentAgendaItem && currentComponent === 'ids' && (
            <WidgetIds meetingId={meetingId} reducedTasks={reducedTasks} />
          )}

          {currentAgendaItem && currentComponent === 'headlines' && (
            <WidgetTasksLineView title="Headlines" type="3" meetingId={meetingId} />
          )}
          {currentAgendaItem && currentComponent === 'boards' && (
            <>
              <WidgetTasksStandardView complexBoardId={generateBoardId(meetingId, 'groupNonDefaultByUser')} />
              <BoardsPage meetingId={meetingId} />
            </>
          )}

          {currentAgendaItem && currentComponent === 'kpi' && (
            <WidgetWrapper
              name="KPI's"
              actionElement={
                <div className="ml-auto flex aic jcr">
                  <ScoreFormTrigger meetingId={meetingId} />
                  <a className="btn btn-md btn-border white-bg" title="Scorecard Settings">
                    <i className="icon-settings mr-1 f-0 blueGray"></i> Scorecard Settings
                  </a>
                  <a className="btn btn-md btn-border white-bg" title="Previous Quarter">
                    <i className="icon-left-arrow icon-rotated mr-1 f-0 blueGray"></i>
                    Previous Quarter
                  </a>
                  <a
                    className="btn btn-md btn-border white-bg popup_button"
                    data-popup="reorder_users_popup"
                    title="Reorder Users"
                  >
                    <i className="icon-users mr-1 f-0 blueGray"></i>Reorder Users
                  </a>
                  <div className="toggle-buttons flex aic">
                    <a className="btn white-bg btn-md btn-border blueGray m-0 selected" title="">
                      Show
                    </a>
                    <a className="btn white-bg btn-md btn-border blueGray m-0" title="">
                      Hide
                    </a>
                  </div>
                </div>
              }
            >
              <WidgetKpi />
            </WidgetWrapper>
          )}

          {currentAgendaItem && currentComponent === 'conclusion' && (
            <WidgetConclusion meetingId={meetingId} reducedTasks={reducedTasks} sessionId={sessionId} />
          )}

          {currentAgendaItem && currentComponent === 'unimplemented' && (
            <WidgetUnimplemented agendaItem={currentAgendaItem} meetingId={meetingId} />
          )}

          <div className="row mb-25">
            <div className="row mb-2" style={{ marginTop: '20px' }}>
              <div className="col-12">
                <div className="col-12">
                  <div className="button-holder">
                    {nextAgendaItem && (
                      <button
                        className="btn green-bg white m-0 w-auto"
                        title={`Next Agenda Item: ${nextAgendaItem.title}`}
                        onClick={setNextAgendaItem}
                      >
                        <i className="icon icon-left-arrow icon-rotated mr-1 f-1"></i>
                        {`Next Agenda Item: ${nextAgendaItem.title}`}
                      </button>
                    )}
                    {!nextAgendaItem && (
                      <button
                        className="btn green-bg white m-0 w-auto"
                        title="Save and Finish Meeting"
                        onClick={saveAndEndMeeting}
                      >
                        <i className="icon icon-left-arrow mr-1 f-1"></i>
                        {`Save and Finish Meeting`}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </PageLayout>
  );
};
