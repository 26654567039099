import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './meetings.constants';
import * as types from './meetings.types';
import * as services from './meetings.saga-services';

function* invokeInitialMeetingPage(action: types.IInitialMeetingPage) {
  yield call(services.initialMeetingPage);
}

export function* watchMeetingPageSaga() {
  yield takeLatest(constants.INITIAL_MEETING_PAGE, invokeInitialMeetingPage);
}
