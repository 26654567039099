import { IPassword, IPasswordSave, IPasswordSearchParams } from '../../resource/password/password.types';
import { IApiRequestConfig } from '../api.types';

export const searchPasswordsApi = (params?: IPasswordSearchParams): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'searchPasswords',
  uri: 'json',
  action: 'getPasswords',
  method: 'get',
  params: {
    ...params,
  },
});

export const savePasswordApi = (password: IPasswordSave): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'savePassword',
  uri: 'json',
  action: 'savePassword',
  method: 'post',
  data: password,
});

export const deletePasswordApi = (passwordId: IPassword['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deletePassword',
  uri: 'json',
  action: 'deletePassword',
  method: 'delete',
  params: {
    id: passwordId,
  },
});
