import { combineReducers } from 'redux';
import * as types from './resource.types';

import { userReducer } from './user/user.reducer';
import { meetingReducer } from './meeting/meeting.reducer';
import { taskReducer } from './task/task.reducer';
import { agendaItemReducer } from './agenda-item/agenda-item.reducer';
import { sessionReducer } from './session/session.reducer';
import { scoreReducer } from './score/score.reducer';
import { kpiRowScoreboardReducer } from './kpi-row-scoreboard/kpi-row-scoreboard.reducer';
import { kpiRowReducer } from './kpi-row/kpi-row.reducer';
import { kpiReducer } from './kpi/kpi.reducer';
import { conclusionReducer } from './conclusion/conclusion.reducer';
import { notificationReducer } from './notification/notification.reducer';
import { sectionReducer } from './section/section.reducer';
import { templateReducer } from './template/template.reducer';
import { calendarReducer } from './calendar/calendar.reducer';
import { eventReducer } from './event/event.reducer';
import { calendarItemReducer } from './calendar-item/calendar-item.reducer';
import { nodeReducer } from './node/node.reducer';
import { boardReducer } from './boards/board/board.reducer';
import { boardListReducer } from './boards/boardList/boardList.reducer';
import { labelReducer } from './label/label.reducer';
import { checkListReducer } from './checklist/checkList.reducer';
import { checklistItemReducer } from './checklistItem/checklistItem.reducer';
import { activityReducer } from './activity/activity.reducer';
import { userGroupReducer } from './user-group/user-group.reducer';
import { passwordReducer } from './password/password.reducer';
import { passwordGroupReducer } from './password-group/password-group.reducer';
import { projectTaskReducer } from './project-task/project-task.reducer';
import { projectTeamReducer } from './project-member/project-member.reducer';
import { projectReducer } from './project/project.reducer';
import { projectReportReducer } from './project-report/project-report.reducer';
import { accountProjectReducer } from './account-project/account-project.reducer';
import { projectTimeReducer } from './project-time/project-time.reducer';
import { agendaReducer } from './agenda/agenda.reducer';
import { quartalReducer } from './quartal/quartal.reducer';
import { categoryReducer } from './category/category.reducer';
import { itemReducer } from './item/item.reducer';
import { columnReducer } from './column/column.reducer';
import { questionReducer } from './question/question.reducer';
import { answerReducer } from './answer/answer.reducer';
import { meetingInfoReducer } from './meeting-info/meeting-info.reducer';

export const resourceReducer = combineReducers<types.IResourcesState>({
  user: userReducer,
  meeting: meetingReducer,
  session: sessionReducer,
  score: scoreReducer,
  task: taskReducer,
  agendaItem: agendaItemReducer,
  kpi: kpiReducer,
  kpiRow: kpiRowReducer,
  kpiRowScoreboard: kpiRowScoreboardReducer,
  conclusion: conclusionReducer,
  notification: notificationReducer,
  section: sectionReducer,
  template: templateReducer,
  calendar: calendarReducer,
  calendarItem: calendarItemReducer,
  event: eventReducer,
  node: nodeReducer,
  board: boardReducer,
  boardList: boardListReducer,
  label: labelReducer,
  checkList: checkListReducer,
  checklistItem: checklistItemReducer,
  activity: activityReducer,
  userGroup: userGroupReducer,
  password: passwordReducer,
  passwordGroup: passwordGroupReducer,
  projectTask: projectTaskReducer,
  projectTeam: projectTeamReducer,
  project: projectReducer,
  projectReport: projectReportReducer,
  accountProject: accountProjectReducer,
  projectTime: projectTimeReducer,
  agenda: agendaReducer,
  quartal: quartalReducer,
  category: categoryReducer,
  item: itemReducer,
  column: columnReducer,
  question: questionReducer,
  answer: answerReducer,
  meetingInfo: meetingInfoReducer,
});
