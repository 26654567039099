import { put, select, call } from 'redux-saga/effects';
import * as types from './checklistItem.types';
import * as actions from './checklistItem.actions';
import { apiRequest } from '../../api/api.saga-services';
import * as api from '../../api/request-config/checklistItem.api';
import { checklistItemSelectors } from './checklistItem.selectors';
import { checkListSelectors } from '../checklist/checkList.selectors';
import { ICheckList } from '../checklist/checkList.types';
import { storeCheckList } from '../checklist/checkList.saga-service';

export function* storeChecklistItem(checklistItem: types.IChecklistItem) {
  yield put(actions.storeChecklistItem(checklistItem));

  return checklistItem;
}

export function* clearChecklistItem(checklistItemId: types.IChecklistItem['id']) {
  yield put(actions.clearChecklistItem(checklistItemId));
}

export function* clearAllChecklistItems() {
  const checklistItemsIds: types.IChecklistItem['id'][] = yield select(checklistItemSelectors.selectResourceIds);
  yield put(actions.clearChecklistItem(checklistItemsIds));
}

export function* createChecklistItem(data: types.IChecklistItemCreate) {
  const checklistItem = (yield call(apiRequest, api.createChecklistItemApi(data))) as types.IChecklistItem;

  if (!checklistItem) {
    return;
  }

  yield call(storeChecklistItem, checklistItem);
  const checkList: ICheckList = yield select(checkListSelectors.selectResourceById, checklistItem.checklistId);

  yield call(storeCheckList, { ...checkList, checklistItemsIds: [...checkList.checklistItemsIds, checklistItem.id] });
  return checklistItem;
}

export function* updateChecklistItem(data: types.IChecklistItemUpdate) {
  const checklistItem = (yield call(apiRequest, api.updateChecklistItemApi(data))) as types.IChecklistItem;
  if (!checklistItem) {
    return;
  }
  checklistItem.id = data.id;
  yield call(storeChecklistItem, checklistItem);
  return checklistItem;
}

export function* deleteChecklistItem(checklistItemId: types.IChecklistItem['id']) {
  const checklistItem: types.IChecklistItem = yield select(checklistItemSelectors.selectResourceById, checklistItemId);
  const checkList: ICheckList = yield select(checkListSelectors.selectResourceById, checklistItem.checklistId);
  yield call(apiRequest, api.deleteChecklistItemApi(checklistItemId));
  yield call(storeCheckList, {
    ...checkList,
    checklistItemsIds: [...checkList.checklistItemsIds].filter((id) => id !== checklistItemId),
  });
}
