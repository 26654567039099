import React, { FunctionComponent, useMemo } from 'react';
import { CURRENT_DATE } from '../../../../../../../app/resource/calendar/calendar.types';
import { getDaysOfMonth } from '../../../../../../../utils/date.utils';
import { CalendarItemDay } from './CalendarItemDay';
import { IMonthViewProps } from './types';

export const MonthView: FunctionComponent<IMonthViewProps> = ({ date, meetingId, userFilter }) => {
  const daysOfMonth = useMemo(() => {
    return getDaysOfMonth(date);
  }, [date]);

  return (
    <>
      <div className="calendar-rendered">
        <div className="calendar-days">
          <div className="calendar-cell">
            <div className="calendar-cell-day">SUN</div>
          </div>
          <div className="calendar-cell">
            <div className="calendar-cell-day">MON</div>
          </div>
          <div className="calendar-cell">
            <div className="calendar-cell-day">TUE</div>
          </div>
          <div className="calendar-cell">
            <div className="calendar-cell-day">WED</div>
          </div>
          <div className="calendar-cell">
            <div className="calendar-cell-day">THU</div>
          </div>
          <div className="calendar-cell">
            <div className="calendar-cell-day">FRI</div>
          </div>
          <div className="calendar-cell">
            <div className="calendar-cell-day">SAT</div>
          </div>
        </div>
        {daysOfMonth.map((date, index) => (
          <div key={`date-${date}`} className="calendar-cell">
            <span className={`calendar-cell-day ${CURRENT_DATE === date ? 'today' : ''}`}>{index + 1}</span>
            <CalendarItemDay meetingId={meetingId} date={date} userFilter={userFilter} />
          </div>
        ))}
      </div>
    </>
  );
};
