/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../app/dialog/dialog.actions';
import { meetingsPageSelectors } from '../../../app/page/page-storage/meetings/meetings.selectors';
import { initialHomePage } from '../../../app/page/home/home.actions';
import { pageStateSelectors } from '../../../app/page/page-state/page-state.selectors';
import { useSelect, useSelectWithParams } from '../../../utils/selector.utils';
import { TaskFormTrigger } from '../../dialogs/TaskFormDialog/TaskFormTrigger';
import { PageLayout } from '../common/PageLayout';
import { WidgetList } from '../common/widget-lists/WidgetList';
import { WidgetWrapperSingleColumn } from '../common/widgets/WidgetWrapperSingleColumn';
import { UserBar } from './UserBar';
import { authSelectors } from '../../../app/auth/auth.selectors';
import { TaskDragDropContext } from '../common/drag-drop-contexts/TaskDragDropContext';
import { createMeeting } from '../../../app/page/page-storage/meetings/meetings.actions';
import { IMeetingCreateForm, IMeetingNewCreateForm } from '../../../app/resource/meeting/meeting.types';
import { CalendarWidgetList } from '../common/widget-lists/CalendarWidgetList/intedx';
import { calendarsPageSelectors } from '../../../app/page/page-storage/calendars/calendars.selectors';
import { generateBoardListId } from '../../../app/page/page-storage/tasks/tasks.saga-services';
import { TasksStandardList } from '../common/widgets/widget-generated-boards/WidgetTasksStandardView/TasksStandardList';
import { widgetSelectors } from '../../../app/widgets/widgets.selectors';
import { initialQuartalsPage } from '../../../app/page/quartals/quartals.actions';
import { widgetsPageSelectors } from '../../../app/page/page-storage/widgets/widgets.selectors';

export const HomePage: FunctionComponent = () => {
  const showLoader = useSelectWithParams(pageStateSelectors.selectPageInProgress, 'home');
  const showCalendar = useSelectWithParams(widgetSelectors.selectWidgetVisibility, 'homeCalendar');
  const showAccounts = useSelectWithParams(widgetSelectors.selectWidgetVisibility, 'homeAccounts');
  const showTodos = useSelectWithParams(widgetSelectors.selectWidgetVisibility, 'homeTodos');
  const showQis = useSelectWithParams(widgetSelectors.selectWidgetVisibility, 'homeQis');

  const dispatch = useDispatch();
  const widgets = useSelect(widgetsPageSelectors.selectPageWidgets);

  const currentUserId = useSelect(authSelectors.selectLoggedUser);

  const boardTasksForUserId = useMemo(
    () => generateBoardListId('', 'groupNonDefaultByMember', { userId: currentUserId ? currentUserId : '' }, 'active'),
    [currentUserId],
  );
  console.log(boardTasksForUserId);
  const qiForUserId = useMemo(
    () =>
      generateBoardListId(
        '',
        'groupMeetingRocksByUser',
        { userId: currentUserId ? currentUserId : '', taskTypeId: '1' },
        'active',
      ),
    [currentUserId],
  );

  const reducedMeetings = useSelect(meetingsPageSelectors.selectReducedMeetings);
  const calendarItemIds = useSelect(calendarsPageSelectors.selectCalendarItems);
  const openMeetingForm = useCallback(() => {
    dispatch(
      openDialog('meetingFormDialog', {
        type: 'account',
        onSubmit: (meetingInfo: IMeetingCreateForm) => {
          // hot fix
          dispatch(createMeeting({ ...meetingInfo, type: 'account' } as IMeetingNewCreateForm));
          //dispatch(createMeeting({ ...meetingInfo, type: 'account' }));
        },
      }),
    );
  }, [dispatch]);

  const openNewWindow = useCallback(
    () =>
      window.open(
        `https://phpstack-696462-2300737.cloudwaysapps.com/googleapi/make_token?user_id=${currentUserId}`,
        'name',
        'width=600,height=400',
      ),
    [currentUserId],
  );

  useEffect(() => {
    dispatch(initialHomePage());
    dispatch(initialQuartalsPage());
  }, [dispatch]);

  return (
    <PageLayout showLoader={showLoader} aditionalNavbar={<UserBar />} showHide={true}>
      {!showLoader && (
        <div className="row mb-25 home-page-class">
          {widgets.map((widget) => {
            if (widget.key === 'homeCalendar' && showCalendar) {
              return (
                <WidgetWrapperSingleColumn
                  name="Calendar"
                  actionElement={
                    <a
                      className="btn-badge green-bg popup_button"
                      data-popup="new_todo_popup"
                      title="add event"
                      onClick={openNewWindow}
                    >
                      <i className="icon-plus"></i>
                    </a>
                  }
                >
                  <CalendarWidgetList calendarItemId={calendarItemIds[0]} />
                </WidgetWrapperSingleColumn>
              );
            } else if (widget.key === 'homeAccounts' && reducedMeetings && reducedMeetings['account'] && showAccounts) {
              return (
                <DragDropContext
                  onDragEnd={(result) => {
                    //console.log(result);
                  }}
                >
                  <WidgetWrapperSingleColumn
                    name="Accounts"
                    filterElement={
                      <label htmlFor="" className="f-1 flex aic mb-2">
                        show/hide:
                        <a className="tab-button" data-target="company-todo">
                          Company To Do’s
                        </a>
                        <a className="selected tab-button" data-target="account-todo">
                          Account To Do’s
                        </a>
                      </label>
                    }
                    actionElement={
                      <a
                        className="btn-badge green-bg popup_button"
                        data-popup="new_todo_popup"
                        title="add event"
                        onClick={openMeetingForm}
                      >
                        <i className="icon-plus"></i>
                      </a>
                    }
                  >
                    <WidgetList droppableId="meeting" type="meeting" widgetItemIds={reducedMeetings['account']} />
                  </WidgetWrapperSingleColumn>
                </DragDropContext>
              );
            } else if (widget.key === 'homeTodos' && showTodos) {
              return (
                <TaskDragDropContext>
                  <WidgetWrapperSingleColumn
                    name="My Tasks"
                    filterElement={
                      <label htmlFor="" className="f-1 flex aic mb-2">
                        show/hide:
                        <a className="tab-button" data-target="company-todo">
                          Company To Do’s
                        </a>
                        <a className="selected tab-button" data-target="account-todo">
                          Account To Do’s
                        </a>
                      </label>
                    }
                  >
                    <TasksStandardList isSingle complexBoardListId={boardTasksForUserId} />
                  </WidgetWrapperSingleColumn>
                </TaskDragDropContext>
              );
            } else if (widget.key === 'homeQis' && showQis) {
              return (
                <TaskDragDropContext>
                  <WidgetWrapperSingleColumn
                    name="Quarterly Initiatives"
                    actionElement={<TaskFormTrigger type="1" />}
                    filterElement={
                      <label htmlFor="" className="f-1 flex aic mb-2">
                        show/hide:
                        <a className="tab-button" data-target="company-todo">
                          Company QI`s
                        </a>
                        <a className="selected tab-button" data-target="account-todo">
                          Account QI`s
                        </a>
                      </label>
                    }
                  >
                    <TasksStandardList isSingle complexBoardListId={qiForUserId} />
                  </WidgetWrapperSingleColumn>
                </TaskDragDropContext>
              );
            } else {
              return null;
            }
          })}
        </div>
      )}
    </PageLayout>
  );
};
