import { createActionConst } from '../../../../utils/action.utils';

const USERS_NAMESPACE = 'USERS';

export const FETCH_USERS = createActionConst(USERS_NAMESPACE, 'FETCH_USERS');
export const CREATE_USER = createActionConst(USERS_NAMESPACE, 'CREATE_USER');
export const UPDATE_USER = createActionConst(USERS_NAMESPACE, 'UPDATE_USER');
export const DELETE_USER = createActionConst(USERS_NAMESPACE, 'DELETE_USER');

export const SIGNUP_USER = createActionConst(USERS_NAMESPACE, 'SIGNUP_USER');

export const STORE_FETCHED_USERS = createActionConst(USERS_NAMESPACE, 'STORE_FETCHED_USERS');
export const CLEAR_FETCHED_USERS = createActionConst(USERS_NAMESPACE, 'CLEAR_FETCHED_USERS');

export const STORE_USER = createActionConst(USERS_NAMESPACE, 'STORE_USER');
export const CLEAR_USER = createActionConst(USERS_NAMESPACE, 'CLEAR_USER');

export const STORE_IS_FETCHED = createActionConst(USERS_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(USERS_NAMESPACE, 'CLEAR_IS_FETCHED');

export const SAVE_USER_SOCKET = 'saveUser';
export const DELETE_USER_SOCKET = 'deleteUser';
