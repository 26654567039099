import { all, call, put } from 'redux-saga/effects';

import * as types from './agenda-item.types';
import * as actions from './agenda-item.actions';
import * as api from '../../api/request-config/agenda-item.api';

import { apiRequest } from '../../api/api.saga-services';
import { agendaItemSelectors } from './agenda-item.selectors';
import { select } from '../../../utils/selector.utils';
import { ICurrentAgendaIndex } from '../../page/page-storage/agenda-items/agenda-items.types';

export function* storeAgendaItem(agendaItem: types.IAgenda) {
  yield put(actions.storeAgendaItem(agendaItem));
}

export function* clearAgendaItem(agendaItemId: types.IAgendaItem['id']) {
  yield put(actions.clearAgendaItem(agendaItemId));
}

export function* setupDefaultAgendaItems(searchParams?: types.IAgendaItemSearchParams) {
  const agendaItems = (yield call(apiRequest, api.searchAgendaItemApi(searchParams))) as types.IAgendaItem[];

  yield all(agendaItems.map((agendaItem) => call(storeAgendaItem, agendaItem)));
  return agendaItems;
}

export function* clearAllAgendaItems() {
  const agendaIds: types.IAgendaItem['id'][] = yield select(agendaItemSelectors.selectResourceIds);
  yield put(actions.clearAgendaItem(agendaIds));
}

/* This method sends api request and handle response */
export function* searchAgendaItems(searchParams?: types.IAgendaItemSearchParams) {
  const agendaItems = (yield call(apiRequest, api.searchAgendaItemApi(searchParams))) as types.IAgendaItem[];

  yield all(agendaItems.map((agendaItem) => call(storeAgendaItem, agendaItem)));
  return agendaItems;
}

export function* moveToNextAgenda(currentAgendaIndex: ICurrentAgendaIndex) {
  yield call(apiRequest, api.moveToNextAgenda(currentAgendaIndex));
}
