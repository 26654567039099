import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { meetingSelectors } from '../../../../../../../app/resource/meeting/meeting.selectors';
import { difference } from '../../../../../../../utils/date.utils';
import { useSelectWithParams } from '../../../../../../../utils/selector.utils';
import { IMeetingNameProps } from './types';

export const MeetingName: FunctionComponent<IMeetingNameProps> = ({ meetingId, date }) => {
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);
  //const meetingDate = `${meeting?.startDate} ${meeting?.startTime}`;

  return (
    <span>
      {' '}
      {meeting ? meeting.title : '/'}, in {difference(moment(), date)}
    </span>
  );
};
