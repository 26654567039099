import * as types from './kpi.types';
import * as constants from './kpi.constants';
import { IKpiRow } from '../../../resource/kpi-row/kpi-row.types';
import { ISession } from '../../../resource/session/session.types';
import { IMeeting } from '../../../resource/meeting/meeting.types';
import { IKpiRowScoreboard } from '../../../resource/kpi-row-scoreboard/kpi-row-scoreboard.types';
import { IScore, IScoreCreateForm } from '../../../resource/score/score.types';

export const storeKpiRowIds = (kpiRowIds: IKpiRow['id'][]): types.IStoreKpiRowIds => ({
  type: constants.STORE_KPI_ROW_IDS,
  payload: kpiRowIds,
});

export const clearKpiRowIds = (): types.IClearKpiRowIds => ({
  type: constants.CLEAR_KPI_ROW_IDS,
});

export const storeSessionIds = (sessionIds: ISession['id'][]): types.IStoreSessionIds => ({
  type: constants.STORE_SESSION_IDS,
  payload: sessionIds,
});

export const clearSessionIds = (): types.IClearSessionIds => ({
  type: constants.CLEAR_SESSION_IDS,
});

export const storeScoreIds = (scoreIds: IScore['id'][]): types.IStoreScoreIds => ({
  type: constants.STORE_SCORE_IDS,
  payload: scoreIds,
});

export const clearScoreIds = (): types.IClearScoreIds => ({
  type: constants.CLEAR_SCORE_IDS,
});

export const fetchKpi = (meetingId: IMeeting['id']): types.IFetchKpi => ({
  type: constants.FETCH_KPI,
  payload: meetingId,
});

export const saveScoreboard = (scoreboard: IKpiRowScoreboard): types.ISaveScoreboard => ({
  type: constants.SAVE_SCOREBOARD,
  payload: scoreboard,
});

export const createScore = (scoreInfo: IScoreCreateForm): types.ICreateScore => ({
  type: constants.CREATE_SCORE,
  payload: scoreInfo,
});

export const updateScore = (scoreId: IScore['id'], scoreInfo: IScoreCreateForm): types.IUpdateScore => ({
  type: constants.UPDATE_SCORE,
  payload: {
    data: scoreInfo,
    scoreId,
  },
});

export const saveScoreOrder = (scoreIds: IScore['id'][]): types.ISaveScoreOrder => ({
  type: constants.SAVE_SCORE_ORDER,
  payload: {
    scoreIds,
  },
});
