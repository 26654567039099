import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';

import { Router } from '../Router';

import { configureStore } from '../../app/store';

import '../resources/styles/theme.scss';
import 'react-datepicker/dist/react-datepicker.css';

export const App: FunctionComponent = () => {
  try {
    return (
      <Provider store={configureStore()}>
        <Router />
      </Provider>
    );
  } catch (e) {
    console.error(e);
    return (
      <div className="loader">
        <div className="error-container">
          <div className="message-title"> Something went wrong! </div>
          <div className="message-body">
            <div className="message"> Sorry, this app is still in development mode</div>
            <div className="message">
              {'Please, try again '}
              <a className="message" href="/home">
                here
              </a>
            </div>
            <div className="message">
              {'Report this bug on '}
              <a href="mailto: arsen@atak.com">arsen@atak.com</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
