import * as types from './quartals.types';
import * as constants from './quartals.constants';
import { IQuartalCreateForm, IQuartal } from '../../../resource/quartal/quartal.types';

export const createQuartal = (quartal: IQuartalCreateForm): types.ICreateQuartal => ({
  type: constants.CREATE_QUARTAL,
  payload: quartal,
});

export const updateQuartal = (quartalId: IQuartal['id'], quartal: IQuartalCreateForm): types.IUpdateQuartal => ({
  type: constants.UPDATE_QUARTAL,
  payload: {
    id: quartalId,
    data: quartal,
  },
});

export const deleteQuartal = (quartalId: IQuartal['id']): types.IDeleteQuartal => ({
  type: constants.DELETE_QUARTAL,
  payload: quartalId,
});

export const storeQuartals = (quartals: IQuartal['id'][]): types.IStoreQuartal => ({
  type: constants.STORE_QUARTAL,
  payload: quartals,
});

export const clearQuartal = (): types.IClearQuartal => ({
  type: constants.CLEAR_QUARTAL,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
