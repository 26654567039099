import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './kpi.constants';
import * as types from './kpi.types';
import * as services from './kpi.saga-services';

function* invokeInitialKpiPage(action: types.IInitialKpiPage) {
  yield call(services.initialKpiPage, action.payload);
}

export function* watchSingleKpiPageSaga() {
  yield takeLatest(constants.INITIAL_KPI_PAGE, invokeInitialKpiPage);
}
