/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { openDialog } from '../../../app/dialog/dialog.actions';
import { pageStateSelectors } from '../../../app/page/page-state/page-state.selectors';
import { savePassword } from '../../../app/page/page-storage/passwords/passwords.actions';
import { initialPasswordsPage } from '../../../app/page/passwords/passwords.actions';
import { IMeeting } from '../../../app/resource/meeting/meeting.types';
import { IPasswordSaveForm } from '../../../app/resource/password/password.types';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { MeetingDashboardNavbar } from '../common/navigation-bars/MeetigDashboardNavbar';
import { PageLayout } from '../common/PageLayout';
import { PasswordFolders } from './PasswordFolders';
import { PasswordTable } from './PasswordTable';

export const PasswordsPage: FunctionComponent = () => {
  const { meetingId } = useParams<{ meetingId: IMeeting['id'] }>();

  const dispatch = useDispatch();
  const showLoader = useSelectWithParams(pageStateSelectors.selectPageInProgress, 'passwords');

  useEffect(() => {
    dispatch(initialPasswordsPage({ meetingId }));
  }, [dispatch, meetingId]);

  const onOpenDialog = useCallback(() => {
    dispatch(
      openDialog('passwordFormDialog', {
        meetingId,
        onSubmit: (data: IPasswordSaveForm) => {
          dispatch(savePassword(data));
        },
      }),
    );
  }, [dispatch, meetingId]);

  return (
    <PageLayout
      externalLogicNavbar={<MeetingDashboardNavbar showLoader={showLoader} meetingId={meetingId} />}
      smallSidebar
    >
      {!showLoader && (
        <div className="row mb-25">
          <div className="col-12">
            <div className="panel">
              <div className="panel-header no-border">
                <h2 className="bold">Password Vault</h2>
                <div className="ml-auto flex aic jcr">
                  <div className="sorting mr-1">
                    Sort:
                    <a className="link darkGray toggle-link ml-1">A-Z</a>
                    <a className="link gray toggle-link ml-1">Z-A</a>
                  </div>
                  <a
                    className="btn btn-md green-bg popup_button"
                    data-popup="new_password_popup"
                    title="Add a Topic"
                    style={{ cursor: 'pointer' }}
                    onClick={onOpenDialog}
                  >
                    <i className="icon-plus mr-1" /> Add New
                  </a>
                </div>
              </div>
              <div className="panel-body pt-0 ho-hover">
                <div className="border-radius-1 lightGray-bg p-2">
                  <div className="row">
                    <PasswordFolders />
                    <PasswordTable />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </PageLayout>
  );
};
