/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import { IUserRecapsProps } from './types';
import { UserRecap } from './UserRecap';

export const UserRecaps: FunctionComponent<IUserRecapsProps> = ({ userIds, nextSessionId }) => {
  return (
    <div className="row mb-25 mt-3">
      <div className="col-12">
        <div className="panel vto-text">
          <div className="panel-header no-border">
            <h2 className="bold">
              Meeting Recap <i className="f-1 icon-info blueGray ml-1" data-tooltip="some tooltip text" />
            </h2>
            <div className="ml-auto flex aic">
              <a className="btn btn-md green-bg popup_button" title="Export" data-popup="new_topic_popup">
                <i className="icon-plus" /> Add a Topic
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="panel-body pt-0">
                <div className="border-radius-1 lightGray-bg px-2 py-1">
                  <div className="row review-issues-container">
                    {userIds.map((userId) => (
                      <UserRecap key={userId} userId={userId} nextSessionId={nextSessionId} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
