import {
  IChecklistItem,
  IChecklistItemCreate,
  IChecklistItemUpdate,
} from '../../resource/checklistItem/checklistItem.types';
import { IApiRequestConfig } from '../api.types';

export const createChecklistItemApi = (checklistItem: IChecklistItemCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createChecklistItem',
  uri: 'json',
  action: 'saveChecklistItem',
  method: 'post',
  data: checklistItem,
});

export const updateChecklistItemApi = (checklistItem: IChecklistItemUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateChecklistItem',
  uri: 'json',
  action: 'saveChecklistItem',
  method: 'put',
  data: checklistItem,
});

export const deleteChecklistItemApi = (checklistItemId: IChecklistItem['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteChecklistItem',
  uri: 'json',
  action: 'deleteChecklistItem',
  method: 'delete',
  params: {
    id: checklistItemId,
  },
});
