/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../app/dialog/dialog.actions';
import { deleteBoard } from '../../../../../app/page/page-storage/boards/boards.actions';
import { userSelectors } from '../../../../../app/resource/user/user.selectors';
import { useApiRequestState } from '../../../../../utils/api.utils';
import { useSelectWithParams } from '../../../../../utils/selector.utils';

import { IBoardFormProps } from '../../../common/forms/BoardForm/types';
import { IBoardHeaderProps } from './types';

export const BoardHeader: FunctionComponent<IBoardHeaderProps> = ({ boardData, meeting }) => {
  const dispatch = useDispatch();
  const [showLoader] = useApiRequestState('getBoardLists');

  const onDeleteBoard = useCallback(() => {
    dispatch(deleteBoard(boardData.id));
  }, [boardData.id, dispatch]);

  const openEditBoardFormDialog = useCallback(() => {
    dispatch(openDialog('boardFormDialog', { boardData } as IBoardFormProps));
  }, [boardData, dispatch]);
  const userIds = [...meeting.shareWithIds, ...meeting.administratorIds];
  const uniqueIds = Array.from(new Set(userIds));

  const users = useSelectWithParams(userSelectors.selectResourcesById, uniqueIds);
  return (
    <div className="panel-header small-header no-border pb-0 board-panel-header">
      {/* {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />} */}
      {showLoader && (
        <h2 className="bold mr-3">
          <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />
        </h2>
      )}
      {!showLoader && <h2 className="bold mr-3">{boardData.name}</h2>}

      <div className="flex aic">
        <a
          className="btn-badge btn-sm mr-05 flex aic jcc darkGray-bg white popup_button"
          data-popup="new_board_popup"
          onClick={openEditBoardFormDialog}
        >
          <i className="icon icon-pencil f-0" />
        </a>
        <a className="btn-badge btn-sm mr-05 flex aic jcc red-bg white" onClick={onDeleteBoard}>
          <i className="icon icon-close f-0" />
        </a>
      </div>
      <div className="flex aic ml-auto">
        {users.map(
          (user) =>
            user && (
              <a className="small-avatar mr-05" key={`userId-${user.id}`}>
                <img src={user.image} alt="" className="img-fluid" style={{ borderRadius: '50%' }} />
              </a>
            ),
        )}
      </div>
      {/* <a  class="btn btn-md darkGray-bg ml-3" title="Team Access"><i class="icon icon-user-icon-user-full"></i> Team Access</a> */}
      {/* <div className="dropdown">
        <span className="dropdown-button btn btn-md darkGray-bg ml-3" data-action="dropdown">
          <i className="icon icon-user-icon-user-full" /> Team Access
        </span>
        <div className="dropdown-menu ml-3">
          <div className="field-container custom-select with-checkbox">
            <div className="select-dropdown visible">
              <div className="select-item selected" data-val="slack">
                <span className="btn-badge purple-bg">LT</span> Leadership
              </div>
              <div className="select-item" data-val="slack">
                <span className="btn-badge blue-bg">BD</span> Biz Development
              </div>
              <div className="select-item" data-val="slack">
                <span className="btn-badge yellow-bg">CD</span> Customer Delivery
              </div>
              <div className="select-item" data-val="slack">
                <span className="btn-badge green-bg">JP</span> Julien's Pack
              </div>
              <div className="select-item" data-val="slack">
                <span className="btn-badge red-bg">SP</span> Sarah's Pack
              </div>
              <div className="select-item" data-val="slack">
                <span className="btn-badge darkGray-bg">DP</span> Development Pack
              </div>
            </div>
          </div>
        </div>
      </div>
      <a className="btn btn-md darkGray-bg" title="Account Access">
        <i className="icon icon-user-icon-user-full" /> Account Access
      </a>
      <a className="btn btn-md btn-border white-bg" title="Labels">
        <img src="images/labels-icon.png" alt="" className="img-fluid mr-1" /> Labels
      </a> */}
    </div>
  );
};
