import { DropResult } from 'react-beautiful-dnd';
import { put } from 'redux-saga/effects';
import { cutAndInstert } from '../../../../utils/dnd.utils';
import { select } from '../../../../utils/selector.utils';

import * as actions from './widgets.actions';
import { widgetsPageSelectors } from './widgets.selectors';
import { IWidget } from './widgets.types';

export function* storeWidgets(widgets: IWidget[]) {
  yield put(actions.storeWidgets(widgets));
}

export function* dndWidget(result: DropResult) {
  if (!result.destination) {
    return;
  }
  const widgetKey = result.draggableId;
  const destinationIndex = result.destination.index;
  const sourceIndex = result.source.index;

  if (destinationIndex === sourceIndex) {
    return;
  }

  const source = (yield select(widgetsPageSelectors.selectPageWidgets)) as IWidget[];
  const sourceMap = source.reduce((prev, curr) => {
    prev[curr.key] = curr;
    return prev;
  }, {} as { [key: string]: IWidget });

  if (!source || source[sourceIndex].key !== widgetKey) {
    return;
  }

  const [sourceKeys] = cutAndInstert(
    source.map((widget) => widget.key),
    sourceIndex,
    destinationIndex,
  );
  yield put(actions.storeWidgets(sourceKeys.map((key) => sourceMap[key])));
}
