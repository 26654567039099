import { combineReducers, Reducer } from 'redux';

import * as types from './meetings.types';
import * as constants from './meetings.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IMeetingsPageState = {
  reducedMeetings: {},
  filteredMeetings: [],
  currentMeeting: null,
  isFetched: false,
};

const reducedMeetingsReducer: Reducer<
  types.IMeetingsPageState['reducedMeetings'],
  types.IStoreReducedMeetings | types.IClearReducedMeetings
> = (state = initialState.reducedMeetings, action) => {
  if (isActionOfType<types.IStoreReducedMeetings>(action, constants.STORE_REDUCED_MEETINGS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearReducedMeetings>(action, constants.CLEAR_REDUCED_MEETINGS)) {
    return initialState.reducedMeetings;
  }
  return state;
};

const filteredMeetingsReducer: Reducer<
  types.IMeetingsPageState['filteredMeetings'],
  types.IStoreFilteredMeetings | types.IClearFilteredMeetings
> = (state = initialState.filteredMeetings, action) => {
  if (isActionOfType<types.IStoreFilteredMeetings>(action, constants.STORE_FILTERED_MEETINGS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearFilteredMeetings>(action, constants.CLEAR_FILTERED_MEETINGS)) {
    return initialState.filteredMeetings;
  }
  return state;
};

const currentMeetingReducer: Reducer<
  types.IMeetingsPageState['currentMeeting'],
  types.IStoreCurrentMeeting | types.IClearCurrentMeeting
> = (state = initialState.currentMeeting, action) => {
  if (isActionOfType<types.IStoreCurrentMeeting>(action, constants.STORE_CURRENT_MEETING)) {
    return action.payload;
  } else if (isActionOfType<types.IClearCurrentMeeting>(action, constants.CLEAR_CURRENT_MEETING)) {
    return initialState.currentMeeting;
  }
  return state;
};

const isFetchedReducer: Reducer<
  types.IMeetingsPageState['isFetched'],
  types.IStoreIsFetched | types.IClearIsFetched
> = (state = initialState.isFetched, action) => {
  if (isActionOfType<types.IStoreIsFetched>(action, constants.STORE_IS_FETCHED)) {
    return true;
  } else if (isActionOfType<types.IClearIsFetched>(action, constants.CLEAR_IS_FETCHED)) {
    return initialState.isFetched;
  }
  return state;
};

export const meetingsPageReducer: Reducer<types.IMeetingsPageState, types.IMeetingsPageAction> = combineReducers({
  reducedMeetings: reducedMeetingsReducer,
  filteredMeetings: filteredMeetingsReducer,
  currentMeeting: currentMeetingReducer,
  isFetched: isFetchedReducer,
});
