/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';

import { agendaItemsPageSelectors } from '../../../../app/page/page-storage/agenda-items/agenda-items.selectors';
import { sessionsPageSelectors } from '../../../../app/page/page-storage/sessions/sessions.selectors';
import { meetingSelectors } from '../../../../app/resource/meeting/meeting.selectors';

import { useSelect, useSelectWithParams } from '../../../../utils/selector.utils';
import { AgendaList } from './AgendaList';
import { NextSession } from './NextSession';
import { IMeetingManagementProps } from './types';

export const MeetingManagement: FunctionComponent<IMeetingManagementProps> = ({ meetingId }) => {
  const agendaItemIds = useSelect(agendaItemsPageSelectors.selectAgendaItems);

  // const nextSessionId = useSelect(meetingDashboardPageSelectors.selectNextSession);
  const nextSessionId = useSelect(sessionsPageSelectors.selectNextSessionId);
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);

  const link = meeting?.sharepointUrl ? meeting.sharepointUrl : '/home';
  const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  const check = regex.test(link);
  const callAlert = useCallback(() => {
    alert('Video Conference Link is invalid');
  }, []);

  return (
    <div className="panel vto-text">
      <div className="panel-header no-border">
        <h2 className="bold">Meeting Management</h2>
        <div className="ml-auto flex aic">
          {check ? (
            <a className="btn btn-md btn-border darkGray-bg" title="Video Conference Link" href={link} target="_blank">
              <i className="icon2-video mr-1">{/* <img src={camera} alt="" className="img-fluid" /> */}</i>
              Video Conference Link
            </a>
          ) : (
            <a className="btn btn-md btn-border darkGray-bg" title="Video Conference Link" onClick={callAlert}>
              <i className="icon2-video mr-1">{/* <img src={camera} alt="" className="img-fluid" /> */}</i>
              Video Conference Link
            </a>
          )}
        </div>
      </div>

      <div className="row">
        <NextSession meetingId={meetingId} sessionId={nextSessionId} />
        <AgendaList meetingId={meetingId} agendaItemsIds={agendaItemIds} />
      </div>
    </div>
  );
};
