import { call, put } from '@redux-saga/core/effects';
import { select, selectWithParams } from '../../../../../utils/selector.utils';
import { storeBoardList } from '../../../../resource/boards/boardList/boardList.actions';
import { boardListSelectors } from '../../../../resource/boards/boardList/boardList.selectors';
import { IBoardList } from '../../../../resource/boards/boardList/boardList.types';
import { IMeeting } from '../../../../resource/meeting/meeting.types';
import { ITask } from '../../../../resource/task/task.types';
import { meetingsPageSelectors } from '../../meetings/meetings.selectors';
import { generateBoardListId, backendStatusToTStatus } from '../tasks.saga-services';

export function* saveNonDefaultByType(task: ITask) {
  const meetingId = (yield select(meetingsPageSelectors.selectCurrentMeeting)) as IMeeting['id'];
  yield call(insertIntoNonDefaultByType, meetingId, task);
}

export function* insertIntoNonDefaultByType(meetingId: IMeeting['id'], task: ITask) {
  const boardListIds = [
    task.taskTypeId,
    generateBoardListId(
      meetingId,
      'groupByNonDefaultTypes',
      { taskTypeId: task.taskTypeId },
      backendStatusToTStatus(task.status),
    ),
  ];
  const activeBoardListIds = [
    task.taskTypeId,
    generateBoardListId(
      meetingId,
      'groupByNonDefaultTypes',
      { taskTypeId: task.taskTypeId },
      backendStatusToTStatus('0'),
    ),
  ];
  const doneBoardListIds = [
    task.taskTypeId,
    generateBoardListId(
      meetingId,
      'groupByNonDefaultTypes',
      { taskTypeId: task.taskTypeId },
      backendStatusToTStatus('1'),
    ),
  ];

  const boardLists = (yield selectWithParams(boardListSelectors.selectResourcesById, boardListIds)) as (
    | IBoardList
    | undefined
  )[];

  const activeBoardLists = (yield selectWithParams(boardListSelectors.selectResourcesById, activeBoardListIds)) as (
    | IBoardList
    | undefined
  )[];

  const doneBoardLists = (yield selectWithParams(boardListSelectors.selectResourcesById, doneBoardListIds)) as (
    | IBoardList
    | undefined
  )[];

  const activeBoardList = activeBoardLists.filter((bl) => bl && bl.id.length > 15) as IBoardList[];
  const doneBoardList = doneBoardLists.filter((bl) => bl && bl.id.length > 15) as IBoardList[];
  const filteredActive = activeBoardList.map((boardList) => ({
    ...boardList,
    tasksIds: boardList.tasksIds.filter((taskId) => taskId !== task.id),
  }));
  const filteredDone = doneBoardList.map((boardList) => ({
    ...boardList,
    tasksIds: boardList.tasksIds.filter((taskId) => taskId !== task.id),
  }));

  if (task.status === '1') {
    yield put(
      storeBoardList(
        filteredDone.map((boardList) => ({
          ...boardList,
          tasksIds: [...boardList.tasksIds, task.id],
        })),
      ),
    );
    yield put(storeBoardList(filteredActive.map((boardList) => boardList)));
  } else {
    yield put(
      storeBoardList(
        filteredActive.map((boardList) => ({
          ...boardList,
          tasksIds: [...boardList.tasksIds, task.id],
        })),
      ),
    );
    yield put(storeBoardList(filteredDone.map((boardList) => boardList)));
  }

  // TODO PART
  const boardListIdsToCreate: IBoardList['id'][] = [];
  boardLists.forEach((boardList, i) => {
    if (!boardList) {
      boardListIdsToCreate.push(boardListIds[i]);
    }
  });
  //
  console.log('boardLists', boardLists);
  const allBoardLists = boardLists.filter(
    (boardList) => boardList && !boardList.tasksIds.includes(task.id),
  ) as IBoardList[];

  console.log('ALL BOARDS', allBoardLists, boardListIds);

  // yield put(
  //   storeBoardList(allBoardLists.map((boardList) => ({ ...boardList, tasksIds: [...boardList.tasksIds, task.id] }))),
  // );
}
