import React from 'react';
import { FunctionComponent } from 'react';
import { FormCheckboxInput } from '../../../inputs/CheckboxInput';
import { IPermissionsFormProps } from './types';

export const PermissionsForm: FunctionComponent<IPermissionsFormProps> = ({ projectData }) => {
  return (
    <>
      <div className="panel green-gradient py-1 px-2 flex aic mb-1 mt-2">
        <h2 className="bold white flex aic">PERMISSIONS</h2>
      </div>
      <div className="panel-body pb-0">
        <div className="row">
          <div className="col-12 mb-2 px-0">
            <div className="remember-checkbox flex aic jcl">
              <FormCheckboxInput
                id="showToAdmins"
                name="showToAdmins"
                defaultChecked={projectData?.showToAdmins === '1' ? true : false}
              />

              <label htmlFor="showToAdmins">
                Show project report to project managers on this project, and aministrators.
              </label>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="remember-checkbox flex aic jcl">
              <FormCheckboxInput
                id="showToAll"
                name="showToAll"
                defaultChecked={projectData ? (projectData?.showToAll === '1' ? true : false) : true}
              />

              <label htmlFor="showToAll">Show project report to everyone on the project.</label>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
    </>
  );
};
