import React, { FunctionComponent, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { passwordGroupSelectors } from '../../../../app/resource/password-group/password-group.selectors';
import { IPasswordSaveForm } from '../../../../app/resource/password/password.types';
import { useRequiredValidation } from '../../../../utils/form/validation.utils';
import { useSelect } from '../../../../utils/selector.utils';
import { FormPasswordInput } from '../../common/inputs/PasswordInput';
import { SelectInput } from '../../common/inputs/SelectInput';
import { FormTextInput } from '../../common/inputs/TextInput';
import { IPasswordFormProps } from './types';

export const PasswordFrom: FunctionComponent<IPasswordFormProps> = ({ data, meetingId, onSubmit }) => {
  const formProps = useForm();
  const requiredValidation = useRequiredValidation();

  const passwordGroups = useSelect(passwordGroupSelectors.selectResourceList);

  const groupOptions = useMemo(
    () =>
      passwordGroups
        // .filter((group) => group.meetingId === meetingId)
        .map((group) => ({
          value: group.id,
          label: group.name,
        })),
    [passwordGroups],
  );

  const defaultGroups = useMemo(
    () => (data ? groupOptions.filter((option) => data.groupIds.includes(option.value)) : []),
    [data, groupOptions],
  );

  return (
    <FormProvider {...formProps}>
      <form
        id="password-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) =>
          onSubmit({ groups: [], ...data, ...formData, meetingId } as IPasswordSaveForm),
        )}
        action=""
        method="post"
      >
        <div className="col-12" style={{ marginTop: '10px' }}>
          <div className="field-container">
            <SelectInput
              defaultOptions={defaultGroups}
              id="groups"
              name="groups"
              label="Password Vault Folder"
              placeholder="Select"
              options={groupOptions}
            />
          </div>
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.account}
              id="account"
              name="account"
              type="text"
              label="Account"
              placeholder="Enter"
              validation={requiredValidation}
            />
          </div>
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.username}
              id="username"
              name="username"
              type="text"
              label="Username"
              placeholder="Enter"
              validation={requiredValidation}
            />
          </div>
          <div className="field-container">
            <FormPasswordInput
              defaultValue={data?.password}
              id="password"
              name="password"
              label="Password"
              placeholder="Password"
              validation={requiredValidation}
            />
          </div>

          <div className="field-container">
            <FormTextInput
              defaultValue={data?.siteUrl}
              id="siteUrl"
              name="siteUrl"
              type="text"
              label="Login Url"
              placeholder="Enter"
              validation={requiredValidation}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
