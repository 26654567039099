/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { createConclusion } from '../../../../../../../app/page/page-storage/conclusions/conclusions.actions';
// import { authSelectors } from '../../../../../../../app/auth/auth.selectors';
import { userSelectors } from '../../../../../../../app/resource/user/user.selectors';
import { useApiRequestState } from '../../../../../../../utils/api.utils';
import { useSelectWithParams } from '../../../../../../../utils/selector.utils';
import { IUserRecapProps } from './types';

const marks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const UserRecap: FunctionComponent<IUserRecapProps> = ({ userId, nextSessionId }) => {
  const dispatch = useDispatch();
  const user = useSelectWithParams(userSelectors.selectResourceById, userId);

  const [recapComment, setRecapCommet] = useState('');
  const [mark, setMark] = useState(10);

  const selectedMark = useMemo(() => marks.map((m) => (m === mark ? true : false)), [mark]);

  // const currentUserId = useSelect(authSelectors.selectLoggedUser);
  // const enable = useMemo(() => (currentUserId ? currentUserId === userId : false), [currentUserId, userId]);
  const enable = true;

  const handleSaveRecap = useCallback(() => {
    dispatch(createConclusion({ sessionId: nextSessionId, userId, score: mark, note: recapComment }));
    setClick(true);
  }, [dispatch, mark, nextSessionId, recapComment, userId]);

  const [showLoader] = useApiRequestState('createConclusion');
  const [click, setClick] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      setClick(false);
    }, 1000);
  }, [showLoader]);

  return (
    user && (
      <div className="single-headline recap-row col-12 p-1 pb-0" onBlur={handleSaveRecap}>
        <div className="panel flex aic px-2">
          <div className="headline-left flex aic flex20">
            <span className="small-avatar mr-1">
              <img src={user.image} alt={`${user.firstName} ${user.lastName}`} className="img-fluid" />
            </span>
            {`${user.firstName} ${user.lastName}`}{' '}
          </div>
          <div style={{ cursor: 'pointer' }} className="toggle-buttons recap-buttons flex aic flex20">
            {marks.map((mark) => (
              <a
                key={`${user.id}/${mark}`}
                className={`recap-button ${selectedMark[mark - 1] ? 'selected' : ''}`}
                onClick={() => setMark(mark)}
              >
                {mark}
              </a>
            ))}
          </div>
          <div className="recap-comment">
            <input
              disabled={!enable}
              type="text"
              className="recap-comment-input"
              onChange={(e) => setRecapCommet(e.target.value)}
            />
            {/* {enable && !(showLoader && click) && (
              <button key={user.id} className="btn darkGray-bg white ml-1" onClick={handleSaveRecap}>
                Save
              </button>
            )}
            {enable && showLoader && click && (
              <Loader key={user.id} type="TailSpin" color="#1bc3a7" height={30} width={30} />
            )} */}
          </div>
        </div>
      </div>
    )
  );
};
