import { call, put } from 'redux-saga/effects';

import * as types from './kpi-row-scoreboard.types';
import * as actions from './kpi-row-scoreboard.actions';

import * as apiService from '../../api/request-config/scoreboard.api';
import { apiRequest } from '../../api/api.saga-services';
import { select } from '../../../utils/selector.utils';
import { kpiRowScoreboardSelectors } from './kpi-row-scoreboard.selectors';

export function* storeKpiRowScoreboard(storeKpiRowScoreboard: types.IKpiRowScoreboard) {
  yield put(actions.storeKpiRowScoreboard(storeKpiRowScoreboard));
  return storeKpiRowScoreboard;
}

export function* clearKpiRowScoreboard(kpiRowScoreboardId: types.IKpiRowScoreboard['id']) {
  yield put(actions.clearKpiRowScoreboard(kpiRowScoreboardId));
}

export function* clearAllKpiRowScoreboards() {
  const kpiRowScoreboardIds = yield select(kpiRowScoreboardSelectors.selectResourceList);
  const kpiRowScoreboardById = yield select(kpiRowScoreboardSelectors.selectResourceIds);
  yield put(actions.clearKpiRowScoreboard(kpiRowScoreboardIds));
  yield put(actions.clearKpiRowScoreboard(kpiRowScoreboardById));
}

export function* createKpiRowScoreboard(scoreboardInfo: types.IKpiRowScoreboardCreate) {
  const scoreboard = (yield call(
    apiRequest,
    apiService.createScoreboardApi(scoreboardInfo),
  )) as types.IKpiRowScoreboard;
  if (!scoreboard) {
    return;
  }

  yield call(storeKpiRowScoreboard, { ...scoreboard, id: scoreboard.id + '' });
  return scoreboard;
}

export function* updateKpiRowScoreboard(scoreboardInfo: types.IKpiRowScoreboardUpdate) {
  const scoreboard = (yield call(
    apiRequest,
    apiService.updateScoreboardApi(scoreboardInfo),
  )) as types.IKpiRowScoreboard;
  if (!scoreboard) {
    return;
  }
  yield call(storeKpiRowScoreboard, { ...scoreboard, id: scoreboard.id + '' });
  return scoreboard;
}
