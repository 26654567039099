import { combineReducers, Reducer } from 'redux';

import { isActionOfType } from '../../../../utils/action.utils';

import * as types from './templates.types';
import * as constants from './templates.constants';

const initialState: types.ITemplatesPageState = {
  templateIds: [],
};

const templateIdsReducer: Reducer<
  types.ITemplatesPageState['templateIds'],
  types.IStoreTemplateIds | types.IClearTemplateIds
> = (state = initialState.templateIds, action) => {
  if (isActionOfType<types.IStoreTemplateIds>(action, constants.STORE_TEMPLATES)) {
    return action.payload;
  } else if (isActionOfType<types.IClearTemplateIds>(action, constants.CLEAR_TEMPLATES)) {
    return initialState.templateIds;
  }
  return state;
};

export const templatesPageReducer: Reducer<types.ITemplatesPageState, types.ITemplatesPageAction> = combineReducers({
  templateIds: templateIdsReducer,
});
