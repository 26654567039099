import { combineReducers, Reducer } from 'redux';

import * as types from './project-tasks.types';
import * as constants from './project-tasks.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IProjectTaskPageState = {
  projectTasksIds: [],
};

const projectTasksReducer: Reducer<
  types.IProjectTaskPageState['projectTasksIds'],
  types.IStoreProjectTask | types.IClearProjectTask
> = (state = initialState.projectTasksIds, action) => {
  if (isActionOfType<types.IStoreProjectTask>(action, constants.STORE_PROJECT_TASK)) {
    return action.payload;
  } else if (isActionOfType<types.IClearProjectTask>(action, constants.CLEAR_PROJECT_TASK)) {
    return initialState.projectTasksIds;
  }

  return state;
};

export const projectTasksPageReducer: Reducer<
  types.IProjectTaskPageState,
  types.IProjectTaskPageAction
> = combineReducers({
  projectTasksIds: projectTasksReducer,
});
