import { call } from 'redux-saga/effects';

import * as pageStateService from '../page-state/page-state.saga-services';
import * as pageInitializerService from '../page.initializer.saga-service';

import * as meetingService from '../page-storage/meetings/meetings.saga-services';
import * as taskService from '../page-storage/tasks/tasks.saga-services';
import * as agendaService from '../page-storage/agenda-items/agenda-items.saga-services';

import * as kpiService from '../page-storage/kpi/kpi.saga-services';
import * as sessionService from '../page-storage/sessions/sessions.saga-services';

import { IMeeting } from '../../resource/meeting/meeting.types';

export function* initialMeetingDashboardPage(meetingId: IMeeting['id']) {
  if (!meetingId) {
    return;
  }
  yield call(agendaService.clearAgendaItemsPageStorage);
  yield call(pageStateService.initPage, 'meetingDashboard');

  if (!((yield call(pageInitializerService.initPage)) as boolean)) {
    yield call(meetingService.putMeetings);

    yield call(agendaService.setupAgendaItemsForMeeting, meetingId);

    yield call(taskService.setupTasksForMeeting, meetingId);
    yield call(kpiService.setupKpi, meetingId);
    yield call(sessionService.setupSessions, { id: meetingId });
    // tide it up
  }

  yield call(pageStateService.completedInit, 'meetingDashboard');
}
