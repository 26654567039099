/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo } from 'react';
import { FunctionComponent } from 'react';

import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../../app/dialog/dialog.selectors';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { BoardForm } from '../../../pages/common/forms/BoardForm';
import { SubmitButton } from '../../../pages/common/forms/SubmitButton';

import { ConnectedDialog } from '../../ConnectedDialog';
import { IBoardFormDialogProps } from './types';

export const BoardFormDialog: FunctionComponent = () => {
  const dispatch = useDispatch();

  const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'boardFormDialog') as IBoardFormDialogProps;

  const title = useMemo(() => (props && props.boardData ? `Edit Board` : 'Create New Board'), [props]);

  const onClose = useCallback(() => {
    dispatch(closeDialog('boardFormDialog'));
  }, [dispatch]);
  return (
    <ConnectedDialog dialogKey="boardFormDialog">
      <div className="popup new_board_popup" style={{ display: 'block' }}>
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            <h3>{title}</h3>
            <span className="close-popup close" onClick={onClose}>
              <i className="icon-close" />
            </span>
          </div>
          <div className="panel-body">
            <BoardForm {...props} />
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" onClick={onClose}>
              Close
            </a>

            {!props && <SubmitButton apiKey={'createBoard'} formName="board-form" text="Save" />}
            {props && <SubmitButton apiKey={'updateBoard'} formName="board-form" text="Save" />}
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
