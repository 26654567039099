/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../app/dialog/dialog.actions';
import { IScoreFormDialogProps } from '../types';

export const ScoreFormTrigger: FunctionComponent<IScoreFormDialogProps> = ({ meetingId }) => {
  const dispatch = useDispatch();

  const openScoreFormDialog = useCallback(() => {
    dispatch(
      openDialog('scoreFormDialog', {
        meetingId,
      }),
    );
  }, [dispatch, meetingId]);

  return (
    <a
      className="btn btn-md green-bg popup_button"
      data-popup="new_score_popup"
      title="Add Score"
      onClick={openScoreFormDialog}
    >
      <i className="icon-plus mr-1"></i> Add Score
    </a>
  );

  // <a className="btn btn-md green-bg popup_button">
  //   <i className="icon-plus mr-1"></i> Add Task
  // </a>;
};
