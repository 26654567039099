/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../app/dialog/dialog.actions';
import { deleteQuartal } from '../../../../app/page/page-storage/quartals/quartals.actions';

import { quartalSelectors } from '../../../../app/resource/quartal/quartal.selectors';
import { IQuartal } from '../../../../app/resource/quartal/quartal.types';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { IQuartalRowProps } from './types';

export const QuartalRow: FunctionComponent<IQuartalRowProps> = ({ quartalId }) => {
  const quartal = useSelectWithParams(quartalSelectors.selectResourceById, quartalId) as IQuartal;

  const dispatch = useDispatch();

  const openQuartalFormDialog = useCallback(() => {
    dispatch(
      openDialog('quartalFormDialog', {
        quartalData: quartal,
      }),
    );
  }, [dispatch, quartal]);

  const onDeleteQuartal = useCallback(() => {
    dispatch(deleteQuartal(quartalId));
  }, [dispatch, quartalId]);

  return (
    quartal && (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          //justifyContent: 'space-between',
          borderBottom: '1px solid #dddddd',
          alignItems: 'center',
        }}
      >
        <span style={{ flexBasis: '15%', padding: '12px 15px', textAlign: 'start' }}>{quartal.year}</span>
        <span style={{ flexBasis: '20%', padding: '12px 15px', textAlign: 'center' }}>{quartal.startQuartal1}</span>
        <span style={{ flexBasis: '20%', padding: '12px 15px', textAlign: 'center' }}>{quartal.startQuartal2}</span>
        <span style={{ flexBasis: '20%', padding: '12px 15px', textAlign: 'center' }}>{quartal.startQuartal3}</span>
        <span style={{ flexBasis: '20%', padding: '12px 15px', textAlign: 'center' }}>{quartal.startQuartal4}</span>
        <div style={{ flexBasis: '5%', display: 'flex', justifyContent: 'flex-end' }}>
          <a
            className="btn-badge btn-sm mr-05 flex aic jcc darkGray-bg white popup_button"
            data-popup="new_board_popup"
            style={{ padding: '5px' }}
            onClick={openQuartalFormDialog}
          >
            <i className="icon icon-pencil f-0" />
          </a>
          <a
            className="btn-badge btn-sm mr-05 flex aic jcc red-bg white"
            style={{ padding: '5px' }}
            onClick={onDeleteQuartal}
          >
            <i className="icon icon-close f-0" />
          </a>
        </div>
      </div>
    )
  );
};
