import { all, call, fork, takeLatest } from 'redux-saga/effects';

import * as constants from './project-members.constants';
import * as types from './project-members.types';
import * as services from './project-members.saga-services';

function* invokeSetupProjectTeams(action: types.ISetupProjectTeams) {
  yield call(services.setupProjectTeams, action.payload.week, action.payload.accountId);
}

function* invokeCreateProjectMember(action: types.ICreateProjectMember) {
  yield call(services.createProjectMember, action.payload);
}

function* invokeUpdateProjectMember(action: types.IUpdateProjectMember) {
  yield call(services.updateProjectMember, action.payload.id, action.payload.data);
}
function* invokeDeleteProjectMember(action: types.IDeleteProjectMember) {
  yield call(services.deleteProjectMember, action.payload);
}
/*
function* invokeSaveProjectMemberSocket(action: types.ISaveProjectMemberSocket) {
  yield call(services.handleProjectMemberFromNotification, action.payload);
}
*/
export function* watchProjectMembersPurePageSaga() {
  yield takeLatest(constants.SETUP_PROJECT_TEAM, invokeSetupProjectTeams);
  yield takeLatest(constants.CREATE_PROJECT_MEMBER, invokeCreateProjectMember);
  yield takeLatest(constants.UPDATE_PROJECT_MEMBER, invokeUpdateProjectMember);
  yield takeLatest(constants.DELETE_PROJECT_MEMBER, invokeDeleteProjectMember);

  //yield takeLatest(constants.SAVE_PROJECT_MEMBER_SOCKET, invokeSaveProjectMemberSocket);
}

export function* watchProjectMembersPageStorageSaga() {
  yield all([watchProjectMembersPurePageSaga].map(fork));
}
