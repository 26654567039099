import { call, takeEvery, takeLatest } from 'redux-saga/effects';
import * as constants from './tasks.constants';

import * as types from './tasks.types';
import * as services from './tasks.saga-services';

import { taskDnd } from './tasks-handlers/dnd.saga-service';

function* invokeMoveTask(action: types.IMoveTask) {
  yield call(services.moveTask, action.payload);
}
function* invokeBoardMoveTask(action: types.IMoveBoardTask) {
  yield call(services.moveBoardTask, action.payload);
}
function* invokeCreateTask(action: types.ICreateTask) {
  yield call(services.createTask, action.payload);
}
function* invokeUpdateTask(action: types.IUpdateTask) {
  yield call(services.updateTask, action.payload.id, action.payload.data);
}

function* invokeDeleteTask(action: types.IDeleteTask) {
  yield call(services.deleteTask, action.payload);
}
function* invokeFilterTaskForUserByMeetingIds(action: types.IFilterTaskForUserByMeetingIds) {
  yield call(services.filterTaskForUserByMeetingIds, action.payload.userId, action.payload.meetingIds);
}

function* invokeSaveTaskSocket(action: types.ISaveTaskSocket) {
  yield call(services.combinedHandleTaskFromNotification, action.payload);
}

function* invokeDeleteTaskSocket(action: types.IDeleteTaskSocket) {
  yield call(services.deleteTaskSocket, action.payload.id);
}

function* invokeDndTask(action: types.IDndTask) {
  yield call(taskDnd, action.payload);
  yield call(services.moveTask, action.payload, true);
}

export function* watchTaskPageSaga() {
  yield takeLatest(constants.MOVE_TASK, invokeMoveTask);
  yield takeLatest(constants.MOVE_BOARD_TASK, invokeBoardMoveTask);

  yield takeLatest(constants.CREATE_TASK, invokeCreateTask);
  yield takeLatest(constants.UPDATE_TASK, invokeUpdateTask);
  yield takeLatest(constants.DELETE_TASK, invokeDeleteTask);
  yield takeLatest(constants.FILTER_TASK_FOR_USER_BY_MEETING, invokeFilterTaskForUserByMeetingIds);

  yield takeEvery(constants.SAVE_TASK_SOCKET, invokeSaveTaskSocket);
  yield takeEvery(constants.DELETE_TASK_SOCKET, invokeDeleteTaskSocket);

  yield takeLatest(constants.DND_TASK, invokeDndTask);
}
