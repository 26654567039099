import * as types from './labels.types';
import * as constants from './labels.constants';
import { ILabelCreateForm, ILabel } from '../../../resource/label/label.types';

export const createLabel = (label: ILabelCreateForm): types.ICreateLabel => ({
  type: constants.CREATE_LABEL,
  payload: label,
});

export const updateLabel = (labelId: ILabel['id'], label: ILabelCreateForm): types.IUpdateLabel => ({
  type: constants.UPDATE_LABEL,
  payload: {
    id: labelId,
    data: label,
  },
});

export const deleteLabel = (labelId: ILabel['id']): types.IDeleteLabel => ({
  type: constants.DELETE_LABEL,
  payload: labelId,
});

export const storeLabels = (labels: ILabel['id'][]): types.IStoreLabel => ({
  type: constants.STORE_LABEL,
  payload: labels,
});

export const clearLabel = (): types.IClearLabel => ({
  type: constants.CLEAR_LABEL,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
