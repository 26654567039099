import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../../app/dialog/dialog.actions';
import { deleteTask } from '../../../../../../app/page/page-storage/tasks/tasks.actions';
import { IDefaultTaskType } from '../../../../../../app/resource/task/task.types';
import { ITaskMenuProps } from './types';

export const TaskMenu: FunctionComponent<ITaskMenuProps> = ({ task }) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const changeOpen = useCallback(() => setOpen(!isOpen), [setOpen, isOpen]);
  const close = useCallback(() => setOpen(false), [setOpen]);

  const openUpdateTask = useCallback(() => {
    dispatch(openDialog('taskFormDialog', { type: task.taskTypeId as IDefaultTaskType, task, userId: task.userId }));
  }, [dispatch, task]);

  const onDeleteTask = useCallback(() => {
    dispatch(deleteTask(task.id));
  }, [dispatch, task]);

  return (
    <div className={`card-options dropdown submenu-dots ${isOpen ? 'opened' : ''}`}>
      <span className="dropdown-button" onClick={changeOpen}>
        <i className="icon-dot-3 link blueGray"></i>
      </span>
      <ul className="dropdown-menu drop-right" onClick={close}>
        <li onClick={openUpdateTask}>
          <span className="drop-item">Edit</span>
        </li>
        <li>
          <span className="drop-item">Move to Issue</span>
        </li>
        <li onClick={onDeleteTask}>
          <span className="drop-item">
            <i className="icon-trash red"></i> Delete
          </span>
        </li>
      </ul>
    </div>
  );
};
