import React, { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IPasswordGroupSave } from '../../../../../app/resource/password-group/password-group.types';
import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { FormTextInput } from '../../../common/inputs/TextInput';
import { IPasswordGroupFormProps } from './types';

export const PasswordGroupFrom: FunctionComponent<IPasswordGroupFormProps> = ({ data, meetingId, onSubmit }) => {
  const formProps = useForm();
  const requiredValidation = useRequiredValidation();

  return (
    <FormProvider {...formProps}>
      <form
        id="password-group-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) =>
          onSubmit({ ...data, ...formData, meetingId } as IPasswordGroupSave),
        )}
        action=""
        method="post"
      >
        <div className="col-12" style={{ marginTop: '10px' }}>
          <div className="field-container">
            <FormTextInput
              defaultValue={data?.name}
              id="name"
              name="name"
              type="text"
              label="Name"
              placeholder="Enter Name"
              validation={requiredValidation}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
