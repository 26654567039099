import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createActivity, updateActivity } from '../../../../../app/page/page-storage/activities/activities.actions';
import { userSelectors } from '../../../../../app/resource/user/user.selectors';
import { useAtUserSearch } from '../../../../../utils/atUserSearch.utils';
import { useSelect } from '../../../../../utils/selector.utils';
import { MembersManyOnAtInput } from '../../MembersManyOnAtInput';

import { IActivityFormProps } from './types';

export const ActivityForm: FunctionComponent<IActivityFormProps> = ({ activityData, taskId }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState('');

  const { searchMember, onSelectUser, onTextChange, toggleClass, isActive } = useAtUserSearch({ setText });
  const userIds = useSelect(userSelectors.selectResourceIds);

  const onSubmit = () => {
    if (!text) {
      return;
    }
    if (activityData && activityData.id) {
      dispatch(updateActivity(activityData.id, { ...activityData, description: text }));
    } else {
      dispatch(createActivity({ description: text, taskId }));
    }
    setText('');
  };

  return (
    <>
      <div className="field-container">
        <input
          id={'description'}
          name={'description'}
          placeholder="Write a Comment"
          onChange={(e) => onTextChange(e.target.value)}
          value={text}
        />
        <button className="btn btn-sm green-bg small-submit comment-submit" onClick={onSubmit}>
          SEND
        </button>
      </div>
      <div className={isActive ? 'dropdown opened' : 'dropdown'}>
        {isActive && (
          <MembersManyOnAtInput
            userIds={userIds}
            closeMenu={toggleClass}
            searchMember={searchMember}
            onSelectUser={onSelectUser}
            dropRight
          />
        )}
      </div>
    </>
  );
};
