import React, { FunctionComponent } from 'react';
import { userSelectors } from '../../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../../utils/selector.utils';
import { INodeFooterProps } from './types';

export const NodeFooter: FunctionComponent<INodeFooterProps> = ({ nodeId, userIds, onUserDelete }) => {
  const users = useSelectWithParams(userSelectors.selectResourcesById, userIds);
  return (
    <ul className="nodes-users-wrapper">
      {users.map(
        (user) =>
          user && (
            <div key={`${nodeId}-userId-${user.id}`} className="nodes-user">
              <p className="u">{`${user.firstName} ${user.lastName}`}</p>
              <span
                key={`x-userId-${user.id}`}
                className="remove-user"
                onClick={() => {
                  onUserDelete(user.id);
                }}
              >
                <i className="icon-close" />
              </span>
            </div>
          ),
      )}
    </ul>
  );
};
