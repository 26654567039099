import React, { useCallback, useMemo, useState } from 'react';
import { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { boardsPageSelectors } from '../../../../app/page/page-storage/boards/boards.selectors';
import { agendaItemSelectors } from '../../../../app/resource/agenda-item/agenda-item.selectors';
import { IAgenda } from '../../../../app/resource/agenda/agenda.types';
import { meetingSelectors } from '../../../../app/resource/meeting/meeting.selectors';
import { IMeeting } from '../../../../app/resource/meeting/meeting.types';
import { useSelect, useSelectWithParams } from '../../../../utils/selector.utils';
import { AgendaItemsForm } from '../../../pages/common/forms/AgendaItemsForm';
import { AddAgendaForm } from './AddAgendaForm';
import { IAddAgendaCreateForm } from './AddAgendaForm/types';
import { IMeetingAgendaProps } from './types';

export const MeetingAgenda: FunctionComponent<IMeetingAgendaProps> = ({ meetingId, onSubmit }) => {
  const data = useSelectWithParams(meetingSelectors.selectResourceById, meetingId) as IMeeting;
  const formProps = useForm();
  const defaultAgendaTable: IAgenda[] = useMemo(
    () => [
      {
        id: '',
        agendaId: '1',
        title: 'Segue',
        checked: '1',
        duration: '5',
        color: '#F9A52D',
        description:
          'Spend 5 minutes sharing one personal best and one professional best from the previous week. No discussion; just an announcement. This helps move your team from working “in the business” to working “on the business”.',
        functionName: 'segue',
        isCustom: '0',
        keyName: 'segue',
        sort: '1',
      },
      {
        id: '',
        agendaId: '2',
        title: 'KPIs',
        checked: '1',
        duration: '5',
        color: '#1672B9',
        description:
          'Review your company scorecard. This is a 5-minute high-level review to make sure your most important five to 15 numbers are on track. The person responsible for the number says whether it is “on track” or “off track”. If the number is “off”, move that measurable to the Issues List portion of the agenda.',
        functionName: 'scorecard',
        isCustom: '0',
        keyName: 'scorecard',
        sort: '2',
      },
      {
        id: '',
        agendaId: '3',
        title: 'Quarterly Initiatives',
        description:
          'Take 5 minutes to review your company and individual Rocks to determine if they are “on track” or “off track.” Again, if the rock is “off”, move it to the Issues List portion of the agenda.',
        color: '#00B393',
        keyName: 'rock_review',
        functionName: 'rock_review',
        isCustom: '0',
        duration: '5',
        sort: '3',
        checked: '1',
      },
      // {
      //   id: '',
      //   agendaId: '4',
      //   title: 'Customer/Employee Headlines',
      //   description:
      //     ' This is a 5-minute opportunity to announce any news, positive or negative, about a customer or employee. If the announcement is an issue, add it to the Issues List portion of the agenda.',
      //   color: '#d43480',
      //   keyName: 'headlines',
      //   functionName: 'headlines',
      //   isCustom: '0',
      //   duration: '5',
      //   sort: '4',
      //   checked: '1',
      // },
      {
        id: '',
        agendaId: '5',
        title: 'Action Items',
        description:
          'Review the seven-day action items from the previous meeting, and report whether each task is “done” or “not done.” This should take no more than 5 minutes',
        color: '#e74c3c',
        keyName: 'to_do_list',
        functionName: 'to_do_list',
        isCustom: '0',
        duration: '15',
        sort: '4',
        checked: '1',
      },
      // {
      //   id: '',
      //   agendaId: '6',
      //   title: 'IDS (Identify, Discuss, Solve)',
      //   description:
      //     'Your leadership team now has 60 minutes to identify, discuss and solve your company’s biggest issues in order of priority. Solving an issue usually requires someone to act, which becomes a task for the to-do list for review at your next meeting.',
      //   color: '#dbadff',
      //   keyName: 'ids',
      //   functionName: 'ids',
      //   isCustom: '0',
      //   duration: '60',
      //   sort: '6',
      //   checked: '1',
      // },
      // {
      //   id: '',
      //   agendaId: '8',
      //   title: 'Social Calendar Review',
      //   description: '',
      //   color: '#d48034',
      //   keyName: 'social_calendar_review',
      //   functionName: 'social_calendar_review',
      //   isCustom: '0',
      //   duration: '5',
      //   sort: '7',
      //   checked: '1',
      // },
      // {
      //   id: '',
      //   agendaId: '9',
      //   title: 'Pipeline Review',
      //   description: '',
      //   color: '#34d480',
      //   keyName: 'event_calendar_review',
      //   functionName: 'event_calendar_review',
      //   isCustom: '0',
      //   duration: '5',
      //   sort: '8',
      //   checked: '1',
      // },
      // IMPORTANT: Meeting Scores should always remain the last item
      {
        id: '',
        agendaId: '7',
        title: 'Meeting Scores',
        description:
          'Use your final 5 minutes to bring the meeting to a close, recap the to-do list and discuss any messages that need to be communicated to the rest of the organization. And rate the meeting on a scale of 1–10; this helps your team self-correct. Establish the practice that anyone who rates the meeting below an “8” must explain why, and “because I never give high marks” is not an acceptable reason."',
        color: '#83c340',
        keyName: 'conclusion',
        functionName: 'conclusion',
        isCustom: '0',
        duration: '5',
        sort: '5',
        checked: '1',
      },
    ],
    [],
  );

  const agendaItemsSelected = useSelectWithParams(
    agendaItemSelectors.selectResourcesById,
    data ? data.agendaItemIds : [],
  ) as IAgenda[];

  const agendaItems = agendaItemsSelected.length > 0 ? agendaItemsSelected : defaultAgendaTable;

  const updateMeeting = useCallback(
    (agendaItems) => {
      const updatedMeeting = {
        title: data.title,
        image: data.image,
        color: data.color,
        administrators: data.administratorIds.map((id) => ({
          value: id,
          label: id,
        })),
        shareWith: data.shareWithIds.map((id) => ({
          value: id,
          label: id,
        })),
        startDate: data.startDate ? data.startDate : '',
        startTime: { value: data.startTime ? data.startTime : '', label: data.startTime ? data.startTime : '' },
        endTime: { value: data.endTime ? data.endTime : '', label: data.endTime ? data.endTime : '' },
        repeatCheckbox: data.repeatCheckbox === '1' ? true : false,
        repeatEvery: data.repeatEvery,
        repeatType: { value: data.repeatType, label: data.repeatType },
        type: data.type,
        agendaItems: agendaItems.agendaItems,
      };
      onSubmit(updatedMeeting, data.id);
    },
    [data, onSubmit],
  );
  const boardIds = useSelect(boardsPageSelectors.selectBoards);
  const [addAgendaState, setAddAgendaState] = useState({
    title: '',
    boardId: '',
    boardListId: '',
    embedUrl: '',
    checked: '1',
    duration: '5',
    agendaId: '',
    description: '',
    sort: '',
  });

  const getFormData = useCallback(
    (formData: IAddAgendaCreateForm) => {
      const newAgendaItem = {
        title: formData.name,
        boardId: formData.board?.value ? formData.board?.value : '',
        boardListId: formData.boardList?.value ? formData.boardList?.value : '',
        embedUrl: formData.embedUrl ? formData.embedUrl : '',
        checked: '1',
        duration: formData.duration,
        agendaId: '',
        description: '',
        sort: data.agendaItemIds.length.toString(),
      };
      setAddAgendaState(newAgendaItem);
      setAddItem(false);
      setOnAdd(true);
    },
    [data.agendaItemIds.length],
  );

  const [addItem, setAddItem] = useState(true);
  const [onAdd, setOnAdd] = useState(false);

  return (
    <>
      <FormProvider {...formProps}>
        <form
          id="meeting-form"
          className="topic-form"
          onSubmit={formProps.handleSubmit(updateMeeting)}
          action=""
          method="post"
        >
          <AgendaItemsForm
            agendaItems={agendaItems}
            getFormData={getFormData}
            setAddItem={setAddItem}
            addItem={addItem}
            addAgendaState={addAgendaState}
            setAddAgendaState={setAddAgendaState}
            onAdd={onAdd}
            setOnAdd={setOnAdd}
          />
        </form>
      </FormProvider>
      {addItem && (
        <>
          <AddAgendaForm meetingId={meetingId} boardIds={boardIds} onSubmit={getFormData} />
          <button type="submit" form="addAgenda-form" className="btn darkGray-bg white">
            Add
          </button>
        </>
      )}
    </>
  );
};
