/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../app/dialog/dialog.actions';
import { createMeeting } from '../../../../../app/page/page-storage/meetings/meetings.actions';
import { IMeetingCreateForm, IMeetingNewCreateForm } from '../../../../../app/resource/meeting/meeting.types';
import SearchIcon from '../../../../resources/images/search-icon.svg';
import { ProjectForm } from '../../../common/forms/ProjectForm';
import { Accounts } from './Accounts';

export const AccountsTab: FunctionComponent = () => {
  const dispatch = useDispatch();

  const [projectForm, setProjectForm] = useState(false); // return to false
  const showProjectForm = useCallback((state) => setProjectForm(state), []);

  const openAccountDialog = useCallback(() => {
    dispatch(
      openDialog('meetingFormDialog', {
        type: 'account',
        onSubmit: (data: IMeetingCreateForm) => {
          // hot fix
          dispatch(createMeeting(data as IMeetingNewCreateForm));
          //dispatch(createMeeting(data));
        },
      }),
    );
  }, [dispatch]);

  return projectForm ? (
    <div className="panel white-bg px-2 py-1 max700">
      <h2 className="flex aic f-2 pt-2 pb-3 bold">New Project</h2>
      <div className="border-radius-1 lightGray-bg p-2">
        <ProjectForm showProjectForm={showProjectForm} />
      </div>
    </div>
  ) : (
    <>
      <div className="panel white-bg flex px-2 py-1">
        <h2 className="flex aic f-1 bold">ALL ACCOUNTS (10)</h2>
        <div className="ml-auto flex aic jcr">
          <div className="flex aic checkbox mr-1">
            <input type="checkbox" id="active-only" />
            <label htmlFor="active-only">Show Active Accounts Only</label>
          </div>
          <div className="field-container custom-select small-select header-select mb-0 mr-1">
            <input type="hidden" defaultValue="Folder 2" />
            <span className="select-field">
              <span className="select-value">
                Filter by Client <i className="icon-down-dir" />
              </span>
            </span>
            <div className="select-dropdown" style={{ display: 'none', boxSizing: 'border-box' }}></div>
          </div>
          <div className="field-container custom-select small-select header-select mb-0 mr-1">
            <input type="hidden" defaultValue="Folder 2" />
            <span className="select-field">
              <span className="select-value">
                Filter by Manager <i className="icon-down-dir" />
              </span>
            </span>
            <div className="select-dropdown" style={{ display: 'none', boxSizing: 'border-box' }}></div>
          </div>
          <form className="form-search-account">
            <input type="text" className="small-input" placeholder="Search By Account or Project…" />
            <button type="submit" className="small-submit">
              <img src={SearchIcon} className="img-fluid" />
            </button>
          </form>
          <a
            className="btn btn-md green-bg popup_button"
            data-popup="new_team_popup"
            title="New Entry"
            style={{ cursor: 'pointer' }}
            onClick={openAccountDialog}
          >
            <i className="icon-plus mr-1" /> New Account
          </a>
          <a
            className="btn btn-md green-bg"
            title="New Entry"
            style={{ cursor: 'pointer' }}
            onClick={() => showProjectForm(true)}
          >
            <i className="icon-plus mr-1" /> New Project
          </a>
        </div>
      </div>
      <Accounts />
    </>
  );
};
