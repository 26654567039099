/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { useSelectWithParams } from '../../../utils/selector.utils';

import { ConnectedDialog } from '../ConnectedDialog';
import { IDeleteDialogProps } from './types';

export const DeleteDialog = () => {
  const dispatch = useDispatch();
  const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'deleteDialog') as IDeleteDialogProps;

  const onCancel = useCallback(() => {
    if (props.onDeny) {
      props.onDeny();
    }
    dispatch(closeDialog('deleteDialog'));
  }, [dispatch, props]);

  return (
    props && (
      <ConnectedDialog dialogKey="deleteDialog">
        <div className="popup delete_popup isVisible">
          <div className="panel">
            <div className="panel-header small-header flex jcsb">
              <h3> {props.title ? props.title : 'Delete'} </h3>
              <span className="close-popup close" onClick={onCancel}>
                <i className="icon-close"></i>
              </span>
            </div>
            <div className="panel-body">
              <h2 className="mb-5 mt-2 text-center">
                {props.message ? props.message : 'Are you sure you want to delete this item?'}
              </h2>
              <form action="" method="post" className="delete-form flex aic jcse">
                <a
                  className="btn btn-border white-bg mr-2 close"
                  title=""
                  style={{ cursor: 'pointer' }}
                  onClick={onCancel}
                >
                  No
                </a>
                <a style={{ cursor: 'pointer' }} className="btn red-bg white" title="" onClick={props.onConfirm}>
                  Yes
                </a>
              </form>
            </div>
          </div>
        </div>
      </ConnectedDialog>
    )
  );
};
