import { createActionConst } from '../../../../utils/action.utils';

const PROJECT_NAMESPACE = 'PROJECTS';

export const SETUP_PROJECT = createActionConst(PROJECT_NAMESPACE, 'SETUP_PROJECT');

export const CREATE_PROJECT = createActionConst(PROJECT_NAMESPACE, 'CREATE_PROJECT');
export const UPDATE_PROJECT = createActionConst(PROJECT_NAMESPACE, 'UPDATE_PROJECT');
export const DELETE_PROJECT = createActionConst(PROJECT_NAMESPACE, 'DELETE_PROJECT');

export const STORE_IS_FETCHED = createActionConst(PROJECT_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(PROJECT_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_PROJECT = createActionConst(PROJECT_NAMESPACE, 'STORE_PROJECT');
export const CLEAR_PROJECT = createActionConst(PROJECT_NAMESPACE, 'CLEAR_PROJECT');

export const SAVE_PROJECT_SOCKET = 'saveProject';
export const DELETE_PROJECT_SOCKET = 'deleteProject';
