/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { openDialog } from '../../../../../app/dialog/dialog.actions';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import { INavigationBarProps } from './types';
import { Settings } from './Settings';
import { Notifications } from './Notifications';
import { NextMeeting } from './NextMeeting';
import { useSelect } from '../../../../../utils/selector.utils';
import { sessionsPageSelectors } from '../../../../../app/page/page-storage/sessions/sessions.selectors';
import moment from 'moment';

const NavigationBar: FunctionComponent<INavigationBarProps> = ({ showLoader, toggleSideBar, leftArrow, showHide }) => {
  const dispatch = useDispatch();
  const openWidgetsDialogDialog = useCallback(() => {
    dispatch(openDialog('widgetsDialog'));
  }, [dispatch]);
  const currentTime = moment().format('dddd, MM/DD, h:mma');

  const nextSessionId = useSelect(sessionsPageSelectors.selectNextSessionId);
  return (
    <div className="row mb-2">
      <div className="col-12">
        <div className="panel single-line">
          <a className="panel-button shrink-menu" style={{ cursor: 'pointer' }} onClick={toggleSideBar}>
            {showLoader ? (
              <Loader type="TailSpin" color="#1bc3a7" height={40} width={40} />
            ) : (
              <i className={` icon-left-arrow transition2 ${leftArrow ? 'icon-rotated' : ''}`}></i>
            )}
          </a>
          <div className="panel-text ml-2">
            <span className="darkGray">
              <i className="icon-clock mr-05"></i>
              {currentTime}
            </span>
          </div>
          {!showLoader && <NextMeeting sessionId={nextSessionId ? nextSessionId : ''} />}
          <ul className="right-menu">
            {showHide && (
              <li>
                <a
                  className="panel-button no-border popup_button"
                  data-popup="sort_widgets_popup"
                  onClick={openWidgetsDialogDialog}
                >
                  <i className="icon-dots"></i>
                </a>
              </li>
            )}

            <Notifications />
            <Settings />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
