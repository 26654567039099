/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createTask } from '../../../../../app/page/page-storage/tasks/tasks.actions';
import { updateTask } from '../../../../../app/page/page-storage/tasks/tasks.actions';
import { IMeeting } from '../../../../../app/resource/meeting/meeting.types';
import { meetingSelectors } from '../../../../../app/resource/meeting/meeting.selectors';
import { IDefaultTaskType, ITask, ITaskCreateForm } from '../../../../../app/resource/task/task.types';
import { userSelectors } from '../../../../../app/resource/user/user.selectors';
import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { useSelect } from '../../../../../utils/selector.utils';
import { DateTimeInput } from '../../inputs/DateTimeInput';
import { SelectInput } from '../../inputs/SelectInput';
import { FormTextInput } from '../../inputs/TextInput';
import { ITaskFormProps } from './types';
import moment from 'moment';

export const TaskForm: FunctionComponent<ITaskFormProps> = ({ task, userId, meetingId, type }) => {
  const dispatch = useDispatch();
  const formProps = useForm();
  const focusMeetingTuple = formProps.watch('meeting');

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: ITaskCreateForm, type: IDefaultTaskType, taskId: ITask['id'] | undefined) => {
    if (!taskId) {
      dispatch(createTask({ ...data, taskTypeId: type }));
    } else {
      dispatch(updateTask(taskId, { ...data, taskTypeId: type }));
    }
  };

  const users = useSelect(userSelectors.selectResourceList);
  const meetings = useSelect(meetingSelectors.selectResourceList);

  const [selectedMeeting, meetingOptions] = useMemo(() => {
    const mappedMeetings = meetings.map((meeting) => ({
      value: meeting.id,
      label: `${meeting.title}`,
    }));

    let foundMeetings = undefined;
    if (meetingId) {
      foundMeetings = (meetings.filter((team) => team.id === meetingId) as IMeeting[]).map((team) => ({
        value: team.id,
        label: team.title,
      }));
      foundMeetings = foundMeetings.length !== 0 ? foundMeetings : undefined;
    } else if (task) {
      foundMeetings = (meetings.filter((team) => task.meetingIds.includes(team.id)) as IMeeting[]).map((team) => ({
        value: team.id,
        label: team.title,
      }));
      foundMeetings = foundMeetings.length !== 0 ? foundMeetings : undefined;
    }

    return [foundMeetings, mappedMeetings];
  }, [meetingId, meetings, task]);

  const [selectedUser, userOptions] = useMemo(() => {
    const focusMeeting: IMeeting | null | undefined = focusMeetingTuple
      ? meetings.find((meeting) => focusMeetingTuple.value === meeting.id)
      : null;

    const mappedUsers = users
      .filter((user) =>
        focusMeeting
          ? focusMeeting.shareWithIds.includes(user.id) || focusMeeting.administratorIds.includes(user.id)
          : true,
      )
      .map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }));

    let foundUser = undefined;
    if (userId) {
      foundUser = mappedUsers.find((option) => option.value === userId);
    }

    return [foundUser, mappedUsers];
  }, [focusMeetingTuple, meetings, userId, users]);

  const reminderOptions = [
    { value: 'noReminder', label: 'None' },
    { value: 'everyDay', label: 'Every Day' },
    { value: 'everyWeek', label: 'Every Week' },
    { value: 'everyMonth', label: 'Every Month' },
  ];

  const todoStatusOptions = [
    { value: '0', label: 'Open' },
    { value: '2', label: 'On Hold' },
    { value: '3', label: 'Urgent' },
  ];

  const rockStatusOptions = [
    { value: '0', label: 'On Track' },
    { value: '2', label: 'Off Track' },
    { value: '1', label: 'Done' },
  ];

  const defaultReminder = task?.reminder
    ? reminderOptions.filter((option) => option.value === task?.reminder)
    : reminderOptions.filter((option) => option.value === 'noReminder');

  const defaultTodoStatus = todoStatusOptions.filter((option) => option.value === task?.status);
  const defaultRockStatus = rockStatusOptions.filter((option) => option.value === task?.status)[0];

  const defaultValue = task?.dueDate;
  const date = useMemo(() => {
    try {
      if (defaultValue) {
        const dateString = moment(defaultValue).format('YYYY-MM-DD');
        return new Date(dateString);
      } else {
        return new Date();
      }
    } catch (error) {
      console.log(error);
    }
  }, [defaultValue]);

  return (
    <FormProvider {...formProps}>
      <form
        id="task-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as ITaskCreateForm, type, task?.id))}
        method="post"
      >
        <div className="field-container">
          <FormTextInput
            id="title"
            name="title"
            type="text"
            label="Title"
            placeholder="Enter Title"
            defaultValue={task ? task.title : ''}
            validation={requiredValidation}
          />
        </div>
        <div className="field-container">
          <SelectInput
            id="user"
            name="user"
            label="User"
            placeholder="Search..."
            defaultOptions={selectedUser}
            validation={requiredValidation}
            options={userOptions}
            isSingle
          />
        </div>

        <div className="field-container">
          <SelectInput
            id="meeting"
            name="meeting"
            label="Meeting"
            placeholder="Search..."
            defaultOptions={selectedMeeting}
            validation={requiredValidation}
            options={meetingOptions}
          />
        </div>
        {type !== '3' && (
          <div className="field-container">
            <DateTimeInput
              id="dueDate"
              name="dueDate"
              label="Due Date"
              placeholder="Enter Start Date"
              defaultValue={date}
              validation={requiredValidation}
            />
          </div>
        )}

        {/*<div className="field-container error">
          <label>
            Status
            <span className="error-msg">Something is wrong.</span>
          </label>
          <div className="field-container custom-select with-checkbox">
            <input type="hidden" />
            <span className="select-field">
              <span className="select-value">Select</span> <i className="icon-down-dir" />
            </span>
            <div className="select-dropdown">
              <span className="select-item selected">
                <span className="d-inline-block badge lightBlue-bg blue" data-val="on track">
                  Open
                </span>
              </span>
              <span className="select-item">
                <span className="d-inline-block badge lightRed-bg red" data-val="off track">
                  Urgent
                </span>
              </span>
              <span className="select-item">
                <span className="d-inline-block badge lightYellow-bg yellow" data-val="done">
                  <i className="icon-check" /> On Hold
                </span>
              </span>
            </div>
          </div>
        </div>
        <div>
          <a className="btn btn-sm lightGray-bg darkGray btn-border mx-0 d-inline-flex mb-3 custom_status">
            <i className="icon-plus" /> Custom status
          </a>
        </div>*/}
        {type === '5' && (
          <div className="field-container custom-select with-checkbox">
            <SelectInput
              id="status"
              name="status"
              label="Status"
              placeholder="Select..."
              options={todoStatusOptions}
              validation={requiredValidation}
              defaultOptions={defaultTodoStatus}
              isSingle
            />
          </div>
        )}
        {/* {type === '3' && (
          <div className="field-container custom-select with-checkbox">
            <SelectInput
              id="status"
              name="status"
              label="Status"
              placeholder="Select..."
              options={rockStatusOptions}
              validation={requiredValidation}
              defaultOptions={defaultRockStatus}
              isSingle
            />
          </div>
        )} */}
        {type === '1' && (
          <div className="field-container custom-select with-checkbox">
            <SelectInput
              id="status"
              name="status"
              label="Status"
              placeholder="Select..."
              options={rockStatusOptions}
              validation={requiredValidation}
              defaultOptions={defaultRockStatus}
              isSingle
            />
          </div>
        )}

        <div className="field-container">
          <SelectInput
            id="reminder"
            name="reminder"
            label="Reminder"
            placeholder="Select..."
            options={reminderOptions}
            defaultOptions={defaultReminder}
            isSingle
          />
        </div>

        <div className="field-container">
          <FormTextInput
            id="description"
            name="description"
            label="Description"
            placeholder="Enter Description"
            defaultValue={task ? task.description : ''}
            multi
          />
        </div>
      </form>
    </FormProvider>
  );
};
