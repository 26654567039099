import { Reducer } from 'redux';

import * as types from './page-state.types';
import * as constants from './page-state.constants';

const initialPageState: types.IPageResolvedState = {
  inProgress: false,
  error: null,
};

const initialState: types.IPageStateState = {
  home: initialPageState,
  liveMeeting: initialPageState,
  meetingDashboard: initialPageState,
  sideBar: initialPageState,
  meetings: initialPageState,
  users: initialPageState,
  tasks: initialPageState,
  kpi: initialPageState,
  vto: initialPageState,
  chart: initialPageState,
  boards: initialPageState,
  boardLists: initialPageState,
  checkLists: initialPageState,
  checklistItems: initialPageState,
  activities: initialPageState,
  task: initialPageState,
  passwords: initialPageState,
  timeTracking: initialPageState,
  projectTasks: initialPageState,
  projectMembers: initialPageState,
  projects: initialPageState,
  projectTimes: initialPageState,
  accountProjects: initialPageState,
  quartals: initialPageState,
  agencyTools: initialPageState,
  categories: initialPageState,
  meetingsInfo: initialPageState,
};

export const pageStateReducer: Reducer<types.IPageStateState, types.IPageStateAction> = (
  state = initialState,
  action,
) => {
  if (action.type === constants.STORE_PAGE_STATE_IN_PROGRESS) {
    const key = (action as types.IStorePageStateInProgress).payload;

    return {
      ...state,
      [key]: {
        ...state[key],
        inProgress: true,
      },
    };
  } else if (action.type === constants.CLEAR_PAGE_STATE_IN_PROGRESS) {
    const key = (action as types.IClearPageStateInProgress).payload;

    return {
      ...state,
      [key]: {
        ...state[key],
        inProgress: false,
      },
    };
  } else if (action.type === constants.STORE_PAGE_STATE_ERROR) {
    const { key, error } = (action as types.IStorePageStateError).payload;

    return {
      ...state,
      [key]: {
        ...state[key],
        error,
      },
    };
  } else if (action.type === constants.CLEAR_PAGE_STATE_ERROR) {
    const key = (action as types.IClearPageStateError).payload;

    return {
      ...state,
      [key]: {
        ...state[key],
        error: null,
      },
    };
  }

  return state;
};
