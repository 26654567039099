import { call } from 'redux-saga/effects';

import * as pageStateService from '../page-state/page-state.saga-services';
import * as pageInitializerService from '../page.initializer.saga-service';
import * as passwordService from '../page-storage/passwords/passwords.saga-services';
import * as passwordGroupService from '../page-storage/password-groups/password-groups.saga-services';
import { IPasswordSearchParams } from '../../resource/password/password.types';

export function* initialPasswordsPage(searchParams: IPasswordSearchParams) {
  yield call(pageStateService.initPage, 'passwords');
  if (!((yield call(pageInitializerService.initPage)) as boolean)) {
    yield call(passwordService.setupPasswords, searchParams);
    yield call(passwordGroupService.getPasswordGroups, searchParams);
  }
  yield call(pageStateService.completedInit, 'passwords');
}
