import { take, put, call, fork, all } from 'redux-saga/effects';
import { EventChannel } from 'redux-saga';
import { createPusherChannel, IPusherAction } from './pusher.connection';
// import { TASK_SOCKET_CONFIG } from '../socket.configs';
import { SOCKET_CONFIG } from '../socket.configs';

export function* watchOnPusherPings() {
  const socketChannel = (yield call(createPusherChannel, SOCKET_CONFIG.channel, SOCKET_CONFIG.actions)) as EventChannel<
    IPusherAction
  >;

  while (true) {
    try {
      // An error from socketChannel will cause the saga jump to the catch block
      const action = (yield take(socketChannel)) as IPusherAction;
      yield put(action);
      // yield fork(pusher);
    } catch (err) {
      console.error('socket error:', err);
      // socketChannel is still open in catch block
      // if we want end the socketChannel, we need close it explicitly
      // socketChannel.close()
    }
  }
}

export function* pusherSagaWatcher() {
  yield all([watchOnPusherPings].map(fork));
}
