import { call, put, select } from 'redux-saga/effects';

import * as actions from './quartals.actions';

import { IQuartalCreate, IQuartalCreateForm, IQuartal } from '../../../resource/quartal/quartal.types';
import * as dialogService from '../../../dialog/dialog.saga-services';
import { quartalsPageSelectors } from './quartals.selectors';
import * as quartalResourceService from '../../../resource/quartal/quartal.saga-service';

export function* clearQuartals() {
  yield put(actions.clearQuartal());
}

export function* setupQuartals() {
  const quartals = (yield call(quartalResourceService.searchQuartals)) as IQuartal['id'][];

  yield put(quartals ? actions.storeQuartals(quartals) : actions.clearQuartal());
}
export function* createQuartal(quartalInfo: IQuartalCreateForm) {
  const quartal = (yield call(
    quartalResourceService.createQuartal,
    mapQuartalFormToQuartalCreate(quartalInfo),
  )) as IQuartal;

  if (!quartal) {
    return;
  }
  const quartalsIds = yield select(quartalsPageSelectors.selectQuartals);

  yield put(quartalsIds ? actions.storeQuartals([...quartalsIds, quartal.id]) : actions.clearQuartal());

  yield call(dialogService.closeDialog, 'quartalFormDialog');
}

export function* updateQuartal(quartalId: IQuartal['id'], quartalInfo: IQuartalCreateForm) {
  const quartal = (yield call(quartalResourceService.updateQuartal, {
    id: quartalId,
    ...mapQuartalFormToQuartalCreate(quartalInfo),
  })) as IQuartal;

  if (!quartal) {
    return;
  }
  const quartalsIds = yield select(quartalsPageSelectors.selectQuartals);

  yield put(quartalsIds ? actions.storeQuartals([...quartalsIds]) : actions.clearQuartal());
  yield call(dialogService.closeDialog, 'quartalFormDialog');
}

export function* deleteQuartal(quartalId: IQuartal['id']) {
  yield call(quartalResourceService.deleteQuartal, quartalId);

  const quartalsIds: IQuartal['id'][] = yield select(quartalsPageSelectors.selectQuartals);

  yield put(quartalsIds ? actions.storeQuartals(quartalsIds.filter((id) => quartalId !== id)) : actions.clearQuartal());
}

export const mapQuartalFormToQuartalCreate = (quartalInfo: IQuartalCreateForm): IQuartalCreate => ({
  startQuartal1: quartalInfo.startQuartal1,
  startQuartal2: quartalInfo.startQuartal2,
  startQuartal3: quartalInfo.startQuartal3,
  startQuartal4: quartalInfo.startQuartal4,
  endQuartal4: quartalInfo.endQuartal4,
  year: quartalInfo.year,
});
export function* clearQuartalsPageStorage() {
  yield put(actions.clearQuartal());
}
