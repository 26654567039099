import React, { FunctionComponent, useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { BoardBodyTasks } from '..';
import { generateBoardListId } from '../../../../../../../app/page/page-storage/tasks/tasks.saga-services';
import { boardListSelectors } from '../../../../../../../app/resource/boards/boardList/boardList.selectors';
import { useSelectWithParams } from '../../../../../../../utils/selector.utils';
import { useToggler } from '../../../../../../../utils/toggler.utils';
import { BoardBodyHeader } from './BoardBodyHeader';
import { IBoardListProps } from './types';

export const BoardList: FunctionComponent<IBoardListProps> = ({ meetingId, boardListId, virtualFlag, isAdmin }) => {
  const activeBoardListId = useMemo(
    () => generateBoardListId(meetingId, 'groupByNonDefaultTypes', { taskTypeId: boardListId }, 'active'),
    [boardListId, meetingId],
  );
  const doneBoardListId = useMemo(
    () => generateBoardListId(meetingId, 'groupByNonDefaultTypes', { taskTypeId: boardListId }, 'done'),
    [boardListId, meetingId],
  );
  const activeBoardList = useSelectWithParams(boardListSelectors.selectResourceById, activeBoardListId);
  const doneBoardList = useSelectWithParams(boardListSelectors.selectResourceById, doneBoardListId);
  const originalBoardList = useSelectWithParams(boardListSelectors.selectResourceById, boardListId);

  const [show, handleShow] = useToggler(false);
  // useEffect(() => {
  //   console.log('dnd');
  // }, [activeTaskIds, doneTaskIds]);

  // HOT FIX
  const bl = useSelectWithParams(boardListSelectors.selectResourceById, boardListId);
  //console.log(activeBoardList, bl);

  return (
    activeBoardList && (
      <div className="single-card-container">
        <div className="panel-body px-0">
          <div className="border-radius-1 lightGray-bg px-2 pb-1">
            {originalBoardList && <BoardBodyHeader boardListData={originalBoardList} meetingId={meetingId} />}
            {activeBoardList.tasksIds && (
              <div>
                {/* <BoardListDragDropContext> */}
                <Droppable droppableId={activeBoardList.id}>
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {bl && <BoardBodyTasks tasksIds={activeBoardList.tasksIds} meetingId={meetingId} />}
                      {!activeBoardList.tasksIds.length && <div style={{ height: '20px' }}></div>}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                {doneBoardList && (
                  <div className="hidden_cards_container">
                    <span className="card-separator hide-cards" onClick={handleShow}>
                      <span className="completed_text">
                        {show
                          ? `HIDE COMPLETED (${doneBoardList.tasksIds.length})`
                          : `SHOW COMPLETED (${doneBoardList.tasksIds.length})`}
                      </span>
                    </span>
                    {show && (
                      <Droppable droppableId={doneBoardList.id}>
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            <BoardBodyTasks tasksIds={doneBoardList.tasksIds} meetingId={meetingId} />
                            {!doneBoardList.tasksIds.length && <div style={{ height: '20px' }}></div>}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    )}
                  </div>
                )}
                {/* </BoardListDragDropContext> */}
              </div>
            )}
            {/* <BoardBodyFooter boardListId={boardList.id} meetingId={meetingId} virtualFlag={virtualFlag} /> */}
          </div>
        </div>
      </div>
    )
  );
};
