import { createActionConst } from '../../../../utils/action.utils';

const BOARD_LIST_NAMESPACE = 'BOARD_LISTS';

export const CREATE_BOARD_LIST = createActionConst(BOARD_LIST_NAMESPACE, 'CREATE_BOARD_LIST');
export const UPDATE_BOARD_LIST = createActionConst(BOARD_LIST_NAMESPACE, 'UPDATE_BOARD_LIST');
export const DELETE_BOARD_LIST = createActionConst(BOARD_LIST_NAMESPACE, 'DELETE_BOARD_LIST');
export const MOVE_BOARD_LIST = createActionConst(BOARD_LIST_NAMESPACE, 'MOVE_BOARD_LIST');

export const STORE_IS_FETCHED = createActionConst(BOARD_LIST_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(BOARD_LIST_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_BOARD_LIST = createActionConst(BOARD_LIST_NAMESPACE, 'STORE_BOARD_LIST');
export const CLEAR_BOARD_LIST = createActionConst(BOARD_LIST_NAMESPACE, 'CLEAR_BOARD_LIST');

export const INITIALIZE_TASK_PROPS = createActionConst(BOARD_LIST_NAMESPACE, 'INITIALIZE_TASK_PROPS');
export const CLEAR_TASK_PROPS = createActionConst(BOARD_LIST_NAMESPACE, 'CLEAR_TASK_PROPS');

export const CREATE_TASK = createActionConst(BOARD_LIST_NAMESPACE, 'CREATE_TASK');
export const UPDATE_TASK = createActionConst(BOARD_LIST_NAMESPACE, 'UPDATE_TASK');
export const DELETE_TASK = createActionConst(BOARD_LIST_NAMESPACE, 'DELETE_TASK');

export const MOVE_TASK = createActionConst(BOARD_LIST_NAMESPACE, 'MOVE_TASK');
export const COPY_TASK = createActionConst(BOARD_LIST_NAMESPACE, 'COPY_TASK');

export const SAVE_BOARD_LIST_SOCKET = 'saveTaskType';
export const DELETE_BOARD_LIST_SOCKET = 'deleteTaskType';

export const SAVE_TASK_SOCKET = 'saveTask';
export const MOVE_TASK_SOCKET = 'moveTask';
export const COPY_TASK_SOCKET = 'copyTask';
