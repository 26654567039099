import { all, call, put } from 'redux-saga/effects';

import * as types from './event.types';
import * as actions from './event.actions';
import * as api from '../../api/request-config/event.api';

import * as calendarItemService from '../calendar-item/calendar-item.saga-service';

import { apiRequest } from '../../api/api.saga-services';
import { select } from '../../../utils/selector.utils';
import { eventSelectors } from './event.selectors';

export function* storeEvent(event: types.IEvent) {
  yield put(actions.storeEvent(event));
  yield call(calendarItemService.storeEventToCalendarItem, event);
}

export function* clearEvent(event: types.IEvent['id']) {
  yield put(actions.clearEvent(event));
}
export function* clearAllEvents() {
  const eventIds: types.IEvent['id'][] = yield select(eventSelectors.selectResourceIds);
  yield put(actions.clearEvent(eventIds));
}

export function* getEvents(params: types.IEventSearchParams) {
  const eventResponses: types.IEventResponse[] = yield call(apiRequest, api.getEventsApi(params));
  if (!eventResponses) {
    return;
  }

  const events: types.IEvent[] = eventResponses.map((event) => mapEventRepsonseToEvent(event) as types.IEvent);
  yield all(events.map((event) => call(storeEvent, event as types.IEvent)));
  yield all(events.map((event) => call(calendarItemService.storeEventToCalendarItem, event)));
  return eventResponses.map((event) => event.id);
}

const mapEventRepsonseToEvent = (eventResponse: types.IEventResponse) => ({
  id: eventResponse.id,
  calendarId: eventResponse.calendarId,
  color: eventResponse.colorId,
  description: eventResponse.description,
  summary: eventResponse.summary,
  start: eventResponse.start.dateTime,
  end: eventResponse.end.dateTime,
});

// const reducedEventIdsByCalendarId = events.reduce((prev, curr) => {
//   const forReturn = { ...prev };

//   if (!forReturn[curr.calendarId]) {
//     forReturn[curr.calendarId] = [];
//   }

//   forReturn[curr.calendarId] = [...forReturn[curr.calendarId], curr.id];

//   return forReturn;
// }, {} as { [key: string]: types.IEvent['id'][] });

// yield all(
//   Object.keys(reducedEventIdsByCalendarId).map((calendarId) =>
//     call(calendarService.addEvents, calendarId, reducedEventIdsByCalendarId[calendarId]),
//   ),
// );
