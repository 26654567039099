/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';
import { IMemberOnAtInputProps } from './types';

export const MemberOnAtInput: FunctionComponent<IMemberOnAtInputProps> = ({
  userId,
  image,
  firstName,
  lastName,
  onClick,
}) => {
  const onMemberClick = useCallback(() => {
    onClick(`${firstName.replaceAll(' ', '-')}-${lastName.replaceAll(' ', '-')}`);
  }, [firstName, lastName, onClick]);

  return (
    <div className="one-member" onClick={onMemberClick}>
      <img src={image} alt="" className="small-avatar img-fluid" />
      {`${firstName} ${lastName}`}
    </div>
  );
};
