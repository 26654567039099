/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import Loader from 'react-loader-spinner';
import { useApiRequestState } from '../../../../../../../../../../utils/api.utils';

import { Member } from './Member';
import { IMembersMenuProps } from './types';

export const MembersMenu: FunctionComponent<IMembersMenuProps> = ({ userIds, dropRight, closeMenu, taskId }) => {
  const [showLoader] = useApiRequestState('updateTaskApi');

  return (
    <div className={dropRight ? 'dropdown-menu drop-right' : 'dropdown-menu'}>
      <div className="mini-popup">
        <div className="mini-popup-header">
          Members
          <span className="close_mini_popup" onClick={closeMenu}>
            ×
          </span>
        </div>
        <div className="mini-popup-body">
          <input type="text" className="small-input" placeholder="Search members" />
          <label className="mb-1 mt-1 d-block">Board members</label>
          {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
          {!showLoader && (
            <div className="list-members">
              {userIds.map((userId) => (
                <Member userId={userId} key={`memberId-${userId}`} taskId={taskId} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
