import { ICurrentAgendaIndex } from '../../page/page-storage/agenda-items/agenda-items.types';
import { IAgendaItemSearchParams } from '../../resource/agenda-item/agenda-item.types';
import { IApiRequestConfig } from '../api.types';

export const searchAgendaItemApi = (params?: IAgendaItemSearchParams): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'searchAgendaItemApi',
  uri: 'json',
  action: 'getAgendaItems',
  method: 'get',
  params: {
    ...params,
  },
});

export const moveToNextAgenda = (params: ICurrentAgendaIndex): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'moveToNextAgendaApi',
  uri: 'json',
  action: 'moveToNextAgenda',
  method: 'post',
  params: {
    ...params,
  },
});
