/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../app/dialog/dialog.actions';
import { IAgencyDescriptionDialogProps } from '../../../../dialogs/AgencyDescriptionDialog/types';
import { IItemHeaderProps } from './types';

export const ItemHeader: FunctionComponent<IItemHeaderProps> = ({ title, description }) => {
  const dispatch = useDispatch();
  const openAgencyDescriptionDialog = useCallback(() => {
    dispatch(openDialog('agencyDescriptionDialog', { title, description } as IAgencyDescriptionDialogProps));
  }, [description, dispatch, title]);
  return (
    <div className="panel-header no-border">
      <h2 className="flex aic bold">
        {title}
        <span
          className="flex link aic ml-05 popup_button"
          data-popup="tooltip_popup"
          data-tooltip_text={`${description}`}
          data-tooltip_title={`${title}`}
        >
          <i className="icon-info blueGray f-10" onClick={openAgencyDescriptionDialog} />
        </span>
      </h2>
      <div className="ml-auto flex aic">
        <a className="btn btn-md btn-border white-bg" title="Print">
          <i className="icon2-print mr-1 f-0 blueGray" />
          Print
        </a>
        <a className="btn btn-md darkGray-bg" title="Export">
          Export
        </a>
      </div>
    </div>
  );
};
