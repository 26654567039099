/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../app/dialog/dialog.actions';
import { createMeeting } from '../../../../app/page/page-storage/meetings/meetings.actions';
import { meetingSelectors } from '../../../../app/resource/meeting/meeting.selectors';
import { IMeetingCreateForm, IMeetingNewCreateForm } from '../../../../app/resource/meeting/meeting.types';
import { useSelect } from '../../../../utils/selector.utils';
import { MeetingRow } from './MeetingRow';
import { IMeetingTableProps } from './types';

export const MeetingTable: FunctionComponent<IMeetingTableProps> = ({ type, sortType }) => {
  const dispatch = useDispatch();

  // const reducedMeetings = useSelect(meetingsPageSelectors.selectReducedMeetings);

  // const meetingIds = useMemo(() => reducedMeetings[type], [reducedMeetings, type]);

  const openMeetingForm = useCallback(() => {
    dispatch(
      openDialog('meetingFormDialog', {
        type: type,
        onSubmit: (meeting: IMeetingCreateForm) => {
          meeting.type = type;
          // hot fix
          dispatch(createMeeting(meeting as IMeetingNewCreateForm));
          //dispatch(createMeeting(meeting));
        },
      }),
    );
  }, [dispatch, type]);

  const meetings = useSelect(meetingSelectors.selectResourceList);
  const meetingsByType = useMemo(() => meetings.filter((meeting) => meeting.type === type), [meetings, type]);
  const meetingsSorted = sortType
    ? [...meetingsByType].sort((a, b) => a?.title.localeCompare(b?.title))
    : [...meetingsByType].sort((a, b) => b?.title.localeCompare(a?.title));
  return (
    <div className="accounts-list panel-body pt-0">
      <div className="border-radius-1 lightGray-bg p-2">
        <div className="table-container" role="table" aria-label="Destinations">
          <div className="flex-table header" role="rowgroup">
            <div className="flex-row first" role="columnheader">
              {type === 'account' ? 'Account Name' : 'Team Name'}
            </div>
            <div className="flex-row" role="columnheader">
              Members
            </div>
            <div className="flex-row" role="columnheader">
              Next Meeting
            </div>
            <div className="flex-row" role="columnheader"></div>
          </div>
          {meetingsSorted && meetingsSorted.map((meeting) => <MeetingRow key={meeting.id} meetingId={meeting.id} />)}
          <a
            className="link panel text-center green flex aic jcc green button_popup"
            data-popup="new_team_popup"
            title={`Add a New ${type === 'account' ? 'Account' : 'Team'}`}
            onClick={openMeetingForm}
          >
            Add a New {type === 'account' ? 'Account' : 'Team'}
          </a>
        </div>
      </div>
    </div>
  );
};
