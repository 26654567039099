/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';

import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';

import { useSelectWithParams } from '../../../utils/selector.utils';

import { ConnectedDialog } from '../ConnectedDialog';

import { IAgencyDescriptionDialogProps } from './types';

export const AgencyDescriptionDialog: FunctionComponent = () => {
  const dispatch = useDispatch();

  const props = useSelectWithParams(
    dialogSelectors.selectDialogProps,
    'agencyDescriptionDialog',
  ) as IAgencyDescriptionDialogProps;

  const onClose = useCallback(() => {
    dispatch(closeDialog('agencyDescriptionDialog'));
  }, [dispatch]);

  return (
    <ConnectedDialog dialogKey="agencyDescriptionDialog">
      <div className="popup tooltip_popup" style={{ display: 'block' }}>
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            <h3>
              <i className="icon-info blueGray f-1" /> <span id="tooltip-title">{props && props.title}</span>
            </h3>
            <span className="close-popup close" onClick={onClose}>
              <i className="icon-close" />
            </span>
          </div>
          <div className="panel-body pt-3 pb-5">
            <p id="tooltip-text">{props && props.description}</p>
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
