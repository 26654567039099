import React, { useMemo } from 'react';
import { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createBoard, updateBoard } from '../../../../../app/page/page-storage/boards/boards.actions';
import { meetingsPageSelectors } from '../../../../../app/page/page-storage/meetings/meetings.selectors';
import { IBoard, IBoardCreateForm } from '../../../../../app/resource/boards/board/board.types';
import { meetingSelectors } from '../../../../../app/resource/meeting/meeting.selectors';

import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { useSelect } from '../../../../../utils/selector.utils';
import { ColorInput } from '../../inputs/ColorInput';
import { SelectInput } from '../../inputs/SelectInput';
import { FormTextInput } from '../../inputs/TextInput';
import { IBoardFormProps } from './types';

export const BoardForm: FunctionComponent<IBoardFormProps> = ({ boardData }) => {
  const dispatch = useDispatch();
  const currentMeetingId = useSelect(meetingsPageSelectors.selectCurrentMeeting);
  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: IBoardCreateForm, boardId?: IBoard['id']) => {
    if (boardId && boardData) {
      dispatch(updateBoard(boardId, { ...boardData, ...data }));
    } else {
      dispatch(createBoard({ ...data }));
    }
  };

  const meetings = useSelect(meetingSelectors.selectResourceList);

  const options = useMemo(
    () =>
      meetings.map((meeting) => ({
        value: meeting.id,
        label: meeting.title,
      })),
    [meetings],
  );

  const meetingsDefaultOptions = useMemo(
    () =>
      boardData
        ? options.filter((option) => boardData.meetingIds.includes(option.value))
        : currentMeetingId
        ? options.filter((option) => currentMeetingId === option.value)
        : undefined,
    [boardData, currentMeetingId, options],
  );
  /*
  
  

  const defaultUser = useMemo(
    () => (nodeData ? options.find((option) => nodeData.userId === option.value) : undefined),
    [options],
  );
*/
  return (
    <FormProvider {...formProps}>
      <form
        id="board-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IBoardCreateForm, boardData?.id))}
        method="post"
      >
        <div className="field-container">
          <FormTextInput
            id="name"
            name="name"
            type="text"
            label="Board Title"
            placeholder="Enter Title"
            defaultValue={boardData ? boardData.name : ''}
            validation={requiredValidation}
          />
        </div>
        <div className="field-container">
          <SelectInput
            id="meetingIds"
            name="meetingIds"
            label="Meetings"
            placeholder="Search..."
            validation={requiredValidation}
            options={options}
            defaultOptions={meetingsDefaultOptions}
          />
        </div>
        <div className="popup-upload-panel">
          <ColorInput
            id="color"
            name="color"
            placeholder="Board Tab Color"
            validation={requiredValidation}
            defaultValue={boardData?.color}
          />
        </div>
      </form>
    </FormProvider>
  );
};
