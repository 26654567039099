import React from 'react';
import { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createBoardList, updateBoardList } from '../../../../../app/page/page-storage/boardLists/boardLists.actions';

import { IBoardList, IBoardListCreateForm } from '../../../../../app/resource/boards/boardList/boardList.types';

import { useRequiredValidation } from '../../../../../utils/form/validation.utils';

import { FormTextInput } from '../../inputs/TextInput';
import { IBoardListFormProps } from './types';

export const BoardListForm: FunctionComponent<IBoardListFormProps> = ({ boardListData, boardId }) => {
  const dispatch = useDispatch();

  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: IBoardListCreateForm, boardListId?: IBoardList['id']) => {
    if (boardListId && boardListData) {
      dispatch(updateBoardList(boardListId, { ...boardListData, ...data }));
    } else {
      data.boardId = boardId;

      dispatch(createBoardList({ ...data }));
    }
  };

  return (
    <FormProvider {...formProps}>
      <form
        id="boardList-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IBoardListCreateForm, boardListData?.id))}
        method="post"
      >
        <div className="field-container">
          <FormTextInput
            id="name"
            name="name"
            type="text"
            label="List Title"
            placeholder="Enter Title"
            defaultValue={boardListData ? boardListData.name : ''}
            validation={requiredValidation}
          />
        </div>
      </form>
    </FormProvider>
  );
};
