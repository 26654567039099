import { combineReducers, Reducer } from 'redux';

import { isActionOfType } from '../../../../utils/action.utils';

import * as types from './sessions.types';
import * as constants from './sessions.constants';

const initialState: types.ISessionsPageState = {
  sessionIds: [],
  nextSessionId: null,
};

const sessionIdsReducer: Reducer<
  types.ISessionsPageState['sessionIds'],
  types.IStoreFetchedSessions | types.IClearFetchedSessions
> = (state = initialState.sessionIds, action) => {
  if (isActionOfType<types.IStoreFetchedSessions>(action, constants.STORE_SESSION_IDS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearFetchedSessions>(action, constants.CLEAR_SESSION_IDS)) {
    return initialState.sessionIds;
  }
  return state;
};

const nextSessionIdReducer: Reducer<
  types.ISessionsPageState['nextSessionId'],
  types.IStoreNextSession | types.IClearNextSession
> = (state = initialState.nextSessionId, action) => {
  if (isActionOfType<types.IStoreNextSession>(action, constants.STORE_NEXT_SESSION)) {
    return action.payload;
  } else if (isActionOfType<types.IClearNextSession>(action, constants.CLEAR_NEXT_SESSION)) {
    return initialState.nextSessionId;
  }
  return state;
};

export const sessionsPageReducer: Reducer<types.ISessionsPageState, types.ISessionsPageAction> = combineReducers({
  sessionIds: sessionIdsReducer,
  nextSessionId: nextSessionIdReducer,
});
