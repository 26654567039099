import { call, put, select } from 'redux-saga/effects';

import * as actions from './meetings.actions';

import * as meetingResourceService from '../../../resource/meeting/meeting.saga-service';
import * as dialogService from '../../../dialog/dialog.saga-services';

import {
  IMeetingSearchParams,
  IMeeting,
  MeetingType,
  IMeetingResponse,
  IMeetingNewCreateForm,
} from '../../../resource/meeting/meeting.types';
import { meetingsPageSelectors } from './meetings.selectors';
import { IReducedMeetings } from './meetings.types';
import { meetingSelectors } from '../../../resource/meeting/meeting.selectors';
import { IUser } from '../../../resource/user/user.types';
import { ISession } from '../../../resource/session/session.types';
import { selectWithParams } from '../../../../utils/selector.utils';
import { storeAgendaItems } from '../agenda-items/agenda-items.saga-services';
import { userFormSelectors } from '../users/user-form/user-form.selectors';

// import * as apiService from '../../api/api.saga-services';

export function* reduceAndStoreMeetings(meetings: IMeeting[]) {
  if (!meetings) {
    return;
  }
  yield put(
    actions.storeReducedMeetings(
      meetings.reduce((prev, curr) => {
        if (!prev[curr.type]) {
          prev[curr.type] = [];
        }
        prev[curr.type] = [...prev[curr.type], curr.id];
        return { ...prev };
      }, {} as IReducedMeetings),
    ),
  );
}

export function* clearReducedMeetings() {
  yield put(actions.clearReducedMeetings());
}

export function* storeCurrentMeeting(currentMeeting: IMeeting['id']) {
  yield put(currentMeeting ? actions.storeCurrentMeeting(currentMeeting) : actions.clearCurrentMeeting());
}

export function* setupMeetings(searchParams?: IMeetingSearchParams) {
  const isFetched = (yield select(meetingsPageSelectors.selectIsFetched)) as boolean;

  let meetings: IMeeting[] = [];

  if (!isFetched) {
    meetings = (yield call(meetingResourceService.searchMeetings, searchParams)) as IMeeting[];
    yield put(meetings ? actions.storeIsFetched() : actions.clearIsFetched());
  } else {
    meetings = yield select(meetingSelectors.selectResourceList);
  }
  yield call(reduceAndStoreMeetings, meetings);
}

export function* putFilteredMeetingsByUserId(userId: IUser['id']) {
  const meetings = (yield select(meetingSelectors.selectResourceList)) as IMeeting[];
  yield call(
    reduceAndStoreMeetings,
    meetings.filter((meeting) => meeting.shareWithIds.includes(userId) || meeting.administratorIds.includes(userId)),
  );
}

export function* putFilteredMeetingsByType(type: MeetingType) {
  const meetings = (yield select(meetingSelectors.selectResourceList)) as IMeeting[];
  yield call(
    reduceAndStoreMeetings,
    meetings.filter((meeting) => meeting.type === type),
  );
}

export function* putMeetings() {
  const meetings = (yield select(meetingSelectors.selectResourceList)) as IMeeting[];
  yield call(reduceAndStoreMeetings, meetings);
}
/*
export function* createMeeting(meeting: IMeetingCreateForm) {
  const agendaItemIds = (yield select(agendaItemsPageSelectors.selectAgendaItems)) as IAgendaItem['id'][];
  const agendaItems = (yield selectWithParams(agendaItemSelectors.selectResourcesById, agendaItemIds)) as IAgendaItem[];

  if (agendaItemIds.length !== meeting.agendaItems.length) {
    return;
  }

  const createdMeeting = (yield call(meetingResourceService.createMeeting, {
    ...meeting,
    repeatEvery: meeting.repeatEvery === '' ? '7' : meeting.repeatEvery,
    repeatType: meeting.repeatType.value,
    startTime: meeting.startTime.value,
    endTime: meeting.endTime.value,
    administrators: meeting.administrators.map((tupple) => tupple.value),
    shareWith: meeting.shareWith.map((tupple) => tupple.value),
    agendaItems: meeting.agendaItems.map((agendaDuration, index) => ({
      duration: agendaDuration.duration,
      sort: index,
      title: agendaDuration.title,
      agendaId: agendaItems[index].agendaId,
    })),
  })) as IMeeting;

  if (!createdMeeting) {
    return;
  }

  // const reducedMeetings = (yield select(meetingsPageSelectors.selectReducedMeetings)) as IReducedMeetings;
  // reducedMeetings[createdMeeting.type] = [...reducedMeetings[createdMeeting.type], createdMeeting.id];

  // yield put(actions.storeReducedMeetings({ ...reducedMeetings }));

  yield call(dialogService.closeDialog, 'meetingFormDialog');
}
*/
export function* createNewMeeting(meeting: IMeetingNewCreateForm) {
  const createdMeeting = (yield call(meetingResourceService.createMeeting, {
    ...meeting,
    repeatEvery: meeting.repeatEvery === '' ? '7' : meeting.repeatEvery,
    repeatType: meeting.repeatType.value,
    startTime: meeting.startTime.value,
    endTime: meeting.endTime.value,
    administrators: meeting.administrators.map((tupple) => tupple.value),
    shareWith: meeting.shareWith.map((tupple) => tupple.value),
    agendaItems: meeting.agendaItems
      ? meeting.agendaItems.length > 0
        ? meeting.agendaItems.map((agendaItem, index) => ({
            duration: agendaItem.duration,
            sort: index,
            title: agendaItem.title,
            checked: agendaItem.checked,
            agendaId: agendaItem.agendaId,
          }))
        : []
      : [],
  })) as IMeetingResponse;

  if (!createdMeeting) {
    return;
  }

  yield call(dialogService.closeDialog, 'meetingFormDialog');
}
/*
export function* updateMeeting(id: IMeeting['id'], meeting: IMeetingCreateForm) {
  const agendaItemIds = (yield select(agendaItemsPageSelectors.selectAgendaItems)) as IAgendaItem['id'][];
  const agendaItems = (yield selectWithParams(agendaItemSelectors.selectResourcesById, agendaItemIds)) as IAgendaItem[];

  if (agendaItems.length !== meeting.agendaItems.length) {
    return;
  }

  const updatedMeeting = (yield call(meetingResourceService.updateMeeting, {
    id,
    ...meeting,
    repeatType: meeting.repeatType.value,
    startTime: meeting.startTime.value,
    endTime: meeting.endTime.value,
    administrators: meeting.administrators.map((tupple) => tupple.value),
    shareWith: meeting.shareWith.map((tupple) => tupple.value),
    agendaItems: meeting.agendaItems.map((agendaDuration, index) => ({
      duration: agendaDuration.duration,
      title: agendaDuration.title,
      sort: index,
      agendaId: agendaItems[index].agendaId,
    })),
  })) as IMeeting;

  if (!updatedMeeting) {
    return;
  }
  yield call(dialogService.closeDialog, 'meetingFormDialog');
}*/

export function* updateNewMeeting(id: IMeeting['id'], meeting: IMeetingNewCreateForm) {
  /*
  const agendaItemIds = (yield select(agendaItemsPageSelectors.selectAgendaItems)) as IAgendaItem['id'][];
  const agendaItems = (yield selectWithParams(agendaItemSelectors.selectResourcesById, agendaItemIds)) as IAgendaItem[];

  if (agendaItems.length !== meeting.agendaItems.length) {
    return;
  }
*/
  const imageData = (yield select(userFormSelectors.selectUserAvatar)) as string;
  const image = imageData !== '' ? imageData : meeting.image;

  const updatedMeeting = (yield call(meetingResourceService.updateMeeting, {
    id,
    ...meeting,
    repeatType: meeting.repeatType.value,
    startTime: meeting.startTime.value,
    endTime: meeting.endTime.value,
    administrators: meeting.administrators.map((tupple) => tupple.value),
    shareWith: meeting.shareWith.map((tupple) => tupple.value),
    image: image,
    agendaItems:
      meeting.agendaItems?.length > 0
        ? meeting.agendaItems.map((agendaItem, index) => ({
            duration: agendaItem.duration,
            sort: index,
            title: agendaItem.title,
            checked: agendaItem.checked,
            agendaId: agendaItem.agendaId ? agendaItem.agendaId : '',
            boardId: agendaItem.boardId ? agendaItem.boardId : '',
            boardListId: agendaItem.boardListId ? agendaItem.boardListId : '',
            embedUrl: agendaItem.embedUrl ? agendaItem.embedUrl : '',
          }))
        : [],
  })) as IMeeting;

  if (!updatedMeeting) {
    return;
  }
  yield call(storeAgendaItems, updatedMeeting.agendaItemIds);

  yield call(dialogService.closeDialog, 'meetingFormDialog');
  yield call(dialogService.closeDialog, 'agendaFormDialog');
  yield call(dialogService.closeDialog, 'agendaCustomFormDialog');
}

export function* deleteMeeting(meetingId: IMeeting['id']) {
  yield call(meetingResourceService.deleteMeeting, meetingId);
}

export function* finishMeetingSession(meetingId: IMeeting['id'], sessionId: ISession['id']) {
  yield call(meetingResourceService.finishMeetingSession, meetingId, sessionId);
}

export function* handleMeetingFromNotification(meetingResponse: IMeetingResponse) {
  const meeting = meetingResourceService.meetingResponseToMeeting(meetingResponse);
  const meetingExists = (yield selectWithParams(meetingSelectors.selectResourceById, meeting.id)) as IMeeting | null;

  yield call(meetingResourceService.storeMeeting, {
    ...meeting,
    taskIds: meetingExists ? meetingExists.taskIds : null,
    sessionIds: meetingExists ? meetingExists.sessionIds : null,
    kpiId: meetingExists ? meetingExists.kpiId : null,
  });

  if (meetingExists) {
    return;
  }

  const reducedMeetings = (yield select(meetingsPageSelectors.selectReducedMeetings)) as IReducedMeetings;

  let newReducedMeetings: IReducedMeetings = {};
  if (reducedMeetings) {
    newReducedMeetings = { ...reducedMeetings };
  }

  if (!newReducedMeetings[meeting.type]) {
    newReducedMeetings[meeting.type] = [];
  }

  newReducedMeetings[meeting.type] = [...newReducedMeetings[meeting.type], meeting.id];

  yield put(actions.storeReducedMeetings(newReducedMeetings));
}

export function* clearMeetingsPageStorage() {
  yield put(actions.clearReducedMeetings());
  yield put(actions.clearIsFetched());
}
