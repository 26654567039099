import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './timer.constants';
import * as types from './timer.types';
import * as services from './timer.saga-services';

function* invokeToggleTimer(action: types.IToggleTimer) {
  yield call(services.toggleTimer, action.payload);
}
function* invokeChangeTimerStatus(action: types.IChangeTimerStatus) {
  yield call(services.changeTimerStatus, action.payload.status, action.payload.timerSaveForm);
}
function* invokeChangeTime(action: types.IChangeTime) {
  yield call(services.changeTime, action.payload);
}
function* invokeChooseClient(action: types.IChooseClient) {
  yield call(services.chooseClient, action.payload.clientId, action.payload.external);
}

function* invokeSaveTimerResult(action: types.ISaveTimerResult) {
  yield call(services.saveTimerResult, action.payload);
}

function* invokeChooseTimerResult(action: types.IChooseTimerResult) {
  yield call(services.chooseTimerResult, action.payload);
}

function* invokeCancelEdit(action: types.ICancelEdit) {
  yield call(services.cancelEdit);
}
// function* invokeChooseProjectTask(action: types.IChooseProjectTask) {
//   // yield call(services.initialTimeTrackingPage);
//   console.log('todo', action);
// }

export function* watchTimerSaga() {
  yield takeLatest(constants.TOGGLE_TIMER, invokeToggleTimer);
  yield takeLatest(constants.CHANGE_TIMER_STATUS, invokeChangeTimerStatus);
  yield takeLatest(constants.CHANGE_TIME, invokeChangeTime);
  yield takeLatest(constants.CHOOSE_CLIENT, invokeChooseClient);
  yield takeLatest(constants.SAVE_TIMER_RESULT, invokeSaveTimerResult);
  yield takeLatest(constants.CHOOSE_TIMER_RESULT, invokeChooseTimerResult);
  yield takeLatest(constants.CANCEL_EDIT, invokeCancelEdit);
}
