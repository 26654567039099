/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';

import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { updateMeeting } from '../../../app/page/page-storage/meetings/meetings.actions';
import { IMeeting, IMeetingNewCreateForm } from '../../../app/resource/meeting/meeting.types';

import { useSelectWithParams } from '../../../utils/selector.utils';

import { SubmitButton } from '../../pages/common/forms/SubmitButton';

import { ConnectedDialog } from '../ConnectedDialog';
import { MeetingAgenda } from './MeetingAgenda';
import { IAgendaFormDialogProps } from './types';

export const AgendaFormDialog: FunctionComponent = () => {
  const dispatch = useDispatch();

  const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'agendaFormDialog') as IAgendaFormDialogProps;

  const onClose = useCallback(() => {
    dispatch(closeDialog('agendaFormDialog'));
  }, [dispatch]);

  const onSubmit = useCallback(
    (data: IMeetingNewCreateForm, meetingId: IMeeting['id']) => {
      dispatch(updateMeeting(meetingId, data));
    },
    [dispatch],
  );

  return (
    <ConnectedDialog dialogKey="agendaFormDialog">
      <div className="popup new_board_popup" style={{ display: 'block' }}>
        <div className="panel" style={{ minWidth: '750px' }}>
          <div className="panel-header small-header flex jcsb">
            <h3>Agenda</h3>
            <span className="close-popup close" onClick={onClose}>
              <i className="icon-close" />
            </span>
          </div>
          <div className="panel-body">
            {props && props.meetingId && <MeetingAgenda meetingId={props.meetingId} onSubmit={onSubmit} />}
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" onClick={onClose}>
              Close
            </a>

            <SubmitButton formName="meeting-form" text="Save" />
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
