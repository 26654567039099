/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useMemo } from 'react';
import { notificationSelectors } from '../../../../../../../app/resource/notification/notification.selectors';
import { difference } from '../../../../../../../utils/date.utils';
import { useSelectWithParams } from '../../../../../../../utils/selector.utils';
import { INotificationProps } from './types';

export const Notification: FunctionComponent<INotificationProps> = ({ notificationId }) => {
  const notification = useSelectWithParams(notificationSelectors.selectResourceById, notificationId);

  const time = useMemo(() => (notification ? difference(notification.createdAt, new Date()) : '/'), [notification]);

  return (
    notification && (
      <>
        <div className="dropdown submenu-dots">
          <span className="dropdown-button" data-action="submenu">
            <i className="icon-dot-3 link blueGray" />
          </span>
          <ul className="dropdown-menu drop-right">
            <li>
              <span className="drop-item">Option 1</span>
            </li>
            <li>
              <span className="drop-item">Option 2</span>
            </li>
            <li>
              <span className="drop-item">Option 3</span>
            </li>
          </ul>
        </div>
        <a>
          <img src={notification.image} alt="notification" className="img-fluid" />
          <span className="drop-menu-desc">
            <span className="user-name">{notification.message}</span>
            <span className="user-time">{`${time} ago`}</span>
          </span>
        </a>
      </>
    )
  );
};
