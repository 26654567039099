import React, { useCallback, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { IBoardBodyHeaderProps } from './types';
import { deleteBoardList } from '../../../../../../../../app/page/page-storage/boardLists/boardLists.actions';
import { openDialog } from '../../../../../../../../app/dialog/dialog.actions';

export const BoardBodyHeader: FunctionComponent<IBoardBodyHeaderProps> = ({ boardListData, meetingId }) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  //const close = useCallback(() => setActive(false), [setActive]);

  const dispatch = useDispatch();

  const onDeleteBoardList = useCallback(() => {
    dispatch(deleteBoardList(boardListData.id));
  }, [boardListData.id, dispatch]);
  const boardId = boardListData.boardId;
  const openEditBoardListFormDialog = useCallback(() => {
    dispatch(openDialog('boardListFormDialog', { boardListData, boardId }));
  }, [boardId, boardListData, dispatch]);
  const boardListId = boardListData.id;
  const openTrelloCardFormDialog = useCallback(() => {
    dispatch(openDialog('trelloCardFormDialog', { boardListId, meetingId }));
  }, [boardListId, dispatch, meetingId]);

  return (
    <div className="panel-header p-0">
      <h2 className="bold">{boardListData.name}</h2>
      <div className="ml-auto flex">
        <span className="mr-05 btn-badge green-bg popup_button" onClick={openTrelloCardFormDialog}>
          <i className="icon-plus" />
        </span>

        <div className={isActive ? 'dropdown ml-auto ln opened' : 'dropdown ml-auto ln'} onClick={toggleClass}>
          <span className="dropdown-button lightGray-bg" data-action="submenu">
            <i className="icon-dot-3 link blueGray" />
          </span>
          <ul className="dropdown-menu drop-right">
            <li onClick={openEditBoardListFormDialog}>
              <span className="drop-item">Edit List</span>
            </li>
            <li onClick={onDeleteBoardList}>
              <span className="drop-item">Delete List</span>
            </li>
            <li onClick={openTrelloCardFormDialog}>
              <span className="drop-item">Add Card</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
