import * as types from './conclusions.types';
import * as constants from './conclusions.constants';
import { IConclusion, IConclusionCreate, IConclusionSearchParams } from '../../../resource/conclusion/conclusion.types';

export const storeConclusions = (conclusions: IConclusion['id'][]): types.IStoreConclusions => ({
  type: constants.STORE_CONCLUSIONS,
  payload: conclusions,
});

export const clearConclusions = (): types.IClearConclusions => ({
  type: constants.CLEAR_CONCLUSIONS,
});

export const fetchConclusions = (searchParams: IConclusionSearchParams): types.IFetchConclusions => ({
  type: constants.FETCH_CONCLUSIONS,
  payload: searchParams,
});

export const createConclusion = (conclusionInfo: IConclusionCreate): types.ICreateConclusion => ({
  type: constants.CREATE_CONCLUSION,
  payload: conclusionInfo,
});

export const updateConclusion = (
  conclusionId: IConclusion['id'],
  conclusionInfo: IConclusionCreate,
): types.IUpdateConclusion => ({
  type: constants.UPDATE_CONCLUSION,
  payload: {
    id: conclusionId,
    data: conclusionInfo,
  },
});

export const deleteConclusion = (conclusionId: IConclusion['id']): types.IDeleteConclusion => ({
  type: constants.DELETE_CONCLUSION,
  payload: conclusionId,
});
