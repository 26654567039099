/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../app/dialog/dialog.actions';
import { createMeeting } from '../../../../app/page/page-storage/meetings/meetings.actions';
import { IMeetingCreateForm, IMeetingNewCreateForm } from '../../../../app/resource/meeting/meeting.types';
import { ControlWrapperProps } from './types';

export const ControlWrapper: FunctionComponent<ControlWrapperProps> = ({ type, sortType, setSortType, children }) => {
  const dispatch = useDispatch();
  const openMeetingForm = useCallback(() => {
    dispatch(
      openDialog('meetingFormDialog', {
        type,
        onSubmit: (meeting: IMeetingCreateForm) => {
          // hot fix
          dispatch(createMeeting({ ...meeting, type } as IMeetingNewCreateForm));
          //dispatch(createMeeting({ ...meeting, type }));
        },
      }),
    );
  }, [dispatch, type]);
  return (
    <>
      <div className="col-12">
        <div className="panel">
          <div className="panel-header small-header no-border">
            <h2 className="bold">{type === 'account' ? 'My Accounts' : 'My Teams'}</h2>
            <div className="ml-auto flex aic jcr">
              <div className="sorting mr-1">
                Sort:
                <a
                  className={`link ${sortType ? 'darkGray' : 'gray'} toggle-link ml-1`}
                  title=""
                  onClick={() => setSortType(1)}
                >
                  A-Z
                </a>
                <a
                  className={`link ${sortType ? 'gray' : 'darkGray'} toggle-link ml-1`}
                  title=""
                  onClick={() => setSortType(0)}
                >
                  Z-A
                </a>
              </div>
              <a
                className="btn btn-md green-bg popup_button"
                title={`Add a New ${type === 'account' ? 'Account' : 'Team'}`}
                onClick={openMeetingForm}
              >
                <i className="icon-plus mr-1"></i> Add a New {type === 'account' ? 'Account' : 'Team'}
              </a>
            </div>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};
