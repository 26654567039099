import { all, call, put, select } from 'redux-saga/effects';

import * as actions from './boardLists.actions';
import * as boardListResourceService from './../../../resource/boards/boardList/boardList.saga-service';

import {
  IBoardListCreate,
  IBoardListCreateForm,
  IBoardList,
  IBoardListFromNotification,
} from '../../../resource/boards/boardList/boardList.types';
import * as dialogService from '../../../dialog/dialog.saga-services';
import { boardListsPageSelectors } from './boardLists.selectors';
import { IBoard } from '../../../resource/boards/board/board.types';
import {
  ICopyTask,
  ICopyTaskFromNotification,
  IMoveTask,
  IMoveTaskFromNotification,
  ITask,
  ITaskCreate,
  ITaskUpdate,
} from '../../../resource/task/task.types';
import * as checkListService from '../checklists/checkLists.saga-services';
import * as activitiesService from '../activities/activities.saga-services';
import * as taskResourceService from '../../../resource/task/task.saga-service';
import { selectWithParams } from '../../../../utils/selector.utils';
import { boardListSelectors } from '../../../resource/boards/boardList/boardList.selectors';
import { DropResult } from 'react-beautiful-dnd';
import { groupNonDefaultByTypeFromRealBoardListAndStore } from './board-lists-handlers/add-new.saga-service';
import { ITrelloReplacementFormDialogProps } from '../../../../components/dialogs/BoardsDialogs/TrelloReplacementFormDialog/types';

export function* clearBoardLists() {
  yield put(actions.clearBoardList());
}

export function* setupBoardLists(boardId: IBoard['id']) {
  const boardLists = (yield call(boardListResourceService.searchBoardLists, boardId)) as IBoardList['id'][];

  yield put(boardLists ? actions.storeBoardLists(boardLists) : actions.clearBoardList());
}
export function* createBoardList(boardListInfo: IBoardListCreateForm) {
  const boardList = (yield call(
    boardListResourceService.createBoardList,
    mapBoardListFormToBoardListCreate(boardListInfo),
  )) as IBoardList;
  yield groupNonDefaultByTypeFromRealBoardListAndStore(boardList);

  if (!boardList) {
    return;
  }

  yield call(dialogService.closeDialog, 'boardListFormDialog');
}

export function* updateBoardList(boardListId: IBoardList['id'], boardListInfo: IBoardListCreateForm) {
  const boardList = (yield call(boardListResourceService.updateBoardList, {
    id: boardListId,
    ...mapBoardListFormToBoardListCreate(boardListInfo),
  })) as IBoardList;

  if (!boardList) {
    return;
  }

  yield call(dialogService.closeDialog, 'boardListFormDialog');
}

export function* deleteBoardList(boardListId: IBoardList['id']) {
  yield call(boardListResourceService.deleteBoardList, boardListId);

  const boardListsIds: IBoardList['id'][] = yield select(boardListsPageSelectors.selectBoardLists);

  yield put(
    boardListsIds
      ? actions.storeBoardLists(boardListsIds.filter((id) => boardListId !== id))
      : actions.clearBoardList(),
  );
}

export const mapBoardListFormToBoardListCreate = (boardListInfo: IBoardListCreateForm): IBoardListCreate => ({
  name: boardListInfo.name,
  boardId: boardListInfo.boardId,
});
export function* clearboardListsPageStorage() {
  yield put(actions.clearBoardList());
}

export function* initializeTaskProps(taskId: ITask['id']) {
  yield all([checkListService.setupCheckLists, activitiesService.setupActivities].map((func) => call(func, taskId)));
}

export function* clearTaskProps() {
  yield call(checkListService.clearCheckListsPageStorage);
  yield call(activitiesService.clearActivitiesPageStorage);
  yield call(dialogService.closeDialog, 'trelloReplacementFormDialog');
}

export function* updateBoardTask(task: ITaskUpdate) {
  yield call(taskResourceService.updateBoardTask, task);
}

export function* createBoardTask(task: ITaskCreate) {
  const newTask = (yield call(taskResourceService.createBoardTask, task)) as ITask;
  yield call(dialogService.closeDialog, 'trelloCardFormDialog');
  yield call(dialogService.closeDialog, 'actionItemDialog');
  if (!newTask) {
    return;
  }
  yield call(dialogService.openDialog, 'trelloReplacementFormDialog', {
    taskId: newTask.id,
    meetingId: newTask.meetingIds[0],
  } as ITrelloReplacementFormDialogProps);
}

export function* moveTask(data: IMoveTask) {
  yield call(taskResourceService.moveTask, data);
}

export function* copyTask(data: ICopyTask) {
  yield call(taskResourceService.copyTask, data);
}

export function* deleteBoardTask(taskId: ITask['id']) {
  yield call(taskResourceService.deleteTask, taskId);
  yield call(dialogService.closeDialog, 'trelloReplacementFormDialog');
  yield call(taskResourceService.clearTask, taskId);
}

export function* handleBoardListFromNotification(boardListResponse: IBoardListFromNotification) {
  console.log(boardListResponse);
  const boardListExists = (yield selectWithParams(
    boardListSelectors.selectResourceById,
    boardListResponse.id,
  )) as IBoardList | null;
  console.log(boardListExists);
  if (boardListExists) {
    yield call(boardListResourceService.storeBoardList, { ...boardListResponse });
  } else {
    yield call(boardListResourceService.storeBoardList, { ...boardListResponse, tasksIds: [] });

    yield groupNonDefaultByTypeFromRealBoardListAndStore({ ...boardListResponse, tasksIds: [] });

    const boardListsIds = (yield select(boardListsPageSelectors.selectBoardLists)) as IBoardList['id'][];
    yield put(actions.storeBoardLists([...boardListsIds, boardListResponse.id]));
  }
}

export function* handleBoardListDeleteFromNotification(boardListId: { id: IBoardList['id'] }) {
  const boardListIds = (yield select(boardListsPageSelectors.selectBoardLists)) as IBoardList['id'][];
  const newListIds = boardListIds.filter((bl) => bl !== boardListId.id);
  yield put(actions.storeBoardLists(newListIds));
}

export function* handlSaveTaskFromNotification(taskResponse: ITask) {
  yield call(taskResourceService.saveTaskNotification, taskResponse);
}

export function* handleMoveTaskFromNotification(moveTaskResponse: IMoveTaskFromNotification) {
  yield call(taskResourceService.moveTaskNotification, moveTaskResponse);
}

export function* handleCopyTaskFromNotification(copyTaskResponse: ICopyTaskFromNotification) {
  yield call(taskResourceService.copyTaskNotification, copyTaskResponse);
}

export function* moveBoardList(result: DropResult) {
  if (!result.destination) {
    return;
  }
  const boardListId = result.draggableId;
  // const destinationId = result.destination.droppableId;
  const destinationIndex = result.destination.index;
  // const sourceId = result.source.droppableId;
  const sourceIndex = result.source.index;

  // const destination = (yield selectWithParams(boardSelectors.selectResourceById, destinationId)) as IBoard;
  // if (!destination || destination.taskTypes[destinationIndex].id === boardListId) {
  //   return;
  // }

  // let source: IBoard | null = null;
  // if (sourceId !== destinationId) {
  //   source = (yield selectWithParams(boardSelectors.selectResourceById, sourceId)) as IBoard;
  // }

  // if (source) {
  //   const sourceBoardLists = [...source.taskTypes];
  //   sourceBoardLists.splice(sourceIndex, 1);
  //   yield put(storeBoard({ ...source, taskTypes: sourceBoardLists }));
  // }

  const destinationBoardListIds = (yield select(boardListsPageSelectors.selectBoardLists)) as IBoardList['id'][];

  // if (destinationIndex > sourceIndex) {
  //   destinationBoardListIds.splice(sourceIndex, 1);
  //   destinationBoardListIds.splice(destinationIndex - 1, 0, boardListId);
  // } else {

  // }

  destinationBoardListIds.splice(sourceIndex, 1);
  destinationBoardListIds.splice(destinationIndex, 0, boardListId);

  yield call(boardListResourceService.saveBoardListOrder, { listIds: [...destinationBoardListIds] });
  yield put(actions.storeBoardLists([...destinationBoardListIds]));
}
