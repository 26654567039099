import React from 'react';
import { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../app/dialog/dialog.actions';

import { IBoardListFormDialogProps } from '../../../../dialogs/BoardsDialogs/BoardListFormDialog/types';
import { IBoardBodyProps } from './types';
import { BoardList } from './BoardBodyTasks/BoardList';
import { BoardListDragDropContext } from '../../../common/drag-drop-contexts/BoardListDragDropContext';
import { Draggable, Droppable } from 'react-beautiful-dnd';

export const BoardBody: FunctionComponent<IBoardBodyProps> = ({
  boardListsIds,
  boardId,
  meetingId,
  unextendableBoard,
  virtualFlag,
  isAdmin,
}) => {
  const dispatch = useDispatch();
  const openBoardListFormDialog = useCallback(() => {
    dispatch(openDialog('boardListFormDialog', { boardId } as IBoardListFormDialogProps));
  }, [boardId, dispatch]);

  return (
    <div className="cards-container-overflow">
      <div className="cards-container">
        {isAdmin === true ? (
          <BoardListDragDropContext>
            <Droppable droppableId={boardId} direction="horizontal" type="column">
              {(provided) => (
                <div style={{ display: 'flex' }} {...provided.droppableProps} ref={provided.innerRef}>
                  {boardListsIds.map((boardListId, index) => (
                    <Draggable key={`board-list-${boardListId}`} draggableId={boardListId} index={index}>
                      {(provided) => (
                        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <BoardList
                            boardListId={boardListId}
                            meetingId={meetingId}
                            virtualFlag={virtualFlag}
                            isAdmin={isAdmin}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </BoardListDragDropContext>
        ) : (
          <>
            <BoardListDragDropContext>
              <div style={{ display: 'flex' }}>
                {boardListsIds.map((boardListId) => (
                  <div key={boardListId}>
                    <BoardList
                      boardListId={boardListId}
                      meetingId={meetingId}
                      virtualFlag={virtualFlag}
                      isAdmin={isAdmin}
                    />
                  </div>
                ))}
              </div>
            </BoardListDragDropContext>
          </>
        )}
        {!unextendableBoard && (
          <div className="single-card-container">
            <div className="panel-body px-0">
              <div
                className="border-radius-1 lightGray-bg px-2 pb-1 py-5 gray-link popup_button"
                onClick={openBoardListFormDialog}
              >
                <div className="new_column p-5 flex flex-column aic jcc">
                  <span className="big-avatar blueGray-bg flex aic jcc">
                    <i className="icon-plus white f-4" />
                  </span>
                  <span className="blueGray mt-2">Add another list</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
