import React, { FunctionComponent, useMemo } from 'react';
import { extractBoardListIds } from '../../../../../../app/page/page-storage/tasks/tasks.saga-services';
import { boardSelectors } from '../../../../../../app/resource/boards/board/board.selectors';
import { useSelectWithParams } from '../../../../../../utils/selector.utils';
import { TaskDragDropContext } from '../../../drag-drop-contexts/TaskDragDropContext';
import { TasksStandardList } from './TasksStandardList';
import { IWidgetTasksStandardViewProps } from './types';

export const WidgetTasksStandardView: FunctionComponent<IWidgetTasksStandardViewProps> = ({
  complexBoardId,
  actionElement,
}) => {
  const board = useSelectWithParams(boardSelectors.selectResourceById, complexBoardId);
  const boardListIds = useMemo(() => extractBoardListIds(board), [board]);

  // const boardListsIds = useSelectWithParams(boardListsPageSelectors.selectBoardLists, []);
  // useEffect(() => {
  //   console.log('rerender');
  // }, [boardListsIds]);
  // console.log(boardListsIds);
  return (
    board && (
      <div className="row mb-25 mt-3">
        <div className="col-12">
          <div className="panel vto-text">
            <div className="panel-header small-header no-border pb-0">
              <h2 className="bold">{board.name}</h2>
              <div className="ml-auto flex aic">{actionElement}</div>
            </div>
            <div className="cards-container-overflow">
              <TaskDragDropContext>
                <div className="cards-container">
                  {boardListIds.map((boardListId) => (
                    <TasksStandardList
                      key={`TasksStandardList-${boardListId}`}
                      complexBoardListId={boardListId}
                      actionElement={actionElement}
                    />
                  ))}
                </div>
              </TaskDragDropContext>
            </div>
            {/* <BoardBody
              boardListsIds={boardListsIds}
              boardId={board.id}
              meetingId={board.meetingIds[0]}
              virtualFlag={false}
            /> */}
          </div>
        </div>
      </div>
    )
  );
};
