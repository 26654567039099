import { IProjectTasksTime } from '../../../resource/project-time/project-time.types';
import * as types from './project-times.types';
import * as constants from './project-times.constants';

export const setupProjectTimes = (week: string): types.ISetupProjectTimes => ({
  type: constants.SETUP_PROJECT_TIME,
  payload: week,
});

export const storeProjectTimes = (projectTimes: IProjectTasksTime['id'][]): types.IStoreProjectTime => ({
  type: constants.STORE_PROJECT_TIME,
  payload: projectTimes,
});

export const clearProjectTime = (): types.IClearProjectTime => ({
  type: constants.CLEAR_PROJECT_TIME,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
