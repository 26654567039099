import React, { FunctionComponent, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IDateTimeInputProps } from './types';

import { get } from 'lodash';
import ReactDatePicker from 'react-datepicker';

export const DateTimeInput: FunctionComponent<IDateTimeInputProps> = ({
  id,
  name,
  defaultValue,
  placeholder,
  validation,
  label,
  yearPicker,
  ...props
}) => {
  const { control, errors, watch } = useFormContext();
  const value = useMemo(() => watch(name), [name, watch]);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={validation}
      render={(controllerProps) => (
        <>
          {!!get(errors, name, false) && (
            <label htmlFor={name} style={{ color: 'red' }}>
              {`${label ? label + ' - ' : ''} ${get(errors, `${name}.message`)}`}
            </label>
          )}
          {!get(errors, name, false) && <label htmlFor={name}>{label}</label>}
          {yearPicker ? (
            <ReactDatePicker
              {...props}
              selected={value}
              id={id}
              name={controllerProps.name}
              onChange={controllerProps.onChange}
              value={controllerProps.value}
              showYearPicker
              dateFormat={'yyyy'}
            />
          ) : (
            <ReactDatePicker
              {...props}
              selected={value}
              id={id}
              name={controllerProps.name}
              onChange={controllerProps.onChange}
              value={controllerProps.value}
            />
          )}
        </>
      )}
    />
  );
};
