import { call, put } from 'redux-saga/effects';

import * as actions from './calendars.actions';

import * as calendarService from '../../../resource/calendar/calendar.saga-service';

import { CURRENT_DATE, ICalendar, ICalendarSearchParams } from '../../../resource/calendar/calendar.types';
import { IUser } from '../../../resource/user/user.types';
import { authSelectors } from '../../../auth/auth.selectors';
import { select, selectWithParams } from '../../../../utils/selector.utils';
import { userSelectors } from '../../../resource/user/user.selectors';

import * as userService from '../../../resource/user/user.saga-service';
import { getCalendarItems } from '../../../resource/calendar-item/calendar-item.saga-service';
import { ICalendarItem, IScope } from '../../../resource/calendar-item/calendar-item.types';
import { storeGooglePermisionGranted } from '../../../auth/auth.actions';
import { calendarSelectors } from '../../../resource/calendar/calendar.selectors';
import { calendarsPageSelectors } from './calendars.selectors';

export function* setupCalendar(from: string, scope: IScope) {
  yield put(actions.clearDescriptionParam());

  const grantedPermisio = yield select(authSelectors.selectGoogleGrantedPermision);

  if (!grantedPermisio) {
    return;
  }

  const calendarItemIds: ICalendarItem['id'][] = yield call(getCalendarItems, from, scope);

  yield calendarItemIds ? put(actions.storeCalendarItemIds(calendarItemIds)) : put(actions.clearCalendarItemIds());

  yield call(getCalendars);
}

export function* getCalendars(calendarSearchParams?: ICalendarSearchParams) {
  const isFetched = !!((yield select(calendarSelectors.selectResourceList)) as ICalendar[]).length;

  if (isFetched) {
    return;
  }

  const myCalendars: ICalendarItem['id'][] = yield call(calendarService.getMyCalendars);
  yield put(actions.storeToggledCalendars(myCalendars));
}

export function* refreshCalendar(userId: IUser['id']) {
  const currentUserId: IUser['id'] | null = yield select(authSelectors.selectLoggedUser);

  if (currentUserId !== userId) {
    return;
  }

  const currentUser = yield selectWithParams(userSelectors.selectResourceById, currentUserId);
  yield call(userService.storeUser, { ...currentUser, googleApiToken: 'true' });
  yield put(storeGooglePermisionGranted('true'));

  yield call(setupCalendar, CURRENT_DATE, 'month');
}

export function* searchByDescription(description: string) {
  yield description ? put(actions.storeDescriptionParam(description)) : put(actions.clearDescriptionParam());
}

export function* toggleCalendar(calendarId: ICalendar['id']) {
  const toggledCalendarIds: ICalendar['id'][] = yield select(calendarsPageSelectors.selectToggledCalendars);

  yield toggledCalendarIds.includes(calendarId)
    ? put(actions.storeToggledCalendars(toggledCalendarIds.filter((id) => id !== calendarId)))
    : put(actions.storeToggledCalendars([...toggledCalendarIds, calendarId]));
}

export function* clearCalendarsPageStorage() {
  yield put(actions.clearCalendarItemIds());
  yield put(actions.clearToggledCalendars());
  yield put(actions.clearDescriptionParam());
}
