/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { questionSelectors } from '../../../../../../../app/resource/question/question.selectors';
import { useSelectWithParams } from '../../../../../../../utils/selector.utils';
import { Answer } from './Answer';
import { IQuestionProps } from './types';

export const Question: FunctionComponent<IQuestionProps> = ({ questionId }) => {
  const question = useSelectWithParams(questionSelectors.selectResourceById, questionId);
  return (
    <div
      className="col-4"
      style={{
        width: `${question?.questionLength}%`,
        maxWidth: `${question?.questionLength}%`,
        flex: `0 0 ${question?.questionLength}%`,
      }}
    >
      {question && question.question !== '' && (
        <div
          className={`panel lightGray-bg min-h60 no-shadow py-1 px-2 flex aic mb-2 ${question.cssClass}`}
          dangerouslySetInnerHTML={{ __html: question.question }}
        >
          {/* {question?.question.split('<br/>').join('\n')} */}
        </div>
      )}
      {question?.answerIds.map((answerId) => (
        <>
          <Answer answerId={answerId} />
        </>
      ))}
    </div>
  );
};
