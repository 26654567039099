/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { updateTask } from '../../../../../../../../../app/page/page-storage/boardLists/boardLists.actions';

import { boardListSelectors } from '../../../../../../../../../app/resource/boards/boardList/boardList.selectors';
import { taskSelectors } from '../../../../../../../../../app/resource/task/task.selectors';
import { ITask } from '../../../../../../../../../app/resource/task/task.types';
import { useSelectWithParams } from '../../../../../../../../../utils/selector.utils';
import { Status } from '../../Status';
import { IListNameProps } from './types';

export const ListName: FunctionComponent<IListNameProps> = ({ taskId }) => {
  const dispatch = useDispatch();
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId) as ITask;
  const boardList = useSelectWithParams(boardListSelectors.selectResourceById, task.taskTypeId);
  const [disabled, setDisabled] = useState(task.showInTasks === '1' ? false : true);
  const handleChange = useCallback(() => {
    setDisabled(!disabled);
    const showInTasks = disabled ? '1' : '0';
    const updatedTask = {
      id: task.id,
      title: task.title,
      description: task.description,
      dueDate: task.dueDate,
      userId: task.userId,
      meetingIds: task.meetingIds,
      status: task.status,
      label: task.label ?? [],
      taskTypeId: task.taskTypeId,
      members: task.members,
      showInTasks: showInTasks,
      boardId: task.boardId,
    };

    dispatch(updateTask(updatedTask));
  }, [disabled, dispatch, task]);
  return (
    <>
      <div className="mb-3 mt-1">
        <span>{`in list `}</span>
        <div className="ml-auto ln d-inline">
          {boardList && <span className="link blueGray underline close">{boardList.name}</span>}
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-2" style={{ flex: `0 0 88%` }}>
          <div className="remember-checkbox flex aic jcl">
            <input
              type="checkbox"
              id="defaultCheckbox"
              name="defaultCheckbox"
              onChange={handleChange}
              checked={!disabled}
            />
            <label htmlFor="defaultCheckbox">Add to Action Items</label>
          </div>
        </div>
        <Status taskId={taskId} />
      </div>
    </>
  );
};
