import { call, put, select } from 'redux-saga/effects';

import * as actions from './labels.actions';

import { ILabelCreate, ILabelCreateForm, ILabel } from '../../../resource/label/label.types';
import * as dialogService from '../../../dialog/dialog.saga-services';
import { labelsPageSelectors } from './labels.selectors';
import * as labelResourceService from '../../../resource/label/label.saga-service';
import { selectWithParams } from '../../../../utils/selector.utils';
import { boardSelectors } from '../../../resource/boards/board/board.selectors';
import { IBoard } from '../../../resource/boards/board/board.types';
import { storeBoard } from '../../../resource/boards/board/board.actions';
import { labelSelectors } from '../../../resource/label/label.selectors';

export function* clearLabels() {
  yield put(actions.clearLabel());
}

export function* setupLabels() {
  const labels = (yield call(labelResourceService.searchLabels)) as ILabel['id'][];

  yield put(labels ? actions.storeLabels(labels) : actions.clearLabel());
}
export function* createLabel(labelInfo: ILabelCreateForm) {
  const label = (yield call(labelResourceService.createLabel, mapLabelFormToLabelCreate(labelInfo))) as ILabel;

  if (!label) {
    return;
  }

  const board = (yield selectWithParams(boardSelectors.selectResourceById, label.boardId)) as IBoard;
  if (!board) {
    return;
  }
  yield put(storeBoard({ ...board, labelIds: [...board.labelIds, label.id] }));

  // const labelsIds = yield select(labelsPageSelectors.selectLabels);
  // yield put(labelsIds ? actions.storeLabels([...labelsIds, label.id]) : actions.clearLabel());

  yield call(dialogService.closeDialog, 'labelFormDialog');
}

export function* updateLabel(labelId: ILabel['id'], labelInfo: ILabelCreateForm) {
  const label = (yield call(labelResourceService.updateLabel, {
    id: labelId,
    ...mapLabelFormToLabelCreate(labelInfo),
  })) as ILabel;

  if (!label) {
    return;
  }
  const labelsIds = yield select(labelsPageSelectors.selectLabels);

  yield put(labelsIds ? actions.storeLabels([...labelsIds]) : actions.clearLabel());
  yield call(dialogService.closeDialog, 'labelFormDialog');
}

export function* deleteLabel(labelId: ILabel['id']) {
  const label = (yield selectWithParams(labelSelectors.selectResourceById, labelId)) as ILabel;
  yield call(labelResourceService.deleteLabel, labelId);
  // const labelsIds: ILabel['id'][] = yield select(labelsPageSelectors.selectLabels);
  // yield put(labelsIds ? actions.storeLabels(labelsIds.filter((id) => labelId !== id)) : actions.clearLabel());

  const board = (yield selectWithParams(boardSelectors.selectResourceById, label.boardId)) as IBoard;
  if (!board) {
    return;
  }
  yield put(storeBoard({ ...board, labelIds: board.labelIds.filter((boardLabelId) => labelId !== boardLabelId) }));
}

export const mapLabelFormToLabelCreate = (labelInfo: ILabelCreateForm): ILabelCreate => ({
  name: labelInfo.name,
  color: labelInfo.color,
  boardId: labelInfo.boardId,
});
export function* clearLabelsPageStorage() {
  yield put(actions.clearLabel());
}
