import { all, call, put } from 'redux-saga/effects';

import * as types from './score.types';
import * as actions from './score.actions';
import * as api from '../../api/request-config/score.api';

import { apiRequest } from '../../api/api.saga-services';
import { ISession } from '../session/session.types';

import * as sessionService from '../session/session.saga-service';
import * as userService from '../user/user.saga-service';
import * as meetingService from '../meeting/meeting.saga-service';
import { userSelectors } from '../user/user.selectors';
import { IUser } from '../user/user.types';
import { meetingSelectors } from '../meeting/meeting.selectors';
import { IMeeting } from '../meeting/meeting.types';
import { select, selectWithParams } from '../../../utils/selector.utils';
import { scoreSelectors } from './score.selectors';

export function* storeScore(score: types.IScore) {
  yield put(actions.storeScore(score));
}

export function* clearScore(scoreId: types.IScore['id']) {
  yield put(actions.clearScore(scoreId));
}
export function* clearAllScores() {
  const scoreIds: types.IScore['id'][] = yield select(scoreSelectors.selectResourceIds);
  yield put(actions.clearScore(scoreIds));
}
/* This method sends api request and handle response */
export function* getScoreBoard(searchParams: types.IScoreSearchParams) {
  yield call(userService.searchUsers);

  const { scores, sessions } = (yield call(apiRequest, api.getScoreBoardApi(searchParams))) as {
    scores: types.IScore[];
    sessions: ISession[];
  };

  yield all(sessions.map((session) => call(sessionService.storeSession, session)));
  yield all(scores.map((score) => call(storeScore, score)));

  const users = ((yield selectWithParams(
    userSelectors.selectResourcesById,
    scores.reduce(
      (prev, curr) => (prev.includes(curr.userId) ? [...prev] : [...prev, curr.userId]),
      [] as IUser['id'][],
    ),
  )) as IUser[]).filter((user) => !!user);

  const meetings = ((yield selectWithParams(
    meetingSelectors.selectResourcesById,
    sessions.reduce(
      (prev, curr) => (prev.includes(curr.meetingId) ? [...prev] : [...prev, curr.meetingId]),
      [] as IMeeting['id'][],
    ),
  )) as IMeeting[]).filter((meeting) => !!meeting);

  yield all(
    [
      ...users.map((user) => ({
        ...user,
        scores: scores.reduce(
          (prev, curr) => (curr.userId === user.id ? [...prev, curr.id] : [...prev]),
          [] as types.IScore['id'][],
        ),
      })),
    ].map((user) => call(userService.storeUser, user)),
  );
  yield all(
    [
      ...meetings.map((meeting) => ({
        ...meeting,
        sessions: sessions.reduce(
          (prev, curr) => (curr.meetingId === meeting.id ? [...prev, curr.id] : [...prev]),
          [] as ISession['id'][],
        ),
      })),
    ].map((meeting) => call(meetingService.storeMeeting, meeting)),
  );
}

/* This method sends api request and handle response */
export function* createScore(scoreInfo: types.IScoreCreate) {
  let score = (yield call(apiRequest, api.createScoreApi(scoreInfo))) as types.IScore;
  score = { ...score, id: score.id + '' };
  yield call(storeScore, score);

  // const user = (yield selectWithParams(userSelectors.selectResourceById, score.userId)) as IUser;
  // if (!user) {
  //   return;
  // }
  // call(userService.storeUser, { ...user, scores: [...user.scores, score.id] });

  return score;
}

export function* updateScore(score: types.IScoreUpdate) {
  yield call(apiRequest, api.updateScoreApi(score));
  yield call(storeScore, score as types.IScore);
  return score;
}

export function* saveScoreOrder(scoreIds: types.ISaveScoreOrder) {
  yield call(apiRequest, api.saveScoreOrderApi(scoreIds));
  //yield call(storeScore, score as types.IScore);
  return scoreIds;
}
