import { createActionConst } from '../../../../utils/action.utils';

const NOTIFICATIONS_NAMESPACE = 'NOTIFICATIONS';

export const FETCH_NOTIFICATIONS = createActionConst(NOTIFICATIONS_NAMESPACE, 'FETCH_NOTIFICATIONS');
export const UPDATE_NOTIFICATION = createActionConst(NOTIFICATIONS_NAMESPACE, 'UPDATE_NOTIFICATION');
export const UPDATE_NOTIFICATIONS = createActionConst(NOTIFICATIONS_NAMESPACE, 'UPDATE_NOTIFICATIONS');

export const DELETE_NOTIFICATION = createActionConst(NOTIFICATIONS_NAMESPACE, 'DELETE_NOTIFICATION');

export const STORE_FETCHED_NOTIFICATIONS = createActionConst(NOTIFICATIONS_NAMESPACE, 'STORE_FETCHED_NOTIFICATIONS');
export const CLEAR_FETCHED_NOTIFICATIONS = createActionConst(NOTIFICATIONS_NAMESPACE, 'CLEAR_FETCHED_NOTIFICATIONS');

export const STORE_IS_FETCHED = createActionConst(NOTIFICATIONS_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(NOTIFICATIONS_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_UNSEEN_COUNT = createActionConst(NOTIFICATIONS_NAMESPACE, 'STORE_UNSEEN_COUNT');
export const CLEAR_UNSEEN_COUNT = createActionConst(NOTIFICATIONS_NAMESPACE, 'CLEAR_UNSEEN_COUNT');

export const HANDLE_NEW_NOTIFICATION = 'saveNotification';
