import React, { FunctionComponent } from 'react';

import { taskSelectors } from '../../../../../../../../../app/resource/task/task.selectors';

import { useSelectWithParams } from '../../../../../../../../../utils/selector.utils';
import { Editor } from './Editor';
import { IDescriptionProps } from './types';

export const Description: FunctionComponent<IDescriptionProps> = ({ taskId }) => {
  const task = useSelectWithParams(taskSelectors.selectResourceById, taskId);

  return (
    <div className="row">
      <div className="col-12">
        <h4 className="bold">Description</h4>

        {task && <Editor task={task} />}
      </div>
    </div>
  );
};
