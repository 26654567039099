import { createActionConst } from '../../../../utils/action.utils';

const AGENDA_ITEM_NAMESPACE = 'AGENDA_ITEM';

export const STORE_AGENDA_ITEMS = createActionConst(AGENDA_ITEM_NAMESPACE, 'STORE_AGENDA_ITEMS');
export const CLEAR_AGENDA_ITEMS = createActionConst(AGENDA_ITEM_NAMESPACE, 'CLEAR_AGENDA_ITEMS');

export const CHANGE_ORDER_AGENDA_ITEM = createActionConst(AGENDA_ITEM_NAMESPACE, 'CHANGE_ORDER_AGENDA_ITEM');

export const STORE_CURRENT_AGENDA_INDEX = createActionConst(AGENDA_ITEM_NAMESPACE, 'STORE_CURRENT_AGENDA_INDEX');
export const CLEAR_CURRENT_AGENDA_INDEX = createActionConst(AGENDA_ITEM_NAMESPACE, 'CLEAR_CURRENT_AGENDA_INDEX');

export const MOVE_TO_NEXT_AGENDA = createActionConst(AGENDA_ITEM_NAMESPACE, 'MOVE_TO_NEXT_AGENDA');
export const MOVE_TO_NEXT_AGENDA_SOCKET = 'moveToNextAgenda';
