import * as types from './calendars.types';
import * as constants from './calendars.constants';
import { ICalendar } from '../../../resource/calendar/calendar.types';
import { ICalendarItem, IScope } from '../../../resource/calendar-item/calendar-item.types';

export const setupCalendar = (from: string, scope: IScope): types.ISetupCalendar => ({
  type: constants.SETUP_CALENDAR,
  payload: { from, scope },
});

export const storeCalendarItemIds = (calendarItemIds: ICalendarItem['id'][]): types.IStoreCalendarItems => ({
  type: constants.STORE_CALENDAR_ITEMS,
  payload: calendarItemIds,
});

export const clearCalendarItemIds = (): types.IClearCalendarItems => ({
  type: constants.CLEAR_CALENDAR_ITEMS,
});

export const searchByDescription = (description: string): types.ISearchByDescription => ({
  type: constants.SEARCH_BY_DESCRIPTION,
  payload: description,
});

export const storeDescriptionParam = (description: string): types.IStoreDescriptionParam => ({
  type: constants.STORE_DESCRIPTION_PARAM,
  payload: description,
});

export const clearDescriptionParam = (): types.IClearDescriptionParam => ({
  type: constants.CLEAR_DESCRIPTION_PARAM,
});

export const toggleCalendar = (calendarId: ICalendar['id']): types.IToggleCalendar => ({
  type: constants.TOGGLE_CALENDAR,
  payload: calendarId,
});
export const storeToggledCalendars = (toggledCalendarIds: ICalendar['id'][]): types.IStoreToggledCalendars => ({
  type: constants.STORE_TOGGLED_CALENDARS,
  payload: toggledCalendarIds,
});

export const clearToggledCalendars = (): types.IClearToggledCalendars => ({
  type: constants.CLEAR_TOGGLED_CALENDARS,
});
