import React from 'react';
import { FunctionComponent } from 'react';
import Loader from 'react-loader-spinner';
import { apiSelectors } from '../../../../../app/api/api.selectors';

import { useSelect } from '../../../../../utils/selector.utils';

import { ISubmitButtonProps } from './types';

export const SubmitButton: FunctionComponent<ISubmitButtonProps> = ({ apiKey, formName, text, styles }) => {
  //const [showLoader] = useApiRequestState(apiKey);
  const apis = useSelect(apiSelectors.selectApis);

  let show = false;
  apis.forEach((api) => {
    if (api.inProgress === true) {
      show = true;
    }
  });

  return (
    <>
      {!show && (
        <button form={formName} type="submit" className={styles ? styles : 'btn btn-green'}>
          {text}
        </button>
      )}
      {show && <Loader type="TailSpin" color="#1bc3a7" height={30} width={30} />}
    </>
  );
};
