import Pusher from 'pusher-js';

import { eventChannel } from 'redux-saga';
import { ITaskSocketAction } from '../../page/page-storage/tasks/tasks.types';
import humps from 'lodash-humps-ts';

export type ISocketAction = ITaskSocketAction;

export interface IPusherEvent {
  message: string;
}

export interface IPusherAction {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

Pusher.logToConsole = true;

// SINGLETON, we don't need multiple instances of this
const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || '', {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER || '',
});

export function createPusherChannel(channelName: string, actionNames: string[]) {
  return eventChannel((emit) => {
    const pingHandler = (actionName: string, event: IPusherEvent) => {
      emit(humps(event.message));
    };

    const channel = pusher.subscribe(channelName);
    actionNames.forEach((actionName) => channel.bind(actionName, pingHandler.bind(null, actionName)));

    const unsubscribe = () => {
      actionNames.forEach((actionName) => channel.unbind(actionName));
    };

    return unsubscribe;
  });
}
