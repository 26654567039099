import { call, put } from 'redux-saga/effects';

import * as actions from './sessions.actions';

// import * as meetingService from '../../../resource/meeting/meeting.saga-service';
import * as userService from '../../../resource/user/user.saga-service';
import * as sessionResourceService from '../../../resource/session/session.saga-service';

import { ISession, ISessionCreate, ISessionSearchParams } from '../../../resource/session/session.types';
import { IUser } from '../../../resource/user/user.types';
import { selectWithParams } from '../../../../utils/selector.utils';
import { sessionSelectors } from '../../../resource/session/session.selectors';
import { meetingSelectors } from '../../../resource/meeting/meeting.selectors';
import { IMeeting } from '../../../resource/meeting/meeting.types';

export function* setupSessions(searchParams: ISessionSearchParams) {
  let sessionIds = null;
  let nextSessionId = null;

  if (searchParams.userId) {
    const user = (yield call(userService.populateSessions, searchParams.userId)) as IUser;
    sessionIds = user.sessionIds;
  } else if (searchParams.id) {
    // const meeting = (yield call(meetingService.populateSessions, searchParams.id)) as IMeeting;
    const meeting = (yield selectWithParams(meetingSelectors.selectResourceById, searchParams.id)) as IMeeting;
    sessionIds = meeting.sessionIds;
  }

  yield sessionIds ? put(actions.storeSessionIds(sessionIds)) : put(actions.clearSessionIds());
  if (sessionIds) {
    nextSessionId = yield call(findNextSession, sessionIds);
  }
  yield nextSessionId ? put(actions.storeNextSession(nextSessionId)) : put(actions.clearNextSession());
}

export function* findNextSession(sessionIds: ISession['id'][]) {
  const sessions = yield selectWithParams(sessionSelectors.selectResourcesById, sessionIds);
  // sort by date, and take first greater then current date;
  return sessions[0] ? sessions[0].id : null;
}

export function* updateSessions(sessionId: ISession['id'], sessionInfo: ISessionCreate) {
  yield call(sessionResourceService.updateSessions, sessionId, sessionInfo);
}

export function* clearSessionsPageStorage() {
  yield put(actions.clearSessionIds());
  yield put(actions.clearNextSession());
}
