import { createActionConst } from '../../../../utils/action.utils';

const PROJECT_TIME_NAMESPACE = 'PROJECT_TIMES';

export const SETUP_PROJECT_TIME = createActionConst(PROJECT_TIME_NAMESPACE, 'SETUP_PROJECT_TIME');

export const STORE_IS_FETCHED = createActionConst(PROJECT_TIME_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(PROJECT_TIME_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_PROJECT_TIME = createActionConst(PROJECT_TIME_NAMESPACE, 'STORE_PROJECT_TIME');
export const CLEAR_PROJECT_TIME = createActionConst(PROJECT_TIME_NAMESPACE, 'CLEAR_PROJECT_TIME');
