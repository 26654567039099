import { ICheckList, ICheckListCreate, ICheckListUpdate } from '../../resource/checklist/checkList.types';
import { IApiRequestConfig } from '../api.types';

export const searchCheckListsApi = (taskId: string): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'getCheckLists',
  uri: 'json',
  action: 'getChecklist',
  method: 'get',
  params: {
    taskId,
  },
});

export const createCheckListApi = (checkList: ICheckListCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createCheckList',
  uri: 'json',
  action: 'saveChecklist',
  method: 'post',
  data: checkList,
});

export const updateCheckListApi = (checkList: ICheckListUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateCheckList',
  uri: 'json',
  action: 'saveChecklist',
  method: 'put',
  data: checkList,
});

export const deleteCheckListApi = (checkListId: ICheckList['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteCheckList',
  uri: 'json',
  action: 'deleteChecklist',
  method: 'delete',
  params: {
    id: checkListId,
  },
});
