import { call, put, select } from 'redux-saga/effects';

import * as actions from './boards.actions';
import * as boardResourceService from './../../../resource/boards/board/board.saga-service';

import {
  IBoardCreate,
  IBoardCreateForm,
  IBoard,
  IBoardFromNotification,
} from '../../../resource/boards/board/board.types';
import * as dialogService from '../../../dialog/dialog.saga-services';
import { boardsPageSelectors } from './boards.selectors';
import * as boardListsService from '../boardLists/boardLists.saga-services';
import { IMeeting } from '../../../resource/meeting/meeting.types';
import { boardSelectors } from '../../../resource/boards/board/board.selectors';
import { selectWithParams } from '../../../../utils/selector.utils';
import { meetingsPageSelectors } from '../meetings/meetings.selectors';
import { filter } from 'lodash';

export function* clearBoards() {
  yield put(actions.clearBoard());
  yield put(actions.clearCurrentBoard());
  yield call(boardListsService.clearBoardLists);
}

export function* setupBoards(meetingId: IMeeting['id']) {
  yield put(actions.clearBoard());
  yield put(actions.clearCurrentBoard());
  yield call(boardListsService.clearBoardLists);
  const boards = (yield call(boardResourceService.searchBoards, meetingId)) as IBoard[];
  yield put(boards ? actions.storeBoards(boards.map((board) => board.id)) : actions.clearBoard());
  return boards;
}

export function* createBoard(boardInfo: IBoardCreateForm) {
  const board = (yield call(boardResourceService.createBoard, mapBoardFormToBoardCreate(boardInfo))) as IBoard;
  yield;
  if (!board) {
    return;
  }

  yield call(dialogService.closeDialog, 'boardFormDialog');
}

export function* storeCurrentBoard(currentBoard: IBoard['id']) {
  yield call(boardListsService.setupBoardLists, currentBoard);
}

export function* updateBoard(boardId: IBoard['id'], boardInfo: IBoardCreateForm) {
  const board = (yield call(boardResourceService.updateBoard, {
    id: boardId,
    ...mapBoardFormToBoardCreate(boardInfo),
  })) as IBoard;

  if (!board) {
    return;
  }

  yield call(dialogService.closeDialog, 'boardFormDialog');
}

export function* deleteBoard(boardId: IBoard['id']) {
  yield call(boardResourceService.deleteBoard, boardId);
  yield put(actions.clearCurrentBoard());
  const boardsIds: IBoard['id'][] = yield select(boardsPageSelectors.selectBoards);

  yield put(boardsIds ? actions.storeBoards(boardsIds.filter((id) => boardId !== id)) : actions.clearBoard());
}

export const mapBoardFormToBoardCreate = (boardInfo: IBoardCreateForm): IBoardCreate => ({
  name: boardInfo.name,
  meetingIds: boardInfo.meetingIds.map((tupple) => tupple.value),
  color: boardInfo.color,
});
export function* clearBoardsPageStorage() {
  yield put(actions.clearBoard());
  yield put(actions.clearCurrentBoard());
  yield call(boardListsService.clearBoardLists);
}

export function* handleBoardFromNotification(boardResponse: IBoardFromNotification) {
  const currentMeeting = (yield select(meetingsPageSelectors.selectCurrentMeeting)) as IMeeting['id'];
  const meetingExists = boardResponse.meetingIds.filter((meetingId) => meetingId === currentMeeting);
  if (meetingExists.length > 0) {
    const boardsIds = (yield select(boardsPageSelectors.selectBoards)) as IBoard['id'][];
    yield call(boardResourceService.storeBoard, { ...boardResponse });

    yield put(actions.storeBoards([...boardsIds, boardResponse.id]));
    yield call(boardListsService.setupBoardLists, boardResponse.id);
  } else {
    const boardsIds = (yield select(boardsPageSelectors.selectBoards)) as IBoard['id'][];
    const filteredBoardIds = boardsIds.filter((boardId) => boardId !== boardResponse.id);
    yield put(actions.storeBoards(filteredBoardIds));
  }
}

export function* handleDeleteBoardFromNotification(boardResponse: IBoardFromNotification) {
  const boardIds = (yield select(boardsPageSelectors.selectBoards)) as IBoard['id'][];
  const filteredBoardIds = boardIds.filter((boardId) => boardId !== boardResponse.id);
  console.log(filteredBoardIds);
  yield put(actions.storeBoards(filteredBoardIds));
}

export function* saveBoardOrder(boardIds: IBoard['id'][]) {
  yield call(boardResourceService.saveBoardOrder, { boardIds });
}
