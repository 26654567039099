import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './meeting-form.constants';
import * as types from './meeting-form.types';
import * as services from './meeting-form.saga-services';

function* invokeInitalMeetingForm(action: types.IInitialMeetingForm) {
  yield call(services.initalMeetingForm, action.payload);
}

export function* watchMeetingsFormSaga() {
  yield takeLatest(constants.INITIAL_MEETING_FORM, invokeInitalMeetingForm);
}
