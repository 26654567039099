import { all, call, fork, takeEvery, takeLatest } from 'redux-saga/effects';

import * as constants from './meetings.constants';
import * as types from './meetings.types';
import * as services from './meetings.saga-services';
import { watchMeetingsFormSaga } from '../../meetings/meeting-form/meeting-form.saga-watchers';

function* invokeSetupMeetings(action: types.IFetchMeetings) {
  yield call(services.setupMeetings, action.payload);
}

function* invokeCreateMeeting(action: types.ICreateMeeting) {
  yield call(services.createNewMeeting, action.payload);
}

function* invokeUpdateMeeting(action: types.IUpdateMeeting) {
  yield call(services.updateNewMeeting, action.payload.id, action.payload.data);
}

function* invokeDeleteMeeting(action: types.IDeleteMeeting) {
  yield call(services.deleteMeeting, action.payload);
}

function* invokeFinishMeetingSession(action: types.IFinishMeetingSession) {
  yield call(services.finishMeetingSession, action.payload.meetingId, action.payload.sessionId);
}

function* invokeSaveMeetingSocket(action: types.ISaveMeetingSocket) {
  yield call(services.handleMeetingFromNotification, action.payload);
}

export function* watchMeetingsPurePageSaga() {
  yield takeLatest(constants.FETCH_MEETINGS, invokeSetupMeetings);
  yield takeLatest(constants.CREATE_MEETING, invokeCreateMeeting);
  yield takeLatest(constants.UPDATE_MEETING, invokeUpdateMeeting);
  yield takeLatest(constants.DELETE_MEETING, invokeDeleteMeeting);
  yield takeLatest(constants.FINISH_MEETING_SESSION, invokeFinishMeetingSession);

  yield takeEvery(constants.SAVE_MEETING_SOCKET, invokeSaveMeetingSocket);
}

export function* watchMeetingsPageSaga() {
  yield all([watchMeetingsPurePageSaga, watchMeetingsFormSaga].map(fork));
}
