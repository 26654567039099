/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { useRequiredValidation } from '../../../../../utils/form/validation.utils';

import { IKpiNoteFormProps } from './types';

import { FormSmallTextInput } from '../../inputs/SmallTextInput';
import { IKpiRowScoreboard } from '../../../../../app/resource/kpi-row-scoreboard/kpi-row-scoreboard.types';
import { saveScoreboard } from '../../../../../app/page/page-storage/kpi/kpi.actions';
import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { scoreSelectors } from '../../../../../app/resource/score/score.selectors';

export const KpiNoteForm: FunctionComponent<IKpiNoteFormProps> = ({ scoreboard }) => {
  const dispatch = useDispatch();

  const formProps = useForm();

  const requiredValidation = useRequiredValidation();
  const scoreId = scoreboard ? scoreboard.scoresId : '';
  const score = useSelectWithParams(scoreSelectors.selectResourceById, scoreId);

  const onSubmit = (data: IKpiRowScoreboard) => {
    const newScoreboard = {
      note: data.note,
      sessionId: scoreboard ? scoreboard.sessionId : '',
      scoresId: scoreboard ? scoreboard.scoresId : '',
      measured: scoreboard ? scoreboard.measured : '',
      id: scoreboard ? scoreboard.id : '',
    };

    dispatch(saveScoreboard(newScoreboard));
  };

  return (
    <FormProvider {...formProps}>
      <form
        id="kpinote-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IKpiRowScoreboard))}
        method="post"
      >
        <div className="border-bottom mb-2 pb-2">Measurable: {score?.title}</div>

        <div className="field-container">
          <FormSmallTextInput
            id="note"
            name="note"
            type="text"
            label="Description"
            placeholder="Enter"
            defaultValue={scoreboard?.note}
            validation={requiredValidation}
            multi
          />
        </div>
      </form>
    </FormProvider>
  );
};
