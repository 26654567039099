/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { authSelectors } from '../../../../app/auth/auth.selectors';
import { storeFilteredMeetings } from '../../../../app/page/page-storage/meetings/meetings.actions';
import { meetingSelectors } from '../../../../app/resource/meeting/meeting.selectors';
import { userSelectors } from '../../../../app/resource/user/user.selectors';
import { useSelect, useSelectWithParams } from '../../../../utils/selector.utils';
import { MeetingBadge } from './MeetingBadge';
import { MeetingReview } from './MeetingReview';

export const UserBar = () => {
  const dispatch = useDispatch();
  const userId = useSelect(authSelectors.selectLoggedUser);
  const user = useSelectWithParams(userSelectors.selectResourceById, userId ? userId : '');

  // const openTaskFormDialog = useCallback(() => {
  //   dispatch(
  //     openDialog('taskFormDialog', {
  //       type: '2',
  //       userId: userId ? userId : undefined,
  //     }),
  //   );
  // }, [dispatch, userId]);

  // const reducedMeetings = useSelect(meetingsPageSelectors.selectReducedMeetings);
  // const meetingIds = useMemo(() => {
  //   let ids: IMeeting['id'][] = [];
  //   if (reducedMeetings['meeting']) {
  //     ids = [...ids, ...reducedMeetings['meeting']];
  //   }
  //   if (reducedMeetings['account']) {
  //     ids = [...ids, ...reducedMeetings['account']];
  //   }
  //   return ids;
  // }, [reducedMeetings]);

  const meetingIds = useSelect(meetingSelectors.selectResourceIds);

  const [meetingFilter, setMeetingFilter] = useState<boolean[]>([]);
  useEffect(() => {
    setMeetingFilter(Array(meetingIds.length).fill(false));
  }, [meetingIds]);

  const setToggledMeeting = useCallback(
    (index) => {
      const newMeetingFilter = [...meetingFilter];
      newMeetingFilter[index] = !newMeetingFilter[index];
      setMeetingFilter(newMeetingFilter);

      const filteredMeetingIds = meetingIds.filter((meetingId, index) => !newMeetingFilter[index]);
      if (userId && filteredMeetingIds) {
        //dispatch(filterTaskForUserByMeetingIds(userId, filteredMeetingIds));
        const newFilteredMeetings = meetingIds.filter((id) => !filteredMeetingIds.includes(id));
        dispatch(storeFilteredMeetings(newFilteredMeetings));
      }
    },
    [dispatch, meetingFilter, meetingIds, userId],
  );

  const [toggleReview, setToggleReview] = useState(false);

  const onToggleReview = useCallback(() => {
    setToggleReview(!toggleReview);
  }, [toggleReview]);

  return (
    user && (
      <div className="row mb-2">
        <div className="col-12">
          <div className="panel flex aic py-1 px-2">
            <div className="big-avatar mr-2">
              <img
                src={user.image}
                alt=""
                className="img-fluid"
                style={{ borderRadius: '50%', height: '60px', width: '60px' }}
              />
            </div>
            <h2 className="mr-3 bold">Good Morning, {user.firstName}!</h2>
            <label className="f-1">show/hide teams:</label>
            <div className="show-hide-teams-container">
              <div className="show-hide-teams">
                {meetingIds.map((meetingId, index) => (
                  <MeetingBadge
                    key={meetingId}
                    meetingId={meetingId}
                    toggled={meetingFilter[index]}
                    setToggled={() => setToggledMeeting(index)}
                  />
                ))}
              </div>
            </div>
            <div className="ml-auto flex">
              {/* <a className="btn btn-md darkGray-bg popup_button" title="Add a Topic" onClick={openTaskFormDialog}>
                <i className="icon-plus mr-1"></i> Add a Topic
              </a> */}
              {/*
              <a className="btn btn-md darkGray-bg popup_button" data-popup="upload_file_popup" title="Upload a File">
                <img alt="" className="img-fluid mr-1" /> Upload a File
              </a>*/}
              <div className={toggleReview ? 'dropdown opened' : 'dropdown'} onClick={onToggleReview}>
                <a className="btn btn-md darkGray-bg dropdown-button" data-action="dropdown" title="Review KPIs">
                  <i className="icon-info mr-1" />
                  Review KPIs
                </a>
                <ul className="dropdown-menu no-lines drop-right">
                  {meetingIds.map((meetingId) => (
                    <MeetingReview key={meetingId} meetingId={meetingId} />
                  ))}
                </ul>
              </div>
              {/*
              <a className="btn btn-md darkGray-bg popup_button" data-popup="invite_user_popup" title="Invite User">
                <i className="icon-user mr-1"></i>Invite User
              </a>*/}
            </div>
          </div>
        </div>
      </div>
    )
  );
};
