import { createActionConst } from '../../../../utils/action.utils';

const KPIS_NAMESPACE = 'KPIS';

export const STORE_KPI_ROW_IDS = createActionConst(KPIS_NAMESPACE, 'STORE_KPI_ROW_IDS');
export const CLEAR_KPI_ROW_IDS = createActionConst(KPIS_NAMESPACE, 'CLEAR_KPI_ROW_IDS');

export const STORE_SESSION_IDS = createActionConst(KPIS_NAMESPACE, 'STORE_SESSION_IDS');
export const CLEAR_SESSION_IDS = createActionConst(KPIS_NAMESPACE, 'CLEAR_SESSION_IDS');

export const STORE_SCORE_IDS = createActionConst(KPIS_NAMESPACE, 'STORE_SCORE_IDS');
export const CLEAR_SCORE_IDS = createActionConst(KPIS_NAMESPACE, 'CLEAR_SCORE_IDS');

export const FETCH_KPI = createActionConst(KPIS_NAMESPACE, 'FETCH_KPI');

export const SAVE_SCOREBOARD = createActionConst(KPIS_NAMESPACE, 'SAVE_SCOREBOARD');
export const CREATE_SCORE = createActionConst(KPIS_NAMESPACE, 'CREATE_SCORE');
export const UPDATE_SCORE = createActionConst(KPIS_NAMESPACE, 'UPDATE_SCORE');
export const SAVE_SCORE_ORDER = createActionConst(KPIS_NAMESPACE, 'SAVE_SCORE_ORDER');

export const SAVE_SCOREBOARD_SOCKET = 'saveScoreBoard';
