/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { FunctionComponent } from 'react';
import { questionSelectors } from '../../../../../app/resource/question/question.selectors';
import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { Answer } from '../../../AgencyDashboardPage/Item/Columns/Column/Question/Answer';
import { IQuestionRowProps } from './types';

export const QuestionRow: FunctionComponent<IQuestionRowProps> = ({ questionId }) => {
  const question = useSelectWithParams(questionSelectors.selectResourceById, questionId);
  return (
    <div
      className="flex aic mb-1"
      style={{
        flexBasis: '100%',
        padding: `0 15px`,
      }}
    >
      {question && question.question !== '' && (
        <div
          className={`panel lightGray-bg min-h60 no-shadow py-1 px-2 flex aic mb-2 profile-label ${question.cssClass}`}
          dangerouslySetInnerHTML={{ __html: question.question }}
          style={{
            width: `${question?.questionLength}%`,
            maxWidth: `${question?.questionLength}%`,
            flex: `0 0 ${question?.questionLength}%`,
          }}
        >
          {/* {question?.question.split('<br/>').join('\n')} */}
        </div>
      )}
      {question?.answerIds.map((answerId) => (
        <div
          key={answerId}
          style={{
            flexGrow: 1,
          }}
        >
          <Answer answerId={answerId} />
        </div>
      ))}
    </div>
  );
};
