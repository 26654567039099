import React, { FunctionComponent, useCallback, useState } from 'react';
import { userSelectors } from '../../../../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../../../../utils/selector.utils';
import { ICalendarFilterItemPorps } from './types';
export const CalendarFilterItem: FunctionComponent<ICalendarFilterItemPorps> = ({ userId, onToggle }) => {
  const user = useSelectWithParams(userSelectors.selectResourceById, userId);
  const [checked, setChecked] = useState(true);

  const handleToggle = useCallback(() => {
    setChecked((old) => !old);
    onToggle(userId);
  }, [onToggle, userId]);

  return (
    user && (
      <div key={user.id} className={`border-checkbox  ml-1 green-checkbox`}>
        <input type="checkbox" checked={checked} name="cal_view" onChange={handleToggle} id={`calendar-${user.id}`} />
        <label htmlFor={`calendar-${user.id}`}>{`${user.firstName} ${user.lastName}`}</label>
      </div>
    )
  );
};
