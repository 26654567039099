import { call, put } from '@redux-saga/core/effects';
import { select, selectWithParams } from '../../../../../utils/selector.utils';
import { storeBoardList } from '../../../../resource/boards/boardList/boardList.actions';
import { boardListSelectors } from '../../../../resource/boards/boardList/boardList.selectors';
import { IBoardList } from '../../../../resource/boards/boardList/boardList.types';
import { IMeeting } from '../../../../resource/meeting/meeting.types';
import { ITask } from '../../../../resource/task/task.types';
import { meetingsPageSelectors } from '../../meetings/meetings.selectors';
import { generateBoardListId, backendStatusToTStatus } from '../tasks.saga-services';

export function* saveDefaultByType(task: ITask) {
  const meetingId = (yield select(meetingsPageSelectors.selectCurrentMeeting)) as IMeeting['id'];
  yield call(insertIntoDefaultByType, meetingId, task);
}

export function* insertIntoDefaultByType(meetingId: IMeeting['id'], task: ITask) {
  const boardListIds = [
    generateBoardListId(
      meetingId === null ? '' : meetingId,
      'groupMeetingRocksByUser',
      { taskTypeId: task.taskTypeId, userId: task.userId },
      backendStatusToTStatus(task.status),
    ),
  ];

  const boardLists = (yield selectWithParams(boardListSelectors.selectResourcesById, boardListIds)) as (
    | IBoardList
    | undefined
  )[];

  // TODO PART
  const boardListIdsToCreate: IBoardList['id'][] = [];
  boardLists.forEach((boardList, i) => {
    if (!boardList) {
      boardListIdsToCreate.push(boardListIds[i]);
    }
  });
  //

  const allBoardLists = boardLists.filter(
    (boardList) => boardList && !boardList.tasksIds.includes(task.id),
  ) as IBoardList[];

  yield put(
    storeBoardList(allBoardLists.map((boardList) => ({ ...boardList, tasksIds: [...boardList.tasksIds, task.id] }))),
  );
}
