import React, { FunctionComponent, ReactNode } from 'react';
import { IDialogKey } from '../../../app/dialog/dialog.types';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { useSelectWithParams } from '../../../utils/selector.utils';

export const ConnectedDialog: FunctionComponent<{ children: ReactNode } & { dialogKey: IDialogKey }> = (props) => {
  const isOpen = useSelectWithParams(dialogSelectors.selectIsDialogOpen, props.dialogKey);

  return isOpen ? <div className="overlay">{props.children}</div> : null;
};
