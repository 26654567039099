import { combineReducers, Reducer } from 'redux';

import * as types from './agenda-items.types';
import * as constants from './agenda-items.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IAgendaItemsPageState = {
  agendaItems: [],
  currentAgendaIndex: null,
};

const agendaItemsReducer: Reducer<
  types.IAgendaItemsPageState['agendaItems'],
  types.IStoreAgendaItems | types.IClearAgendaItems
> = (state = initialState.agendaItems, action) => {
  if (isActionOfType<types.IStoreAgendaItems>(action, constants.STORE_AGENDA_ITEMS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearAgendaItems>(action, constants.CLEAR_AGENDA_ITEMS)) {
    return initialState.agendaItems;
  }
  return state;
};

const currentAgendaIndexReducer: Reducer<
  types.IAgendaItemsPageState['currentAgendaIndex'],
  types.IStoreCurrentAgenda | types.IClearCurrentAgenda
> = (state = initialState.currentAgendaIndex, action) => {
  if (isActionOfType<types.IStoreCurrentAgenda>(action, constants.STORE_CURRENT_AGENDA_INDEX)) {
    return action.payload;
  } else if (isActionOfType<types.IClearCurrentAgenda>(action, constants.CLEAR_CURRENT_AGENDA_INDEX)) {
    return initialState.currentAgendaIndex;
  }
  return state;
};

export const agendaItemsPageReducer: Reducer<
  types.IAgendaItemsPageState,
  types.IAgendaItemsPageAction
> = combineReducers({
  agendaItems: agendaItemsReducer,
  currentAgendaIndex: currentAgendaIndexReducer,
});
