import { combineReducers, Reducer } from 'redux';

import * as types from './user-form.types';
import * as constants from './user-form.constants';
import { isActionOfType } from '../../../../../utils/action.utils';

const initialState: types.IUserFormState = {
  selectableAccounts: [],
  selectableTeams: [],
  userAvatar: '',
};

const selectableAccountsReducer: Reducer<
  types.IUserFormState['selectableAccounts'],
  types.IStoreSelectableAccounts | types.IClearSelectableAccounts
> = (state = initialState.selectableAccounts, action) => {
  if (isActionOfType<types.IStoreSelectableAccounts>(action, constants.STORE_SELECTABLE_ACCOUNTS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearSelectableAccounts>(action, constants.CLEAR_SELECTABLE_ACCOUNTS)) {
    return initialState.selectableAccounts;
  }
  return state;
};

const selectableTeamsReducer: Reducer<
  types.IUserFormState['selectableTeams'],
  types.IStoreSelectableTeams | types.IClearSelectableTeams
> = (state = initialState.selectableTeams, action) => {
  if (isActionOfType<types.IStoreSelectableTeams>(action, constants.STORE_SELECTABLE_TEAMS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearSelectableTeams>(action, constants.CLEAR_SELECTABLE_TEAMS)) {
    return initialState.selectableTeams;
  }
  return state;
};

const userAvatarReducer: Reducer<types.IUserFormState['userAvatar'], types.ISaveUserAvatar | types.IClearUserAvatar> = (
  state = initialState.userAvatar,
  action,
) => {
  if (isActionOfType<types.ISaveUserAvatar>(action, constants.SAVE_USER_AVATAR)) {
    return action.payload;
  } else if (isActionOfType<types.IClearUserAvatar>(action, constants.CLEAR_USER_AVATAR)) {
    return initialState.userAvatar;
  }
  return state;
};

export const userFormReducer: Reducer<types.IUserFormState, types.IUserFormAction> = combineReducers({
  selectableAccounts: selectableAccountsReducer,
  selectableTeams: selectableTeamsReducer,
  userAvatar: userAvatarReducer,
});
