import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './users.constants';
import * as types from './users.types';
import * as services from './users.saga-services';

function* invokeInitialUserPage(action: types.IInitialUserPage) {
  yield call(services.initialUserPage);
}

export function* watchUserPageInitialSaga() {
  yield takeLatest(constants.INITIAL_USER_PAGE, invokeInitialUserPage);
}
