/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../app/dialog/dialog.actions';
import { quartalsPageSelectors } from '../../../app/page/page-storage/quartals/quartals.selectors';
import { initialQuartalsPage } from '../../../app/page/quartals/quartals.actions';
import { useSelect } from '../../../utils/selector.utils';
import { PageLayout } from '../common/PageLayout';
import { QuartalRow } from './QuartalRow';

export const QuartalsPage: FunctionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialQuartalsPage());
  }, [dispatch]);

  const quartalsIds = useSelect(quartalsPageSelectors.selectQuartals);

  const openQuartalFormDialog = useCallback(() => {
    dispatch(openDialog('quartalFormDialog'));
  }, [dispatch]);

  return (
    <PageLayout>
      {' '}
      <div className="row mb-25">
        <div className="col-12">
          <div className="panel">
            <div className="panel-body">
              <div
                style={{
                  borderCollapse: 'collapse',
                  margin: '35px auto 0 auto',
                  fontSize: '0.9em',
                  minWidth: '400px',
                  borderRadius: '10px 10px 0 0',
                  overflow: 'hidden',
                  boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
                  width: '80%',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',

                    backgroundColor: '#1bc3a7',
                    color: '#ffffff',
                    fontWeight: 'bold',
                  }}
                >
                  <span style={{ flexBasis: '15%', padding: '12px 15px', textAlign: 'start' }}>Year</span>
                  <span style={{ flexBasis: '20%', padding: '12px 15px', textAlign: 'center' }}>Start quarter I</span>
                  <span style={{ flexBasis: '20%', padding: '12px 15px', textAlign: 'center' }}>Start quarter II</span>
                  <span style={{ flexBasis: '20%', padding: '12px 15px', textAlign: 'center' }}>Start quarter III</span>
                  <span style={{ flexBasis: '20%', padding: '12px 15px', textAlign: 'center' }}>Start quarter IV</span>
                  <span style={{ flexBasis: '5%', padding: '12px 15px', textAlign: 'center' }}>MENU</span>
                </div>

                {quartalsIds.map((id) => (
                  <QuartalRow quartalId={id} key={`${id}-quartal`} />
                ))}
                <div style={{ borderTop: '2px solid #1bc3a7' }}></div>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', padding: '20px 10px 0 10px', justifyContent: 'center' }}
              >
                <a
                  className="btn-badge green-bg popup_button"
                  data-popup="new_todo_popup"
                  title="Add quartal"
                  onClick={openQuartalFormDialog}
                >
                  <i className="icon-plus" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
