import { put, select, call } from 'redux-saga/effects';
import * as types from './answer.types';
import * as actions from './answer.actions';

import * as api from '../../api/request-config/answer.api';
import { answerSelectors } from './answer.selectors';
import { apiRequest } from '../../api/api.saga-services';

export function* storeAnswer(answer: types.IAnswer) {
  yield put(actions.storeAnswer(answer));

  return answer;
}

export function* clearAnswer(answerId: types.IAnswer['id']) {
  yield put(actions.clearAnswer(answerId));
}

export function* clearAllAnswers() {
  const answerIds: types.IAnswer['id'][] = yield select(answerSelectors.selectResourceIds);
  yield put(actions.clearAnswer(answerIds));
}

// export function* searchAnswers() {
//   const Answers: types.IAnswer[] = yield call(apiRequest, api.searchAnswersApi());

//   yield all(Answers.map((Answer) => call(storeAnswer, Answer)));
//   return Answers.map((Answer) => Answer.id);
// }

// export function* createAnswer(data: types.IAnswerCreate) {
//   const Answer = (yield call(apiRequest, api.createAnswerApi(data))) as types.IAnswer;
//   if (!Answer) {
//     return;
//   }
//   yield call(storeAnswer, Answer);
//   return Answer;
// }

export function* updateAnswer(data: types.IAnswerUpdate) {
  const answer = (yield call(apiRequest, api.updateAnswerApi(data))) as types.IAnswer;
  if (!answer) {
    return;
  }
  answer.id = data.id;
  yield call(storeAnswer, answer);
  return answer;
}

// export function* deleteAnswer(AnswerId: types.IAnswer['id']) {
//   yield call(apiRequest, api.deleteAnswerApi(AnswerId));
// }
