import * as types from './account-projects.types';
import * as constants from './account-projects.constants';
import { IAccountProject } from '../../../resource/account-project/account-project.types';
/*
export const createAccountProject = (accountProject: IAccountProjectCreateForm): types.ICreateAccountProject => ({
  type: constants.CREATE_ACCOUNT_PROJECT,
  payload: accountProject,
});

export const updateAccountProject = (
  accountProjectId: IAccountProject['id'],
  accountProject: IAccountProjectCreateForm,
): types.IUpdateAccountProject => ({
  type: constants.UPDATE_ACCOUNT_PROJECT,
  payload: {
    id: accountProjectId,
    data: accountProject,
  },
});

export const deleteAccountProject = (accountProjectId: IAccountProject['id']): types.IDeleteAccountProject => ({
  type: constants.DELETE_ACCOUNT_PROJECT,
  payload: accountProjectId,
});
*/
export const storeAccountProjects = (accountProjects: IAccountProject['id'][]): types.IStoreAccountProject => ({
  type: constants.STORE_ACCOUNT_PROJECT,
  payload: accountProjects,
});

export const clearAccountProject = (): types.IClearAccountProject => ({
  type: constants.CLEAR_ACCOUNT_PROJECT,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
