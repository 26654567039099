/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { authSelectors } from '../../../../app/auth/auth.selectors';

import { boardListsPageSelectors } from '../../../../app/page/page-storage/boardLists/boardLists.selectors';

import { boardSelectors } from '../../../../app/resource/boards/board/board.selectors';
import { meetingSelectors } from '../../../../app/resource/meeting/meeting.selectors';
import { useSelect, useSelectWithParams } from '../../../../utils/selector.utils';
import { BoardBody } from './BoardBody';
import { BoardHeader } from './BoardHeader';
import { IBoardProps } from './types';

export const Board: FunctionComponent<IBoardProps> = ({ boardId, meetingId, unextendableBoardList }) => {
  const board = useSelectWithParams(boardSelectors.selectResourceById, boardId);
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);
  const boardListsIds = useSelect(boardListsPageSelectors.selectBoardLists);

  const userId = useSelect(authSelectors.selectLoggedUser);

  const admin = meeting?.administratorIds.includes(userId ? userId : '');
  return (
    <div>
      {board && meeting && <BoardHeader boardData={board} meeting={meeting} />}
      {board && admin !== undefined && (
        <BoardBody
          boardListsIds={boardListsIds}
          boardId={boardId}
          meetingId={meetingId}
          virtualFlag={true}
          unextendableBoard={unextendableBoardList}
          isAdmin={admin}
        />
      )}
    </div>
  );
};
