/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { chooseClient, chooseTimerResult } from '../../../../../../app/page/timer/timer.actions';

import { meetingSelectors } from '../../../../../../app/resource/meeting/meeting.selectors';
import { useSelectWithParams } from '../../../../../../utils/selector.utils';
import { ITaskTimeProps } from './types';

export const TaskTime: FunctionComponent<ITaskTimeProps> = ({ taskTime }) => {
  const dispatch = useDispatch();
  const account = useSelectWithParams(meetingSelectors.selectResourceById, taskTime.meetingId);

  const openTracker = useCallback(() => {
    dispatch(chooseClient(taskTime.meetingId, true));
  }, [dispatch, taskTime.meetingId]);

  const openTrackerEdit = useCallback(() => {
    dispatch(chooseTimerResult(taskTime.id));
  }, [dispatch, taskTime.id]);

  return (
    <div className="tracking-row">
      <div className="tracking-icon">
        <img src={account?.image} alt="" className="img-fluid" />
      </div>
      <div className="tracking-client">
        <a className="tracking-client-name">{account?.title}</a>
        <div className="tracking-client-work-type blueGray">{taskTime.title}</div>
      </div>
      <div className="tracking-actions">
        <div className="tracking-actions-time timetracker-on">
          {Math.floor(Number(taskTime.minutes) / 60)}:
          {taskTime.minutes.length < 2 ? `0${Number(taskTime.minutes) % 60}` : Number(taskTime.minutes) % 60}
        </div>
        <div className="tracking-actions-start play_tracker" onClick={openTracker}>
          <a title="Start" className="btn green-bg px-2 mx-2">
            <i className="icon-clock mr-1 f-10" /> Start
          </a>
        </div>
        <div className="round-icon-small mr-1 edit_tracker" onClick={openTrackerEdit}>
          <i className="icon-pencil" />
        </div>
        <div className="round-icon-small mr-1 popup_button" data-popup="delete_popup">
          <i className="icon2-trash" />
        </div>
      </div>
    </div>
  );
};
