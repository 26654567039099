import { call, cancelled, put } from 'redux-saga/effects';
import axios from 'axios';
import humps, { createHumps } from 'lodash-humps-ts';
import { snakeCase } from 'lodash';

// import { logout } from '../auth/auth.saga-services';
import * as types from './api.types';
import * as actions from './api.actions';

import { select } from '../../utils/selector.utils';
import { authSelectors } from '../auth/auth.selectors';

// SINGLETON, we don't need multiple instances of this
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '',
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json',
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* apiRequest<TParams = any, TPayload = any, TResponse = any>(
  requestInfo: types.IApiRequestConfig<TParams, TPayload>,
) {
  const accessToken = yield select(authSelectors.selectAccessToken);
  const cancelSource = yield call([axios.CancelToken, axios.CancelToken.source]);
  try {
    yield put(actions.storeApiInProgress(requestInfo.apiRouteKey));
    yield put(actions.clearApiError(requestInfo.apiRouteKey));

    const snakes = createHumps(snakeCase);
    const responseUnderscore = yield call([api, api.request], {
      // url: `${requestInfo.uri}/`,
      url: requestInfo.uri,
      method: requestInfo.method,
      params: {
        action: requestInfo.action,
        ...snakes(requestInfo.params),
        rand: requestInfo.method === 'get' ? Math.floor(Math.random() * 100000) : undefined,
      },
      data: {
        ...snakes(requestInfo.data),
      },
      cancelToken: cancelSource.token,
      responseType: requestInfo.responseType ? requestInfo.responseType : undefined,
      headers: { Authorization: `Bearer ${accessToken}` },
      ...requestInfo.overrides,
    });
    yield put(actions.clearApiInProgress(requestInfo.apiRouteKey));

    const response = humps(responseUnderscore);
    if (response && response.data && response.data.status === 'fail') {
      yield put(actions.storeApiError(requestInfo.apiRouteKey, { message: response.data.message }));
      return null;
    } else if (response && response.data) {
      if (Object.keys(response.data.result).length === 1) {
        return response.data.result.list as TResponse[];
      } else {
        const data = response.data.result;
        delete data.list;
        return data as TResponse;
      }
    }
    yield put(actions.storeApiError(requestInfo.apiRouteKey, { message: 'Something went wrong' }));
    return null;
  } catch (e) {
    if (e.response?.data) {
      yield put(actions.storeApiError(requestInfo.apiRouteKey, e.response.data));
      yield put(actions.clearApiInProgress(requestInfo.apiRouteKey));
      return null;
    }

    throw e;
  } finally {
    if (yield cancelled()) {
      // cancel api call if task is cancelled
      yield call([cancelSource, cancelSource.cancel]);
    }
  }
}
