import React, { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createChecklistItem } from '../../../../../app/page/page-storage/checklists/checkLists.actions';

import { IChecklistItemCreateForm } from '../../../../../app/resource/checklistItem/checklistItem.types';
import { useRequiredValidation } from '../../../../../utils/form/validation.utils';

import { FormSmallTextInputCI } from '../../inputs/SmallTextInputCI';

import { IChecklistItemFormProps } from './types';

export const ChecklistItemForm: FunctionComponent<IChecklistItemFormProps> = ({ checkListId, onClick }) => {
  const dispatch = useDispatch();

  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: IChecklistItemCreateForm) => {
    onClick();

    data.checklistId = checkListId;
    data.status = '0';

    dispatch(createChecklistItem({ ...data }));
  };

  return (
    <FormProvider {...formProps}>
      <form
        id={`checklistItem-form-${checkListId}`}
        className="add-checklist-item"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IChecklistItemCreateForm))}
        method="post"
      >
        <>
          <FormSmallTextInputCI
            id={`title-${checkListId}`}
            name="title"
            type="text"
            placeholder="Add checklist item"
            validation={requiredValidation}
          />
        </>
        <button type="submit" className="btn btn-md">
          ADD
        </button>
      </form>
    </FormProvider>
  );
};
