import { call, put } from 'redux-saga/effects';

import * as actions from './templates.actions';

import * as templateService from '../../../resource/template/template.saga-service';
import * as sectionService from '../../../resource/section/section.saga-service';
import * as dialogService from '../../../dialog/dialog.saga-services';

import { ITemplate, ITemplateCreate, ITemplateSearchParams } from '../../../resource/template/template.types';
import { ISection, ISectionCreate, ISectionUpdate } from '../../../resource/section/section.types';
import { templatesPageSelectors } from './templates.selectors';
import { select } from '../../../../utils/selector.utils';

export function* fetchTemplates(searchParams?: ITemplateSearchParams) {
  const templateIds: ITemplate['id'][] = yield call(templateService.searchTemplates, searchParams);
  yield templateIds ? put(actions.storeTemplateIds(templateIds)) : put(actions.clearTemplateIds());
}

export function* addSection(sectionInfo: ISectionCreate) {
  const section = yield call(sectionService.createSection, sectionInfo);
  if (!section) {
    return;
  }
  yield call(dialogService.closeDialog, 'sectionFormDialog');
}

export function* updateSection(newSection: ISectionUpdate) {
  const section = yield call(sectionService.updateSection, newSection);

  if (!section) {
    return;
  }
  yield call(dialogService.closeDialog, 'sectionFormDialog');
}

export function* createTemplate(templateInfo: ITemplateCreate) {
  const templateId: ITemplate['id'] = yield call(templateService.createTemplate, templateInfo);

  const templateIds = yield select(templatesPageSelectors.selectTemplateIds);

  yield put(actions.storeTemplateIds([...templateIds, templateId]));
}

export function* deleteSection(sectionId: ISection['id']) {
  yield call(sectionService.deleteSection, sectionId);
  yield call(dialogService.closeDialog, 'sectionFormDialog');
}

export function* clearTemplatesPageStorage() {
  yield put(actions.clearTemplateIds());
}
