import moment from 'moment-timezone';
import React, { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { signUpUser } from '../../../../../app/page/page-storage/users/users.actions';

import { IUserSignUp, IUserSignUpForm } from '../../../../../app/resource/user/user.types';
import { usePasswordValidation, useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { AvatarInput } from '../../inputs/AvatarInput';

import { FormSmallTextInput } from '../../inputs/SmallTextInput';

export const SignUpForm: FunctionComponent = () => {
  const dispatch = useDispatch();

  const formProps = useForm();

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: IUserSignUpForm) => {
    const submitData = { ...data, timezone: moment.tz.guess() } as IUserSignUp; //data.timezone.value
    dispatch(signUpUser(submitData));
  };

  // const timezones = moment.tz.names();
  // const timezoneOptions = useMemo(
  //   () =>
  //     timezones.map((timezone) => ({
  //       value: timezone,
  //       label: timezone,
  //     })),
  //   [timezones],
  // );

  const password = formProps.watch('password');
  const repeatPassword = formProps.watch('repeatPassword');

  const passwordValidation = usePasswordValidation(password, repeatPassword, 6);

  return (
    <FormProvider {...formProps}>
      <form
        id="signUp-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IUserSignUpForm))}
        method="post"
      >
        <div className="field-container">
          <FormSmallTextInput
            id="firstName"
            name="firstName"
            type="text"
            label="First Name"
            placeholder="Enter First Name"
            validation={requiredValidation}
          />
        </div>
        <div className="field-container">
          <FormSmallTextInput
            id="lastName"
            name="lastName"
            type="text"
            label="Last Name"
            placeholder="Enter Last Name"
            validation={requiredValidation}
          />
        </div>
        <div className="field-container">
          <FormSmallTextInput
            id="email"
            name="email"
            type="email"
            label="Email"
            placeholder="Enter Email"
            validation={requiredValidation}
          />
        </div>
        <div className="field-container">
          <FormSmallTextInput
            id="password"
            name="password"
            type="password"
            label="Password"
            placeholder="Enter Password"
            validation={passwordValidation}
          />
        </div>
        <div className="field-container">
          <FormSmallTextInput
            id="repeatPassword"
            name="repeatPassword"
            type="password"
            label="Repeat Password"
            placeholder="Enter Password"
            validation={passwordValidation}
          />
        </div>

        <div className="field-container">
          <FormSmallTextInput
            id="address"
            name="address"
            type="text"
            label="Address"
            placeholder="Enter Address"
            // validation={requiredValidation}
          />
        </div>
        <div className="field-container">
          <FormSmallTextInput
            id="phone"
            name="phone"
            type="text"
            label="Phone"
            placeholder="Enter Phone"
            // validation={requiredValidation}
          />
        </div>
        {/* <div className="field-container">
          <SelectInput
            id="timezone"
            name="timezone"
            label="Timezone"
            placeholder="Enter Timezone"
            options={timezoneOptions}
            validation={requiredValidation}
            isSingle
          />
        </div> */}
        <div className="popup-upload-panel">
          {/* <ImageInput
            id="image"
            name="image"
            label={`Upload Contact Photo`}
            maxImageSize={2}
            acceptableFormats={'jpg, png, gif'}
            validation={requiredValidation}
          /> */}
          <AvatarInput
            id="image"
            name="image"
            label={`Upload Contact Photo`}
            maxImageSize={2}
            acceptableFormats={'jpg, png, gif'}
            // validation={requiredValidation}
          />
        </div>
      </form>
    </FormProvider>
  );
};
