/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { filterPasswords } from '../../../../../app/page/page-storage/passwords/passwords.actions';
import { passwordGroupSelectors } from '../../../../../app/resource/password-group/password-group.selectors';
import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { IPasswordFolderProps } from './types';

export const PasswordFolder: FunctionComponent<IPasswordFolderProps> = ({ passwordGroupId }) => {
  const dispatch = useDispatch();
  const passwordGroup = useSelectWithParams(passwordGroupSelectors.selectResourceById, passwordGroupId);

  const applyFilter = useCallback(() => {
    dispatch(filterPasswords({ groupId: passwordGroupId }));
  }, [dispatch, passwordGroupId]);

  return (
    passwordGroup && (
      <a
        className="directory-button panel small-panel flex aic px-1 mb-1"
        onClick={applyFilter}
        style={{ cursor: 'pointer' }}
      >
        <i className="icon2-folder mr-1 blueGray" /> {passwordGroup.name}
        <span className="ml-auto blueGray">{`${passwordGroup.passwordIds.length} items`}</span>
      </a>
    )
  );
};
