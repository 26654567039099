import { IUserGroupCreate, IUserGroupSearchParamas } from '../../resource/user-group/user-group.types';
import { IApiRequestConfig } from '../api.types';

export const searchUserGroupsApi = (params?: IUserGroupSearchParamas): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'searchUserGroups',
  uri: 'json',
  action: 'getUserGroups',
  method: 'get',
  params: {
    ...params,
  },
});

export const createUserGroupApi = (userGroup: IUserGroupCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createUserGroup',
  uri: 'json',
  action: 'saveUserGroup',
  method: 'post',
  data: userGroup,
});
