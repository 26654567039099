/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormCheckboxInput } from '../../../inputs/CheckboxInput';
import { FormCSTextInput } from '../../../inputs/CustomSizeTextInput';
import { FormSmallTextInput } from '../../../inputs/SmallTextInput';
import { DeleteAI } from './DeleteAI';

import { IAgendaItemProps } from './types';

export const AgendaItem: FunctionComponent<IAgendaItemProps> = ({
  index,
  agendaItem,
  removeAgendaItem,
  defaultCheckbox,
  provided,
}) => {
  const { control, setValue } = useFormContext();
  const [onEdit, onEditUpdate] = useState(agendaItem.title === '' ? true : false);
  const formTitle = useWatch<{ [key: string]: string }>({
    control,
    name: `agendaItems[${index}].title`,
  });
  const [disabled, setDisabled] = useState(false);
  const [checkTrigger, setTrigger] = useState(false);
  useEffect(() => {
    if (!defaultCheckbox) {
      if (Number(agendaItem.agendaId) > 7 || agendaItem.agendaId === '') {
        setValue(`agendaItems[${index}].checked`, '0');
        setDisabled(true);
        setTrigger(true);
      }
      if (Number(agendaItem.agendaId) < 8) {
        setValue(`agendaItems[${index}].checked`, '1');
      }
    } else {
      if (checkTrigger === true) {
        setValue(`agendaItems[${index}].checked`, '1');
        setTrigger(false);
      }

      setDisabled(false);
    }
  }, [agendaItem.agendaId, agendaItem.checked, checkTrigger, defaultCheckbox, index, setValue]);
  /*
  useEffect(() => {
    if (agendaItem && !disabled) {
      setValue(`agendaItems[${index}].duration`, agendaItem.duration);
    } else if (agendaItem && disabled) {
      setValue(`agendaItems[${index}].duration`, '0');
    }
  }, [agendaItem, disabled, index, setValue]);
*/

  return (
    <div
      className="one-item"
      id={`agendaItems[${index}].id`}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      draggable={true}
    >
      {agendaItem.id && (
        <>
          <div style={{ display: 'none' }}>
            <FormCSTextInput
              id={`agendaItems[${index}].id`}
              name={`agendaItems[${index}].id`}
              type="text"
              size="small-input"
              defaultValue={agendaItem.id}
            />
          </div>
        </>
      )}
      <div style={{ display: 'none' }}>
        <FormCSTextInput
          id={`agendaItems[${index}].agendaId`}
          name={`agendaItems[${index}].agendaId`}
          type="text"
          size="small-input"
          defaultValue={agendaItem.agendaId ? agendaItem.agendaId : ''}
        />
        <FormCSTextInput
          id={`agendaItems[${index}].boardId`}
          name={`agendaItems[${index}].boardId`}
          type="text"
          size="small-input"
          defaultValue={agendaItem.boardId}
        />
        <FormCSTextInput
          id={`agendaItems[${index}].boardListId`}
          name={`agendaItems[${index}].boardListId`}
          type="text"
          size="small-input"
          defaultValue={agendaItem.boardListId}
        />
        <FormCSTextInput
          id={`agendaItems[${index}].embedUrl`}
          name={`agendaItems[${index}].embedUrl`}
          type="text"
          size="small-input"
          defaultValue={agendaItem.embedUrl}
        />
      </div>
      <div style={{ display: 'none' }}>
        <FormCSTextInput
          id={`agendaItems[${index}].sort`}
          name={`agendaItems[${index}].sort`}
          type="text"
          size="small-input"
          defaultValue={agendaItem.sort ? agendaItem.agendaId : '99'}
        />
      </div>
      <div style={{ display: 'none' }}>
        <FormCSTextInput
          id={`agendaItems[${index}].description`}
          name={`agendaItems[${index}].description`}
          type="text"
          size="small-input"
          defaultValue={agendaItem.description ? agendaItem.description : ''}
        />
      </div>
      <i className="icon-hamburger" />
      <div className="flex aic jcl">
        {defaultCheckbox ? (
          <FormCheckboxInput
            id={`agendaItems[${index}].checked`}
            name={`agendaItems[${index}].checked`}
            defaultChecked={agendaItem.checked === '0' ? false : true}
          />
        ) : (
          <FormCheckboxInput
            id={`agendaItems[${index}].checked`}
            name={`agendaItems[${index}].checked`}
            defaultChecked={false}
            check={Number(agendaItem.agendaId) > 7 || agendaItem.agendaId === '' ? false : undefined}
            disabled={disabled}
          />
        )}
        <label
          htmlFor={`agendaItems[${index}].checked`}
          onDoubleClick={() => onEditUpdate(true)}
          style={onEdit ? { marginBottom: '13px', position: 'relative' } : { position: 'relative' }}
        >
          <label
            style={
              onEdit
                ? { display: 'none' }
                : {
                    display: 'block',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                  }
            }
          >
            {formTitle ? formTitle : agendaItem.title}
          </label>
        </label>

        <div style={onEdit ? { visibility: 'visible' } : { visibility: 'hidden' }} onBlur={() => onEditUpdate(false)}>
          <FormSmallTextInput
            id={`agendaItems[${index}].title`}
            name={`agendaItems[${index}].title`}
            type="text"
            defaultValue={agendaItem.title}
          />
        </div>
      </div>
      <div className="input-with-label">
        <FormCSTextInput
          id={`agendaItems[${index}].duration`}
          name={`agendaItems[${index}].duration`}
          type="text"
          defaultValue={agendaItem.duration ? agendaItem.duration : '0'}
          disabled={disabled}
          size="mw"
        />

        <span className="input-label">Minutes</span>
      </div>
      <DeleteAI agendaItem={index} removeAgendaItem={removeAgendaItem} />
    </div>

    /*
    <div className="flex">
      <div className="flex aic">
        <span className="remove-task" onClick={() => removeTask(index)}>
          ×
        </span>
        {AgendaItem.id && (
          <div style={{ display: 'none' }}>
            <FormCSTextInput
              id={`tasks[${index}].id`}
              name={`tasks[${index}].id`}
              type="text"
              size="small-input"
              defaultValue={AgendaItem.id}
            />
          </div>
        )}
        {AgendaItem.title}
        <div style={{ display: 'none' }}>
          <FormCSTextInput
            id={`tasks[${index}].title`}
            name={`tasks[${index}].title`}
            type="text"
            size="small-input"
            defaultValue={AgendaItem.title}
          />
        </div>
      </div>
      <div className="flex aic jcr ml-auto">
        <div className="remember-checkbox">
          <FormCheckboxInput
            id={`tasks[${index}].billable`}
            name={`tasks[${index}].billable`}
            defaultChecked={AgendaItem.billable === '1' ? true : false}
          />

          <label htmlFor={`tasks[${index}].billable`} />
        </div>
      </div>
      {focusBudgetTuple && focusBudgetTuple.value === 'fees_per_task' && (
        <div className="flex aic jcr max100 ml-1 task_member_budget_hours" style={{ display: 'block !important' }}>
          <div className="field-container flex aic jcr mb-0">
            <FormCSTextInput
              id="budget"
              name={`tasks[${index}].budget`}
              type="text"
              size="small-input"
              defaultValue={AgendaItem.budget ? AgendaItem.budget : '10'}
            />
          </div>
        </div>
      )}
      {focusBudgetTuple && focusBudgetTuple.value === 'hours_per_task' && (
        <div className="flex aic jcr max100 ml-1 task_member_budget_amount" style={{ display: 'none !important' }}>
          <div className="field-container flex aic jcr mb-0">
            $
            <FormCSTextInput
              id="budgetHours"
              name={`tasks[${index}].budgetHours`}
              type="text"
              size="small-input"
              defaultValue={AgendaItem.budgetHours ? AgendaItem.budgetHours : '100'}
            />
          </div>
        </div>
      )}

      {focusHourlyTuple && focusHourlyTuple.value === 'task' && (
        <div className="flex aic jcr max100 ml-1 task_member_hourly_rate" style={{ display: 'none !important' }}>
          <div className="field-container flex aic jcr mb-0">
            $
            <FormCSTextInput
              id={`tasks[${index}].hourlyRate`}
              name={`tasks[${index}].hourlyRate`}
              type="text"
              size="small-input"
              defaultValue={AgendaItem.hourlyRate ? AgendaItem.hourlyRate : '100'}
            />
          </div>
        </div>
      )}
    </div>*/
  );
};
