/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../app/dialog/dialog.actions';
import { updateUser } from '../../../../app/page/page-storage/users/users.actions';
import { userSelectors } from '../../../../app/resource/user/user.selectors';
import { IUser, IUserCreateForm } from '../../../../app/resource/user/user.types';
import { formatDateUS } from '../../../../utils/date.utils';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { MeetingInfo } from './MeetingInfo';
import { IUserInfoParams } from './types';

export const UserInfo: FunctionComponent<IUserInfoParams> = ({ selectedUserId }) => {
  const dispatch = useDispatch();

  const selectedUser = useSelectWithParams(userSelectors.selectResourceById, selectedUserId) as IUser;
  const openUserForm = useCallback(() => {
    dispatch(
      openDialog('userFormDialog', {
        data: selectedUser ? selectedUser : undefined,
        onSubmit: (user: IUserCreateForm, id?: IUser['id']) => {
          if (id) {
            dispatch(updateUser(id, user));
          }
        },
      }),
    );
  }, [dispatch, selectedUser]);
  useEffect(() => {
    console.log(selectedUser?.image);
  }, [selectedUser.image]);

  const teams = selectedUser?.teamsnew;
  const accounts = selectedUser?.accountsnew;

  return (
    selectedUser && (
      <div className="col-9 py-2 pr-3">
        <div className="border-radius-1 lightGray-bg p-2">
          <div className="panel px-5 py-2 no-hover">
            <div className="flex aic pb-3">
              <img src={selectedUser.image} alt="" className="img-fluid bigger-avatar mr-1" />
              <div className="avatar-desc">
                <h2>{selectedUser.firstName}</h2>
                <h2>{selectedUser.lastName}</h2>
                <h3 className="blueGray light">
                  ATAK Interactive
                  {/*loggedUser.jobTitle*/}
                  <br />
                  {selectedUser.jobTitle}
                </h3>
              </div>
            </div>
            {!!teams?.length && (
              <div className="directory-item">
                <div className="directory-item-left">Teams: </div>
                <div className="directory-item-right">
                  <i className="icon-user-full blueGray mr-1"></i>
                  {teams &&
                    teams.map((team) => team && <MeetingInfo selectedMeeting={team} key={`teamId-${team.id}`} />)}
                </div>
              </div>
            )}
            {!!accounts?.length && (
              <div className="directory-item">
                <div className="directory-item-left">Accounts:</div>
                <div className="directory-item-right">
                  <i className="icon-users blueGray mr-1"></i>
                  {accounts &&
                    accounts.map((acc) => acc && <MeetingInfo selectedMeeting={acc} key={`teamId-${acc.id}`} />)}
                </div>
              </div>
            )}
            {selectedUser.email && (
              <div className="directory-item">
                <div className="directory-item-left">Email:</div>
                <div className="directory-item-right">
                  <i className="icon2-at blueGray mr-1"></i>
                  <a href={`mailto:${selectedUser.email}`}>{selectedUser.email}</a>
                </div>
              </div>
            )}
            {selectedUser.phone && (
              <div className="directory-item">
                <div className="directory-item-left">Phone (work):</div>
                <div className="directory-item-right">
                  <i className="icon2-phone blueGray mr-1"></i>
                  <a href={`tel:${selectedUser.phone}`}>{selectedUser.phone}</a>
                </div>
              </div>
            )}
            {selectedUser.alternativePhone && (
              <div className="directory-item">
                <div className="directory-item-left">Phone (mobile):</div>
                <div className="directory-item-right">
                  <i className="icon2-phone blueGray mr-1"></i>
                  <a href={`tel:${selectedUser.alternativePhone}`}>{selectedUser.alternativePhone}</a>
                </div>
              </div>
            )}
            {selectedUser.address && (
              <div className="directory-item">
                <div className="directory-item-left">Address:</div>
                <div className="directory-item-right">
                  <i className="icon2-location blueGray mr-1"></i>
                  <a>{selectedUser.address}</a>
                </div>
              </div>
            )}
            {selectedUser.dob && (
              <div className="directory-item">
                <div className="directory-item-left">Birthday:</div>
                <div className="directory-item-right">
                  <i className="icon-calendar blueGray mr-1"></i>
                  <span>{formatDateUS(selectedUser.dob)}</span>
                </div>
              </div>
            )}
            {/* <div className="directory-item">
              <div className="directory-item-left">Slack:</div>
              <div className="directory-item-right">
                <i className="icon2-slack blueGray mr-1"></i>
                <a>{selectedUser.skype}</a>
              </div>
            </div> */}
            <p className="text-right flex aic mt-2 jcr">
              <a className="btn white-bg btn-border" title="Edit" onClick={openUserForm}>
                Edit
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  );
};
