import React, { useCallback, useState } from 'react';
import Logo from '../../resources/images/dark-logo.svg';
import { useDispatch } from 'react-redux';

import { IUserCredentials } from '../../../app/resource/user/user.types';
import { logIn } from '../../../app/auth/auth.actions';

// import { Spinner } from '../common/Spinner';
import { SocialMedia } from './SocialMedia';
import { LoginForm } from './LoginForm';
import { Footer } from './Footer';
import { SignUp } from './SignUp';
import { Dialogs } from '../../dialogs';

export const LoginPage = () => {
  const dispatch = useDispatch();
  const [rememberUser, setRememberUser] = useState(false);

  const login = useCallback(
    (data: IUserCredentials) => {
      dispatch(logIn({ ...data, rememberUser }));
    },
    [dispatch, rememberUser],
  );

  // // Test
  // useEffect(() => {
  //   dispatch(logOut());
  // }, [dispatch]);

  return (
    <>
      <main id="app-root" className="login">
        <div className="fixed-panel">
          <div className="panel w500">
            <div className="panel-body px-5 pb-0">
              <div className="my-5 text-center">
                <img src={Logo} alt="" className="img-fluid" />
              </div>
              <div className="login-form">
                <LoginForm onSubmit={login} />

                <Footer setRememberUser={setRememberUser} />
                <SignUp />
              </div>
            </div>
            <SocialMedia />
          </div>
        </div>
      </main>
      <Dialogs />
    </>
  );
};
