/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import { projectSelectors } from '../../../../app/resource/project/project.selectors';
import { IProjectUpdate } from '../../../../app/resource/project/project.types';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { ProjectForm } from '../../../pages/common/forms/ProjectForm';
import { IProjectFormDataProps } from './types';

export const ProjectFormData: FunctionComponent<IProjectFormDataProps> = ({ projectId }) => {
  const projectData = useSelectWithParams(projectSelectors.selectResourceById, projectId) as IProjectUpdate;

  return projectData ? <ProjectForm projectData={projectData} /> : <></>;
};
