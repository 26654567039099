import { put, select, call, all } from 'redux-saga/effects';
import * as types from './checkList.types';
import * as actions from './checkList.actions';
import { apiRequest } from '../../api/api.saga-services';
import * as api from '../../api/request-config/checkList.api';
import { checkListSelectors } from './checkList.selectors';
import { IChecklistItem } from '../checklistItem/checklistItem.types';
import { storeChecklistItem } from '../checklistItem/checklistItem.saga-service';
import { ITask } from '../task/task.types';

export function* storeCheckList(checkList: types.ICheckList) {
  yield put(actions.storeCheckList(checkList));

  return checkList;
}

export function* clearCheckList(checkListId: types.ICheckList['id']) {
  yield put(actions.clearCheckList(checkListId));
}

export function* clearAllCheckLists() {
  const checkListsIds: types.ICheckList['id'][] = yield select(checkListSelectors.selectResourceIds);
  yield put(actions.clearCheckList(checkListsIds));
}

export function* searchCheckLists(taskId: ITask['id']) {
  const checkLists: types.ICheckListResponse[] = yield call(apiRequest, api.searchCheckListsApi(taskId));
  const checklistItems = checkLists.reduce((prev, curr) => [...prev, ...curr.items], [] as IChecklistItem[]);

  yield all(checklistItems.map((checklistItem) => call(storeChecklistItem, checklistItem)));
  yield all(
    checkLists.map((checkList) =>
      call(storeCheckList, {
        id: checkList.id,
        title: checkList.title,
        taskId: checkList.taskId,
        changedAt: checkList.changedAt,
        changedBy: checkList.changedBy,
        deleted: checkList.deleted,
        checklistItemsIds: checkList.items.map((checkListItem) => checkListItem.id),
      }),
    ),
  );
  return checkLists.map((checkList) => checkList.id);
}

export function* createCheckList(data: types.ICheckListCreate) {
  const checkList = (yield call(apiRequest, api.createCheckListApi(data))) as types.ICheckListResponse;

  if (!checkList) {
    return;
  }
  yield all(checkList.items.map((checklistItem) => call(storeChecklistItem, checklistItem)));
  yield call(storeCheckList, {
    id: checkList.id,
    title: checkList.title,
    taskId: checkList.taskId,
    changedAt: checkList.changedAt,
    changedBy: checkList.changedBy,
    deleted: checkList.deleted,
    checklistItemsIds: checkList.items.map((checkListItem) => checkListItem.id),
  });
  return checkList;
}

export function* updateCheckList(data: types.ICheckListUpdate) {
  const checkList = (yield call(apiRequest, api.updateCheckListApi(data))) as types.ICheckListResponse;
  if (!checkList) {
    return;
  }

  yield call(storeCheckList, {
    id: data.id,
    title: checkList.title,
    taskId: checkList.taskId,
    changedAt: checkList.changedAt,
    changedBy: checkList.changedBy,
    deleted: checkList.deleted,
    checklistItemsIds: checkList.items.map((checkListItem) => checkListItem.id),
  });
  return checkList;
}

export function* deleteCheckList(checkListId: types.ICheckList['id']) {
  yield call(apiRequest, api.deleteCheckListApi(checkListId));
}
