/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent } from 'react';
import { passwordsPageSelectors } from '../../../../app/page/page-storage/passwords/passwords.selectors';
import { useSelect } from '../../../../utils/selector.utils';
import { PasswordRow } from './PasswordRow';

export const PasswordTable: FunctionComponent = () => {
  const passwordIds = useSelect(passwordsPageSelectors.selectFiltederPasswordIds);
  return (
    <div className="col">
      <div className="table-container pass-table" role="table" aria-label="Destinations">
        <div className="flex-table header panel mb-1 aic py-0" role="rowgroup">
          <div className="flex-row first" role="columnheader">
            ACCOUNT
          </div>
          <div className="flex-row" role="columnheader">
            USERNAME
          </div>
          <div className="flex-row" role="columnheader">
            PASSWORD
          </div>
          <div className="flex-row" role="columnheader" />
        </div>
        {passwordIds.map((passwordId) => (
          <PasswordRow key={`password-row-${passwordId}`} passwordId={passwordId} />
        ))}
      </div>
    </div>
  );
};
