/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { useApiRequestState } from '../../../utils/api.utils';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { ScoreForm } from '../../pages/common/forms/ScoreForm';
import { SubmitButton } from '../../pages/common/forms/SubmitButton';
import { ConnectedDialog } from '../ConnectedDialog';
import { IScoreFormDialogProps } from './types';

export const ScoreFormDialog: FunctionComponent = () => {
  const dispatch = useDispatch();

  const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'scoreFormDialog') as IScoreFormDialogProps;
  const [showLoader, error] = useApiRequestState('createTaskApi');

  const onClose = useCallback(() => {
    dispatch(closeDialog('scoreFormDialog'));
  }, [dispatch]);

  return (
    <ConnectedDialog dialogKey="scoreFormDialog">
      <div className="popup new_todo_popup" style={{ display: 'block' }}>
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            Add Score
            <span className="close-popup close" onClick={onClose}>
              {!error && !showLoader && <i className="icon-close" />}
              {showLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
              {error && !showLoader && <p style={{ color: 'red' }}> Something went wrong </p>}
            </span>
          </div>
          <div className="panel-body" style={{ maxHeight: '619.1px' }}>
            <ScoreForm {...props} />
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" onClick={onClose}>
              Close
            </a>
            {!props && <SubmitButton apiKey="createScoreApi" formName="score-form" text="Save" />}
            {props && <SubmitButton apiKey="updateScoreApi" formName="score-form" text="Save" />}
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
