/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../app/dialog/dialog.actions';
import { changeOrderAgendaItem } from '../../../../../app/page/page-storage/agenda-items/agenda-items.actions';
import { agendaItemSelectors } from '../../../../../app/resource/agenda-item/agenda-item.selectors';
import { useSelectWithParams } from '../../../../../utils/selector.utils';
import { IAgendaListProps } from './types';

export const AgendaList: FunctionComponent<IAgendaListProps> = ({ meetingId, agendaItemsIds }) => {
  const agendaItems = useSelectWithParams(agendaItemSelectors.selectResourcesById, agendaItemsIds);
  const dispatch = useDispatch();
  const handleDrop = useCallback(
    (result: DropResult) => {
      dispatch(changeOrderAgendaItem(meetingId, result));
    },
    [dispatch, meetingId],
  );

  const openAgendaFormDialog = useCallback(() => {
    dispatch(openDialog('agendaFormDialog', { meetingId }));
  }, [dispatch, meetingId]);
  const openAgendaCustomFormDialog = useCallback(() => {
    dispatch(openDialog('agendaCustomFormDialog', { meetingId }));
  }, [dispatch, meetingId]);

  return (
    <div className="col-6 pl-1">
      <div className="panel-body pt-0 pl-0">
        <div className="border-radius-1 lightGray-bg meetingAg p-2">
          <div style={{ display: 'flex' }}>
            <h3 className="bold mb-2" style={{ marginTop: '5px' }}>
              Agenda
            </h3>
            <div className="ml-auto flex">
              <a
                className="btn-badge btn-sm mr-05 flex aic jcc darkGray-bg white popup_button"
                onClick={openAgendaFormDialog}
              >
                <i className="icon icon-pencil f-0" />
              </a>
              <a
                className="btn-badge green-bg popup_button"
                data-popup="new_account_popup"
                title="add event"
                onClick={openAgendaCustomFormDialog}
              >
                <i className="icon-plus" />
              </a>
            </div>
          </div>
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId={`meeting[${meetingId}].agendaItems`}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {agendaItems.map(
                    (agendaItem, index) =>
                      agendaItem &&
                      agendaItem.checked === '1' && (
                        <Draggable key={`${agendaItem.id}`} draggableId={agendaItem.id} index={index}>
                          {(provided) => (
                            <a
                              key={agendaItem.id}
                              className="directory-button panel flex aic px-1 mb-1"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              draggable={true}
                            >
                              {agendaItem.title}
                              <span className="ml-auto blueGray">{`${agendaItem.duration} min`}</span>
                            </a>
                          )}
                        </Draggable>
                      ),
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {/* {agendaItems.map(
            (agendaItem) =>
              agendaItem &&
              agendaItem.checked === '1' && (
                <a key={agendaItem.id} className="directory-button panel flex aic px-1 mb-1">
                  {agendaItem.title}
                  <span className="ml-auto blueGray">{`${agendaItem.duration} min`}</span>
                </a>
              ),
          )} */}
        </div>
      </div>
    </div>
  );
};
