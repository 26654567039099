import { combineReducers, Reducer } from 'redux';

import * as types from './passwords.types';
import * as constants from './passwords.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IPasswordsPageState = {
  passwordIds: [],
  filteredPasswordIds: [],
  filterParams: {
    groupId: null,
  },
};

const passwordIdsReducer: Reducer<
  types.IPasswordsPageState['passwordIds'],
  types.IStorePasswords | types.IClearPasswords
> = (state = initialState.passwordIds, action) => {
  if (isActionOfType<types.IStorePasswords>(action, constants.STORE_PASSWORDS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearPasswords>(action, constants.CLEAR_PASSWORDS)) {
    return initialState.passwordIds;
  }
  return state;
};

const filteredPasswordIdsReducer: Reducer<
  types.IPasswordsPageState['filteredPasswordIds'],
  types.IStoreFilteredPasswords | types.IClearFilteredPasswords
> = (state = initialState.filteredPasswordIds, action) => {
  if (isActionOfType<types.IStoreFilteredPasswords>(action, constants.STORE_FILTERED_PASSWORDS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearFilteredPasswords>(action, constants.CLEAR_FILTERED_PASSWORDS)) {
    return initialState.filteredPasswordIds;
  }
  return state;
};

const filterParamsReducer: Reducer<
  types.IPasswordsPageState['filterParams'],
  types.IStoreFilterParams | types.IClearFilterParams
> = (state = initialState.filterParams, action) => {
  if (isActionOfType<types.IStoreFilterParams>(action, constants.STORE_FILTER_PARAMS)) {
    return action.payload;
  } else if (isActionOfType<types.IClearFilterParams>(action, constants.CLEAR_FILTER_PARAMS)) {
    return initialState.filterParams;
  }
  return state;
};

export const passwordsPageReducer: Reducer<types.IPasswordsPageState, types.IPasswordsPageAction> = combineReducers({
  passwordIds: passwordIdsReducer,
  filteredPasswordIds: filteredPasswordIdsReducer,
  filterParams: filterParamsReducer,
});
