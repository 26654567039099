import {
  IBoardList,
  IBoardListCreate,
  IBoardListUpdate,
  ISaveBoardListOrder,
  ISaveTaskOrder,
} from '../../resource/boards/boardList/boardList.types';
import { IApiRequestConfig } from '../api.types';

export const searchBoardListsApi = (boardId: string): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'getBoardLists',
  uri: 'json',
  action: 'getTaskTypes',
  method: 'get',
  params: {
    boardId,
  },
});

export const createBoardListApi = (boardList: IBoardListCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createBoardList',
  uri: 'json',
  action: 'saveTaskType',
  method: 'post',
  data: boardList,
});

export const updateBoardListApi = (boardList: IBoardListUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateBoardList',
  uri: 'json',
  action: 'saveTaskType',
  method: 'put',
  data: boardList,
});

export const deleteBoardListApi = (boardListId: IBoardList['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteBoardList',
  uri: 'json',
  action: 'deleteTaskType',
  method: 'delete',
  params: {
    id: boardListId,
  },
});

export const saveTaskOrderApi = (data: ISaveTaskOrder): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'saveTaskOrder',
  uri: 'json',
  action: 'saveTaskOrder',
  method: 'put',
  data: data,
});

export const saveBoardListOrder = (data: ISaveBoardListOrder): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'saveBoardListOrder',
  uri: 'json',
  action: 'saveBoardListOrder',
  method: 'put',
  data: data,
});
