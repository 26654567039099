import { combineReducers, Reducer } from 'redux';

import * as types from './boardLists.types';
import * as constants from './boardLists.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IBoardListsPageState = {
  boardListsIds: [],
};

const boardListsReducer: Reducer<
  types.IBoardListsPageState['boardListsIds'],
  types.IStoreBoardList | types.IClearBoardList
> = (state = initialState.boardListsIds, action) => {
  if (isActionOfType<types.IStoreBoardList>(action, constants.STORE_BOARD_LIST)) {
    return action.payload;
  } else if (isActionOfType<types.IClearBoardList>(action, constants.CLEAR_BOARD_LIST)) {
    return initialState.boardListsIds;
  }

  return state;
};

export const boardListsPageReducer: Reducer<types.IBoardListsPageState, types.IBoardListsPageAction> = combineReducers({
  boardListsIds: boardListsReducer,
});
