import { all, call, fork, takeLatest } from 'redux-saga/effects';

import * as constants from './quartals.constants';
import * as types from './quartals.types';
import * as services from './quartals.saga-services';

function* invokeCreateQuartal(action: types.ICreateQuartal) {
  yield call(services.createQuartal, action.payload);
}

function* invokeUpdateQuartal(action: types.IUpdateQuartal) {
  yield call(services.updateQuartal, action.payload.id, action.payload.data);
}
function* invokeDeleteQuartal(action: types.IDeleteQuartal) {
  yield call(services.deleteQuartal, action.payload);
}

export function* watchQuartalsPurePageSaga() {
  yield takeLatest(constants.CREATE_QUARTAL, invokeCreateQuartal);
  yield takeLatest(constants.UPDATE_QUARTAL, invokeUpdateQuartal);
  yield takeLatest(constants.DELETE_QUARTAL, invokeDeleteQuartal);
}

export function* watchQuartalsPageStorageSaga() {
  yield all([watchQuartalsPurePageSaga].map(fork));
}
