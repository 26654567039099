import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { clearWidgetVisibility, storeWidgetVisibility } from '../../../../../../app/widgets/widgets.actions';
import { widgetSelectors } from '../../../../../../app/widgets/widgets.selectors';
import { useSelectWithParams } from '../../../../../../utils/selector.utils';
import { IWidgetDialogItemProps } from './types';

export const WidgetDialogItem: FunctionComponent<IWidgetDialogItemProps> = ({ widget, provided }) => {
  const dispatch = useDispatch();
  const visible = useSelectWithParams(widgetSelectors.selectWidgetVisibility, widget.key);
  const toggle = useCallback(() => {
    if (visible) {
      dispatch(clearWidgetVisibility(widget.key));
    } else {
      dispatch(storeWidgetVisibility(widget.key));
    }
  }, [dispatch, visible, widget.key]);
  return (
    widget && (
      <div
        id={widget.key}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        draggable={true}
        className="one-widget"
      >
        <div className="one-widget-container">
          <i className="icon-hamburger" />
          <h3>{widget.title}</h3>
          <i style={{ cursor: 'pointer' }} onClick={toggle} className={`icon-eye ${visible ? '' : 'toggled'}`} />
        </div>
      </div>
    )
  );
};
