/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../../app/dialog/dialog.selectors';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { BoardListForm } from '../../../pages/common/forms/BoardListForm';
import { SubmitButton } from '../../../pages/common/forms/SubmitButton';
import { ConnectedDialog } from '../../ConnectedDialog';
import { IBoardListFormDialogProps } from './types';

export const BoardListFormDialog: FunctionComponent = () => {
  const dispatch = useDispatch();

  const props = useSelectWithParams(
    dialogSelectors.selectDialogProps,
    'boardListFormDialog',
  ) as IBoardListFormDialogProps;

  const title = useMemo(() => (props && props.boardListData ? `Edit List` : 'Add Another List'), [props]);

  const onClose = useCallback(() => {
    dispatch(closeDialog('boardListFormDialog'));
  }, [dispatch]);

  return (
    <ConnectedDialog dialogKey="boardListFormDialog">
      <div className="popup new_board_list_popup" style={{ display: 'block' }}>
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            <h3>{title}</h3>
            <span className="close-popup close" onClick={onClose}>
              <i className="icon-close" />
            </span>
          </div>
          <div className="panel-body">
            <BoardListForm {...props} />
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" onClick={onClose}>
              Close
            </a>

            {!props && <SubmitButton apiKey="createBoardList" formName="boardList-form" text="Save" />}
            {props && <SubmitButton apiKey="updateBoardList" formName="boardList-form" text="Save" />}
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
