/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { useMemo } from 'react';
import { FunctionComponent } from 'react';
import { activitySelectors } from '../../../../../../../../../../app/resource/activity/activity.selectors';
import { userSelectors } from '../../../../../../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../../../../../../utils/selector.utils';
import { IActivityProps } from './types';

export const Activity: FunctionComponent<IActivityProps> = ({ activityId }) => {
  const activity = useSelectWithParams(activitySelectors.selectResourceById, activityId);
  const user = useSelectWithParams(userSelectors.selectResourceById, activity ? activity.changedBy : '');

  const createMarkup = useMemo(() => ({ __html: activity ? activity.description : '' }), [activity]);
  const activityTime = useMemo(
    () =>
      activity
        ? `${moment(activity.changedAt).format('MM/DD')} at ${moment(activity.changedAt).format('HH:MM')}${moment(
            activity.changedAt,
          ).format('A')}`
        : '',
    [activity],
  );

  return (
    <div className="single-activity">
      <a className="small-avatar mr-1">
        <img src={user?.image} alt="" className="img-fluid" />
      </a>
      <div className="single-activity-desc">
        <b>{`${user?.firstName}${user?.lastName}`}</b> <div dangerouslySetInnerHTML={createMarkup}></div>
        <div className="activity-date blueGray">{activityTime}</div>
      </div>
    </div>
  );
};
