import { IUser, IUserCreateForm, IUserSearchParams, IUserSignUp } from '../../../resource/user/user.types';

import * as types from './users.types';
import * as constants from './users.constants';

export const fetchUsers = (searchParams?: IUserSearchParams): types.IFetchUsers => ({
  type: constants.FETCH_USERS,
  payload: searchParams,
});

export const signUpUser = (user: IUserSignUp): types.ISignUpUser => ({
  type: constants.SIGNUP_USER,
  payload: user,
});

export const createUser = (user: IUserCreateForm): types.ICreateUser => ({
  type: constants.CREATE_USER,
  payload: user,
});

export const updateUser = (userId: IUser['id'], user: IUserCreateForm): types.IUpdateUser => ({
  type: constants.UPDATE_USER,
  payload: {
    id: userId,
    data: user,
  },
});

export const deleteUser = (userId: IUser['id']): types.IDeleteUser => ({
  type: constants.DELETE_USER,
  payload: userId,
});

export const storeUsers = (Users: IUser['id'][]): types.IStoreUser => ({
  type: constants.STORE_USER,
  payload: Users,
});

export const clearUser = (): types.IClearUser => ({
  type: constants.CLEAR_USER,
});

export const storeFetchedUsers = (fetchedUsers: IUser['id'][]): types.IStoreFetchedUsers => ({
  type: constants.STORE_FETCHED_USERS,
  payload: fetchedUsers,
});

export const clearFetchedUsers = (): types.IClearFetchedUsers => ({
  type: constants.CLEAR_FETCHED_USERS,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
