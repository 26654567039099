import { INodeCreate, INodeUpdate, IOrganizationChartNode } from '../../resource/node/node.types';
import { IApiRequestConfig } from '../api.types';

export const searchNodesApi = (): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'getNodes',
  uri: 'json',
  action: 'getOrgChart',
  method: 'get',
  params: {},
});

export const createNodeApi = (node: INodeCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createNode',
  uri: 'json',
  action: 'saveOrgChart',
  method: 'post',
  data: node,
});

export const updateNodeApi = (node: INodeUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateNode',
  uri: 'json',
  action: 'saveOrgChart',
  method: 'put',
  data: node,
});

export const deleteNodeApi = (nodeId: IOrganizationChartNode['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteNode',
  uri: 'json',
  action: 'deleteOrgChart',
  method: 'delete',
  params: {
    id: nodeId,
  },
});
