import React, { FunctionComponent } from 'react';
import { accountProjectsPageSelectors } from '../../../../../../app/page/page-storage/account-projects/account-projects.selectors';
import { useSelect } from '../../../../../../utils/selector.utils';
import { Account } from './Account';

export const Accounts: FunctionComponent = () => {
  const accountProjectIds = useSelect(accountProjectsPageSelectors.selectAccountProjects);

  return (
    <div className="panel white-bg p-2 mt-2">
      {accountProjectIds.map(
        (accountProjectId) => accountProjectId && <Account accountId={accountProjectId} key={accountProjectId} />,
      )}
    </div>
  );
};
