/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PageLayout } from '../common/PageLayout';

import { useSelect, useSelectWithParams } from '../../../utils/selector.utils';

import { pageStateSelectors } from '../../../app/page/page-state/page-state.selectors';

import { initialAgencyToolsPage } from '../../../app/page/agency-tools/agency-tools.actions';
import { categoriesPageSelectors } from '../../../app/page/page-storage/categories/categories.selectors';
import { Category } from './Category';

export const AgencyToolsPage: FunctionComponent = () => {
  const dispatch = useDispatch();

  const showLoader = useSelectWithParams(pageStateSelectors.selectPageInProgress, 'agencyTools');

  useEffect(() => {
    dispatch(initialAgencyToolsPage());
  }, [dispatch]);

  const categoryIds = useSelect(categoriesPageSelectors.selectCategories);
  console.log(categoryIds);

  return (
    <PageLayout showLoader={showLoader}>
      <div className="row mb-25">
        <div className="col-12">
          <div className="panel">
            <div className="agency-tools panel-body">
              <div className="border-radius-1 lightGray-bg p-5">
                <div className="row big-gap">
                  {categoryIds.map((categoryId) => (
                    <div className="col-4 px-2 mb-5" key={categoryId}>
                      <Category categoryId={categoryId} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
