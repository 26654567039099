import React, { FunctionComponent } from 'react';
import { apiSelectors } from '../../../../../../app/api/api.selectors';
import { useSelectWithParams } from '../../../../../../utils/selector.utils';
import Loader from 'react-loader-spinner';
import { WeekView } from './WeekView';
import { MonthView } from './MonthView';
import { ICalendarViewProps } from './types';
import { DayView } from './DayView';

export const CalendarView: FunctionComponent<ICalendarViewProps> = ({ date, meetingId, userFilter, viewType }) => {
  const progress = useSelectWithParams(apiSelectors.selectApiInProgress, 'getEvents');
  const error = useSelectWithParams(apiSelectors.selectApiError, 'getEvents');
  return progress ? (
    <div
      style={{
        width: '200px',
        height: '200px',
        margin: 'auto',
      }}
    >
      {!error && <Loader type="TailSpin" color="#1bc3a7" height={'100%'} width={'100%'} />}
      {error && <span style={{ color: 'red' }}> {error} </span>}
    </div>
  ) : (
    <>
      {viewType === 'day' && <DayView date={date} meetingId={meetingId} userFilter={userFilter} />}
      {viewType === 'week' && <WeekView date={date} meetingId={meetingId} userFilter={userFilter} />}
      {viewType === 'month' && <MonthView date={date} meetingId={meetingId} userFilter={userFilter} />}
    </>
  );
};
