import { IApiRequestConfig } from '../api.types';

export const searchAccountProjectsApi = (accountId?: string): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'getAccountProjects',
  uri: 'json',
  action: 'getAccountProjects',
  method: 'get',
  params: {
    accountId,
  },
});
/*
export const createAccountProjectApi = (AccountProject: IAccountProjectCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createAccountProject',
  uri: 'json',
  action: 'saveAccountProject',
  method: 'post',
  data: AccountProject,
});

export const updateAccountProjectApi = (AccountProject: IAccountProjectUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateAccountProject',
  uri: 'json',
  action: 'saveAccountProject',
  method: 'put',
  data: AccountProject,
});

export const deleteAccountProjectApi = (AccountProjectId: IAccountProject['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteAccountProject',
  uri: 'json',
  action: 'deleteAccountProject',
  method: 'delete',
  params: {
    id: AccountProjectId,
  },
});
*/
