/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logOut } from '../../../../../../app/auth/auth.actions';
import { authSelectors } from '../../../../../../app/auth/auth.selectors';
import { useSelect } from '../../../../../../utils/selector.utils';
import { useToggler } from '../../../../../../utils/toggler.utils';
import { UserDetails } from './UserDetails';

export const Settings: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isToggledPanel, handleTogglePanel] = useToggler();
  const handleLogout = useCallback(() => {
    dispatch(logOut());
  }, [dispatch]);

  const handleMyProfileClick = useCallback(() => {
    history.push('/directory');
  }, [history]);

  const userId = useSelect(authSelectors.selectLoggedUser);
  return (
    <li className={`dropdown ${!isToggledPanel ? 'opened' : ''}`}>
      <a style={{ cursor: 'pointer' }} className="panel-button dropdown-button" onClick={handleTogglePanel}>
        <i className="icon-settings"></i>
        <i className="icon-down-dir"></i>
      </a>
      <ul className="dropdown-menu settings-menu drop-right" onMouseLeave={handleTogglePanel}>
        <li>{userId && <UserDetails userId={userId} />}</li>
        <li>
          <a style={{ cursor: 'pointer' }} className="drop-item" onClick={handleMyProfileClick}>
            <i className="icon-user blueGray mr-1"></i> My profile
          </a>
        </li>
        <li>
          <a className="drop-item">
            <i className="icon-settings blueGray mr-1"></i> Settings
          </a>
        </li>
        <li>
          <a style={{ cursor: 'pointer' }} className="drop-item" onClick={handleLogout}>
            <i className="icon-logout blueGray mr-1"></i> Logout
          </a>
        </li>
        {/* <li className="noClick" onClick={(e) => e.stopPropagation()}>
          <div className="drop-item darkGray">
            <label className="switch mr-1">
              <input type="checkbox" className="switch-input change-theme" />
              <span className="switch-label"></span>
              <span className="switch-handle"></span>
            </label>
            Light theme
          </div>
        </li> */}
      </ul>
    </li>
  );
};
