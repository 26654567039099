import {
  ICopyTask,
  IMoveTask,
  ITask,
  ITaskCreate,
  ITaskSearchParams,
  ITaskUpdate,
} from '../../resource/task/task.types';
import { IApiRequestConfig } from '../api.types';

export const searchTasksApi = (params?: ITaskSearchParams): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'searchTasks',
  uri: 'json',
  action: 'getTasks',
  method: 'get',
  params: {
    ...params,
  },
});

export const createTaskApi = (task: ITaskCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createTaskApi',
  uri: 'json',
  action: 'saveTask',
  method: 'post',
  data: task,
});

export const updateTaskApi = (task: ITaskUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateTaskApi',
  uri: 'json',
  action: 'saveTask',
  method: 'put',
  data: task,
});

export const deleteTaskApi = (taskId: ITask['id']): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'deleteTaskApi',
  uri: 'json',
  action: 'deleteTask',
  method: 'delete',
  params: {
    id: taskId,
  },
});

export const moveTaskApi = (data: IMoveTask): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'moveTask',
  uri: 'json',
  action: 'moveTask',
  method: 'post',
  data: data,
});

export const copyTaskApi = (data: ICopyTask): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'copyTask',
  uri: 'json',
  action: 'copyTask',
  method: 'post',
  data: data,
});
