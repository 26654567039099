import * as types from './activities.types';
import * as constants from './activities.constants';
import { IActivity, IActivityCreateForm, ISaveFile } from '../../../resource/activity/activity.types';

export const createActivity = (activity: IActivityCreateForm): types.ICreateActivity => ({
  type: constants.CREATE_ACTIVITY,
  payload: activity,
});

export const updateActivity = (activityId: IActivity['id'], activity: IActivityCreateForm): types.IUpdateActivity => ({
  type: constants.UPDATE_ACTIVITY,
  payload: {
    id: activityId,
    data: activity,
  },
});

export const deleteActivity = (activityId: IActivity['id']): types.IDeleteActivity => ({
  type: constants.DELETE_ACTIVITY,
  payload: activityId,
});

export const storeActivities = (activities: IActivity['id'][]): types.IStoreActivity => ({
  type: constants.STORE_ACTIVITY,
  payload: activities,
});

export const clearActivity = (): types.IClearActivity => ({
  type: constants.CLEAR_ACTIVITY,
});

export const saveFile = (activity: ISaveFile): types.ISaveFilePage => ({
  type: constants.SAVE_FILE,
  payload: activity,
});

export const storeIsFetched = (): types.IStoreIsFetched => ({
  type: constants.STORE_IS_FETCHED,
});

export const clearIsFetched = (): types.IClearIsFetched => ({
  type: constants.CLEAR_IS_FETCHED,
});
