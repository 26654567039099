import { call, put, select } from 'redux-saga/effects';

import * as actions from './passwords.actions';

import * as passwordService from '../../../resource/password/password.saga-service';
import * as dialogService from '../../../dialog/dialog.saga-services';

import { selectWithParams } from '../../../../utils/selector.utils';
import { IPassword, IPasswordSaveForm, IPasswordSearchParams } from '../../../resource/password/password.types';
import { passwordSelectors } from '../../../resource/password/password.selectors';
import { passwordsPageSelectors } from './passwords.selectors';
import { IPasswordFilterParams } from './passwords.types';

// import * as apiService from '../../api/api.saga-services';

export function* setupPasswords(searchParams?: IPasswordSearchParams) {
  const passwords = (yield call(passwordService.searchPasswords, searchParams)) as IPassword[];

  yield put(passwords ? actions.storePasswords(passwords.map((user) => user.id)) : actions.clearPasswrods());
  yield put(
    passwords ? actions.storeFilteredPasswords(passwords.map((user) => user.id)) : actions.clearFilteredPasswrods(),
  );
}

export function* savePassword(passwordData: IPasswordSaveForm) {
  const groupIds = passwordData.groups.map((tuple) => tuple.value);
  const password = (yield call(passwordService.savePassword, {
    id: passwordData.id,
    account: passwordData.account,
    username: passwordData.username,
    password: passwordData.password,
    siteUrl: passwordData.siteUrl,
    meetingId: passwordData.meetingId,
    groupIds,
  })) as IPassword;

  yield call(dialogService.closeDialog, 'passwordFormDialog');

  const passwordIds = (yield select(passwordsPageSelectors.selectPasswordIds)) as IPassword['id'][];
  yield put(
    actions.storePasswords(passwordIds.includes(password.id) ? [...passwordIds] : [...passwordIds, password.id]),
  );
  yield call(filterPasswords);
}

export function* deletePassword(passwordId: IPassword['id']) {
  yield call(passwordService.deletePassword, passwordId);
  const passwordIds = (yield select(passwordsPageSelectors.selectPasswordIds)) as IPassword['id'][];
  yield put(actions.storePasswords(passwordIds.filter((id) => passwordId !== id)));
  yield call(filterPasswords);
  yield call(dialogService.closeDialog, 'deleteDialog');
}

export function* handlePasswordFromNotification(password: IPassword) {
  const passwordExists = (yield selectWithParams(
    passwordSelectors.selectResourceById,
    password.id,
  )) as IPassword | null;

  yield call(passwordService.storePassword, {
    ...passwordExists,
    ...password,
  });

  const passwordIds = (yield select(passwordsPageSelectors.selectPasswordIds)) as IPassword['id'][];
  yield put(
    actions.storePasswords(passwordIds.includes(password.id) ? [...passwordIds] : [...passwordIds, password.id]),
  );
  yield call(filterPasswords);
}

export function* storeFilterPasswords(filterParams: IPasswordFilterParams) {
  yield put(actions.storeFilterParams(filterParams));
  yield call(filterPasswords);
}

export function* filterPasswords() {
  const filterParams = (yield select(passwordsPageSelectors.selectFilterParams)) as IPasswordFilterParams;
  const passwordIds = (yield select(passwordsPageSelectors.selectPasswordIds)) as IPassword['id'][];
  const passwords = (yield selectWithParams(passwordSelectors.selectResourcesById, passwordIds)) as IPassword[];

  yield put(
    actions.storeFilteredPasswords(
      passwords
        .filter((password) => (filterParams.groupId ? password.groupIds.includes(filterParams.groupId) : true))
        .map((password) => password.id),
    ),
  );
}

export function* clearPasswordsPageStorage() {
  yield put(actions.clearPasswrods());
  yield put(actions.clearFilteredPasswrods());
  yield put(actions.clearFilterParams());
}
