import React, { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IFormTextInputProps } from './types';

import { get } from 'lodash';

export const FormSmallTextInput: FunctionComponent<IFormTextInputProps> = ({
  id,
  name,
  defaultValue,
  type,
  placeholder,
  validation,
  disabled,
  rightIcon,
  leftIcon,
  label,
  multi,
}) => {
  const { control, errors } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={validation}
      render={(controllerProps) => (
        <>
          {!!get(errors, name, false) && (
            <label htmlFor={name} style={{ color: 'red' }}>
              {`${label ? label + ' - ' : ''} ${get(errors, `${name}.message`)}`}
            </label>
          )}

          {!get(errors, name, false) && <label htmlFor={name}>{label}</label>}

          {leftIcon}
          {rightIcon}

          {multi ? (
            <textarea
              id={id}
              name={name}
              placeholder={placeholder}
              disabled={disabled}
              onChange={controllerProps.onChange}
              value={controllerProps.value}
            />
          ) : (
            <input
              id={id}
              name={controllerProps.name}
              type={type}
              placeholder={placeholder}
              disabled={disabled}
              onChange={controllerProps.onChange}
              value={controllerProps.value}
              className={'small-input'}
            />
          )}
        </>
      )}
    />
  );
};
