import { combineReducers, Reducer } from 'redux';

import * as types from './projects.types';
import * as constants from './projects.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IProjectPageState = {
  projectIds: [],
};

const projectsReducer: Reducer<types.IProjectPageState['projectIds'], types.IStoreProject | types.IClearProject> = (
  state = initialState.projectIds,
  action,
) => {
  if (isActionOfType<types.IStoreProject>(action, constants.STORE_PROJECT)) {
    return action.payload;
  } else if (isActionOfType<types.IClearProject>(action, constants.CLEAR_PROJECT)) {
    return initialState.projectIds;
  }

  return state;
};

export const projectsPageReducer: Reducer<types.IProjectPageState, types.IProjectPageAction> = combineReducers({
  projectIds: projectsReducer,
});
