import { put, select, call, all } from 'redux-saga/effects';
import * as types from './project-task.types';
import * as actions from './project-task.actions';
import * as api from './../../api/request-config/project-task.api';
import { projectTaskSelectors } from './project-task.selectors';
import { apiRequest } from '../../api/api.saga-services';

export function* storeProjectTask(projectTask: types.IProjectTask) {
  yield put(actions.storeProjectTask(projectTask));

  return projectTask;
}

export function* clearProjectTask(projectTaskId: types.IProjectTask['id']) {
  yield put(actions.clearProjectTask(projectTaskId));
}

export function* clearAllProjectTasks() {
  const projectTasksIds: types.IProjectTask['id'][] = yield select(projectTaskSelectors.selectResourceIds);
  yield put(actions.clearProjectTask(projectTasksIds));
}

export function* searchProjectTasks(searchParams?: types.IProjectTaskSearchParams) {
  const projectTasks: types.IProjectTask[] = yield call(apiRequest, api.searchProjectTasksApi(searchParams));
  yield all(projectTasks.map((projectTask) => call(storeProjectTask, projectTask)));
  return projectTasks.map((projectTask) => projectTask.id);
}

export function* createProjectTask(data: types.IProjectTaskCreate) {
  //const projectTask = (yield call(apiRequest, api.createProjectTaskApi(data))) as types.IProjectTask;
  const projectTask = data as types.IProjectTask;
  if (!projectTask) {
    return;
  }
  yield call(storeProjectTask, projectTask);
  return projectTask;
}

export function* updateProjectTask(data: types.IProjectTaskUpdate) {
  //const projectTask = (yield call(apiRequest, api.updateProjectTaskApi(data))) as types.IProjectTask;
  const projectTask = data as types.IProjectTask;
  if (!projectTask) {
    return;
  }
  //projectTask.id = data.id;
  yield call(storeProjectTask, projectTask);
  return projectTask;
}

export function* deleteProjectTask(projectTaskId: types.IProjectTask['id']) {
  yield call(apiRequest, api.deleteProjectTaskApi(projectTaskId));
}
