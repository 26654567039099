import { put } from 'redux-saga/effects';

import * as types from './kpi-row.types';
import * as actions from './kpi-row.actions';
import { select } from '../../../utils/selector.utils';
import { kpiRowSelectors } from './kpi-row.selectors';

export function* storeKpiRow(storeKpiRow: types.IKpiRow) {
  yield put(actions.storeKpiRow(storeKpiRow));
  return storeKpiRow;
}

export function* clearKpiRow(kpiRowId: types.IKpiRow['id']) {
  yield put(actions.clearKpiRow(kpiRowId));
}

export function* clearAllKpiRows() {
  const kpiRowIds: types.IKpiRow['id'][] = yield select(kpiRowSelectors.selectResourceIds);
  yield put(actions.clearKpiRow(kpiRowIds));
}
