import { INotification, INotificationSearchParams } from '../../resource/notification/notification.types';
import { IApiRequestConfig } from '../api.types';

export const searchNotificationsApi = (params?: INotificationSearchParams): IApiRequestConfig => ({
  apiRouteKey: 'searchNotifications',
  uri: 'json',
  action: 'getNotifications',
  method: 'get',
  params: {
    ...params,
  },
});

export const updateNotificationApi = (notificationId: INotification['id']): IApiRequestConfig => ({
  apiRouteKey: 'updateNotification',
  uri: 'json',
  action: 'updateNotifications',
  method: 'put',
  params: {
    id: notificationId,
  },
});

export const updateNotificationsApi = (notificationIds: INotification['id'][]): IApiRequestConfig => ({
  apiRouteKey: 'updateNotifications',
  uri: 'json',
  action: 'updateNotifications',
  method: 'put',
  data: {
    notificationIds,
  },
});
