import moment from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';
import { signUpUser } from '../../../../app/page/page-storage/users/users.actions';
import { IUserSignUp } from '../../../../app/resource/user/user.types';
import FacebookLogin from 'react-facebook-login';
import { useHistory } from 'react-router-dom';

export const SocialMedia: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [toggleFacebook, setToggleFacebook] = useState(false);
  const handleGoogleLogin = useCallback(
    (googleData) => {
      const data = {
        firstName: googleData.profileObj.givenName,
        lastName: googleData.profileObj.familyName,
        email: googleData.profileObj.givenName,
        password: 'temp_123',
        image: googleData.profileObj.imageUrl,
        address: '',
        phone: '',
        timezone: moment.tz.guess(),
        tokenId: googleData.tokenObj.access_token,
        expiresAt: `${googleData.tokenObj.expires_at}`,
        expiresIn: `${googleData.tokenObj.expires_in}`,
        source: 'google',
      } as IUserSignUp;

      dispatch(signUpUser(data));
    },
    [dispatch],
  );
  const handleGoogleFailure = useCallback((result) => {
    console.log(result);
  }, []);
  const handleFacebookLogin = useCallback(
    (facebookData) => {
      if (facebookData.status === 'unknown') {
        setToggleFacebook(false);
      } else {
        const data = {
          firstName: facebookData.first_name,
          lastName: facebookData.last_name,
          email: facebookData.email,
          password: 'temp_123',
          image: facebookData.picture ? facebookData.picture.data.url : '',
          address: '',
          phone: '',
          timezone: moment.tz.guess(),
          tokenId: facebookData.accessToken,
          expiresAt: `${facebookData.data_access_expiration_time}`,
          expiresIn: `${facebookData.expiresIn}`,
          source: 'facebook',
        } as IUserSignUp;

        dispatch(signUpUser(data));
      }
    },
    [dispatch],
  );
  const handleFacebookButton = useCallback(() => {
    setToggleFacebook(!toggleFacebook);
  }, [toggleFacebook]);
  // const updateProfile = useCallback((profile) => {
  //   console.log(profile);
  // }, []);

  // useEffect(() => {
  //   window.addEventListener('message', (e) => handlePostMessage(e));
  // }, [handlePostMessage, e]);

  const requestProfile = useCallback(() => {
    const oauthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=78yqcc87un62qs&scope=r_liteprofile&redirect_uri=http://localhost:3000/login`;
    const width = 450,
      height = 730,
      left = window.screen.width / 2 - width / 2,
      top = window.screen.height / 2 - height / 2;

    const response = window.open(
      oauthUrl,
      '_self',
      'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
        width +
        ', height=' +
        height +
        ', top=' +
        top +
        ', left=' +
        left,
    )?.frames;
    console.log(response?.location.search);

    //handlePostMessage(response);
  }, []);
  const history = useHistory();
  const lnRes = history.location.search;
  useEffect(() => {
    if (lnRes) {
      console.log(lnRes);
      const code = lnRes.slice(6);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': '*' },
        body: JSON.stringify({
          grant_type: 'authorization_code',
          code: code,
          redirect_uri: 'http://localhost:3000/login',
          client_id: '78yqcc87un62qs',
          client_secret: 'lFvJJX9f5VvaPM2U',
        }),
      };
      fetch('https://www.linkedin.com/oauth/v2/accessToken', requestOptions)
        .then((response) => response.json())
        .then((data) => console.log(data));
    }
  }, [lnRes]);

  return (
    <div className="panel-footer lightGray-bg flex-column px-5">
      <button className="btn btn-lg white-bg mb-1 w100 aic jcc fb-color facebook-login" onClick={handleFacebookButton}>
        <i className="icon-facebook" /> Login with Facebook
      </button>
      {toggleFacebook && (
        <div style={{ display: 'none' }}>
          <FacebookLogin
            appId="287491633361276"
            fields="first_name, last_name, email, picture, birthday"
            autoLoad
            callback={handleFacebookLogin}
            textButton="Login with Facebook"
            cssClass="icon-facebook btn btn-lg white-bg mb-1 w100 aic jcc fb-color facebook-login"
            buttonStyle={{ width: '100%' }}
          />
        </div>
      )}

      <GoogleLogin
        clientId="520131743539-r7gu37qqf5ci51netfn00m2cjmko418e.apps.googleusercontent.com"
        buttonText="Login with Google"
        onSuccess={handleGoogleLogin}
        onFailure={handleGoogleFailure}
        cookiePolicy={'single_host_origin'}
        className="btn btn-lg white-bg mb-1 w100 aic jcc g-color google-login"
      ></GoogleLogin>

      <button className="btn btn-lg white-bg mb-1 w100 aic jcc ln-color linkedin-login" onClick={requestProfile}>
        <i className="icon-linkedin" /> Login with LinkedIn
      </button>
    </div>
  );
};
