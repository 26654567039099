import React, { FunctionComponent, useCallback } from 'react';

import { IUser } from '../../../../../../../app/resource/user/user.types';
import { CalendarFilterItem } from './CalendarFilterItem';
import { ICalendarFilterProps } from './types';

export const CalendarFilter: FunctionComponent<ICalendarFilterProps> = ({ setUserFilter, userIds }) => {
  const onToggle = useCallback(
    (userId: IUser['id']) => {
      setUserFilter((ids) => {
        if (ids.includes(userId)) {
          return ids.filter((id) => userId !== id);
        } else {
          return [...ids, userId];
        }
      });
    },
    [setUserFilter],
  );

  return (
    <>
      {userIds.map((userId) => (
        <CalendarFilterItem key={`user-filter-item-${userId}`} userId={userId} onToggle={onToggle} />
      ))}
    </>
  );
};
