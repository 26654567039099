import { call } from 'redux-saga/effects';

import * as answerResourceService from '../../../resource/answer/answer.saga-service';
import { IAnswer, IAnswerUpdate } from '../../../resource/answer/answer.types';

// export function* clearAnswers() {
//   yield put(actions.clearAnswer());
// }

// export function* setupAnswers(accountId: string, AnswerId?: string) {
//   const Answers = (yield call(AnswerResourceService.searchAnswers, accountId, AnswerId)) as IAnswer['id'][];

//   yield put(Answers ? actions.storeAnswers(Answers) : actions.clearAnswer());
// }

// export function* setupAnswer(accountId: string, AnswerId?: string) {
//   const Answers = (yield call(AnswerResourceService.searchAnswers, accountId, AnswerId)) as IAnswer['id'][];

//   yield put(Answers ? actions.storeAnswers(Answers) : actions.clearAnswer());
// }

// export function* createAnswer(AnswerInfo: IAnswerCreateForm) {
//   const Answer = (yield call(
//     AnswerResourceService.createAnswer,
//     mapAnswerFormToAnswerCreate(AnswerInfo),
//   )) as IAnswer;

//   if (!Answer) {
//     return;
//   }
// }

export function* updateAnswer(answer: IAnswerUpdate) {
  const answerResponse = (yield call(answerResourceService.updateAnswer, answer)) as IAnswer;

  if (!answerResponse) {
    return;
  }
}

// export function* deleteAnswer(AnswerId: IAnswer['id'], accountId: IAccountAnswer['id']) {
//   yield call(AnswerResourceService.deleteAnswer, AnswerId);

//   const AnswersIds: IAnswer['id'][] = yield select(AnswersPageSelectors.selectAnswers);

//   yield put(AnswersIds ? actions.storeAnswers(AnswersIds.filter((id) => AnswerId !== id)) : actions.clearAnswer());

//   const accountAnswer: IAccountAnswer = yield select(accountAnswerSelectors.selectResourceById, accountId);
//   const updatedIds = accountAnswer.AnswerReportIds.filter((id) => AnswerId !== id);
//   const updateAnswer: IAccountAnswer = { ...accountAnswer, AnswerReportIds: updatedIds };
//   yield call(accountAnswerService.storeAccountAnswer, updateAnswer);

//   yield call(dialogService.closeDialog, 'AnswerFormDialog');
// }

// export const mapAnswerFormToAnswerCreate = (AnswerInfo: IAnswerCreateForm): IAnswerCreate => ({
//   name: AnswerInfo.name,
//   startDate: AnswerInfo.startDate,
//   endDate: AnswerInfo.endDate,
//   note: AnswerInfo.note,
//   type: AnswerInfo.type,
//   hourlyRate: AnswerInfo.hourlyRate ? AnswerInfo.hourlyRate.value : 'no_rate',
//   budgetType: AnswerInfo.budgetType.value,
//   totalAnswerFee: AnswerInfo.totalAnswerFee,
//   totalAnswerHours: AnswerInfo.totalAnswerHours,
//   AnswerHoursRate: AnswerInfo.AnswerHoursRate,
//   fixedFee: AnswerInfo.fixedFee,
//   showToAll: AnswerInfo.showToAll,
//   showToAdmins: AnswerInfo.showToAdmins,
//   accountId: AnswerInfo.account.value,
//   teams: AnswerInfo.teams ? AnswerInfo.teams : [],
//   tasks: AnswerInfo.tasks ? AnswerInfo.tasks : [],
// });

// export function* clearAnswersPageStorage() {
//   yield put(actions.clearAnswer());
// }
/*
export function* handleAnswerFromNotification(AnswerResponse: IAnswerFromNotification) {
  const AnswerExists = (yield selectWithParams(
    AnswerSelectors.selectResourceById,
    AnswerResponse.id,
  )) as IAnswer | null;

  if (AnswerExists) {
    yield call(AnswerResourceService.storeAnswer, { ...AnswerResponse });
  } else {
    const AnswersIds = yield select(AnswersPageSelectors.selectAnswers);
    yield call(AnswerResourceService.storeAnswer, { ...AnswerResponse });

    yield put(actions.storeAnswers([...AnswersIds, AnswerResponse.id]));
  }
}
*/
