/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../app/dialog/dialog.selectors';
import { useApiRequestState } from '../../../utils/api.utils';
import { useSelectWithParams } from '../../../utils/selector.utils';
import { SubmitButton } from '../../pages/common/forms/SubmitButton';
import { PasswordGroupFrom } from '../../pages/PasswordsPage/PasswordFolders/PasswordGroupForm';

import { ConnectedDialog } from '../ConnectedDialog';
import { IPasswordGroupFormDialogProps } from './types';

export const PasswordGroupFormDialog: FunctionComponent = () => {
  const dispatch = useDispatch();
  const props = useSelectWithParams(
    dialogSelectors.selectDialogProps,
    'passwordGroupFormDialog',
  ) as IPasswordGroupFormDialogProps;

  const [createShowLoader, createError] = useApiRequestState('savePasswordGroup');

  const onClose = useCallback(() => {
    dispatch(closeDialog('passwordGroupFormDialog'));
  }, [dispatch]);

  return (
    <ConnectedDialog dialogKey="passwordGroupFormDialog">
      <div className="popup new_team_popup mid-popup isVisible">
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            <h3>{props?.data ? 'Edit folder' : 'Add folder'} </h3>
            <span className="close-popup close">
              {createShowLoader && <Loader type="TailSpin" color="#1bc3a7" height={20} width={20} />}
              {createError && <p style={{ color: 'red' }}> Something went wrong </p>}
            </span>
          </div>
          <div className="panel-body pt-0" style={{ maxHeight: '500px' }}>
            <PasswordGroupFrom {...props} />
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" title="" onClick={onClose}>
              Close
            </a>
            <SubmitButton apiKey="savePasswordGroup" formName="password-group-form" text="Save" />
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
