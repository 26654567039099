import { call, takeEvery, takeLatest } from 'redux-saga/effects';

import * as constants from './notifications.constants';
import * as types from './notifications.types';
import * as services from './notifications.saga-services';

function* invokeSetupNotifications(action: types.IFetchNotifications) {
  yield call(services.setupNotifications);
}

function* invokeHandleNewNotification(action: types.IHandleNewNotification) {
  yield call(services.handleNewNotificationNotification, action.payload);
}

export function* watchNotificationsPageSaga() {
  yield takeLatest(constants.FETCH_NOTIFICATIONS, invokeSetupNotifications);
  yield takeEvery(constants.HANDLE_NEW_NOTIFICATION, invokeHandleNewNotification);
}
