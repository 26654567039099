import React, { FunctionComponent, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { clearApiError } from '../../../../app/api/api.actions';
import { apiSelectors } from '../../../../app/api/api.selectors';
import { useRequiredValidation } from '../../../../utils/form/validation.utils';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { FormTextInput } from '../../common/inputs/TextInput';
import { ILoginFormProps } from './types';

export const LoginForm: FunctionComponent<ILoginFormProps> = ({ onSubmit }) => {
  const formProps = useForm();
  const requiredValidation = useRequiredValidation();

  const dispatch = useDispatch();
  const passwordReset = useRef(false);

  const error = useSelectWithParams(apiSelectors.selectApiError, 'login');

  useEffect(() => {
    if (error && !passwordReset.current) {
      formProps.reset({
        email: formProps.getValues().email,
        password: '',
      });
      passwordReset.current = true;
    }
  }, [dispatch, error, formProps]);

  useEffect(() => {
    if (error && !formProps.formState.isSubmitted && formProps.formState.isDirty) {
      dispatch(clearApiError('login'));
      passwordReset.current = false;
    }
  }, [dispatch, error, formProps.formState.isDirty, formProps.formState.isSubmitted]);

  return (
    <FormProvider {...formProps}>
      <form id="login-form" onSubmit={formProps.handleSubmit(onSubmit)}>
        <div className="field-container">
          <FormTextInput
            id="email"
            name="email"
            type="email"
            placeholder="Email address"
            validation={requiredValidation}
          />
        </div>
        <div className="field-container password-field">
          <FormTextInput
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            validation={requiredValidation}
            rightIcon={
              <span className="reveal_password">
                <i className="icon-eye toggled"></i>
              </span>
            }
          />
        </div>
        {error && <label style={{ color: 'red' }}>{error.message}</label>}
      </form>
    </FormProvider>
  );
};
