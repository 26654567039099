import { call } from 'redux-saga/effects';

import { loadCachedUser, logOutIfUserExpired } from './auth/auth.saga-services';

export function* initialize() {
  const expiredToken = (yield call(logOutIfUserExpired)) as boolean;
  if (!expiredToken) {
    yield call(loadCachedUser);
  }
}
