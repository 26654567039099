/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { FunctionComponent } from 'react';
import { userSelectors } from '../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../utils/selector.utils';
import { MemberOnAtInput } from './MemberOnAtInput';

import { IMembersManyOnAtInputProps } from './types';

export const MembersManyOnAtInput: FunctionComponent<IMembersManyOnAtInputProps> = ({
  userIds,
  dropRight,
  closeMenu,
  searchMember,
  onSelectUser,
}) => {
  const users = useSelectWithParams(userSelectors.selectResourcesById, userIds);
  const filteredUsers = useMemo(() => {
    if (searchMember) {
      return users.filter((user) => user?.firstName.toLocaleLowerCase().includes(searchMember.toLocaleLowerCase()));
    }
    return users;
  }, [searchMember, users]);

  return (
    <div className={dropRight ? 'dropdown-menu drop-right' : 'dropdown-menu'}>
      <div className="mini-popup">
        <div className="mini-popup-header">
          Members
          <span className="close_mini_popup" onClick={closeMenu}>
            ×
          </span>
        </div>
        <div className="mini-popup-body">
          <div className="list-members">
            {filteredUsers.map(
              (user) =>
                user && (
                  <MemberOnAtInput
                    key={`memberId-${user.id}`}
                    userId={user.id}
                    firstName={user.firstName}
                    lastName={user.lastName}
                    image={user.image}
                    onClick={onSelectUser}
                  />
                ),
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
