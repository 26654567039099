/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { ConnectedDialog } from '../ConnectedDialog';

export const ConfirmationDialog = () => {
  // const dispatch = useDispatch();
  // const props = useSelectWithParams(dialogSelectors.selectDialogProps, 'confirmation') as IConfirmationDialogProps;

  return (
    <ConnectedDialog dialogKey="confirmation">
      <div className="popup new_channel_popup isVisible">
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            <h3>Add Calendar</h3>
            <span className="close-popup close">
              <i className="icon-close"></i>
            </span>
          </div>
          <div className="panel-body">
            <p>URL to google calendar: </p>
            <h1>
              <a href="phpstack-696462-2300737.cloudwaysapps.com/googleapi/make_token?user_id=46">
                https://phpstack-696462-2300737.cloudwaysapps.com/googleapi/make_token?user_id=46
              </a>
            </h1>
          </div>
          <div className="panel-footer no-border flex jcr">
            <a href="" className="link blueGray mr-2 close" title="">
              Close
            </a>
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
