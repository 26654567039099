import React from 'react';
import { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateMeeting } from '../../../../../app/page/page-storage/meetings/meetings.actions';
import { agendaItemSelectors } from '../../../../../app/resource/agenda-item/agenda-item.selectors';
import { boardSelectors } from '../../../../../app/resource/boards/board/board.selectors';
import { boardListSelectors } from '../../../../../app/resource/boards/boardList/boardList.selectors';

import { meetingSelectors } from '../../../../../app/resource/meeting/meeting.selectors';

import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { useSelect, useSelectWithParams } from '../../../../../utils/selector.utils';
import { SelectInput } from '../../inputs/SelectInput';

import { FormTextInput } from '../../inputs/TextInput';
import { IAgendaCustomItemCreateForm, IAgendaCustomItemFormProps } from './types';
import { IAgenda } from '../../../../../app/resource/agenda/agenda.types';
import { FormCSTextInput } from '../../inputs/CustomSizeTextInput';

export const AgendaCustomItemForm: FunctionComponent<IAgendaCustomItemFormProps> = ({ meetingId, boardIds }) => {
  const dispatch = useDispatch();

  const formProps = useForm();

  const requiredValidation = useRequiredValidation();
  const boardOptions = useSelectWithParams(boardSelectors.selectResourcesById, boardIds).map((board) => ({
    value: board?.id,
    label: board?.name,
  }));
  const meeting = useSelectWithParams(meetingSelectors.selectResourceById, meetingId);
  const agendaItems = useSelectWithParams(
    agendaItemSelectors.selectResourcesById,
    meeting && meeting.agendaItemIds ? meeting.agendaItemIds : [],
  ) as IAgenda[];

  const onSubmit = (data: IAgendaCustomItemCreateForm) => {
    console.log(data);
    const newAgendaItem = {
      title: data.name,
      boardId: data.board?.value ? data.board?.value : '',
      boardListId: data.boardList?.value ? data.boardList?.value : '',
      embedUrl: data.embedUrl ? data.embedUrl : '',
      checked: '1',
      duration: data.duration,
      agendaId: '',
      description: '',
      sort: meeting?.agendaItemIds.length.toString(),
    };

    const updatedMeeting = {
      title: meeting ? meeting.title : '',
      image: meeting ? meeting.image : '',
      color: meeting ? meeting.color : '',
      administrators: meeting
        ? meeting.administratorIds.map((id) => ({
            value: id,
            label: id,
          }))
        : [],
      shareWith: meeting
        ? meeting.shareWithIds.map((id) => ({
            value: id,
            label: id,
          }))
        : [],
      startDate: meeting ? (meeting?.startDate ? meeting?.startDate : '') : '',
      startTime: meeting
        ? {
            value: meeting.startTime ? meeting.startTime : '',
            label: meeting.startTime ? meeting.startTime : '',
          }
        : {
            value: '',
            label: '',
          },
      endTime: meeting
        ? { value: meeting.endTime ? meeting.endTime : '', label: meeting.endTime ? meeting.endTime : '' }
        : { value: '', label: '' },
      repeatCheckbox: meeting ? (meeting.repeatCheckbox === '1' ? true : false) : true,
      repeatEvery: meeting ? meeting.repeatEvery : '',
      repeatType: meeting ? { value: meeting.repeatType, label: meeting.repeatType } : { value: '', label: '' },
      type: meeting ? meeting.type : 'account',
      agendaItems: [...agendaItems, newAgendaItem],
    };

    dispatch(updateMeeting(meetingId, updatedMeeting));
  };

  const focusConnectTuple = formProps.watch('control');
  const focusBoardTuple = formProps.watch('board');
  const boardListOptions = useSelect(boardListSelectors.selectResourceList)
    .filter((boardList) => focusBoardTuple && boardList.boardId === focusBoardTuple.value)
    .map((boardList) => ({ value: boardList.id, label: boardList.name }));

  const connectOptions = [
    { value: '0', label: 'Accountability Board' },
    { value: '1', label: 'Accountability List' },
    { value: '2', label: 'External URL' },
  ];

  return (
    <FormProvider {...formProps}>
      <form
        id="agendaCustomItem-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IAgendaCustomItemCreateForm))}
        method="post"
      >
        <div className="field-container">
          <FormTextInput
            id="name"
            name="name"
            type="text"
            label="Agenda Name"
            placeholder="Enter Agenda Name"
            // defaultValue={boardListData ? boardListData.name : ''}
            validation={requiredValidation}
          />
        </div>
        <div className="field-container">
          <SelectInput
            id="control"
            name="control"
            label="Connect to"
            // defaultOptions={selectedUser}
            validation={requiredValidation}
            options={connectOptions}
            isSingle
          />
        </div>
        {focusConnectTuple && (focusConnectTuple.value === '0' || focusConnectTuple.value === '1') && (
          <div className="field-container">
            <SelectInput
              id="board"
              name="board"
              label="Board"
              // defaultOptions={selectedUser}
              validation={requiredValidation}
              options={boardOptions}
              isSingle
            />
          </div>
        )}
        {focusConnectTuple && focusConnectTuple.value === '1' && (
          <div className="field-container">
            <SelectInput
              id="boardList"
              name="boardList"
              label="Board List"
              // defaultOptions={selectedUser}
              validation={requiredValidation}
              options={boardListOptions}
              isSingle
            />
          </div>
        )}
        {focusConnectTuple && focusConnectTuple.value === '2' && (
          <div className="field-container">
            <FormTextInput
              id="embedUrl"
              name="embedUrl"
              type="text"
              label="Embed URL"
              placeholder="Enter"
              // defaultValue={boardListData ? boardListData.name : ''}
              validation={requiredValidation}
            />
          </div>
        )}
        <div className="field-container">
          <FormCSTextInput
            id="duration"
            name="duration"
            type="text"
            size="mw"
            label="Duration"
            placeholder="Minutes"
            validation={requiredValidation}
          />
        </div>
      </form>
    </FormProvider>
  );
};
