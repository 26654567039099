import { all, call, put } from 'redux-saga/effects';

import * as meetingResourceService from '../../../resource/meeting/meeting.saga-service';
import * as scoreboardResourceService from '../../../resource/kpi-row-scoreboard/kpi-row-scoreboard.saga-service';
import * as scoreResourceService from '../../../resource/score/score.saga-service';

import * as kpiService from '../../../resource/kpi/kpi.saga-service';
import * as kpiRowService from '../../../resource/kpi-row/kpi-row.saga-service';
import * as kpiRowScoreboardService from '../../../resource/kpi-row-scoreboard/kpi-row-scoreboard.saga-service';

import * as dialogService from '../../../dialog/dialog.saga-services';

import * as actions from './kpi.actions';

import { IMeeting } from '../../../resource/meeting/meeting.types';
import { ISession } from '../../../resource/session/session.types';
import { IKpiRow } from '../../../resource/kpi-row/kpi-row.types';
import { IKpiRowScoreboard } from '../../../resource/kpi-row-scoreboard/kpi-row-scoreboard.types';
import { select, selectWithParams } from '../../../../utils/selector.utils';
import { kpiSelectors } from '../../../resource/kpi/kpi.selectors';
import { IKpi } from '../../../resource/kpi/kpi.types';
import { omit } from 'lodash';
import { kpiRowSelectors } from '../../../resource/kpi-row/kpi-row.selectors';
import { IScore, IScoreCreate, IScoreCreateForm } from '../../../resource/score/score.types';
import { meetingSelectors } from '../../../resource/meeting/meeting.selectors';
import { kpiPageSelectors } from './kpi.selectors';
import { scoreSelectors } from '../../../resource/score/score.selectors';

export function* storeKpiRowIds(kpiRowIds: IKpiRow['id'][]) {
  yield kpiRowIds ? put(actions.storeKpiRowIds(kpiRowIds)) : put(actions.clearKpiRowIds());
}

export function* storeSessionIds(sessionIds: ISession['id'][]) {
  yield sessionIds ? put(actions.storeSessionIds(sessionIds)) : put(actions.clearSessionIds());
}

export function* storeScoreIds(scoreIds: IScore['id'][]) {
  yield scoreIds ? put(actions.storeScoreIds(scoreIds)) : put(actions.clearScoreIds());
}

export function* setupKpi(meetingId: IMeeting['id']) {
  const meeting = (yield call(meetingResourceService.populateKpi, meetingId)) as IMeeting;

  if (!meeting || !meeting.kpiId) {
    return;
  }

  const kpi = (yield selectWithParams(kpiSelectors.selectResourceById, meeting.kpiId)) as IKpi;

  yield call(storeScoreIds, kpi.kpiRowIds);
  yield call(storeKpiRowIds, kpi.kpiRowIds);
  yield call(storeSessionIds, kpi.sessionIds);
}

export function* saveScoreboard(scoreboardInfo: IKpiRowScoreboard) {
  if (!scoreboardInfo) {
    return;
  }
  if ((scoreboardInfo.id + '').includes('/')) {
    const scoreboard = (yield call(
      scoreboardResourceService.createKpiRowScoreboard,
      omit(scoreboardInfo, 'id'),
    )) as IKpiRowScoreboard;

    //yield call(scoreboardResourceService.clearKpiRowScoreboard, scoreboardInfo.id);
    //yield call(scoreboardResourceService.storeKpiRowScoreboard, scoreboard);

    const kpiRow = (yield selectWithParams(kpiRowSelectors.selectResourceById, scoreboardInfo.scoresId)) as IKpiRow;

    if (kpiRow) {
      kpiRow.kpiRowScoreboardIds[kpiRow.kpiRowScoreboardIds.indexOf(scoreboardInfo.id)] = scoreboard.id;
      yield call(kpiRowService.storeKpiRow, { ...kpiRow });
    }
  } else {
    yield call(scoreboardResourceService.updateKpiRowScoreboard, scoreboardInfo);
    //yield call(scoreboardResourceService.storeKpiRowScoreboard, { ...scoreboard, id: scoreboardInfo.id });
  }
  yield call(dialogService.closeDialog, 'kpiNoteFormDialog');
}

// TODO: Ask Arsen about handling this from notifications - whole table should be generated on the backend because i cant find
// which item has been updated
export function* handleScoreboardFromNotification(scoreboard: IKpiRowScoreboard) {
  yield call(scoreboardResourceService.storeKpiRowScoreboard, scoreboard);
  const newId = `${scoreboard.scoresId}/${scoreboard.sessionId}`;
  const newScoreboard = { ...scoreboard, id: newId };
  yield call(scoreboardResourceService.storeKpiRowScoreboard, newScoreboard);
}

export function* createScore(scoreInfo: IScoreCreateForm) {
  const score = (yield call(scoreResourceService.createScore, mapScoreFormToScoreCreate(scoreInfo))) as IScore;

  if (!score) {
    return;
  }
  // call store storeScore
  const scoreIds = yield select(kpiPageSelectors.selectScoreIds);
  yield call(storeScoreIds, [...scoreIds, score.id]);

  yield call(generateAndStoreKpiRowFromScore, score);
  yield call(dialogService.closeDialog, 'scoreFormDialog');
}

export function* handleScoreFromNotification(score: IScore) {
  const scoreExists = (yield selectWithParams(scoreSelectors.selectResourceById, score.id)) as IScore | null;

  yield call(scoreResourceService.storeScore, score);

  if (scoreExists) {
    return;
  }

  yield call(generateAndStoreKpiRowFromScore, score);
}

export function* updateScore(scoreId: IScore['id'], scoreInfo: IScoreCreateForm) {
  const score = (yield call(scoreResourceService.updateScore, {
    id: scoreId,
    ...mapScoreFormToScoreCreate(scoreInfo),
  })) as IScore;

  if (!score) {
    return;
  }

  yield call(dialogService.closeDialog, 'scoreFormDialog');
}

export function* saveScoreOrder(scoreIds: IScore['id'][]) {
  yield call(scoreResourceService.saveScoreOrder, { scoreIds });
}

export function* generateAndStoreKpiRowFromScore(score: IScore) {
  const meeting = (yield selectWithParams(meetingSelectors.selectResourceById, score.meetingId)) as IMeeting;

  if (!meeting) {
    return;
  }

  const kpiRow = generateKpiRow(score, meeting.sessionIds ? meeting.sessionIds : []);

  yield all(
    kpiRow.kpiRowScoreboards.map((kpiRowScoreboard) =>
      call(kpiRowScoreboardService.storeKpiRowScoreboard, kpiRowScoreboard),
    ),
  );
  yield call(kpiRowService.storeKpiRow, {
    id: kpiRow.id,
    scoreId: kpiRow.id,
    userId: kpiRow.userId,
    kpiRowScoreboardIds: kpiRow.kpiRowScoreboards.map((kpiRowScoreboard) => kpiRowScoreboard.id),
  });

  if (meeting.kpiId) {
    const kpi = (yield selectWithParams(kpiSelectors.selectResourceById, meeting.kpiId)) as IKpi;
    kpi.kpiRowIds = [...kpi.kpiRowIds, kpiRow.id];

    yield call(kpiService.storeKpi, { ...kpi });
    yield call(storeKpiRowIds, kpi.kpiRowIds);
  }
}

const generateKpiRow = (score: IScore, sessionIds: ISession['id'][]) => ({
  id: score.id,
  scoreId: score.id,
  userId: score.userId,
  kpiRowScoreboards: sessionIds.map((sessionId) => ({
    id: `${score.id}/${sessionId}`,
    sessionId: sessionId,
    scoresId: score.id,
    measured: '',
    note: '',
  })),
});

export const mapScoreFormToScoreCreate = (scoreInfo: IScoreCreateForm): IScoreCreate => ({
  meetingId: scoreInfo.meeting.value,
  userId: scoreInfo.user.value,
  title: scoreInfo.title,
  goal: scoreInfo.goal.value,
  score: scoreInfo.score,
  units: scoreInfo.units,
  type: scoreInfo.type.value,
});

export function* clearKpisPageStorage() {
  yield put(actions.clearSessionIds());
  yield put(actions.clearKpiRowIds());
  yield put(actions.clearScoreIds());
}
