import { createActionConst } from '../../../../utils/action.utils';

const BOARD_NAMESPACE = 'BOARDS';

export const CREATE_BOARD = createActionConst(BOARD_NAMESPACE, 'CREATE_BOARD');
export const UPDATE_BOARD = createActionConst(BOARD_NAMESPACE, 'UPDATE_BOARD');
export const DELETE_BOARD = createActionConst(BOARD_NAMESPACE, 'DELETE_BOARD');

export const STORE_CURRENT_BOARD = createActionConst(BOARD_NAMESPACE, 'STORE_CURRENT_BOARD');
export const CLEAR_CURRENT_BOARD = createActionConst(BOARD_NAMESPACE, 'CLEAR_CURRENT_BOARD');

export const STORE_IS_FETCHED = createActionConst(BOARD_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(BOARD_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_BOARD = createActionConst(BOARD_NAMESPACE, 'STORE_BOARD');
export const CLEAR_BOARD = createActionConst(BOARD_NAMESPACE, 'CLEAR_BOARD');
export const SAVE_BOARD_ORDER = createActionConst(BOARD_NAMESPACE, 'SAVE_BOARD_ORDER');

export const SAVE_BOARD_SOCKET = 'saveBoards';
export const DELETE_BOARD_SOCKET = 'deleteBoards';
