import React, { FunctionComponent } from 'react';
import { scoreSelectors } from '../../../../../../../../../app/resource/score/score.selectors';
import { useSelectWithParams } from '../../../../../../../../../utils/selector.utils';
import { IKpiRowScoreDetailsProps } from './types';

export const KpiRowScoreDetails: FunctionComponent<IKpiRowScoreDetailsProps> = ({ scoreId }) => {
  const score = useSelectWithParams(scoreSelectors.selectResourceById, scoreId);
  return (
    score && (
      <>
        <span className="kpi-header-measurable">{score.title}</span>
        <span className="kpi-header-goal">
          <span className="round-icon-small mr-1">{score.goal}</span> {score.score} {score.units}
        </span>
      </>
    )
  );
};
