import { all, call, fork, takeLatest } from 'redux-saga/effects';

import * as constants from './chart.constants';
import * as types from './chart.types';
import * as services from './chart.saga-services';

function* invokeCreateChart(action: types.ICreateChart) {
  yield call(services.createNode, action.payload);
}

function* invokeUpdateChart(action: types.IUpdateChart) {
  yield call(services.updateNode, action.payload.id, action.payload.data);
}
function* invokeDeleteChart(action: types.IDeleteChart) {
  yield call(services.deleteNode, action.payload);
}

/*
function* invokeSaveChartSocket(action: types.ISaveChartSocket) {
  yield call(services.handleChartFromNotification);
}
*/
export function* watchChartPurePageSaga() {
  yield takeLatest(constants.CREATE_CHART, invokeCreateChart);
  yield takeLatest(constants.UPDATE_CHART, invokeUpdateChart);
  yield takeLatest(constants.DELETE_CHART, invokeDeleteChart);
  //yield takeEvery(constants.SAVE_CHART_SOCKET, invokeSaveChartSocket);
}

export function* watchChartPageSaga() {
  yield all([watchChartPurePageSaga].map(fork));
}
