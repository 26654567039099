import { combineReducers, Reducer } from 'redux';

import * as types from './account-projects.types';
import * as constants from './account-projects.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.IAccountProjectPageState = {
  accountProjectIds: [],
};

const accountProjectsReducer: Reducer<
  types.IAccountProjectPageState['accountProjectIds'],
  types.IStoreAccountProject | types.IClearAccountProject
> = (state = initialState.accountProjectIds, action) => {
  if (isActionOfType<types.IStoreAccountProject>(action, constants.STORE_ACCOUNT_PROJECT)) {
    return action.payload;
  } else if (isActionOfType<types.IClearAccountProject>(action, constants.CLEAR_ACCOUNT_PROJECT)) {
    return initialState.accountProjectIds;
  }

  return state;
};

export const accountProjectsPageReducer: Reducer<
  types.IAccountProjectPageState,
  types.IAccountProjectPageAction
> = combineReducers({
  accountProjectIds: accountProjectsReducer,
});
