import { all, call, put } from 'redux-saga/effects';

import * as types from './notification.types';
import * as actions from './notification.actions';

import { apiRequest } from '../../api/api.saga-services';

import * as notificationApi from '../../api/request-config/notification.api';
import { notificationSelectors } from './notification.selectors';
import { select } from '../../../utils/selector.utils';

export function* storeNotification(notification: types.INotification) {
  yield put(actions.storeNotification(notification));
  return notification;
}

export function* clearNotification(notificationId: types.INotification['id']) {
  yield put(actions.clearNotification(notificationId));
}

export function* clearAllNotifications() {
  const notificationIds: types.INotification['id'][] = yield select(notificationSelectors.selectResourceIds);
  yield put(actions.clearNotification(notificationIds));
}
/* This method sends api request and handle response */
export function* searchNotifications(searchParams?: types.INotificationSearchParams) {
  const notifications = (yield call(
    apiRequest,
    notificationApi.searchNotificationsApi(searchParams),
  )) as types.INotification[];

  if (!notifications) {
    return;
  }
  // yield all(notifications.map((notification) => call(storeNotification, notification)));
  yield all(
    notifications.map((notification) =>
      call(storeNotification, { ...notification, status: 'seen', id: notification.id.toString() }),
    ),
  );

  return notifications;
}

/* This method sends api request and handle response */
export function* updateNotification(notificationId: types.INotification['id']) {
  const notification = (yield call(
    apiRequest,
    notificationApi.updateNotificationApi(notificationId),
  )) as types.INotification;

  if (!notification) {
    return;
  }
  notification.id = notification.id.toString();
  call(storeNotification, notification);
}

/* This method sends api request and handle response */
export function* updateNotifications(notificationIds: types.INotification['id'][]) {
  const notifications = (yield call(
    apiRequest,
    notificationApi.updateNotificationsApi(notificationIds),
  )) as types.INotification[];

  if (!notifications) {
    return;
  }

  yield all(
    notifications.map((notification) => call(storeNotification, { ...notification, id: notification.id.toString() })),
  );
}
