/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent, useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { closeDialog } from '../../../../app/dialog/dialog.actions';
import { dialogSelectors } from '../../../../app/dialog/dialog.selectors';

import { useSelectWithParams } from '../../../../utils/selector.utils';
import { BoardTaskForm } from '../../../pages/common/forms/BoardTaskForm';
import { SubmitButton } from '../../../pages/common/forms/SubmitButton';
import { ConnectedDialog } from '../../ConnectedDialog';
import { ITrelloCardFormDialogProps } from './types';

export const TrelloCardFormDialog: FunctionComponent = () => {
  const props = useSelectWithParams(
    dialogSelectors.selectDialogProps,
    'trelloCardFormDialog',
  ) as ITrelloCardFormDialogProps;
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(closeDialog('trelloCardFormDialog'));
  }, [dispatch]);

  return (
    <ConnectedDialog dialogKey="trelloCardFormDialog">
      <div className="popup new_trello_card_popup" style={{ display: 'block' }}>
        <div className="panel">
          <div className="panel-header small-header flex jcsb">
            <h3>Add Another card</h3>
            <span className="close-popup close" onClick={onClose}>
              <i className="icon-close" />
            </span>
          </div>
          <div className="panel-body">
            {props && <BoardTaskForm boardListId={props.boardListId} meetingId={props.meetingId} />}
          </div>
          <div className="panel-footer no-border flex jcr">
            <a className="link blueGray mr-2 close" onClick={onClose}>
              Close
            </a>
            <SubmitButton apiKey={'createTaskApi'} formName={'board-task-form'} text="Save" />
          </div>
        </div>
      </div>
    </ConnectedDialog>
  );
};
