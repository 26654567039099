import { apiSelectors } from '../app/api/api.selectors';
import { IApiRouteKey } from '../app/api/api.types';
import { useSelectWithParams } from './selector.utils';

export const useApiRequestState = (apiRouteKey: IApiRouteKey) => {
  const showLoader = useSelectWithParams(apiSelectors.selectApiInProgress, apiRouteKey);
  const error = useSelectWithParams(apiSelectors.selectApiError, apiRouteKey);

  return [showLoader, error];
};
