/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useState } from 'react';
import { authSelectors } from '../../../../../../app/auth/auth.selectors';
import { useSelect } from '../../../../../../utils/selector.utils';
import { IParticipantProps } from './types';

export const Participant: FunctionComponent<IParticipantProps> = ({ user }) => {
  const [toggle, setToggle] = useState(false);
  const loggedUser = useSelect(authSelectors.selectLoggedUser);

  return (
    <div className={`dropdown mr-1 mb-1 ${toggle ? 'opened' : ''}`}>
      <a
        className="btn white-bg darkGray dropdown-button small-shadow f-14 mx-0"
        data-action="dropdown"
        title={`${user.firstName} ${user.lastName}`}
        onClick={() => setToggle(!toggle)}
      >
        <span className="small-avatar mr-1">
          <img src={user.image} alt={`${user.firstName} ${user.lastName}`} className="img-fluid" />
          {loggedUser === user.id && (
            <span className="status-badge green-bg">
              <i className="icon-check" />
            </span>
          )}
        </span>
        {`${user.firstName} ${user.lastName}`}
      </a>
      <ul className="dropdown-menu no-lines">
        <li>
          <a href="" className="drop-item darkGray">
            <i className="icon2-slack mr-1 blueGray" /> {user.youtube ? user.youtube : '/'}
          </a>
        </li>
        <li>
          <a href="" className="drop-item darkGray">
            <i className="icon2-whatsapp mr-1 blueGray" /> {user.twitter ? user.twitter : '/'}
          </a>
        </li>
        <li>
          <a href="" className="drop-item darkGray">
            <i className="icon2-hangouts mr-1 blueGray" /> {user.facebook ? user.facebook : '/'}
          </a>
        </li>
        {/* <li>
          <a href="" className="drop-item darkGray">
            <i className="icon2-skype mr-1 blueGray" /> {user.skype ? user.skype : '/'}
          </a>
        </li> */}
      </ul>
    </div>
  );
};
