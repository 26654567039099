/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { columnSelectors } from '../../../../../../app/resource/column/column.selectors';
import { useSelectWithParams } from '../../../../../../utils/selector.utils';

import { IImgColumnProps } from './types';

export const ImgColumn: FunctionComponent<IImgColumnProps> = ({ columnId }) => {
  const column = useSelectWithParams(columnSelectors.selectResourceById, columnId);
  const history = useHistory();
  return (
    <a
      className="personas-item"
      onClick={() => history.push(`/agency-single/${columnId}`)}
      style={{ cursor: 'pointer' }}
    >
      <div className="persona-desc">
        <div className="competitive-item">
          <img src={column?.image} alt="" className="img-fluid competitive-img" />
        </div>
        <p className="bold mt-4 darkGray f-16">{column?.title}</p>
      </div>
    </a>
  );
};
