import { call, put } from 'redux-saga/effects';

import * as actions from './categories.actions';
import * as categoryResourceService from '../../../resource/category/category.saga-service';

import { ICategory } from '../../../resource/category/category.types';

export function* clearCategories() {
  yield put(actions.clearCategory());
}

export function* setupCategories() {
  const categories = (yield call(categoryResourceService.searchCategories)) as ICategory['id'][];

  yield put(categories ? actions.storeCategories(categories) : actions.clearCategory());
}

export function* clearCategoriesPageStorage() {
  yield put(actions.clearCategory());
}
/*
export function* handleCategoryFromNotification(CategoryResponse: ICategoryFromNotification) {
  const CategoryExists = (yield selectWithParams(
    CategorySelectors.selectResourceById,
    CategoryResponse.id,
  )) as ICategory | null;

  if (CategoryExists) {
    yield call(CategoryResourceService.storeCategory, { ...CategoryResponse });
  } else {
    const CategorysIds = yield select(CategorysPageSelectors.selectCategorys);
    yield call(CategoryResourceService.storeCategory, { ...CategoryResponse });

    yield put(actions.storeCategorys([...CategorysIds, CategoryResponse.id]));
  }
}
*/
