import React, { FunctionComponent } from 'react';
import { useSelect } from '../../../../../../../utils/selector.utils';
import { kpiPageSelectors } from '../../../../../../../app/page/page-storage/kpi/kpi.selectors';
import { KpiHeaderSession } from './KpiHeaderSession';

export const WidgetKpiHeader: FunctionComponent = () => {
  const sessionIds = useSelect(kpiPageSelectors.selectSessionIds);
  return (
    <div className="flex-table header panel mb-1 aic py-0" role="rowgroup">
      <div className="flex-row first" role="columnheader">
        <span className="kpi-header-user">USER</span>
        <span className="kpi-header-measurable">MEASURABLE</span>
        <span className="kpi-header-goal">GOAL</span>
      </div>
      <div className="flex-row" role="columnheader">
        <div className="panel single-line w-auto">
          {sessionIds.map((sessionId) => (
            <KpiHeaderSession key={sessionId} sessionId={sessionId} />
          ))}
        </div>
      </div>
    </div>
  );
};
