/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { tasksPageSelectors } from '../../../../../../app/page/page-storage/tasks/tasks.selectors';
import { useSelect } from '../../../../../../utils/selector.utils';
import { TaskFormTrigger } from '../../../../../dialogs/TaskFormDialog/TaskFormTrigger';
import { TaskWidgetLine } from '../../../widget-items/task-widget-items/TaskWIdgetLine';
import { IWidgetTasksSimpleLineViewProps } from './types';

export const WidgetTasksSimpleLineView: FunctionComponent<IWidgetTasksSimpleLineViewProps> = ({
  type,
  meetingId,
  title,
}) => {
  const reducedTasks = useSelect(tasksPageSelectors.selectReducedTasks);
  const userIds = useMemo(() => (reducedTasks[type] ? Object.keys(reducedTasks[type]) : []), [reducedTasks, type]);

  const [show, setShow] = useState(false);
  const handleShow = useCallback(() => setShow(!show), [show, setShow]);

  return (
    <div className="panel vto-text">
      <div className="panel-header no-border">
        <h2 className="bold">{title}</h2>
        <div className="ml-auto flex aic">
          <TaskFormTrigger type={type} meetingId={meetingId} />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="panel-body pt-0">
            <div className="border-radius-1 lightGray-bg px-2 py-1 h600">
              <div className="todo-container tabs">
                {userIds.map(
                  (userId) =>
                    reducedTasks[type][userId] &&
                    reducedTasks[type][userId].active.map((taskId, index) => (
                      <TaskWidgetLine
                        key={`${type}/${userId}/active/${taskId}`}
                        userId={userId}
                        taskId={taskId}
                        width={true}
                        index={index}
                        type={type}
                      />
                    )),
                )}

                <div className="hidden_cards_container">
                  <span className="card-separator hide-cards" onClick={handleShow}>
                    <span className="completed_text">{show ? `HIDE COMPLETED` : `SHOW COMPLETED`}</span>
                  </span>
                  {show &&
                    userIds.map(
                      (userId) =>
                        reducedTasks[type][userId] &&
                        reducedTasks[type][userId].done.map((taskId, index) => (
                          <TaskWidgetLine
                            key={`${type}/${userId}/done/${taskId}`}
                            userId={userId}
                            taskId={taskId}
                            width={true}
                            index={index}
                            type={type}
                          />
                        )),
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
