import { call } from 'redux-saga/effects';

import * as pageStateService from '../page-state/page-state.saga-services';
import * as pageInitializerService from '../page.initializer.saga-service';

import * as meetingService from '../page-storage/meetings/meetings.saga-services';
import * as taskService from '../page-storage/tasks/tasks.saga-services';
import * as agendaService from '../page-storage/agenda-items/agenda-items.saga-services';

import * as kpiService from '../page-storage/kpi/kpi.saga-services';
import * as sessionService from '../page-storage/sessions/sessions.saga-services';
import { ICurrentAgendaIndex } from '../page-storage/agenda-items/agenda-items.types';

export function* initalLiveMeetingPage(meetingParams: ICurrentAgendaIndex) {
  if (!meetingParams.meetingId) {
    return;
  }
  yield call(pageStateService.initPage, 'liveMeeting');

  if (!((yield call(pageInitializerService.initPage)) as boolean)) {
    yield call(meetingService.putMeetings);
    // yield call(agendaService.moveToNextAgenda, meetingParams);

    yield call(agendaService.setupAgendaItemsForMeeting, meetingParams.meetingId);
    yield call(taskService.setupTasksForMeeting, meetingParams.meetingId);

    yield call(kpiService.setupKpi, meetingParams.meetingId);
    yield call(sessionService.setupSessions, { id: meetingParams.meetingId });
  }

  yield call(pageStateService.completedInit, 'liveMeeting');
}
