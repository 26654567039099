/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FunctionComponent } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Task } from './Task';
import { IBoardBodyTasksProps } from './types';

export const BoardBodyTasks: FunctionComponent<IBoardBodyTasksProps> = ({ tasksIds, meetingId }) => {
  return (
    <div className="todo-container tabs">
      {tasksIds.map((taskId, index) => (
        <Draggable key={`draggable-task-${taskId}`} draggableId={taskId} index={index}>
          {(provided) => <Task key={`taskId-${taskId}`} taskId={taskId} meetingId={meetingId} provided={provided} />}
        </Draggable>
      ))}
    </div>
  );
};
