import { createActionConst } from '../../../../utils/action.utils';

const ANSWER_NAMESPACE = 'ANSWERS';

export const SETUP_ANSWER = createActionConst(ANSWER_NAMESPACE, 'SETUP_ANSWER');

export const CREATE_ANSWER = createActionConst(ANSWER_NAMESPACE, 'CREATE_ANSWER');
export const UPDATE_ANSWER = createActionConst(ANSWER_NAMESPACE, 'UPDATE_ANSWER');
export const DELETE_ANSWER = createActionConst(ANSWER_NAMESPACE, 'DELETE_ANSWER');

export const STORE_IS_FETCHED = createActionConst(ANSWER_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(ANSWER_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_ANSWER = createActionConst(ANSWER_NAMESPACE, 'STORE_ANSWER');
export const CLEAR_ANSWER = createActionConst(ANSWER_NAMESPACE, 'CLEAR_ANSWER');

export const SAVE_ANSWER_SOCKET = 'saveAnswer';
export const DELETE_ANSWER_SOCKET = 'deleteAnswer';
