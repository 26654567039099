import { call, takeLatest } from 'redux-saga/effects';

import * as constants from './agency-tools.constants';
import * as types from './agency-tools.types';
import * as services from './agency-tools.saga-services';

function* invokeInitialAgencyToolsPage(action: types.IInitialAgencyToolsPage) {
  yield call(services.initialAgencyToolsPage);
}

export function* watchAgencyToolsPageSaga() {
  yield takeLatest(constants.AGENCY_TOOLS_PAGE, invokeInitialAgencyToolsPage);
}
