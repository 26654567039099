import React from 'react';
import { FunctionComponent } from 'react';

import { projectTeamSelectors } from '../../../../../../../app/resource/project-member/project-member.selectors';
import { userSelectors } from '../../../../../../../app/resource/user/user.selectors';
import { useSelectWithParams } from '../../../../../../../utils/selector.utils';

import { ITeamProps } from './types';

export const Team: FunctionComponent<ITeamProps> = ({ teamId }) => {
  const team = useSelectWithParams(projectTeamSelectors.selectResourceById, teamId);
  const user = useSelectWithParams(userSelectors.selectResourceById, teamId);

  return (
    <div className="tracking-small-row">
      <div className="tracking-small-team-item px-2">
        <div className="account-item">
          <a href="timetracking-team-single.php" className="small-avatar mr-05">
            <img src={user?.image} alt="" className="img-fluid" />
            <span className="status-badge green-bg">
              <i className="icon-check green" />
            </span>
          </a>
          <div className="account-item-desc mr-1">
            <h3 className="account-item-title">
              <a href="timetracking-team-single.php" className="text-underline hover-green">
                {`${user?.firstName} ${user?.lastName}`}
              </a>
            </h3>
          </div>
          <img src="images/verified-badge.svg" alt="" className="img-fluid verified-badge" />
        </div>
      </div>
      <div className="tracking-actions">
        <div className="tracking-team-hours-num mr-2">{team?.totalHours}</div>
        <div className="tracking-client-progress">
          <div className="progressbar-container">
            <div className="progressbar-progress" style={{ width: `${team?.billablePercent}%` }} />
            <div className="progressbar-progress-secondary" style={{ width: `${team?.nonBillablePercent}%` }} />
          </div>
        </div>
        <div className="tracking-client team-team-capacity">
          <div className="tracking-team-capacity">{team?.capacity}</div>
        </div>
        <div className="tracking-client team-billable-hours">
          <div className="tracking-team-billable-hours">{team?.billableHours}</div>
        </div>
      </div>
    </div>
  );
};
