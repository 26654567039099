import { call } from 'redux-saga/effects';

import * as pageInitializerService from '../page.initializer.saga-service';
import * as pageStateService from '../page-state/page-state.saga-services';
import * as sessionService from '../page-storage/sessions/sessions.saga-services';
import * as userGroupService from '../page-storage/user-groups/user-groups.saga-services';
import * as meetingInfoService from '../page-storage/meetings-info/meetings-info.saga-services';

import { select } from '../../../utils/selector.utils';
import { authSelectors } from '../../auth/auth.selectors';
import { IUser } from '../../resource/user/user.types';

export function* initialUserPage() {
  yield call(pageStateService.initPage, 'users');

  if (!((yield call(pageInitializerService.initPage)) as boolean)) {
    const userId = (yield select(authSelectors.selectLoggedUser)) as IUser['id'];

    yield call(userGroupService.getUserGroups);
    yield call(sessionService.setupSessions, { userId: userId });
    yield call(meetingInfoService.setupMeetingsInfo);
  }

  yield call(pageStateService.completedInit, 'users');
}
