import { call, put, select } from 'redux-saga/effects';

import * as actions from './notifications.actions';

import * as notificationResourceService from '../../../resource/notification/notification.saga-service';
import { notificationsPageSelectors } from './notifications.selectors';
import { authSelectors } from '../../../auth/auth.selectors';
import { INotification } from '../../../resource/notification/notification.types';
import { notificationSelectors } from '../../../resource/notification/notification.selectors';

export function* setupNotifications() {
  // const currentUserId = yield select(authSelectors.selectLoggedUser);

  const isFetched = yield select(notificationsPageSelectors.selectIsFetched);

  let notifications: INotification[] = [];

  if (!isFetched) {
    // notifications = (yield call(notificationResourceService.searchNotifications, {
    //   receiverId: currentUserId,
    // })) as INotification['id'][];

    notifications = (yield call(notificationResourceService.searchNotifications)) as INotification[];

    yield put(notifications ? actions.storeIsFetched() : actions.clearIsFetched());
  } else {
    notifications = yield select(notificationSelectors.selectResourceList);
  }
  yield put(actions.storeUnseenCount(notifications.filter((notification) => notification.status === 'unseen').length));
  yield put(actions.storeFetchedNotifications(notifications.map((notification) => notification.id)));
}

export function* handleNewNotificationNotification(notification: INotification) {
  const currentUserId = yield select(authSelectors.selectLoggedUser);

  if (notification.receiverId !== currentUserId) {
    return;
  }

  yield call(notificationResourceService.storeNotification, { ...notification, status: 'unseen' });

  const notifications = (yield select(notificationsPageSelectors.selectFetchedNotifications)) as INotification['id'][];
  yield put(actions.storeFetchedNotifications([notification.id, ...notifications]));

  const unseenCount = yield select(notificationsPageSelectors.selectUnseenCount);
  yield put(actions.storeUnseenCount(unseenCount + 1));
}

export function* clearNotificationsPageStorage() {
  yield put(actions.clearFetchedNotifications());
  yield put(actions.clearIsFetched());
}
