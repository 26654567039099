import { call } from 'redux-saga/effects';

import * as pageInitializerService from '../page.initializer.saga-service';
import * as pageStateService from '../page-state/page-state.saga-services';
import * as categoriesPageService from '../page-storage/categories/categories.saga-services';

export function* initialAgencyToolsPage() {
  yield call(pageStateService.initPage, 'agencyTools');

  if (!((yield call(pageInitializerService.initPage)) as boolean)) {
    yield call(categoriesPageService.setupCategories);
  }
  yield call(pageStateService.completedInit, 'agencyTools');
}
