import { ISaveScoreOrder, IScoreCreate, IScoreSearchParams, IScoreUpdate } from '../../resource/score/score.types';
import { IApiRequestConfig } from '../api.types';

export const getScoreBoardApi = (searchParams: IScoreSearchParams): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'scoreBoard',
  uri: 'json',
  action: 'getScoreBoard',
  method: 'get',
  params: searchParams,
});

export const createScoreApi = (score: IScoreCreate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'createScoreApi',
  uri: 'json',
  action: 'saveScore',
  method: 'post',
  data: score,
});

export const updateScoreApi = (score: IScoreUpdate): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'updateScoreApi',
  uri: 'json',
  action: 'saveScore',
  method: 'post',
  data: score,
});

export const saveScoreOrderApi = (scoreIds: ISaveScoreOrder): IApiRequestConfig<unknown> => ({
  apiRouteKey: 'saveScoreOrderApi',
  uri: 'json',
  action: 'saveScoreOrder',
  method: 'post',
  data: scoreIds,
});
