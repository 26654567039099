/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../../../app/dialog/dialog.actions';
import { scoreSelectors } from '../../../../../../../app/resource/score/score.selectors';
import { useSelectWithParams } from '../../../../../../../utils/selector.utils';
import { IScoreRowProps } from './types';
import { UserCell } from './UserCell';

import Pause from '../../../../../../resources/images/pause.svg';

export const ScoreRow: FunctionComponent<IScoreRowProps> = ({ scoreId }) => {
  const dispatch = useDispatch();
  const score = useSelectWithParams(scoreSelectors.selectResourceById, scoreId);

  const openScoreForm = useCallback(() => {
    if (score) {
      dispatch(openDialog('scoreFormDialog', { meetingId: score.meetingId, score }));
    }
  }, [dispatch, score]);

  return (
    score && (
      <div className="flex-table" role="rowgroup">
        <div className="flex-row" role="cell">
          <UserCell userId={score.userId} />
        </div>
        <div className="flex-row f-14" role="cell">
          {score.title}
        </div>
        <div className="flex-row flex aic" role="cell">
          <span className="round-icon-small mr-1">{score.goal}</span>
          <div className="account-item-desc">
            <h3 className="account-item-title">{`${score.score} ${score.units}`}</h3>
          </div>
        </div>
        <div className="flex-row aic jcr last" role="cell">
          <a className="round-icon-small mr-1">
            <img src={Pause} className="img-fluid" />
          </a>
          <a className="round-icon-small mr-1 popup_button" data-popup="new_password_popup" onClick={openScoreForm}>
            <i className="icon-pencil" />
          </a>
          <a className="round-icon-small mr-0 popup_button" data-popup="delete_popup">
            <i className="icon2-trash" />
          </a>
        </div>
      </div>
    )
  );
};
