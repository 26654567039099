/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { ChangeEvent, useCallback, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import {
  deleteCheckList,
  updateCheckList,
} from '../../../../../../../../../../app/page/page-storage/checklists/checkLists.actions';

import { ChecklistItemForm } from '../../../../../../../../common/forms/ChecklistItemForm';

import { Items } from './Items';
import { ProgressBar } from './ProgressBar';

import { ICheckListProps } from './types';

export const CheckList: FunctionComponent<ICheckListProps> = ({ checkList }) => {
  const dispatch = useDispatch();
  const [isCreateActive, setCreateActive] = useState(false);
  const toggleCreate = () => {
    setCreateActive(!isCreateActive);
  };

  const [isDeleteActive, setDeleteActive] = useState(false);
  const toggleDelete = () => {
    setDeleteActive(!isDeleteActive);
  };

  const onDeleteCheckList = useCallback(() => {
    dispatch(deleteCheckList(checkList.id));
  }, [checkList.id, dispatch]);

  const [title, setTitle] = useState(checkList.title);

  const onBlur = useCallback(() => {
    dispatch(updateCheckList(checkList.id, { taskId: checkList.taskId, title: title }));
  }, [checkList.id, checkList.taskId, dispatch, title]);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const updatedTitle = event.target.value;
    setTitle(updatedTitle);
  }, []);

  return (
    <div className="col-12">
      <h4 className={isDeleteActive ? 'bold flex jcsb aic dropdown opened' : 'bold flex jcsb aic dropdown'}>
        <input type="text" className="editable" value={title} onChange={onChange} onBlur={(e) => onBlur()} />
        <a className="link link-red red px-2 dropdown-button" onClick={toggleDelete}>
          Delete
        </a>
        <div className="dropdown-menu drop-right max-h320">
          <div className="mini-popup">
            <div className="mini-popup-header">
              Delete checklist
              <span className="close_mini_popup" onClick={toggleDelete}>
                ×
              </span>
            </div>
            <div className="mini-popup-body">
              <p className="ln mb-2 darkGray normal-text">
                Deleting a checklist is permanent and there is no way to get it back.
              </p>
              <a className="btn btn-md red-bg white d-block w100 jcc normal-text mx-0" onClick={onDeleteCheckList}>
                Delete checklist
              </a>
            </div>
          </div>
        </div>
      </h4>
      <ProgressBar checkList={checkList} />
      {checkList.checklistItemsIds && <Items checkList={checkList} />}
      <div
        className={
          isCreateActive ? 'flex aic mt-2 add-checklist-container opened' : 'flex aic mt-2 add-checklist-container'
        }
      >
        <a className="btn btn-md green-bg white mr-1 add_checklist_item" title="Add an Item" onClick={toggleCreate}>
          <i className="icon icon-plus" /> Add an Item
        </a>
        <ChecklistItemForm checkListId={checkList.id} onClick={toggleCreate} />
      </div>
    </div>
  );
};
