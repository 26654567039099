import React from 'react';
import { FunctionComponent } from 'react';

import { projectTeamsPageSelectors } from '../../../../../../app/page/page-storage/project-members/project-members.selectors';
import { projectTeamSelectors } from '../../../../../../app/resource/project-member/project-member.selectors';
import { useSelect, useSelectWithParams } from '../../../../../../utils/selector.utils';

export const Total: FunctionComponent = () => {
  const teamsIds = useSelect(projectTeamsPageSelectors.selectProjectTeams);
  const teams = useSelectWithParams(projectTeamSelectors.selectResourcesById, teamsIds);

  const totalHours = teams.reduce((prev, curr) => (prev = prev + Number(curr?.totalHours)), 0);
  const teamCapacity = teams.reduce((prev, curr) => (prev = prev + Number(curr?.capacity)), 0);
  const billableHours = teams.reduce(
    (prev, curr) => (prev = prev + Number(curr?.billableHours ? curr.billableHours : '0')),
    0,
  );
  const billablePercent = (billableHours / totalHours) * 100;
  const nonBillableHours = teams.reduce((prev, curr) => (prev = prev + Number(curr?.nonBillableHours)), 0);
  const nonBillablePercent = (nonBillableHours / totalHours) * 100;

  return (
    <div className="panel white-bg flex p-4 mt-2">
      <div className="row w100">
        <div className="col-6 flex">
          <div className="team-total-hours green">
            <span>Total Hours:</span>
            <span className="team-total-hours-num">{totalHours.toString()}</span>
          </div>
          <div className="team-total-hours">
            <span>Team Capacity:</span>
            <span className="team-total-hours-num">{teamCapacity}</span>
          </div>
        </div>
        <div className="col-6">
          <div className="tracking-team-progress">
            <div className="progressbar-percetages">
              <span className="progress-checkpoints progress20">25%</span>
              <span className="progress-checkpoints progress40">50%</span>
              <span className="progress-checkpoints progress60">75%</span>
              <span className="progress-checkpoints progress80">100%</span>
            </div>
            <div className="progressbar-container big-progress">
              <div className="progressbar-progress" style={{ width: `${billablePercent}%` }} />
              <div className="progressbar-progress-secondary" style={{ width: `${nonBillablePercent}%` }} />
            </div>
            <div className="team-progress-legend">
              <span className="billable-hours">Billable Hours</span>
              <span className="nonbillable-hours">Non-Billable Hours</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
