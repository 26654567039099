import { call, put } from 'redux-saga/effects';

import * as actions from './account-projects.actions';
import * as accountProjectResourceService from './../../../resource/account-project/account-project.saga-service';

import { IAccountProject } from '../../../resource/account-project/account-project.types';

export function* clearAccountProjects() {
  yield put(actions.clearAccountProject());
}

export function* setupAccountProjects(accountId?: string) {
  const accountProjects = (yield call(
    accountProjectResourceService.searchAccountProjects,
    accountId,
  )) as IAccountProject['id'][];

  yield put(accountProjects ? actions.storeAccountProjects(accountProjects) : actions.clearAccountProject());
}
/*
export function* createAccountProject(accountProjectInfo: IAccountProjectCreateForm) {
  const AccountProject = (yield call(
    AccountProjectResourceService.createAccountProject,
    mapAccountProjectFormToAccountProjectCreate(AccountProjectInfo),
  )) as IAccountProject;

  if (!AccountProject) {
    return;
  }
}

export function* updateAccountProject(
  AccountProjectId: IAccountProject['id'],
  AccountProjectInfo: IAccountProjectCreateForm,
) {
  const AccountProject = (yield call(AccountProjectResourceService.updateAccountProject, {
    id: AccountProjectId,
    ...mapAccountProjectFormToAccountProjectCreate(AccountProjectInfo),
  })) as IAccountProject;

  if (!AccountProject) {
    return;
  }
}

export function* deleteAccountProject(AccountProjectId: IAccountProject['id']) {
  yield call(AccountProjectResourceService.deleteAccountProject, AccountProjectId);

  const AccountProjectsIds: IAccountProject['id'][] = yield select(AccountProjectsPageSelectors.selectAccountProjects);

  yield put(
    AccountProjectsIds
      ? actions.storeAccountProjects(AccountProjectsIds.filter((id) => AccountProjectId !== id))
      : actions.clearAccountProject(),
  );
}

export const mapAccountProjectFormToAccountProjectCreate = (
  AccountProjectInfo: IAccountProjectCreateForm,
): IAccountProjectCreate => ({
  title: AccountProjectInfo.title,
  billable: AccountProjectInfo.billable,
  budget: AccountProjectInfo.budget,
  budgetHours: AccountProjectInfo.budgetHours,
  hourlyRate: AccountProjectInfo.hourlyRate,
  changedBy: AccountProjectInfo.changedBy,
  changedAt: AccountProjectInfo.changedAt,
  deleted: AccountProjectInfo.deleted,
  projectId: AccountProjectInfo.projectId,
});

export function* clearAccountProjectsPageStorage() {
  yield put(actions.clearAccountProject());
}
/*
export function* handleAccountProjectFromNotification(AccountProjectResponse: IAccountProjectFromNotification) {
  const AccountProjectExists = (yield selectWithParams(
    AccountProjectSelectors.selectResourceById,
    AccountProjectResponse.id,
  )) as IAccountProject | null;

  if (AccountProjectExists) {
    yield call(AccountProjectResourceService.storeAccountProject, { ...AccountProjectResponse });
  } else {
    const AccountProjectsIds = yield select(AccountProjectsPageSelectors.selectAccountProjects);
    yield call(AccountProjectResourceService.storeAccountProject, { ...AccountProjectResponse });

    yield put(actions.storeAccountProjects([...AccountProjectsIds, AccountProjectResponse.id]));
  }
}
*/
