/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FunctionComponent } from 'react';
import { CheckListForm } from '../../../../../../../../common/forms/CheckListForm';
import { ICheckListMenuProps } from './types';

export const CheckListMenu: FunctionComponent<ICheckListMenuProps> = ({ taskId }) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  //const close = useCallback(() => setActive(false), [setActive]);
  return (
    <div className={isActive ? 'dropdown opened' : 'dropdown'}>
      <a
        className="btn w100 gray-bg darkGray px-1 f-1 ml-0 mb-1 mt-1 dropdown-button"
        title="Checklist"
        onClick={toggleClass}
      >
        <i className="icon f-9 blueGray icon-check mr-1" /> Checklist
      </a>
      <div className="dropdown-menu drop-right">
        <div className="mini-popup">
          <div className="mini-popup-header">
            Add checklist
            <span className="close_mini_popup" onClick={toggleClass}>
              ×
            </span>
          </div>
          <div className="mini-popup-body">
            <CheckListForm taskId={taskId} onClick={toggleClass} />
          </div>
        </div>
      </div>
    </div>
  );
};
