import { put, select, call, all } from 'redux-saga/effects';
import * as types from './project-member.types';
import * as actions from './project-member.actions';
import * as api from './../../api/request-config/project-member.api';
import { projectTeamSelectors } from './project-member.selectors';
import { apiRequest } from '../../api/api.saga-services';

export function* storeProjectMember(projectMember: types.IProjectTeam) {
  yield put(actions.storeProjectMember(projectMember));

  return projectMember;
}

export function* clearProjectMember(projectMemberId: types.IProjectMember['id']) {
  yield put(actions.clearProjectMember(projectMemberId));
}

export function* clearAllProjectMembers() {
  const projectMembersIds: types.IProjectMember['id'][] = yield select(projectTeamSelectors.selectResourceIds);
  yield put(actions.clearProjectMember(projectMembersIds));
}

export function* searchProjectMembers(week: string, accountId?: string) {
  const projectMembers: types.IProjectTeam[] = yield call(apiRequest, api.searchProjectMembersApi(week, accountId));

  projectMembers.forEach((member) => (member.id = member.userId));
  yield all(projectMembers.map((projectMember) => call(storeProjectMember, projectMember)));
  return projectMembers.map((projectMember) => projectMember.id);
}

export function* createProjectMember(data: types.IProjectTeamCreate) {
  //const projectMember = (yield call(apiRequest, api.createProjectMemberApi(data))) as types.IProjectMember;
  const projectMember = data as types.IProjectTeam;
  if (!projectMember) {
    return;
  }
  yield call(storeProjectMember, projectMember);
  return projectMember;
}

export function* updateProjectMember(data: types.IProjectTeamUpdate) {
  //const projectMember = (yield call(apiRequest, api.updateProjectMemberApi(data))) as types.IProjectMember;
  const projectMember = data as types.IProjectTeam;
  if (!projectMember) {
    return;
  }
  projectMember.id = data.id;
  yield call(storeProjectMember, projectMember);
  return projectMember;
}

export function* deleteProjectMember(projectMemberId: types.IProjectMember['id']) {
  yield call(apiRequest, api.deleteProjectMemberApi(projectMemberId));
}
