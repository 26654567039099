import { createActionConst } from '../../../../utils/action.utils';

const CATEGORY_NAMESPACE = 'CATEGORYS';

export const SETUP_CATEGORY = createActionConst(CATEGORY_NAMESPACE, 'SETUP_CATEGORY');

export const CREATE_CATEGORY = createActionConst(CATEGORY_NAMESPACE, 'CREATE_CATEGORY');
export const UPDATE_CATEGORY = createActionConst(CATEGORY_NAMESPACE, 'UPDATE_CATEGORY');
export const DELETE_CATEGORY = createActionConst(CATEGORY_NAMESPACE, 'DELETE_CATEGORY');

export const STORE_IS_FETCHED = createActionConst(CATEGORY_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(CATEGORY_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_CATEGORY = createActionConst(CATEGORY_NAMESPACE, 'STORE_CATEGORY');
export const CLEAR_CATEGORY = createActionConst(CATEGORY_NAMESPACE, 'CLEAR_CATEGORY');

export const SAVE_CATEGORY_SOCKET = 'saveCategory';
export const DELETE_CATEGORY_SOCKET = 'deleteCategory';
