/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../../../../../app/dialog/dialog.actions';
import { taskSelectors } from '../../../../../../../app/resource/task/task.selectors';

import { ITask } from '../../../../../../../app/resource/task/task.types';
import { useSelect, useSelectWithParams } from '../../../../../../../utils/selector.utils';
import { Labels } from './Labels';
import { Members } from './Members';

import { ITaskProps } from './types';
import { meetingsPageSelectors } from '../../../../../../../app/page/page-storage/meetings/meetings.selectors';

export const Task: FunctionComponent<ITaskProps> = ({ taskId, meetingId, provided }) => {
  let newId = taskId;
  const check = taskId.includes('-');
  if (check) {
    const sepIndex = taskId.indexOf('-');
    newId = taskId.slice(0, sepIndex);
  }

  const dispatch = useDispatch();
  const task = useSelectWithParams(taskSelectors.selectResourceById, newId) as ITask;

  const openTrelloReplacementFormDialog = useCallback(
    (taskId: ITask['id']) => {
      dispatch(openDialog('trelloReplacementFormDialog', { taskId, meetingId }));
    },
    [dispatch, meetingId],
  );
  const defaultStartDateValue = task?.dueDate;
  const defaultDueDate = useMemo(() => {
    try {
      if (defaultStartDateValue) {
        const dateString = moment(defaultStartDateValue).format('YYYY-MM-DD');
        if (dateString === 'Invalid date') {
          //dispatch(updateTask({ ...task, dueDate: '0000-00-00 00:00:00', label: task.label ? task.label : [] }));
          return '0000-00-00 00:00:00';
        }
        return defaultStartDateValue;
      }
    } catch (error) {
      console.log(error);
      return '0000-00-00 00:00:00';
    }
  }, [defaultStartDateValue]);
  const current = moment().startOf('day');

  //Difference in number of days
  const differenceDays = moment.duration(moment(defaultDueDate, 'YYYY-MM-DD').diff(current)).asDays();
  const status = task ? task.status : '0';
  const styleDate = useMemo(() => {
    if (defaultDueDate === '0000-00-00 00:00:00') {
      return 'lightGray-bg blueGray';
    } else if (status === '1') {
      return 'lightGreen-bg green';
    } else if (differenceDays <= 2 && differenceDays >= 0) {
      return 'lightYellow-bg yellow';
    } else if (differenceDays < 0) {
      return 'lightRed-bg red';
    } else {
      return 'lightGray-bg blueGray';
    }
  }, [defaultDueDate, differenceDays, status]);

  // const [checked, setChecked] = useState(task.status === '1');
  // const onCheck = () => {
  //   setChecked(!checked);
  // };

  // useEffect(() => {
  //   if ((task.status === '1') !== checked) {
  //     dispatch(updateTask({ label: [], ...task, status: checked ? '1' : '0' }));
  //   }
  // }, [checked, dispatch, task]);

  const filteredMeetingIds = useSelect(meetingsPageSelectors.selectFilteredMeetings);
  const [filteredTask, setFilteredTask] = useState(false);
  useEffect(() => {
    setFilteredTask(filteredMeetingIds.some((id) => task.meetingIds.includes(id)));
  }, [filteredMeetingIds, task]);
  return (
    task && (
      <a
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        className="card board-card panel popup_button"
        onClick={() => openTrelloReplacementFormDialog(task.id)}
        style={filteredTask === true ? { display: 'none' } : {}}
      >
        {task.label && <Labels labels={task.label} taskId={task.id} />}
        <div className="card-body">
          <div className="card-desc">{task.title}</div>
        </div>
        <div className="card-footer flex aic jcsb">
          <div className="text-left flex aic">
            <div className={`badge board-badge ${styleDate} mr-1`}>
              {defaultDueDate === '0000-00-00 00:00:00' || '' ? 'Not Set' : moment(defaultDueDate).format('MM/DD')}
            </div>
            {/* <div className="checklist-checkbox flex aic py-1 m-0">
              <input id="done" type="checkbox" checked={checked} onChange={onCheck} />
              <label htmlFor="done">&nbsp;</label>
            </div> */}
            <div className="blueGray mr-1 f-9">
              <i className="icon icon-hamburger" />
            </div>
            <div className="blueGray mr-1 f-9">
              <i className="icon icon-eye" />
            </div>
          </div>
          {task.members && <Members membersIds={task.members} />}
        </div>
      </a>
    )
  );
};
