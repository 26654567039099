/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useCallback } from 'react';
import { authSelectors } from '../../../../../app/auth/auth.selectors';
import { calendarItemSelectors } from '../../../../../app/resource/calendar-item/calendar-item.selectors';
import { useSelect, useSelectWithParams } from '../../../../../utils/selector.utils';
import { EventWidgets } from './EventWidgets';
import { ICalendarWidgetListProps } from './types';
export const CalendarWidgetList: FunctionComponent<ICalendarWidgetListProps> = ({ calendarItemId }) => {
  const calendarItem = useSelectWithParams(calendarItemSelectors.selectResourceById, calendarItemId);
  const currentUserId = useSelect(authSelectors.selectLoggedUser);

  // const grantedPermision = useSelect(authSelectors.selectGoogleGrantedPermision);

  const openNewWindow = useCallback(
    () =>
      window.open(
        `phpstack-696462-2300737.cloudwaysapps.com/googleapi/make_token?user_id=${currentUserId}`,
        'name',
        'width=600,height=400',
      ),
    [currentUserId],
  );
  return (
    <div className="panel-body">
      {calendarItem ? (
        calendarItem.eventIds && <EventWidgets eventIds={calendarItem.eventIds} />
      ) : (
        <p>
          Please, grant access for google calendars{' '}
          <a target="_blank" onClick={openNewWindow} style={{ cursor: 'pointer' }}>
            here
          </a>
          .
        </p>
      )}
    </div>
  );
};
//
