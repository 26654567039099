import { combineReducers, Reducer } from 'redux';

import * as types from './categories.types';
import * as constants from './categories.constants';
import { isActionOfType } from '../../../../utils/action.utils';

const initialState: types.ICategoryPageState = {
  categoryIds: [],
};

const categoriesReducer: Reducer<
  types.ICategoryPageState['categoryIds'],
  types.IStoreCategory | types.IClearCategory
> = (state = initialState.categoryIds, action) => {
  if (isActionOfType<types.IStoreCategory>(action, constants.STORE_CATEGORY)) {
    return action.payload;
  } else if (isActionOfType<types.IClearCategory>(action, constants.CLEAR_CATEGORY)) {
    return initialState.categoryIds;
  }

  return state;
};

export const categoriesPageReducer: Reducer<types.ICategoryPageState, types.ICategoryPageAction> = combineReducers({
  categoryIds: categoriesReducer,
});
