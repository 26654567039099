import * as types from './page-state.types';
import * as constants from './page-state.constants';

export const storePageInProgress = (key: types.IPageKey): types.IStorePageStateInProgress => ({
  type: constants.STORE_PAGE_STATE_IN_PROGRESS,
  payload: key,
});
export const clearPageInProgress = (key: types.IPageKey): types.IClearPageStateInProgress => ({
  type: constants.CLEAR_PAGE_STATE_IN_PROGRESS,
  payload: key,
});

export const storePageError = (key: types.IPageKey, error: string): types.IStorePageStateError => ({
  type: constants.STORE_PAGE_STATE_ERROR,
  payload: {
    key,
    error,
  },
});
export const clearPageError = (key: types.IPageKey): types.IClearPageStateError => ({
  type: constants.CLEAR_PAGE_STATE_ERROR,
  payload: key,
});
