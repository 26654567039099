import React, { FunctionComponent, useCallback } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { moveBoardList } from '../../../../../app/page/page-storage/boardLists/boardLists.actions';
import { dndTask } from '../../../../../app/page/page-storage/tasks/tasks.actions';

export const BoardListDragDropContext: FunctionComponent = ({ children }) => {
  const dispatch = useDispatch();
  const handleDrop = useCallback(
    (result: DropResult) => {
      console.log(result);
      if (result.type === 'column') {
        dispatch(moveBoardList(result));
      } else {
        dispatch(dndTask(result));
      }
    },
    [dispatch],
  );

  return <DragDropContext onDragEnd={handleDrop}>{children}</DragDropContext>;
};
