/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createScore, updateScore } from '../../../../../app/page/page-storage/kpi/kpi.actions';
import { meetingSelectors } from '../../../../../app/resource/meeting/meeting.selectors';
import { IScore, IScoreCreateForm } from '../../../../../app/resource/score/score.types';
import { userSelectors } from '../../../../../app/resource/user/user.selectors';
import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { useSelect } from '../../../../../utils/selector.utils';
import { SelectInput } from '../../inputs/SelectInput';
import { FormTextInput } from '../../inputs/TextInput';
import { IScoreFormProps } from './types';

const TYPE_OPTIONS = [
  { value: 'number', label: 'Number' },
  { value: 'dollar', label: 'Dollar' },
  { value: 'precentage', label: 'Precentage' },
];

const GOAL_OPTIONS = [
  { value: '<=', label: '<=' },
  { value: '==', label: '==' },
  { value: '>=', label: '>=' },
];

export const ScoreForm: FunctionComponent<IScoreFormProps> = ({ meetingId, score }) => {
  const dispatch = useDispatch();
  const formProps = useForm();
  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: IScoreCreateForm, scoreId: IScore['id'] | undefined) => {
    if (!scoreId) {
      dispatch(createScore({ ...data }));
    } else {
      dispatch(updateScore(scoreId, data));
    }
  };

  const users = useSelect(userSelectors.selectResourceList);
  const meetings = useSelect(meetingSelectors.selectResourceList);

  const meeting = useMemo(() => meetings.find((meeting) => meeting.id === meetingId), [meetingId, meetings]);

  const [selectedUser, userOptions] = useMemo(() => {
    const mappedUsers = users
      .filter((user) =>
        meeting ? meeting.shareWithIds.includes(user.id) || meeting.administratorIds.includes(user.id) : false,
      )
      .map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      }));

    let foundUser = undefined;
    if (score && score.userId) {
      foundUser = mappedUsers.find((option) => option.value === score.userId);
    }

    return [foundUser, mappedUsers];
  }, [meeting, score, users]);

  const [selectedMeeting, meetingOptions] = useMemo(() => {
    const mappedMeetings = meetings.map((meeting) => ({
      value: meeting.id,
      label: `${meeting.title}`,
    }));

    const foundMeetings = meeting
      ? {
          value: meeting.id,
          label: `${meeting.title}`,
        }
      : undefined;

    return [foundMeetings, mappedMeetings];
  }, [meeting, meetings]);

  const [selectedGoal, goalOptions] = useMemo(() => {
    let selectedGoal = undefined;
    if (score && score.goal) {
      selectedGoal = GOAL_OPTIONS.find((option) => option.value === score.goal);
    }
    return [selectedGoal, GOAL_OPTIONS];
  }, [score]);

  const [selectedType, typeOptions] = useMemo(() => {
    let selectedType = undefined;
    if (score && score.type) {
      selectedType = TYPE_OPTIONS.find((option) => option.value === score.type);
    }
    return [selectedType, TYPE_OPTIONS];
  }, [score]);

  // () => {
  //   let selectedType = undefined;
  //   if (score && score.type) {
  //     selectedType = TYPE_OPTIONS.find((option) => option.value === score.type);
  //   }
  //   return [selectedType, TYPE_OPTIONS];
  // },

  return (
    <FormProvider {...formProps}>
      <form
        id="score-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IScoreCreateForm, score?.id))}
        method="post"
      >
        <div className="field-container">
          <FormTextInput
            id="title"
            name="title"
            type="text"
            label="Title"
            placeholder="Enter Title"
            defaultValue={score ? score.title : ''}
            validation={requiredValidation}
          />
        </div>
        <div className="field-container">
          <SelectInput
            id="user"
            name="user"
            label="User"
            placeholder="Search..."
            defaultOptions={selectedUser}
            validation={requiredValidation}
            options={userOptions}
            isSingle
          />
        </div>

        <div className="field-container">
          <SelectInput
            id="meeting"
            name="meeting"
            label="Meeting"
            placeholder="Search..."
            defaultOptions={selectedMeeting}
            validation={requiredValidation}
            options={meetingOptions}
            isSingle
          />
        </div>

        {/* <div className="field-container error">
          <label>
            Status
            <span className="error-msg">Something is wrong.</span>
          </label>
          <div className="field-container custom-select with-checkbox">
            <input type="hidden" />
            <span className="select-field">
              <span className="select-value">Select</span> <i className="icon-down-dir" />
            </span>
            <div className="select-dropdown">
              <span className="select-item selected">
                <span className="d-inline-block badge lightBlue-bg blue" data-val="on track">
                  Open
                </span>
              </span>
              <span className="select-item">
                <span className="d-inline-block badge lightRed-bg red" data-val="off track">
                  Urgent
                </span>
              </span>
              <span className="select-item">
                <span className="d-inline-block badge lightYellow-bg yellow" data-val="done">
                  <i className="icon-check" /> On Hold
                </span>
              </span>
            </div>
          </div>
        </div>
        <div>
          <a className="btn btn-sm lightGray-bg darkGray btn-border mx-0 d-inline-flex mb-3 custom_status">
            <i className="icon-plus" /> Custom status
          </a>
        </div> */}

        <div className="field-container">
          <SelectInput
            id="goal"
            name="goal"
            label="Goal"
            placeholder="Select..."
            options={goalOptions}
            defaultOptions={selectedGoal}
            isSingle
          />
        </div>

        <div className="field-container">
          <FormTextInput
            id="score"
            name="score"
            label="Score"
            validation={requiredValidation}
            defaultValue={score ? score.score : ''}
          />
        </div>
        <div className="field-container">
          <FormTextInput
            id="units"
            name="units"
            label="Units"
            validation={requiredValidation}
            defaultValue={score ? score.units : ''}
          />
        </div>

        <div className="field-container">
          <SelectInput
            id="type"
            name="type"
            label="Type"
            placeholder="Select..."
            options={typeOptions}
            defaultOptions={selectedType}
            isSingle
          />
        </div>
      </form>
    </FormProvider>
  );
};
