import { call } from 'redux-saga/effects';
import { IMeeting } from '../../resource/meeting/meeting.types';

import * as pageStateService from '../page-state/page-state.saga-services';
import * as meetingService from '../page-storage/meetings/meetings.saga-services';
import * as pageInitializerService from '../page.initializer.saga-service';

import * as kpiService from '../page-storage/kpi/kpi.saga-services';
import * as sessionService from '../page-storage/sessions/sessions.saga-services';

export function* initialKpiPage(meetingId: IMeeting['id']) {
  yield call(pageStateService.initPage, 'kpi');
  if (!((yield call(pageInitializerService.initPage)) as boolean)) {
    yield call(meetingService.putMeetings);
    yield call(kpiService.setupKpi, meetingId);
    yield call(sessionService.setupSessions, { id: meetingId });
  }
  yield call(pageStateService.completedInit, 'kpi');
}
