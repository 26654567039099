import { createActionConst } from '../../../../utils/action.utils';

const CHECKLIST_NAMESPACE = 'CHECKLISTS';

export const CREATE_CHECKLIST = createActionConst(CHECKLIST_NAMESPACE, 'CREATE_CHECKLIST');
export const UPDATE_CHECKLIST = createActionConst(CHECKLIST_NAMESPACE, 'UPDATE_CHECKLIST');
export const DELETE_CHECKLIST = createActionConst(CHECKLIST_NAMESPACE, 'DELETE_CHECKLIST');

export const STORE_IS_FETCHED = createActionConst(CHECKLIST_NAMESPACE, 'STORE_IS_FETCHED');
export const CLEAR_IS_FETCHED = createActionConst(CHECKLIST_NAMESPACE, 'CLEAR_IS_FETCHED');

export const STORE_CHECKLIST = createActionConst(CHECKLIST_NAMESPACE, 'STORE_CHECKLIST');
export const CLEAR_CHECKLIST = createActionConst(CHECKLIST_NAMESPACE, 'CLEAR_CHECKLIST');

export const SAVE_CHECKLIST_SOCKET = 'saveCheckList';
export const DELETE_CHECKLIST_SOCKET = 'deleteCheckList';

const CHECKLIST_ITEM_NAMESPACE = 'CHECKLIST_ITEMS';

export const CREATE_CHECKLIST_ITEM = createActionConst(CHECKLIST_ITEM_NAMESPACE, 'CREATE_CHECKLIST_ITEM');
export const UPDATE_CHECKLIST_ITEM = createActionConst(CHECKLIST_ITEM_NAMESPACE, 'UPDATE_CHECKLIST_ITEM');
export const DELETE_CHECKLIST_ITEM = createActionConst(CHECKLIST_ITEM_NAMESPACE, 'DELETE_CHECKLIST_ITEM');

export const STORE_CHECKLIST_ITEM = createActionConst(CHECKLIST_ITEM_NAMESPACE, 'STORE_CHECKLIST_ITEM');
export const CLEAR_CHECKLIST_ITEM = createActionConst(CHECKLIST_ITEM_NAMESPACE, 'CLEAR_CHECKLIST_ITEM');

export const SAVE_CHECKLIST_ITEM_SOCKET = 'saveChecklistItem';
export const DELETE_CHECKLIST_ITEM_SOCKET = 'deleteChecklistItem';
