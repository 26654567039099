import { call, put, select } from 'redux-saga/effects';

import * as actions from './project-members.actions';
import * as projectMemberResourceService from './../../../resource/project-member/project-member.saga-service';

import { projectTeamsPageSelectors } from './project-members.selectors';
import {
  IProjectMember,
  IProjectMemberCreate,
  IProjectMemberCreateForm,
  IProjectTeam,
} from '../../../resource/project-member/project-member.types';

export function* clearProjectMembers() {
  yield put(actions.clearProjectMember());
}

export function* setupProjectTeams(week: string, accountId?: string) {
  const projectTeams = (yield call(
    projectMemberResourceService.searchProjectMembers,
    week,
    accountId,
  )) as IProjectTeam['userId'][];

  yield put(projectTeams ? actions.storeProjectMembers(projectTeams) : actions.clearProjectMember());
}

export function* createProjectMember(projectMemberInfo: IProjectMemberCreateForm) {
  /* const projectMember = (yield call(
    projectMemberResourceService.createProjectMember,
    mapProjectMemberFormToProjectMemberCreate(projectMemberInfo),
  )) as IProjectMember;

  if (!projectMember) {
    return;
  }*/
}

export function* updateProjectMember(
  projectMemberId: IProjectMember['id'],
  projectMemberInfo: IProjectMemberCreateForm,
) {
  /*
  const projectMember = (yield call(projectMemberResourceService.updateProjectMember, {
    id: projectMemberId,
    ...mapProjectMemberFormToProjectMemberCreate(projectMemberInfo),
  })) as IProjectMember;

  if (!projectMember) {
    return;
  }*/
}

export function* deleteProjectMember(projectMemberId: IProjectMember['id']) {
  yield call(projectMemberResourceService.deleteProjectMember, projectMemberId);

  const projectMembersIds: IProjectMember['id'][] = yield select(projectTeamsPageSelectors.selectProjectTeams);

  yield put(
    projectMembersIds
      ? actions.storeProjectMembers(projectMembersIds.filter((id) => projectMemberId !== id))
      : actions.clearProjectMember(),
  );
}

export const mapProjectMemberFormToProjectMemberCreate = (
  ProjectMemberInfo: IProjectMemberCreateForm,
): IProjectMemberCreate => ({
  manage: ProjectMemberInfo.manage,
  budgetHours: ProjectMemberInfo.budgetHours,
  hourlyRate: ProjectMemberInfo.hourlyRate,
  changedBy: ProjectMemberInfo.changedBy,
  changedAt: ProjectMemberInfo.changedAt,
  deleted: ProjectMemberInfo.deleted,
  projectId: ProjectMemberInfo.projectId,
  userId: ProjectMemberInfo.userId,
});

export function* clearProjectMembersPageStorage() {
  yield put(actions.clearProjectMember());
}
/*
export function* handleProjectMemberFromNotification(projectMemberResponse: IProjectMemberFromNotification) {
  const projectMemberExists = (yield selectWithParams(
    projectMemberSelectors.selectResourceById,
    projectMemberResponse.id,
  )) as IProjectMember | null;

  if (projectMemberExists) {
    yield call(projectMemberResourceService.storeProjectMember, { ...projectMemberResponse });
  } else {
    const projectMembersIds = yield select(projectTeamsPageSelectors.selectProjectMembers);
    yield call(projectMemberResourceService.storeProjectMember, { ...projectMemberResponse });

    yield put(actions.storeProjectMembers([...projectMembersIds, projectMemberResponse.id]));
  }
}
*/
