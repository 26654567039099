import React, { FunctionComponent } from 'react';
import { sessionSelectors } from '../../../../../../../../app/resource/session/session.selectors';
import { formatDayMonth } from '../../../../../../../../utils/date.utils';
import { useSelectWithParams } from '../../../../../../../../utils/selector.utils';
import { IKpiHeaderSessionProps } from './types';

export const KpiHeaderSession: FunctionComponent<IKpiHeaderSessionProps> = ({ sessionId }) => {
  const session = useSelectWithParams(sessionSelectors.selectResourceById, sessionId);
  return session && <div className="kpi-date">{formatDayMonth(session.sessionDate)}</div>;
};
