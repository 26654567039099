import React, { FunctionComponent } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Task } from '../../../BoardsPage/Board/BoardBody/BoardBodyTasks/Task';
import { MeetingWidget } from '../../widget-items/MeetingWidget';
import { TaskWidget } from '../../widget-items/task-widget-items/TaskWidget';
import { IWidgetListParams } from './types';
export const WidgetList: FunctionComponent<IWidgetListParams> = ({
  name,
  type,
  widgetItemIds,
  actionElement,
  droppableId,
}) => {
  return (
    <div className="single-card-container">
      <div className="panel-body p-0">
        <div className="border-radius-1 lightGray-bg p-2 pb-1">
          {name && (
            <div className="panel-header p-0">
              <h2 className="bold">{name}</h2>
              <div className="ml-auto flex">{actionElement}</div>
            </div>
          )}
          <Droppable droppableId={droppableId}>
            {(provided) => (
              <div className="todo-container tabs" {...provided.droppableProps} ref={provided.innerRef}>
                {widgetItemIds.map((id, index) => {
                  if (type === 'task') {
                    return (
                      <Draggable key={`${droppableId}/${id}`} draggableId={id} index={index}>
                        {(provided) => <TaskWidget taskId={id} provided={provided} />}
                      </Draggable>
                    );
                  } else if (type === 'meeting') {
                    return <MeetingWidget key={`${droppableId}/${id}`} meetingId={id} />;
                  } else if (type === 'taskBoard') {
                    return (
                      <Draggable key={`${droppableId}/${id}`} draggableId={id} index={index}>
                        {(provided) => <Task taskId={id} provided={provided} meetingId="" />}
                      </Draggable>
                    );
                  } else {
                    return <></>;
                  }
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </div>
  );
};
