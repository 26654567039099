import { all, call, put, select } from 'redux-saga/effects';
import * as types from './password-group.types';
import * as actions from './password-group.actions';
import { passwordGroupSelectors } from './password-group.selectors';
import { apiRequest } from '../../api/api.saga-services';
import * as api from '../../api/request-config/password-group.api';

export function* storePasswordGroup(passwordGroup: types.IPasswordGroup) {
  yield put(actions.storePasswordGroup(passwordGroup));
  return passwordGroup;
}

export function* clearPasswordGroup(passwordGroupId: types.IPasswordGroup['id']) {
  yield put(actions.clearPasswordGroup(passwordGroupId));
}
export function* clearAllPasswordGroups() {
  const passwordGroupIds: types.IPasswordGroup['id'][] = yield select(passwordGroupSelectors.selectResourceIds);
  yield put(actions.clearPasswordGroup(passwordGroupIds));
}
/* This method sends api request and handle response */
export function* getPasswordGroups(searchParams?: types.IPasswordGroupSearchParams) {
  const passwordGroups = (yield call(apiRequest, api.searchPasswordGroupsApi(searchParams))) as types.IPasswordGroup[];
  if (!passwordGroups) {
    return;
  }
  yield all(passwordGroups.map((passwordGroup) => call(storePasswordGroup, passwordGroup)));
  return passwordGroups;
}

export function* savePasswordGroup(data: types.IPasswordGroupSave) {
  const passwordGroup = (yield call(apiRequest, api.savePasswordGroupApi(data))) as types.IPasswordGroup;
  if (!passwordGroup) {
    return;
  }
  yield call(
    storePasswordGroup,
    passwordGroup.passwordIds ? { ...data, ...passwordGroup } : { ...data, ...passwordGroup, passwordIds: [] },
  );
  return passwordGroup.id;
}
