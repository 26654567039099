import { all, call, put, select } from 'redux-saga/effects';
import * as types from './user-group.types';
import * as actions from './user-group.actions';
import { apiRequest } from '../../api/api.saga-services';
import * as api from '../../api/request-config/user-group.api';
import { userGroupSelectors } from './user-group.selectors';

export function* storeUserGroup(userGroup: types.IUserGroup) {
  yield put(actions.storeUserGroup(userGroup));
  return userGroup;
}

export function* clearUserGroup(userGroupId: types.IUserGroup['id']) {
  yield put(actions.clearUserGroup(userGroupId));
}
export function* clearAllUserGroups() {
  const userGroupIds: types.IUserGroup['id'][] = yield select(userGroupSelectors.selectResourceIds);
  yield put(actions.clearUserGroup(userGroupIds));
}
/* This method sends api request and handle response */
export function* getUserGroups(searchParams?: types.IUserGroupSearchParamas) {
  const userGroups = (yield call(apiRequest, api.searchUserGroupsApi(searchParams))) as types.IUserGroup[];
  if (!userGroups) {
    return;
  }
  yield all(userGroups.map((userGroup) => call(storeUserGroup, userGroup)));
  return userGroups;
}

export function* createUserGroup(data: types.IUserGroupCreate) {
  const userGroup = (yield call(apiRequest, api.createUserGroupApi(data))) as types.IUserGroup;
  if (!userGroup) {
    return;
  }
  yield call(storeUserGroup, userGroup);
  return userGroup.id;
}
