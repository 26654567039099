import { createActionConst } from '../../../../utils/action.utils';

const PASSWORDS_NAMESPACE = 'PASSWORDS';

export const SEARCH_PASSWORDS = createActionConst(PASSWORDS_NAMESPACE, 'SEARCH_PASSWORDS');
export const SAVE_PASSWORD = createActionConst(PASSWORDS_NAMESPACE, 'SAVE_PASSWORD');
export const DELETE_PASSWORD = createActionConst(PASSWORDS_NAMESPACE, 'DELETE_PASSWORD');

export const STORE_PASSWORDS = createActionConst(PASSWORDS_NAMESPACE, 'STORE_PASSWORDS');
export const CLEAR_PASSWORDS = createActionConst(PASSWORDS_NAMESPACE, 'CLEAR_PASSWORDS');

export const FILTER_PASSWORDS = createActionConst(PASSWORDS_NAMESPACE, 'FILTER_PASSWORDS');
export const STORE_FILTERED_PASSWORDS = createActionConst(PASSWORDS_NAMESPACE, 'STORE_FILTERED_PASSWORDS');
export const CLEAR_FILTERED_PASSWORDS = createActionConst(PASSWORDS_NAMESPACE, 'CLEAR_FILTERED_PASSWORDS');

export const STORE_FILTER_PARAMS = createActionConst(PASSWORDS_NAMESPACE, 'STORE_FILTER_PARAMS');
export const CLEAR_FILTER_PARAMS = createActionConst(PASSWORDS_NAMESPACE, 'CLEAR_FILTER_PARAMS');
