import React, { FunctionComponent } from 'react';
import { agendaItemSelectors } from '../../../../../../app/resource/agenda-item/agenda-item.selectors';
import { useSelectWithParams } from '../../../../../../utils/selector.utils';
import { IAgendaDialogItemProps } from './types';

export const AgendaDialogItem: FunctionComponent<IAgendaDialogItemProps> = ({ agendaItemId, provided }) => {
  const agendaItem = useSelectWithParams(agendaItemSelectors.selectResourceById, agendaItemId);

  return (
    agendaItem && (
      <div
        id={agendaItemId}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        draggable={true}
        className="one-widget"
      >
        <div className="one-widget-container">
          <i className="icon-hamburger" />
          <h3>{agendaItem.title}</h3>
          <i className="icon-eye" />
        </div>
      </div>
    )
  );
};
