import { all, put } from 'redux-saga/effects';
import { select } from '../../../utils/selector.utils';
import { clearPageInProgress, storePageInProgress } from './page-state.actions';
import { pageStateSelectors } from './page-state.selectors';
import { IPageKey } from './page-state.types';

export function* initPage(pageKey: IPageKey) {
  const states = yield select(pageStateSelectors.selectPageStates);
  yield all(Object.keys(states).map((page) => put(clearPageInProgress(page as IPageKey))));
  yield put(storePageInProgress(pageKey));
}

export function* completedInit(pageKey: IPageKey) {
  yield put(clearPageInProgress(pageKey));
}
