import { call } from 'redux-saga/effects';
import { selectWithParams } from '../../../../utils/selector.utils';

import { IAgendaItem } from '../../../resource/agenda-item/agenda-item.types';
import { meetingSelectors } from '../../../resource/meeting/meeting.selectors';
import { IMeeting } from '../../../resource/meeting/meeting.types';

import * as agendaPageService from '../../page-storage/agenda-items/agenda-items.saga-services';

export function* initalMeetingForm(meetingId: IMeeting['id'] | undefined) {
  let agendaItemIds: IAgendaItem['id'][] = [];
  if (meetingId) {
    const meeting = yield selectWithParams(meetingSelectors.selectResourceById, meetingId);
    if (meeting) {
      agendaItemIds = meeting.agendaItemIds;
    }
  } else {
    agendaItemIds = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
  }
  //yield call(agendaResourceService.setupDefaultAgendaItems);
  yield call(agendaPageService.setupAgendaItemsForMeetingForm, agendaItemIds);
}
