import React, { useEffect } from 'react';
import { FunctionComponent } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { authSelectors } from '../../../../../app/auth/auth.selectors';
import { createTask } from '../../../../../app/page/page-storage/boardLists/boardLists.actions';
import { storeCurrentBoard } from '../../../../../app/page/page-storage/boards/boards.actions';
import { boardsPageSelectors } from '../../../../../app/page/page-storage/boards/boards.selectors';
import { boardSelectors } from '../../../../../app/resource/boards/board/board.selectors';
import { boardListSelectors } from '../../../../../app/resource/boards/boardList/boardList.selectors';

import { IActionItemForm, ITaskCreate } from '../../../../../app/resource/task/task.types';

import { useRequiredValidation } from '../../../../../utils/form/validation.utils';
import { useSelect, useSelectWithParams } from '../../../../../utils/selector.utils';
import { SelectInput } from '../../inputs/SelectInput';

import { FormSmallTextInput } from '../../inputs/SmallTextInput';

import { IActionItemFormProps } from './types';

export const ActionItemForm: FunctionComponent<IActionItemFormProps> = ({ meetingId }) => {
  const dispatch = useDispatch();
  const userId = useSelect(authSelectors.selectLoggedUser);
  const formProps = useForm();

  const boardIds = useSelect(boardsPageSelectors.selectBoards);
  const boardOptions = useSelectWithParams(boardSelectors.selectResourcesById, boardIds).map((board) => ({
    value: board?.id,
    label: board?.name,
  }));
  const focusBoardTuple = formProps.watch('boardId');
  useEffect(() => {
    if (focusBoardTuple) {
      dispatch(storeCurrentBoard(focusBoardTuple.value));
    }
  }, [dispatch, focusBoardTuple]);

  const boardListOptions = useSelect(boardListSelectors.selectResourceList)
    .filter((boardList) => focusBoardTuple && boardList.boardId === focusBoardTuple.value)
    .map((boardList) => ({ value: boardList.id, label: boardList.name }));

  const requiredValidation = useRequiredValidation();

  const onSubmit = (data: IActionItemForm) => {
    console.log('data', data);
    const newData: ITaskCreate = {
      title: data.title,
      description: '',
      dueDate: '0000-00-00 00:00:00',
      userId: userId ? userId : '',
      meetingIds: [meetingId],
      status: '0',
      label: [],
      members: [],
      showInTasks: '1',
      taskTypeId: data.boardListId.value,
      boardId: data.boardId.value,
    };
    console.log('task: ', newData);
    dispatch(createTask(newData));
  };

  return (
    <FormProvider {...formProps}>
      <form
        id="action-item-form"
        className="topic-form"
        onSubmit={formProps.handleSubmit((formData) => onSubmit(formData as IActionItemForm))}
        method="post"
      >
        <div className="field-container">
          <SelectInput
            id="boardId"
            name="boardId"
            label="Board"
            // defaultOptions={selectedUser}
            validation={requiredValidation}
            options={boardOptions}
            isSingle
          />
        </div>
        <div className="field-container">
          <SelectInput
            id="boardListId"
            name="boardListId"
            label="Board List"
            // defaultOptions={selectedUser}
            validation={requiredValidation}
            options={boardListOptions}
            isSingle
          />
        </div>
        <div className="field-container">
          <FormSmallTextInput
            id="title"
            name="title"
            type="text"
            label="Action Item Title"
            placeholder="Enter Title"
            defaultValue=""
            validation={requiredValidation}
          />
        </div>
      </form>
    </FormProvider>
  );
};
