/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FunctionComponent, useState } from 'react';
import { MembersMenu } from '../../TaskMenus/MembersMenu';
import { IMenuMembersProps } from './types';

export const MenuMembers: FunctionComponent<IMenuMembersProps> = ({ userIds, taskId }) => {
  const [isActive, setActive] = useState(false);
  //console.log('Usao');
  const toggleClass = () => {
    setActive(!isActive);
  };
  //const close = useCallback(() => setActive(false), [setActive]);

  return (
    <div className={isActive ? 'dropdown opened' : 'dropdown'}>
      <a className="btn w100 gray-bg darkGray px-1 f-1 ml-0 mb-1 mt-1 dropdown-button " onClick={toggleClass}>
        <i className="icon f-9 blueGray icon-user mr-1" /> Members
      </a>
      {isActive && <MembersMenu userIds={userIds} dropRight={true} closeMenu={toggleClass} taskId={taskId} />}
    </div>
  );
};
